import React, { useEffect } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router"
import { Redirect, Route } from "react-router-dom"

import { useBookContext } from "../../contexts/Mobile/BookContext"
import { useAnalyticsScreenView } from "../../providers/Mobile/FirebaseAnalyticsProvider"
import { internalTimeFormat } from "../../utils"
import Asset from "./Book/Assets/Asset"
import Desks from "./Book/Desks"
import Done from "./Book/Done"
import SingleSuggest from "./Book/SingleSuggest"
import Suggest from "./Book/Suggest"
import Summary from "./Book/Summary"
import Time from "./Book/Time"
import Title from "./Book/Title"
import isToday from "dayjs/plugin/isToday"

import { useAppSelector } from "../../redux/reducers"
import { RoomResponse } from "../../redux/rooms/types"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { fetchSuggestions } from "../../redux/suggestions/suggestionsSlice"
import { useActions } from "../../redux/utils"

import { AnimatedSwitch } from "../../components/Mobile/AnimatedSwitch"
import { BuildingPicker } from "../../components/Mobile/BuildingPicker"
import { DateTimePicker } from "../../components/Mobile/DateTimePicker"
import { Drawer } from "../../components/Mobile/Drawer"
import { FloorPicker } from "../../components/Mobile/FloorPicker"
import { RoomPicker } from "../../components/Mobile/RoomPicker"
import SafeViewArea from "../../components/Mobile/SafeViewArea"
import Loader from "../../components/basic/Loader"

import "./Book.sass"

dayjs.extend(isToday)

const Book = () => {
	useAnalyticsScreenView("Book")

	const {
		type,
		id,
		date,
		timeslot,
		building,
		floor,
		isShowDateTimePicker,
		onDateTimePick,
		onFloorPick,
		onBuildingPick,
		isBookDesk,
		isBookRoom,
		bookScreenURLs,
		goToHome,
	} = useBookContext()

	const history = useHistory()

	const actions = useActions({
		fetchSuggestions: () => fetchSuggestions({}),
	})

	const { entries: rooms } = useAppSelector((state) => state.rooms)
	const { entries: suggestions, isLoaded: isSuggestionsLoaded } =
		useAppSelector((state) => state.suggestions)

	const { entry: settings } = useAppSelector(selectSettingsEffective)

	const unknownRooms = rooms.filter(
		(room: RoomResponse) => room.building === null,
	)

	const meetNowDuration: number = settings?.device_meet_now_duration ?? 15

	useEffect(() => {
		actions.fetchSuggestions()
	}, [actions])

	/**
	 * Prevent users from going to previous stages after a successful reservation.
	 * The user has the opportunity to return to previous stages in 3 ways:
	 * 1. When the user can back to the reservation `book/{type}/summary` stage from the <Done /> component.
	 * (Redirect restrictions for first way can be found in <Done /> component)
	 * 2. When the user can back to the reservation `book/{type}/done` stage from <Book /> component.
	 * 3. Follow link to one of the previous registration stages. There are currently no restrictions on this logic.
	 *
	 * Part of the code below is responsible for the second way and restricts the visit to the previous stage <Done />
	 * using redirect to the reservation screen.
	 */
	useEffect(() => {
		if (history.action === "POP") {
			history.length = 0
			goToHome()
		}
	}, [goToHome, history])

	/**
	 * Stop execution until suggestions are loaded
	 */
	if (isBookDesk && !isSuggestionsLoaded) {
		return <Loader variant="fullScreen" />
	}

	return (
		<SafeViewArea className="Book">
			<AnimatedSwitch>
				<Route exact path={"/book/asset/*"}>
					<div className="sub-page">
						<Asset />
					</div>
				</Route>
				<Route exact path={"/book/:type/suggest"}>
					<div className="sub-page">
						<Suggest />
					</div>
				</Route>
				<Route exact path={"/book/:type/suggest/:id"}>
					<div className="sub-page">
						<SingleSuggest />
					</div>
				</Route>
				<Route exact path="/book/:type/time">
					<div className="sub-page">
						<Time />
						<Drawer open={isShowDateTimePicker}>
							<DateTimePicker
								date={date}
								timeslot={timeslot}
								preferredTime={
									date.isToday()
										? dayjs().format(internalTimeFormat())
										: dayjs("9:00", internalTimeFormat()).format(
												internalTimeFormat(),
										  )
								}
								defaultTimeSpanMinutes={
									isBookRoom ? meetNowDuration : undefined
								}
								onlyInput={isBookRoom}
								onPick={onDateTimePick}
								isRoomBooking={isBookRoom}
							/>
						</Drawer>
					</div>
				</Route>
				<Route exact path="/book/:type/building">
					<div className="sub-page">
						<BuildingPicker
							type={type}
							asPage
							showUnknown={isBookRoom && unknownRooms.length > 0}
							date={date}
							timeslot={timeslot}
							excludeResId={id}
							onPick={onBuildingPick}
						/>
					</div>
				</Route>
				<Route exact path="/book/:type/floor">
					<div className="sub-page">
						{building && (
							<FloorPicker
								building={building}
								asPage
								showOccupancy
								date={date}
								timeslot={timeslot}
								excludeResId={id}
								onPick={onFloorPick}
							/>
						)}
						{!building && <Redirect to={bookScreenURLs.time} />}
					</div>
				</Route>
				<Route exact path="/book/:type/room">
					<div className="sub-page">
						{timeslot && building && <RoomPicker />}
						{!building && <Redirect to={bookScreenURLs.time} />}
					</div>
				</Route>
				<Route exact path="/book/:type/desk">
					<div className="sub-page">
						{timeslot && building && floor && <Desks />}
						{(!building || !floor) && <Redirect to={bookScreenURLs.time} />}
					</div>
				</Route>
				<Route exact path="/book/:type/title">
					<div className="sub-page">
						<Title />
					</div>
				</Route>
				<Route exact path="/book/:type/summary">
					<div className="sub-page">
						<Summary />
					</div>
				</Route>
				<Route exact path="/book/:type/done">
					<div className="sub-page">
						<Done />
					</div>
				</Route>
				<Route
					exact
					path="/book/:type"
					render={() => {
						/**
						 * In case suggestions are available, redirect
						 * to the suggestion screen instead.
						 */
						if (isBookDesk && suggestions.length > 0) {
							return <Redirect to={bookScreenURLs.suggest} />
						}
						return <Redirect to={bookScreenURLs.time} />
					}}
				/>
			</AnimatedSwitch>
		</SafeViewArea>
	)
}

export default Book
