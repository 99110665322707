import React from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { ReservationCheckinUtils } from "../../checkin_utils"
import { timeZone as defaultTimezone } from "../../dayjs"
import { useToast } from "../../hooks/useToast"
import { shortUserTimeFormat } from "../../utils"
import AssetIcon from "../AssetIcon"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	checkinAssetReservation,
	checkoutAssetReservation,
} from "../../redux/asset_reservation/assetReservationSlice"
import { AssetReservation } from "../../redux/asset_reservation/types"
import { fetchMyAssetReservations } from "../../redux/mobile/reservations/reservationsSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { useActions } from "../../redux/utils"

import "./AssetReservationRow.sass"

type AssetReservationRowProps = {
	reservation: AssetReservation
	loadDays: number
}

export const AssetReservationRow: React.FC<AssetReservationRowProps> = ({
	reservation,
	loadDays,
}) => {
	const { errorToast } = useToast()
	const { openModal, closeModal } = useModals()
	const { asset } = reservation
	const { t } = useTranslation()
	const history = useHistory()

	const { entry: settings } = useAppSelector(selectSettingsEffective)
	const { desk_check_in_enabled } = settings ?? {}

	const actions = useActions({
		checkIn: (reservationId: string) => checkinAssetReservation(reservationId),
		checkOut: (reservationId: string) =>
			checkoutAssetReservation(reservationId),

		fetch: (start: string, end: string) =>
			fetchMyAssetReservations({ start, end }),
	})

	const canCheckIn = ReservationCheckinUtils.isEnabled(
		"checkin",
		settings,
		undefined,
		reservation,
	)
	const canCheckOut = ReservationCheckinUtils.isEnabled(
		"checkout",
		settings,
		undefined,
		reservation,
	)

	const reservationRowClassName = classNames("asset-reservation-row", {
		canBeActive: canCheckIn,
		isActive: canCheckOut,
	})

	const actionClicked = (type: "checkin" | "checkout") => {
		let title = t("mobile.home.confirm_checkin")
		let func = handleCheckIn

		if (type === "checkout") {
			title = t("mobile.home.confirm_checkout")
			func = handleCheckOut
		}

		openModal(ConfirmationModal, {
			title,
			onConfirm: () => {
				func()
				closeModal()
			},
		})
	}

	const handleCheckOut = async () => {
		const response = await actions.checkOut(reservation.id)

		if (checkoutAssetReservation.rejected.match(response)) {
			errorToast(response.error?.message)
		}

		actions.fetch(
			dayjs().startOf("day").toISOString(),
			dayjs().endOf("day").add(loadDays, "day").toISOString(),
		)
	}

	const handleCheckIn = async () => {
		const response = await actions.checkIn(reservation.id)

		if (checkinAssetReservation.rejected.match(response)) {
			errorToast(response.error?.message)
		}

		actions.fetch(
			dayjs().startOf("day").toISOString(),
			dayjs().endOf("day").add(loadDays, "day").toISOString(),
		)
	}

	const timezoneDiffers = reservation.tz !== defaultTimezone

	return (
		<div
			className={reservationRowClassName}
			onClick={() => {
				history.push("/home/asset-reservation/" + reservation.id)
			}}
		>
			<div className="resource-info">
				<div className="desk-name">{asset.name}</div>
				<div className="aux-info">
					<span className="from">
						{dayjs(reservation.start).format(shortUserTimeFormat())}
					</span>
					<span className="dash">{" - "}</span>
					<span className="to">
						{dayjs(reservation.end).format(shortUserTimeFormat())}
					</span>
					{timezoneDiffers && (
						<>
							<span> &middot; </span>
							<span className="diff-tz">{reservation.tz}</span>
						</>
					)}
				</div>
				<span className="building-floor">
					{asset.building && `${asset.building.name}`}
				</span>

				{desk_check_in_enabled && (
					<>
						{reservation.checked_in?.check_out_at && (
							<div className="checkout-info">
								{t("mobile.general.checked_out")}
							</div>
						)}
						{desk_check_in_enabled && canCheckIn && (
							<div className="actions-row">
								<Button
									variant="link"
									className="action"
									onClick={() => actionClicked("checkin")}
								>
									{t("mobile.general.check_in")}
								</Button>
							</div>
						)}
						{desk_check_in_enabled && canCheckOut && (
							<div className="actions-row">
								<Button
									variant="link"
									className="action"
									onClick={() => actionClicked("checkout")}
								>
									{t("mobile.general.check_out")}
								</Button>
							</div>
						)}
					</>
				)}
			</div>
			<div className="resource-icon">
				<AssetIcon name={asset.asset_type.icon} />
			</div>
		</div>
	)
}
