import React, { useState } from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { AssetType } from "../redux/asset_types/types"

import AssetTypeForm from "../components/Form/AssetTypeForm"

type Props = {
	open: boolean
	assetType?: AssetType
}

export default function AssetTypeModal({ open, assetType }: Props) {
	const { closeModal } = useModals()
	const [shouldCloseModal, setShouldCloseModal] = useState(true)
	const onRequestClose = () => {
		closeModal()
	}

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={onRequestClose}
			ariaHideApp={false}
			shouldCloseOnEsc={shouldCloseModal}
		>
			<AssetTypeForm
				assetType={assetType}
				onChangeShouldCloseModal={setShouldCloseModal}
			/>
		</ReactModal>
	)
}
