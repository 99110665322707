import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { ReservationCheckinUtils } from "../../checkin_utils"

import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { ScheduleReservation } from "../../redux/types"
import { selectUser } from "../../redux/user/selectors"

import "./CheckinLabel.sass"

type ScheduleCheckinLabelProps = {
	day: Dayjs
	reservation: ScheduleReservation
}

export function ScheduleCheckinLabel({
	day,
	reservation,
}: ScheduleCheckinLabelProps) {
	const { t } = useTranslation()
	const { entry: currentUser } = useAppSelector(selectUser)
	const { entry: settings } = useAppSelector(selectSettingsEffective)

	const checkinLabelClassName = "CheckinLabel ReservationCheckinLabel"
	const canCheckin = ReservationCheckinUtils.isEnabled(
		"checkin",
		settings,
		currentUser,
		reservation,
	)

	if (dayjs().isSame(day, "day")) {
		if (reservation.checked_in) {
			if (reservation.checked_in.check_out_at) {
				return (
					<span className="CheckinLabel checked-out">
						{t("desktop.manage.checked_out")}
					</span>
				)
			}
			if (reservation.checked_in.check_in_at) {
				return (
					<span className={checkinLabelClassName}>
						{t("desktop.manage.checked_in")}
					</span>
				)
			}
		}
		if (canCheckin) {
			return (
				<span className={checkinLabelClassName}>
					{t("desktop.manage.check_in")}
				</span>
			)
		}
	}

	return null
}
