import { FC } from "react"

import { useTranslation } from "react-i18next"

import { AssetType } from "../../../redux/asset_types/types"

import AssetIcon from "../../../components/AssetIcon"
import Button from "../../../components/basic/Button"

import "./AssetTypeItem.sass"

type AssetTypeItemProps = {
	assetType: AssetType
	onEditClick: (id: string) => void
	onAddAssetClick: (id: string) => void
}
const AssetTypeItem: FC<AssetTypeItemProps> = ({
	assetType,
	onEditClick,
	onAddAssetClick,
}) => {
	const { id, name, icon, description, buildings } = assetType ?? {}
	const { t } = useTranslation()

	return (
		<div className="asset-type-item">
			<div className="asset-type-icon">
				<AssetIcon name={icon} />
			</div>
			<div className="asset-type-details">
				<div className="name-wrapper">
					<strong className="name">{name}</strong>
				</div>
				{description && <div className="description">{description}</div>}
				{buildings[0] && <div className="building">{buildings[0].name}</div>}
			</div>
			<div className="button-wrapper">
				<Button onClick={(e) => onEditClick(id)} variant="secondary">
					{t("desktop.settings.assets.edit_button")}
				</Button>
				<Button onClick={(e) => onAddAssetClick(id)}>
					{t("desktop.settings.assets.add_button")}
				</Button>
			</div>
		</div>
	)
}

export default AssetTypeItem
