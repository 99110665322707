/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useEffect, useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import {
	documentsURL,
	visitorSettingURL,
	visitorSettingsURL,
} from "../../../api"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useToast } from "../../../hooks/useToast"
import LicensesInfoList from "../../../screens/Settings/LicensesInfoList"
import { OptionType } from "../../../types/sharedTypes"
import { EMAIL_REGEX } from "../../../utils"
import Card from "../../Card"
import Field, { UncontrolledField } from "../../Field"
import BuildingFilter from "../../Filter/BuildingFilter"
import { AsyncCheckbox } from "../../advanced/AsyncCheckbox"
import AsyncSelect from "../../advanced/AsyncSelect"
import { AsyncSwitch } from "../../advanced/AsyncToggle"
import { ConfirmationModal } from "../../advanced/ConfirmationModal"
import Button from "../../basic/Button"
import { Input } from "../../basic/Input"
import { Select } from "../../basic/Select"
import PageForm from "../PageFormHook"
import { setErrors } from "../formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchSubscriptionsQuery } from "../../../redux/api/billing/subscriptions"
import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { fetchBuildingsWithVM } from "../../../redux/buildings/buildingsWithVMSlice"
import { selectBuildingsWithVM } from "../../../redux/buildings/selectors"
import { FetchOptions } from "../../../redux/buildings/types"
import {
	DocumentResponse,
	DocumentStatusType,
} from "../../../redux/documents/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { VisitorEmailType } from "../../../redux/visitor_email_settings/types"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import {
	NewVisitsCreatedByOption,
	VisitorSettingRequest,
	VisitorSettingResponse,
} from "../../../redux/visitor_settings/types"
import {
	fetchVisitorSetting,
	fetchVisitorSettings,
	updateVisitorSetting,
} from "../../../redux/visitor_settings/visitorSettingsSlice"

import { ReactComponent as PlusSVG } from "../../../assets/images/icons/Plus circle.svg"

import "./VisitorSettingsForm.sass"

type FormValues = {
	building_id: string
	enabled: boolean
	create_visits_by: OptionType<NewVisitsCreatedByOption>
	document_signing_enabled: boolean
	document?: DocumentResponse
	send_signed_email: boolean
	document_send_to: string
	automatic_visitor_registration: boolean
	excluded_calendar_domain: string
	enable_general_notification: boolean
	send_app_notification: boolean
	send_sms_notification: boolean
	send_email_limit_notification: boolean
	send_slack_notification: boolean
	send_arrival_email_notification: boolean
	enable_visitors_notification: boolean
	invitation_email_id?: string
	send_invitation_email: boolean
	welcome_email_id?: string
	send_welcome_email: boolean
	maintenance_email?: string
	enable_maintenance_notification: boolean
}

const VisitorSettingsForm = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { errorToast, infoToast } = useToast()
	const { openModal, closeModal } = useModals()
	const actions = useActions({
		updateVisitorSetting: (setting: VisitorSettingRequest) =>
			updateVisitorSetting(setting),
		fetchVisitorSettings: () => fetchVisitorSettings(),
		fetchVisitorSetting: (id: string) => fetchVisitorSetting(id),
		fetchBuildingsWithVM: (options: FetchOptions = {}) =>
			fetchBuildingsWithVM(options),
	})

	const {
		data: { results: buildings = [] } = {},
		isSuccess: areBuildingsLoaded,
	} = useFetchBuildingsQuery()

	const { entries: buildingsWithVM } = useAppSelector(selectBuildingsWithVM)
	const { entries: visitorSettings, isLoaded } = useAppSelector(
		selectVisitorSettings,
	)

	const { data: { trials: availableTrials } = {} } = useFetchSubscriptionsQuery(
		{},
	)

	const [visitorSetting, setVisitorSetting] = useState<VisitorSettingRequest>()

	const { value: buildingFilter, onChange: setBuildingFilter } =
		useLocalStorage("visitor-settings-building-filter", "")

	const selectedBuilding =
		buildings.find((building) => building.id === buildingFilter) ?? buildings[0]

	const { id, building_id, enabled } = visitorSetting || {}

	const visitsCreatedByDropdownOptions = useMemo(() => {
		return Object.values(NewVisitsCreatedByOption).map((value) => {
			return {
				value,
				label: t(
					`desktop.settings.visitors.visitor_settings.new_visits.${value.toLowerCase()}` as ParseKeys,
				),
			}
		})
	}, [t])

	const methods = useForm<FormValues>({
		defaultValues: {
			...visitorSetting,
			create_visits_by: visitsCreatedByDropdownOptions.find(
				(o) => o.value === visitorSetting?.create_visits_by,
			),
		},
	})

	const { control, setError, reset, getValues, watch } = methods

	const refresh = useCallback(
		(value: boolean) => {
			if (value) {
				infoToast(
					t(
						"desktop.settings.visitors.visitor_settings.settings_enabled_toast",
					),
				)
			} else {
				infoToast(
					t(
						"desktop.settings.visitors.visitor_settings.settings_disabled_toast",
					),
				)
			}

			actions.fetchVisitorSetting(building_id ?? buildingFilter)
		},
		[actions, buildingFilter, building_id, infoToast, t],
	)

	useEffect(() => {
		actions.fetchVisitorSetting(building_id ?? buildingFilter)
	}, [actions, buildingFilter, building_id])

	useEffect(() => {
		if (isLoaded && buildingFilter) {
			const exVisitorSetting = visitorSettings.find(
				(vs) => vs.building_id === buildingFilter,
			)

			setVisitorSetting({
				id: exVisitorSetting?.id,
				building_id: buildingFilter,
				enabled: exVisitorSetting?.enabled ?? false,
				create_visits_by:
					exVisitorSetting?.create_visits_by ??
					NewVisitsCreatedByOption.ALL_EMPLOYEES,
				document_signing_enabled:
					exVisitorSetting?.document_signing_enabled ?? false,
				document: exVisitorSetting?.document,
				send_signed_email: exVisitorSetting?.send_signed_email ?? false,
				document_send_to: exVisitorSetting?.document_send_to ?? "",
				automatic_visitor_registration:
					exVisitorSetting?.automatic_visitor_registration ?? false,
				excluded_calendar_domain:
					exVisitorSetting?.excluded_calendar_domain ?? "",
				enable_general_notification:
					exVisitorSetting?.enable_general_notification ?? false,
				send_app_notification: exVisitorSetting?.send_app_notification ?? false,
				send_sms_notification: exVisitorSetting?.send_sms_notification ?? false,
				send_email_limit_notification:
					exVisitorSetting?.send_email_limit_notification ?? false,
				send_slack_notification:
					exVisitorSetting?.send_slack_notification ?? false,
				send_arrival_email_notification:
					exVisitorSetting?.send_arrival_email_notification ?? false,
				enable_visitors_notification:
					exVisitorSetting?.enable_visitors_notification ?? false,
				invitation_email_id: exVisitorSetting?.invitation_email_id,
				send_invitation_email: exVisitorSetting?.send_invitation_email ?? false,
				welcome_email_id: exVisitorSetting?.welcome_email_id,
				send_welcome_email: exVisitorSetting?.send_welcome_email ?? false,
				enable_maintenance_notification:
					exVisitorSetting?.enable_maintenance_notification ?? false,
				maintenance_email: exVisitorSetting?.maintenance_email,
			})
		}
	}, [buildingFilter, visitorSettings, isLoaded])

	// Update default values if settings were updated from parent (e.g. different building was selected)
	useEffect(() => {
		reset({
			...visitorSetting,
			create_visits_by: visitsCreatedByDropdownOptions.find(
				(o) => o.value === visitorSetting?.create_visits_by,
			),
		})
	}, [getValues, reset, visitorSetting, visitsCreatedByDropdownOptions])

	// Fetch buildings if visitor management enable change
	useEffect(() => {
		actions.fetchBuildingsWithVM()
	}, [actions, building_id, enabled])

	const generateBody = useCallback(
		(values: FormValues) => {
			return {
				...values,
				enabled: Boolean(enabled),
				create_visits_by: values.create_visits_by.value,
				document_id: values.document?.id ?? undefined,
				document: undefined,
			}
		},
		[enabled],
	)

	const onUpdateClick = useCallback(
		async (values: FormValues) => {
			const response = await actions.updateVisitorSetting(generateBody(values))

			if (updateVisitorSetting.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast)
				}
			} else {
				infoToast(
					t(
						"desktop.settings.visitors.visitor_settings.settings_updated_toast",
					),
				)
			}
		},
		[actions, generateBody, setError, errorToast, infoToast, t],
	)

	const handleVisitorManagementConfirmation = async (
		action: (value: boolean) => Promise<void>,
		value: boolean,
	) => {
		if (
			(buildingsWithVM.length > 1 && value === false) ||
			(buildingsWithVM.length >= 1 && value === true)
		) {
			await action(value)
			return
		}

		const hasTrialAvailable = availableTrials?.some(
			(trial) => trial === "visitor",
		)
		const isEnablingVM = value === true

		let title, hint

		if (!hasTrialAvailable && isEnablingVM) {
			title = t(
				"desktop.settings.visitors.visitor_settings.enable_visitor_management_confirmation_title",
			)
			hint = t(
				"desktop.settings.visitors.visitor_settings.enable_visitor_management_confirmation_hint_no_trial",
			)
		} else if (isEnablingVM) {
			title = t(
				"desktop.settings.visitors.visitor_settings.enable_visitor_management_confirmation_title",
			)
			hint = t(
				"desktop.settings.visitors.visitor_settings.enable_visitor_management_confirmation_hint",
			)
		} else if (!isEnablingVM) {
			title = t(
				"desktop.settings.visitors.visitor_settings.disable_visitor_management_confirmation_title",
			)
			hint = (
				<Trans i18nKey="desktop.settings.visitors.visitor_settings.disable_visitor_management_confirmation_hint">
					<a href="mailto:support@getjoan.com"></a>
				</Trans>
			)
		}

		openModal(ConfirmationModal, {
			title,
			hint,
			onConfirm: async () => {
				await action(value)
				closeModal()
			},
		})
	}

	const documentSigningEnabled = watch("document_signing_enabled")
	const sendSignedEmail = watch("send_signed_email")

	const updateMode = id !== undefined

	return (
		<div className="VisitorSettingsForm">
			{areBuildingsLoaded && buildings.length < 1 && (
				<Card className="building-missing">
					<div>
						<div>{t("desktop.settings.floor_plans.no_data.no_buildings")}</div>
						<div>
							<Trans i18nKey="desktop.settings.floor_plans.actions.add_building">
								<Link to="/settings/buildings">Add a new building</Link>
							</Trans>
						</div>
					</div>
				</Card>
			)}
			{selectedBuilding && (
				<FormProvider {...methods}>
					<PageForm
						updateMode={true}
						onUpdate={onUpdateClick}
						hide={!(updateMode && selectedBuilding)}
						header={
							<div className="visitor-settings-header">
								<div className="visitor-settings-header__controls">
									<div>
										<BuildingFilter
											value={buildingFilter}
											onChange={setBuildingFilter}
										/>
									</div>
									{selectedBuilding && (
										<div>
											<AsyncSwitch
												confirmAction={handleVisitorManagementConfirmation}
												updateMode={updateMode}
												urlGenerator={() => {
													if (updateMode) {
														return visitorSettingURL(selectedBuilding.id)
													}

													return visitorSettingsURL()
												}}
												analyticsGenerator={async (response) => {
													const data: VisitorSettingResponse =
														await response.json()

													if (data) {
														analyticsEvent(
															data.enabled
																? SupportedEvents.VISITOR_ADMIN_MANAGEMENT_ENABLED
																: SupportedEvents.VISITOR_ADMIN_MANAGEMENT_DISABLED,
															{
																id: data.id,
																building_id: data.building_id,
																/** Fetch happens after, so we manually sub/detract */
																total_buildings_enabled:
																	buildingsWithVM.length +
																	(data.enabled ? 1 : -1),
															},
														)
													}
												}}
												bodyGenerator={(value) => {
													const values = methods.getValues()

													if (updateMode) {
														return {
															enabled: value,
														}
													}

													return {
														...generateBody(values),
														enabled: value,
														send_invitation_email: true,
														send_welcome_email: true,
														enable_visitors_notification: true,
													}
												}}
												refresh={refresh}
												value={visitorSetting?.enabled ?? false}
												label={t(
													"desktop.settings.visitors.visitor_settings.enable_visitor_management",
												)}
											/>
										</div>
									)}
								</div>
								<div className="visitor-settings-header__content">
									<Trans
										i18nKey={
											"desktop.settings.visitors.visitor_settings.configuration_text"
										}
										values={{
											buildingName: `<strong>${selectedBuilding?.name}</strong>`,
										}}
									/>
									<div className="subtext">
										<LicensesInfoList licenseType="building" />
									</div>
								</div>
							</div>
						}
					>
						<UncontrolledField>
							<AsyncSwitch
								urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
								bodyGenerator={(value) => ({
									enable_general_notification: value,
								})}
								refresh={actions.fetchVisitorSettings}
								value={visitorSetting?.enable_general_notification ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.enable_general_notifications",
								)}
							/>
						</UncontrolledField>

						<div className="SettingsGroup">
							<div className="Row">
								<div className="FieldWrapper">
									<p className="Subtext mw-50">
										{t(
											"desktop.settings.visitors.visitor_settings.select_your_prefered_notification_methods",
										)}
									</p>
								</div>
							</div>
							<div className="Row">
								{/*<UncontrolledField>
									<AsyncCheckbox
										label={t(
											"desktop.settings.visitors.visitor_settings.visitor_arrival_joan_app_push_notifications",
										)}
										value={visitorSetting?.send_app_notification ?? false}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_app_notification: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_general_notification}
										isSecondary
									/>
								</UncontrolledField>
								*/}
								{/* 								<UncontrolledField>
									<AsyncCheckbox
										label={t(
											"desktop.settings.visitors.visitor_settings.link_to_joan_push_notifications",
										)}
										value={visitorSetting?.send_slack_notification ?? false}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_slack_notification: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_general_notification}
										isSecondary
									/>
								</UncontrolledField> */}
							</div>
							<div className="Row">
								<UncontrolledField>
									<AsyncCheckbox
										label={t(
											"desktop.settings.visitors.visitor_settings.visitor_arrival_email_notifications",
										)}
										value={
											visitorSetting?.send_arrival_email_notification ?? false
										}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_arrival_email_notification: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_general_notification}
										isSecondary
									/>
								</UncontrolledField>
								{/* <UncontrolledField>
									<AsyncCheckbox
										value={
											visitorSetting?.send_email_limit_notification ?? false
										}
										label={t(
											"desktop.settings.visitors.visitor_settings.enable_email_notifications_when_on_limit",
										)}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_email_limit_notification: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_general_notification}
										isSecondary
									/>
								</UncontrolledField> */}
							</div>
							<div className="Row">
								<UncontrolledField>
									<AsyncCheckbox
										value={visitorSetting?.send_sms_notification ?? false}
										label={t(
											"desktop.settings.visitors.visitor_settings.visitor_arrival_sms_notification",
										)}
										description={t(
											"desktop.settings.visitors.visitor_settings.visitor_arrival_sms_notification_details",
										)}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_sms_notification: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_general_notification}
										isSecondary
									/>
								</UncontrolledField>
							</div>
						</div>
						<hr />
						<UncontrolledField>
							<AsyncSwitch
								urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
								bodyGenerator={(value) => ({
									enable_visitors_notification: value,
								})}
								refresh={actions.fetchVisitorSettings}
								value={visitorSetting?.enable_visitors_notification ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.enable_visitor_notifications",
								)}
							/>
						</UncontrolledField>

						<div className="SettingsGroup">
							<div className="Row">
								<div className="FieldWrapper">
									<p className="Subtext mw-50">
										{t(
											"desktop.settings.visitors.visitor_settings.set_invitation_email_to_be_sent",
										)}
									</p>
								</div>
							</div>
							<div className="Row">
								<UncontrolledField>
									<AsyncCheckbox
										value={visitorSetting?.send_invitation_email ?? false}
										label={t(
											"desktop.settings.visitors.visitor_settings.invitation_email",
										)}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_invitation_email: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_visitors_notification}
										isSecondary
									/>
								</UncontrolledField>
								<UncontrolledField>
									<AsyncCheckbox
										value={visitorSetting?.send_welcome_email ?? false}
										label={t(
											"desktop.settings.visitors.visitor_settings.welcome_email",
										)}
										urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
										bodyGenerator={(value: boolean) => ({
											send_welcome_email: value,
										})}
										refresh={actions.fetchVisitorSettings}
										disabled={!visitorSetting?.enable_visitors_notification}
										isSecondary
									/>
								</UncontrolledField>
							</div>
							<div className="Row">
								<div className="FieldWrapper">
									<Button
										className="EditButton"
										variant="secondary"
										onClick={() =>
											history.push(
												`/settings/visitors/general/${building_id}/emails/${VisitorEmailType.INVITATION}`,
											)
										}
										isDisabled={
											!visitorSetting?.enable_visitors_notification ||
											!visitorSetting?.send_invitation_email
										}
									>
										{t(
											"desktop.settings.visitors.visitor_settings.edit_invitation_email",
										)}
									</Button>
								</div>
								<div className="FieldWrapper">
									<Button
										className="EditButton"
										variant="secondary"
										onClick={() =>
											history.push(
												`/settings/visitors/general/${building_id}/emails/${VisitorEmailType.WELCOME}`,
											)
										}
										isDisabled={
											!visitorSetting?.enable_visitors_notification ||
											!visitorSetting?.send_welcome_email
										}
									>
										{t(
											"desktop.settings.visitors.visitor_settings.edit_welcome_email",
										)}
									</Button>
								</div>
							</div>
						</div>

						{/* <Field
						control={control}
						name="send_app_notification"
						className="field-width-50"
					>
						{(props) => (
							<Switch
								{...props}
								label={t(
									"desktop.settings.visitors.visitor_settings.visitor_arrival_joan_app_push_notifications",
								)}
								disabled={!enableGeneralNotifications}
							/>
						)}
					</Field> */}

						{/* <div className="StoreIcons">
							<a
								href="https://apps.apple.com/us/app/joan-workplace-management/id1519226753 "
								target="_blank"
								rel="noreferrer"
							>
								<AppStoreSVG />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.visionect.joan.desk"
								target="_blank"
								rel="noreferrer"
							>
								<GooglePlaySVG />
							</a>
						</div> */}
						{/* <Field
						control={control}
						name="send_email_limit_notification"
						className="field-width-50"
					>
						{(props) => (
							<Switch
								{...props}
								label={t(
									"desktop.settings.visitors.visitor_settings.enable_email_notifications_when_on_limit",
								)}
								disabled={!enableGeneralNotifications}
							/>
						)}
					</Field> */}

						<hr />
						<div className="SettingsGroup">
							<Field
								control={control}
								name="create_visits_by"
								className="field-width-50 m-0"
								label={t(
									"desktop.settings.visitors.visitor_settings.new_visits_can_be_created_by",
								)}
							>
								{(props) => (
									<Select<OptionType>
										{...props}
										options={visitsCreatedByDropdownOptions}
									/>
								)}
							</Field>
						</div>

						<hr />

						<UncontrolledField>
							<AsyncSwitch
								urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
								bodyGenerator={(value) => ({
									automatic_visitor_registration: value,
								})}
								refresh={actions.fetchVisitorSettings}
								value={visitorSetting?.automatic_visitor_registration ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.automatic_visitor_registration_via_room_calendar",
								)}
							/>
						</UncontrolledField>
						<div className="SettingsGroup">
							<div className="Row">
								<div className="FieldWrapper">
									<p className="Subtext mw-50">
										<Trans i18nKey="desktop.settings.visitors.visitor_settings.automatic_visitor_registration_via_calendar">
											<a href="mailto:invite@getjoan.com">mail</a>
											<a
												href="https://support.getjoan.com/knowledge/how-to-invite-visitors-from-your-calendar"
												target="_blank"
												rel="noreferrer noopener"
											>
												link
											</a>
										</Trans>
									</p>
								</div>
							</div>
							<Field
								control={control}
								name="excluded_calendar_domain"
								className="field-width-50"
								label={t(
									"desktop.settings.visitors.visitor_settings.excluded_domains",
								)}
								subText={t(
									"desktop.settings.visitors.visitor_settings.excluded_domains_description",
								)}
							>
								{(props) => (
									<Input
										{...props}
										disabled={
											!visitorSetting?.automatic_visitor_registration ?? false
										}
									/>
								)}
							</Field>
						</div>
						<hr />

						<UncontrolledField>
							<AsyncSwitch
								urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
								bodyGenerator={(value) => ({
									document_signing_enabled: value,
								})}
								refresh={actions.fetchVisitorSettings}
								value={visitorSetting?.document_signing_enabled ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.document_signing",
								)}
							/>
						</UncontrolledField>
						<div className="SettingsGroup">
							<Field
								control={control}
								name="document"
								className="field-width-50"
								label={t(
									"desktop.settings.visitors.visitor_settings.select_document_for_signing",
								)}
								rules={{
									validate: (value) => {
										if (documentSigningEnabled && !value) {
											return t(
												"desktop.settings.visitors.visitor_settings.select_document_for_signing_required",
											)
										}
									},
								}}
							>
								{(props) => (
									<AsyncSelect
										{...props}
										urlGenerator={(fetchOptions) => {
											return documentsURL(fetchOptions)
										}}
										nothingFoundMessage={t(
											"desktop.settings.visitors.documents.no_documents.title",
										)}
										getOptionLabel={(document) => document.title}
										getOptionValue={(document) => document.id}
										disabled={!documentSigningEnabled}
										filterResultsFn={(d) =>
											d.status === DocumentStatusType.PUBLISHED
										}
									/>
								)}
							</Field>

							<div className="add-document">
								<Button
									to="/settings/visitors/documents/add"
									variant="link"
									isSmall
								>
									<PlusSVG />
									{t("desktop.settings.visitors.documents.new_document")}
								</Button>
							</div>

							<UncontrolledField>
								<AsyncCheckbox
									value={visitorSetting?.send_signed_email ?? false}
									label={t(
										"desktop.settings.visitors.visitor_settings.send_signed_documents_to_specific_email",
									)}
									urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
									bodyGenerator={(value: boolean) => ({
										send_signed_email: value,
									})}
									refresh={actions.fetchVisitorSettings}
									disabled={!visitorSetting?.document_signing_enabled}
									isSecondary
								/>
							</UncontrolledField>

							<Field
								control={control}
								name="document_send_to"
								className="field-width-50"
								label={t(
									"desktop.settings.visitors.visitor_settings.email_for_gathering_signed_documents",
								)}
								rules={{
									validate: (value) => {
										if (sendSignedEmail && !value) {
											return t(
												"desktop.settings.visitors.visitor_settings.email_for_gathering_signed_documents_valid",
											)
										}
									},
									pattern: {
										value: EMAIL_REGEX,
										message: t(
											"desktop.settings.visitors.visitor_settings.email_for_gathering_signed_documents_valid",
										),
									},
								}}
							>
								{(props) => (
									<>
										<div className="Row">
											<div className="FieldWrapper">
												<p className="Subtext">
													{t(
														"desktop.settings.visitors.visitor_settings.for_all_visits_with_mandatory_document_signing",
													)}
												</p>
											</div>
										</div>
										<Input {...props} disabled={!sendSignedEmail} />
									</>
								)}
							</Field>
						</div>
						<hr />
						<UncontrolledField>
							<AsyncSwitch
								urlGenerator={() => visitorSettingURL(selectedBuilding.id)}
								bodyGenerator={(value) => ({
									enable_maintenance_notification: value,
								})}
								refresh={actions.fetchVisitorSettings}
								value={visitorSetting?.enable_maintenance_notification ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.maintenance_email",
								)}
							/>
						</UncontrolledField>
						<div className="SettingsGroup">
							<Field
								control={control}
								name="maintenance_email"
								className="field-width-50"
								label={t(
									"desktop.settings.visitors.visitor_settings.set_maintenance_email",
								)}
							>
								{(props) => (
									<>
										<div className="Row">
											<div className="FieldWrapper">
												<p className="Subtext">
													{t(
														"desktop.settings.visitors.visitor_settings.maintenance_email_description",
													)}
												</p>
											</div>
										</div>
										<Input
											{...props}
											disabled={
												!visitorSetting?.enable_maintenance_notification ??
												false
											}
										/>
									</>
								)}
							</Field>
						</div>
					</PageForm>
				</FormProvider>
			)}
		</div>
	)
}
export default VisitorSettingsForm
