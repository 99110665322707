import { useEffect, useRef } from "react"

export function useTimeout(callback?: () => void, time = 10 * 1000) {
	let timeout = useRef<any>(null)

	useEffect(() => {
		timeout.current = setTimeout(() => {
			callback && callback()
		}, time)

		return () => {
			clearTimeout(timeout.current)
		}
	}, [time, callback])

	return timeout.current
}
