import React from "react"

import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import Datestring from "../../../components/Datestring"
import Heading from "../../../components/Heading"
import AssetCSVReportDownload from "../../../components/Manage/AssetsCSVReportDownload"
import { WeekNav } from "../../../components/Manage/CalNav"
import WeekendToggle from "../../../components/Manage/WeekendToggle"
import Button from "../../../components/basic/Button"

type Props = {
	fromDate: Dayjs
	toDate: Dayjs
	weekEnd: Dayjs
	showWeekends: boolean
	showExportButton: boolean
	toggleWeekends: () => void
	onNewReservation: () => void
	assetTypeId?: string
	departmentId?: string
}
const Header = ({
	fromDate,
	toDate,
	weekEnd,
	showWeekends,
	toggleWeekends,
	onNewReservation,
	showExportButton,
	assetTypeId,
	departmentId,
}: Props) => {
	const { t } = useTranslation()

	return (
		<Heading>
			<WeekNav />
			<span className="date-range">
				<Datestring date={fromDate} isInline isLong /> -{" "}
				<Datestring date={weekEnd} isInline isLong />
			</span>
			<WeekendToggle showWeekends={showWeekends} onToggle={toggleWeekends} />
			<div className="spacer" />
			{showExportButton && (
				<AssetCSVReportDownload
					start={fromDate}
					end={toDate}
					departmentId={departmentId}
					assetTypeId={assetTypeId}
				/>
			)}

			<Button className="NewMeeting" onClick={onNewReservation} isSmall>
				{t("desktop.manage.asset_booking.buttons.new_reservation")}
			</Button>
		</Heading>
	)
}

export default Header
