import { RefObject, useEffect } from "react"

/*
 * the useOnClick outside hook will check
 * if click occurred outside the provided ref and if so it will execute the handler
 */
export const useOnClickOutside = (
	refs: RefObject<HTMLElement | HTMLDivElement>[],
	handler: (event: TouchEvent | MouseEvent) => void,
) => {
	useEffect(() => {
		const listener = (event: TouchEvent | MouseEvent) => {
			const contains: boolean = refs.some(
				(r) => !r.current || r.current.contains(event.target as Node),
			)
			event.stopPropagation()

			if (!contains) {
				handler(event)
			}
		}

		document.addEventListener("mousedown", listener)
		document.addEventListener("touchstart", listener)

		return () => {
			document.removeEventListener("mousedown", listener)
			document.removeEventListener("touchstart", listener)
		}
	}, [refs, handler])
}
