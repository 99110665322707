import { useState } from "react"

import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../../../../modals/modalStyles"

import Button from "../../../../components/basic/Button"

import { ReactComponent as CrossSVG } from "../../../../assets/images/icons/Cross.svg"

import "./DemoDataModal.sass"

export type DemoDataModalProps = {
	open: boolean
	onConfirm: () => Promise<void>
	onCancel: () => void
	className?: string
}

const DemoDataModal = ({
	open,
	onConfirm,
	onCancel,
	className,
}: DemoDataModalProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false)

	const { t } = useTranslation()

	const cn = classNames("DemoDataModal ModalForm", className)

	const handleOnConfirm = async () => {
		setIsSubmitting(true)
		await onConfirm()
		setIsSubmitting(false)
	}

	return (
		<ReactModal
			isOpen={open}
			onRequestClose={() => onCancel()}
			ariaHideApp={false}
			style={{
				content: contentStyle,
				overlay: overlayStyle,
			}}
		>
			<div className={cn}>
				<div className="top-bar">
					<h1>{t("desktop.settings.desks.demo_data_modal.title")}</h1>
					<button className="close" onClick={() => onCancel()}>
						<CrossSVG />
					</button>
				</div>

				<div className="hint">
					<Trans i18nKey="desktop.settings.desks.demo_data_modal.description">
						<p></p>
						<p></p>
					</Trans>
				</div>
				<div className="actions actions-edit">
					<Button
						variant="secondary"
						onClick={onCancel}
						isDisabled={isSubmitting}
					>
						{t("desktop.settings.desks.demo_data_modal.buttons.no_demo_data")}
					</Button>
					<Button
						variant="primary"
						onClick={handleOnConfirm}
						isDisabled={isSubmitting}
					>
						{t("desktop.settings.desks.demo_data_modal.buttons.demo_data")}
					</Button>
				</div>
			</div>
		</ReactModal>
	)
}
export default DemoDataModal
