import { ThunkApiConfig } from "RootType"

import { floorsURL, get } from "../../api"
import { getErrorMessage } from "../reduxUtils"
import { FloorsResponse } from "./types"
import { createAsyncThunk } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
/*
	This call isn't affecting global state with the exception of logging the user out.
	It's only used for information gathering on component level.
*/
export const fetchFloorsOnly = createAsyncThunk<
	FloorsResponse,
	string | null,
	ThunkApiConfig
>("floors/fetchFloorsOnly", async (building = null, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(
		floorsURL({
			stats: true,
			query: building
				? {
						conditions: { building_id: building },
				  }
				: {
						fields: ["*", "settings"],
				  },
		}),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})
