import { PropsWithChildren, ReactElement, ReactNode, ReactPortal } from "react"

export enum RecurringType {
	EVERY_DAY = "every_day",
	EVERY_WEEKDAY = "every_weekday",
	EVERY_DAY_OF_WEEK = "every_dayofweek",
}

export const isRecurringType = (value: string): value is RecurringType =>
	Object.values<string>(RecurringType).includes(value)

/**
 * @example
 * const TestObjectConst = {
 * 	A: 'a',
 * 	B:'b',
 * } as const
 * type TestType = UnionTypeFromObject<typeof TestObjectConst>
 *  // TestType = 'a' | 'b'
 *
 */
export type ObjectToUnion<TObject> = TObject[keyof TObject]
export type ArrayToUnion<T extends readonly any[]> = T[number]

export type InternalTime = `${number}${number}:${number}${number}`

export type AddAllAndNumber<UnionType extends string> = {
	[P in UnionType]: P | `${P}:all` | `${P}:${number}`
}[UnionType]

export type OptionType<Value = string> = {
	value: Value
	label: string | ReactNode
	disabled?: boolean
	tooltip?: string | ReactNode
}

export type OptionalKeysRecord<
	UnionType extends string,
	ValueType extends unknown,
> = {
	[K in UnionType]?: ValueType
}

export type MultiOptionType<TValue extends string | number> = {
	value: TValue
	label: string | ReactNode
	isSingleSelect?: boolean
}

export type IdAndName = {
	id: string
	name: string
}

export type KeyAndName = {
	key: string
	name: string
}

export interface GroupBase<Option> {
	options: Option[]
	label?: string
}

export type FCC<Props = unknown> = React.FC<PropsWithChildren<Props>>

export type Children =
	| ReactElement
	| string
	| number
	| Iterable<Children>
	| ReactPortal
	| boolean
	| null
	| undefined

export type ISODate = string

export type Facet = {
	id: string
	name: string
	count: number
}

export type ImageResizeOptions = {
	width?: number
	height?: number
	mode: number
}

/**
 * Represents a path in a nested object for elements with type string.
 * @template T - The type of the nested object.
 * @template Key - The key type of the nested object.
 */

export type PathToStrings<
	T,
	Key extends keyof any = keyof T,
> = Key extends string
	? Key extends keyof T
		? T[Key] extends string
			? Key
			: T[Key] extends Record<string, any>
			? `${Key}.${PathToStrings<T[Key], keyof T[Key]>}`
			: never
		: never
	: never
