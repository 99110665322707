import { api } from "../"
import { roomURL, roomsURL, scanRoomsURL } from "../../../api"
import { endScan, startScan } from "../../autoScanRooms/autoScanRoomsSlice"
import { PaginatedOptions } from "../../reduxUtils"
import {
	CheckScanRoomsResponse,
	RoomRequestWithKey,
	RoomResponse,
	RoomsFetchRequest,
	RoomsResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const rooms = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchRooms: builder.query<
			RoomsResponse & { offset: number },
			PaginatedOptions & RoomsFetchRequest
		>({
			query: ({ floor, building, ...options }) => {
				const params = {
					...(floor &&
						floor !== FilterSpecialValues.ALL && { floor_id: floor }),
					...(building &&
						building !== FilterSpecialValues.ALL && { building_id: building }),
					...options,
				}
				return {
					url: roomsURL({ ...params }),
				}
			},
			providesTags: (result, _error) =>
				result
					? [
							...result.results.map(({ key }) => ({
								type: "Rooms" as const,
								id: key,
							})),
							{ type: "Rooms", id: "LIST" },
					  ]
					: [{ type: "Rooms", id: "LIST" }],
			transformResponse: (response: RoomsResponse, _, arg) => {
				return {
					...response,
					offset: arg?.offset ?? 0,
				}
			},
		}),
		fetchRoom: builder.query<RoomResponse, string>({
			query: (key) => ({
				url: roomURL(key),
			}),
			providesTags: (_result, _error, key) => [{ type: "Rooms", id: key }],
		}),
		createRoom: builder.mutation<RoomResponse, { key: string }>({
			query: (body) => ({
				url: roomsURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Rooms", id: "LIST" }],
		}),
		updateRoom: builder.mutation<RoomResponse, RoomRequestWithKey>({
			query: ({ key, ...body }) => ({
				url: roomURL(key),
				method: "PUT",
				body: {
					key,
					...body,
				},
			}),
			invalidatesTags: (_result, _error, { key }) => [
				{ type: "Rooms", id: "LIST" },
				{ type: "Rooms", id: key },
			],
		}),
		destroyRoom: builder.mutation<void, string>({
			query: (key) => ({
				url: roomURL(key),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error) => [{ type: "Rooms", id: "LIST" }],
		}),
		startScanRooms: builder.mutation<void, void>({
			query: () => ({
				url: scanRoomsURL(),
				method: "POST",
			}),
		}),
		checkScanRooms: builder.query<CheckScanRoomsResponse, void>({
			query: () => ({
				url: scanRoomsURL(),
			}),
		}),
		startScanAndCheckRooms: builder.mutation<null, void>({
			queryFn: async (_arg, { dispatch }, _extraOptions, fetchWithBQ) => {
				dispatch(startScan())

				const response = await fetchWithBQ({
					url: scanRoomsURL(),
					method: "POST",
				})

				if (response.error) {
					dispatch(endScan())
					return {
						error: response.error,
					}
				}

				const scanStatus = setInterval(async () => {
					const response = await fetchWithBQ({
						url: scanRoomsURL(),
					})

					if (response.error) {
						dispatch(endScan())
						return {
							error: response.error,
						}
					}

					const { in_progress } = response.data as CheckScanRoomsResponse

					if (!in_progress) {
						dispatch(endScan())
						clearInterval(scanStatus)
						dispatch(api.util.invalidateTags(["Rooms"]))
					}
				}, 5000)

				return {
					data: null,
				}
			},
		}),
	}),
})

export const {
	useFetchRoomsQuery,
	useFetchRoomQuery,
	useCreateRoomMutation,
	useUpdateRoomMutation,
	useDestroyRoomMutation,
	useStartScanRoomsMutation,
	useCheckScanRoomsQuery,
	useStartScanAndCheckRoomsMutation,
} = rooms
