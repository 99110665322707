import { useCallback, useEffect, useState } from "react"

import * as serviceWorker from "../serviceWorkerRegistration"
import { isNative } from "../utils"

export const useCheckForSWUpdates = () => {
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
	const [showReload, setShowReload] = useState<boolean>(false)

	const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
		setShowReload(true)
		setWaitingWorker(registration.waiting)
	}, [])

	const reloadPage = useCallback(() => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" })
		setShowReload(false)
		// Anchors do not trigger a full page reload, so we need to return the URL without hash
		window.location.href = window.location.hash
			? window.location.href.split("#")[0]
			: window.location.href
	}, [waitingWorker])

	useEffect(() => {
		// JOAN-5820: Capacitor doesn't support service workers, no need to attempt in that case
		if (isNative()) {
			return
		}
		serviceWorker.register({
			onUpdate: onSWUpdate,
		})
	}, [onSWUpdate])
	return { showReload, waitingWorker, reloadPage }
}
