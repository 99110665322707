import React from "react"

import { useTranslation } from "react-i18next"

import { TopNav } from "./TopNav"

import { DeleteType } from "../../redux/reservations/types"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as DeleteSVG } from "../../assets/images/icons/Delete.svg"

import "./ReservationDeleteMenu.sass"

type ReservationOptionMenuProps = {
	onClick: (type: DeleteType) => void
	onClose: () => void
}

export const ReservationDeleteMenu: React.FC<ReservationOptionMenuProps> = ({
	onClick,
	onClose,
}) => {
	const { t } = useTranslation()

	return (
		<div className="ReservationDeleteMenu">
			<div className="picker-content">
				<div className="section-head">
					<TopNav
						backArrow={false}
						title={t("mobile.home.delete_recurring")}
						titleCenter={true}
						rightIcon={
							<CrossSVG
								onClick={() => {
									onClose()
								}}
							/>
						}
						onTopScreen={false}
					/>
				</div>
				<div
					className="section"
					onClick={() => {
						onClick(DeleteType.CURRENT)
					}}
				>
					<div className="option">
						<div className="option-icon">
							<DeleteSVG />
						</div>
						<div className="option-name">{t("mobile.home.delete_this")}</div>
					</div>
				</div>
				<div
					className="section"
					onClick={() => {
						onClick(DeleteType.CURRENT_FUTURE)
					}}
				>
					<div className="option">
						<div className="option-icon">
							<DeleteSVG />
						</div>
						<div className="option-name">
							{t("mobile.home.delete_this_following")}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
