import React, { useMemo } from "react"

import cn from "classnames"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { API_ROOT } from "../../../../api"
import { BILLING_PATHNAMES } from "../../Billing/constants"
import { ROOMS_PATHS } from "../constants"

import { useFetchPaymentsQuery } from "../../../../redux/api/billing/payments"
import { useFetchBuildingsQuery } from "../../../../redux/api/buildings"
import { useFetchCalendarsQuery } from "../../../../redux/api/calendars"
import { useFetchRoomsDevicesQuery } from "../../../../redux/api/devices"
import { useFetchRoomsQuery } from "../../../../redux/api/rooms"

import Accordion from "../../../../components/basic/Accordion"

import { ReactComponent as ErrorSVG } from "../../../../assets/images/icons/Error.svg"
import { ReactComponent as LoaderSVG } from "../../../../assets/images/icons/Loader.svg"
import { ReactComponent as SuccessSVG } from "../../../../assets/images/icons/RadioCheck.svg"
import { ReactComponent as RoomSVG } from "../../../../assets/images/icons/Room.svg"

import "./RoomSettingsDetails.sass"

const RoomSettingsDetails = () => {
	const { t } = useTranslation()

	const {
		data: { results: buildings = [] } = {},
		isFetching: areBuildingsLoading,
	} = useFetchBuildingsQuery({ limit: 1 })

	const {
		data: { results: calendars = [] } = {},
		isFetching: areCalendarsLoading,
	} = useFetchCalendarsQuery({ limit: 1 })
	const { data: { results: rooms = [] } = {}, isFetching: areRoomsLoading } =
		useFetchRoomsQuery({ limit: 1 })
	const {
		data: { results: devices = [] } = { limit: 1 },
		isFetching: areDevicesLoading,
	} = useFetchRoomsDevicesQuery({ limit: 1 })
	const { data: entry, isFetching: isBillingInfoLoading } =
		useFetchPaymentsQuery()
	const { billing_details } = entry ?? {}

	const hasBuilding = buildings.length > 0
	const hasCalendar = calendars.length > 0
	const hasRooms = rooms.length > 0
	const hasPairedDevices = devices.length > 0
	const hasBillingInfo = !!billing_details?.id

	const steps = useMemo(
		() => [
			{
				title: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.create_building.title",
				),
				descr: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.create_building.description",
				),
				path: "/settings/buildings",
				completed: hasBuilding,
				isLoading: areBuildingsLoading,
			},
			{
				title: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.connect_calendar.title",
				),
				descr: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.connect_calendar.description",
				),
				path: `${API_ROOT}/onboarding/calendar/select/`,
				completed: hasCalendar,
				isLoading: areCalendarsLoading,
			},
			{
				title: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.add_rooms.title",
				),
				descr: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.add_rooms.description",
				),
				path: ROOMS_PATHS.addRoom,
				completed: hasRooms,
				isLoading: areRoomsLoading,
			},
			{
				title: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.pair_devices.title",
				),
				descr: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.pair_devices.description",
				),
				path: ROOMS_PATHS.pair,
				completed: hasPairedDevices,
				isLoading: areDevicesLoading,
			},
			{
				title: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.provide_billing.title",
				),
				descr: t(
					"desktop.settings.rooms.overview.onboarding_details.steps.provide_billing.description",
				),
				path: BILLING_PATHNAMES.overview.companyDetails,
				completed: hasBillingInfo,
				isLoading: isBillingInfoLoading,
			},
		],
		[
			areBuildingsLoading,
			areCalendarsLoading,
			areDevicesLoading,
			areRoomsLoading,
			hasBillingInfo,
			hasBuilding,
			hasCalendar,
			hasPairedDevices,
			hasRooms,
			isBillingInfoLoading,
			t,
		],
	)

	return (
		<div className="RoomSettingsDetails">
			<div className="Description">
				<div>
					{t("desktop.settings.rooms.overview.onboarding_details.info")}
				</div>
				<RoomSVG />
			</div>

			<Accordion
				header={t("desktop.settings.rooms.overview.onboarding_details.title")}
				defaultOpen
			>
				<div className="Steps">
					{steps.map((s) => (
						<Step
							title={s.title}
							descr={s.descr}
							path={s.path}
							completed={s.completed}
							isLoading={s.isLoading}
						/>
					))}
				</div>
			</Accordion>
		</div>
	)
}

type StepProps = {
	title: string
	descr: string
	path: string
	completed: boolean
	isLoading: boolean
}
const Step = ({ title, descr, path, completed, isLoading }: StepProps) => {
	const statusIcon = completed ? <SuccessSVG /> : <ErrorSVG />

	const iconClassNames = cn({
		StepIcon: true,
		Loading: isLoading,
		Completed: completed,
		Uncompleted: !completed,
	})

	const titleClassNames = cn({
		StepTitle: true,
		Completed: completed,
	})

	const isCalendarURL = path.includes("/onboarding/calendar/select/")

	return (
		<>
			<div className="Step">
				<div className="StepHead">
					<div className={iconClassNames}>
						{isLoading ? <LoaderSVG /> : statusIcon}
					</div>
					{isCalendarURL ? (
						<a
							href={path}
							className={titleClassNames}
							target="_blank"
							rel="noopener noreferrer"
						>
							{title}
						</a>
					) : (
						<NavLink to={path} className={titleClassNames}>
							{title}
						</NavLink>
					)}
				</div>
				<div className="StepBody">{descr}</div>
			</div>
		</>
	)
}

export default RoomSettingsDetails
