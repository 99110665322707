import React, { PropsWithChildren } from "react"

import { ToastContainer } from "react-toastify"

import AppParamsProvider from "./AppParamsProvider"
import AppUpdateProvider from "./AppUpdateProvider"
import AuthProvider from "./AuthProvider"
import { CheckInProvider } from "./CheckInProvider"
import CompanyProvider from "./CompanyProvider"
import { InitializationProvider } from "./InitializationProvider"
import LoadingProvider from "./LoadingProvider"
import LocalizationProvider from "./LocalizationProvider"
import MobileCompanyProvider from "./MobileCompanyProvider"
import MobileLoadingProvider from "./MobileLoadingProvider"
import UserProvider from "./UserProvider"

import { selectIsMobile } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"

export function Providers({ children }: PropsWithChildren<unknown>) {
	const isMobile = useAppSelector(selectIsMobile)

	return (
		<>
			<AppParamsProvider>
				<LocalizationProvider>
					<AuthProvider>
						<UserProvider>
							<InitializationProvider>
								{isMobile ? (
									<>
										<MobileCompanyProvider />
										<MobileLoadingProvider>{children}</MobileLoadingProvider>
									</>
								) : (
									<>
										<CompanyProvider />
										<LoadingProvider>
											<AppUpdateProvider>
												<CheckInProvider>{children}</CheckInProvider>
											</AppUpdateProvider>
										</LoadingProvider>
									</>
								)}
							</InitializationProvider>
						</UserProvider>
					</AuthProvider>
				</LocalizationProvider>
			</AppParamsProvider>
			<ToastContainer />
		</>
	)
}
