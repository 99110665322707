import React, { useCallback, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { useToast } from "../../../../hooks/useToast"
import { ROOMS_PATHS } from "../constants"

import { usePairRoomDeviceMutation } from "../../../../redux/api/devices"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { getAppParams } from "../../../../redux/appParams/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import Field from "../../../../components/Field"
import PageForm from "../../../../components/Form/PageFormHook"
import { setErrors } from "../../../../components/Form/formUtils"
import { Input } from "../../../../components/basic/Input"
import MultiToggle from "../../../../components/basic/MultiToggle"

import "./PairDeviceForm.sass"

type FormValues = {
	pin: string
}

enum PairDeviceType {
	COMPANY_DEVICE = "company_device",
	TV_OR_BROWSER = "tv_or_browser",
	TABLET = "tablet",
}

const PairDeviceForm = () => {
	const history = useHistory()

	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { appName, appConfiguratorURL, appKnowledgeCenter } =
		useAppSelector(getAppParams)

	const [pairRoomDevice] = usePairRoomDeviceMutation()

	const [selectedDevice, setSelectedDevice] = useState(
		PairDeviceType.COMPANY_DEVICE,
	)

	const methods = useForm<FormValues>({
		defaultValues: {
			pin: "",
		},
	})
	const {
		control,
		setError,
		formState: { isSubmitting },
		reset,
	} = methods

	const handlePairRoomDevice = useCallback(
		async ({ pin }: FormValues) => {
			const response = await pairRoomDevice({ pin })

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
				}
				return
			}
			reset()
			history.push(ROOMS_PATHS.devices)
			infoToast(t("desktop.settings.rooms.devices.form.toasts.device_paired"))
		},
		[errorToast, history, infoToast, pairRoomDevice, reset, setError, t],
	)

	const handleChangeRoomDevice = (device: PairDeviceType) => {
		reset()
		setSelectedDevice(device)
	}

	return (
		<FormProvider {...methods}>
			<PageForm
				className="PairRoomDeviceForm"
				updateMode={false}
				onCreate={handlePairRoomDevice}
				backUrl={ROOMS_PATHS.devices}
				disabled={isSubmitting}
			>
				<div className="FieldWrapper">
					<label htmlFor="device_type" className="Label">
						{t("desktop.settings.rooms.devices.form.choose_device")}
					</label>
					<div className="switch">
						<MultiToggle
							options={[
								{
									label: t(
										"desktop.settings.rooms.devices.device_subtypes.company_device",
									),
									value: PairDeviceType.COMPANY_DEVICE,
								},
								{
									label: t(
										"desktop.settings.rooms.devices.device_subtypes.tv_or_browser",
									),
									value: PairDeviceType.TV_OR_BROWSER,
								},
								{
									label: t(
										"desktop.settings.rooms.devices.device_subtypes.tablet",
									),
									value: PairDeviceType.TABLET,
								},
							]}
							onChange={handleChangeRoomDevice}
							value={selectedDevice}
						/>
					</div>
				</div>

				<div className="info">
					<Trans
						i18nKey="desktop.settings.rooms.devices.form.pair_new_device_info_start"
						components={{
							a: (
								<a href={appConfiguratorURL} target="_blank" rel="noreferrer">
									{t(
										"desktop.settings.visitors.email_settings.knowledge_base_link_text",
									)}
								</a>
							),
						}}
						values={{
							appConfigurator: `${appName} Configurator`,
						}}
					></Trans>
				</div>

				<div className="info">
					<Trans
						i18nKey="desktop.settings.rooms.devices.form.pair_new_device_info_end"
						components={{
							a: (
								<a
									href={appKnowledgeCenter.howToAddDevices}
									target="_blank"
									rel="noreferrer"
								>
									{t(
										"desktop.settings.rooms.devices.form.pair_new_device_knowledge_base_link_text",
									)}
								</a>
							),
						}}
					></Trans>
				</div>

				<Field
					control={control}
					name="pin"
					label={t("desktop.settings.rooms.devices.form.device_pin")}
					rules={{
						minLength: {
							value: 6,
							message: t(
								"desktop.settings.rooms.devices.form.device_pin_too_short",
							),
						},
					}}
				>
					{(props) => <Input {...props} />}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default PairDeviceForm
