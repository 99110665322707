import { CSSProperties } from "react"

export const contentStyle: CSSProperties = {
	maxWidth: "90%",
	height: "auto",
	position: "absolute",
	top: "60px",
	left: "50vw",
	right: "none",
	bottom: "none",
	padding: "1rem",
	transform: "translate(-50%, 0)",
	boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
	borderRadius: "3px",
	border: "none",
	overflow: "auto",
}

export const overlayStyle: CSSProperties = {
	backgroundColor: "rgba(196, 196, 196, 0.2)",
	zIndex: 100,
}
