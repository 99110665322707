import {
	SliceState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { RoomResponse } from "./types"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

/**
 *  Slice
 */
export interface EventsState extends SliceState {
	entries: RoomResponse[]
}

const initialState: EventsState = {
	entries: [],
	...sliceInitialState,
}

const roomsSlice = createSlice({
	name: "rooms",
	initialState,
	reducers: {
		fetchRoomsStart: (state) => {
			state.isLoading = true
		},
		fetchRoomsError: (state, { payload }: PayloadAction<string>) => {
			state.isLoading = false
			state.isLoaded = false
			state.error = payload
		},
		fetchRoomsSuccess: (state, { payload }: PayloadAction<RoomResponse[]>) => {
			setFetchSuccessState(state)
			state.entries = payload
		},
	},
})

export const roomsReducer = roomsSlice.reducer

export const {
	fetchRoomsStart,
	fetchRoomsError,
	fetchRoomsSuccess,
} = roomsSlice.actions
