import { ReactNode } from "react"

import classNames from "classnames"

import { ReactComponent as EllipseSVG } from "../../../assets/images/icons/Ellipse.svg"
import { ReactComponent as LockSVG } from "../../../assets/images/icons/Lock.svg"

type OptionProps = {
	isActive?: boolean
	onClick?: () => void
	isDisabled?: boolean
	isLocked?: boolean
	name?: string
	children: ReactNode
}

const Option = ({
	children,
	isActive,
	onClick,
	isDisabled,
	isLocked,
	name,
}: OptionProps) => {
	const className = classNames({
		Option: true,
		isActive,
		isDisabled,
	})

	return (
		<div
			className={className}
			onClick={!isDisabled ? onClick : undefined}
			id={name}
		>
			<EllipseSVG className="radio" />{" "}
			<span className="label">
				{children} {isLocked && <LockSVG className="lock" />}
			</span>
		</div>
	)
}

export default Option
