import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { oAuthLogoutURL } from "../api"

import { logout } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Heading from "../components/Heading"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Button from "../components/basic/Button"

const AuthLogout = () => {
	const { t } = useTranslation()
	const { isMobile } = useAppSelector((state) => state.app)

	const [problem, setProblem] = useState(false)
	const history = useHistory()
	const actions = useActions({ logout: () => logout() })

	const goBack = () => {
		history.goBack()
	}

	useEffect(() => {
		const completeLogout = async () => {
			setProblem(false)

			try {
				const res = await actions.logout()

				if (logout.rejected.match(res)) {
					throw new Error(res.error.message)
				}

				if (isMobile) {
					history.push("/auth/start")
					window.location.reload()
					return
				}

				window.location.href = oAuthLogoutURL()
			} catch (e) {
				setProblem(true)
				toast.error(t("general.redirection.logout_unsuccesful"))
			}
		}

		completeLogout()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<RouteView className="Login">
			<Screen className="Logout">
				<Heading>{t("general.redirection.signed_out")}</Heading>
				<p>{t("general.redirection.redirecting")}</p>
				{problem && (
					<p>
						<Button isSmall={true} onClick={goBack}>
							{t("general.redirection.go_back")}
						</Button>
					</p>
				)}
			</Screen>
		</RouteView>
	)
}

export default AuthLogout
