export type UserData = {
	id?: number | string
	first_name?: string
	last_name?: string
	email?: string | null
}

export enum UserGroup {
	ADMIN = "portal_admin",
	OFFICE_MANAGER = "portal_officer_manager",
	USER = "portal_user",
}

type HasGroups = {
	groups?: string[]
}

export function formatUser(user?: UserData) {
	if (!user) {
		return "Unknown"
	}

	const { id, first_name, last_name, email } = user

	if (!!first_name && String(first_name) !== "") {
		if (!!last_name && String(last_name) !== "") {
			return `${first_name} ${last_name}`
		} else {
			return first_name
		}
	} else if (email) {
		return email
	} else if (email === null) {
		return "Anonymous"
	} else if (id) {
		return `Employee #${id}`
	} else {
		return "Unknown"
	}
}

export function isOfficeManager({ groups = [] }: HasGroups) {
	return groups.includes(UserGroup.OFFICE_MANAGER)
}

export function isPortalAdmin({ groups = [] }: HasGroups) {
	return groups.includes(UserGroup.ADMIN)
}

export function isNormalUser({ groups = [] }: HasGroups) {
	return groups.includes(UserGroup.USER)
}

export function usernameComparator(a: UserData, b: UserData) {
	if (!a || !b) {
		return 0
	}

	const aName = formatUser(a)
	const bName = formatUser(b)

	return aName.localeCompare(bName)
}
