import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { FetchOptions } from "../redux/users/types"

import PersonForm from "../components/Form/PersonForm"

type Props = {
	open: boolean
	person: any
	fetchOptions?: FetchOptions
	refetch?: () => void
}

export default function PersonModal({
	open,
	person,
	fetchOptions,
	refetch,
}: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<PersonForm
				person={person}
				fetchOptions={fetchOptions}
				refetch={refetch}
			/>
		</ReactModal>
	)
}
