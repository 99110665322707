import {
	ChangeEventHandler,
	HTMLAttributes,
	ReactChild,
	forwardRef,
} from "react"

import classNames from "classnames"

import { useId } from "../../../hooks/useId"

import "./style.sass"

export type SwitchProps = {
	value?: boolean
	onChange?: (v: boolean) => void
	label?: ReactChild
	disabled?: boolean
	altInput?: string
	id?: string
	subLabel?: ReactChild
	hasError?: boolean
	rightAlign?: boolean
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange">

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
	(
		{
			label,
			disabled,
			altInput = "switch",
			className,
			id,
			value,
			subLabel,
			hasError,
			onChange,
			rightAlign,
			...args
		},
		ref,
	) => {
		const componentId = useId(id)

		const labelClasses = classNames("Label", "SwitchLabel", {
			disabled,
			error: hasError,
		})
		const toggleClasses = classNames(className, "Switch", {
			disabled,
			rightAlign,
		})

		const innerOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
			onChange && onChange(e.target.checked)
		}

		const sanitizedValue = value ?? false

		return (
			<div className={toggleClasses}>
				{!rightAlign && (
					<label className="switcher">
						<input
							ref={ref}
							aria-label={altInput}
							type="checkbox"
							disabled={disabled}
							checked={sanitizedValue}
							id={componentId}
							onChange={innerOnChange}
							{...args}
						/>
						<div className="switcher-bg"></div>
						<div className="slider" />
					</label>
				)}
				{label && (
					<label className={labelClasses} htmlFor={componentId}>
						{label}
						{subLabel && <span className="SubText">{subLabel}</span>}
					</label>
				)}
				{rightAlign && (
					<label className="switcher">
						<input
							ref={ref}
							aria-label={altInput}
							type="checkbox"
							disabled={disabled}
							checked={sanitizedValue}
							id={componentId}
							onChange={innerOnChange}
							{...args}
						/>
						<div className="switcher-bg"></div>
						<div className="slider" />
					</label>
				)}
			</div>
		)
	},
)

export default Switch
