import { useState } from "react"

import classNames from "classnames"

import { useId } from "../../../hooks/useId"
import { Children } from "../../../types/sharedTypes"
import { Collapse } from "../Collapse"

import { ReactComponent as ChevronSVG } from "../../../assets/images/icons/Chevron.svg"

import "./style.sass"

interface AccordionProps {
	header: Children
	children: Children
	className?: string
	itemKey?: string
	defaultOpen?: boolean
}

const Accordion = ({
	header,
	children,
	className,
	itemKey,
	defaultOpen = false,
}: AccordionProps) => {
	const [isOpen, setIsOpen] = useState(defaultOpen)
	const key = useId(itemKey)

	const toggleAccordion = () => {
		setIsOpen((prev) => !prev)
	}

	const cn = classNames("Accordion", className)
	const cnHeader = classNames("Accordion__header", { isOpened: isOpen })

	return (
		<div className={cn}>
			<button type="button" className={cnHeader} onClick={toggleAccordion}>
				<span className="Accordion__header__content">{header}</span>
				<ChevronSVG className="Accordion__header__chevron" />
			</button>
			<Collapse
				isExtended={isOpen}
				itemKey={key}
				className="Accordion__content"
			>
				{children}
			</Collapse>
		</div>
	)
}

export default Accordion
