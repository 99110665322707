import React, { FC, useMemo } from "react"

import classNames from "classnames"

import { Desk } from "../../redux/shareable/types"

import "./PlaceDesk.sass"

type PlaceDeskProps = {
	desk: Desk
	mapHeight: number
	mapWidth: number
	index?: number
}
const PlaceDesk: FC<PlaceDeskProps> = ({
	desk,
	index,
	mapHeight,
	mapWidth,
}) => {
	const { coord_x, coord_y, reserved } = desk
	const isHighlighted: boolean = index !== undefined

	const classes = classNames({
		"desk-pointer": true,
		reserved: reserved,
		dim: !isHighlighted,
	})

	const placeStyle = useMemo(
		() => ({
			top: `${(coord_y / mapHeight) * 100}%`,
			left: `${(coord_x / mapWidth) * 100}%`,
		}),
		[coord_x, coord_y, mapHeight, mapWidth],
	)

	return (
		<div className={classes} style={placeStyle}>
			{index}
		</div>
	)
}

export default PlaceDesk
