import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import DropdownMulti from "../basic/DropdownMulti"

import { RoomDeviceResponse } from "../../redux/api/devices/types"

type Props = {
	value: string[]
	onChange: (v: string[]) => void
}

const DeviceBatteryStatusValues = {
	BATTERY_UNDER_75: "BATTERY_UNDER_75",
	BATTERY_UNDER_50: "BATTERY_UNDER_50",
	BATTERY_UNDER_25: "BATTERY_UNDER_25",
}

const DeviceWifiStatusValues = {
	STRONG_WIFI: "STRONG_WIFI",
	WEAK_WIFI: "WEAK_WIFI",
	DISCONNECTED: "DISCONNECTED",
}

export const DeviceStatusFilterValues = {
	NO_ROOMS: "NO_ROOMS",
	...DeviceBatteryStatusValues,
	...DeviceWifiStatusValues,
}

export const { BATTERY_UNDER_75, BATTERY_UNDER_50, BATTERY_UNDER_25 } =
	DeviceBatteryStatusValues

export const { STRONG_WIFI, WEAK_WIFI, DISCONNECTED } = DeviceWifiStatusValues

export const filterDevicesByBatteryLvl = (
	value: string,
	devices: RoomDeviceResponse[],
) => {
	switch (value) {
		case BATTERY_UNDER_25:
			return devices.filter((d) => d.battery < 25)
		case BATTERY_UNDER_75:
			return devices.filter((d) => d.battery < 75)
		case BATTERY_UNDER_50:
			return devices.filter((d) => d.battery < 50)
		default:
			return devices
	}
}

export const filterDevicesByWifiLvlConnection = (
	value: string,
	devices: RoomDeviceResponse[],
) => {
	switch (value) {
		case DISCONNECTED:
			return devices.filter(
				(d) => d.wifi_quality === null || d.wifi_quality < 1,
			)
		case WEAK_WIFI:
			return devices.filter(
				(d) =>
					d.wifi_quality !== null && d.wifi_quality >= 1 && d.wifi_quality < 60,
			)
		case STRONG_WIFI:
			return devices.filter(
				(d) => d.wifi_quality !== null && d?.wifi_quality >= 60,
			)
		default:
			return devices
	}
}

const DeviceStatusFilter = ({ value, onChange }: Props) => {
	const { t } = useTranslation()

	const options = Object.values(DeviceStatusFilterValues).map((value) => ({
		label: t(
			`desktop.settings.rooms.devices.filters.statuses.${value.toLowerCase()}` as ParseKeys,
		),
		value,
	}))

	return (
		<DropdownMulti
			className="device-status-filter"
			options={options}
			defaultLabel={t(
				"desktop.settings.rooms.devices.filters.statuses.all_statuses",
			)}
			optionsName={t("desktop.settings.rooms.devices.statuses")}
			values={value}
			onChange={onChange}
		/>
	)
}

export default DeviceStatusFilter
