import React from "react"

import dayjs from "dayjs"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { shortUserTimeFormat } from "../../../utils"

import { selectEvents } from "../../../redux/events/selectors"
import { EventResponse } from "../../../redux/events/types"
import { formatFloorLabel } from "../../../redux/floors/utils"
import { useAppSelector } from "../../../redux/reducers"
import { formatUser } from "../../../redux/user/utils"

import { CorrectIconMapper } from "../../../components/CorrectIconMapper"
import { EventActions } from "../../../components/Mobile/EventActions"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as ClockSVG } from "../../../assets/images/icons/Clock.svg"
import { ReactComponent as PeopleSVG } from "../../../assets/images/icons/People.svg"
import { ReactComponent as PersonSVG } from "../../../assets/images/icons/Person.svg"

import "./EventDetail.sass"

type ParamsType = {
	id: string
}

const EventDetail: React.FC = () => {
	const { t } = useTranslation()
	const { id } = useParams<ParamsType>()

	const { entries: events, isLoaded, isLoading } = useAppSelector(selectEvents)

	const event: EventResponse | undefined = events.find(
		(e: EventResponse) => e.id === id,
	)

	const attendees = []
	const location = []

	const now = dayjs()

	const start = dayjs(event?.start)
	const end = dayjs(event?.end)

	let minsBefore = 0
	if (now.isBefore(start)) {
		minsBefore = start.diff(now, "minute")
	}

	if (event) {
		attendees.push(
			...event.attendees
				.filter((a) => !event.organizer || a.mail !== event.organizer.email)
				.map((a) => ({ email: a.mail || a.mail, ...a }))
				.map((a) => formatUser(a)),
		)

		location.push(event.room.name)

		const building = event.room.locations.find((l) => l.type === 0)
		const floor = event.room.locations.find((l) => l.type === 1)

		if (building) {
			location.push(building.name)
		}
		if (floor) {
			location.push(formatFloorLabel(t("mobile.book.floor"), floor.name))
		}
	}

	return (
		<SafeViewArea className="EventDetail">
			<div className="head">
				<TopNav />
				<div className="icon">
					<div>
						<CorrectIconMapper iconType="room" needsWrap={false} />
					</div>
				</div>
			</div>
			<div className="body">
				{isLoaded && !isLoading && event && (
					<>
						<div className="main-data">
							<div className="info">
								<div className="type">{t("mobile.general.room")}</div>
								<div className="status">{t("mobile.home.reserved")}</div>
							</div>
							<div className="name">{event.summary}</div>
							<div className="location">{location.join(", ")}</div>
						</div>
						<div className="details">
							{event.organizer &&
								event.organizer.email !== event.room.email && (
									<div className="detail-row">
										<PersonSVG />
										<div className="organizer">
											{event.organizer.displayName || event.organizer.email}
										</div>
									</div>
								)}
							{attendees.length > 0 && (
								<div className="detail-row">
									<PeopleSVG />
									<div className="attendees">{attendees.join(", ")}</div>
								</div>
							)}
							<div className="detail-row">
								<CalendarSVG />
								<div>{start.format("dddd, MMM D")}</div>
							</div>
							<div className="detail-row">
								<ClockSVG />
								<div>
									{start.format(shortUserTimeFormat())}
									{" - "}
									{end.format(shortUserTimeFormat())}
									{minsBefore > 0 && minsBefore < 30 && (
										<span className="time-info">
											{" ("}
											<Trans i18nKey="mobile.home.starts_in" count={minsBefore}>
												starts in {{ minsBefore }} minutes
											</Trans>
											{")"}
										</span>
									)}
								</div>
							</div>
							<EventActions event={event} />
						</div>
					</>
				)}
				{isLoading && (
					<div className="loading">
						<Loader />
					</div>
				)}
			</div>
		</SafeViewArea>
	)
}

export default EventDetail
