import React, { useCallback, useState } from "react"

import { Dayjs } from "dayjs"

import Time from "./Time"

import { TimeslotResponse } from "../../../../redux/timeslots/types"

import { DateTimePicker as DateAndTimePicker } from "../../../../components/Mobile/DateTimePicker"
import { Drawer } from "../../../../components/Mobile/Drawer"

type DateTimeProps = {
	onConfirm: () => void
	editing: boolean
	handleClose: () => void
	date: Dayjs
	timeslot: Partial<TimeslotResponse> | null
	preferredTime: string
	onPick: (
		date: Dayjs | null,
		timeslot: Partial<TimeslotResponse> | null,
	) => void
	onCancelClick: () => void
}

const DateTimePicker = ({
	onConfirm,
	editing,
	handleClose,
	date,
	timeslot,
	preferredTime,
	onPick,
	onCancelClick,
}: DateTimeProps) => {
	const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(true)

	const onPickHandler = useCallback(
		(date: Dayjs | null, slot: Partial<TimeslotResponse> | null) => {
			onPick(date, slot)
			setIsDateTimePickerVisible(false)
		},
		[onPick],
	)

	const onCancelHandler = useCallback(() => {
		setIsDateTimePickerVisible(false)
		onCancelClick()
	}, [onCancelClick])

	return (
		<div>
			<Time
				onPicker={() => setIsDateTimePickerVisible(true)}
				onConfirm={onConfirm}
				editing={editing}
				handleClose={handleClose}
			/>
			<Drawer open={isDateTimePickerVisible}>
				<DateAndTimePicker
					onlyInput
					date={date}
					timeslot={timeslot}
					preferredTime={preferredTime}
					onPick={onPickHandler}
					onCancelClick={onCancelHandler}
				/>
			</Drawer>
		</div>
	)
}

export default DateTimePicker
