import React from "react"

import { Redirect, Route } from "react-router-dom"

import Help from "./Auth/Help"
import Login from "./Auth/Login"
import Start from "./Auth/Start"

import { AnimatedSwitch } from "../../components/Mobile/AnimatedSwitch"

const Profile: React.FC = () => {
	return (
		<div className="Profile">
			<AnimatedSwitch>
				<Route exact path="/auth/start" component={Start} />
				<Route exact path="/auth/login" component={Login} />
				<Route exact path="/auth/help" component={Help} />
				<Route
					exact
					path="/auth"
					render={() => <Redirect to="/auth/start" />}
				/>
			</AnimatedSwitch>
		</div>
	)
}

export default Profile
