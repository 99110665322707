/* eslint-disable jsx-a11y/accessible-emoji */
import classNames from "classnames"
import { Link, NavLink, useHistory, useRouteMatch } from "react-router-dom"

import LogoVariant from "../screens/LogoVariant"
import Avatar from "./advanced/Avatar"
import Clock from "./advanced/Clock"

import { selectNeedsOnboarding } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"
import { formatUser } from "../redux/user/utils"

import "./Header.sass"

type Props = {
	showNav?: boolean
	isCentered?: boolean
}

function Header({ showNav = true, isCentered }: Props) {
	const isFloorPlan = useRouteMatch("/manage/floor-plan")
	const isQRPrint = useRouteMatch("/qr-printer")
	const needsOnboarding = useAppSelector(selectNeedsOnboarding)
	const history = useHistory()
	const { location } = useHistory()
	const isVisitor = location.pathname.startsWith("/manage/visitor")

	const { isLoaded, user, showWeekends } = useAppSelector((state) => ({
		isLoaded: state.user.isLoaded,
		user: state.user.entry,
		showWeekends: state.app.showWeekends,
	}))

	const headerClassName = classNames({
		Header: true,
		isConstrained: (!showWeekends && !isFloorPlan) || isVisitor,
		isCentered,
	})

	if (isQRPrint) {
		return null
	}

	function handleHomeClick() {
		history.push("/manage")
	}

	return (
		<div className={headerClassName}>
			<div className="row">
				<div className="logo-box" onClick={handleHomeClick}>
					<LogoVariant variant="landscape" />
				</div>

				{showNav && !needsOnboarding && (
					<div className="nav">
						<NavLink to="/manage">Schedule</NavLink>
						<NavLink to="/settings">Settings</NavLink>
					</div>
				)}

				{!isCentered && <div className="stretch" />}

				<Clock />
				{isLoaded && (
					<Link className="user" to={"/settings/profile"} title="Log out">
						<Avatar user={user} size="medium" />
						<span className="name">{formatUser(user)}</span>
					</Link>
				)}
			</div>
		</div>
	)
}

export default Header
