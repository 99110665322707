import { api } from "../.."
import { invoicesURL } from "../../../../api"
import { PaginatedOptions } from "../../../reduxUtils"
import { InvoicesResponse } from "./types"

const FETCH_DEFAULTS = {
	limit: 20,
	offset: 0,
}

export const invoices = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchInvoices: builder.query<
			InvoicesResponse & { offset: number },
			PaginatedOptions | void
		>({
			query: (options) => invoicesURL({ ...FETCH_DEFAULTS, ...options }),
			providesTags: ["Invoices"],
			transformResponse: (response: InvoicesResponse, meta, arg) => {
				return { ...response, offset: arg?.offset ?? 0 }
			},
		}),
	}),
})

export const { useFetchInvoicesQuery } = invoices
