import { useTranslation } from "react-i18next"

import { Label } from "../Field/Label"
import SortableCardsContainer from "../SortableCardsContainer"
import ButtonSelect from "../basic/ButtonSelect"
import { Input } from "../basic/Input"

import { Answer, ScreeningsQuestion } from "../../redux/screenings/types"

import Button from "../../components/basic/Button"

import "./HealthEditQuestions.sass"

type HealthQuestionProps = {
	question: ScreeningsQuestion
	index: number
	onChange: (v: ScreeningsQuestion, i: number) => void
}

const HealthQuestion = ({ question, index, onChange }: HealthQuestionProps) => {
	const { t } = useTranslation()

	const handleQuestionChange = (editedQuestion: string) => {
		const updatedQuestion: ScreeningsQuestion = {
			...question,
			question: editedQuestion,
		}
		onChange(updatedQuestion, index)
	}

	const handleAnswerChange = (answer: Answer) => {
		const updatedQuestion: ScreeningsQuestion = {
			...question,
			positive_answer: answer,
		}
		onChange(updatedQuestion, index)
	}

	return (
		<div className="HealthQuestion">
			<div className="Question">
				<Input
					rows={1}
					value={question.question}
					onChange={handleQuestionChange}
				/>
			</div>
			<ButtonSelect
				options={[
					{
						label: t("desktop.manage.visitors.health_screening.answers.yes"),
						value: Answer.YES,
					},
					{
						label: t("desktop.manage.visitors.health_screening.answers.no"),
						value: Answer.NO,
					},
				]}
				value={question.positive_answer as Answer}
				onChange={handleAnswerChange}
			/>
		</div>
	)
}
type Props = {
	questions: ScreeningsQuestion[]
	onChange: (v: ScreeningsQuestion[]) => void
}

export const HealthEditQuestions = ({ questions, onChange }: Props) => {
	const { t } = useTranslation()

	const handleOnFieldDeleted = (position: number) => {
		onChange(questions.filter((_, index) => index !== position))
	}

	const handleOnFieldSorted = (
		sourceIndex: number,
		destinationIndex: number,
	) => {
		const currentFieldList = Array.from(questions)
		const [reorderedField] = currentFieldList.splice(sourceIndex, 1)
		currentFieldList.splice(destinationIndex, 0, reorderedField)
		onChange(currentFieldList)
	}

	const handleQuestionEdit = (question: ScreeningsQuestion, index: number) => {
		const updatedQuestions: ScreeningsQuestion[] = [...questions]
		updatedQuestions[index] = {
			...updatedQuestions[index],
			question: question.question,
			positive_answer: question.positive_answer,
		}

		onChange(updatedQuestions)
	}

	const handleAddClick = () => {
		const currentQuestions = [...questions]
		currentQuestions.push({
			question: "",
			positive_answer: Answer.NO,
		})

		onChange(currentQuestions)
	}

	return (
		<div className="HealthEditQuestions">
			<div className="Questions">
				<Label>
					{t("desktop.manage.visitors.health_screening.form.questions")}
					<div className="description">
						{t(
							"desktop.manage.visitors.health_screening.form.people_with_matching_answers_able_to_enter_edit_questions",
						)}
					</div>
				</Label>
				<SortableCardsContainer
					onCardDeleted={handleOnFieldDeleted}
					onCardSorted={handleOnFieldSorted}
				>
					{questions.map((q, i) => (
						<HealthQuestion
							key={`question-${i}`}
							question={q}
							index={i}
							onChange={handleQuestionEdit}
						/>
					))}
				</SortableCardsContainer>
			</div>
			<hr />
			<div className="Action">
				<Button onClick={handleAddClick} variant="secondary">
					{t("desktop.manage.visitors.health_screening.form.add_new_question")}
				</Button>
			</div>
		</div>
	)
}
