import { ThunkApiConfig } from "RootType"

import {
	deleteJSON,
	get,
	inviteCheckinURL,
	inviteReinviteURL,
	inviteURL,
	invitesURL,
	postJSON,
	putJSON,
	reprintURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import { inviteRemove } from "../invites/invitesSlice"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { InviteCancelRequest, InviteRequest, InviteResponse } from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchInvite = createAsyncThunk<
	InviteResponse,
	string,
	ThunkApiConfig
>("invite/fetch", async (id, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(inviteURL(id), {}, access_token)

	if (response.ok) {
		return await response.json()
	}
	return rejectWithValue(await getErrorMessage(response))
})

export const createInvite = createAsyncThunk<
	InviteResponse,
	InviteRequest,
	ThunkApiConfig<ResponseError>
>("invite/create", async (payload, { getState, rejectWithValue }) => {
	const { access_token } = getState().auth

	const response = await postJSON(invitesURL(), { body: payload }, access_token)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

type UpdateInviteProps = InviteRequest & {
	id: string
}

export const updateInvite = createAsyncThunk<
	InviteResponse,
	UpdateInviteProps,
	ThunkApiConfig<ResponseError>
>(
	"invite/update",
	async ({ id, ...payload }, { getState, rejectWithValue }) => {
		const { access_token } = getState().auth

		const response = await putJSON(
			inviteURL(id),
			{ body: payload },
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export const checkinInvite = createAsyncThunk<
	InviteResponse,
	string,
	ThunkApiConfig
>("invite/checkin", async (id, { getState }) => {
	const { access_token } = getState().auth

	const response = await postJSON(inviteCheckinURL(id), undefined, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const inviteReinvite = createAsyncThunk<void, string, ThunkApiConfig>(
	"invite/reinvite",
	async (id, { getState }) => {
		const { access_token } = getState().auth

		const response = await postJSON(
			inviteReinviteURL(id),
			undefined,
			access_token,
		)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

type CancelInviteProps = InviteCancelRequest & {
	id: string
}

export const cancelInvite = createAsyncThunk<
	void,
	CancelInviteProps,
	ThunkApiConfig
>("invite/cancel", async ({ id, ...payload }, { getState, dispatch }) => {
	const { access_token } = getState().auth

	const response = await deleteJSON(
		inviteURL(id),
		{ body: payload },
		access_token,
	)

	if (response.ok) {
		dispatch(inviteRemove(id))
		return
	}

	throw new Error(await getErrorMessage(response))
})

export const invitesReprintBadge = createAsyncThunk<
	void,
	string,
	ThunkApiConfig
>("invite/reprint", async (id, { getState }) => {
	const { access_token } = getState().auth

	const response = await postJSON(reprintURL(id), undefined, access_token)

	if (response.ok) {
		return
	}

	throw new Error(await getErrorMessage(response))
})

export interface InvitesState extends SliceState {
	entry?: InviteResponse
}

const initialState: InvitesState = {
	...sliceInitialState,
	entry: undefined,
}

const inviteSlice = createSlice({
	name: "invite",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchInvite.fulfilled, (state, { payload }) => {
			state.entry = payload

			setFetchSuccessState(state)
			return state
		})

		builder.addCase(inviteReinvite.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})

		builder.addMatcher(
			isAnyOf(inviteReinvite.fulfilled, invitesReprintBadge.fulfilled),
			(state) => {
				setSubmitSuccessState(state)
			},
		)
		builder.addMatcher(
			isAnyOf(
				createInvite.fulfilled,
				updateInvite.fulfilled,
				checkinInvite.fulfilled,
			),
			(state, { payload }) => {
				state.entry = payload

				setSubmitSuccessState(state)
				return state
			},
		)
		builder.addMatcher(isAnyOf(fetchInvite.pending), (state) => {
			state.isLoading = true
		})
		builder.addMatcher(
			isAnyOf(
				createInvite.pending,
				updateInvite.pending,
				checkinInvite.pending,
				cancelInvite.pending,
				inviteReinvite.pending,
				invitesReprintBadge.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(isAnyOf(fetchInvite.rejected), (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addMatcher(
			isAnyOf(
				createInvite.rejected,
				updateInvite.rejected,
				checkinInvite.rejected,
				cancelInvite.rejected,
				inviteReinvite.rejected,
				invitesReprintBadge.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
	},
})

export const inviteReducer = inviteSlice.reducer
