import { ReactNode } from "react"

import classNames from "classnames"

import "./SafeViewArea.sass"

type SafeViewAreaProps = {
	children: ReactNode
	className?: string
}
const SafeViewArea = ({ children, className }: SafeViewAreaProps) => {
	const safeViewAreaClass = classNames("safe-view-area", className)
	return <div className={safeViewAreaClass}>{children}</div>
}

export default SafeViewArea
