import React from "react"

import classNames from "classnames"

import "./Drawer.sass"

type DrawerProps = {
	open: boolean
}

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
	open,
	children,
}) => {
	const drawerClass = classNames({
		Drawer: true,
		open,
	})

	return (
		<div className={drawerClass}>
			<div className="drawer-content">{children}</div>
		</div>
	)
}
