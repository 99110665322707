import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { ParseKeys } from "i18next"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import { useToast } from "../../../hooks/useToast"
import VisitDeleteModal from "../../../modals/Visitors/VisitDeleteModal"
import { VisitExportModal } from "../../../modals/Visitors/VisitExportModal"
import { generateString, updateHistory } from "../../../utils"
import {
	ENTRIES_PER_PAGE,
	VISITORS_FILTERS_STORE_NAME,
	VISITORS_VISITS_PATHNAME,
} from "../constants"
import VisitorsFilters, { Filter } from "./VisitorsFilters"
import { useModals } from "@mattjennings/react-modal-stack"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { selectBuildingsWithVM } from "../../../redux/buildings/selectors"
import { getInvitesTotal } from "../../../redux/invites/invitesSlice"
import { selectInvites } from "../../../redux/invites/selectors"
import { FetchOptions as InvitesFetchOptions } from "../../../redux/invites/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { formatUser, isNormalUser } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"
import {
	checkoutVisitor,
	visitorDocument,
	visitsReprintBadge,
} from "../../../redux/visitor/visitorSlice"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { NewVisitsCreatedByOption } from "../../../redux/visitor_settings/types"
import { fetchVisitorSettings } from "../../../redux/visitor_settings/visitorSettingsSlice"
import { selectVisitors } from "../../../redux/visitors/selectors"
import {
	Host,
	VisitorListResponse,
	FetchOptions as VisitorsFetchOptions,
} from "../../../redux/visitors/types"
import {
	fetchVisitors,
	getVisitorsTotal,
} from "../../../redux/visitors/visitorsSlice"

import Datestring from "../../../components/Datestring"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Heading from "../../../components/Heading"
import { DayNav } from "../../../components/Manage/CalNav"
import WeekendToggle from "../../../components/Manage/WeekendToggle"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import { Tooltip } from "../../../components/Tooltip"
import View from "../../../components/View"
import Table from "../../../components/advanced/Table"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"
import MultiToggle from "../../../components/basic/MultiToggle"
import {
	Action,
	Column,
	DEFAULT_EMPTY_TABLE_CELL,
} from "../../../components/basic/Table"

import { ReactComponent as CheckSVG } from "../../../assets/images/icons/Check.svg"
import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

// import { ReactComponent as ChairSVG } from "../../../assets/Icon/Chair.svg"
// import { ReactComponent as DocumentCheckSVG } from "../../../assets/Icon/DocumentCheck.svg"
// import { ReactComponent as HealthSVG } from "../../../assets/Icon/Health.svg"
// import { ReactComponent as LoginSVG } from "../../../assets/Icon/Login.svg"
// import { ReactComponent as LogoutSVG } from "../../../assets/Icon/Logout.svg"
// import { ReactComponent as PersonSVG } from "../../../assets/Icon/Person.svg"
import "./Visitors.sass"

const { stringify } = queryString

enum TabType {
	VISITS = "visits",
	INVITES = "invites",
}

type StatusCellProps = {
	confirmed: boolean
	status: string
	tooltipContent?: string
}

const StatusCell = ({
	confirmed,
	status,
	tooltipContent = undefined,
}: StatusCellProps) => {
	const { t } = useTranslation()

	return (
		<>
			{confirmed && tooltipContent ? (
				<Tooltip uniqueId={generateString(4)} content={`${tooltipContent}`}>
					<div className="StatusCell">
						<CheckSVG />
						{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
					</div>
				</Tooltip>
			) : confirmed && !tooltipContent ? (
				<div className="StatusCell">
					<CheckSVG />
					{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
				</div>
			) : (
				<div className="StatusCell">
					<CrossSVG />
					{t(`desktop.manage.visitors.table.cell.${status}` as ParseKeys)}
				</div>
			)}
		</>
	)
}

type NameCellProps = {
	name: string
	host?: Host
}

const NameCell = ({ name, host }: NameCellProps) => {
	const { t } = useTranslation()

	return (
		<div className="NameCell">
			<div className="visitor">
				<div className="name">{name}</div>
				<div className="host">
					{t("desktop.manage.visitors.table.cell.host")}:{" "}
					<b>{formatUser(host)}</b>
				</div>
			</div>
		</div>
	)
}

/**
 * ManageVisitors component
 */

const ManageVisitors = () => {
	const history = useHistory()
	const { search, pathname } = history.location
	const { t } = useTranslation()
	const { openModal } = useModals()
	const { infoToast, errorToast } = useToast()

	const { currentDate, showWeekends } = useAppSelector(selectAppDates)
	const { entry: user } = useAppSelector(selectUser)
	const {
		entries: tableData,
		count: visitorsCount,
		offset,
		isLoaded,
		total: visitorTotal,
	} = useAppSelector(selectVisitors)
	const { total: invitesTotal } = useAppSelector(selectInvites)
	const { entries: visitorSettings, isLoaded: settingsLoaded } = useAppSelector(
		selectVisitorSettings,
	)
	const { entries: buildingsWithVM } = useAppSelector(selectBuildingsWithVM)
	const start = currentDate.startOf("day").toISOString()
	const end = currentDate.endOf("day").toISOString()

	const defaultFilter: Filter = {
		building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
		show: "all",
		search: "",
		page: 1,
	}

	const [storedFilterValues, saveFilter] = useStoredFilter({
		filterName: VISITORS_FILTERS_STORE_NAME,
		defaultFilterValues: defaultFilter,
	})

	const reqParams = useRef<Filter>(storedFilterValues)

	const [building, setBuilding] = useState(storedFilterValues.building_id)

	const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
		useBackendPagination({
			offset,
			totalNumberOfItems: visitorsCount,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 7,
			maxTrailingLinks: 2,
		})

	const actions = useActions({
		fetchVisitors: (params: VisitorsFetchOptions) =>
			fetchVisitors({ ...params, limit: ENTRIES_PER_PAGE }),
		getInvitesTotal: (params: InvitesFetchOptions) => getInvitesTotal(params),
		checkoutVisitor: (id: string) => checkoutVisitor(id),
		fetchVisitorSettings: () => fetchVisitorSettings(),
		visitorDocument: (id: string) => visitorDocument(id),
		visitReprint: (id: string) => visitsReprintBadge(id),
		toggleWeekends: () => toggleWeekends(),
		getVisitorsTotal: (params: VisitorsFetchOptions) =>
			getVisitorsTotal(params),
	})

	// we need to check if the building exist in the buildings VM list as non existing building can trigger 402 error
	if (reqParams.current.building_id !== FilterSpecialValues.ALL) {
		reqParams.current.building_id = buildingsWithVM.find(
			(b) => reqParams.current.building_id === b.id,
		)
			? reqParams.current.building_id
			: FilterSpecialValues.ALL
	}

	const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
		reqParams: reqParams,
		path: VISITORS_VISITS_PATHNAME,
		fetchCall: actions.fetchVisitors,
		fetchOptions: { start, end },
		offset,
	})

	const editVisit = useCallback(
		(v: VisitorListResponse) =>
			history.push(`/manage/visitors/visits/edit/${v.id}`),
		[history],
	)

	const getVisitorsCount = useCallback(
		({ building_id }: Filter) => {
			actions.getVisitorsTotal({ building_id, start, end })
		},
		[actions, start, end],
	)

	const tableColumns = useMemo<Column<VisitorListResponse>[]>(() => {
		return [
			{
				field: "full_name",
				label: "",
				renderCell: (v) => {
					return <NameCell name={v.full_name} host={v.host} />
				},
			},
			{
				field: "health_screening_passed",
				label: t("desktop.manage.visitors.table.health_screening"),
				renderCell: (v) => {
					if (
						v.health_screening_passed !== undefined &&
						v.health_screening_passed !== null
					) {
						return (
							<StatusCell
								confirmed={v.health_screening_passed || false}
								status={v.health_screening_passed ? "passed" : "failed"}
							/>
						)
					}
					return DEFAULT_EMPTY_TABLE_CELL
				},
			},
			{
				field: "document_signed",
				label: t("desktop.manage.visitors.table.documents"),
				renderCell: (v) => {
					if (v.document_signed !== undefined && v.document_signed !== null) {
						return (
							<StatusCell
								confirmed={v.document_signed || false}
								status={v.document_signed ? "signed" : "not_signed"}
								tooltipContent={v.document_name}
							/>
						)
					}
					return DEFAULT_EMPTY_TABLE_CELL
				},
			},
			{
				field: "checkin_at",
				label: t("desktop.manage.visitors.table.checked_in"),
				renderCell: (v) => {
					return dayjs(v.checkin_at).format("LT")
				},
			},
			{
				field: "checkout_at",
				label: t("desktop.manage.visitors.table.checked_out"),
				renderCell: (v) => {
					return v.checkout_at
						? dayjs(v.checkout_at).format("LT")
						: DEFAULT_EMPTY_TABLE_CELL
				},
			},
			{
				field: "desk",
				label: t("desktop.manage.visitors.table.assigned_desk"),
				renderCell: (v) => {
					return v.desk?.name || DEFAULT_EMPTY_TABLE_CELL
				},
			},
		]
	}, [t])

	const rowActions = useMemo<Action<VisitorListResponse>[]>(() => {
		return [
			{
				label: t("desktop.manage.visitors.table.cell.action.edit_visitor"),
				onClick: editVisit,
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.reprint_badge"),
				onClick: async (v) => {
					const response = await actions.visitReprint(v.id)

					if (visitsReprintBadge.fulfilled.match(response)) {
						infoToast(t("desktop.manage.visitors.reprint_success"))
					} else {
						errorToast(response?.error?.message)
					}
				},
			},
			{
				label: (v) => {
					if (v.document_signed) {
						return t("desktop.manage.visitors.table.cell.action.download_doc")
					}
					return ""
				},
				onClick: async (v) => {
					const fullName = v.full_name
					const pdf = await actions.visitorDocument(v.id)

					if (visitorDocument.fulfilled.match(pdf)) {
						await saveAs(pdf.payload, `Signed document (${fullName}).pdf`)
						infoToast(t("desktop.manage.visitors.document_downloaded_success"))
					} else {
						errorToast(pdf.payload)
					}
				},
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.check_out"),
				onClick: async (v) => {
					const response = await actions.checkoutVisitor(v.id)

					if (checkoutVisitor.fulfilled.match(response)) {
						infoToast(t("desktop.manage.visitors.visitor_checked_out"))
						actions.fetchVisitors({
							building_id: reqParams.current.building_id,
							show: reqParams.current.show,
							start,
							end,
							search: reqParams.current.search,
						})
					} else {
						errorToast(response.error.message)
					}
				},
			},
			{
				label: t("desktop.manage.visitors.table.cell.action.delete_visitor"),
				onClick: (visitor) =>
					openModal(VisitDeleteModal, {
						visitor,
						fetchParams: {
							building_id: reqParams.current.building_id,
							show: reqParams.current.show,
							start,
							end,
							search: reqParams.current.search,
						},
					}),
			},
		]
	}, [t, editVisit, actions, infoToast, errorToast, start, end, openModal])

	// const tableFooterCells = [
	// 	<div className="FooterCell">
	// 		<PersonSVG />
	// 		<b>1</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.visitors")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<HealthSVG />
	// 		<b>3/4</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.passed")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		{" "}
	// 		<DocumentCheckSVG />
	// 		<b>1/2</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.signed")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<LoginSVG />
	// 		<b>1</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.checked_in")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<LogoutSVG />
	// 		<b>1</b>{" "}
	// 		<span>{t("desktop.manage.visitors.table.summary.checked_out")}</span>
	// 	</div>,
	// 	<div className="FooterCell">
	// 		<ChairSVG />
	// 		<b>1</b> <span>{t("desktop.manage.visitors.table.summary.desks")}</span>
	// 	</div>,
	// ]

	const onTabChangeHandler = (tab: TabType) => {
		history.push({
			pathname: `/manage/visitors/${tab}`,
			search: stringify({ building_id: reqParams.current.building_id }),
		})
	}

	const handleNewClick = () => {
		history.push("/manage/visitors/visits/add")
	}

	const handleExportClick = () => {
		openModal(VisitExportModal, {
			range: { from: currentDate, to: currentDate },
			buildingId: reqParams.current.building_id,
			show: reqParams.current.show,
			search: reqParams.current.search,
		})
	}

	const handleFilterChange = useCallback(
		async (filter: Filter) => {
			if (
				reqParams.current.building_id !== filter.building_id &&
				(filter.search !== "" || filter.show !== "all")
			) {
				getVisitorsCount(filter)
			}
			reqParams.current = { ...filter, page: 1 }
			updateHistory(pathname, reqParams.current)
			setBuilding(filter.building_id)
			fetchDataPagination()
		},

		[getVisitorsCount, fetchDataPagination, pathname, setBuilding],
	)

	const isAdminManager = !isNormalUser(user)

	useEffect(() => {
		updateHistory(pathname, reqParams.current)
		fetchDataPagination()

		if (reqParams.current.search !== "" || reqParams.current.show !== "all") {
			getVisitorsCount(reqParams.current)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, start])

	// it will navigate to the first page when there is no results on higher pages
	useEffect(() => {
		if (isLoaded && visitorsCount > 0 && tableData.length === 0 && page > 1) {
			setPage(1)
		}
	}, [visitorsCount, isLoaded, page, tableData.length, setPage])

	useEffect(() => {
		actions.getInvitesTotal({
			building_id: building,
			limit: 0,
			start,
			end,
		})
	}, [actions, building, start, end])

	useEffect(() => {
		if (!settingsLoaded) {
			actions.fetchVisitorSettings()
		}
	}, [actions, settingsLoaded])

	// saves the current params into the locale store
	useEffect(
		() => () => {
			saveFilter(reqParams.current)
		},
		[saveFilter, reqParams.current],
	)

	return (
		<View className="ManageVisitors">
			{!settingsLoaded ? (
				<div className="loading">
					<Loader />
				</div>
			) : (
				<>
					<Heading>
						<DayNav noMaxDateSet />

						<span className="date-range">
							<Datestring date={currentDate} isLong showYear isInline />
						</span>

						<WeekendToggle
							showWeekends={showWeekends}
							onToggle={actions.toggleWeekends}
						/>

						<div className="space"></div>

						{isAdminManager && (
							<Button
								onClick={handleExportClick}
								variant="secondary-white"
								isSmall
							>
								{t("desktop.manage.visitors.buttons.export_csv")}
							</Button>
						)}
						{(isAdminManager ||
							(visitorSettings?.length &&
								visitorSettings
									.filter((setting) => setting.enabled)
									.some(
										(setting) =>
											setting.create_visits_by !==
											NewVisitsCreatedByOption.OFFICE_MANAGER_AND_ADMIN,
									))) && (
							<Button isSmall onClick={handleNewClick}>
								{t("desktop.manage.visitors.buttons.new_visitor")}
							</Button>
						)}
					</Heading>

					<Space size={0.5} />

					<MultiToggle
						options={[
							{
								label: (
									<span>
										{t("desktop.manage.visitors.tabs.visitors")}
										{" · "}
										{visitorTotal}
									</span>
								),
								value: TabType.VISITS,
							},
							{
								label: (
									<span>
										{t("desktop.manage.visitors.tabs.invites")}
										{" · "}
										{invitesTotal}
									</span>
								),
								value: TabType.INVITES,
							},
						]}
						onChange={onTabChangeHandler}
						value={TabType.VISITS}
					/>

					<Space size={0.5} />

					<VisitorsFilters
						onChange={handleFilterChange}
						defaultValues={storedFilterValues}
					/>

					<Space size={0.5} />

					<Table
						showRowNumber
						calcRowNumber={calcRowNumber}
						loading={false}
						rows={tableData}
						columns={tableColumns}
						onRowClick={editVisit}
						rowActions={rowActions}
						pagination={
							paginationLinks.length > 1 ? (
								<Pagination
									links={paginationLinks}
									setPage={setPage}
									onPrevious={() => setPage(page - 1)}
									onNext={() => setPage(page + 1)}
									hasNext={hasNext}
									hasPrevious={hasPrevious}
									from={from}
									to={to}
									total={visitorsCount}
									items={t("desktop.manage.visitors.table.visits")}
								/>
							) : undefined
						}
						// footerCells={tableFooterCells}
					/>
				</>
			)}
		</View>
	)
}

export default ManageVisitors
