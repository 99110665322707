import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import { OptionType } from "../../../types/sharedTypes"
import { Tooltip } from "../Tooltip"

import "./style.sass"

export type MultiToggleProps<T extends string> = {
	value: string
	onChange: (v: T) => void
	options: OptionType<T>[]
}

export default function MultiToggle<T extends string>({
	value,
	onChange,
	options,
}: PropsWithChildren<MultiToggleProps<T>>) {
	const innerOnChange = (value: T) => {
		onChange(value)
	}

	return (
		<div className="MultiToggle">
			{options.map((option) => (
				<Tooltip
					key={option.value}
					uniqueId={option.value}
					content={option?.disabled ? option?.tooltip : null}
				>
					<button
						type="button"
						className={classNames("Toggle", {
							Active: option.value === value,
							Disabled: option?.disabled,
						})}
						disabled={option?.disabled}
						onClick={() => innerOnChange(option.value)}
					>
						{option.label}
					</button>
				</Tooltip>
			))}
		</div>
	)
}
