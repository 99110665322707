import React, { useEffect } from "react"

import queryString from "query-string"
import { useLocation } from "react-router-dom"
import { Redirect } from "react-router-dom"

import { getAppParams } from "../redux/appParams/selectors"
import { fetchAuthCodeSuccess, setIsLogin } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Heading from "../components/Heading"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"

const { parse } = queryString

function AuthCallback() {
	const oldCode = useAppSelector((state) => state.auth.code)
	const actions = useActions({
		fetchAuthCodeSuccess: (code: string) => fetchAuthCodeSuccess(code),
		setIsLoginTrue: () => setIsLogin(true),
	})

	const { search } = useLocation()
	const { code: newCode, state: redirectTo } = parse(search)

	const { appName } = useAppSelector(getAppParams)

	// fix for Cannot update a component (`AppParamsProvider`) while rendering a different component (`AuthCallback`)
	useEffect(() => {
		if (oldCode !== newCode && typeof newCode === "string") {
			actions.fetchAuthCodeSuccess(newCode)
		}
	}, [actions, newCode, oldCode])

	useEffect(() => {
		if (newCode) {
			actions.setIsLoginTrue()
		}
	}, [actions, newCode])

	if (newCode) {
		return <Redirect to={(redirectTo as string) || "/"} />
	} else {
		return (
			<RouteView className="AuthCallback">
				<Screen>
					<Heading>Authorization failed</Heading>
					<p>Code missing in reply from {appName} Portal.</p>
				</Screen>
			</RouteView>
		)
	}
}

export default AuthCallback
