import { objectValuesToOptions } from "../../../utils"

export const INDUSTRIES = {
	HEALTHCARE_SERVICES: "Healthcare Services",
	TRANSPORTATION_LOGISTICS: "Transportation & Logistics",
	FACILITY_MANAGEMENT: "Facility Management",
	ARCHITECTURE_INTERIOR_DESIGN: "Architecture & Interior Design",
	AV_RESELLER_DISTRIBUTOR: "AV Reseller & Distributor",
	COMMUNICATIONS: "Communications",
	CONSTRUCTION: "Construction",
	EDUCATION: "Education",
	FINANCIAL_SERVICES: "Financial Services",
	IT_DEVELOPMENT_SERVICES: "IT Development Services",
	MANUFACTURING: "Manufacturing",
	PUBLIC_ADMINISTRATION: "Public Administration",
	REAL_ESTATE: "Real Estate",
	RETAIL: "Retail",
	NGO: "NGO",
	CIVIL_DEFENSE: "Civil Defense",
	COWORKING: "Coworking",
	ELECTRONICS_RETAIL: "Electronics Retail",
	ECOMMERCE: "Ecommerce",
	SMART_BUILDING_SOLUTIONS: "Smart Building Solutions",
	DIGITAL_SIGNAGE: "Digital Signage",
	ENTREPRENEUR_STARTUP_ECOSYSTEM: "Entrepreneur/startup ecosystem",
	ISP_TELECOMMUNICATIONS: "ISP & Telecommunications",
	SOFTWARE_DEVELOPMENT: "Software Development",
	LEGAL_SERVICES: "Legal Services",
	OTHER: "Other",
} as const
export const INDUSTRIES_OPTIONS = objectValuesToOptions(INDUSTRIES)

export const EMPLOYEES = {
	E50: "1-50",
	E100: "51-100",
	E200: "101-200",
	E500: "201-500",
	E1000: "501-1000",
	E1000P: "1000+",
} as const

export const EMPLOYEES_OPTIONS = objectValuesToOptions(EMPLOYEES)

export const TOOLS = {
	GOOGLE_WORKSPACE: "Google Workspace",
	OFFICE_365: "Office 365",
	MICROSOFT_TEAMS: "Microsoft Teams",
	SLACK: "Slack",
	ZOOM: "Zoom",
	OTHER: "Other",
} as const

export const TOOLS_OPTIONS = objectValuesToOptions(TOOLS)

export const SMALL_COUNTS = {
	C0: "0",
	C4: "1-4",
	C15: "5-15",
	C50: "15-50",
	C100: "50-100",
	C100P: "100+",
} as const

export const SMALL_COUNTS_OPTIONS = objectValuesToOptions(SMALL_COUNTS)
