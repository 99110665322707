import React, { PropsWithChildren } from "react"

import { useLocation } from "react-router-dom"

import { useLoadReducers } from "../hooks/useLoadReducers"
import Loading from "../screens/Mobile/Loading"

function MobileLoadingProvider({ children }: PropsWithChildren<unknown>) {
	const { pathname } = useLocation()

	const isAuthPath = pathname.indexOf("/auth/") > -1

	const { areSlicesLoaded } = useLoadReducers({
		reducerKeys: [
			"settings",
			"featureFlags",
			"consent",
			"experiments",
			"user_experiments",
			"experiments",
			"user",
		],
	})

	if (!areSlicesLoaded && !isAuthPath) {
		return <Loading />
	}

	return <>{children}</>
}

export default MobileLoadingProvider
