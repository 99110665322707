import React, {
	HTMLProps,
	MouseEvent,
	PropsWithChildren,
	ReactNode,
} from "react"

import classNames from "classnames"
import { Link, LinkProps } from "react-router-dom"

import { openInAppBrowser } from "../../../utils"

import { ReactComponent as ButtonLoaderSVG } from "../../../assets/images/icons/ButtonLoader.svg"

import "./style.sass"

type OmitProps = "value" | "target" | "title" | "href" | "onClick"
type ButtonVariant =
	| "primary"
	| "secondary"
	| "secondary-white"
	| "danger"
	| "danger-pop"
	| "link"
	| "submit"
	| "mobile-action"

type ElementProps =
	| Omit<HTMLProps<HTMLButtonElement>, OmitProps>
	| Omit<HTMLProps<HTMLAnchorElement>, OmitProps>
	| Omit<LinkProps, OmitProps>

export type ButtonProps = {
	onClick?: (e: MouseEvent<HTMLElement>) => void
	variant?: ButtonVariant
	children?: ReactNode
	value?: string
	to?: string
	href?: string
	target?: string
	className?: any
	confirmationPrompt?: string
	isSmall?: boolean
	isSubmit?: boolean
	isDisabled?: boolean
	noConfirm?: boolean
	title?: string
	isVisible?: boolean
	isLink?: boolean
	isLoading?: boolean
	icon?: ReactNode
} & ElementProps

export default function Button({
	children = "Submit",
	onClick,
	className,
	isSmall,
	confirmationPrompt,
	isSubmit: _isSubmit,
	isDisabled,
	noConfirm = false,
	isVisible = true,
	isLoading = false,
	variant = "primary",
	icon,
	...props
}: PropsWithChildren<ButtonProps>) {
	if (isVisible === false) {
		return null
	}

	const isSubmit = variant === "submit" || _isSubmit
	const isDanger = ["danger", "danger-pop"].includes(variant)

	const buttonClass = classNames("Button", className, variant, {
		isSmall,
		isDisabled,
		isLoading,
		"has-icon": !!icon,
	})

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()

		if (props.href) {
			openInAppBrowser(props.href)
			return
		}

		if (isDisabled || isLoading) {
			e.preventDefault()
			return
		}

		if (!isDanger) {
			onClick?.(e)
		} else if (
			noConfirm ||
			window.confirm(
				confirmationPrompt ?? 'Click "OK" to proceed with the deletion.',
			)
		) {
			onClick?.(e)
		}
	}

	if (props.to) {
		return (
			<Link
				onClick={onClick ? handleClick : undefined}
				{...(props as LinkProps)}
				className={buttonClass}
			>
				<span className="label">{children}</span>
			</Link>
		)
	}

	return (
		<button
			onClick={handleClick}
			disabled={isDisabled}
			{...(props as HTMLProps<HTMLButtonElement>)}
			type={isSubmit ? "submit" : "button"}
			className={buttonClass}
		>
			{icon && (
				<span className="button-icon">
					{isLoading ? (
						<ButtonLoaderSVG className="button--icon--loader" />
					) : (
						icon
					)}
				</span>
			)}
			{!icon && isLoading && <ButtonLoaderSVG className="loader" />}
			<span className="label">
				{isSubmit && !children ? "Submit" : children}
			</span>
		</button>
	)
}
