import { useEffect, useState } from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { IS_WHITE_LABEL } from "../../../../constants"
import { contentStyle, overlayStyle } from "../../../../modals/modalStyles"
import { getLabel } from "../../../../utils"

import { useFetchDeskBookingConsentQuery } from "../../../../redux/api/consents"
import { useFetchServicesQuery } from "../../../../redux/api/services"

import Space from "../../../../components/Space"
import Button from "../../../../components/basic/Button"
import { Checkbox } from "../../../../components/basic/Checkbox"

import { ReactComponent as CrossSVG } from "../../../../assets/images/icons/Cross.svg"

import "./DeskActivationModal.sass"

export type DeskActivationModalProps = {
	open: boolean
	onConfirm: (consent: boolean) => void
	onCancel: () => void
	className?: string
	isActivation: boolean
	hasTrialAvailable: boolean
}

const DeskActivationModal = ({
	open,
	onConfirm,
	onCancel,
	className,
	isActivation,
	hasTrialAvailable,
}: DeskActivationModalProps) => {
	const { data: consent } = useFetchDeskBookingConsentQuery()

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [consentGiven, setConsentGiven] = useState(!!consent)

	const { t } = useTranslation()
	const { data: services } = useFetchServicesQuery()
	const isTrial = services?.desk?.status === "in_trial" ?? false

	const cn = classNames("DeskActivationModal ModalForm", className)

	const confirmButtonText = t(
		`desktop.settings.desks.desk_activation_modal.buttons.yes_${
			isActivation
				? hasTrialAvailable
					? "activate_trial"
					: "activate"
				: "deactivate"
		}` as ParseKeys,
	)

	const title = t(
		`desktop.settings.desks.desk_activation_modal.${
			isActivation ? "enable" : "disable"
		}.title` as ParseKeys,
	)

	const getDescription = () => {
		if (!isActivation) {
			if (isTrial) {
				return (
					<Trans i18nKey="desktop.settings.desks.desk_activation_modal.disable.description">
						<Space size={0.5} />
						<a href={`mailto:${getLabel("links.supportEmail")}`}>email</a>
					</Trans>
				)
			}

			return (
				<Trans i18nKey="desktop.settings.desks.desk_activation_modal.disable.no_trial_description">
					{IS_WHITE_LABEL ? null : (
						<a
							href={getLabel("links.supportFormURL")}
							target="_blank"
							rel="noreferrer"
						>
							email
						</a>
					)}
					<a
						href={`mailto:${getLabel("links.supportEmail")}`}
						target="_blank"
						rel="noreferrer"
					>
						email
					</a>
				</Trans>
			)
		}
		if (hasTrialAvailable) {
			return t(
				"desktop.settings.desks.desk_activation_modal.enable.description.trial_available",
			)
		}

		return (
			<Trans i18nKey="desktop.settings.desks.desk_activation_modal.enable.description.no_trial_available">
				<Space size={0.5} />
			</Trans>
		)
	}

	const handleOnConfirm = async () => {
		setIsSubmitting(true)
		await onConfirm(consentGiven)
		setIsSubmitting(false)
	}

	useEffect(() => {
		setConsentGiven(!!consent)
	}, [consent])

	return (
		<ReactModal
			isOpen={open}
			onRequestClose={() => onCancel()}
			ariaHideApp={false}
			style={{
				content: contentStyle,
				overlay: overlayStyle,
			}}
		>
			<div className={cn}>
				<div className="top-bar">
					<h1>{title}</h1>
					<button className="close" onClick={() => onCancel()}>
						<CrossSVG />
					</button>
				</div>

				<div className="hint">{getDescription()}</div>
				{isActivation && (
					<Checkbox
						isSecondary
						label={
							<div>
								<Trans i18nKey="desktop.settings.desks.desk_activation_modal.enable.terms_agreement">
									<a
										href={getLabel("links.termsOfUseURL")}
										target="_blank"
										rel="noopener noreferrer"
									>
										terms
									</a>
									<a
										href={getLabel("links.privacyPolicyURL")}
										target="_blank"
										rel="noopener noreferrer"
									>
										policy
									</a>
								</Trans>
							</div>
						}
						value={consentGiven}
						onChange={setConsentGiven}
					/>
				)}
				{isActivation || isTrial ? (
					<div className="actions actions-edit">
						<Button variant="secondary" onClick={onCancel}>
							{t("general.no")}
						</Button>
						<Button
							variant="primary"
							onClick={handleOnConfirm}
							isDisabled={(isActivation && !consentGiven) || isSubmitting}
						>
							{confirmButtonText}
						</Button>
					</div>
				) : null}
			</div>
		</ReactModal>
	)
}
export default DeskActivationModal
