import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { get } from "../../api"
import { UrlParameters } from "../../api/queryBuilder"
import debounce from "lodash.debounce"
import AsyncSelect from "react-select/async"

import { useAppSelector } from "../../redux/reducers"

import "./MultipleAsyncSelector.sass"

type Props<Option> = {
	urlGenerator: (fetchOptions: UrlParameters) => string
	placeholder?: string
	nothingFoundMessage?: string
	getOptionLabel: (dataPoint: Option) => string
	getOptionValue: (dataPoint: Option) => string
	extraClassName?: string
	options: Option[]
	onChange: (value: Option[]) => void
}

/**
 *
 * @param placeholder - Placeholder text for the input component
 * @param urlGenerator - Function that gets fetchOptions and should return a string that the component can call a GET request with and should return and object in the following form: { results: ArrayOfObjects[] }. The key results is currently hard coded since this is the way Django handles pagination.
 */
export const MultipleAsyncSelector = <Option,>({
	placeholder,
	nothingFoundMessage,
	urlGenerator,
	getOptionLabel,
	getOptionValue,
	extraClassName,
	onChange,
	options,
}: Props<Option>) => {
	const { t } = useTranslation()
	const { access_token } = useAppSelector((state) => state.auth)
	const className = classNames([
		"async-multiple-select-container",
		extraClassName,
	])

	const loadData = (
		search: string | undefined,
		callback: (options: Option[]) => void,
	) => {
		if (!access_token) return callback([])

		const fetchOptions = {
			limit: 10,
			search,
		}

		if (search && search.trim().length < 1) {
			delete fetchOptions.search
		}

		get(urlGenerator(fetchOptions), {}, access_token)
			.then((response) => {
				if (response.ok) {
					return response.json().then((data) => {
						return callback(data.results)
					})
				}
				return callback([])
			})
			.catch((_) => {
				return callback([])
			})
	}

	const debouncedDataLoader = debounce(loadData, 500)

	return (
		<AsyncSelect
			placeholder={placeholder || t("general.select.standard")}
			className={className}
			classNamePrefix="async-multiple-select"
			isMulti
			getOptionLabel={getOptionLabel as any} // React-select 3.x has shitty types that resolve to any anyway
			getOptionValue={getOptionValue as any} // React-select 3.x has shitty types that resolve to any anyway
			closeMenuOnSelect={false}
			noOptionsMessage={({ inputValue }) => {
				if (!inputValue.trim().length) return null
				return nothingFoundMessage || t("general.not_found.standard")
			}}
			loadOptions={debouncedDataLoader}
			defaultOptions
			components={{
				DropdownIndicator: null,
			}}
			options={options as any} // React-select 3.x has shitty types that resolve to any anyway
			onChange={(value) => {
				onChange(value as Option[])
			}}
		/>
	)
}
