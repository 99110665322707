import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { useFromToCalculator } from "../../hooks/useFromToCalculator"
import Button from "../basic/Button"
import { CategoryRow } from "./CategoryRow"
import { DatePicker } from "./DatePicker"
import { TimeslotPicker } from "./TimeslotPicker"
import { TopNav } from "./TopNav"
import isBetween from "dayjs/plugin/isBetween"

import { TimeslotResponse } from "../../redux/timeslots/types"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./DateTimePicker.sass"

dayjs.extend(isBetween)

type DateTimePickerProps = {
	date: Dayjs
	timeslot: Partial<TimeslotResponse> | null
	preferredTime: string
	defaultTimeSpanMinutes?: number
	onlyInput?: boolean
	unavailableSlots?: Array<Partial<TimeslotResponse>>
	onPick: (
		date: Dayjs | null,
		timeslot: Partial<TimeslotResponse> | null,
	) => void
	onCancelClick?: () => void
	isRoomBooking?: boolean
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
	date,
	timeslot,
	preferredTime,
	defaultTimeSpanMinutes = 480,
	onlyInput = false,
	unavailableSlots,
	onPick,
	onCancelClick,
	isRoomBooking = false,
}) => {
	const { t } = useTranslation()

	const { defaultFrom, defaultTo } = useFromToCalculator(
		undefined,
		isRoomBooking,
	)

	const [selectedDate, setSelectedDate] = useState(date)
	const [selectedSlot, setSelectedSlot] = useState(timeslot)
	const [customFrom, setCustomFrom] = useState(defaultFrom)
	const [customTo, setCustomTo] = useState(defaultTo)

	useEffect(() => {
		setSelectedDate(date)
		setCustomFrom(timeslot?.from ?? defaultFrom)
		setCustomTo(timeslot?.to ?? defaultTo)
		setSelectedSlot({
			from: timeslot?.from ?? defaultFrom,
			to: timeslot?.to ?? defaultTo,
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, timeslot])

	const handleSave = () => {
		if (selectedSlot !== null) {
			onPick(selectedDate, selectedSlot)
		}
	}

	return (
		<div className="DateTimePicker">
			<div className="picker-content">
				<div className="section-head">
					<TopNav
						backArrow={false}
						title={t("mobile.general.choose_time")}
						titleCenter={true}
						rightIcon={
							<CrossSVG
								onClick={() => {
									if (onCancelClick) {
										onCancelClick()
										return
									}
									onPick(null, null)
								}}
							/>
						}
						onTopScreen={false}
					/>
				</div>
				<div className="section">
					<CategoryRow name={t("mobile.general.date")}>
						<DatePicker
							selectedDate={selectedDate}
							onSelect={setSelectedDate}
						/>
					</CategoryRow>
				</div>
				<div className="section no-border">
					<CategoryRow name={t("mobile.general.timeslot")}>
						<TimeslotPicker
							selectedSlot={selectedSlot}
							onSelect={setSelectedSlot}
							customFrom={customFrom}
							onCustomFrom={setCustomFrom}
							customTo={customTo}
							onCustomTo={setCustomTo}
							preferredTime={preferredTime}
							defaultTimeSpanMinutes={defaultTimeSpanMinutes}
							onlyInput={onlyInput}
							unavailableSlots={unavailableSlots}
						/>
					</CategoryRow>
				</div>
				<div className="section-foot">
					<Button
						variant="mobile-action"
						isDisabled={
							customFrom === "" || customTo === "" || selectedSlot === null
						}
						onClick={handleSave}
					>
						{t("mobile.general.confirm")}
					</Button>
				</div>
			</div>
		</div>
	)
}
