import React, { useState } from "react"

import { useTranslation } from "react-i18next"
import { Switch, useHistory, useParams } from "react-router-dom"

import PrinterSettings from "./PrinterSettings"
import TabletSettings from "./TabletSettings"

import Breadcrumbs from "../../../components/Breadcrumbs"
import Intro from "../../../components/Intro"
import ProtectedRoute from "../../../components/ProtectedRoute"
import Space from "../../../components/Space"
import View from "../../../components/View"
import MultiToggle from "../../../components/basic/MultiToggle"

type ParamsType = {
	device_type: DeviceType
}

enum DeviceType {
	TABLET = "tablet",
	PRINTER = "printer",
}

function DeviceSettings() {
	const { t } = useTranslation()
	const history = useHistory()
	const { device_type } = useParams<ParamsType>()

	const [selectedDevice, setSelectedDevice] = useState<DeviceType>(device_type)

	const onDeviceChangeHandler = (device: DeviceType) => {
		setSelectedDevice(device)
		history.push(`/settings/visitors/device-settings/${device}`)
	}

	return (
		<View className="DeviceSettings">
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.settings.visitors.title"),
					t("desktop.settings.visitors.device_settings.title"),
				]}
			/>

			<Intro>{t("desktop.settings.visitors.device_settings.subtitle")}</Intro>

			<Space size={1} />

			<MultiToggle
				options={[
					{
						label: t(
							"desktop.settings.visitors.device_settings.device_types.tablets",
						),
						value: DeviceType.TABLET,
					},
					{
						label: t(
							"desktop.settings.visitors.device_settings.device_types.printers",
						),
						value: DeviceType.PRINTER,
					},
				]}
				onChange={onDeviceChangeHandler}
				value={selectedDevice}
			/>

			<Space size={1} />

			<Switch>
				<ProtectedRoute
					exact
					path="/settings/visitors/device-settings/tablet"
					accessPermission="core_visitors.change_tabletsettings"
					component={TabletSettings}
				/>
				<ProtectedRoute
					exact
					path="/settings/visitors/device-settings/printer"
					accessPermission="core_visitors.change_printersettings"
					component={PrinterSettings}
				/>
			</Switch>
		</View>
	)
}

export default DeviceSettings
