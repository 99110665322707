import React, { useCallback, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../hooks/useToast"
import { COLOR_HEX_REGEX } from "../../utils"
import Field from "../Field"
import { ColorPicker } from "../basic/ColorPicker"
import { ImageSelector } from "../basic/ImageSelector"
import PageForm from "./PageFormHook"
import { setErrors } from "./formUtils"

import { uploadFileCompat } from "../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../redux/files/utils"
import { updateSettings } from "../../redux/settings/settingsSlice"
import {
	ImageResponse,
	Settings,
	SettingsRequest,
} from "../../redux/settings/types"
import { useActions } from "../../redux/utils"

import "./BrandingForm.sass"

type FormValues = {
	company_logo?: ImageResponse
	company_color: string
}

type Props = {
	settings?: Settings
}

const BrandingForm = ({ settings }: Props) => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { company_color, company_logo } = settings ?? {}

	const methods = useForm<FormValues>({
		defaultValues: {
			company_color: company_color ?? "",
			company_logo: company_logo ?? undefined,
		},
	})
	const { setError, control } = methods

	const actions = useActions({
		updateSettings: (payload: SettingsRequest) => updateSettings(payload),
		uploadFile: (logo: File) => uploadFileCompat(logo),
	})

	const [logo, setSelectedLogo] = useState<File | null>()

	const onUpdateClick = useCallback(
		async ({ company_color }: FormValues) => {
			let response
			let logoResponse
			if (logo) {
				const logoWithFixedName = new File(
					[logo],
					removeDotsAndSpaces(logo.name),
				)
				logoResponse = await actions.uploadFile(logoWithFixedName)

				if (uploadFileCompat.rejected.match(logoResponse)) {
					errorToast(logoResponse.error.message)
					return
				}
			}

			const settingsToUpdate = {
				...settings,
				company_color,
			}

			if (logoResponse?.payload) {
				settingsToUpdate.company_logo = {
					id: logoResponse.payload.id,
					name: logoResponse.payload.name,
					path: logoResponse.payload.path,
				}
			} else if (logo === null) {
				settingsToUpdate.company_logo = null
			}

			response = await actions.updateSettings({
				settings: settingsToUpdate,
			})

			if (
				response &&
				updateSettings.rejected.match(response) &&
				response.payload
			) {
				setErrors(response.payload, setError, errorToast)
			} else {
				infoToast(
					t("desktop.settings.organization.branding.form.branding_updated"),
				)
			}
		},
		[actions, settings, logo, errorToast, infoToast, setError, t],
	)

	return (
		<FormProvider {...methods}>
			<PageForm
				className="BrandingForm"
				updateMode={true}
				onUpdate={onUpdateClick}
			>
				<div className="field-description">
					<div>
						<Field control={control} name="company_logo">
							{(props) => (
								<ImageSelector
									{...props}
									label={t(
										"desktop.settings.organization.branding.upload_logo",
									)}
									onChange={setSelectedLogo}
									image={company_logo}
									accept="image/png"
								/>
							)}
						</Field>
					</div>
					<div className="info">
						<div>{t("desktop.settings.organization.branding.logo_size")}</div>
						<div>{t("desktop.settings.organization.branding.logo_info")}</div>
					</div>
				</div>
				<div className="field-description">
					<div>
						<Field
							control={control}
							name="company_color"
							rules={{
								pattern: {
									value: COLOR_HEX_REGEX,
									message: t(
										"desktop.settings.organization.branding.color_invalid",
									),
								},
							}}
						>
							{(props) => <ColorPicker {...props} />}
						</Field>
					</div>
					<div className="info">
						{t("desktop.settings.organization.branding.color_info")}
					</div>
				</div>
			</PageForm>
		</FormProvider>
	)
}

export default BrandingForm
