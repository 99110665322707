import React, { useContext } from "react"

import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"

import {
	FinishType,
	FlowContext,
} from "../../providers/Tablet/RegistrationFlowProvider"
import { isNative } from "../../utils"
import { Capacitor } from "@capacitor/core"

import { useAppSelector } from "../../redux/reducers"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"

import PrintBadge from "../../components/Visitors/PrintBadge"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"
import Button from "../../components/basic/Button"

type Params = {
	type: FinishType
}

const Finish = ({ match }: RouteComponentProps<Params>) => {
	const { t } = useTranslation()

	const { startStep } = useContext(FlowContext)

	const { params } = match || {}
	const { type } = params || {}

	const { data, registration, checkInQr } = useAppSelector(
		selectVisitorRegistration,
	)

	const handleBack = () => startStep()

	const getNavTitle = () => {
		switch (type) {
			case FinishType.QR_CODE:
				return t("tablet.visitors.screens.finish.personal_qr_code")
			case FinishType.CHECK_IN:
			case FinishType.CHECK_OUT:
			case FinishType.HEALTH_FAILED:
			default:
				return ""
		}
	}

	const getTitle = () => {
		switch (type) {
			case FinishType.CHECK_OUT:
				return t("tablet.visitors.screens.finish.thanks_for_visiting")
			case FinishType.HEALTH_FAILED:
				return (
					data?.health_screening?.failure_msg_headline ??
					t("tablet.visitors.screens.finish.sorry")
				)
			case FinishType.QR_CODE:
				return t("tablet.visitors.screens.finish.your_qr_code")
			case FinishType.CHECK_IN:
			default:
				return `${t("tablet.visitors.screens.finish.welcome")} ${
					registration?.full_name
				}`
		}
	}

	const getInfo = () => {
		switch (type) {
			case FinishType.CHECK_OUT:
				return t("tablet.visitors.screens.finish.checked_out")
			case FinishType.HEALTH_FAILED:
				return (
					data?.health_screening?.failure_msg ??
					t("tablet.visitors.screens.finish.not_enter")
				)
			case FinishType.QR_CODE:
				return t("tablet.visitors.screens.finish.use_tablet")
			case FinishType.CHECK_IN:
			default:
				if (registration?.host) {
					const firstName = registration?.host?.first_name

					const lastName = registration?.host?.last_name
						? ` ${registration?.host?.last_name}`
						: ""

					const host = `${firstName}${lastName}`

					return t("tablet.visitors.screens.finish.host_notify", {
						host: host,
					})
				}
				return ""
		}
	}

	return (
		<VisitorView>
			<VisitorHeader title={getNavTitle()} />
			<VisitorContent>
				<div className="title">{getTitle()}</div>
				<div className="info">{getInfo()}</div>
				{type === FinishType.CHECK_IN && Capacitor.isNativePlatform() && (
					<PrintBadge />
				)}
				{type === FinishType.QR_CODE && (
					<div className="qr_code">
						<img alt="QrCode" src={checkInQr} />
					</div>
				)}
			</VisitorContent>
			<VisitorFooter>
				{type === FinishType.QR_CODE && (
					<Button href={checkInQr} download="qr_code.png">
						{t("tablet.visitors.save_qr_code")}
					</Button>
				)}
				{isNative() && (
					<>
						<div>
							<Button onClick={handleBack}>
								{t("tablet.visitors.bact_to_start")}
							</Button>
						</div>
						<div className="notice">{t("tablet.visitors.redirect")}</div>
					</>
				)}
			</VisitorFooter>
		</VisitorView>
	)
}

export default Finish
