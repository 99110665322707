import React from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { generateString } from "../../utils"
import { UncontrolledField } from "../Field"
import { SortableItemProp } from "../SortableCardsContainer"
import Space from "../Space"
import { Checkbox } from "../basic/Checkbox"
import { Input } from "../basic/Input"

import { SignInField, SignInFieldType } from "../../redux/tablet_settings/types"

import "./VisitorSignInField.sass"

interface Props extends SortableItemProp {
	signInField: SignInField
	onChange: (setting: SignInField) => void
	valid?: boolean
}

export default function VisitorSignInField({
	signInField,
	onChange,
	valid = true,
}: Props) {
	const { type, required, custom_field_name = "" } = signInField

	const { t } = useTranslation()

	const onNameChanged = (value: string) => {
		onChange({
			...signInField,
			custom_field_name: value,
		})
	}

	const onRequiredChanged = (required: boolean) => {
		onChange({
			...signInField,
			required,
		})
	}

	const SignInFieldSettingsClasses = classNames("VisitorSignInFieldRow", {
		Required: required,
	})

	return (
		<div className={SignInFieldSettingsClasses}>
			<div className="VisitorSignInField">
				<div className="VisitorSignInFieldName">
					{t(
						`desktop.settings.visitors.device_settings.tablet.sign_in_fields.${type.toLocaleLowerCase()}.title` as ParseKeys,
					)}
				</div>
				<Space size={0.5} />
				{type === SignInFieldType.CUSTOM && (
					<UncontrolledField
						errorText={
							!valid
								? t(
										"desktop.settings.visitors.device_settings.tablet.sign_in_fields.enter_custom_field_required",
								  )
								: undefined
						}
					>
						<Input
							type="text"
							placeholder={t(
								"desktop.settings.visitors.device_settings.tablet.sign_in_fields.enter_custom_field",
							)}
							onChange={(value) => onNameChanged(value)}
							value={custom_field_name}
							hasError={!valid}
						/>
					</UncontrolledField>
				)}

				{type === SignInFieldType.HOST && (
					<span className="VisitorSignInFieldInfoText">
						{t(
							`desktop.settings.visitors.device_settings.tablet.sign_in_fields.${type.toLocaleLowerCase()}.infoText` as ParseKeys,
						)}
					</span>
				)}
				<Space size={0.25} />
				<div>
					<Checkbox
						name={`required-field ${generateString(4)}`}
						label={t(
							"desktop.settings.visitors.device_settings.tablet.sign_in_fields.required",
						)}
						value={required}
						onChange={onRequiredChanged}
						disabled={type === SignInFieldType.NAME}
						isSecondary
					/>
				</div>
			</div>
		</div>
	)
}
