import React, { useEffect } from "react"

import { isDev, isProd } from "../utils"

import { getAppParams } from "../redux/appParams/selectors"
import { fetchAuthCode } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Heading from "../components/Heading"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Button from "../components/basic/Button"

import "./Login.sass"

function Login() {
	const actions = useActions({
		fetchAuthCode: () => fetchAuthCode(),
	})

	const { appName } = useAppSelector(getAppParams)

	useEffect(() => {
		isProd() && actions.fetchAuthCode()
	}, [actions])

	return (
		<RouteView className="Login">
			<Screen>
				{isProd() && (
					<>
						<Heading>Signing in</Heading>
						<p>Redirecting you to {appName} Portal for authorization.</p>
					</>
				)}
				{isDev() && (
					<>
						<Heading>Signed out</Heading>
						<br />
						<Button onClick={actions.fetchAuthCode}>Sign in</Button>
					</>
				)}
			</Screen>
		</RouteView>
	)
}

export default Login
