import { fetchBaseQueryExtended } from "./fetchBaseQueryExtended"
import { createApi } from "@reduxjs/toolkit/query/react"

// the list of tagTypes sorted by name
const TAG_TYPES = [
	"Amenities",
	"Assets",
	"AssetTypes",
	"Buildings",
	"Buildings",
  "Calendars",
	"Company",
	"Consents",
	"CreditCard",
	"CustomerInfo",
	"Demo",
	"Departments",
	"DeskReservations",
	"Desks",
	"Estimates",
	"Floors",
	"Invites",
	"Invoices",
	"Me",
	"Payments",
	"Rooms",
	"RoomsDevices",
	"Screenings",
	"Services",
	"SubscriptionPlans",
	"Subscriptions",
	"Timeslots",
	"Users",
	"Visits",
] as const

// Define a service using a base URL and expected endpoints
export const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQueryExtended,
	tagTypes: TAG_TYPES,
	endpoints: () => ({}),
})

type TagType = (typeof TAG_TYPES)[number]

/**
 * A utility function that takes an object with `success` and `error` properties,
 * and returns a function that can be used as in `invalidateTags` option in an endpoint definition.
 * The returned function checks if the `result` is truthy and returns the `success` array if true,
 * otherwise returns the `error` array.
 * In most cases you should use only the success array
 *
 * Invalidates the result based on success or error tags.
 * @param success - An array of success tags.
 * @param error - An array of error tags.
 * @returns A function that takes a result and returns success if the result is truthy, otherwise error.
 * @example
 * invalidatesTags: invalidateOn({
 *   success: ['Company', 'Assets'],
 *   error: ['Buildings']
 * });
 *
 */
export const invalidateOn =
	({ success = [], error = [] }: { success?: TagType[]; error?: TagType[] }) =>
	(result: unknown) =>
		result ? success : error
