import { useEffect } from "react"

import { UseFormReturn, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { COUNTRIES } from "../../../../constants"
import { useToast } from "../../../../hooks/useToast"

import {
	useCreateCustomerInfoMutation,
	useFetchCustomerInfoQuery,
	useUpdateCustomerInfoMutation,
} from "../../../../redux/api/billing/customerInfo"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { setErrors } from "../../../../components/Form/formUtils"

export type CompanyDetailsFormValues = {
	company?: string
	first_name?: string
	last_name?: string
	email?: string
	address?: string
	zip?: string
	city?: string
	country?: { value: string; label: string }
	state?: string | null
	phone?: string | null
	vat_number?: string | null
	vat_not_applicable?: boolean
}

export type UseCompanyDetailsFormReturn = {
	methods: UseFormReturn<CompanyDetailsFormValues>
	onUpdateClick: (values: CompanyDetailsFormValues) => Promise<boolean>
	onCreateClick: (values: CompanyDetailsFormValues) => Promise<boolean>
	isUpdateMode: boolean
	isLoaded: boolean
}

export const useCompanyDetailsForm = (): UseCompanyDetailsFormReturn => {
	const { infoToast, errorToast } = useToast()
	const { t } = useTranslation()

	const { data, isFetching, isSuccess, error } = useFetchCustomerInfoQuery()

	const [createCustomerInfo] = useCreateCustomerInfoMutation()
	const [updateCustomerInfo] = useUpdateCustomerInfoMutation()
	const isCustomerInfoMissing =
		(error && "status" in error && error.status === 404) ?? false

	const defaultValues: CompanyDetailsFormValues = {
		company: data?.company,
		first_name: data?.first_name,
		last_name: data?.last_name,
		email: data?.email,
		address: data?.address,
		zip: data?.zip,
		city: data?.city,
		country: data?.country
			? { value: data.country, label: COUNTRIES[data.country] }
			: undefined,
		state: data?.state,
		phone: data?.phone,
		vat_number: data?.vat_number,
	}

	const methods = useForm<CompanyDetailsFormValues>({
		defaultValues,
	})
	const { reset, setError } = methods

	const onUpdateClick = async ({
		country,
		vat_number,
		...values
	}: CompanyDetailsFormValues) => {
		const response = await updateCustomerInfo({
			...values,
			...(vat_number && { vat_number }),
			country: country?.value,
		})

		if (isRejected(response)) {
			const { error } = response
			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast)

				return false
			}
		}

		infoToast(
			t("desktop.settings.billing.billing_details.toast.update_success"),
		)

		return true
	}

	const onCreateClick = async ({
		country,
		vat_number,
		...values
	}: CompanyDetailsFormValues) => {
		const response = await createCustomerInfo({
			...values,
			...(vat_number && { vat_number }),
			country: country?.value,
		})

		if (isRejected(response)) {
			const { error } = response
			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast)

				return false
			}
		}

		infoToast(
			t("desktop.settings.billing.billing_details.toast.create_success"),
		)

		return true
	}

	const isUpdateMode = !!data?.id

	useEffect(() => {
		reset(defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return {
		methods,
		onUpdateClick,
		onCreateClick,
		isUpdateMode,
		isLoaded: !isFetching && (isSuccess || isCustomerInfoMissing),
	}
}
