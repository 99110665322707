import { selectAppDates } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import Datestring from "../../../components/Datestring"
import Heading from "../../../components/Heading"
import { DayNav } from "../../../components/Manage/CalNav"

const Header = () => {
	const { currentDate } = useAppSelector(selectAppDates)
	return (
		<Heading>
			<DayNav />
			<span className="date-range">
				<Datestring date={currentDate} isLong isInline />
			</span>
		</Heading>
	)
}

export default Header
