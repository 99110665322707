import dayjs from "dayjs"

import { RootState } from "../reducers"
import { createSelector } from "@reduxjs/toolkit"

const getApp = (state: RootState) => state.app
export const selectAppDates = createSelector(getApp, (app) => {
	return {
		today: dayjs(app.today),
		currentDate: dayjs(app.currentDate),
		fromDate: dayjs(app.fromDate),
		toDate: dayjs(app.toDate),
		showWeekends: app.showWeekends,
	}
})

export const selectApp = createSelector(getApp, (app) => {
	return {
		...app,
		today: dayjs(app.today),
		currentDate: dayjs(app.currentDate),
		fromDate: dayjs(app.fromDate),
		toDate: dayjs(app.toDate),
	}
})

export const selectError = (state: RootState) => state.app.error

export const selectIsMobile = (state: RootState) => state.app.isMobile

export const selectCompanyId = (state: RootState) => state.app.company
export const selectNeedsOnboarding = (state: RootState) =>
	state.app.needsOnboarding
export const selectOnboarding = (state: RootState) => state.app.onboarding
