import React from "react"

import classNames from "classnames"

import "./style.sass"

type DividerColor = "gray-1" | "gray-2" | "gray-3"

export type DividerProps = {
	className?: string
	hasMargin?: boolean
	color?: DividerColor
}

const Divider = ({
	className,
	color = "gray-1",
	hasMargin = true,
}: DividerProps) => {
	const cn = classNames("Divider", className, color, {
		margin: hasMargin,
	})
	return <div className={cn}></div>
}

export default Divider
