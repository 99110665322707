import { ThunkApiConfig } from "RootType"

import { desksScheduleGetURL, get } from "../../api"
import { timeZone } from "../../dayjs"
import { ISODate } from "../../types/sharedTypes"
import {
	Aggregation,
	PaginatedOptions,
	PaginatedResponseWithAggregation,
	PaginationState,
	SliceState,
	checkInReservation,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { DeskScheduleEntry } from "./types"
import {
	PayloadAction,
	Slice,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

export type DeskScheduleStatus = "reserved" | "available" | "all"

export type FetchDeskScheduleProps = {
	start: ISODate
	end: ISODate
	building_id?: string
	department_id?: string
	floor_id?: string
	search?: string
	amenity_id?: string[] | string
} & PaginatedOptions

export const fetchDesksSchedule = createAsyncThunk<
	PaginatedResponseWithAggregation<DeskScheduleEntry>,
	FetchDeskScheduleProps | undefined,
	ThunkApiConfig
>("desks/fetchDesksSchedule", async (params, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const {
		amenity_id,
		building_id,
		floor_id,
		department_id,
		...remainingParams
	} = params ?? {}

	const isAllFloorFilter = floor_id === FilterSpecialValues.ALL
	const isAllDepartmentsFilter = department_id === FilterSpecialValues.ALL
	const isNoneDepartmentsFilter =
		department_id === FilterSpecialValues.NOT_ASSIGNED
	const isAllBuildingFilter = building_id === FilterSpecialValues.ALL
	const isAllAmenitiesFilter = amenity_id?.[0]
		? amenity_id[0] === FilterSpecialValues.ALL
		: true

	const updatedParams = {
		...remainingParams,
		tz: timeZone,
		building_id: isAllBuildingFilter ? undefined : building_id,
		floor_id: isAllFloorFilter
			? undefined
			: building_id && !isAllBuildingFilter
			? floor_id
			: undefined,
		department_id: isAllDepartmentsFilter
			? undefined
			: isNoneDepartmentsFilter
			? ""
			: department_id,
		amenity_id:
			isAllAmenitiesFilter || typeof amenity_id === "string"
				? undefined
				: amenity_id?.join(","),
	}

	const response: Response = await get(
		desksScheduleGetURL(updatedParams),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export interface DeskScheduleState extends SliceState, PaginationState {
	schedule: DeskScheduleEntry[]
	aggregation: Aggregation
}

const initialState: DeskScheduleState = {
	schedule: [],
	aggregation: {},
	...sliceInitialState,
	...paginationInitialState,
}

const deskScheduleSlice: Slice<DeskScheduleState> = createSlice({
	name: "desk-schedule",
	initialState,
	reducers: {
		deskCheckIn(state, { payload }: PayloadAction<string>) {
			checkInReservation(state, payload)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDesksSchedule.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchDesksSchedule.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchDesksSchedule.fulfilled, (state, action) => {
			const { payload } = action
			setFetchSuccessState(state)
			state.schedule = payload.results
			state.count = payload.count
			state.offset = action.meta.arg?.offset ?? 0

			state.aggregation = payload.aggr.reduce(
				(obj, aggr) => ({ ...obj, ...aggr }),
				{},
			)
		})
	},
})

export const deskScheduleReducer = deskScheduleSlice.reducer

export const { deskCheckIn } = deskScheduleSlice.actions
