import React, { useRef } from "react"

import { useTranslation } from "react-i18next"

import { ReactComponent as SearchSVG } from "../../assets/images/icons/Search.svg"

import "./SearchBox.sass"

type SearchBoxProps = {
	onChange: (text: string) => void
}

export const SearchBox: React.FC<SearchBoxProps> = ({ onChange }) => {
	const { t } = useTranslation()

	const inputRef = useRef<HTMLInputElement>(null)

	return (
		<div className="SearchBox">
			<div className="search-icon">
				<SearchSVG />
			</div>
			<div className="search-input">
				<input
					placeholder={t("mobile.presence.search_colleague")}
					onChange={(event: React.ChangeEvent<{ value: string }>) => {
						onChange(event.target.value)
					}}
					ref={inputRef}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							inputRef.current?.blur()
						}
					}}
				/>
			</div>
		</div>
	)
}
