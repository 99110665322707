import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Intro.sass"

type Props = {
	isConstrained?: boolean
	isCentered?: boolean
}

const Intro = ({
	children,
	isConstrained,
	isCentered,
}: PropsWithChildren<Props>) => {
	const introClassName = classNames({
		Intro: true,
		isConstrained,
		isCentered,
	})

	return <div className={introClassName}>{children}</div>
}

export default Intro
