import { HTMLProps, forwardRef } from "react"

import classNames from "classnames"

import { InternalTime } from "../../../types/sharedTypes"
import { TimePicker } from "../TimePicker"

import "./style.sass"

export type TimeRange = {
	start?: InternalTime
	end?: InternalTime
}

export type TimeRangePickerProps = {
	onChange?: (value: TimeRange) => void
	value?: TimeRange
	maxTime?: InternalTime
	minTime?: InternalTime
	name?: string
	hasError?: boolean
} & Omit<HTMLProps<HTMLInputElement>, "onChange" | "value" | "ref">

export const TimeRangePicker = forwardRef<
	HTMLInputElement,
	TimeRangePickerProps
>(
	(
		{
			onChange,
			name,
			className,
			disabled,
			value,
			autoFocus,
			hasError,
			...props
		}: TimeRangePickerProps,
		ref,
	) => {
		const cn = classNames("TimeRangePicker", className, { disabled })

		const internalOnChange =
			(type: "start" | "end") => (time: InternalTime) => {
				onChange && onChange({ ...value, [type]: time })
			}

		return (
			<div className={cn} id={name}>
				<TimePicker
					autoFocus={autoFocus}
					ref={ref}
					{...props}
					value={value?.start}
					onChange={internalOnChange("start")}
					name={`${name}-start`}
					disabled={disabled}
					hasError={hasError}
				/>
				<span>&ndash;</span>
				<TimePicker
					{...props}
					value={value?.end}
					onChange={internalOnChange("end")}
					name={`${name}-end`}
					disabled={disabled}
					hasError={hasError}
				/>
			</div>
		)
	},
)
