import classNames from "classnames"

import { API_ROOT } from "../../../api"
import { colorFromString } from "../../../utils"

import { UserProfile } from "../../../redux/api/users/types"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"

import { ReactComponent as EllipseSVG } from "../../../assets/images/icons/Ellipse.svg"

import "./style.sass"

export type AvatarProps = {
	user: { email: string; groups: string[]; profile?: UserProfile }
	size?: "small" | "medium" | "large"
	variant?: "square" | "circle"
	className?: string
}

const Avatar = ({
	user,
	size = "small",
	variant = "circle",
	className,
}: AvatarProps) => {
	const userStyle: Record<string, string> = {
		"--user-color": colorFromString(user.email),
	}

	const cn = classNames("Avatar", className, size, variant)

	if (user.profile?.picture?.url) {
		const { url, name } = user.profile?.picture ?? {}

		return (
			<div className={cn}>
				<img src={`${API_ROOT}${url}`} alt={name} />
			</div>
		)
	}

	if (isOfficeManager(user)) {
		return <span className={cn}>👑</span>
	}

	if (isPortalAdmin(user)) {
		return <span className={cn}>⛑</span>
	}

	return <EllipseSVG className={cn} style={userStyle} />
}

export default Avatar
