import React, { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../hooks/useToast"
import Field from "../../Field"
import { Label } from "../../Field/Label"
import { Input } from "../../basic/Input"
import ModalForm from "../ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { cancelInvite } from "../../../redux/invite/inviteSlice"
import { InviteCancelRequest } from "../../../redux/invite/types"
import { fetchInvites } from "../../../redux/invites/invitesSlice"
import { InviteListResponse } from "../../../redux/invites/types"
import { FetchOptions as InvitesFetchOptions } from "../../../redux/invites/types"
import { useActions } from "../../../redux/utils"

import { ReactComponent as InfoSVG } from "../../../assets/images/icons/InfoOutlined.svg"

import "./InviteCancelForm.sass"

type FormValues = {
	additionalInformation?: string
}

type Props = {
	invite: InviteListResponse
	fetchParams?: InvitesFetchOptions
}

const InviteCancelForm = ({ invite, fetchParams }: Props) => {
	const { t } = useTranslation()
	const { closeModal } = useModals()
	const { infoToast, errorToast } = useToast()

	const {
		building_id,
		show,
		start: startDate,
		end: endDate,
		search,
	} = fetchParams || {}

	const actions = useActions({
		cancelInvite: (id: string, payload: InviteCancelRequest) =>
			cancelInvite({ id, ...payload }),
		fetchInvites: (params: InvitesFetchOptions) => fetchInvites(params),
	})

	const methods = useForm<FormValues>({
		defaultValues: {
			additionalInformation: undefined,
		},
	})

	const { control } = methods

	const onCancelClick = useCallback(
		async (values: FormValues) => {
			const response = await actions.cancelInvite(invite.id, {
				cancel_reason: values.additionalInformation,
			})

			if (cancelInvite.fulfilled.match(response)) {
				infoToast(t("desktop.manage.visitors.invite.invite_canceled"))
				actions.fetchInvites({
					building_id,
					show,
					start: startDate,
					end: endDate,
					search,
				})
				closeModal()
			} else {
				errorToast(response.error.message)
			}
		},
		[
			actions,
			closeModal,
			infoToast,
			errorToast,
			invite.id,
			t,
			building_id,
			show,
			startDate,
			endDate,
			search,
		],
	)

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="VisitorInviteCancelForm"
				updateMode={false}
				title={t("desktop.manage.visitors.invite.cancel_invite")}
				onCreate={onCancelClick}
				createButtonText={t("desktop.manage.visitors.invite.cancel_invite")}
			>
				<Label>
					{t("desktop.manage.visitors.invite.additional_information_optional")}
				</Label>
				<Field control={control} name="additionalInformation">
					{(props) => (
						<Input {...props} multiline className="additiona-info-input" />
					)}
				</Field>

				<div className="subtext">
					<InfoSVG />
					{t(
						"desktop.manage.visitors.invite.send_additional_information_to_invitees",
					)}
				</div>
			</ModalForm>
		</FormProvider>
	)
}

export default InviteCancelForm
