import React, { useEffect } from "react"

import { Trans, useTranslation } from "react-i18next"

import { getAppParams } from "../../../../redux/appParams/selectors"
import { fetchExperiments } from "../../../../redux/experiments/experimentsSlice"
import { useAppSelector } from "../../../../redux/reducers"
import { useActions } from "../../../../redux/utils"

import RoomsForm from "../../../../components/Form/RoomsForm"
import Heading from "../../../../components/Heading"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const RoomsOverview = () => {
	const { t } = useTranslation()

	const actions = useActions({
		fetchExperiments: () => fetchExperiments(),
	})

	useEffect(() => {
		actions.fetchExperiments()
	}, [actions])

	const { appName } = useAppSelector(getAppParams)

	return (
		<View className="SettingsPage">
			<Heading>{t("desktop.settings.rooms.overview.title")}</Heading>

			<Intro isConstrained>
				<Trans
					i18nKey="desktop.settings.rooms.overview.intro"
					components={{
						a: (
							<a
								href="https://portal.getjoan.com/manage/devices"
								target="_blank"
								rel="noreferrer"
							>
								{appName}
							</a>
						),
					}}
					values={{
						appName,
					}}
				></Trans>
			</Intro>

			<Space size={0.75} />

			<RoomsForm />
		</View>
	)
}

export default RoomsOverview
