import { useMemo } from "react"

import classNames from "classnames"

import { TABLET_LOW_BATTERY_THRESHOLD } from "../../constants"

import { RoomDeviceResponse } from "../../redux/api/devices/types"

import { ReactComponent as Battery40SVG } from "../../assets/images/icons/Battery40.svg"
import { ReactComponent as Battery60SVG } from "../../assets/images/icons/Battery60.svg"
import { ReactComponent as Battery80SVG } from "../../assets/images/icons/Battery80.svg"
import { ReactComponent as Battery100SVG } from "../../assets/images/icons/Battery100.svg"
import { ReactComponent as BatteryChargingSVG } from "../../assets/images/icons/BatteryCharging.svg"
import { ReactComponent as BatteryCriticalSVG } from "../../assets/images/icons/BatteryCritical.svg"
import { ReactComponent as NoWifiSVG } from "../../assets/images/icons/NoWifi.svg"
import { ReactComponent as WifiOneBarSVG } from "../../assets/images/icons/Wifi1Bar.svg"
import { ReactComponent as WifiTwoBarSVG } from "../../assets/images/icons/Wifi2Bar.svg"
import { ReactComponent as WifiThreeBarSVG } from "../../assets/images/icons/Wifi3Bar.svg"

import "./RoomDeviceStatus.sass"

type deviceBatteryStatusProps = {
	batteryStatus: number
	batteryCharging?: boolean
}

export const DeviceBatteryStatus = ({
	batteryStatus,
	batteryCharging,
}: deviceBatteryStatusProps) => {
	let icon = useMemo(() => {
		if (batteryCharging) {
			return <BatteryChargingSVG />
		} else {
			if (batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD) {
				return <BatteryCriticalSVG />
			} else if (batteryStatus <= 40) {
				return <Battery40SVG />
			} else if (batteryStatus <= 60) {
				return <Battery60SVG />
			} else if (batteryStatus <= 80) {
				return <Battery80SVG />
			}
			return <Battery100SVG />
		}
	}, [batteryCharging, batteryStatus])

	const iconClasses = classNames({
		icon: true,
		warning: batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD,
	})

	return (
		<div className="status">
			<span className={iconClasses}>{icon}</span>
			{!batteryCharging && <span className="label">{batteryStatus} %</span>}
		</div>
	)
}

type DeviceWifiQualityProps = {
	wifiQuality: number | null
}

export const DeviceWifiStatus = ({ wifiQuality }: DeviceWifiQualityProps) => {
	let icon = useMemo(() => {
		if (wifiQuality === null || wifiQuality < 1) {
			return <NoWifiSVG />
		} else if (wifiQuality <= 25) {
			return <WifiOneBarSVG />
		} else if (wifiQuality > 25 && wifiQuality < 70) {
			return <WifiTwoBarSVG />
		} else {
			return <WifiThreeBarSVG />
		}
	}, [wifiQuality])

	const iconClasses = classNames({
		icon: true,
		warning: wifiQuality === null || wifiQuality < 1,
	})

	return (
		<div className="status">
			<span className={iconClasses}>{icon}</span>
		</div>
	)
}

type DeviceStatusProps = {
	device: RoomDeviceResponse
}

const RoomDeviceStatus = ({ device }: DeviceStatusProps) => {
	return (
		<div className="RoomDeviceStatus">
			<DeviceWifiStatus wifiQuality={device.wifi_quality} />
			<DeviceBatteryStatus batteryStatus={device.battery} />
		</div>
	)
}

export default RoomDeviceStatus
