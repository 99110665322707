import { useEffect } from "react"

import { AppState, App as CapApp } from "@capacitor/app"

export const useRefresh = (callBack: Function): void => {
	useEffect(() => {
		const listener = CapApp.addListener("appStateChange", (state: AppState) => {
			if (state.isActive) {
				callBack()
			}
		})

		return () => {
			listener
				.then((r) => {
					r.remove()
				})
				.catch(() => {
					console.log("Something went wrong when removing a listener.")
				})
		}
	}, [callBack])

	return
}
