import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { DEFAULT_EMPTY_TABLE_CELL } from "../basic/Table"

import { Aggregation } from "../../redux/reduxUtils"

import { ReactComponent as PersonSVG } from "../../assets/images/icons/Person smallest.svg"

type ReservationSummaryProps = {
	day: Dayjs
	aggregation: Aggregation
}

export function ReservationSummary({
	day,
	aggregation,
}: ReservationSummaryProps) {
	const { t } = useTranslation()
	const dayStr = day.format("YYYY-MM-DD")
	const count: number | undefined = aggregation[dayStr]

	return (
		<td>
			{count ? (
				<div className="attendees">
					<PersonSVG />
					<span>
						{count}{" "}
						{count === 1
							? t("desktop.manage.person")
							: t("desktop.manage.people")}
					</span>
				</div>
			) : (
				<div className="attendees">
					<span className="none">{DEFAULT_EMPTY_TABLE_CELL}</span>
				</div>
			)}
		</td>
	)
}
