import { useEffect, useRef } from "react"

import { useHistory } from "react-router-dom"

export function useRedirectTimeout(url = "/", time = 10 * 1000) {
	const history = useHistory()
	let redirectTimeout = useRef<any>(null)

	useEffect(() => {
		redirectTimeout.current = setTimeout(() => {
			history.push(url)
		}, time)

		return () => {
			clearTimeout(redirectTimeout.current)
		}
	}, [history, time, url])

	return redirectTimeout.current
}
