import React from "react"

import { Dayjs } from "dayjs"
import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import ExportAssetsForm from "../components/Form/ExportAssetsForm"

export type RangeType = {
	start: Dayjs
	end: Dayjs
}

type Props = {
	open: boolean
	range: RangeType
	departmentId?: string
	assetTypeId?: string
}

export default function ExportAssetsModal({
	open,
	range,
	departmentId,
	assetTypeId,
}: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<ExportAssetsForm
				range={range}
				defaultAssetTypeId={assetTypeId}
				defaultDepartmentId={departmentId}
			/>
		</ReactModal>
	)
}
