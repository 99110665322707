import { useCallback, useEffect } from "react"

import { useHistory } from "react-router-dom"
import useWebSocket, { ReadyState } from "react-use-websocket"

import { webSocketTabletURL } from "../../api"
import { TABLET_STATUS_REFRESH_TIME } from "../../constants"
import { VISITOR_BASE_URL } from "../../screens/Visitors/Visitors"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"

import { authenticateTablet } from "../../redux/auth/authSlice"
import { useAppSelector } from "../../redux/reducers"
import { getAuth } from "../../redux/selectors"
import { selectTablet } from "../../redux/tablet/selectors"
import {
	clearPrinter,
	fetchPrinter,
	fetchTablet,
} from "../../redux/tablet/tabletSlice"
import { AuthenticateTabletRequest } from "../../redux/tablet/types"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import { RegistrationResponse } from "../../redux/visitor_registration/types"
import {
	FetchProps,
	RegistrationDataProps,
	fetchBranding,
	fetchQrCode,
	fetchRegistrationData,
	setRegistration,
} from "../../redux/visitor_registration/visitorRegistrationSlice"

type RequestMessageData = {
	type:
		| "REQUEST_TOKEN"
		| "REQUEST_QR_CODE"
		| "REQUEST_DATA"
		| "REQUEST_BRANDING"
}

type PrintMessageData = {
	type: "PRINT_BADGE"
	data: RegistrationResponse
}

type DeviceStatusMessageData = {
	type: "DEVICE_STATUS"
	data: {
		battery_status: number
		battery_charging: boolean
	}
}

type PrinterStatusMessageData = {
	type: "DEVICE_ERROR"
	data: {
		printer: boolean
		description: string
	}
}

type MessageData =
	| RequestMessageData
	| PrintMessageData
	| DeviceStatusMessageData
	| PrinterStatusMessageData

const WebSocketProvider = () => {
	const history = useHistory()

	const { access_token } = useAppSelector(getAuth)
	const { id, buildingId, pin } = useAppSelector(selectTablet)
	const { printError } = useAppSelector(selectVisitorRegistration)

	const actions = useActions({
		fetchQrCode: (props: FetchProps) => fetchQrCode(props),
		fetchBranding: (props: FetchProps) => fetchBranding(props),
		fetchRegistrationData: (props: RegistrationDataProps) =>
			fetchRegistrationData(props),
		fetchTablet: (id: string) => fetchTablet(id),
		fetchPrinter: (id: string) => fetchPrinter(id),
		clearPrinter: () => clearPrinter(),
		authenticateTablet: (body: AuthenticateTabletRequest) =>
			authenticateTablet(body),
		setRegistration: (registration: RegistrationResponse) =>
			setRegistration(registration),
	})

	const { sendJsonMessage, lastJsonMessage, readyState } =
		useWebSocket<MessageData>(
			webSocketTabletURL(id!, access_token!),
			{
				reconnectAttempts: 5,
				shouldReconnect: () => true,
			},
			id !== null && access_token !== null,
		)

	const sendDeviceStatus = useCallback(() => {
		Device.getBatteryInfo().then((info) => {
			sendJsonMessage({
				type: "DEVICE_STATUS",
				data: {
					battery_status: info.batteryLevel
						? Math.round(info.batteryLevel * 100)
						: 0,
					battery_charging: info.isCharging,
				},
			})
		})
	}, [sendJsonMessage])

	const getDevicesData = useCallback(async () => {
		const response = await actions.fetchTablet(id!)

		if (fetchTablet.fulfilled.match(response)) {
			if (response.payload.connected_device) {
				actions.fetchPrinter(response.payload.connected_device.id)
			} else {
				actions.clearPrinter()
			}
		}
	}, [actions, id])

	useEffect(() => {
		if (lastJsonMessage !== null && id && buildingId) {
			switch (lastJsonMessage.type) {
				case "REQUEST_TOKEN":
					if (pin) {
						Device.getId().then((deviceId) => {
							actions.authenticateTablet({
								device_id: deviceId.identifier,
								pin,
							})
						})
					}
					break
				case "REQUEST_BRANDING":
					actions.fetchBranding({
						buildingId,
						tabletId: id,
					})
					break
				case "REQUEST_DATA":
					getDevicesData()
					break
				case "REQUEST_QR_CODE":
					actions.fetchQrCode({
						buildingId,
						tabletId: id,
					})
					break
				case "PRINT_BADGE":
					actions
						.fetchRegistrationData({
							buildingId,
							tabletId: id,
							touchless_pin: null,
							invite_id: null,
						})
						.then((response) => {
							if (fetchRegistrationData.fulfilled.match(response)) {
								if (response.payload.printer_settings?.enable_badge_printing) {
									actions.setRegistration(lastJsonMessage.data)
									history.push(`${VISITOR_BASE_URL}/print`)
								}
							}
						})
					break
			}
		}
	}, [history, actions, getDevicesData, id, buildingId, pin, lastJsonMessage])

	useEffect(() => {
		if (readyState === ReadyState.OPEN && Capacitor.isNativePlatform()) {
			sendDeviceStatus()

			const timer = setInterval(() => {
				sendDeviceStatus()
			}, TABLET_STATUS_REFRESH_TIME * 1000)

			return () => clearInterval(timer)
		}
	}, [sendDeviceStatus, readyState])

	useEffect(() => {
		if (readyState === ReadyState.OPEN && printError) {
			sendJsonMessage({
				type: "DEVICE_ERROR",
				data: {
					printer: true,
					description: "Printer error",
				},
			})
		}
	}, [sendJsonMessage, readyState, printError])

	return null
}

export default WebSocketProvider
