import { RootState } from "../reducers"
import { RoomSettings, VisitorSettings } from "./types"
import { createSelector } from "@reduxjs/toolkit"

export const selectExperiments = (state: RootState) => state.experiments

export const selectVisitorSettings = createSelector(
	selectExperiments,
	(experiments) => {
		const { isLoading, isLoaded } = experiments
		const visitorSettings = experiments.entries.find(
			(r) => r.data.type === "VISITOR_SETTINGS",
		)
		if (visitorSettings && isVisitorSetting(visitorSettings)) {
			return { isLoading, isLoaded, visitorSettings }
		}
		return { isLoading, isLoaded }
	},
)

export const selectRoomsSettings = createSelector(
	selectExperiments,
	(experiments) => {
		const { isLoading, isLoaded } = experiments
		const roomsSettings = experiments.entries.find(
			(r) => r.data.type === "ROOMS_SETTINGS",
		)

		if (roomsSettings && !isVisitorSetting(roomsSettings)) {
			return { isLoading, isLoaded, roomsSettings }
		}
		return { isLoading, isLoaded }
	},
)

const isVisitorSetting = (
	value: VisitorSettings | RoomSettings,
): value is VisitorSettings =>
	value.data.type === "VISITOR_SETTINGS" ? true : false
