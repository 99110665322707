import { FC, Ref, forwardRef } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { AmenityResponse } from "../../redux/amenities/types"
import { Reservation } from "../../redux/shareable/types"

import FillSpace from "../../components/FillSpace"

import "./DeskListItem.sass"

type DeskListItemProps = {
	name: string
	reserved: boolean
	index?: number
	reservations?: Reservation[]
	amenities: AmenityResponse[]
}

const DeskListItem = forwardRef(
	(
		{ name, reserved, index, reservations, amenities }: DeskListItemProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const { t } = useTranslation()
		const hasReservations: boolean = Boolean(
			reservations && reservations.length > 0,
		)

		const deskItemClass = classNames({
			"desk-list-item": true,
		})
		const itemIconClass = classNames({
			"desk-list-icon": true,
			reserved,
		})

		return (
			<div className={deskItemClass} ref={ref}>
				<div className="desk-info">
					<div className={itemIconClass}>{index}</div>
					<div className="desk-name">{name}</div>
					{!reserved && (
						<div className="free">
							{t("desktop.shareable.shareable_free_desk")}
						</div>
					)}
				</div>
				{amenities.length > 0 && <DeskAmenities amenities={amenities} />}
				{reserved && (
					<div className="reservation-details">
						{hasReservations &&
							reservations?.map((r, i) => <ReservationItem {...r} key={i} />)}
					</div>
				)}
			</div>
		)
	},
)

const ReservationItem: FC<Reservation> = ({ user, start, end }) => {
	const timeSlot = `${dayjs(start).format("LT")} - ${dayjs(end).format("LT")}`

	return (
		<div className="reservation-item">
			<div className="name">
				{user?.email
					? `${user.first_name ?? ""} ${user.last_name ?? ""}`
					: "Anonymous"}
			</div>
			<FillSpace />
			<div className="time-slot">{timeSlot}</div>
		</div>
	)
}

export default DeskListItem

type DeskAmenitiesProps = {
	amenities: AmenityResponse[]
}
const DeskAmenities: FC<DeskAmenitiesProps> = ({ amenities }) => {
	return (
		<div className="amenities">
			{amenities.map((a, i) => (
				<div className="amenity" key={i}>
					{a.name}
				</div>
			))}
		</div>
	)
}
