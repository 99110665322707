// do similar to ../timeslots/index.ts
import { api } from ".."
import { amenitiesURL, amenityURL } from "../../../api"
import { AmenityResponse } from "../../amenities/types"
import { PaginatedOptions } from "../../reduxUtils"
import { AmenitiesResponse } from "./types"

const FETCH_DEFAULTS = {
	limit: 20,
	offset: 0,
}

export const amenities = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchAmenities: builder.query<
			AmenitiesResponse & { offset: number },
			(PaginatedOptions & { search?: string }) | void
		>({
			query: (options) => ({
				url: amenitiesURL({ ...FETCH_DEFAULTS, ...options }),
			}),
			providesTags: (result, _error, arg) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "Amenities" as const,
								id,
							})),
							{ type: "Amenities", id: "LIST" },
					  ]
					: [{ type: "Amenities", id: "LIST" }],
			transformResponse: (response: AmenitiesResponse, meta, arg) => {
				return { ...response, offset: arg?.offset ?? 0 }
			},
		}),
		fetchAmenity: builder.query<AmenityResponse, string>({
			query: (id) => ({
				url: amenityURL(id),
			}),
			providesTags: (_result, _error, id) => [{ type: "Amenities", id }],
		}),
		createAmenity: builder.mutation<AmenityResponse, string>({
			query: (name) => ({
				url: amenitiesURL(),
				method: "POST",
				body: { name },
			}),
			invalidatesTags: [{ type: "Amenities", id: "LIST" }],
		}),
		updateAmenity: builder.mutation<AmenityResponse, AmenityResponse>({
			query: ({ id, ...body }) => ({
				url: amenityURL(id),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "Amenities", id: "LIST" },
				{ type: "Amenities", id },
			],
		}),
		deleteAmenity: builder.mutation<void, string>({
			query: (id) => ({
				url: amenityURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "Amenities", id: "LIST" },
				{ type: "Amenities", id },
			],
		}),
	}),
})

export const {
	useFetchAmenitiesQuery,
	useLazyFetchAmenitiesQuery,
	useFetchAmenityQuery,
	useCreateAmenityMutation,
	useUpdateAmenityMutation,
	useDeleteAmenityMutation,
} = amenities
