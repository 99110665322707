import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./mobileModalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import FilterSelector, {
	Filter,
} from "../../components/Mobile/Filter/FilterSelector"

type Props = {
	open: boolean
	onChange: (filter: Filter) => void
	modalId: string
	filter: Filter
}

export default function FilterModal({ open, onChange, filter }: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<FilterSelector onChange={onChange} filter={filter} />
		</ReactModal>
	)
}
