import React from "react"

import querystring from "query-string"
import { useHistory } from "react-router-dom"

import PlanVariationCard from "./PlanVariationCard"

import {
	CURRENCIES,
	SUBSCRIPTION_PERIODS,
} from "../../../../redux/api/billing/constants"
import { useFetchPlansQuery } from "../../../../redux/api/billing/plans"
import {
	PlanType,
	SubscriptionPeriod,
} from "../../../../redux/api/billing/types"
import { getPlanGroup } from "../../../../redux/api/billing/utils"

import "./SubscriptionFlow.sass"

const { parse } = querystring

/**
 *
 * THIS IS temp component and will be changed after the new change subscription flow is implemented
 */
const SubscriptionFlow = () => {
	const { data: plans } = useFetchPlansQuery()
	const {
		location: { search },
	} = useHistory()

	const {
		planType,
		period = SUBSCRIPTION_PERIODS.MONTH,
		currency = CURRENCIES.EUR,
	} = parse(search)

	if (!plans) {
		return null
	}
	const planGroup = getPlanGroup(planType as PlanType)
	// this is temp code to get plans
	const roomGroupPlans = plans
		.filter((plan) => plan.planGroup === planGroup && plan.visible)
		.map((plan) => ({
			...plan,
			plans: plan.plans.find(
				(p) => p.period_unit === period && p.currency === currency,
			),
		}))

	return (
		<div className="SubscriptionFlow">
			{roomGroupPlans.map((groupPlans, index) => {
				if (!groupPlans.plans) {
					return null
				}

				return (
					<PlanVariationCard
						planType={groupPlans.plan_type}
						subscriptionPeriod={period as SubscriptionPeriod}
						isCurrentPlan={planType === groupPlans.plan_type}
						isSelected={index === 1}
						plan={groupPlans.plans}
						key={groupPlans.plan_type}
						onSelectPlan={() => {}}
					/>
				)
			})}
		</div>
	)
}

export default SubscriptionFlow
