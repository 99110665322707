import {
	ChangeEvent,
	HTMLAttributes,
	ReactNode,
	forwardRef,
	useCallback,
	useMemo,
} from "react"

import classNames from "classnames"

import { generateString } from "../../../utils"
import { Label } from "./../../Field/Label"

import { ReactComponent as CheckSVG } from "../../../assets/images/icons/CheckboxCheckmark.svg"

import "./style.sass"

export type CheckboxProps = {
	className?: string
	label?: ReactNode
	subLabel?: string
	description?: ReactNode
	onChange?: (value: boolean) => void
	value?: boolean
	hasError?: boolean
	name?: string
	disabled?: boolean
	isSecondary?: boolean
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "value">

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	(
		{
			className,
			onChange,
			label,
			description,
			subLabel,
			value,
			hasError,
			isSecondary = false,
			...props
		},
		ref,
	) => {
		const recognizer = useMemo(() => {
			return props.name || generateString(5)
		}, [props.name])

		const innerOnChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				onChange && onChange(e.target.checked)
			},
			[onChange],
		)

		const sanitizedValue = value ?? false

		return (
			<div
				className={classNames("NewCheckbox", className, {
					checked: sanitizedValue,
					disabled: !!props.disabled,
					error: hasError,
					isSecondary,
				})}
			>
				<div className="control">
					<div className="main">
						<input
							ref={ref}
							{...props}
							id={recognizer}
							type="checkbox"
							onChange={innerOnChange}
							checked={sanitizedValue}
						/>
						{label && (
							<Label
								className="CheckboxLabel"
								forInput={recognizer}
								subText={subLabel}
							>
								{isSecondary && (
									<span className="checkmark">
										{sanitizedValue && <CheckSVG />}
									</span>
								)}
								{label}
							</Label>
						)}
					</div>
					{description && <span className="extra">{description}</span>}
				</div>
			</div>
		)
	},
)
