import { ParseKeys } from "i18next"

import { PLAN_GROUPS, PLAN_TYPES } from "./constants"
import { Subscription } from "./subscriptions/types"
import { PlanGroup, PlanType, SubscriptionUnitType } from "./types"

const ACTIVE_LICENSE_STATUSES = ["active", "in_trial", "non_renewing"]

export const getLicenses = (
	subscriptions: Subscription[],
	type: SubscriptionUnitType,
) =>
	subscriptions
		.filter(
			(s) =>
				ACTIVE_LICENSE_STATUSES.includes(s.status) && s.unit[0].type === type,
		)
		.map((s) => ({
			quantity: s.unit[0].quantity ?? 0,
			quantityUsed: s.unit[0].quantity_used ?? 0,
			renewalDate: s.next_billing_date,
			status: s.status,
		}))

export const getPlanGroup = (planType: PlanType) => {
	switch (planType) {
		case PLAN_TYPES.DESK_ESSENTIALS:
		case PLAN_TYPES.DESK_AND_ASSET_ESSENTIALS:
			return PLAN_GROUPS.DESK_BOOKING

		case PLAN_TYPES.JOAN_ON_TABLET:
			return PLAN_GROUPS.JOAN_ON_TABLET

		case PLAN_TYPES.SIGN_ESSENTIALS:
		case PLAN_TYPES.SIGN_PROFESSIONAL:
			return PLAN_GROUPS.SIGN

		case PLAN_TYPES.VISITOR_PROFESSIONAL:
		case PLAN_TYPES.VISITOR_PROFESSIONAL_EAP:
			return PLAN_GROUPS.VISITOR

		case PLAN_TYPES.ROOM_ESSENTIALS:
		case PLAN_TYPES.ROOM_PROFESSIONAL:
		case PLAN_TYPES.ROOM_ENTERPRISE:
			return PLAN_GROUPS.ROOM

		default:
			return PLAN_GROUPS.DEPRECATED
	}
}

const mapPlanGroupsToKeys = {
	desk: "joan_desk_asset",
	room: "joan_room",
	home: "joan_home",
	jod: "joan_on_display",
	jot: "joan_on_tablet",
	sign: "joan_sign",
	visitor: "joan_visitor",
	//this should not be used, but it's here for backwards compatibility
	deprecated: "joan_room",
}

export const groupToTranslationKey = (group: PlanGroup) => {
	const key = mapPlanGroupsToKeys[group]
	return `desktop.components.subscription_card.${key}` as ParseKeys
}
