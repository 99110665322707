import { SchedulePeopleDataRow } from "."

import { formatUser } from "../../../redux/user/utils"

type Props = {
	peopleRow: SchedulePeopleDataRow
	rowNumber: number
	isCurrentUser: boolean
}

export const PeopleRowHeading = ({
	peopleRow,
	rowNumber,
	isCurrentUser,
}: Props) => {
	const name = formatUser(peopleRow)
	return (
		<div className="PeopleRowHeading RowHeading">
			<div className="row-number">{rowNumber}</div>
			<span className="people" title={name}>
				{name}
				{isCurrentUser && (
					<>
						<span className="you"> You</span>
					</>
				)}
			</span>
		</div>
	)
}
