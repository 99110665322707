import { Building } from "../devices/types"
import { PaginatedResponse } from "../reduxUtils"

export type ScreeningError = {
	detail: string
}

export type ScreeningSuccess = {
	detail: string
}
export type Screening = {
	company: string
	created_at: string
	id: string
	modified_at: string
	passed: boolean
}
export type EmployeeScreeningPayload = Screening & {
	employee: {
		id: string
	}
	survey: JSON
}

export type VisitorScreeningPayload = Screening & {
	visitor: {
		id: string
	}
	survey: JSON
}

export type User = {
	id: number | string
	email: string
	first_name: string
	last_name: string
	groups: Array<string>
}

export type NotifyEmail = {
	email: string
}

export type ScreeningsQuestion = {
	question: string
	positive_answer: "yes" | "no" | ""
}

export type ScreeningsConfig = {
	id?: string
	modified_at?: Date
	enabled: boolean
	send_survey_to: "visitors" | "employees" | "visitors&employees"
	questions: ScreeningsQuestion[]
	updated_by?: User
	notify_emails: NotifyEmail[]
	auto_cancel_desk_reservation: boolean
	instructions: string
	success_msg: string
	failure_msg: string
}

export type ScreeningsConfigData = {
	building_id: string
	enabled: boolean
	questions: ScreeningsQuestion[]
	introduction: string
	success_msg: string
	failure_msg: string
	success_msg_headline?: string
	introduction_headline?: string
	failure_msg_headline?: string
}

export type ScreeningsConfigAction = {
	type: string
	payload: ScreeningsConfig | string
}

/**NEW SCREENING */

export type ScreeningConfigList = {
	id: string
	is_active: boolean
	applies_to: Applies_To[]
	name: string
	created_at: string
}

export type ScreeningConfigListResponse = PaginatedResponse<ScreeningConfigList>

export enum Applies_To {
	VISITORS = "visitors",
	EMPLOYEES = "employees",
}

export type ScreeningConfig = {
	id?: string
	building_id?: string
	is_active?: boolean
	name: string
	applies_to: Applies_To[]
	questions: ScreeningsQuestion[]
	auto_cancel_desk_reservation: boolean
	notify_email?: string[]
	introduction?: string
	introduction_headline?: string
	failure_msg_headline?: string
	failure_msg?: string
}

export type ScreeningConfigResponse = ScreeningConfig & {
	building?: Building
}

export enum Answer {
	NO = "no",
	YES = "yes",
}

export const DEFAULT_QUESTIONS: ScreeningsQuestion[] = [
	{
		question: "question_1",
		positive_answer: Answer.NO,
	},
	{
		question: "question_2",
		positive_answer: Answer.NO,
	},
	{
		question: "question_3",
		positive_answer: Answer.NO,
	},
	{
		question: "question_4",
		positive_answer: Answer.NO,
	},
]

export type ScreeningConfigsBulkRequest = {
	screening_config_ids: string[]
	action: ScreeningConfigsBulkAction
}

export enum ScreeningConfigsBulkAction {
	DELETE = "DELETE",
	ACTIVATE = "ACTIVATE",
	DEACTIVATE = "DEACTIVATE",
	DOWNLOAD_CSV = "download_csv",
}

export type ScreeningConfigExportFetchOptions = {
	start: string
	end: string
	export_for: string
}
