import React from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import EditRoomForm from "./EditRoomForm"
import { skipToken } from "@reduxjs/toolkit/query"

import { useFetchRoomQuery } from "../../../../redux/api/rooms"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import Loader from "../../../../components/basic/Loader"

type ParamsType = {
	key: string
}

const EditRoom = () => {
	const { t } = useTranslation()

	const params = useParams<ParamsType>()

	const { data: room, isFetching: isLoading } = useFetchRoomQuery(
		params?.key ?? skipToken,
	)

	const roomsNotFound = !!params?.key && !room?.key

	return (
		<View>
			<Breadcrumbs
				depth={3}
				values={[
					t("desktop.settings.rooms.title"),
					t("desktop.settings.rooms.rooms.title"),
					room?.key
						? t("desktop.settings.rooms.rooms.form.edit_room_title")
						: t("desktop.settings.rooms.rooms.form.add_room_title"),
				]}
			/>
			<Intro isConstrained>{t("desktop.settings.rooms.rooms.intro")}</Intro>
			<Space size={0.75} />

			{isLoading ? (
				<Loader />
			) : roomsNotFound ? (
				<p>{t("general.not_found.standard")}</p>
			) : (
				<EditRoomForm room={room} />
			)}
		</View>
	)
}

export default EditRoom
