import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import BuildingDeleteModal from "../../modals/BuildingDeleteModal"
import { OptionType } from "../../types/sharedTypes"
import { calculatePercentAmount } from "../../utils"
import Field from "../Field"
import {
	GooglePlacesSelect,
	GooglePlacesSelectType,
} from "../advanced/GooglePlacesSelect"
import { LimitOccupancyDecider } from "../advanced/LimitOccupancyDecider"
import { Input } from "../basic/Input"
import { Select } from "../basic/Select"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { TIMEZONE_OPTIONS } from "./options"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	useCreateBuildingMutation,
	useFetchBuildingsQuery,
	useUpdateBuildingMutation,
} from "../../redux/api/buildings"
import { BuildingResponse } from "../../redux/api/buildings/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import "./BuildingForm.sass"

type Props = {
	building: BuildingResponse | null
}

type FormValues = {
	name: string
	address: GooglePlacesSelectType
	timezone: OptionType<string>
	limit: number | null
}

const BuildingForm = ({ building }: Props) => {
	const { openModal, closeModal } = useModals()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const { id, name, address, latitude, longitude, settings } = building || {}

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? "",
			address: {
				address,
				latitude,
				longitude,
			} as GooglePlacesSelectType,
			timezone: settings?.timezone
				? {
						label: settings?.timezone,
						value: settings?.timezone,
				  }
				: undefined,
			limit: settings?.desk_capacity_limit ?? null,
		},
	})
	const {
		setError,
		control,
		getValues,
		formState: { isSubmitting },
	} = methods

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

	const [updateBuilding] = useUpdateBuildingMutation()
	const [createBuilding] = useCreateBuildingMutation()

	const onCreateClick = useCallback(
		async ({ name, address, limit, timezone }: FormValues) => {
			const response = await createBuilding({
				...address,
				name,
				parking_slots: 0,
				active: true,
				settings: {
					timezone: timezone?.value ?? null,
					desk_capacity_limit: limit,
				},
			})

			if (isRejected(response)) {
				if (isApiResponseError(response.error)) {
					setErrors(response.error.formError, setError, errorToast, {
						"settings.timezone": "timezone",
					})
				}
			} else {
				analyticsEvent(SupportedEvents.LOCATION_ADD, {
					id: response.data.id,
					name,
					total: buildings.length + 1,
				})
				infoToast(
					t("desktop.settings.buildings.building_form.building_created_toast"),
				)
				closeModal()
			}
		},
		[buildings, createBuilding, setError, errorToast, infoToast, t, closeModal],
	)

	const onUpdateClick = useCallback(
		async ({ name, address, limit, timezone }: FormValues) => {
			if (id) {
				const response = await updateBuilding({
					id,
					...address,
					name,
					parking_slots: 0,
					active: true,
					settings: {
						timezone: timezone?.value ?? null,
						desk_capacity_limit: limit,
					},
				})

				if (isRejected(response)) {
					if (isApiResponseError(response.error)) {
						setErrors(response.error.formError, setError, errorToast, {
							"settings.timezone": "timezone",
						})
					}
				} else {
					analyticsEvent(SupportedEvents.LOCATION_UPDATE, {
						id,
						name,
					})
					infoToast(
						t(
							"desktop.settings.buildings.building_form.building_updated_toast",
						),
					)
					closeModal()
				}
			}
		},
		[id, updateBuilding, setError, errorToast, infoToast, t, closeModal],
	)

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			if (building !== null) {
				openModal(BuildingDeleteModal, { building })
			}
		},
		[openModal, building],
	)

	const updateMode = Boolean(building)

	const totalDeskNo = building?.desks_count || 0

	const activeDeskAmount = calculatePercentAmount(
		totalDeskNo,
		getValues().limit || 0,
	)

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="BuildingForm"
				updateMode={updateMode}
				title={
					updateMode
						? t("desktop.settings.buildings.building_form.edit_building")
						: t("desktop.settings.buildings.building_form.new_building")
				}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
				hasConfirmationPrompt={false}
			>
				<Field
					control={control}
					name="name"
					label={t("desktop.settings.buildings.building_form.building_name")}
				>
					{(props) => (
						<Input
							autoFocus
							maxLength={60}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="address"
					label={t("desktop.settings.buildings.building_form.address")}
					subText={t("general.optional")}
				>
					{(props) => (
						<GooglePlacesSelect
							{...props}
							maxLength={200}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field control={control} name="limit">
					{(props) => (
						<LimitOccupancyDecider
							{...props}
							label={t(
								"desktop.settings.buildings.building_form.building_occupancy_limit",
							)}
							infoText={t(
								"desktop.settings.buildings.building_form.building_bookable_desks",

								{ activeDeskAmount, totalDeskNo },
							)}
							maxLength={30}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="timezone"
					label={t("desktop.settings.buildings.building_form.timezone")}
					subText={t("general.optional")}
				>
					{(props) => (
						<Select
							{...props}
							options={TIMEZONE_OPTIONS}
							disabled={isSubmitting}
							clearable
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default BuildingForm
