import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { useFetchAssetsQuery } from "../../redux/api/assets"
import { useFetchServicesQuery } from "../../redux/api/services"
import { selectRoomsSettings } from "../../redux/experiments/selectors"
import { RootState, useAppSelector } from "../../redux/reducers"

import { ReactComponent as AssetSVG } from "../../assets/images/icons/Asset.svg"
import { ReactComponent as ChairSVG } from "../../assets/images/icons/Chair.svg"
import { ReactComponent as RoomSVG } from "../../assets/images/icons/Room.svg"

import "./LaunchBar.sass"

type LaunchButtonProps = {
	onClick?: React.EventHandler<any>
	icon: JSX.Element
}

const LaunchButton: React.FC<React.PropsWithChildren<LaunchButtonProps>> = ({
	onClick,
	icon,
	children,
}) => {
	return (
		<div className="LaunchButton" onClick={onClick}>
			{icon}
			{children}
		</div>
	)
}

export const LaunchBar: React.FC = () => {
	const { t } = useTranslation()

	const { data: services } = useFetchServicesQuery()

	const { entries: rooms } = useAppSelector((state: RootState) => state.rooms)

	const { roomsSettings, isLoaded: areRoomSettingsLoaded } =
		useAppSelector(selectRoomsSettings)

	const { data: { results: assets = [] } = {} } = useFetchAssetsQuery({
		active: true,
	})

	// Room booking is enabled by default, unless the admin disabled it, by
	// setting the ROOM_SETTINGS experiment endpoint value of `enabled` to false
	const roomBookingEnabled =
		roomsSettings === undefined ||
		(roomsSettings &&
			roomsSettings.data &&
			roomsSettings.data.values &&
			roomsSettings.data.values.enabled)

	const showRoomsLaunchButton =
		rooms.length > 0 && areRoomSettingsLoaded && roomBookingEnabled

	const showDesksLaunchButton = services?.desk?.active ?? false
	const showAssetLunchButton: boolean =
		(services?.desk?.active ?? false) && (assets?.length > 0 ?? false)
	const showPrompt = showDesksLaunchButton || showRoomsLaunchButton

	if (!showDesksLaunchButton && !showRoomsLaunchButton) {
		return null
	}

	return (
		<div className="LaunchBar">
			{showPrompt && <div className="lead">{t("mobile.home.welcome")}</div>}

			<div className="actions">
				{showDesksLaunchButton && (
					<NavLink to="/book/desk">
						<LaunchButton icon={<ChairSVG />}>
							{t("mobile.home.book_desk")}
						</LaunchButton>
					</NavLink>
				)}

				{showRoomsLaunchButton && (
					<NavLink to="/book/room">
						<LaunchButton icon={<RoomSVG />}>
							{t("mobile.home.book_room")}
						</LaunchButton>
					</NavLink>
				)}
				{showAssetLunchButton && (
					<NavLink to="/book/asset/time">
						<LaunchButton icon={<AssetSVG />}>
							{t("mobile.home.book_asset")}
						</LaunchButton>
					</NavLink>
				)}
			</div>
		</div>
	)
}
