import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Card.sass"

type Props = {
	className?: any
}
/**
 *
 * @deprecated
 */
const Card = ({ className, children, ...rest }: PropsWithChildren<Props>) => (
	<div
		className={classNames("Card old-card", {
			[className]: !!className,
		})}
		{...rest}
	>
		{children}
	</div>
)

export default Card
