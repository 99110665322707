import React, { useRef, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { AVAILABLE_LANGUAGES } from "../../../i18n"

import { changeLanguage } from "../../../redux/app/appSlice"
import { selectApp } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import { ReactComponent as ChevronDownSVG } from "../../../assets/images/icons/Chevron down.svg"
import { ReactComponent as ChevronSVG } from "../../../assets/images/icons/Chevron.svg"
import { ReactComponent as RadioUncheckedSVG } from "../../../assets/images/icons/Radio unchecked.svg"
import { ReactComponent as WorldSVG } from "../../../assets/images/icons/World.svg"

import "./style.sass"

export type LanguageSelectorProps = {}

const LanguageSelector = () => {
	const { i18n } = useTranslation()
	const [showLanguageOptions, setShowLanguageOptions] = useState(false)
	const languageOptionsRef = useRef<HTMLDivElement>(null)

	useOnClickOutside([languageOptionsRef], () => {
		setShowLanguageOptions(false)
	})

	const actions = useActions({
		changeLanguage: (lang: string) => changeLanguage(lang),
	})

	const { lang: language } = useAppSelector(selectApp)

	const options = AVAILABLE_LANGUAGES.filter((lang) => language !== lang).map(
		(lang) => {
			return {
				label: lang,
				value: lang,
			}
		},
	)

	const onLanguageChange = (value: string) => {
		i18n.changeLanguage(value)
		dayjs.locale(value)
		actions.changeLanguage(value)
		setShowLanguageOptions(false)
	}

	if (!language && options.length < 1) {
		return null
	}

	return (
		<div className="LanguageSelector">
			<div
				onClick={() => {
					setShowLanguageOptions(!showLanguageOptions)
				}}
				className="control"
			>
				<span className="label">
					<WorldSVG /> {language}{" "}
					{showLanguageOptions ? <ChevronDownSVG /> : <ChevronSVG />}
				</span>
			</div>
			{showLanguageOptions && options.length >= 1 && (
				<div className="options" ref={languageOptionsRef}>
					{options.map(({ label, value }, i) => (
						<div
							className={value === language ? "option active" : "option"}
							key={`option-${i}`}
							onClick={() => onLanguageChange(value)}
						>
							<span className="label">
								<RadioUncheckedSVG />
								{label}
							</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default LanguageSelector
