import React, { MouseEvent, useCallback, useMemo } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { buildingsURL, floorsURL } from "../../../../api"
import { useToast } from "../../../../hooks/useToast"
import { IdAndName, OptionType } from "../../../../types/sharedTypes"
import { EMAIL_REGEX } from "../../../../utils"
import { ROOMS_PATHS } from "../constants"
import { AMENITIES } from "./constants"

import {
	useCreateRoomMutation,
	useDestroyRoomMutation,
	useUpdateRoomMutation,
} from "../../../../redux/api/rooms"
import { RoomResponse } from "../../../../redux/api/rooms/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Field from "../../../../components/Field"
import PageForm from "../../../../components/Form/PageFormHook"
import { setErrors } from "../../../../components/Form/formUtils"
import AsyncSelect from "../../../../components/advanced/AsyncSelect"
import { Input } from "../../../../components/basic/Input"
import { Select } from "../../../../components/basic/Select"

type FormValues = {
	key: string
	name: string
	capacity: number
	building: IdAndName | null
	floor: IdAndName | null
	amenities: OptionType[]
}

type Props = {
	room: RoomResponse | undefined
}

const EditRoomForm = ({ room }: Props) => {
	const [createRoom] = useCreateRoomMutation()
	const [updateRoom] = useUpdateRoomMutation()
	const [destroyRoom] = useDestroyRoomMutation()

	const history = useHistory()

	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { key, name, capacity, building, floor, amenities = [] } = room ?? {}

	const selectedAmenities = useMemo(() => {
		const filteredAmenities = (
			Object.keys(AMENITIES).filter((v) =>
				isNaN(Number(v)),
			) as (keyof typeof AMENITIES)[]
		).filter((key) => {
			return amenities.includes(AMENITIES[key])
		})

		return filteredAmenities.map((key) => {
			return {
				value: AMENITIES[key].toString(),
				label: t(
					`desktop.settings.rooms.rooms.form.amenities.${key.toLowerCase()}` as ParseKeys,
				),
			}
		})
	}, [amenities, t])

	const methods = useForm<FormValues>({
		defaultValues: {
			key,
			name,
			capacity,
			building,
			floor,
			amenities: selectedAmenities,
		},
	})
	const {
		control,
		setError,
		formState: { isSubmitting },
		reset,
		watch,
	} = methods

	const watchedBuilding = watch("building")

	const onCreateClick = useCallback(
		async ({ key }: FormValues) => {
			const response = await createRoom({ key })

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
					return
				}
			}

			reset()
			infoToast(
				t("desktop.settings.rooms.rooms.form.toasts.room_updated", {
					name: key,
				}),
			)
			history.push(ROOMS_PATHS.rooms)
		},
		[createRoom, reset, infoToast, t, history, setError, errorToast],
	)

	const onUpdateClick = useCallback(
		async ({ key, name, capacity, building, floor, amenities }: FormValues) => {
			if (!key) {
				return
			}
			const response = await updateRoom({
				key,
				name: name ?? "",
				capacity: capacity.toString(),
				building: building?.id ?? null,
				floor: floor?.id ?? null,
				amenities: amenities.map((amenity) => parseInt(amenity.value)),
			})

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
					return
				}
			}
			infoToast(t("desktop.settings.rooms.rooms.form.toasts.room_updated"))
			history.push(ROOMS_PATHS.rooms)
		},
		[updateRoom, infoToast, t, history, setError, errorToast],
	)

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			if (!key) {
				return
			}

			const response = await destroyRoom(key)

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
					return
				}
			}
			infoToast(t("desktop.settings.rooms.rooms.form.toasts.room_deleted"))
			history.push(ROOMS_PATHS.rooms)
		},
		[key, destroyRoom, infoToast, t, history, setError, errorToast],
	)

	const amenitiesOptions = useMemo(
		() =>
			(
				Object.keys(AMENITIES).filter((v) =>
					isNaN(Number(v)),
				) as (keyof typeof AMENITIES)[]
			).map((key) => ({
				label: t(
					`desktop.settings.rooms.rooms.form.amenities.${key.toLowerCase()}` as ParseKeys,
				),
				value: AMENITIES[key].toString(),
			})),
		[t],
	)

	const updateMode = !!key

	return (
		<FormProvider {...methods}>
			<PageForm
				className="PairRoomDeviceForm"
				updateMode={updateMode}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
				backUrl={ROOMS_PATHS.rooms}
				disabled={isSubmitting}
			>
				<Field
					control={control}
					name="key"
					label={t("desktop.settings.rooms.rooms.form.fields.id")}
					rules={{
						required: t("desktop.settings.rooms.rooms.form.fields.id_required"),
						pattern: {
							value: EMAIL_REGEX,
							message: t(
								"desktop.settings.rooms.rooms.form.fields.invalid_email_format",
							),
						},
					}}
				>
					{(props) => <Input {...props} />}
				</Field>

				{updateMode && (
					<>
						<Field
							control={control}
							name="name"
							label={t("desktop.settings.rooms.rooms.form.fields.name")}
						>
							{(props) => <Input {...props} />}
						</Field>

						<Field
							control={control}
							name="capacity"
							label={t("desktop.settings.rooms.rooms.form.fields.capacity")}
							rules={{
								required: t(
									"desktop.settings.rooms.rooms.form.fields.capacity_required",
								),
							}}
						>
							{(props) => <Input {...props} />}
						</Field>

						<Field
							control={control}
							name="amenities"
							label={t("desktop.settings.rooms.rooms.form.fields.amenities")}
						>
							{(props) => (
								<Select
									{...props}
									isMulti
									options={amenitiesOptions}
									disabled={isSubmitting}
									clearable
								/>
							)}
						</Field>

						<Field
							control={control}
							name="building"
							label={t("desktop.settings.rooms.rooms.form.fields.building")}
						>
							{(props) => (
								<AsyncSelect
									urlGenerator={(fetchOptions) => {
										return buildingsURL(fetchOptions)
									}}
									nothingFoundMessage={t(
										"desktop.settings.rooms.rooms.form.fields.not_found.building",
									)}
									getOptionLabel={(building) => building.name}
									getOptionValue={(building) => building.id}
									fetchLimit={10000}
									clearable
									{...props}
								/>
							)}
						</Field>

						{watchedBuilding?.id && (
							<Field
								control={control}
								name="floor"
								label={t("desktop.settings.rooms.rooms.form.fields.floor")}
							>
								{(props) => (
									<AsyncSelect
										urlGenerator={(fetchOptions) => {
											return floorsURL({
												query: {
													conditions: {
														building_id: watchedBuilding.id,
													},
												},
												...fetchOptions,
											})
										}}
										key={watchedBuilding.id} // using key to trigger refetch when building changes
										nothingFoundMessage={t(
											"desktop.settings.rooms.rooms.form.fields.not_found.floor",
										)}
										getOptionLabel={(floor) => floor.name}
										getOptionValue={(floor) => floor.id}
										fetchLimit={10000}
										clearable
										{...props}
									/>
								)}
							</Field>
						)}
					</>
				)}
			</PageForm>
		</FormProvider>
	)
}

export default EditRoomForm
