import React from "react"

import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from "capacitor-native-settings"
import { useTranslation } from "react-i18next"

import { isNative } from "../../../utils"
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"

import { useAppSelector } from "../../../redux/reducers"
import {
	updatePrivacyMode,
	updatePushEnabled,
} from "../../../redux/user/userSlice"
import { useActions } from "../../../redux/utils"

import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import { Checker } from "../../../components/Mobile/Checker"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Settings.sass"

declare let window: any

const Settings: React.FC = () => {
	const { t } = useTranslation()

	const { isLoading, entry } = useAppSelector((state) => state.user)

	const actions = useActions({
		updatePrivacyMode: (privacyMode: boolean) => updatePrivacyMode(privacyMode),
		updatePushEnabled: (pushEnabled: boolean) => updatePushEnabled(pushEnabled),
	})

	const handlePrivateValue = () => {
		const newValue = !entry.privacy_mode_enabled

		actions.updatePrivacyMode(newValue)
	}

	const handlePushEnable = async () => {
		const newValue = !entry.push_enabled
		if (newValue) {
			const isPushNotificationsAvailable =
				Capacitor.isPluginAvailable("PushNotifications")

			if (isPushNotificationsAvailable) {
				const deviceInfo = await Device.getInfo()

				const isRemoteNotificationEnabled: boolean =
					deviceInfo.operatingSystem === "ios"
						? (await Diagnostic.getRemoteNotificationsAuthorizationStatus()) ===
						  "authorized"
						: await Diagnostic.isRemoteNotificationsEnabled()
				if (!isRemoteNotificationEnabled) {
					if (window.confirm(t("mobile.profile.confirm_settings_change"))) {
						NativeSettings.open({
							optionAndroid: AndroidSettings.ApplicationDetails,
							optionIOS: IOSSettings.App,
						})
					}
				} else {
					actions.updatePushEnabled(newValue)
				}
			} else {
				actions.updatePushEnabled(newValue)
			}
		} else {
			actions.updatePushEnabled(newValue)
		}
	}

	return (
		<SafeViewArea className="MobileSettings">
			<TopNav title={t("mobile.profile.settings")} />
			<div className="body">
				<CategoryRow name={t("mobile.profile.privacy")}>
					<div className="input">
						<div className="text">
							<div className="name">{t("mobile.profile.hide_user_info")}</div>
							<div className="helper-text">
								{t("mobile.profile.hide_user_info_desc")}
							</div>
						</div>
						<div className="action">
							<Checker
								checked={entry.privacy_mode_enabled}
								disabled={isLoading}
								onChange={handlePrivateValue}
							/>
						</div>
					</div>
				</CategoryRow>
				{isNative() && (
					<CategoryRow name={t("mobile.profile.push_notifications")}>
						<div className="input">
							<div className="text">
								<div className="name">
									{t("mobile.profile.push_notifications")}
								</div>
								<div className="helper-text">
									{t("mobile.profile.push_notifications_desc")}
								</div>
							</div>
							<div className="action">
								<Checker
									checked={entry.push_enabled}
									disabled={isLoading}
									onChange={handlePushEnable}
								/>
							</div>
						</div>
					</CategoryRow>
				)}
			</div>
		</SafeViewArea>
	)
}

export default Settings
