import dayjs from "dayjs"
import i18next from "i18next"

import { ResponseError, parseApiError } from "../api/apiUtils"
import { formatErrorResponse } from "../api/utils"
import { Facet, OptionalKeysRecord } from "../types/sharedTypes"
import { AssetScheduleState } from "./asset_schedule/assetScheduleSlice"
import { DeskScheduleState } from "./desk_schedule/deskScheduleSlice"
import { PeopleScheduleState } from "./people_schedule/peopleScheduleSlice"
import { PayloadAction, SerializedError } from "@reduxjs/toolkit"

export interface SliceState {
	isLoaded: boolean
	isLoading: boolean
	error: string | null
	isSubmitting: boolean
	submitError: string | null
}

export interface PaginationState {
	offset: number
	count: number
	next?: string
	previous?: string
}

export const sliceInitialState: SliceState = {
	isLoaded: false,
	isLoading: false,
	error: null,
	isSubmitting: false,
	submitError: null,
}

export const paginationInitialState: PaginationState = {
	offset: 0,
	count: 0,
}

export interface PaginatedResponse<Result> {
	count: number
	next?: string
	previous?: string
	results: Result[]
}

export type Aggregation = Record<string, number>

export interface PaginatedResponseWithAggregation<Result>
	extends PaginatedResponse<Result> {
	aggr: Aggregation[]
}
export interface PaginatedResponseWithAggregationAndFacets<
	Result,
	Facets extends string,
> extends PaginatedResponseWithAggregation<Result> {
	facets: OptionalKeysRecord<Facets, Facet[]>
}

export type PaginatedOptions = {
	limit?: number
	offset?: number
}

export const getErrorMessage = async (response: Response): Promise<string> => {
	let text = await response.text()
	try {
		text = JSON.parse(text)
	} catch {}

	return formatErrorResponse(text || response.statusText)
}

export const getErrorObject = async (
	response: Response,
): Promise<ResponseError> => {
	const json = await response.json()

	return parseApiError(json)
}

export type PayloadWithAction = PayloadAction<
	unknown,
	string,
	| {
			rejectedWithValue: true
	  }
	| ({
			rejectedWithValue: false
	  } & {}),
	SerializedError
>

export const setFetchErrorState = <
	TState extends SliceState,
	TAction extends PayloadWithAction,
>(
	state: TState,
	action: TAction,
): void => {
	state.isLoading = false
	state.isLoaded = true
	state.error = action.error.message ?? getDefaultErrorMessage()
}

export const setFetchSuccessState = <TState extends SliceState>(
	state: TState,
): void => {
	state.isLoading = false
	state.isLoaded = true
	state.error = null
}

export const setSubmitErrorState = <
	TState extends SliceState,
	TAction extends PayloadWithAction,
>(
	state: TState,
	action: TAction,
): void => {
	state.isSubmitting = false
	state.submitError = action.error.message || getDefaultErrorMessage()
}

export const setSubmitSuccessState = <TState extends SliceState>(
	state: TState,
): void => {
	state.isSubmitting = false
	state.submitError = null
}

export const getDefaultErrorMessage = () =>
	i18next.t("mobile.general.something_wrong")

type ScheduleState =
	| PeopleScheduleState
	| AssetScheduleState
	| DeskScheduleState

export const checkInReservation = (
	state: ScheduleState,
	payload: string,
): void => {
	state.schedule.map((key) => {
		return key.schedule.map((day) => {
			return day.reservations.map((reservation) => {
				if (reservation.id === payload) {
					reservation.checked_in = {
						check_in_at: dayjs().toISOString(),
					}
				}
				return reservation
			})
		})
	})
}
