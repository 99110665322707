import { ForwardedRef, forwardRef } from "react"

import { useTranslation } from "react-i18next"
import {
	ActionMeta,
	GetOptionLabel,
	GetOptionValue,
	GroupBase,
	OnChangeValue,
	OptionsOrGroups,
} from "react-select"

import { get } from "../../../api"
import { UrlParameters } from "../../../api/queryBuilder"
import Async from "../../basic/AsyncSelect"
import Select from "react-select/dist/declarations/src/Select"

import { useAppSelector } from "../../../redux/reducers"

type Props<
	TOption extends unknown = unknown,
	TIsMulti extends boolean = false,
> = {
	urlGenerator: (fetchOptions: UrlParameters) => string
	filterResultsFn?: (option: TOption) => boolean
	createGroupedOptions?: (options: TOption[]) => GroupBase<TOption>[]
	placeholder?: string
	characterDelay?: number
	characterDelayMessage?: string
	nothingFoundMessage?: string
	getOptionLabel: GetOptionLabel<TOption>
	getOptionValue: GetOptionValue<TOption>
	className?: string
	id?: string
	isMulti?: TIsMulti
	shouldClear?: (
		options: TOption[],
		value: OnChangeValue<TOption, TIsMulti> | undefined,
	) => boolean
	value?: OnChangeValue<TOption, TIsMulti>
	onChange?: (
		newValue: OnChangeValue<TOption, TIsMulti> | null,
		actionMeta?: ActionMeta<TOption>,
	) => void
	clearable?: boolean
	disabled?: boolean
	cacheOptions?: boolean
	authenticate?: boolean
	key?: string
	fetchLimit?: number
	isPaginated?: boolean
}

const AsyncSelectInternal = <TOption, TIsMulti extends boolean>(
	{
		placeholder,
		characterDelay,
		characterDelayMessage,
		nothingFoundMessage,
		urlGenerator,
		filterResultsFn,
		clearable,
		createGroupedOptions,
		authenticate = true,
		fetchLimit = 10,
		value,
		onChange,
		shouldClear,
		isPaginated,
		...rest
	}: Props<TOption, TIsMulti>,
	ref: React.ForwardedRef<Select<TOption, TIsMulti>>,
) => {
	const { t } = useTranslation()
	const { access_token } = useAppSelector((state) => state.auth)

	const loadOptions = async (
		search: string | undefined,
		loadedOptions: OptionsOrGroups<TOption, GroupBase<TOption>>,
	) => {
		if (authenticate && !access_token) {
			return {
				options: [],
				hasMore: false,
			}
		}

		if (characterDelay && search && search.trim().length < characterDelay) {
			return {
				options: [],
				hasMore: false,
			}
		}

		const fetchOptions = {
			limit: fetchLimit,
			search,
			...(isPaginated ? { offset: loadedOptions.length } : {}),
		}

		if (search && search.trim().length < 1) {
			delete fetchOptions.search
		}

		const response = await get(urlGenerator(fetchOptions), {}, access_token)
		if (response.ok) {
			const data = await response.json()
			const options = filterResultsFn
				? data.results.filter(filterResultsFn)
				: data.results
			const groupedOptions = createGroupedOptions
				? createGroupedOptions(options)
				: options

			/**
			 * If we refetch and the value isn't in the
			 * options anymore, make sure to clear the
			 * select.
			 */
			if (value && shouldClear?.(options, value)) {
				onChange?.(null)
			}

			return {
				options: groupedOptions,
				hasMore: isPaginated ? data.next !== null : false,
			}
		}

		return {
			options: [],
			hasMore: false,
		}
	}

	return (
		<Async
			ref={ref}
			placeholder={placeholder ?? t("general.select.standard")}
			loadOptions={loadOptions}
			characterDelay={characterDelay}
			characterDelayMessage={
				characterDelay
					? characterDelayMessage ??
					  t("general.enter_at_least", { characterDelay })
					: undefined
			}
			nothingFoundMessage={
				nothingFoundMessage ?? t("general.not_found.standard")
			}
			clearable={clearable}
			value={value}
			onChange={onChange}
			isPaginated={isPaginated}
			{...rest}
		/>
	)
}

/**
 * For AsyncSelect to be typed correctly provide type to the component or set value
 * @example
 *   <AsyncSelect<OptionType> {...props} /> // with provided type normal select
 *   <AsyncSelect<OptionType, true> isMulti {...props} /> // with provided type  multi select
 * @param placeholder - Placeholder text for the input component
 * @param urlGenerator - Function that gets fetchOptions and should return a string that the component can call a GET request with and should return and object in the following form: { results: ArrayOfObjects[] }. The key results is currently hard coded since this is the way Django handles pagination.
 * @param getOptionLabel - Function that returns the label for options
 * @param getOptionValue - Function that returns the value for options
 * @param shouldClear - Function that calculates whether the select should clear its value
 * @param {number} [characterDelay]  - optional number of characters to delay search
 * @param {string} [characterDelayMessage]  - optional text for delay search
 * @param {string} [nothingFoundMessage]  - optional text for nothing found message
 * @param	{string} [extraClassName] - additional class names
 * @param {string} [id] - HTML id passed down to the input element
 * @param {boolean} [isMulti] - changes behavior of the select from normal (false) to multi select (true)
 * @param {TOption} [value] - value of the selected option
 * @param {function} [onChange] - Function is triggered on change
 * @param {boolean} [disabled] - disable the input
 * @param {boolean} [clearable] - can clear the selected option
 * @param {boolean} [cacheOptions] - when true it will cache the options
 * @param {string} [key] - change of key can be used to re-trigger first fetch
 * @param {number} [fetchLimit] - max number of options to fetch
 *
 */

export default forwardRef(AsyncSelectInternal) as <
	TOption,
	TIsMulti extends boolean = false,
>(
	props: Props<TOption, TIsMulti> & {
		ref?: ForwardedRef<Select<TOption, TIsMulti>>
	},
) => ReturnType<typeof AsyncSelectInternal> // The forwardRef does not know how to pass types from component
