import { ToastOptions, toast } from "react-toastify"

import { getDefaultErrorMessage } from "../redux/reduxUtils"

type UseToastResult = {
	errorToast: (message?: string, options?: ToastOptions) => void
	infoToast: (message: string, options?: ToastOptions) => void
	warningToast: (message: string, options?: ToastOptions) => void
}

const DEFAULT_OPTIONS: ToastOptions = { hideProgressBar: true }

export const useToast = (): UseToastResult => {
	const errorToast = (
		message?: string,
		options: ToastOptions = DEFAULT_OPTIONS,
	) => toast.error(message || getDefaultErrorMessage(), options)

	const infoToast = (
		message: string,
		options: ToastOptions = DEFAULT_OPTIONS,
	) => toast.info(message, options)

	const warningToast = (
		message: string,
		options: ToastOptions = DEFAULT_OPTIONS,
	) => toast.warning(message, options)

	return {
		errorToast,
		infoToast,
		warningToast,
	}
}
