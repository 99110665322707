import { useEffect } from "react"

import { toast } from "react-toastify"

import { useScrollToLocation } from "../hooks/useScrollToLocation"
import { Alert } from "./Alert"
import { ConfirmButton } from "./ConfirmButton"
import { Tooltip } from "./Tooltip"
import Button from "./basic/Button"
import Loader from "./basic/Loader"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"
import { selectSsoProviders } from "../redux/ssoProviders/selectors"
import {
	fetchSsoProviders,
	removeSsoProvider,
} from "../redux/ssoProviders/ssoProvidersSlice"
import {
	SsoProviderResponse,
	SsoProviderType,
} from "../redux/ssoProviders/types"
import { useActions } from "../redux/utils"

import { ReactComponent as GoogleSVG } from "../assets/images/icons/GoogleWorkplace.svg"
import { ReactComponent as Office365SVG } from "../assets/images/icons/Office365.svg"

import "./SsoProviders.sass"

const SSO_PROVIDERS: {
	[x: string]: {
		label: string
		icon: JSX.Element
	}
} = {
	google: {
		label: "Google",
		icon: <GoogleSVG />,
	},
	office365: {
		label: "Office 365",
		icon: <Office365SVG />,
	},
}

const getProviderName = (provider: SsoProviderType) => {
	return SSO_PROVIDERS[provider]?.label ?? provider
}

type ProviderProps = {
	provider: SsoProviderType
}

const ProviderLabel = ({ provider }: ProviderProps) => {
	return <b>{getProviderName(provider)}</b>
}

const ProviderIcon = ({ provider }: ProviderProps) => {
	return SSO_PROVIDERS[provider]?.icon ?? null
}

const ProviderStatus = ({ provider, linked }: SsoProviderResponse) => {
	if (linked) {
		return (
			<Tooltip
				uniqueId={provider}
				content={`Connected to ${getProviderName(provider)}`}
			>
				<div className="indicator" />
			</Tooltip>
		)
	}

	return (
		<Tooltip uniqueId={provider} content="Not connected">
			<div className="indicator inactive" />
		</Tooltip>
	)
}

interface ProviderButtonProps extends SsoProviderResponse {
	onConnect: (url: string) => void
	onDisconnect: (provider: SsoProviderType) => void
}

const ProviderButton = ({
	provider,
	link_url,
	linked,
	onConnect,
	onDisconnect,
}: ProviderButtonProps) => {
	const { appName } = useAppSelector(getAppParams)

	if (linked) {
		return (
			<ConfirmButton
				cardHint={
					<>
						<p>
							You are about to disconnect from your{" "}
							<ProviderLabel provider={provider} /> connected account. This
							means that you will not be able to sign in through the{" "}
							<ProviderLabel provider={provider} /> Single Sign-On functionality
							anymore. You will still be able to sign in with your {appName}{" "}
							email and password, or reconnect with your{" "}
							<ProviderLabel provider={provider} /> account later on.
						</p>
						Do you wish to continue?
					</>
				}
				className="btn-sso"
				onConfirm={() => onDisconnect(provider)}
				isSmall
				variant="secondary"
			>
				<ProviderIcon provider={provider} />
				Disconnect from&nbsp;
				<ProviderLabel provider={provider} />
			</ConfirmButton>
		)
	}

	return (
		<Button
			variant="secondary"
			isSmall
			onClick={() => onConnect(link_url)}
			className="btn-sso"
		>
			<ProviderIcon provider={provider} />
			Connect to&nbsp;
			<ProviderLabel provider={provider} />
		</Button>
	)
}

export const SsoProviders = () => {
	useScrollToLocation()

	const { ssoProviders, isLoaded, isLoading, error } =
		useAppSelector(selectSsoProviders)
	const actions = useActions({
		fetchSsoProviders: () => fetchSsoProviders(),
		removeSsoProvider: (provider: SsoProviderType) =>
			removeSsoProvider(provider),
	})

	const onConnect = (link_url: string) => {
		let next = window.location.href

		if (!next.endsWith("#connected-accounts")) {
			next += "#connected-accounts"
		}

		window.location.href = `${link_url}&next=${encodeURIComponent(next)}`
	}

	const onDisconnect = async (provider: SsoProviderType) => {
		const response = await actions.removeSsoProvider(provider)

		if (removeSsoProvider.fulfilled.match(response)) {
			toast.info(`${getProviderName(provider)} successfully unpaired.`, {
				hideProgressBar: true,
			})
		} else {
			toast.error(response.error.message, { hideProgressBar: true })
		}

		await actions.fetchSsoProviders()
	}

	useEffect(() => {
		actions.fetchSsoProviders()
	}, [actions])

	const renderProvider = (props: SsoProviderResponse) => {
		return (
			<div className="provider" key={props.provider}>
				<ProviderButton
					{...props}
					onConnect={onConnect}
					onDisconnect={onDisconnect}
				/>
				<ProviderStatus {...props} />
			</div>
		)
	}

	return (
		<div className="SsoProviders" id="connected-accounts">
			{!isLoaded && <Loader />}
			{isLoaded && ssoProviders.map(renderProvider)}
			{!error && !isLoading && isLoaded && !ssoProviders.length && (
				<div>No providers found.</div>
			)}
			{error && (
				<Alert type="error">
					Could not load providers. Please contact support.
				</Alert>
			)}
		</div>
	)
}
