import { ForwardedRef, forwardRef } from "react"

import { OptionType } from "../../../types/sharedTypes"
import Option from "./Option"

import "./style.sass"

type OptionTypeWithDisabled<T> = OptionType<T> & {
	isDisabled?: boolean
}

export type OptionsProps<Value> = {
	options: OptionTypeWithDisabled<Value>[]
	value: Value
	name?: string
	onChange: (v: Value) => void
	isDisabled?: boolean
}

const Options = <Value,>(
	{
		options,
		value,
		name,
		onChange,
		isDisabled: areOptionsDisabled,
	}: OptionsProps<Value>,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {
	return (
		<div className="Options" ref={ref}>
			{options.map(({ isDisabled, ...opt }, i) => {
				const onClick = () => onChange(opt.value)
				const isActive = value === opt.value
				const isOptionDisabled = isDisabled || areOptionsDisabled

				const isLocked = isActive && areOptionsDisabled

				return (
					<Option
						isActive={isActive}
						onClick={!isOptionDisabled ? onClick : undefined}
						isDisabled={isOptionDisabled}
						isLocked={isLocked}
						key={`option-${i}`}
						name={name}
					>
						{opt.label}
					</Option>
				)
			})}
		</div>
	)
}

export default forwardRef(Options) as <Value>(
	props: OptionsProps<Value> & {
		ref?: ForwardedRef<HTMLDivElement>
	},
) => ReturnType<typeof Options>
