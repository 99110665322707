import React from "react"

import { Route } from "react-router-dom"

import AssetReservationDetail from "./Home/AssetReservationDetail"
import EventDetail from "./Home/EventDetail"
import InviteDetail from "./Home/InviteDetail"
import ReservationDetail from "./Home/ReservationDetail"
import ReservationMap from "./Home/ReservationMap"
import Reservations from "./Home/Reservations"
import VisitorDetail from "./Home/VisitorDetail"

import { AnimatedSwitch } from "../../components/Mobile/AnimatedSwitch"

import "./Home.sass"

const Home: React.FC = () => {
	return (
		<div className="Home">
			<AnimatedSwitch>
				<Route exact path="/home/reservations" component={Reservations} />
				<Route
					exact
					path="/home/reservation/:id"
					component={ReservationDetail}
				/>
				<Route
					exact
					path="/home/asset-reservation/:id"
					component={AssetReservationDetail}
				/>
				<Route
					exact
					path="/home/reservation/:id/map"
					component={ReservationMap}
				/>
				<Route exact path="/home/event/:id" component={EventDetail} />
				<Route exact path="/home/visitor/:id" component={VisitorDetail} />
				<Route exact path="/home/invite/:id" component={InviteDetail} />
			</AnimatedSwitch>
		</div>
	)
}

export default Home
