import { useEffect } from "react"

import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { shadeColor } from "../utils"

import {
	useFetchCompanyQuery,
	useLazyFetchCompanyQuery,
} from "../redux/api/company"
import { selectIsMobile } from "../redux/app/selectors"
import { fetchCompanyConsent } from "../redux/consent/consentSlice"
import { fetchEventsRooms } from "../redux/events/eventsSlice"
import { fetchExperiments } from "../redux/experiments/experimentsSlice"
import { fetchFeatureFlags } from "../redux/feature_flags/featureFlagsSlice"
import { useAppSelector } from "../redux/reducers"
import { fetchSCIM } from "../redux/scim/scimSlice"
import { selectIsAuth } from "../redux/selectors"
import { selectSettingsEffective } from "../redux/settings/selectors"
import { fetchSettings } from "../redux/settings/settingsSlice"
import { fetchUserExperiments } from "../redux/user_experiments/userExperimentsSlice"
import { useActions } from "../redux/utils"

function CompanyProvider() {
	const actions = useActions({
		fetchSettings: () => fetchSettings(),
		fetchSCIM: () => fetchSCIM(),
		fetchEventsRooms: () => fetchEventsRooms(),
		fetchExperiments: () => fetchExperiments(),
		fetchUserExperiments: () => fetchUserExperiments(),
		fetchConsent: () => fetchCompanyConsent(),
		fetchFeatureFlags: () => fetchFeatureFlags(),
	})

	const isAuth = useAppSelector(selectIsAuth)
	useFetchCompanyQuery(undefined, {
		skip: !isAuth,
	})
	const [getCompany, { isSuccess }] = useLazyFetchCompanyQuery()
	const { isLoaded: areSettingsLoaded, entry: settings } = useAppSelector(
		selectSettingsEffective,
	)

	const { isLoaded: areFeatureFlagsLoaded, isWithoutAccessToken } =
		useAppSelector((state) => state.featureFlags)

	const { isLoaded: isSCIMLoaded } = useAppSelector((state) => state.scim)

	const { isLoaded: areRoomsLoaded } = useAppSelector((state) => state.rooms)

	const { isLoaded: areExperimentsLoaded } = useAppSelector(
		(state) => state.experiments,
	)

	const { isLoaded: areUserExperimentsLoaded } = useAppSelector(
		(state) => state.user_experiments,
	)

	const isMobile = useAppSelector(selectIsMobile)

	const { isLoaded: isConsentLoaded, isLoading: isConsentLoading } =
		useAppSelector((state) => state.consent)

	const isColorBrandingEnabled = useCheckForFeatureFlag(
		"colorBranding.isEnabled",
	)

	useEffect(() => {
		if (!isSuccess && isAuth) {
			getCompany()
		}
	}, [isSuccess, isAuth, getCompany])

	useEffect(() => {
		if (!areSettingsLoaded && isAuth) {
			actions.fetchSettings()
		}
	}, [areSettingsLoaded, isAuth, actions])

	useEffect(() => {
		// check if flags were fetched without access token
		if ((!areFeatureFlagsLoaded || isWithoutAccessToken) && isAuth) {
			actions.fetchFeatureFlags()
		}
	}, [areFeatureFlagsLoaded, isWithoutAccessToken, isAuth, actions])

	useEffect(() => {
		if (!isConsentLoaded && !isConsentLoading && isAuth) {
			actions.fetchConsent()
		}
	}, [actions, isConsentLoaded, isAuth, isConsentLoading])

	useEffect(() => {
		if (!areRoomsLoaded && isAuth && isMobile) {
			actions.fetchEventsRooms()
		}
	}, [actions, isAuth, areRoomsLoaded, isMobile])

	useEffect(() => {
		if (!areExperimentsLoaded && isAuth) {
			actions.fetchExperiments()
		}
	}, [actions, isAuth, areExperimentsLoaded])

	useEffect(() => {
		if (!areUserExperimentsLoaded && isAuth) {
			actions.fetchUserExperiments()
		}
	}, [actions, isAuth, areUserExperimentsLoaded])

	useEffect(() => {
		if (!isSCIMLoaded && isAuth) {
			actions.fetchSCIM()
		}
	}, [actions, isSCIMLoaded, isAuth])

	useEffect(() => {
		if (
			!areFeatureFlagsLoaded ||
			!isColorBrandingEnabled ||
			!settings?.company_color ||
			!document
		) {
			return
		}
		document.documentElement.style.setProperty(
			"--primary-color",
			settings.company_color,
		)
		document.documentElement.style.setProperty(
			"--primary-darker-color",
			shadeColor(settings.company_color, 1.1),
		)
		document.documentElement.style.setProperty(
			"--primary-lighter-color",
			shadeColor(settings.company_color, 0.9),
		)
	}, [settings?.company_color, areFeatureFlagsLoaded, isColorBrandingEnabled])

	return null
}

export default CompanyProvider
