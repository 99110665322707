import { RootState } from "../reducers"
import { createSelector } from "@reduxjs/toolkit"

export const getAssetReservationSelector = (state: RootState) =>
	state.assetReservation

export const selectAssetReservationById = (id: string) =>
	createSelector(
		getAssetReservationSelector,
		({ reservations, isLoaded, isLoading, error }) => ({
			isLoading,
			isLoaded,
			reservation: reservations[id],
			error,
		}),
	)
