import { ThunkApiConfig } from "RootType"

import { assetReservationsGetURL, get } from "../../../api"
import { AssetReservation } from "../../asset_reservation/types"
import {
	PaginatedResponse,
	PaginationState,
	SliceState,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../../reduxUtils"
import { FetchAssetReservationsProps } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */

export const fetchMyAssetReservations = createAsyncThunk<
	PaginatedResponse<AssetReservation>,
	FetchAssetReservationsProps,
	ThunkApiConfig
>("reservations/fetchMyAssetReservations", async (params, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response: Response = await get(
		assetReservationsGetURL({ ...params, show: "my" }),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})
/**
 *  Slice
 */
export interface ReservationState extends SliceState, PaginationState {
	assetReservations: AssetReservation[]
}

const initialState: ReservationState = {
	assetReservations: [],
	...sliceInitialState,
	...paginationInitialState,
}

const reservationsSlice = createSlice({
	name: "reservations",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMyAssetReservations.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchMyAssetReservations.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(
			fetchMyAssetReservations.fulfilled,
			(state, { payload, meta }) => {
				const { results, count } = payload
				setFetchSuccessState(state)
				state.assetReservations = results
				state.count = count
				state.offset = meta.arg?.offset ?? 0
			},
		)
	},
})

export const reservationsReducer = reservationsSlice.reducer
