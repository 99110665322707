import { PropsWithChildren } from "react"

import classNames from "classnames"

import { colorFromString } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"

import { ReactComponent as EllipseSVG } from "../../assets/images/icons/Ellipse.inner.svg"
import { ReactComponent as SquareSVG } from "../../assets/images/icons/Square.inner.svg"

import "./ReservationData.sass"

const ICON_TYPES = {
	ellipse: EllipseSVG,
	square: SquareSVG,
}

type Props = {
	label: string
	onClick: () => void
	iconType?: "ellipse" | "square"
	showIcon?: boolean
	showOwnership?: boolean
	isYou?: boolean
	isPartial?: boolean
	idString?: string
	isVisit?: boolean
}

const ReservationData = ({
	children,
	label,
	onClick,
	iconType = "ellipse",
	showIcon = true,
	showOwnership = true,
	isYou = false,
	isPartial = false,
	idString = "",
	isVisit = false,
}: PropsWithChildren<Props>) => {
	const iconStyle: any = {
		"--user-color": colorFromString(idString),
	}

	const IconSVG = ICON_TYPES[iconType]

	const reservationClassName = classNames("Reservation", "ReservationData", {
		isPartial: isPartial,
	})

	return (
		<div className={reservationClassName} onClick={() => onClick()}>
			<span className="label">
				{showIcon &&
					(isVisit ? (
						<CorrectIconMapper
							iconType="visitor"
							needsWrap={false}
							className="badge"
						/>
					) : (
						<IconSVG style={iconStyle} />
					))}
				<span className="name">
					{label}
					{showOwnership && isYou && (
						<>
							{" "}
							<span className="you">You</span>
						</>
					)}
				</span>
			</span>
			{children}
		</div>
	)
}

export default ReservationData
