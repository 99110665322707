import React from "react"

import { useTranslation } from "react-i18next"

import PairDeviceForm from "./PairDeviceForm"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const PairRoomDevice = () => {
	const { t } = useTranslation()

	return (
		<View className="PairRoomDevice">
			<Breadcrumbs
				depth={3}
				values={[
					t("desktop.settings.rooms.title"),
					t("desktop.settings.rooms.devices.title"),
					t("desktop.settings.rooms.devices.pair_new_device"),
				]}
			/>
			<Intro isConstrained>
				{t("desktop.settings.rooms.devices.pair_new_device")}
			</Intro>
			<Space size={0.75} />

			<PairDeviceForm />
		</View>
	)
}

export default PairRoomDevice
