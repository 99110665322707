import { BuildingResponse } from "../buildings/types"
import { FloorResponse } from "../floors/types"
import { PaginatedResponse } from "../reduxUtils"

export enum CHECK_INS_TYPES {
	DESK = "desk",
	ASSET = "asset",
}

export type CheckInResponse = {
	id: string // Reservation ID
	name: string
	type: CHECK_INS_TYPES
	building: Pick<BuildingResponse, "id" | "name">
	floor: Pick<FloorResponse, "id" | "name">
	start: string
	end: string
	tz: string
	checkin_at: string
}

export type CheckInsResponse = PaginatedResponse<CheckInResponse>

export type FetchOptions = {
	mode: "open" | "all"
	tz?: string
	checked_in_at: string
}
