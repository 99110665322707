import React, { useEffect } from "react"

import { QRCode } from "react-qr-svg"

import { useAppSelector } from "./redux/reducers"

import "./QRPrinter.sass"

export default function QRPrinter() {
	const { company } = useAppSelector((state) => state.app)

	const targetURL = `${process.env.REACT_APP_URI}/visitor-login/${company}`

	useEffect(() => {
		window.print()
	}, [])

	return (
		<div className="QRPrinter">
			<QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" value={targetURL} />
		</div>
	)
}
