import React, { useEffect } from "react"

import classNames from "classnames"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { fetchAppointment } from "../redux/appointments/appointmentsSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"
import { storeConfiguration } from "../redux/visitor_login/visitorLoginSlice"

import VisitorPINForm from "../components/Form/VisitorLoginForm"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Space from "../components/Space"
import View from "../components/View"

import "./VisitorLogin.sass"

type Params = {
	company_id?: string
}

export default function VisitorLogin({
	match,
	location,
}: RouteComponentProps<Params>) {
	const { params } = match || {}
	const { company_id } = params || {}
	const { search } = location || {}

	const actions = useActions({
		fetchAppointment: (cid: string, pin: string) =>
			fetchAppointment({ cid, pin }),
		storeConfiguration: (params: Record<string, string>) =>
			storeConfiguration(params),
	})

	const { companyName, disableRequestMeeting } = useAppSelector(
		(state) => state.visitor_login,
	)

	useEffect(() => {
		if (typeof search === "string" && search.length > 1) {
			const params = Object.fromEntries(
				new URLSearchParams(search.substring(1)),
			)
			const properParams: Record<string, string> = {}

			Object.keys(params).forEach((key) => {
				const prop = params[key]
				if (prop !== undefined) {
					properParams[key] = Array.isArray(prop) ? prop.join(",") : prop
				}
			})

			actions.storeConfiguration(properParams)
		}
	}, [actions, search])

	const { isLoading } = useAppSelector((state) => state.appointments)

	const history = useHistory()

	const handleSubmit = async (pin: string) => {
		if (company_id && pin) {
			const response = await actions.fetchAppointment(company_id, pin)

			if (
				fetchAppointment.fulfilled.match(response) &&
				response.payload.id &&
				response.payload.visitor_checked_in_datetime
			) {
				toast.info("You have already checked into your appointment.", {
					hideProgressBar: true,
				})
			} else if (
				fetchAppointment.fulfilled.match(response) &&
				response.payload.id
			) {
				toast.info("Found your appointment.", { hideProgressBar: true })

				history.push(
					`/visitor-login/${company_id}/${response.payload.id}/${pin}`,
				)
			} else {
				toast.error("Could not find appointment.", {
					hideProgressBar: true,
				})
			}
		}
	}

	function handleRequestMeeting() {
		history.push(`/visitor-login/${company_id}/new`)
	}

	const visitorClassName = classNames({
		VisitorLogin: true,
		isConstrained: true,
	})

	return (
		<RouteView className={visitorClassName}>
			<Screen>
				<View>
					<Heading isCentered>
						{companyName ? (
							<span>Welcome to {companyName}!</span>
						) : (
							<span>Welcome to our office!</span>
						)}
					</Heading>
					<Intro isCentered>Please log into your appointment.</Intro>

					<Space size={0.75} />

					<VisitorPINForm
						disableRequestMeeting={disableRequestMeeting}
						onRequestAdHoc={handleRequestMeeting}
						onSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</View>
			</Screen>
		</RouteView>
	)
}
