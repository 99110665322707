import { forwardRef, useEffect, useState } from "react"

import { Label } from "../../Field/Label"
import { Checkbox } from "../../basic/Checkbox"
import { Input, InputProps } from "../../basic/Input"

import "./style.sass"

type Props = {
	value: number | null
	onChange: (v: number | null) => void
	infoText: string
} & Omit<InputProps, "onChange" | "value">

export const LimitOccupancyDecider = forwardRef<HTMLInputElement, Props>(
	({ onChange, label, infoText, value, ...props }, ref) => {
		const [showPercent, setShowPercent] = useState(value !== null)

		useEffect(() => {
			if (showPercent) {
				onChange(value === null ? 100 : value)
			} else {
				onChange(null)
			}
		}, [showPercent, value, onChange])

		const innerOnChange = (value: string) => {
			const number = parseInt(value)

			if (number > 100 || number < 0) {
				return
			}

			onChange(showPercent ? number : null)
		}

		const parsedValue = value === null ? "" : value

		return (
			<div className="LimitOccupancyDecider">
				<Checkbox
					value={showPercent}
					onChange={() => setShowPercent(!showPercent)}
					label={label}
				/>
				{showPercent && (
					<div className="NumberField">
						<Input
							{...props}
							value={parsedValue}
							ref={ref}
							type="number"
							min={0}
							max={100}
							onChange={innerOnChange}
						/>
						<Label>% {infoText}</Label>
					</div>
				)}
			</div>
		)
	},
)
