import React, { useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"
import { RecurringType } from "../../types/sharedTypes"
import { Checker } from "./Checker"
import { TopNav } from "./TopNav"
import { Device } from "@capacitor/device"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./RepeatPicker.sass"

enum RepeatPickerEnum {
	ONCE = "once",
}

export type RepeatPickerTypes = RepeatPickerEnum | RecurringType

export const repeatPickerTypes = {
	...RecurringType,
	...RepeatPickerEnum,
}

type RepeatPickerProps = {
	date: Dayjs
	value: RepeatPickerTypes
	until: Dayjs | null
	onPick: (repeatOption: RepeatPickerTypes, untilDate: Dayjs | null) => void
}

export const RepeatPicker: React.FC<RepeatPickerProps> = ({
	date,
	value: defaultValue = repeatPickerTypes.ONCE,
	onPick,
}) => {
	const { t } = useTranslation()

	const [selectedOption, setSelectedOption] = useState(defaultValue)

	const desk_reservation_window_length = useCheckReservationWindowLength()

	let options = Object.values(repeatPickerTypes)

	const onChange = async (option: RepeatPickerTypes, value: string | null) => {
		const deviceInfo = Device.getInfo()
		if ((await deviceInfo).operatingSystem !== "ios") {
			changeDate(option, value)
		}
	}

	const onBlur = async (option: RepeatPickerTypes, value: string | null) => {
		const deviceInfo = Device.getInfo()

		if ((await deviceInfo).operatingSystem === "ios") {
			// Adjust date because iOS doesn't support max and min on date input
			const minLimit = dayjs().format("YYYY-MM-DD")
			const maxLimit = dayjs()
				.add(desk_reservation_window_length ?? 0, "day")
				.format("YYYY-MM-DD")

			if (value !== null && value > maxLimit) {
				value = maxLimit
			}

			if (value !== null && value < minLimit) {
				value = minLimit
			}

			changeDate(option, value)
		}
	}

	const changeDate = (option: RepeatPickerTypes, value: string | null) => {
		if (!value) {
			onPick(option, null)
			return
		}

		const untilDate = dayjs(value)
		if (untilDate.isValid()) {
			onPick(option, untilDate)
		}
	}

	return (
		<div className="RepeatPicker">
			<div className="picker-content">
				<div className="section-head">
					<TopNav
						backArrow={false}
						title={t("mobile.general.repeat_reservation")}
						rightIcon={
							<CrossSVG
								onClick={() => {
									onPick(selectedOption, null)
								}}
							/>
						}
						onTopScreen={false}
					/>
				</div>
				{options.map((option) => {
					return (
						<div className="section" key={option}>
							<div
								className="option"
								onClick={() => {
									setSelectedOption(option)
									if (option === repeatPickerTypes.ONCE) {
										changeDate(option as RepeatPickerTypes, null)
									}
								}}
							>
								<div className="option-name">
									{t(`mobile.general.repeat.${option}` as ParseKeys)}
									{option === repeatPickerTypes.EVERY_DAY_OF_WEEK &&
										dayjs(date).format(" dddd")}
								</div>
								<div className="option-check">
									<Checker checked={option === selectedOption} />
								</div>
								{option !== repeatPickerTypes.ONCE && (
									<input
										type="date"
										className="date-input"
										min={dayjs().format("YYYY-MM-DD")}
										max={dayjs()
											.add(desk_reservation_window_length ?? 0, "day")
											.format("YYYY-MM-DD")}
										onChange={(event: React.ChangeEvent<{ value: string }>) => {
											onChange(option as RepeatPickerTypes, event.target.value)
										}}
										onBlur={(event: React.ChangeEvent<{ value: string }>) => {
											onBlur(option as RepeatPickerTypes, event.target.value)
										}}
									/>
								)}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
