import { AmenityResponse } from "../amenities/types"
import { HereCoords } from "../floors/types"

export type Department = {
	id: string
	name: string
}

export type User = {
	id: string
	first_name: string | null
	last_name: string | null
	email: string | null
}

export type Reservation = {
	user: User
	start: string
	end: string
}

export type Desk = {
	id: string
	coord_x: number
	coord_y: number
	reserved: boolean
	name: string
	departments: Department[]
	reservations: Reservation[]
	amenities: AmenityResponse[]
}

export type ShareableResponse = {
	image: {
		id: string
		url: string
		width: number
		height: number
	}
	floor: {
		id: string
		name: string
	}
	building: {
		id: string
		name: string
	}
	desks: Desk[]
	payload: {
		dark_mode: boolean
		here: {
			coord_x: number
			coord_y: number
		}
	}
	logo?: {
		url: string
	}
}

export type DesksByDepartment = Record<string, Desk[]>

export type Shareable = ShareableResponse & {
	desksByDepartment: DesksByDepartment
}

export type ShareableAccessLog = {
	created_at: string
	ip_address: string
	user_agent: string
}

export type ShareableAccessLogs = Record<string, ShareableAccessLog[]>

export type ShareableAccessLogResponse = {
	count: number
	results: ShareableAccessLog[]
}

export enum ShareableType {
	FLOOR_PLAN = "floor_plan",
}

export type CreateShareableRequest = {
	type: ShareableType
	floor_id: string
	payload: {
		dark_mode: boolean
		here: HereCoords
	}
}
