import { OptionType } from "../../../../types/sharedTypes"

export const ACTIVATION_DATE_OPTIONS = {
	today: "today",
	beginning_month: "beginning_month",
	custom: "custom",
} as const

export const ACTIVATION_DATE_RADIO_OPTIONS: OptionType[] = [
	{
		value: ACTIVATION_DATE_OPTIONS.today,
		label:
			"desktop.settings.billing.pre_paid_code_card.activation_date_radio_options.today",
	},
	{
		value: ACTIVATION_DATE_OPTIONS.beginning_month,
		label:
			"desktop.settings.billing.pre_paid_code_card.activation_date_radio_options.beginning_month",
	},
	{
		value: ACTIVATION_DATE_OPTIONS.custom,
		label:
			"desktop.settings.billing.pre_paid_code_card.activation_date_radio_options.custom",
	},
] as const

export const PREPAID_CODE_ISSUED_FOR = {
	RESELLER: "RESELLER",
	DISTRIBUTOR: "DISTRIBUTOR",
	END_CUSTOMER: "END_CUSTOMER",
} as const
