import { useState } from "react"

import { useTranslation } from "react-i18next"

import { toInternalTime } from "../utils"
import { CorrectIconMapper } from "./CorrectIconMapper"
import Button from "./basic/Button"

import { selectCheckIns } from "../redux/check_ins/selectors"
import { CheckInResponse } from "../redux/check_ins/types"
import { useAppSelector } from "../redux/reducers"

import "./CheckIns.sass"

type Props = {
	handleCheckIn: (checkIn: CheckInResponse) => void
}

export const CheckIns = ({ handleCheckIn }: Props) => {
	const { entries: checkIns } = useAppSelector(selectCheckIns)

	return (
		<div className="CheckIns">
			{checkIns.map((checkIn) => {
				return (
					<CheckIn
						checkIn={checkIn}
						handleCheckIn={handleCheckIn}
						key={checkIn.id}
					/>
				)
			})}
		</div>
	)
}

type CheckInProps = {
	checkIn: CheckInResponse
	handleCheckIn: (checkIn: CheckInResponse) => void
}

const CheckIn = ({ checkIn, handleCheckIn }: CheckInProps) => {
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation()

	const onClick = async () => {
		setLoading(true)
		await handleCheckIn(checkIn)
		setLoading(false)
	}

	return (
		<div className="CheckIn" key={checkIn.id}>
			<div className="Name">
				{checkIn.name}{" "}
				<div className="ResourceIcon">
					<CorrectIconMapper iconType={checkIn.type} />
				</div>
			</div>
			<div className="Description">
				{toInternalTime(checkIn.start)} - {toInternalTime(checkIn.end)}
				{checkIn.building && <span> &middot; {checkIn.building.name}</span>}
				{checkIn.floor && <span> &middot; {checkIn.floor.name}</span>}
			</div>
			<div className="Confirm">
				<Button isLoading={loading} onClick={onClick}>
					{t("mobile.general.check_in")}
				</Button>
			</div>
		</div>
	)
}
