import React, { ChangeEvent, ForwardedRef, forwardRef } from "react"

import { Field } from "./Field"
import { Label } from "./Label"
import { TextInput } from "./TextInput"

type Props = {
	value: string
	onChange: (v: string) => void
	label?: string
	helpText?: string
	maxLength?: number
	canEdit?: boolean
	required?: boolean
}

/**
 * @deprecated
 */
export const PhoneField = forwardRef(
	(
		{ value, onChange, label, helpText, maxLength, canEdit, required }: Props,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (maxLength) {
				onChange(e.target.value.substr(0, maxLength))
			} else {
				onChange(e.target.value)
			}
		}

		return (
			<Field className="PhoneField">
				<Label>{label || "Phone"}</Label>
				<TextInput
					ref={ref}
					type="text"
					value={value}
					onChange={handleChange}
					isDisabled={!canEdit}
					required={required}
				/>
				{helpText && <p>{helpText}</p>}
			</Field>
		)
	},
)
