import { ThunkApiConfig } from "RootType"

import {
	deleteJSON,
	get,
	patchJSON,
	postData,
	postJSON,
	putJSON,
	userURL,
	usersURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	PaginationState,
	SliceState,
	getErrorMessage,
	getErrorObject,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	CSVEntry,
	FetchOptions,
	UserRequest,
	UserResponse,
	UsersResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const ENTRIES_PER_PAGE = 10

const FETCH_DEFAULTS = {
	limit: ENTRIES_PER_PAGE,
	offset: 0,
	search: "",
}

/**
 *  Thunks
 */
export const fetchUsers = createAsyncThunk<
	UsersResponse,
	FetchOptions | undefined,
	ThunkApiConfig
>("users/fetch", async (options, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	options = { ...FETCH_DEFAULTS, ...options }
	// We need to remove empty string because it is used as NONE filter
	if (options.department_id === "") {
		delete options.department_id
	}

	const response = await get(usersURL(options), {}, access_token)

	if (response.ok) {
		const data = await response.json()

		return {
			...options,
			...data,
		}
	}

	return rejectWithValue(await getErrorObject(response))
})

// CREATE USER

export const createUser = createAsyncThunk<
	UserResponse,
	UserRequest,
	ThunkApiConfig<ResponseError>
>("users/create", async (payload, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(usersURL(), { body: [payload] }, access_token)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

// CREATE MULTIPLE USERS

export const batchCreateUsers = createAsyncThunk<
	UserResponse[],
	CSVEntry[],
	ThunkApiConfig
>("users/createBatch", async (users = [], { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(usersURL(), { body: users }, access_token)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

// IMPORT USERS
export const importUsers = createAsyncThunk<
	UserResponse[] | { status: number; errors: Record<string, string[]>[] },
	File,
	ThunkApiConfig
>("users/importCSV", async (file, { getState }) => {
	const { access_token } = getState().auth

	const response = await postData(usersURL(), { body: { file } }, access_token)

	if (response.ok) {
		return await response.json()
	}

	if (response.status === 400) {
		return { status: response.status, errors: await response.json() }
	}

	throw new Error(await getErrorMessage(response))
})

// UPDATE USER
type UserRequestWithEmail = {
	email: string
	payload: UserRequest
}

export const updateUser = createAsyncThunk<
	UserResponse,
	UserRequestWithEmail,
	ThunkApiConfig<ResponseError>
>("users/update", async ({ email, payload }, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		userURL(email),
		{ body: payload },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

type PatchUserRequest = {
	email: string
	payload: Partial<UserRequest>
}
export const patchUser = createAsyncThunk<
	UserResponse,
	PatchUserRequest,
	ThunkApiConfig<ResponseError>
>("users/patch", async ({ email, payload }, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await patchJSON(
		userURL(email),
		{ body: payload },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

export const destroyUser = createAsyncThunk<
	string,
	string,
	ThunkApiConfig<ResponseError>
>("users/destroy", async (email, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await deleteJSON(
		usersURL(),
		{ body: { emails: [email] } },
		access_token,
	)

	if (response.ok) {
		return email
	}

	return rejectWithValue(await getErrorObject(response))
})

/**
 *  Slice
 */

export interface UsersState extends SliceState, PaginationState {
	entries: UserResponse[]
	initialLoad: boolean
}

const initialState: UsersState = {
	entries: [],
	initialLoad: false,
	...sliceInitialState,
	...paginationInitialState,
}

const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUsers.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchUsers.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
			const { results, ...paginationData } = payload
			state = {
				...state,
				...paginationData,
				initialLoad: true,
				entries: results,
			}
			setFetchSuccessState(state)

			return state
		})

		builder.addCase(createUser.fulfilled, (state, { payload }) => {
			state.entries = [...state.entries, payload]
			setSubmitSuccessState(state)
		})

		builder.addCase(batchCreateUsers.fulfilled, (state, { payload }) => {
			state.entries = [...state.entries, ...payload]
			setSubmitSuccessState(state)
		})

		builder.addCase(destroyUser.fulfilled, (state, { payload }) => {
			state.entries = state.entries.filter((u) => u.email !== payload)
			setSubmitSuccessState(state)
		})

		builder.addMatcher(
			isAnyOf(
				createUser.pending,
				batchCreateUsers.pending,
				updateUser.pending,
				patchUser.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				createUser.rejected,
				batchCreateUsers.rejected,
				updateUser.rejected,
				patchUser.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
		builder.addMatcher(
			isAnyOf(updateUser.fulfilled, patchUser.fulfilled),
			(state, { payload }) => {
				state.entries = [
					...state.entries.filter((u) => u.id !== payload.id),
					payload,
				]
				setSubmitSuccessState(state)
			},
		)
	},
})

export const usersReducer = usersSlice.reducer
