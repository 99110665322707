import {
	ChangeEvent,
	ForwardedRef,
	HTMLProps,
	forwardRef,
	useCallback,
	useRef,
} from "react"

import classNames from "classnames"

import { ReactComponent as MinusSVG } from "../../../assets/images/icons/Minus.svg"
import { ReactComponent as PlusSVG } from "../../../assets/images/icons/Plus.svg"

import "./style.sass"

type CommonProps = {
	disabled?: boolean
	className?: string
	onChange?: (value: number) => void
	maxValue?: number
	minValue?: number
	value?: number
	step?: number
	hasError?: boolean
}

type ConditionalProps = HTMLProps<HTMLInputElement>

export type InputNumberProps = CommonProps & Omit<ConditionalProps, "onChange">

enum Direction {
	INCREMENT = "INCREMENT",
	DECREMENT = "DECREMENT",
}

export const InputNumber = forwardRef<HTMLDivElement, InputNumberProps>(
	(
		{
			className,
			onChange,
			disabled,
			minValue = 0,
			maxValue,
			value,
			step = 1,
			hasError,
			...props
		},
		forwardedRef,
	) => {
		const ref = useRef<HTMLInputElement>(null)

		const innerOnChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = parseFloat(e.target.value)

				if (maxValue && value > maxValue) {
					return
				}

				if (minValue && value < minValue) {
					return
				}

				onChange && onChange(value)
			},
			[onChange, minValue, maxValue],
		)

		const updateValue = (direction: Direction) => {
			if (!ref.current) {
				return
			}

			const currentValue = Number(ref.current.value)
			let newValue

			if (direction === Direction.INCREMENT) {
				if (maxValue !== undefined && currentValue + step > maxValue) {
					return
				}
				newValue = currentValue + step
			} else if (direction === Direction.DECREMENT) {
				if (minValue !== undefined && currentValue - step < minValue) {
					return
				}
				newValue = currentValue - step
			}

			if (onChange && newValue !== undefined) {
				onChange(newValue)
			}
		}

		return (
			<div
				ref={forwardedRef}
				className={classNames("InputNumber", className, {
					disabled: !!disabled,
					error: hasError,
				})}
			>
				<button
					className="NumberMinus"
					type="button"
					disabled={disabled}
					onClick={() => updateValue(Direction.DECREMENT)}
				>
					<MinusSVG />
				</button>
				<input
					{...(props as HTMLProps<HTMLInputElement>)}
					ref={ref as ForwardedRef<HTMLInputElement>}
					disabled={disabled}
					className="InputNumberField"
					type="number"
					min={minValue}
					max={maxValue}
					step={step}
					value={value}
					onChange={innerOnChange}
					onClick={() => ref.current?.select()} // Highlight the whole input field for better UX
				/>
				<button
					className="NumberPlus"
					type="button"
					disabled={disabled}
					onClick={() => updateValue(Direction.INCREMENT)}
				>
					<PlusSVG />
				</button>
			</div>
		)
	},
)

export default InputNumber
