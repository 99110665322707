type Props = {
	offset: number
	totalNumberOfItems: number
	entriesPerPage: number
	maxLinks: number
	maxTrailingLinks: number
}

enum PaginatorDisplay {
	LeftVisible,
	MiddleSpread,
	RightVisible,
	NoDots,
}

export type LinkElement = {
	label: string | number
	disabled: boolean
	active: boolean
}

export const useBackendPagination = ({
	offset,
	totalNumberOfItems,
	entriesPerPage,
	maxLinks,
	maxTrailingLinks,
}: Props) => {
	const from = offset + 1
	let to = offset + entriesPerPage
	let hasNext = true
	let hasPrevious = false

	const currentPage =
		offset >= totalNumberOfItems ? -1 : offset / entriesPerPage + 1

	const pageCount = Math.ceil(totalNumberOfItems / entriesPerPage)

	if (totalNumberOfItems - offset <= entriesPerPage) {
		to = offset + (totalNumberOfItems - offset)
		hasNext = false
	}

	if (offset >= entriesPerPage) {
		hasPrevious = true
	}

	const formatLink = (link: number | string): LinkElement => {
		return {
			label: link,
			active: link === currentPage,
			disabled: typeof link === "string",
		}
	}

	const whereToDisplayLink = (): PaginatorDisplay => {
		if (pageCount <= maxLinks) {
			return PaginatorDisplay.NoDots
		}

		if (currentPage <= maxLinks - maxTrailingLinks - 1) {
			return PaginatorDisplay.LeftVisible
		}

		if (
			currentPage >= maxLinks - maxTrailingLinks &&
			currentPage + (maxLinks - maxTrailingLinks * 2) < pageCount
		) {
			return PaginatorDisplay.MiddleSpread
		}

		return PaginatorDisplay.RightVisible
	}

	const generateLinks = () => {
		const links = []

		switch (whereToDisplayLink()) {
			case PaginatorDisplay.NoDots:
				for (let i = 1; i <= pageCount; i++) {
					links.push(formatLink(i))
				}
				break
			case PaginatorDisplay.LeftVisible:
				for (let i = 1; i <= maxLinks - maxTrailingLinks; i++) {
					links.push(formatLink(i))
				}
				links.push(formatLink("..."))
				links.push(formatLink(pageCount))
				break
			case PaginatorDisplay.MiddleSpread:
				{
					links.push(formatLink(1))
					links.push(formatLink("..."))
					const numIterations = maxLinks - maxTrailingLinks * 2
					const spot = Math.ceil(numIterations / 2)
					for (
						let i = currentPage - spot + 1;
						i < currentPage + numIterations - spot + 1;
						i++
					) {
						links.push(formatLink(i))
					}
					links.push(formatLink("..."))
					links.push(formatLink(pageCount))
				}
				break
			case PaginatorDisplay.RightVisible:
				links.push(formatLink(1))
				links.push(formatLink("..."))
				for (let i = pageCount - (maxLinks - 3); i <= pageCount; i++) {
					links.push(formatLink(i))
				}
				break
			default:
				break
		}
		return links
	}

	const calcRowNumber = (index: number) => offset + index + 1

	return {
		from,
		to,
		hasNext,
		hasPrevious,
		paginationLinks: generateLinks(),
		calcRowNumber,
	}
}
