import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from "react"

import { useTranslation } from "react-i18next"

import { useRefresh } from "../../../hooks/mobile/useRefresh"
import { Capacitor } from "@capacitor/core"
import { BarcodeScanner } from "@joan/capacitor-barcode-scanner"

import VisitorContent from "../../../components/Visitors/VisitorContent"
import VisitorFooter from "../../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../../components/Visitors/VisitorHeader"
import VisitorView from "../../../components/Visitors/VisitorView"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"

/**
 * This wrapper makes sure we have the necessary permissions
 * to run the QR code scanner and won't allow the app to
 * render until we get it.
 *
 * Capacitor doesn't offer a permissions library on its own,
 * the external library providers need to provide their own
 * Android permission check.
 *
 * We make sure this component does everything necessary to
 * handle all permission logic.
 */
const QRScannerProvider = ({ children }: PropsWithChildren<unknown>) => {
	const { t } = useTranslation()

	const [isScannerReady, setIsScannerReady] = useState<boolean>()

	const init = useCallback(async () => {
		if (!Capacitor.isNativePlatform()) {
			setIsScannerReady(true)
			return
		}

		const status = await BarcodeScanner.checkPermission({ force: false })

		if (
			!status.granted ||
			status.denied ||
			status.asked ||
			status.neverAsked ||
			status.restricted ||
			status.unknown
		) {
			setIsScannerReady(false)
		} else {
			setIsScannerReady(true)
		}
	}, [])

	const handleGivePermissions = useCallback(async () => {
		const status = await BarcodeScanner.checkPermission({ force: true })

		if (status.granted || status.asked) {
			init()
		}

		if (status.denied) {
			const c = window.confirm(
				t(
					"tablet.visitors.onboarding.screens.camera_permission.reenable_access",
				),
			)

			if (c) {
				BarcodeScanner.openAppSettings()
			}
		}
	}, [init, t])

	useEffect(() => {
		init()
	}, [init])

	useRefresh(init)

	if (isScannerReady === undefined) {
		return <Loader size="large" />
	}

	if (!isScannerReady) {
		return (
			<VisitorView>
				<VisitorHeader />
				<VisitorContent>
					<div className="subtitle">
						{t(
							"tablet.visitors.onboarding.screens.camera_permission.need_camera_permission",
						)}
					</div>
					<div className="info">
						{t(
							"tablet.visitors.onboarding.screens.camera_permission.we_need_access_to_camera_to_scan",
						)}
					</div>
				</VisitorContent>
				<VisitorFooter>
					<Button onClick={handleGivePermissions}>
						{t("tablet.visitors.give_permission")}
					</Button>

					<Button
						variant="link"
						href="https://support.getjoan.com/knowledge/how-to-add-devices"
					>
						{t(
							"tablet.visitors.onboarding.screens.camera_permission.more_about_permissions",
						)}
					</Button>
				</VisitorFooter>
			</VisitorView>
		)
	}

	return <>{children}</>
}

export default QRScannerProvider
