import React, { useEffect, useMemo } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { nameComparator } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"
import Loader from "../basic/Loader"
import SafeViewArea from "./SafeViewArea"
import { TopNav } from "./TopNav"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { BuildingIds } from "../../redux/buildings/types"
import { fetchEventsRooms } from "../../redux/events/eventsSlice"
import { selectEvents } from "../../redux/events/selectors"
import { useAppSelector } from "../../redux/reducers"
import { BuildingRoom } from "../../redux/rooms/types"
import { TimeslotResponse } from "../../redux/timeslots/types"
import { dateRangeOverlaps } from "../../redux/timeslots/utils"
import { selectDefaultUserBuilding } from "../../redux/user/selectors"
import { useActions } from "../../redux/utils"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as LoaderSVG } from "../../assets/images/icons/Loader.svg"

import "./BuildingPicker.sass"

type Props = {
	type: "desk" | "room" | string
	asPage?: boolean
	showAll?: boolean
	showUnknown?: boolean
	date?: Dayjs | null
	timeslot?: Partial<TimeslotResponse> | null
	excludeResId?: string | null
	onPick: (building: any | null) => void
}

export const BuildingPicker = ({
	type,
	asPage = false,
	showAll = false,
	showUnknown = false,
	date,
	timeslot,
	excludeResId,
	onPick,
}: Props) => {
	const { t } = useTranslation()

	const actions = useActions({
		fetchEventsRooms: () => fetchEventsRooms(),
	})

	const { data: { results = [] } = {} } = useFetchBuildingsQuery({
		stats: true,
	})

	const {
		entries: rooms,
		isLoaded: areRoomsLoaded,
		isLoading: areRoomsLoading,
	} = useAppSelector((state) => state.rooms)

	const { entries: events, isLoading: areEventsLoading } =
		useAppSelector(selectEvents)

	const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

	useEffect(() => {
		if (type === "room") {
			actions.fetchEventsRooms()
		}
	}, [actions, type])

	const allBuildingObjects: BuildingRoom[] = rooms
		.filter((room) => room.building && room.building.id)
		.map((room) => room.building!)
		.sort(nameComparator)

	const roomBuildings = allBuildingObjects.reduce(
		(uniqueBuildings: Array<BuildingRoom>, building) => {
			if (!uniqueBuildings.find((b) => b.id === building.id)) {
				return uniqueBuildings.concat(building)
			} else {
				return uniqueBuildings
			}
		},
		[],
	)

	const deskBuildings = useMemo(() => {
		const sortedResults = [...results].sort(nameComparator)
		const defaultBuilding = sortedResults.find(
			(building) => building.id === defaultUserBuilding?.id,
		)

		if (!defaultBuilding) {
			return sortedResults
		}

		return [
			defaultBuilding,
			...sortedResults.filter((building) => building.id !== defaultBuilding.id),
		]
	}, [defaultUserBuilding, results])

	const buildings =
		type === "desk" ? deskBuildings : type === "room" ? roomBuildings : []

	function filterRoomsByBuilding(rooms: Array<any>, building: any) {
		return rooms.filter((ro) => ro.building && ro.building.id === building.id)
	}

	function filterRoomsWithoutBuilding(rooms: Array<any>) {
		return rooms.filter((room) => room.building === null)
	}

	const roomOccupancy = (rooms: Array<any>) => {
		const roomKeys = rooms.map((r) => r.key)

		const dateStr = date?.format("DD-MM-YYYY")
		const from = dayjs(dateStr + " " + timeslot?.from, "DD-MM-YYYY HH:mm")
		const to = dayjs(dateStr + " " + timeslot?.to, "DD-MM-YYYY HH:mm")

		const roomEvents = events.filter(
			(event) =>
				roomKeys.includes(event.resource) &&
				dateRangeOverlaps(
					from.toDate(),
					to.toDate(),
					new Date(event.start),
					new Date(event.end),
				) &&
				(!excludeResId || event.id !== excludeResId),
		)

		return roomEvents.length
	}

	const showOccupancy = (rooms: Array<any>) => {
		if (areEventsLoading) {
			return (
				<div className="occupancy-loading">
					<LoaderSVG />
				</div>
			)
		}

		const occupancy = roomOccupancy(rooms)

		const full = occupancy >= rooms.length

		return (
			<div className="occupancy">
				<span>
					{full
						? t("mobile.general.full")
						: rooms.length - occupancy + " / " + rooms.length}{" "}
					<CorrectIconMapper iconType="room" needsWrap={false} />
				</span>
			</div>
		)
	}

	let fullUnknown = false

	if (type === "room") {
		const selRooms = filterRoomsWithoutBuilding(rooms)
		fullUnknown = roomOccupancy(selRooms) >= selRooms.length
	}

	return (
		<SafeViewArea className="BuildingPicker">
			<TopNav
				backArrow={asPage}
				title={!asPage ? t("mobile.general.choose_location") : ""}
				rightIcon={
					<CrossSVG
						onClick={() => {
							onPick(null)
						}}
					/>
				}
			/>
			<div className="picker-content">
				{asPage && <h2>{t("mobile.general.choose_location")}</h2>}
				{(areRoomsLoaded || type === "desk") && (
					<div>
						{showAll && (
							<div
								className="building-item"
								onClick={() => {
									onPick(null)
								}}
							>
								<div className="building-info">
									<div className="name">
										{t("mobile.general.all_buildings")}
									</div>
								</div>
							</div>
						)}
						{buildings.map((building: any) => {
							let full = false
							if (type === "room") {
								const selRooms = filterRoomsByBuilding(rooms, building)
								full = roomOccupancy(selRooms) >= selRooms.length
							}

							const itemName = classNames({
								"building-item": true,
								disabled: full,
							})

							return (
								<div
									className={itemName}
									key={building.id}
									onClick={() => {
										if (!full) onPick(building)
									}}
								>
									<div className="building-info">
										<div className="name">{building.name}</div>
										{building.address && building.address !== "," && (
											<div className="address">{building.address}</div>
										)}
									</div>
									{type === "room" &&
										showOccupancy(filterRoomsByBuilding(rooms, building))}
								</div>
							)
						})}
						{showUnknown && (
							<div
								className={classNames({
									"building-item": true,
									disabled: fullUnknown,
								})}
								onClick={() => {
									if (!fullUnknown) {
										onPick({
											id: BuildingIds.UNKNOWN,
											name: BuildingIds.UNKNOWN,
										})
									}
								}}
							>
								<div className="building-info">
									<div className="name">{t("mobile.general.unknown")}</div>
								</div>
								{type === "room" &&
									showOccupancy(filterRoomsWithoutBuilding(rooms))}
							</div>
						)}
						{buildings.length === 0 && !showUnknown && (
							<div>{t("mobile.general.no_locations")}</div>
						)}
					</div>
				)}
				{areRoomsLoading && !areRoomsLoaded && <Loader />}
			</div>
		</SafeViewArea>
	)
}
