import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { assetTypesGetURL, departmentsURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import { RangeType } from "../../modals/ExportAssetsModal"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import { DatePicker } from "../basic/DatePicker"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchAssetTypesQuery } from "../../redux/api/assetTypes"
import { useFetchDepartmentsQuery } from "../../redux/api/departments"
import { DepartmentResponse } from "../../redux/api/departments/types"
import { fetchAssetReservationsExport } from "../../redux/asset_reservation/assetReservationSlice"
import { AssetType } from "../../redux/asset_types/types"
import { useActions } from "../../redux/utils"

import "./ExportForm.sass"

type Props = {
	range: RangeType
	defaultAssetTypeId?: string
	defaultDepartmentId?: string
}

type FormValues = {
	start: Date
	end: Date
	department: DepartmentResponse | null
	assetType: AssetType | null
}

const ExportAssetsForm = ({
	range,
	defaultAssetTypeId,
	defaultDepartmentId,
}: Props) => {
	const { closeModal } = useModals()
	const { t } = useTranslation()

	const { errorToast } = useToast()

	const { data: { results: departments = [] } = {} } =
		useFetchDepartmentsQuery()

	const { data: { results: assetTypes = [] } = {} } = useFetchAssetTypesQuery()

	const methods = useForm<FormValues>({
		defaultValues: {
			start: range.start.toDate(),
			end: range.end.toDate(),
			department: defaultDepartmentId
				? departments.find((d) => d.id === defaultDepartmentId)
				: null,
			assetType: defaultAssetTypeId
				? assetTypes.find((a) => a.id === defaultAssetTypeId)
				: null,
		},
	})

	const {
		control,
		formState: { isSubmitting },
		watch,
	} = methods

	const actions = useActions({
		downloadCSV: (
			start: string,
			end: string,
			departmentId?: string,
			assetTypeId?: string,
		) =>
			fetchAssetReservationsExport({
				start,
				end,
				departmentId,
				assetTypeId,
			}),
	})

	const getReportName = (
		start: Date,
		end: Date,
		departmentName: string = t(
			"desktop.manage.asset_booking.download_form.all_departments",
		),
		assetTypeName: string = t(
			"desktop.manage.asset_booking.download_form.all_asset_types",
		),
	) => {
		const startStr = dayjs(start).format("YYYY-MM-DD")
		const endStr = dayjs(end).format("YYYY-MM-DD")

		return `${t(
			"desktop.manage.download_form.report",
		)} ${departmentName} ${assetTypeName} ${startStr} - ${endStr}.csv`
	}

	const onSubmit = async ({
		start,
		end,
		department,
		assetType,
	}: FormValues) => {
		const response = await actions.downloadCSV(
			dayjs(start).startOf("day").toISOString(),
			dayjs(end).endOf("day").toISOString(),
			department?.id,
			assetType?.id,
		)
		if (fetchAssetReservationsExport.rejected.match(response)) {
			errorToast(response.error.message)
			return
		}

		const blob = new Blob([response.payload], {
			type: "text/plain;charset=utf-8",
		})

		await saveAs(
			blob,
			getReportName(start, end, department?.name, assetType?.name),
		)

		closeModal()
	}

	return (
		<FormProvider {...methods}>
			<ModalForm<FormValues>
				className="ExportForm"
				onCreate={onSubmit}
				title={t("desktop.manage.asset_booking.download_form.title")}
				createButtonText={t("general.download")}
			>
				<Field
					control={control}
					className="double-date-field"
					name="start"
					label={t("desktop.manage.download_form.from")}
				>
					{(props) => (
						<DatePicker
							{...props}
							// @ts-ignore https://github.com/orgs/react-hook-form/discussions/7764
							maxDate={watch("end") ?? undefined}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field
					control={control}
					className="double-date-field"
					name="end"
					label={t("desktop.manage.download_form.to")}
				>
					{(props) => (
						<DatePicker
							{...props}
							minDate={watch("start") ?? undefined}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="department"
					label={t("desktop.manage.asset_booking.download_form.department")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => {
								return departmentsURL(fetchOptions)
							}}
							getOptionLabel={(department) => department.name}
							getOptionValue={(department) => department.id}
							clearable
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="assetType"
					label={t("desktop.manage.asset_booking.download_form.asset_type")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => {
								return assetTypesGetURL(fetchOptions)
							}}
							getOptionLabel={(assetType) => assetType.name}
							getOptionValue={(assetType) => assetType.id}
							clearable
							disabled={isSubmitting}
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default ExportAssetsForm
