import { MutableRefObject, useCallback } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { ENTRIES_PER_PAGE } from "../screens/Manage/constants"

const { stringify } = queryString

type Props<
	Filter extends { page?: number },
	ActionProps extends Partial<Filter & { offset: number }>,
> = {
	reqParams: MutableRefObject<Filter>
	fetchCall: (props: ActionProps) => unknown
	path: string
	fetchOptions: Pick<
		ActionProps,
		Exclude<keyof ActionProps, keyof Filter | "offset">
	>
	offset: number
}

export const useFetchPaginatedData = <
	Filter extends { page?: number },
	ActionProps extends Partial<Filter & { offset: number }>,
>({
	reqParams,
	path,
	fetchCall,
	fetchOptions,
	offset,
}: Props<Filter, ActionProps>) => {
	const history = useHistory()

	const page = Math.floor(offset / ENTRIES_PER_PAGE) + 1

	const fetchDataPagination = useCallback(() => {
		const { page: newPage, ...params } = reqParams.current
		const offset = ENTRIES_PER_PAGE * ((newPage ?? 1) - 1)

		fetchCall({
			...params,
			...fetchOptions,
			offset,
		} as ActionProps)
	}, [fetchCall, reqParams, fetchOptions])

	const setPage = useCallback(
		(nextPage: number) => {
			reqParams.current = { ...reqParams.current, page: nextPage }
			history.push(`${path}?${stringify(reqParams.current)}`)
		},
		[history, path, reqParams],
	)

	return {
		setPage,
		fetchDataPagination,
		page,
	}
}
