import React, { useEffect, useRef, useState } from "react"

import classNames from "classnames"
import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { useCalculateBookable } from "../../hooks/useCalculateBookable"
import { nameComparator } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"
import Button from "../basic/Button"
import Loader from "../basic/Loader"
import SafeViewArea from "./SafeViewArea"
import { TopNav } from "./TopNav"

import { useFetchDesksQuery } from "../../redux/api/desks"
import { DeskResponse } from "../../redux/api/desks/types"
import { useFetchFloorsQuery } from "../../redux/api/floors"
import { BuildingResponse } from "../../redux/buildings/types"
import { FloorResponse } from "../../redux/floors/types"
import { TimeslotResponse } from "../../redux/timeslots/types"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as LoaderSVG } from "../../assets/images/icons/Loader.svg"

import "./FloorPicker.sass"

type Props = {
	building: BuildingResponse
	asPage?: boolean
	showAll?: boolean
	showOccupancy?: boolean
	date?: Dayjs | null
	timeslot?: Partial<TimeslotResponse> | null
	excludeResId?: string
	onPick: (floor: FloorResponse | null) => void
}

export const FloorPicker = ({
	building,
	asPage = false,
	showAll = false,
	showOccupancy = false,
	date,
	timeslot,
	excludeResId,
	onPick,
}: Props) => {
	const history = useHistory()

	const wrapperRef = useRef<HTMLDivElement>(null)
	const { t } = useTranslation()

	const [height, setHeight] = useState(0)

	const dateStr = date?.toISOString()

	const { data: { results: desks = [] } = {}, isFetching: isFetchingDesks } =
		useFetchDesksQuery({ building: building.id })

	const { data: { results: floors = [] } = {}, isFetching: isFetchingFloors } =
		useFetchFloorsQuery({ building: building.id, stats: true })

	const desksBookable = useCalculateBookable({
		date: dateStr,
		timeslot: timeslot === null ? undefined : timeslot,
		buildingId: building.id,
		excludeResId,
	})

	const setWrapperHeight = () => {
		if (wrapperRef.current) {
			setHeight(wrapperRef.current.clientHeight)
		}
	}

	useEffect(() => {
		setWrapperHeight()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetchingDesks, isFetchingFloors])

	return (
		<SafeViewArea className="FloorPicker">
			<TopNav
				backArrow={asPage}
				title={
					!asPage
						? t("mobile.general.choose_map")
						: building
						? `${building.name}`
						: ""
				}
				rightIcon={
					<CrossSVG
						onClick={() => {
							onPick(null)
						}}
					/>
				}
			/>
			<div className="FloorPicker__body">
				{asPage && <h2>{t("mobile.general.choose_map")}</h2>}
				{isFetchingFloors || isFetchingDesks ? (
					<Loader variant="fullScreen" />
				) : (
					<>
						<div className="wrapper" ref={wrapperRef}>
							<div className="scrollable" style={{ height }}>
								{showAll && (
									<div
										className="floor-item"
										onClick={() => {
											onPick(null)
										}}
									>
										<div className="name">{t("mobile.general.all_floors")}</div>
									</div>
								)}
								{floors
									.filter(
										(floor: FloorResponse) => (floor.desks_count ?? 0) > 0,
									)
									.sort(nameComparator)
									.map((floor: FloorResponse) => {
										return (
											<FloorItem
												key={floor.id}
												floor={floor}
												onClick={onPick}
												showOccupancy={showOccupancy}
												desksBookable={desksBookable}
												desks={desks.filter(
													(desk) => desk.floor.id === floor.id && desk.active,
												)}
											/>
										)
									})}
								{floors.length === 0 && (
									<div>{t("mobile.general.no_maps")}</div>
								)}
							</div>
						</div>
						{asPage && (
							<div className="choose-another-building">
								<p className="question">{t("mobile.book.not_okay")}</p>
								<div className="next-button">
									<Button
										variant="mobile-action"
										onClick={() => history.push("/book/desk/building")}
									>
										{t("mobile.book.pick_another_building")}
									</Button>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</SafeViewArea>
	)
}

type FloorItemProps = {
	floor: FloorResponse
	onClick: (floor: FloorResponse | null) => void
	showOccupancy: boolean
	desksBookable: Record<string, boolean>
	desks: DeskResponse[]
}

const FloorItem: React.FC<FloorItemProps> = ({
	floor,
	desks = [],
	onClick,
	showOccupancy,
	desksBookable,
}) => {
	const allDesksNo = floor.desks_count ?? 0
	const isLoading = Object.keys(desksBookable).length === 0
	let full = false
	let freeDesksNo = allDesksNo

	if (showOccupancy && !isLoading) {
		freeDesksNo = desks.filter((d) => desksBookable[d.id]).length
		full = freeDesksNo === 0
	}

	const itemName = classNames({
		"floor-item": true,
		disabled: full,
	})

	return (
		<div
			className={itemName}
			onClick={() => {
				if (!full) {
					onClick(floor)
				}
			}}
		>
			<div className="name">{floor.name}</div>
			{showOccupancy && !isLoading && (
				<div className="occupancy">
					<span>
						{freeDesksNo + " / " + allDesksNo}
						<CorrectIconMapper iconType="desk" needsWrap={false} />
					</span>
				</div>
			)}
			{showOccupancy && isLoading && (
				<div className="occupancy-loading">
					<LoaderSVG />
				</div>
			)}
		</div>
	)
}
