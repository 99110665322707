import React from "react"

import { useTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"

import { LabelPaths } from "../../../../constants"
import { useToast } from "../../../../hooks/useToast"
import { getLabel } from "../../../../utils"
import { ROOMS_PATHS } from "../constants"
import DeviceDetailForm from "./DeviceDetailForm"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/query"

import {
	useFetchRoomDeviceQuery,
	useRepairRoomDeviceMutation,
	useUnpairRoomDeviceMutation,
} from "../../../../redux/api/devices"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import RoomDeviceStatus from "../../../../components/Visitors/RoomDeviceStatus"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Button from "../../../../components/basic/Button"
import Loader from "../../../../components/basic/Loader"

import "./DeviceDetail.sass"

type ParamsType = {
	device_id: string
}

const DeviceDetail = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const { device_id } = useParams<ParamsType>()
	const { openModal, closeModal } = useModals()
	const { errorToast, infoToast } = useToast()

	const { data: roomDevice, isFetching: isLoading } = useFetchRoomDeviceQuery(
		device_id ?? skipToken,
	)

	const { id, name = "", type } = roomDevice ?? {}

	const [repairRoomDevice] = useRepairRoomDeviceMutation()
	const [unpairRoomDevice] = useUnpairRoomDeviceMutation()

	// TODO: At the moment, it is not clear how to determine which settings are applied. Additional information is needed to complete the task.
	const settingsStatuses = [
		{
			label: t("desktop.settings.rooms.devices.form.settings_options.features"),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
		{
			label: t(
				"desktop.settings.rooms.devices.form.settings_options.custom_button",
			),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
		{
			label: t(
				"desktop.settings.rooms.devices.form.settings_options.office_hours",
			),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
		{
			label: t("desktop.settings.rooms.devices.form.settings_options.logo"),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
		{
			label: t(
				"desktop.settings.rooms.devices.form.settings_options.localization",
			),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
		{
			label: t(
				"desktop.settings.rooms.devices.form.settings_options.custom_content",
			),
			value: t(
				"desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
			),
		},
	]

	const handleRepair = async () => {
		if (roomDevice?.id) {
			const response = await repairRoomDevice([roomDevice.id])

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					errorToast(error.message)
				}
				return
			}

			infoToast(
				t("desktop.settings.rooms.devices.form.toasts.device_repaired_one"),
			)
		}
	}
	const handleUnpair = async () => {
		if (roomDevice?.id) {
			const response = await unpairRoomDevice([roomDevice.id])

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					errorToast(error.message)
				}
				return
			}

			infoToast(
				t("desktop.settings.rooms.devices.form.toasts.device_unpaired_one"),
			)
			history.push(ROOMS_PATHS.devices)
		}
	}

	const handleUnpairConfirmation = () => {
		openModal(ConfirmationModal, {
			onConfirm: () => {
				handleUnpair()
				closeModal()
			},
		})
	}

	return (
		<View className="RoomDeviceDetail">
			<Breadcrumbs
				depth={name ? 3 : 2}
				values={[
					t("desktop.settings.rooms.title"),
					t("desktop.settings.rooms.devices.title"),
					name,
				]}
				includeParamsAsPath
			/>

			<Space size={0.75} />

			{isLoading ? (
				<div className="loading">
					<Loader />
				</div>
			) : roomDevice ? (
				<div className="device-detail-content">
					<DeviceDetailForm roomDevice={roomDevice} />

					<div className="info-panel">
						<div className="infos">
							<div className="info">
								<div className="info-name">
									{t("desktop.settings.rooms.devices.uuid")}
								</div>
								<div className="info-data">{id}</div>
							</div>
							<div className="info">
								<div className="info-name">
									{t("desktop.settings.rooms.devices.model")}
								</div>
								<div className="info-data">
									{type &&
										getLabel(`deviceTypes.${type.toLowerCase()}` as LabelPaths)}
								</div>
							</div>
							<div className="info">
								<div className="info-name">
									{t("desktop.settings.rooms.devices.status")}
								</div>
								<div className="info-data">
									<RoomDeviceStatus device={roomDevice} />
								</div>
							</div>
						</div>

						<div className="settings">
							{settingsStatuses.map(({ label, value }, idx) => (
								<div className="settings-item" key={idx}>
									<div className="settings-item-label">{label}</div>
									<div className="settings-item-value">{value}</div>
								</div>
							))}

							{/*TODO: Add correct paths for configuration pages (Device/Building/Global settings) */}
							<div className="settings-actions">
								<div>
									<Link
										className="settings-action"
										to="/settings/rooms/device-settings"
									>
										{t(
											"desktop.settings.rooms.devices.form.settings_actions.configure_device_settings",
										)}
									</Link>
								</div>

								<div>
									<Link className="settings-action" to="/settings/">
										{t(
											"desktop.settings.rooms.devices.form.settings_actions.configure_building_settings",
										)}
									</Link>
								</div>

								<div>
									<Link className="settings-action" to="/settings/">
										{t(
											"desktop.settings.rooms.devices.form.settings_actions.configure_global_settings",
										)}
									</Link>
								</div>
							</div>
						</div>

						<div className="actions">
							<Button
								isSmall
								variant="danger-pop"
								noConfirm
								onClick={() => handleUnpairConfirmation()}
							>
								{t("desktop.settings.rooms.devices.actions.un_pair")}
							</Button>
							<Button
								isSmall
								variant="secondary-white"
								onClick={() => handleRepair()}
							>
								{t("desktop.settings.rooms.devices.actions.re_pair")}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div>{t("desktop.settings.rooms.devices.no_device")}</div>
			)}
		</View>
	)
}

export default DeviceDetail
