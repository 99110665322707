import React from "react"

import { useTranslation } from "react-i18next"

import Loader from "../../components/basic/Loader"

import "./Loading.sass"

const Loading: React.FC = () => {
	const { t } = useTranslation()

	return (
		<div className="Loading">
			<div className="body">
				<div className="loader">
					<Loader />
					{t("mobile.general.loading")}
				</div>
			</div>
		</div>
	)
}

export default Loading
