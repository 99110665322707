import { ChangeEvent } from "react"

import { InternalTime } from "../../types/sharedTypes"
import { isInternalTime } from "../../utils"
import { Field } from "./Field"
import { Label } from "./Label"

import "./StartEndField.sass"

type Props = {
	startValue: string
	endValue: string
	helpText?: string | JSX.Element
	canEdit?: boolean
	order?: number
	required?: boolean
	onStartChange: (v: InternalTime) => void
	onEndChange: (v: InternalTime) => void
}

export const StartEndField = ({
	startValue,
	endValue,
	helpText,
	order,
	required = false,
	canEdit = false,
	onStartChange,
	onEndChange,
}: Props) => {
	function handleStartChange(e: ChangeEvent<HTMLInputElement>) {
		if (isInternalTime(e.target.value)) {
			onStartChange(e.target.value)
		}
	}

	function handleEndChange(e: ChangeEvent<HTMLInputElement>) {
		if (isInternalTime(e.target.value)) {
			onEndChange(e.target.value)
		}
	}

	return (
		<Field className="StartEndField">
			<Label>Time</Label>
			<div className="dual-inputs">
				<input
					type="time"
					pattern="[0-9]{2}:[0-9]{2}"
					disabled={!canEdit}
					value={startValue}
					onChange={handleStartChange}
					min="00:01"
					max={endValue}
					required={required}
				/>

				<span>&ndash;</span>

				<input
					type="time"
					pattern="[0-9]{2}:[0-9]{2}"
					disabled={!canEdit}
					value={endValue}
					// onInput={handleEndChange}
					onChange={handleEndChange}
					min={startValue}
					max="23:59"
					required={required}
				/>
			</div>
			{helpText && <p className="HelperText">{helpText}</p>}
		</Field>
	)
}
