import React, { useCallback, useEffect, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { buildingURL, companySettingsURL } from "../../api"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useToast } from "../../hooks/useToast"
import { HealthScreeningExportModal } from "../../modals/HealthScreeningExportModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useAppSelector } from "../../redux/reducers"
import {
	deleteScreeningConfig,
	fetchScreeningConfigList,
	fetchScreeningsConfig,
	screeningConfigsBulkAction,
	updateScreeningsConfigNew,
} from "../../redux/screenings/screeningsSlice"
import { selectScreenings } from "../../redux/screenings/selectors"
import {
	ScreeningConfig,
	ScreeningConfigList,
	ScreeningConfigsBulkAction,
	ScreeningConfigsBulkRequest,
} from "../../redux/screenings/types"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { fetchSettings } from "../../redux/settings/settingsSlice"
import { useActions } from "../../redux/utils"

import Breadcrumbs from "../../components/Breadcrumbs"
import BuildingFilter from "../../components/Filter/BuildingFilter"
import FilterSpace from "../../components/Filter/FilterSpace"
import Filters from "../../components/Filter/Filters"
import SearchFilter from "../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../components/Filter/types"
import Intro from "../../components/Intro"
import Space from "../../components/Space"
import View from "../../components/View"
import { AsyncSwitch } from "../../components/advanced/AsyncToggle"
import Table from "../../components/advanced/Table"
import Button from "../../components/basic/Button"
import Loader from "../../components/basic/Loader"
import MultiActionButton from "../../components/basic/MultiActionButton"
import {
	Action,
	Column,
	DEFAULT_EMPTY_TABLE_CELL,
} from "../../components/basic/Table"

import { ReactComponent as DeviceSVG } from "../../assets/images/icons/Device.svg"

import "./Health.sass"

const DEFAULT_DATE_FORMATTER = "ddd, D MMM, YYYY"

const formatDate = (date: Dayjs | string) => {
	return dayjs(date).format(DEFAULT_DATE_FORMATTER)
}

const Health = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { infoToast, errorToast } = useToast()
	const { openModal } = useModals()

	const { value: buildingFilter, onChange: setBuildingFilter } =
		useLocalStorage("health-settings-building-filter", FilterSpecialValues.ALL)

	const { data: { results: buildings = [] } = {}, refetch: refetchBuildings } =
		useFetchBuildingsQuery()
	const { entry: settings_effective } = useAppSelector(selectSettingsEffective)

	const selectedBuilding =
		buildings.find((building) => building.id === buildingFilter) ?? buildings[0]

	const [selectedRows, setSelectedRows] = useState<ScreeningConfigList[]>([])

	const actions = useActions({
		fetchConfigList: (building_id: string) =>
			fetchScreeningConfigList(building_id),
		fetchConfig: (id: string) => fetchScreeningsConfig(id),
		updateConfig: (settings: ScreeningConfig) =>
			updateScreeningsConfigNew(settings),
		deleteScreeningConfig: (id: string) => deleteScreeningConfig(id),
		screeningConfigsBulkAction: (body: ScreeningConfigsBulkRequest) =>
			screeningConfigsBulkAction(body),
		fetchSettings: () => fetchSettings(),
	})

	useEffect(() => {
		actions.fetchConfigList(buildingFilter)
	}, [actions, buildingFilter])

	const { screeningConfigsListEntries: healthSettings, isLoading } =
		useAppSelector(selectScreenings)

	const [search, setSearch] = useState<string>("")

	const filteredConfigs = useMemo<ScreeningConfigList[]>(() => {
		if (!search) {
			return healthSettings
		}
		return healthSettings.filter((config) =>
			config.name.toLowerCase().includes(search.toLowerCase()),
		)
	}, [search, healthSettings])

	const bulkActionOptions = useMemo(
		() => [
			{
				label: t(
					"desktop.manage.visitors.health_screening.table.actions.delete",
				),
				value: ScreeningConfigsBulkAction.DELETE,
			},
		],
		[t],
	)

	const tableColumns = useMemo<Column<ScreeningConfigList>[]>(() => {
		return [
			{
				field: "name",
				label: t("desktop.manage.visitors.health_screening.table.name"),
				renderCell: (i) => i.name ?? DEFAULT_EMPTY_TABLE_CELL,
			},
			{
				field: "created_at",
				label: t("desktop.manage.visitors.health_screening.table.date_added"),
				renderCell: (i) => formatDate(i.created_at) ?? DEFAULT_EMPTY_TABLE_CELL,
			},
			{
				field: "applies_to",
				label: t(
					"desktop.manage.visitors.health_screening.table.requires_filling_by",
				),
				renderCell: (i) => (
					<span>
						{i.applies_to.map((a, index, array) => (
							<React.Fragment key={a}>
								{t(`desktop.manage.visitors.health_screening.table.${a}`)}
								{index < array.length - 1 && index === 0 && array.length > 1
									? " & "
									: ""}
							</React.Fragment>
						))}
					</span>
				),
			},
			{
				field: "is_active",
				label: t("desktop.manage.visitors.health_screening.table.status"),
				renderCell: (i) => (
					<div className="StatusCell">
						<div className={`indicator ${!i.is_active && "inactive"}`} />
						{!i.is_active
							? t(
									"desktop.manage.visitors.health_screening.table.status_inactive",
							  )
							: t(
									"desktop.manage.visitors.health_screening.table.status_active",
							  )}
					</div>
				),
			},
		]
	}, [t])

	const rowActions = useMemo<Action<ScreeningConfigList>[]>(() => {
		return [
			{
				label: t("desktop.manage.visitors.health_screening.table.actions.edit"),
				onClick: (s) => history.push(`health/edit/${s.id}`),
			},
			{
				label: (s) =>
					t(
						`desktop.manage.visitors.health_screening.table.actions.${
							s.is_active ? "deactivate" : "activate"
						}`,
					),
				onClick: async (s) => {
					const configData = await actions.fetchConfig(s.id)

					if (fetchScreeningsConfig.fulfilled.match(configData)) {
						const response = await actions.updateConfig({
							...configData.payload,
							id: s.id,
							is_active: !s.is_active,
						})
						if (updateScreeningsConfigNew.fulfilled.match(response)) {
							infoToast(
								t(
									"desktop.manage.visitors.health_screening.form.questionnaire_status_updated",
								),
							)
							actions.fetchConfigList(buildingFilter)
						} else {
							if (response.error) {
								if (response.payload)
									Object.keys(response.payload).forEach((err) =>
										errorToast(
											t(
												`desktop.manage.visitors.health_screening.form.errors.${err}` as ParseKeys,
											),
										),
									)
							}
						}
					}
				},
			},
			{
				label: t(
					"desktop.manage.visitors.health_screening.table.actions.download_csv",
				),
				onClick: (i) => {
					if (!selectedRows.length) {
						openModal(HealthScreeningExportModal, {
							screeningConfigsList: [i],
						})
					} else {
						openModal(HealthScreeningExportModal, {
							screeningConfigsList: selectedRows,
						})
					}
				},
			},
			{
				label: t(
					"desktop.manage.visitors.health_screening.table.actions.delete",
				),
				onClick: async (s) => {
					const response = await actions.deleteScreeningConfig(s.id)

					if (deleteScreeningConfig.fulfilled.match(response)) {
						infoToast("Screening config deleted successfully")
						actions.fetchConfigList(buildingFilter)
					} else {
						if (response.error) errorToast(response.error.message)
					}
				},
			},
		]
	}, [
		t,
		actions,
		buildingFilter,
		errorToast,
		history,
		infoToast,
		openModal,
		selectedRows,
	])

	const refresh = useCallback(
		(value: boolean) => {
			if (value) {
				infoToast(
					t(
						"desktop.manage.visitors.health_screening.form.health_screening_enabled",
					),
				)
			} else {
				infoToast(
					t(
						"desktop.manage.visitors.health_screening.form.health_screening_disabled",
					),
				)
			}

			refetchBuildings()
			actions.fetchSettings()
		},
		[actions, infoToast, refetchBuildings, t],
	)

	const handleRowClick = ({ id }: ScreeningConfigList) => {
		history.push(`health/edit/${id}`)
	}

	const handleBulkAction = async (action: ScreeningConfigsBulkAction) => {
		const activeSelectedRows = selectedRows.map((config) => config.id)

		if (activeSelectedRows.length <= 0) {
			infoToast(
				t(
					"desktop.settings.visitors.documents.action_statuses.no_documents_selected",
				),
			)
			return
		}

		const actionResponse = await actions.screeningConfigsBulkAction({
			screening_config_ids: activeSelectedRows,
			action,
		})
		if (screeningConfigsBulkAction.rejected.match(actionResponse)) {
			errorToast(actionResponse.error.message)
		} else {
			actions.fetchConfigList(buildingFilter)

			switch (action) {
				case ScreeningConfigsBulkAction.DELETE:
					infoToast(
						t(
							"desktop.settings.visitors.documents.action_statuses.delete_success",
						),
					)
					break
			}
		}
	}

	return (
		<View className="Health">
			<Breadcrumbs
				depth={1}
				values={[t("desktop.manage.visitors.health_screening.heading")]}
			/>

			<Intro>
				<div className="Subheading">
					{t("desktop.manage.visitors.health_screening.intro")}
				</div>
			</Intro>
			<Space size={1.75} />
			<div className="Health">
				<div className="PageHeader">
					<div className="health-settings-header">
						<div className="health-settings-header__controls">
							<div>
								<BuildingFilter
									value={buildingFilter}
									onChange={setBuildingFilter}
									showAll
								/>
							</div>
							{selectedBuilding && (
								<div>
									<AsyncSwitch
										updateMode={true}
										urlGenerator={() => {
											if (buildingFilter === FilterSpecialValues.ALL) {
												return companySettingsURL()
											} else {
												return buildingURL(buildingFilter)
											}
										}}
										bodyGenerator={(value) => {
											return {
												settings: {
													questionnaire_enabled: value,
												},
											}
										}}
										value={
											buildingFilter === FilterSpecialValues.ALL
												? settings_effective?.questionnaire_enabled
												: selectedBuilding?.settings?.questionnaire_enabled
										}
										refresh={refresh}
										label={t(
											"desktop.manage.visitors.health_screening.enable_health_screening",
										)}
									/>
								</div>
							)}
						</div>
						<div className="health-settings-header__content">
							{buildingFilter !== FilterSpecialValues.ALL ? (
								<Trans
									i18nKey={"desktop.manage.visitors.invite.configuration_text"}
									values={{
										buildingName: `<strong>${selectedBuilding?.name}</strong>`,
									}}
								/>
							) : (
								<Trans
									i18nKey={
										"desktop.manage.visitors.invite.configuration_text_all_buildings"
									}
								/>
							)}
						</div>
					</div>
				</div>
				<Filters>
					<SearchFilter
						value={search}
						onChange={(v: string) => setSearch(v)}
						placeholder={t(
							"desktop.manage.visitors.health_screening.table.search_documents",
						)}
					/>
					<FilterSpace />
					<MultiActionButton
						className="btn-Bulk"
						options={bulkActionOptions}
						onAction={handleBulkAction}
						label={t("desktop.settings.visitors.bulk_actions_filter_name")}
					/>
					<Button
						isSmall
						onClick={() =>
							history.push(
								`health/new/${
									buildingFilter !== FilterSpecialValues.ALL
										? buildingFilter
										: ""
								}`,
							)
						}
					>
						{t(
							"desktop.manage.visitors.health_screening.table.add_questionnaire",
						)}
					</Button>
				</Filters>
				{isLoading ? (
					<Loader />
				) : (
					<Table
						isSelectable
						loading={isLoading}
						columns={tableColumns}
						rows={filteredConfigs}
						rowActions={rowActions}
						emptyTableCell={<EmptyTable />}
						onSelectedRows={setSelectedRows}
						onRowClick={handleRowClick}
					/>
				)}
			</div>
		</View>
	)
}

export default Health

const EmptyTable = () => {
	const { t } = useTranslation()
	return (
		<div className="EmptyTable">
			<DeviceSVG />
			<div>
				{t("desktop.manage.visitors.health_screening.table.empty_data")}
			</div>
		</div>
	)
}
