import React, { forwardRef } from "react"

import { Field } from "./Field"
import { Label } from "./Label"
import { TextInput } from "./TextInput"

import "./EmailField.sass"

type Props = {
	value: string
	onChange: (v: string) => void
	label?: string
	helpText?: string
	maxLength?: number
	canEdit?: boolean
	required?: boolean
}

/**
 * @deprecated
 */
export const EmailField = forwardRef<HTMLInputElement, Props>(
	({ value, onChange, label, helpText, maxLength, canEdit, required }, ref) => {
		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (maxLength) {
				onChange(e.target.value.substr(0, maxLength))
			} else {
				onChange(e.target.value)
			}
		}

		return (
			<Field className="EmailField">
				<Label>{label || "Email"}</Label>
				<TextInput
					ref={ref}
					type="email"
					value={value}
					onChange={handleChange}
					isDisabled={!canEdit}
					pattern={"^.+@.+\\..+$"}
					required={required}
				/>
				{helpText && <p>{helpText}</p>}
			</Field>
		)
	},
)
