import React, { SyntheticEvent, useMemo, useState } from "react"

import { PinField } from "../Field/PinField"
import Button from "../basic/Button"

import Card from "../../components/Card"
import Space from "../../components/Space"

import "./VisitorLoginForm.sass"

type Props = {
	disableRequestMeeting: boolean
	onRequestAdHoc: () => void
	onSubmit: (pin: string) => void
	isLoading: boolean
}

const VisitorLoginForm = ({
	disableRequestMeeting,
	onRequestAdHoc,
	onSubmit,
	isLoading,
}: Props) => {
	const [appointmentPin, setAppointmentPin] = useState("")

	const isValid = useMemo(() => appointmentPin.length === 6, [appointmentPin])

	function handleSubmit(e: SyntheticEvent) {
		e.stopPropagation()
		e.preventDefault()

		onSubmit && onSubmit(appointmentPin)
	}

	return (
		<form className="VisitorLoginForm" onSubmitCapture={handleSubmit}>
			<Card className="visitor-login">
				<div className="fields">
					<PinField
						label="Appointment PIN"
						value={appointmentPin}
						onChange={setAppointmentPin}
						canEdit={true}
						maxLength={6}
						minLength={6}
					/>
				</div>
			</Card>

			<Space size={0.75} />

			<div className={`actions`}>
				<Button
					onClick={handleSubmit}
					variant="submit"
					className="submit"
					isDisabled={!isValid}
					isLoading={isLoading}
				>
					Log in
				</Button>

				{!disableRequestMeeting && (
					<Button variant="secondary" onClick={onRequestAdHoc}>
						Request appointment
					</Button>
				)}
			</div>
		</form>
	)
}

export default VisitorLoginForm
