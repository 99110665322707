import React, { useEffect, useRef } from "react"

import { OptionType } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useLazyFetchFloorsQuery } from "../../redux/api/floors"
import { FloorResponse } from "../../redux/floors/types"
import { floorComparator, formatFloorLabel } from "../../redux/floors/utils"

type Props = {
	value: string
	onChange: (v: string) => void
	buildingId: string
	showOnlyWithPlan?: boolean
	showAll?: boolean
}

export default function FloorFilter({
	value,
	onChange,
	buildingId,
	showOnlyWithPlan = false,
	showAll = false,
}: Props) {
	const [fetchFloors, { data: { results: entries = [] } = {}, isLoading }] =
		useLazyFetchFloorsQuery()

	const valueRef = useRef<string>(value)
	const prevFloorIdsRef = useRef<string[] | undefined>()

	useEffect(() => {
		if (
			buildingId !== FilterSpecialValues.EMPTY &&
			buildingId !== FilterSpecialValues.ALL
		) {
			fetchFloors({
				stats: true,
				building: buildingId,
			})
		}
	}, [buildingId, fetchFloors])

	// Calculate which floor is selected
	useEffect(() => {
		if (
			!isLoading &&
			entries.length > 0 &&
			buildingId === entries[0].building_id
		) {
			if (showAll) {
				const hasFloor =
					entries.find((f: FloorResponse) => f.id === valueRef.current) !==
					undefined

				if (!hasFloor) {
					onChange(FilterSpecialValues.ALL)
				} else {
					onChange(valueRef.current)
				}
			} else {
				let floors: FloorResponse[] = entries

				if (showOnlyWithPlan) {
					floors = floors.filter((f) => f.image)
				}

				const floorIds: string[] = floors.map((f) => f.id)

				let hasNewOne = false

				// Find if there is a new floor and select it
				if (prevFloorIdsRef.current) {
					for (let fId of floorIds) {
						if (!prevFloorIdsRef.current.includes(fId)) {
							prevFloorIdsRef.current = floorIds
							valueRef.current = fId

							hasNewOne = true

							onChange(fId)
						}
					}
				}

				if (!hasNewOne) {
					// Leave previously selected or select first
					if (valueRef.current && floorIds.includes(valueRef.current)) {
						prevFloorIdsRef.current = floorIds

						onChange(valueRef.current)
					} else {
						prevFloorIdsRef.current = floorIds
						valueRef.current = floorIds[0]

						onChange(floorIds[0])
					}
				}
			}
		} else if (!isLoading && entries.length === 0) {
			onChange(FilterSpecialValues.ALL)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entries, buildingId, showAll, showOnlyWithPlan])

	let floors = entries.slice(0).sort(floorComparator)

	if (showOnlyWithPlan) {
		floors = floors.filter((f: FloorResponse) => f.image)
	}

	if (
		floors.length === 0 ||
		buildingId === FilterSpecialValues.EMPTY ||
		buildingId === FilterSpecialValues.ALL
	) {
		return null
	}

	const floorOptions: OptionType<string>[] = floors.map((b) => ({
		value: b.id,
		label: formatFloorLabel("Floor", b.name),
	}))
	const count = floors.length

	showAll &&
		floorOptions.unshift({
			label: (
				<>
					All floors <span className="count">&middot; {count}</span>
				</>
			),
			value: FilterSpecialValues.ALL,
		})

	return (
		<Dropdown
			className="floor-filter"
			options={floorOptions}
			value={value}
			onChange={(v) => {
				valueRef.current = v
				onChange(v)
			}}
		/>
	)
}
