import React, { useEffect } from "react"

import classNames from "classnames"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { useRedirectTimeout } from "../hooks/useRedirectTimeout"

import {
	checkIntoAppointment,
	fetchAppointment,
} from "../redux/appointments/appointmentsSlice"
import { AppointmentResponse } from "../redux/appointments/types"
import { RootState, useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import VisitorAppointmentForm from "../components/Form/VisitorAppointmentForm"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Space from "../components/Space"
import View from "../components/View"
import Button from "../components/basic/Button"

import "./VisitorCheckIn.sass"

type Params = {
	company_id?: string
	appointment_id?: string
	pin?: string
}

export default function VisitorCheckIn({ match }: RouteComponentProps<Params>) {
	const { params } = match || {}
	const { company_id, appointment_id, pin } = params || {}

	const actions = useActions({
		fetchAppointment: (cid: string, pin: string) =>
			fetchAppointment({ cid, pin }),
		checkIntoAppointment: (cid: string, id: string) =>
			checkIntoAppointment({ cid, id }),
	})

	const { requireHealthScreen } = useAppSelector(
		(state: RootState) => state.visitor_login,
	)

	const { entries: appointments, isLoading } = useAppSelector(
		(state: RootState) => state.appointments,
	)

	const appointment = appointments.find(
		(a: AppointmentResponse) => a.id === appointment_id,
	)
	useEffect(() => {
		if (!appointment && company_id && pin) {
			actions.fetchAppointment(company_id, pin)
		}
	}, [actions, appointment, company_id, pin])

	const history = useHistory()

	const handleSubmitWithoutHealthScreen = async () => {
		if (company_id && appointment_id) {
			const resp = await actions.checkIntoAppointment(
				company_id,
				appointment_id,
			)

			if (checkIntoAppointment.fulfilled.match(resp)) {
				toast.success("Checked in.", { hideProgressBar: true })

				history.push(`/visitor-login/${company_id}/checked_in`)
			} else {
				toast.error(resp.error.message, {
					hideProgressBar: true,
				})
			}
		}
	}

	const handleSubmitWithHealthScreen = () => {
		if (!Array.isArray(appointment?.visitors)) {
			return false
		}

		const visitor_id = appointment?.visitors[0].id

		if (!visitor_id) return false

		history.push(
			`/health-screening/${company_id}/visitor/${visitor_id}/appointment/${appointment_id}`,
		)
	}

	function handleCancel() {
		history.push(`/visitor-login/${company_id}`)
	}

	const visitorClassName = classNames({
		VisitorCheckIn: true,
		isConstrained: true,
	})

	const submitHandler = requireHealthScreen
		? handleSubmitWithHealthScreen
		: handleSubmitWithoutHealthScreen

	return (
		<RouteView className={visitorClassName}>
			<Screen>
				<View>
					<Heading>
						<span>Visitor Check-in</span>
					</Heading>
					<Intro>
						Please verify the details of the appointment you're about to attend.
						<br />
						Your host will be notified that you have arrived.
					</Intro>

					<Space size={0.75} />

					{appointment && (
						<VisitorAppointmentForm
							appointment={appointment}
							onCancel={handleCancel}
							onSubmit={submitHandler}
							submitLabel={"Check in"}
							canEdit={false}
							isLoading={isLoading}
						/>
					)}
				</View>
			</Screen>
		</RouteView>
	)
}

export function CheckedIn({ match }: RouteComponentProps<Params>) {
	const { params } = match || {}
	const { company_id } = params || {}

	const history = useHistory()

	function handleDismiss() {
		history.push(`/visitor-login/${company_id}`)
	}

	const checkedClassName = classNames({
		CheckedIn: true,
		isConstrained: true,
	})

	useRedirectTimeout(`/visitor-login/${company_id}`, 10000)

	return (
		<RouteView className={checkedClassName}>
			<Screen>
				<View className="CheckedInResult">
					<Heading>
						<span>Checked in</span>
					</Heading>
					<Intro>
						<p>
							Thank you for checking into your appointment. Your host has been
							notified.
						</p>
					</Intro>

					<Space size={0.75} />

					<Button onClick={handleDismiss}>Dismiss</Button>
				</View>
			</Screen>
		</RouteView>
	)
}
