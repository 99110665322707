import { ThunkApiConfig } from "RootType"

import {
	get,
	patchJSON,
	postData,
	postJSON,
	visitorRegistrationBrandingURL,
	visitorRegistrationCheckinURL,
	visitorRegistrationCheckoutURL,
	visitorRegistrationFinishURL,
	visitorRegistrationQrURL,
	visitorRegistrationSaveURL,
	visitorRegistrationSignatureURL,
	visitorRegistrationURL,
} from "../../api"
import { timeZone } from "../../dayjs"
import { FileResponse } from "../files/types"
import {
	SliceState,
	getErrorMessage,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	BrandingResponse,
	CheckoutRequest,
	RegistrationCreateRequest,
	RegistrationDataResponse,
	RegistrationFinishResponse,
	RegistrationResponse,
	RegistrationUpdateRequest,
	SignatureUploadRequest,
} from "./types"
import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
	isAnyOf,
} from "@reduxjs/toolkit"

export type FetchProps = {
	buildingId: string
	tabletId: string
}

export const fetchBranding = createAsyncThunk<
	BrandingResponse,
	FetchProps,
	ThunkApiConfig
>("visitorRegistration/branding", async ({ buildingId, tabletId }) => {
	const response = await get(
		visitorRegistrationBrandingURL(buildingId, tabletId),
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const fetchQrCode = createAsyncThunk<string, FetchProps, ThunkApiConfig>(
	"visitorRegistration/qr",
	async ({ buildingId, tabletId }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await get(
			visitorRegistrationQrURL(buildingId, tabletId),
			{},
			access_token,
		)

		if (response.ok && response.body) {
			return URL.createObjectURL(await response.blob())
		}

		throw new Error(await getErrorMessage(response))
	},
)

export type RegistrationDataProps = FetchProps & {
	invite_id: string | null
	touchless_pin: string | null
}

export const fetchRegistrationData = createAsyncThunk<
	RegistrationDataResponse,
	RegistrationDataProps,
	ThunkApiConfig
>(
	"visitorRegistration/data",
	async ({ buildingId, tabletId, touchless_pin, invite_id }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await get(
			visitorRegistrationURL(buildingId, tabletId, {
				touchless_pin,
				invite_id,
			}),
			{},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const createRegistration = createAsyncThunk<
	RegistrationResponse,
	RegistrationCreateRequest & { buildingId: string; tabletId: string },
	ThunkApiConfig
>("visitorRegistration/create", async ({ buildingId, tabletId, ...body }) => {
	const response = await postJSON(
		visitorRegistrationURL(buildingId, tabletId),
		{
			body,
		},
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const updateRegistration = createAsyncThunk<
	RegistrationResponse,
	RegistrationUpdateRequest & {
		buildingId: string
		tabletId: string
		id: string
	},
	ThunkApiConfig
>(
	"visitorRegistration/update",
	async ({ buildingId, tabletId, id, ...body }) => {
		const response = await patchJSON(
			visitorRegistrationSaveURL(buildingId, tabletId, id),
			{
				body,
			},
		)

		if (response.ok) {
			return await response.json()
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const checkinRegistration = createAsyncThunk<
	RegistrationResponse,
	{
		buildingId: string
		tabletId: string
		id: string
		printBadge?: boolean
	},
	ThunkApiConfig
>(
	"visitorRegistration/checkin",
	async ({ buildingId, tabletId, id, printBadge = false }) => {
		const response = await postJSON(
			visitorRegistrationCheckinURL(buildingId, tabletId, id, {
				tz: timeZone,
				print_badge: printBadge ? "true" : undefined,
			}),
			undefined,
		)

		if (response.ok) {
			return await response.json()
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const checkoutRegistration = createAsyncThunk<
	void,
	CheckoutRequest & {
		buildingId: string
		tabletId: string
	},
	ThunkApiConfig
>(
	"visitorRegistration/checkout",
	async ({ buildingId, tabletId, ...body }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await postJSON(
			visitorRegistrationCheckoutURL(buildingId, tabletId),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const uploadSignature = createAsyncThunk<
	FileResponse,
	SignatureUploadRequest,
	ThunkApiConfig
>("visitorRegistration/uploadSignature", async ({ registrationId, file }) => {
	const response = await postData(
		visitorRegistrationSignatureURL(registrationId),
		{ body: { file } },
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const finishRegistration = createAsyncThunk<
	RegistrationFinishResponse,
	string,
	ThunkApiConfig
>("visitorRegistration/finish", async (registrationId) => {
	const response = await postJSON(
		visitorRegistrationFinishURL(registrationId),
		undefined,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export interface VisitorRegistrationState extends SliceState {
	branding?: BrandingResponse
	touchlessQr?: string
	checkInQr?: string
	data?: RegistrationDataResponse
	registration?: RegistrationResponse
	printError?: boolean
}

const initialState: VisitorRegistrationState = {
	branding: undefined,
	touchlessQr: undefined,
	checkInQr: undefined,
	data: undefined,
	registration: undefined,
	printError: undefined,
	...sliceInitialState,
}

const visitorRegistrationSlice = createSlice({
	name: "visitorRegistration",
	initialState,
	reducers: {
		setRegistration: (
			state,
			{ payload }: PayloadAction<RegistrationResponse>,
		) => {
			state.registration = {
				...payload,
				full_name: payload.visitor!.full_name,
			}
		},
		resetRegistration: (state) => {
			state.registration = undefined
			state.printError = undefined
		},
		setPrintError: (state, { payload }: PayloadAction<boolean>) => {
			state.printError = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBranding.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.branding = payload
		})
		builder.addCase(fetchQrCode.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.touchlessQr = payload
		})
		builder.addCase(fetchRegistrationData.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.data = payload
		})
		builder.addCase(uploadSignature.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})
		builder.addCase(checkinRegistration.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.registration = {
				...payload,
				full_name: payload.visitor!.full_name,
			}
		})
		builder.addCase(checkoutRegistration.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})
		builder.addCase(finishRegistration.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.checkInQr = payload.qrcode_url
		})
		builder.addMatcher(
			isAnyOf(createRegistration.fulfilled, updateRegistration.fulfilled),
			(state, { payload }) => {
				setSubmitSuccessState(state)
				state.registration = payload
			},
		)
		builder.addMatcher(
			isAnyOf(
				fetchBranding.pending,
				fetchQrCode.pending,
				fetchRegistrationData.pending,
			),
			(state) => {
				state.isLoading = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				createRegistration.pending,
				updateRegistration.pending,
				uploadSignature.pending,
				checkinRegistration.pending,
				checkoutRegistration.pending,
				finishRegistration.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				fetchBranding.rejected,
				fetchQrCode.rejected,
				fetchRegistrationData.rejected,
			),
			(state, action) => {
				setFetchErrorState(state, action)
			},
		)
		builder.addMatcher(
			isAnyOf(
				createRegistration.rejected,
				updateRegistration.rejected,
				uploadSignature.rejected,
				checkinRegistration.rejected,
				checkoutRegistration.rejected,
				finishRegistration.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
	},
})

export const visitorRegistrationReducer = visitorRegistrationSlice.reducer
export const { setRegistration, resetRegistration, setPrintError } =
	visitorRegistrationSlice.actions
