import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { BuildingResponse } from "../redux/buildings/types"

import BuildingForm from "../components/Form/BuildingForm"

type Props = {
	open: boolean
	building: BuildingResponse | null
}

export default function BuildingModal({ open, building }: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<BuildingForm building={building} />
		</ReactModal>
	)
}
