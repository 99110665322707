import dayjs, { Dayjs } from "dayjs"

import { SchedulePeopleDataRow } from "."
import { toInternalTime } from "../../../utils"

import { useFetchTimeslotsQuery } from "../../../redux/api/timeslots"
import { PeopleScheduleReservation } from "../../../redux/people_schedule/types"
import { TimeslotResponse } from "../../../redux/timeslots/types"
import { getAvailableSlots } from "../../../redux/timeslots/utils"

import ReservationData from "../../../components/Manage/ReservationData"
import { ScheduleCheckinLabel } from "../../../components/Manage/ScheduleCheckingLabel"

type PeopleCellProps = {
	day: Dayjs
	header: SchedulePeopleDataRow
	schedule: PeopleScheduleReservation[]
	renderVacancy?: (d: {
		hasAvailableSlots: boolean
		day: Dayjs
		disabled?: boolean
	}) => JSX.Element
	onClick: (r: PeopleScheduleReservation) => void
	rowIndex: string
	disabled?: boolean
}

export default function PeopleScheduleCell({
	day,
	header,
	schedule,
	onClick,
	renderVacancy,
	rowIndex,
	disabled,
}: PeopleCellProps) {
	const { data: timeslots = [] } = useFetchTimeslotsQuery()

	const slots = timeslots.filter((slot: TimeslotResponse) => !!slot.active)

	const bookedSlots = schedule.map((r) => ({
		from: toInternalTime(dayjs(r.start)),
		to: toInternalTime(dayjs(r.end)),
	}))

	const availableSlots = getAvailableSlots(slots, bookedSlots)

	return (
		<>
			{schedule.map((entry, k) => (
				<ReservationData
					key={`entry-${rowIndex}-${k}`}
					onClick={() => onClick(entry)}
					showOwnership={false}
					idString={entry.desk.id}
					iconType="square"
					isPartial={entry.start ? availableSlots.length > 0 : false}
					label={entry.desk.name}
					isVisit={!!entry.visit_id}
				>
					<ScheduleCheckinLabel
						day={day}
						reservation={{ ...entry, user: header }}
					/>
				</ReservationData>
			))}

			{availableSlots.length > 0 &&
				!!renderVacancy &&
				renderVacancy({
					hasAvailableSlots: availableSlots.length > 0,
					day,
					disabled,
				})}
		</>
	)
}
