import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { RangeType } from "../../redux/visitors/types"

import InviteExportForm from "../../components/Form/Visitors/InviteExportForm"

type Props = {
	open: boolean
	range: RangeType
	buildingId?: string
	search?: string
	show?: string
}

export const InviteExportModal = ({
	open,
	range,
	buildingId,
	search,
	show,
}: Props) => {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<InviteExportForm
				range={range}
				buildingId={buildingId}
				show={show}
				search={search}
			/>
		</ReactModal>
	)
}
