import { ThunkApiConfig } from "RootType"

import {
	deleteJSON,
	get,
	postJSON,
	putJSON,
	visitorEmailPreviewURL,
	visitorEmailSettingURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	VisitorEmailPreviewRequestParams,
	VisitorEmailPreviewResponse,
	VisitorEmailSettingRequest,
	VisitorEmailSettingResponse,
	VisitorEmailSettingsRequestParams,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchVisitorEmailSetting = createAsyncThunk<
	VisitorEmailSettingResponse,
	VisitorEmailSettingsRequestParams,
	ThunkApiConfig
>("visitorEmailSettings/fetch", async ({ building_id, type }, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(
		visitorEmailSettingURL(building_id, type),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export type CreateVisitorEmailSettingProps = VisitorEmailSettingsRequestParams &
	VisitorEmailSettingRequest

export const createVisitorEmailSetting = createAsyncThunk<
	VisitorEmailSettingResponse,
	CreateVisitorEmailSettingProps,
	ThunkApiConfig<ResponseError>
>(
	"visitorEmailSettings/create",
	async ({ building_id, type, ...body }, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await postJSON(
			visitorEmailSettingURL(building_id, type),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export type UpdateVisitorEmailSettingProps = VisitorEmailSettingsRequestParams &
	VisitorEmailSettingRequest

export const updateVisitorEmailSetting = createAsyncThunk<
	VisitorEmailSettingResponse,
	UpdateVisitorEmailSettingProps,
	ThunkApiConfig<ResponseError>
>(
	"visitorEmailSettings/update",
	async ({ building_id, type, ...body }, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await putJSON(
			visitorEmailSettingURL(building_id, type),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export const destroyVisitorEmailSetting = createAsyncThunk<
	void,
	VisitorEmailSettingsRequestParams,
	ThunkApiConfig<ResponseError>
>(
	"visitorEmailSettings/destroy",
	async ({ building_id, type }, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await deleteJSON(
			visitorEmailSettingURL(building_id, type),
			undefined,
			access_token,
		)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const visitorEmailPreview = createAsyncThunk<
	VisitorEmailPreviewResponse,
	VisitorEmailPreviewRequestParams,
	ThunkApiConfig<ResponseError>
>(
	"visitorEmailSettings/emailPreview",
	async ({ building_id, type, content }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await postJSON(
			visitorEmailPreviewURL(building_id, type),
			{ body: content },
			access_token,
		)

		if (response.ok) {
			return await response.text()
		}

		throw new Error(await getErrorMessage(response))
	},
)

export interface VisitorEmailSettingsState extends SliceState {
	entry?: VisitorEmailSettingResponse
	emailPreview?: string
}

const initialState: VisitorEmailSettingsState = {
	entry: undefined,
	...sliceInitialState,
}

const visitorEmailSettingsSlice = createSlice({
	name: "visitorEmailSettings",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchVisitorEmailSetting.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.entry = payload
			},
		)
		builder.addCase(destroyVisitorEmailSetting.fulfilled, (state) => {
			setFetchSuccessState(state)
			state.entry = undefined
		})
		builder.addCase(fetchVisitorEmailSetting.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchVisitorEmailSetting.rejected, (state, action) => {
			setFetchErrorState(state, action)
			state.entry = undefined
		})
		builder.addCase(visitorEmailPreview.fulfilled, (state, { payload }) => {
			state.isSubmitting = false
			state.emailPreview = payload
		})
		builder.addMatcher(
			isAnyOf(
				createVisitorEmailSetting.fulfilled,
				updateVisitorEmailSetting.fulfilled,
			),
			(state, { payload }) => {
				setSubmitSuccessState(state)
				state.entry = payload
			},
		)
		builder.addMatcher(
			isAnyOf(
				createVisitorEmailSetting.pending,
				updateVisitorEmailSetting.pending,
				destroyVisitorEmailSetting.pending,
				visitorEmailPreview.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				createVisitorEmailSetting.rejected,
				updateVisitorEmailSetting.rejected,
				destroyVisitorEmailSetting.rejected,
				visitorEmailPreview.rejected,
			),
			(state, action) => {
				setFetchErrorState(state, action)
			},
		)
	},
})

export const visitorEmailSettingsReducer = visitorEmailSettingsSlice.reducer
