import React, { MouseEvent, ReactNode, useState } from "react"

import classNames from "classnames"

import { openInAppBrowser } from "../../../utils"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

export type AlertProps = {
	children: ReactNode
	variant?: "warning" | "error" | "info" | "danger"
	className?: string
	href?: string
	isDisabled?: boolean
	hideCloseButton?: boolean
	onClick?: (e: MouseEvent<HTMLElement>) => void
}

/**
 * Displays alert banner if the content (children) is provided.
 */
const Alert = ({
	children,
	className,
	variant = "warning",
	onClick,
	isDisabled = false,
	href,
	hideCloseButton = false,
}: AlertProps) => {
	const cn = classNames("Alert", variant, className, {
		isLink: !!href,
		isDisabled,
	})

	const [show, setShow] = useState(children)

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		e.preventDefault()

		if (isDisabled) {
			return
		}

		if (href) {
			openInAppBrowser(href)
			return
		}

		if (onClick) {
			onClick(e)
			return
		}
	}

	const handleCloseClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		return setShow(undefined)
	}

	if (show) {
		return (
			<div onClick={handleClick} className={cn}>
				<div className="AlertContent">{show}</div>
				{!hideCloseButton && (
					<button className="CloseButton" onClick={handleCloseClick}>
						<CrossSVG />
					</button>
				)}
			</div>
		)
	} else return null
}

export default Alert
