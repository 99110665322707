import React, { FC, useEffect, useState } from "react"

import classNames from "classnames"

import { FilterSpecialValues } from "../../Filter/types"

import "./HorizontalListSelect.sass"

export type HorizontalListProps = {
	listElements: ({ name: string; id: string } & Record<string, any>)[]
	onSelect: (selected: string[]) => void
	preSelected?: string[]
}
const SPECIAL_FILTER_VALUES: readonly string[] = [
	FilterSpecialValues.ALL,
	FilterSpecialValues.EMPTY,
]

export const HorizontalListMultiSelect: FC<React.PropsWithChildren<HorizontalListProps>> = ({
	listElements,
	onSelect,
	preSelected = [],
	children,
}) => {
	const [selected, setSelected] = useState<string[]>(preSelected)

	const handleOnClick = (id: string) => {
		if (SPECIAL_FILTER_VALUES.includes(id)) {
			setSelected([id])

			return
		}
		setSelected((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((ps) => ps !== id)
			}
			if (SPECIAL_FILTER_VALUES.includes(prevSelected[0])) {
				const [, ...rest] = prevSelected
				return [...rest, id]
			}
			return [...prevSelected, id]
		})
	}

	useEffect(() => {
		onSelect(selected)
	}, [onSelect, selected])

	return (
		<div className="horizontal-list-selector">
			<div className="label">{children}</div>
			<div className="horizontal-scroll">
				{listElements.map((el) => {
					const classes = classNames("list-item", {
						selected: selected.includes(el.id),
					})

					return (
						<div
							className={classes}
							key={el.id}
							onClick={() => handleOnClick(el.id)}
						>
							{el.name}
						</div>
					)
				})}
			</div>
		</div>
	)
}
