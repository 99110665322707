import React, { useEffect, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { ReservationCheckinUtils } from "../../../checkin_utils"
import { useToast } from "../../../hooks/useToast"
import { useAnalyticsScreenView } from "../../../providers/Mobile/FirebaseAnalyticsProvider"
import { shortUserTimeFormat } from "../../../utils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	checkinAssetReservation,
	checkoutAssetReservation,
	clearAssetReservationState,
	deleteAssetReservation,
	fetchAssetReservation,
} from "../../../redux/asset_reservation/assetReservationSlice"
import { selectAssetReservationById } from "../../../redux/asset_reservation/selectors"
import { setBookAsset } from "../../../redux/book_asset/bookAssetSlice"
import { BookAsset } from "../../../redux/book_asset/types"
import { useAppSelector } from "../../../redux/reducers"
import { DeleteType } from "../../../redux/reservations/types"
import { selectSettingsEffective } from "../../../redux/settings/selectors"
import { useActions } from "../../../redux/utils"

import AssetIcon from "../../../components/AssetIcon"
import { Drawer } from "../../../components/Mobile/Drawer"
import { ReservationDeleteMenu } from "../../../components/Mobile/ReservationDeleteMenu"
import { ReservationOptionMenu } from "../../../components/Mobile/ReservationOptionMenu"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import { ConfirmationModal } from "../../../components/advanced/ConfirmationModal"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as ClockSVG } from "../../../assets/images/icons/Clock.svg"
import { ReactComponent as MoreHorizontalSVG } from "../../../assets/images/icons/More horizontal.svg"
import { ReactComponent as WorldSVG } from "../../../assets/images/icons/World2.svg"

import "./AssetReservationDetail.sass"

type ParamsType = {
	id: string
}

const ReservationDetail: React.FC = () => {
	useAnalyticsScreenView("Home/ReservationDetail")
	const { openModal, closeModal } = useModals()

	const { t } = useTranslation()
	const { id: reservationId } = useParams<ParamsType>()
	const history = useHistory()
	const { errorToast } = useToast()

	const [openMenu, setOpenMenu] = useState(false)
	const [openDeleteMenu, setOpenDeleteMenu] = useState(false)

	const actions = useActions({
		checkIn: (id: string) => checkinAssetReservation(id),
		checkOut: (id: string) => checkoutAssetReservation(id),
		fetchAssetReservation: (id: string) => fetchAssetReservation(id),
		delete: (options: { reservationId: string; type?: DeleteType }) =>
			deleteAssetReservation(options),
		setBookAsset: (payload: BookAsset) => setBookAsset(payload),
		clearAssetReservationState: () => clearAssetReservationState({}),
	})

	const { entry: settings } = useAppSelector(selectSettingsEffective)
	const { desk_check_in_enabled } = settings ?? {}

	const { reservation, isLoaded, error } = useAppSelector(
		selectAssetReservationById(reservationId),
	)
	const { asset } = reservation ?? {}

	const hasCheckin = reservation?.checked_in?.check_in_at
	const hasCheckout = reservation?.checked_in?.check_out_at

	const handleNoReservation = () => {
		toast(t("mobile.general.reservation_not_exist"))
		history.push("/home/reservations")
	}

	const handleEdit = () => {
		if (!reservation) {
			handleNoReservation()
			return
		}
		actions.setBookAsset({
			id: reservation.id,
			building: asset?.building,
			assetTypeId: asset?.asset_type.id,
			asset: reservation.asset,
			start: reservation.start,
			end: reservation.end,
			userEmail: reservation.user.email,
		})
		history.push("/book/asset/summary")
	}

	const handleDeleteOption = async () => {
		setOpenMenu(false)

		if (!reservation) {
			handleNoReservation()
			return
		}

		if (reservation.recurring) {
			setOpenDeleteMenu(true)
		} else {
			handleDeleteConfirmation(DeleteType.CURRENT)
		}
	}

	const handleDelete = async (type: DeleteType) => {
		const response = await actions.delete({ reservationId, type })
		if (deleteAssetReservation.fulfilled.match(response)) {
			history.push("/home/reservations")
			closeModal()
			return
		}
	}

	const handleDeleteConfirmation = (type: DeleteType) => {
		setOpenDeleteMenu(false)
		openModal(ConfirmationModal, {
			onConfirm: () => handleDelete(type),
		})
	}

	const canCheckIn = ReservationCheckinUtils.isEnabled(
		"checkin",
		settings,
		undefined,
		reservation,
	)
	const canCheckOut = ReservationCheckinUtils.isEnabled(
		"checkout",
		settings,
		undefined,
		reservation,
	)

	const actionClicked = (type: "checkin" | "checkout") => {
		let title = t("mobile.home.confirm_checkin")
		let func = handleCheckIn

		if (type === "checkout") {
			title = t("mobile.home.confirm_checkout")
			func = handleCheckOut
		}

		openModal(ConfirmationModal, {
			title,
			onConfirm: () => {
				func()
				closeModal()
			},
		})
	}

	const handleCheckOut = async () => {
		const response = await actions.checkOut(reservationId)

		if (checkoutAssetReservation.rejected.match(response)) {
			errorToast(response.error.message)
		}

		await actions.fetchAssetReservation(reservationId)
	}

	const handleCheckIn = async () => {
		const response = await actions.checkIn(reservationId)

		if (checkinAssetReservation.rejected.match(response)) {
			errorToast(response.error.message)
		}

		await actions.fetchAssetReservation(reservationId)
	}

	useEffect(() => {
		actions.fetchAssetReservation(reservationId)
	}, [actions, reservationId])

	useEffect(() => () => actions.clearAssetReservationState(), [actions])

	return (
		<SafeViewArea className="ReservationDetail">
			<div className="head">
				<TopNav
					rightIcon={
						<MoreHorizontalSVG
							onClick={() => {
								setOpenMenu(true)
							}}
						/>
					}
				/>
				<div className="icon">
					<div>
						{asset?.asset_type?.icon && (
							<AssetIcon name={asset?.asset_type?.icon} />
						)}
					</div>
				</div>
			</div>
			<div className="body">
				{isLoaded && reservation && asset && (
					<>
						<div className="main-data">
							<div className="info">
								<div className="type"></div>
								<div className="status">{t("mobile.home.reserved")}</div>
							</div>
							<div className="name">{asset.name}</div>
							<div className="location">
								{asset.building && `${asset.building.name}`}
							</div>
						</div>
						<div className="details">
							<div className="detail-row">
								<CalendarSVG />
								<div>{dayjs(reservation.start).format("dddd, MMM D")}</div>
							</div>
							<div className="detail-row">
								<ClockSVG />
								<div>
									{dayjs(reservation.start).format(shortUserTimeFormat())}
									{" - "}
									{dayjs(reservation.end).format(shortUserTimeFormat())}
								</div>
							</div>
							<div className="detail-row">
								<WorldSVG />
								<div>{reservation.tz}</div>
							</div>
							{desk_check_in_enabled && (
								<>
									{hasCheckin && (
										<div className="detail-row">
											{t("mobile.general.checked_in")}{" "}
											{dayjs(reservation.checked_in?.check_in_at).format(
												shortUserTimeFormat(),
											)}
											{hasCheckout && (
												<>
													<br />
													{t("mobile.general.checked_out")}{" "}
													{dayjs(reservation.checked_in?.check_out_at).format(
														shortUserTimeFormat(),
													)}
												</>
											)}
										</div>
									)}
									{(canCheckIn || canCheckOut) && (
										<div className="actions-row">
											{canCheckIn && (
												<Button
													variant="link"
													className="action"
													onClick={() => actionClicked("checkin")}
												>
													{t("mobile.general.check_in")}
												</Button>
											)}
											{canCheckOut && (
												<Button
													variant="link"
													className="action"
													onClick={() => actionClicked("checkout")}
												>
													{t("mobile.general.check_out")}
												</Button>
											)}
										</div>
									)}
								</>
							)}
						</div>
					</>
				)}
				{error && (
					<div className="no-data">
						<div className="reason">
							{t("mobile.general.reservation_not_exist")}
						</div>
						<Button onClick={() => history.push("/home/reservations")}>
							{t("mobile.navigation.home")}
						</Button>
					</div>
				)}
				{!isLoaded && (
					<div className="loading">
						<Loader variant="fullScreen" />
					</div>
				)}
			</div>
			<Drawer open={openMenu}>
				<ReservationOptionMenu
					onEdit={handleEdit}
					onDelete={handleDeleteOption}
					onClose={() => {
						setOpenMenu(false)
					}}
				/>
			</Drawer>
			<Drawer open={openDeleteMenu}>
				<ReservationDeleteMenu
					onClick={handleDeleteConfirmation}
					onClose={() => {
						setOpenDeleteMenu(false)
					}}
				/>
			</Drawer>
		</SafeViewArea>
	)
}

export default ReservationDetail
