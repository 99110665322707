import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"

import { OptionType } from "../../../types/sharedTypes"

import { ReactComponent as ChevronSVG } from "../../../assets/images/icons/Chevron.svg"

import "./style.sass"

export type DropdownProps<T> = {
	options: OptionType<T>[]
	value?: T
	onChange: (v: T) => void
	className?: string
	loading?: boolean
} & { ref?: ForwardedRef<HTMLDivElement> }

const Dropdown = <T,>(
	{
		options,
		value: currentValue,
		onChange,
		className,
		loading,
	}: DropdownProps<T>,
	ref: ForwardedRef<HTMLDivElement>,
) => {
	if (loading) return null

	const current = options.find((opt) => opt.value === currentValue)
	const { label: currentLabel } = current || {}
	const Chevron = options.length !== 1 && <ChevronSVG />

	if (!currentLabel && options.length < 1) {
		return null
	}

	return (
		<div ref={ref} className={classNames("Dropdown", className)}>
			<div className="control">
				<span className="label">{currentLabel}</span> {Chevron}
			</div>
			{options.length > 1 && (
				<div className="options">
					{Chevron}
					{options.map(({ label, value }, i) => (
						<div
							className={value === currentValue ? "option active" : "option"}
							key={`option-${i}`}
							onClick={() => onChange(value)}
						>
							<span className="label">{label}</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

// type assertion to make sure the component accepts generic props
// and also accepts ref
export default forwardRef(Dropdown) as <T>(
	props: DropdownProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof Dropdown>
