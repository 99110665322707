import React, { useEffect, useState } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { PRIMARY_COLOR } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"
import { COLOR_HEX_REGEX, getOption } from "../../../utils"
import { ONBOARDING_PATHS } from "../constants"

import {
	useFetchCompanyQuery,
	useUpdateCompanyDetailsMutation,
} from "../../../redux/api/company"
import {
	EMPLOYEES_OPTIONS,
	INDUSTRIES_OPTIONS,
	SMALL_COUNTS_OPTIONS,
	TOOLS_OPTIONS,
} from "../../../redux/api/company/constants"
import { CompanyRequest } from "../../../redux/api/company/types"
import { isApiResponseError, isRejected } from "../../../redux/api/types"
import {
	setOnboardingDone,
	setOnboardingStep,
} from "../../../redux/app/appSlice"
import { selectOnboarding } from "../../../redux/app/selectors"
import { uploadFileCompat } from "../../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../../redux/files/utils"
import { useAppSelector } from "../../../redux/reducers"
import { fetchSettings } from "../../../redux/settings/settingsSlice"
import { ImageResponse } from "../../../redux/settings/types"
import { useActions } from "../../../redux/utils"

import Field from "../../../components/Field"
import PageForm from "../../../components/Form/PageFormHook"
import { setErrors } from "../../../components/Form/formUtils"
import { TIMEZONE_OPTIONS } from "../../../components/Form/options"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import RouteView from "../../../components/RouteView"
import Screen from "../../../components/Screen"
import View from "../../../components/View"
import GoogleMap from "../../../components/advanced/GoogleMap"
import { GooglePlacesSelect } from "../../../components/advanced/GooglePlacesSelect"
import Button from "../../../components/basic/Button"
import { ColorPicker } from "../../../components/basic/ColorPicker"
import { ImageSelector } from "../../../components/basic/ImageSelector"
import { Input } from "../../../components/basic/Input"
import MultiCheckbox from "../../../components/basic/MultiCheckbox"
import { Select } from "../../../components/basic/Select"

import "./styles.sass"

type GoogleAddress = {
	address?: string
	latitude?: number
	longitude?: number
}

type FormValues = {
	name?: string
	country?: string
	industry?: OptionType
	employees?: OptionType
	address: GoogleAddress
	meeting_room_count?: OptionType
	daily_visitors?: OptionType
	tools?: string[]
	timeZone?: OptionType
	logo?: ImageResponse | null
	color?: string
}

const OrganizationDetails = () => {
	const [logo, setLogo] = useState<File | null>()

	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const methods = useForm<FormValues>({
		defaultValues: {
			address: { address: "", latitude: 0, longitude: 0 },
			color: PRIMARY_COLOR,
		},
	})
	const {
		control,
		watch,
		reset,
		setError,
		formState: { isSubmitting },
	} = methods
	const history = useHistory()

	const { data: company } = useFetchCompanyQuery()
	const [updateCompany] = useUpdateCompanyDetailsMutation()

	const onboarding = useAppSelector(selectOnboarding)

	const actions = useActions({
		uploadFile: (logo: File) => uploadFileCompat(logo),
		fetchSettings: () => fetchSettings(),
		setOnboardingStep: (step: number) => setOnboardingStep(step),
		setOnboardingDone: () => setOnboardingDone(),
	})

	const hasNextStep =
		onboarding && onboarding.currentStep + 1 < onboarding.steps.length

	/**
	 * updateCompany
	 */
	const saveOrganizationDetails = async (values: FormValues) => {
		const payload: CompanyRequest = {
			name: values.name,
			address: values.address?.address,
			employees: values.employees?.value,
			industry: values.industry?.value,
			meeting_room_count: values.meeting_room_count?.value,
			daily_visitors: values.daily_visitors?.value,
			tools: values.tools,
			latitude: values.address?.latitude,
			longitude: values.address?.longitude,
			settings: {
				company_color: values.color,
				timezone: values.timeZone?.value,
			},
		}

		if (logo) {
			const pictureWithFixedName = new File(
				[logo],
				removeDotsAndSpaces(logo.name),
			)

			const file = await actions.uploadFile(pictureWithFixedName)

			if (uploadFileCompat.fulfilled.match(file)) {
				payload.settings!.company_logo = file.payload
			} else {
				errorToast(file.error.message)
				return
			}
		} else if (logo === null) {
			payload.settings!.company_logo = null
		}

		const response = await updateCompany(payload)

		if (response && isRejected(response)) {
			if (isApiResponseError(response.error)) {
				setErrors(response.error.formError, setError, errorToast)

				return
			}

			return
		}

		infoToast(t("desktop.onboarding.user_info.form.toast.update_success"))
		actions.fetchSettings()

		if (hasNextStep) {
			history.push(
				ONBOARDING_PATHS[onboarding.steps[onboarding.currentStep + 1]],
			)
			actions.setOnboardingStep(onboarding.currentStep + 1)

			return
		}

		actions.setOnboardingDone()
		history.push(onboarding?.url ?? "/")
	}

	const validateAddress = (value: GoogleAddress) =>
		!value.address
			? t("desktop.onboarding.organization.form.required")
			: undefined

	useEffect(() => {
		if (company) {
			reset({
				name: company.name ?? undefined,
				address: company.address
					? {
							address: company.address,
							latitude: company.latitude ?? 0,
							longitude: company.longitude ?? 0,
					  }
					: { address: "" },
				country: company.country ?? undefined,
				industry: getOption(INDUSTRIES_OPTIONS, company.industry),
				employees: getOption(EMPLOYEES_OPTIONS, company.employees),
				meeting_room_count: getOption(
					SMALL_COUNTS_OPTIONS,
					company.meeting_room_count,
				),
				daily_visitors: getOption(SMALL_COUNTS_OPTIONS, company.daily_visitors),
				tools: company.tools ?? [],
				timeZone: getOption(
					TIMEZONE_OPTIONS,
					company.settings.timezone ?? timeZone,
				),
				logo: company.settings.company_logo,
				color: company.settings.company_color ?? PRIMARY_COLOR,
			})
		}
	}, [company, reset])

	if (!company) {
		return null
	}

	const { latitude, longitude } = watch("address")

	const doesLatAndLongExist = !!latitude && !!longitude

	return (
		<RouteView className="OrganizationDetails isConstrained">
			<Screen>
				<View className="OrganizationDetails">
					<Heading>
						{t(
							`desktop.onboarding.organization.heading.${
								!company?.name ? "create" : "update"
							}` as ParseKeys,
						)}
					</Heading>
					<Intro>
						{t(
							`desktop.onboarding.organization.intro.${
								!company?.name ? "create" : "update"
							}` as ParseKeys,
						)}
					</Intro>
					<FormProvider {...methods}>
						<PageForm
							updateMode={true}
							onUpdate={saveOrganizationDetails}
							submitButtonText={hasNextStep ? t("general.next") : undefined}
							additionalButton={
								<Button
									variant="secondary-white"
									onClick={() => history.push("/auth/logout")}
								>
									{t("desktop.settings.account.log_out_button")}
								</Button>
							}
						>
							<Field
								control={control}
								name="name"
								label={t("desktop.onboarding.organization.form.name")}
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
								required
							>
								{(props) => <Input {...props} maxLength={120} />}
							</Field>

							<Field
								control={control}
								name="address"
								label={t("desktop.onboarding.organization.form.address")}
								rules={{
									validate: validateAddress,
								}}
								required
							>
								{(props) => (
									<GooglePlacesSelect
										{...props}
										maxLength={30}
										disabled={isSubmitting}
									/>
								)}
							</Field>

							{doesLatAndLongExist && (
								<GoogleMap position={{ lat: latitude, lng: longitude }} />
							)}

							<Field
								className="OrganizationDetails__label-left"
								control={control}
								name="logo"
								label={<PictureLabel />}
							>
								{({ value, ...props }) => (
									<ImageSelector
										{...props}
										label={t(
											"desktop.onboarding.organization.form.logo.upload",
										)}
										onChange={setLogo}
										image={value}
										accept="image/png"
									/>
								)}
							</Field>
							<Field
								className="label-left"
								control={control}
								label={t("desktop.onboarding.organization.form.color")}
								name="color"
								rules={{
									pattern: {
										value: COLOR_HEX_REGEX,
										message: t(
											"desktop.settings.organization.branding.color_invalid",
										),
									},
								}}
							>
								{(props) => <ColorPicker {...props} />}
							</Field>
							<Field
								control={control}
								name="timeZone"
								label={t("desktop.onboarding.organization.form.timezone")}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => <Select {...props} options={TIMEZONE_OPTIONS} />}
							</Field>
							<Field
								control={control}
								name="employees"
								label={t("desktop.onboarding.organization.form.company_size")}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => <Select {...props} options={EMPLOYEES_OPTIONS} />}
							</Field>
							<Field
								control={control}
								name="industry"
								label={t(
									"desktop.onboarding.organization.form.company_industry",
								)}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => <Select {...props} options={INDUSTRIES_OPTIONS} />}
							</Field>
							<Field
								control={control}
								name="meeting_room_count"
								label={t(
									"desktop.onboarding.organization.form.number_of_meeting_rooms",
								)}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => (
									<Select {...props} options={SMALL_COUNTS_OPTIONS} />
								)}
							</Field>
							<Field
								control={control}
								name="daily_visitors"
								label={t(
									"desktop.onboarding.organization.form.number_of_visitors_per_day",
								)}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => (
									<Select {...props} options={SMALL_COUNTS_OPTIONS} />
								)}
							</Field>
							<Field
								control={control}
								name="tools"
								label={t("desktop.onboarding.organization.form.tools")}
								subText={`(${t(
									"desktop.onboarding.organization.form.multi_select",
								)})`}
								required
								rules={{
									required: t("desktop.onboarding.organization.form.required"),
								}}
							>
								{(props) => (
									<MultiCheckbox
										value={props.value}
										options={TOOLS_OPTIONS}
										onChange={props.onChange}
									/>
								)}
							</Field>
						</PageForm>
					</FormProvider>
				</View>
			</Screen>
		</RouteView>
	)
}

const PictureLabel = () => {
	const { t } = useTranslation()
	return (
		<div>
			<p>
				{t("desktop.onboarding.organization.form.logo.title")}
				<br />
				<span className="text--small">({t("general.optional")})</span>
			</p>
			<p className="guidelines">
				{t("desktop.onboarding.organization.form.logo.guidelines")}
			</p>
		</div>
	)
}

export default OrganizationDetails
