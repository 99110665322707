import { useState } from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { BILLING_URL } from "../../../../api"
import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"
import LicensesInfoList from "../../LicensesInfoList"
import DemoDataModal from "./DemoDataModal"
import DeskActivationModal from "./DeskActivationModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchBuildingsQuery } from "../../../../redux/api/buildings"
import {
	useCreateDeskBookingConsentMutation,
	useFetchDeskBookingConsentQuery,
	useUpdateDeskBookingConsentMutation,
} from "../../../../redux/api/consents"
import { useCreateDemoDataMutation } from "../../../../redux/api/demo"
import {
	useActivateServiceMutation,
	useDeactivateServiceMutation,
	useFetchServicesQuery,
} from "../../../../redux/api/services"
import { Services } from "../../../../redux/api/services/types"
import { isRejected } from "../../../../redux/api/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { updateSettings } from "../../../../redux/settings/settingsSlice"
import { SettingsRequest } from "../../../../redux/settings/types"
import { selectUserGroups } from "../../../../redux/user/selectors"
import { isNormalUser } from "../../../../redux/user/utils"
import { useActions } from "../../../../redux/utils"

import Field from "../../../../components/Field"
import PageForm from "../../../../components/Form/PageFormHook"
import { setErrors } from "../../../../components/Form/formUtils"
import {
	CHECKIN_PERIOD_OPTIONS,
	DISABLED_CHECKIN_PERIOD,
	HIDE_RESERVATION_WINDOW_LENGTH_OPTIONS,
	RESERVATION_LENGTH_OPTIONS,
	TIMEZONE_OPTIONS,
} from "../../../../components/Form/options"
import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import { Checkbox } from "../../../../components/basic/Checkbox"
import { Select } from "../../../../components/basic/Select"
import Switch from "../../../../components/basic/Switch"

import "./DeskSettingsForm.sass"

const FORM_MAPPING = {
	"settings.timezone": "timezone",
	"settings.desk_check_in_enabled": "desk_check_in_enabled",
	"settings.desk_checkin_period": "desk_checkin_period",
	"settings.desk_reservation_window_length": "desk_reservation_window_length",
	"settings.desk_send_notifications_at_change":
		"desk_send_notifications_at_change",
	"settings.desk_department_rules_enabled": "desk_department_rules_enabled",
	"settings.desk_one_reservation_per_user": "desk_one_reservation_per_user",
} as const

type FormValues = {
	timezone: OptionType<string>
	desk_check_in_enabled: boolean
	desk_checkin_period: OptionType<number>
	desk_hide_reservations: boolean
	desk_hide_reservations_window_length: OptionType<number>
	desk_mandatory_check_in: boolean
	desk_reservation_window_length: OptionType<number>
	desk_send_notifications_at_change: boolean
	desk_department_rules_enabled: boolean
	desk_one_reservation_per_user: boolean
}

const DeskSettingsForm = () => {
	const [isEnableSubmitting, setIsEnableSubmitting] = useState(false)

	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()
	const { openModal, closeModal } = useModals()

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()
	const { data: services } = useFetchServicesQuery()
	const { data: deskBookingConsent } = useFetchDeskBookingConsentQuery()
	const [activateService] = useActivateServiceMutation()
	const [deactivateService] = useDeactivateServiceMutation()
	const [createDeskBookingConsent] = useCreateDeskBookingConsentMutation()
	const [updateDeskBookingConsent] = useUpdateDeskBookingConsentMutation()
	const [createDemoData] = useCreateDemoDataMutation()

	const groups = useAppSelector(selectUserGroups)
	const { entry } = useAppSelector(selectSettingsEffective)
	const {
		timezone,
		desk_check_in_enabled: check_in_enabled,
		desk_checkin_period: checkin_period,
		desk_hide_reservations: hide_reservations,
		desk_hide_reservations_window_length: hide_reservations_window_length,
		desk_reservation_window_length: reservation_window_length,
		desk_send_notifications_at_change: send_notifications_at_change,
		desk_department_rules_enabled: department_rules_enabled,
		desk_one_reservation_per_user: one_reservation_per_user,
	} = entry ?? {}

	const actions = useActions({
		updateSettings: (payload: SettingsRequest) => updateSettings(payload),
	})

	const methods = useForm<FormValues>({
		defaultValues: {
			timezone: { value: timezone ?? "", label: timezone ?? "" },
			desk_check_in_enabled: check_in_enabled ?? false,
			desk_checkin_period:
				CHECKIN_PERIOD_OPTIONS.find((o) => o.value === checkin_period) ??
				CHECKIN_PERIOD_OPTIONS[0],
			desk_hide_reservations: hide_reservations ?? false,
			desk_hide_reservations_window_length:
				HIDE_RESERVATION_WINDOW_LENGTH_OPTIONS.find(
					(o) => o.value === hide_reservations_window_length,
				) ?? HIDE_RESERVATION_WINDOW_LENGTH_OPTIONS[0],
			desk_mandatory_check_in:
				checkin_period === DISABLED_CHECKIN_PERIOD ? false : true,
			desk_reservation_window_length:
				RESERVATION_LENGTH_OPTIONS.find(
					(o) => o.value === reservation_window_length,
				) ?? RESERVATION_LENGTH_OPTIONS[0],
			desk_send_notifications_at_change: send_notifications_at_change ?? false,
			desk_department_rules_enabled: department_rules_enabled ?? false,
			desk_one_reservation_per_user: one_reservation_per_user ?? false,
		},
	})

	const {
		setError,
		control,
		formState: { isSubmitting },
	} = methods

	const onSaveClick = async ({
		timezone,
		desk_check_in_enabled,
		desk_checkin_period,
		desk_hide_reservations_window_length,
		desk_reservation_window_length,
		desk_send_notifications_at_change,
		desk_department_rules_enabled,
		desk_one_reservation_per_user,
	}: FormValues) => {
		const response = await actions.updateSettings({
			settings: {
				timezone: timezone.value,
				desk_check_in_enabled,
				desk_checkin_period: desk_checkin_period.value,
				desk_hide_reservations:
					desk_hide_reservations_window_length.value !== 0,
				desk_hide_reservations_window_length:
					desk_hide_reservations_window_length.value !== 0
						? desk_hide_reservations_window_length.value
						: null,
				desk_mandatory_check_in:
					desk_checkin_period.value === DISABLED_CHECKIN_PERIOD ? false : true,
				desk_reservation_window_length: desk_reservation_window_length.value,
				desk_send_notifications_at_change,
				desk_department_rules_enabled,
				desk_one_reservation_per_user,
			},
		})

		if (updateSettings.rejected.match(response)) {
			if (response.payload) {
				setErrors(response.payload, setError, errorToast, FORM_MAPPING)
			}
		} else {
			infoToast(
				t("desktop.settings.booking_policies.form.desk_setting_updated_toast"),
			)
		}
	}
	const handleConsent = async (isConsentGiven: boolean): Promise<boolean> => {
		if (!deskBookingConsent) {
			if (!isConsentGiven) {
				errorToast(t("desktop.settings.desks.general.toasts.consent_not_given"))

				return false
			}

			const consentResponse =
				deskBookingConsent === null
					? await createDeskBookingConsent(true)
					: await updateDeskBookingConsent(true)

			if (isRejected(consentResponse)) {
				errorToast(consentResponse.error.message)

				return false
			}
		}
		return true
	}

	const handleServiceActivation = async (isConsentGiven: boolean) => {
		if (!(await handleConsent(isConsentGiven))) {
			return
		}

		const response = await activateService("desk")

		if (isRejected(response)) {
			errorToast(response.error.message)

			return
		}

		infoToast(t("desktop.settings.desks.general.toasts.activate_success"))
		closeModal()

		// If there are no buildings, create demo data
		if (buildings.length === 0) {
			openModal(DemoDataModal, {
				onConfirm: onConfirmDemoData,
				onCancel: closeModal,
			})
		}
	}

	const onConfirmDemoData = async () => {
		const demoDataResponse = await createDemoData()

		if (isRejected(demoDataResponse)) {
			errorToast(demoDataResponse.error.message)

			return
		}

		infoToast(
			t("desktop.settings.desks.general.toasts.create_demo_data_success"),
		)
		closeModal()
	}

	const handleServiceDeactivation = async () => {
		const response = await deactivateService("desk")

		if (isRejected(response)) {
			errorToast(response.error.message)

			return
		}
		const consentResponse = await updateDeskBookingConsent(false)

		if (isRejected(consentResponse)) {
			errorToast(consentResponse.error.message)

			return
		}

		infoToast(t("desktop.settings.desks.general.toasts.deactivate_success"))
		closeModal()
	}

	const consentAndRedirectToPayments = async (isConsentGiven: boolean) => {
		if (!(await handleConsent(isConsentGiven))) {
			return
		}

		closeModal()
		// TODO: Redirect to billing page when it's ready
		window.open(BILLING_URL, "_blank")
	}

	const handleOnDeskEnableChange = (isActivation: boolean) => {
		const hasTrial = hasTrialAvailable(services)

		openModal(DeskActivationModal, {
			isActivation,
			hasTrialAvailable: hasTrial,
			onConfirm: async (isConsentGiven: boolean) => {
				setIsEnableSubmitting(true)

				if (isActivation) {
					if (hasTrial) {
						await handleServiceActivation(isConsentGiven)
						setIsEnableSubmitting(false)

						return
					}

					await consentAndRedirectToPayments(isConsentGiven)
					setIsEnableSubmitting(false)

					return
				}

				await handleServiceDeactivation()
				setIsEnableSubmitting(false)
			},
			onCancel: closeModal,
		})
	}

	const canEdit = !isNormalUser({ groups })
	const isDisabled = !canEdit || isSubmitting

	return (
		<FormProvider {...methods}>
			<PageForm<FormValues>
				className="DeskSettingsForm"
				updateMode
				onUpdate={onSaveClick}
				disabled={!canEdit}
				header={
					<>
						<Switch
							value={!!services?.desk?.active}
							onChange={handleOnDeskEnableChange}
							label={t(
								"desktop.settings.desks.general.enable_desks_and_assets",
							)}
							disabled={isEnableSubmitting}
						/>
						<LicensesInfoList licenseType="user" />
					</>
				}
			>
				<Field
					control={control}
					name="timezone"
					label={t("desktop.settings.booking_policies.form.timezone")}
					className="horizontal-field"
				>
					{(props) => (
						<Select
							{...props}
							options={TIMEZONE_OPTIONS}
							disabled={isDisabled}
							getOptionValue={(t) => `${t.value}`}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="desk_reservation_window_length"
					label={t(
						"desktop.settings.booking_policies.form.allow_booking_for_up_to",
					)}
					className="horizontal-field"
				>
					{(props) => (
						<Select
							{...props}
							options={RESERVATION_LENGTH_OPTIONS}
							disabled={isDisabled}
							getOptionValue={(t) => `${t.value}`}
						/>
					)}
				</Field>
				<Field control={control} name="desk_check_in_enabled">
					{(props) => (
						<Checkbox
							{...props}
							disabled={isDisabled}
							label={t(
								"desktop.settings.booking_policies.form.enable_checking",
							)}
							description={t(
								"desktop.settings.booking_policies.form.enable_checking_description",
							)}
							isSecondary
						/>
					)}
				</Field>
				<Field
					control={control}
					name="desk_checkin_period"
					label={t(
						"desktop.settings.booking_policies.form.release_desk_if_no_checkin",
					)}
					className="horizontal-field"
				>
					{(props) => (
						<Select
							{...props}
							options={CHECKIN_PERIOD_OPTIONS}
							disabled={isDisabled}
							getOptionValue={(t) => `${t.value}`}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="desk_hide_reservations_window_length"
					label={t("desktop.settings.booking_policies.form.hide_reservations")}
					className="horizontal-field"
				>
					{(props) => (
						<Select
							{...props}
							options={HIDE_RESERVATION_WINDOW_LENGTH_OPTIONS}
							disabled={isDisabled}
							getOptionValue={(t) => `${t.value}`}
						/>
					)}
				</Field>
				<Field control={control} name="desk_send_notifications_at_change">
					{(props) => (
						<Checkbox
							{...props}
							disabled={isDisabled}
							label={t(
								"desktop.settings.booking_policies.form.send_notification",
							)}
							description={t(
								"desktop.settings.booking_policies.form.enable_checking_description",
							)}
							isSecondary
						/>
					)}
				</Field>
				<Field control={control} name="desk_department_rules_enabled">
					{(props) => (
						<Checkbox
							{...props}
							disabled={isDisabled}
							label={t(
								"desktop.settings.booking_policies.form.enable_departmental_booking_rules",
							)}
							description={
								<Trans i18nKey="desktop.settings.booking_policies.form.enable_departmental_booking_rules_description">
									<RedirectLink to="departments">placeholder</RedirectLink>
								</Trans>
							}
							isSecondary
						/>
					)}
				</Field>
				<Field control={control} name="desk_one_reservation_per_user">
					{(props) => (
						<Checkbox
							{...props}
							disabled={isDisabled}
							label={t(
								"desktop.settings.booking_policies.form.limit_concurrent_reservations",
							)}
							description={t(
								"desktop.settings.booking_policies.form.limit_concurrent_reservations_description",
							)}
							isSecondary
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default DeskSettingsForm

/**
 * Checks if a trial is available for the desk.
 * @param services - The services object containing desk information.
 * @returns A boolean indicating whether a trial is available.
 */
const hasTrialAvailable = (services?: Services): boolean => {
	if (!services) {
		return true
	}

	if (services.desk.active === false && !services.desk.trial_end) {
		return true
	}

	if (
		services.desk.active === false &&
		services.desk.trial_end &&
		dayjs().isBefore(services.desk.trial_end)
	) {
		return true
	}

	return false
}
