const DESKS_ROOT_PATH = "/settings/desks"
const DESKS_USER_PATHNAME = `${DESKS_ROOT_PATH}/general`
const DESKS_LAYOUT_PATHNAME = `${DESKS_ROOT_PATH}/layout`
const DESKS_POLICIES_PATHNAME = `${DESKS_ROOT_PATH}/policies`
const DESKS_TIME_SLOTS_PATHNAME = `${DESKS_ROOT_PATH}/time-slots`
const DESKS_AMENITIES_PATHNAME = `${DESKS_ROOT_PATH}/amenities`

export const DESKS_PATHS = {
	root: DESKS_ROOT_PATH,
	general: DESKS_USER_PATHNAME,
	layout: DESKS_LAYOUT_PATHNAME,
	policies: DESKS_POLICIES_PATHNAME,
	timeSlots: {
		default: DESKS_TIME_SLOTS_PATHNAME,
		add: `${DESKS_TIME_SLOTS_PATHNAME}/add`,
		edit: `${DESKS_TIME_SLOTS_PATHNAME}/edit/:slot_id`,
	},
	amenities: {
		default: DESKS_AMENITIES_PATHNAME,
		add: `${DESKS_AMENITIES_PATHNAME}/add`,
		edit: `${DESKS_AMENITIES_PATHNAME}/edit/:amenity_id`,
	},
} as const
