import React, { useEffect, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"
import { DESKS_PATHS } from "../../Settings/Desks/constants"
import { VISITORS_PATHS } from "../../Settings/Visitors/constants"
import { ONBOARDING_PATHS } from "../constants"

import { useFetchMeQuery, useUpdateMeMutation } from "../../../redux/api/me"
import { isApiResponseError, isRejected } from "../../../redux/api/types"
import {
	DISCOVERIES_OPTIONS,
	JOB_ROLES_OPTIONS,
	JOB_TITLES_OPTIONS,
	NONE_OF_THOSE,
	USE_CASES,
	USE_CASES_OPTIONS,
	isUseCase,
} from "../../../redux/api/users/constants"
import { UserProfileRequest } from "../../../redux/api/users/types"
import {
	setOnboardingDone,
	setOnboardingStep,
	setOnboardingUrl,
} from "../../../redux/app/appSlice"
import { selectOnboarding } from "../../../redux/app/selectors"
import { uploadFileCompat } from "../../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../../redux/files/utils"
import { useAppSelector } from "../../../redux/reducers"
import { selectScim } from "../../../redux/scim/selectors"
import { ImageResponse } from "../../../redux/settings/types"
import { isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Field from "../../../components/Field"
import PageForm from "../../../components/Form/PageFormHook"
import { setErrors } from "../../../components/Form/formUtils"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import RouteView from "../../../components/RouteView"
import Screen from "../../../components/Screen"
import View from "../../../components/View"
import Button from "../../../components/basic/Button"
import { ImageSelector } from "../../../components/basic/ImageSelector"
import { Input } from "../../../components/basic/Input"
import { Select } from "../../../components/basic/Select"

import "./styles.sass"

type FormValues = {
	first_name?: string
	last_name?: string
	job_title?: OptionType
	job_role?: OptionType
	use_case?: OptionType[]
	use_case_text?: string
	discovery?: OptionType
	picture?: ImageResponse
}

const formMapping = {
	"profile.picture_id": "picture",
	"profile.discovery": "discovery",
	"profile.use_case": "use_case",
	"profile.job_role": "job_role",
	"profile.job_title": "job_title",
} as const

const UserDetails = () => {
	const [picture, setPicture] = useState<File | null>()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const history = useHistory()

	const { data: user } = useFetchMeQuery()
	const [saveUser] = useUpdateMeMutation()

	const onboarding = useAppSelector(selectOnboarding)
	const { entry: scimSettings } = useAppSelector(selectScim)

	const methods = useForm<FormValues>({
		defaultValues: { first_name: "", last_name: "" },
	})
	const { control, watch, resetField, reset, setError } = methods

	const actions = useActions({
		uploadFile: (logo: File) => uploadFileCompat(logo),
		setOnboardingStep: (step: number) => setOnboardingStep(step),
		setOnboardingDone: () => setOnboardingDone(),
		setOnboardingUrl: (url: string) => setOnboardingUrl(url),
	})
	const isAdmin = isPortalAdmin(user ?? { groups: [] })
	const hasNextStep =
		onboarding && onboarding.currentStep + 1 < onboarding.steps.length

	/*
	 * Save user info
	 */
	const saveUserDetails = async (values: FormValues) => {
		const profile: UserProfileRequest = {
			job_title: values.job_title?.value ?? null,
			job_role: values.job_role?.value ?? null,
			...(isAdmin && {
				use_case: getUseCase(values),
				discovery: values.discovery?.value,
			}),
			picture_id: user?.profile?.picture?.id ?? null,
		}

		const payload = {
			first_name: values.first_name,
			last_name: values.last_name,
			profile,
		}

		if (picture) {
			const pictureWithFixedName = new File(
				[picture],
				removeDotsAndSpaces(picture.name),
			)

			const file = await actions.uploadFile(pictureWithFixedName)

			if (uploadFileCompat.fulfilled.match(file)) {
				payload.profile.picture_id = file.payload.id
			} else {
				errorToast(file.error.message)
				return
			}
		} else if (picture === null) {
			payload.profile.picture_id = null
		}

		const response = await saveUser(payload)

		if (response && isRejected(response)) {
			if (isApiResponseError(response.error)) {
				setErrors(response.error.formError, setError, errorToast, formMapping)
			}
			return
		}

		infoToast(t("desktop.onboarding.user_info.form.toast.update_success"))

		if (isAdmin) {
			const redirectURL = getRedirectURL(values.use_case)
			redirectURL && actions.setOnboardingUrl(redirectURL)
		}

		if (hasNextStep) {
			history.push(
				ONBOARDING_PATHS[onboarding.steps[onboarding.currentStep + 1]],
			)
			actions.setOnboardingStep(onboarding.currentStep + 1)

			return
		}

		actions.setOnboardingDone()
		history.push(
			isAdmin ? getRedirectURL(values.use_case) ?? "/" : onboarding?.url ?? "/",
		)
	}

	const useCases = watch("use_case")

	/*
	 * we need to remove the other selected items when user selects the NONE_OF_THOSE
	 * and when some other option is selected we need to remove NONE_OF_THOSE form selection
	 */
	useEffect(() => {
		if (
			useCases &&
			useCases.length > 1 &&
			isNoneOfThoseOptionSelected(useCases)
		) {
			const newUseCase =
				useCases.findIndex((useCase) => useCase.value === NONE_OF_THOSE) === 0
					? useCases.filter((useCase) => useCase.value !== NONE_OF_THOSE)
					: [{ value: NONE_OF_THOSE, label: USE_CASES.NONE_OF_THOSE }]

			resetField("use_case", { defaultValue: newUseCase })
		}
	}, [resetField, useCases, user])

	useEffect(() => {
		if (user) {
			const { first_name, last_name, profile } = user

			const job_title = getOption(JOB_TITLES_OPTIONS, profile?.job_title)
			const job_role = getOption(JOB_ROLES_OPTIONS, profile?.job_role)
			const use_case = profile?.use_case?.flatMap((useCase) =>
				isUseCase(useCase) ? { value: useCase, label: USE_CASES[useCase] } : [],
			)
			const use_case_text =
				profile?.use_case?.indexOf(NONE_OF_THOSE) === 0
					? profile?.use_case[1]
					: undefined
			const discovery = getOption(DISCOVERIES_OPTIONS, profile?.discovery)

			reset(
				isAdmin
					? {
							first_name,
							last_name,
							job_title,
							job_role,
							use_case,
							use_case_text,
							discovery,
							picture: profile?.picture,
					  }
					: {
							first_name,
							last_name,
							job_title,
							job_role,
							picture: profile?.picture,
					  },
			)
		}
	}, [reset, user, isAdmin])

	return (
		<RouteView className="UserDetails isConstrained">
			<Screen>
				<View className="UserDetails">
					<Heading>{t("desktop.onboarding.user_info.heading")}</Heading>
					<Intro>{t("desktop.onboarding.user_info.intro")}</Intro>
					<FormProvider {...methods}>
						<PageForm
							updateMode={true}
							onUpdate={saveUserDetails}
							submitButtonText={hasNextStep ? t("general.next") : undefined}
							additionalButton={
								<Button
									variant="secondary-white"
									onClick={() => history.push("/auth/logout")}
								>
									{t("desktop.settings.account.log_out_button")}
								</Button>
							}
						>
							<Field
								control={control}
								name="first_name"
								label={t("desktop.onboarding.user_info.form.first_name")}
								required
							>
								{(props) => (
									<Input
										{...props}
										maxLength={30}
										disabled={scimSettings?.enabled}
									/>
								)}
							</Field>
							<Field
								control={control}
								name="last_name"
								label={t("desktop.onboarding.user_info.form.last_name")}
								required
							>
								{(props) => (
									<Input
										{...props}
										maxLength={30}
										disabled={scimSettings?.enabled}
									/>
								)}
							</Field>
							<Field
								control={control}
								name="job_title"
								label={t("desktop.onboarding.user_info.form.job_title")}
								required={isAdmin ? true : false}
							>
								{(props) => (
									<Select
										{...props}
										options={JOB_TITLES_OPTIONS}
										clearable={!isAdmin ? true : false}
									/>
								)}
							</Field>
							<Field
								control={control}
								name="job_role"
								label={t("desktop.onboarding.user_info.form.job_role")}
								required={isAdmin ? true : false}
							>
								{(props) => (
									<Select
										{...props}
										options={JOB_ROLES_OPTIONS}
										clearable={!isAdmin ? true : false}
									/>
								)}
							</Field>
							{isAdmin && (
								<>
									<Field
										control={control}
										name="use_case"
										label={t("desktop.onboarding.user_info.form.use_case")}
										required
									>
										{(props) => (
											<Select isMulti {...props} options={USE_CASES_OPTIONS} />
										)}
									</Field>
									{isNoneOfThoseOptionSelected(useCases) && (
										<Field
											control={control}
											name="use_case_text"
											label={t(
												"desktop.onboarding.user_info.form.use_case_other",
											)}
											required
										>
											{(props) => <Input {...props} maxLength={30} />}
										</Field>
									)}
									<Field
										control={control}
										name="discovery"
										label={t("desktop.onboarding.user_info.form.discovery")}
										required
									>
										{(props) => (
											<Select {...props} options={DISCOVERIES_OPTIONS} />
										)}
									</Field>
								</>
							)}
							<Field
								className="UserDetails__picture"
								control={control}
								name="picture"
								label={<PictureLabel />}
							>
								{({ value, ...props }) => (
									<ImageSelector
										{...props}
										label={t("desktop.onboarding.user_info.form.picture")}
										onChange={setPicture}
										image={value}
										accept="image/png"
									/>
								)}
							</Field>
						</PageForm>
					</FormProvider>
				</View>
			</Screen>
		</RouteView>
	)
}

const PictureLabel = () => {
	const { t } = useTranslation()
	return (
		<div>
			<p>{t("desktop.onboarding.user_info.form.picture_info.title")}</p>
			<p className="picture__text">
				{t("desktop.onboarding.user_info.form.picture_info.guidelines")}
			</p>
			<p className="picture__text">
				{t("desktop.onboarding.user_info.form.picture_info.avatar_text")}
			</p>
		</div>
	)
}

export default UserDetails

const isNoneOfThoseOptionSelected = (useCases?: OptionType[]) =>
	!!(useCases && useCases.find((useCase) => useCase.value === NONE_OF_THOSE))

const getOption = (options: OptionType[], value?: string | null) =>
	value ? options.find((t) => t.value === value) : undefined

const getUseCase = (values: FormValues) => {
	if (!values.use_case) return

	let useCases: string[] = values.use_case.map((c) => c.value) ?? []
	if (useCases.indexOf(NONE_OF_THOSE) === 0 && values.use_case_text) {
		useCases = [...useCases, values.use_case_text]
	}
	return useCases
}

const getRedirectURL = (useCases?: OptionType[]) => {
	if (useCases?.find((useCase) => useCase.value === "DESKS")) {
		return ONBOARDING_REDIRECT_URLS.DESKS
	}

	if (useCases?.find((useCase) => useCase.value === "VISITORS")) {
		return ONBOARDING_REDIRECT_URLS.VISITORS
	}

	return ONBOARDING_REDIRECT_URLS.DESKS
}

const ONBOARDING_REDIRECT_URLS: Record<string, string> = {
	DESKS: DESKS_PATHS.general,
	VISITORS: VISITORS_PATHS.general,
} as const
