import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../hooks/useToast"
import Field from "../Field"
import { Checkbox } from "../basic/Checkbox"
import PageForm from "./PageFormHook"
import { setErrors } from "./formUtils"

import {
	postExperiments,
	updateExperiments,
} from "../../redux/experiments/experimentsSlice"
import { selectRoomsSettings } from "../../redux/experiments/selectors"
import { RoomsSettingsRequest } from "../../redux/experiments/types"
import { useAppSelector } from "../../redux/reducers"
import { selectUserPermissions } from "../../redux/user/selectors"
import { useActions } from "../../redux/utils"

import "./RoomsForm.sass"

type FormValues = {
	enabled: boolean
}

function RoomsForm() {
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const actions = useActions({
		postExperiments: (payload: RoomsSettingsRequest) =>
			postExperiments(payload),
		updateExperiments: (id: string, payload: RoomsSettingsRequest) =>
			updateExperiments({ id, ...payload }),
	})

	const permissions = useAppSelector(selectUserPermissions)
	const canEdit = permissions.includes("desk.delete_floorplan") // is office manager

	const { roomsSettings } = useAppSelector(selectRoomsSettings)
	const { data } = roomsSettings || {}

	const methods = useForm<FormValues>({
		defaultValues: {
			enabled: data?.values.enabled ?? false,
		},
	})
	const {
		setError,
		control,
		formState: { isSubmitting },
	} = methods

	const onSaveClick = async ({ enabled }: FormValues) => {
		let response

		if (roomsSettings && roomsSettings.id) {
			response = await actions.updateExperiments(roomsSettings.id, {
				type: "ROOMS_SETTINGS",
				values: {
					enabled: enabled,
				},
			})
		} else {
			response = await actions.postExperiments({
				type: "ROOMS_SETTINGS",
				values: {
					enabled: enabled,
				},
			})
		}

		if (
			postExperiments.rejected.match(response) ||
			updateExperiments.rejected.match(response)
		) {
			if (response.payload) {
				setErrors(response.payload, setError, errorToast)
			}
		} else {
			infoToast(
				t("desktop.settings.rooms.form.room_booking_settings_updated_toast"),
			)
		}
	}

	return (
		<FormProvider {...methods}>
			<PageForm
				className="RoomsForm"
				updateMode={true}
				onUpdate={onSaveClick}
				disabled={!canEdit}
			>
				<Field control={control} name="enabled">
					{(props) => (
						<Checkbox
							{...props}
							label={t("desktop.settings.rooms.form.in_app_room_booking")}
							description={t(
								"desktop.settings.rooms.form.in_app_room_booking_description",
							)}
							disabled={isSubmitting}
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default RoomsForm
