import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
} from "react"

import {
	SupportedEvents,
	analyticsEvent,
	analyticsIdentifyUser,
	enablePosthogRecording,
} from "../analytics"

import { useLazyFetchBuildingsQuery } from "../redux/api/buildings"
import { useLazyFetchMeQuery } from "../redux/api/me"
import { setIsLogin } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { selectIsAuth, selectIsLogin } from "../redux/selectors"
import { selectUser } from "../redux/user/selectors"
import { patchUser } from "../redux/users/usersSlice"
import { useActions } from "../redux/utils"

import { MemoizedErrorFallback } from "../components/RouteView"

export type UserContextType = {
	user: any
	isLoaded: boolean
}

export const UserContext = React.createContext<UserContextType>({
	user: null,
	isLoaded: false,
})

function UserProvider({ children }: PropsWithChildren<unknown>) {
	const [fetchUser, { error }] = useLazyFetchMeQuery()
	const memoizedError = useMemo(() => error && Error(error.message), [error])

	const { entry: user, isLoaded } = useAppSelector(selectUser)
	const isAuth = useAppSelector(selectIsAuth)
	const isLogin = useAppSelector(selectIsLogin)
	const [
		fetchBuildings,
		{
			data: { results: buildings = [], count: buildingsCount = 0 } = {},
			isSuccess,
			isFetching,
		},
	] = useLazyFetchBuildingsQuery()

	const actions = useActions({
		setIsLoginFalse: () => setIsLogin(false),
		patchManagementUser: (email: string, building_id: string) =>
			patchUser({ email, payload: { building_id } }),
	})

	const reloadPage = useCallback(() => window.location.reload(), [])

	useEffect(() => {
		if (!isLoaded && isAuth) {
			fetchUser()
		}
	}, [isAuth, fetchUser, isLoaded])

	useEffect(() => {
		if (!isFetching && !isSuccess && isAuth) {
			fetchBuildings({ limit: 1 })
		}
	}, [isAuth, fetchBuildings, isSuccess, isFetching])

	useEffect(() => {
		if (isLoaded) {
			analyticsIdentifyUser(user)
			enablePosthogRecording(user?.groups)
		}
	}, [user, isLoaded])

	useEffect(() => {
		if (isLoaded && user.email && !user.building && buildingsCount === 1) {
			actions
				.patchManagementUser(user.email, buildings[0].id)
				.then((result) => {
					if (patchUser.fulfilled.match(result)) {
						fetchUser()
					}
				})
		}
	}, [fetchUser, buildings, user, isLoaded, actions, buildingsCount])

	useEffect(() => {
		if (isLoaded && isLogin) {
			analyticsEvent(SupportedEvents.USER_LOGGED_IN, {
				id: user.id,
				email: user.email,
			})
			actions.setIsLoginFalse()
		}
	}, [actions, user, isLoaded, isLogin])

	if (memoizedError) {
		return (
			<MemoizedErrorFallback resetError={reloadPage} error={memoizedError} />
		)
	}

	return <>{children}</>
}

export default UserProvider
