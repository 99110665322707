import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react"

import { get } from "../../../api"
import { UrlParameters } from "../../../api/queryBuilder"
import { Input, InputProps } from "../../basic/Input"
import debounce from "lodash.debounce"

import { useAppSelector } from "../../../redux/reducers"

import "./style.sass"

type SuggestionListProps<TOption extends unknown = unknown> = {
	options: TOption[]
	onSelect: (v: TOption) => void
	getOptionLabel: (option: TOption) => string
}

const SuggestionList = <TOption extends unknown = unknown>({
	options,
	onSelect,
	getOptionLabel,
}: SuggestionListProps<TOption>) => {
	return (
		<div className="SuggestionList">
			{options.map((option: TOption, i) => {
				const onClick = () => {
					onSelect(option)
				}

				return (
					<div className="SuggestionItem" onClick={onClick} key={i}>
						{getOptionLabel(option)}
					</div>
				)
			})}
		</div>
	)
}

type Props<TOption extends unknown = unknown> = {
	urlGenerator: (fetchOptions: UrlParameters) => string
	filterResultsFn?: (option: TOption) => boolean
	getOptionLabel: (option: TOption) => string
	getOptionValue: (option: TOption) => string
	minCharForSearch?: number
	autoCompleteCallback?: (v: TOption) => void
} & InputProps

const AsyncAutocompleteInternal = <TOption extends unknown = unknown>(
	{
		value,
		onChange,
		urlGenerator,
		filterResultsFn,
		getOptionLabel,
		getOptionValue,
		minCharForSearch = 3,
		autoCompleteCallback,
		...props
	}: Props<TOption>,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

	const [textValue, setTextValue] = useState(value)
	const [showList, setShowList] = useState(false)
	const [options, setOptions] = useState<TOption[]>([])

	const { access_token } = useAppSelector((state) => state.auth)

	const loadData = (search: string | undefined) => {
		if (!access_token) return

		const fetchOptions = {
			limit: 10,
			search,
		}

		if (search && search.trim().length < 1) {
			delete fetchOptions.search
		}
		get(urlGenerator(fetchOptions), {}, access_token)
			.then((response) => {
				if (response.ok) {
					return response.json().then((data) => {
						setOptions(
							filterResultsFn
								? data.results.filter(filterResultsFn)
								: data.results,
						)
					})
				}
				setOptions([])
			})
			.catch((_) => {
				setOptions([])
			})
	}

	const debouncedDataLoader = debounce(loadData, 500)

	const innerOnChange = (value: string) => {
		if (value.length >= minCharForSearch) {
			setShowList(true)
			debouncedDataLoader(value)
		} else {
			setShowList(false)
		}
		setTextValue(value)
		onChange && onChange(value)
	}

	const innerSelect = async (value: TOption) => {
		setTextValue(getOptionLabel(value))

		autoCompleteCallback && autoCompleteCallback(value)

		onChange && onChange(getOptionLabel(value))
	}

	const innerBlur = () => {
		timeoutRef.current = setTimeout(() => {
			setShowList(false)
		}, 200)
	}

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}
		}
	}, [])

	return (
		<div className="AsyncAutocomplete">
			<div className="Wrapper">
				<Input
					autoComplete="off"
					{...props}
					value={textValue}
					onChange={innerOnChange}
					onBlur={innerBlur}
					ref={ref}
				/>
				{showList && options.length > 0 && (
					<SuggestionList
						options={options}
						onSelect={innerSelect}
						getOptionLabel={getOptionLabel}
					/>
				)}
			</div>
		</div>
	)
}

export default forwardRef(AsyncAutocompleteInternal) as <TOption>(
	props: Props<TOption> & {
		ref?: ForwardedRef<HTMLInputElement>
	},
) => ReturnType<typeof AsyncAutocompleteInternal> // The forwardRef does not know how to pass types from component
