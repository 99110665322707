import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"

import { CheckInResponse } from "../redux/check_ins/types"

import { CheckIns } from "../components/CheckIns"

type Props = {
	open: boolean
	handleCheckIn: (checkIn: CheckInResponse) => void
	onClose: () => void
}

const CheckInsModal = ({ open, onClose, handleCheckIn }: Props) => {
	//fix for react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`.
	ReactModal.setAppElement("#root")

	return (
		<ReactModal
			isOpen={open}
			style={{
				content: { ...contentStyle, padding: 0 },
				overlay: overlayStyle,
			}}
			onRequestClose={() => onClose()}
		>
			<CheckIns handleCheckIn={handleCheckIn} />
		</ReactModal>
	)
}

export default CheckInsModal
