import { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { usersURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import { DEPARTMENT_PATHS } from "../../screens/Settings/Departments/constants"
import { calculatePercentAmount } from "../../utils"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import { LimitOccupancyDecider } from "../advanced/LimitOccupancyDecider"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import PageForm from "./PageFormHook"
import { setErrors } from "./formUtils"

import {
	useCreateDepartmentMutation,
	useDeleteDepartmentMutation,
	useFetchDepartmentsQuery,
	useUpdateDepartmentMutation,
} from "../../redux/api/departments"
import { DepartmentResponse } from "../../redux/api/departments/types"
import { getNumberOfDesksInDepartment } from "../../redux/api/departments/utils"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { formatUser } from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"

import "./DepartmentForm.sass"

type Props = {
	department: DepartmentResponse | null
}

type FormValues = {
	name: string
	users: UserResponse[]
	capacity_limit: number | null
	active: boolean
}

const DepartmentForm = ({ department }: Props) => {
	const history = useHistory()
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const { data: { results: departments = [] } = {} } =
		useFetchDepartmentsQuery()

	const { id, name, users, capacity_limit, active } = department ?? {}

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? "",
			capacity_limit: capacity_limit ?? null,
			active: department ? active : true,
			users: users ?? [],
		},
	})

	const {
		setError,
		control,
		getValues,
		formState: { isSubmitting },
	} = methods

	const [createDepartment] = useCreateDepartmentMutation()
	const [updateDepartment] = useUpdateDepartmentMutation()
	const [deleteDepartment] = useDeleteDepartmentMutation()
	const onCreateClick = useCallback(
		async ({ name, capacity_limit, users, active }: FormValues) => {
			const response = await createDepartment({
				name,
				capacity_limit: capacity_limit ?? undefined,
				users: users.map((u) => u.email),
				active,
			})

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
					return
				}
				errorToast(response.error.message)
				return
			}

			infoToast(
				t(
					"desktop.settings.departments.department_form.department_created_toast",
				),
			)

			analyticsEvent(SupportedEvents.DEPARTMENT_ADD, {
				id: response.data.id,
				name: response.data.name,
				total: departments.length + 1,
			})

			history.push(DEPARTMENT_PATHS.root)
		},
		[
			history,
			departments,
			infoToast,
			createDepartment,
			t,
			setError,
			errorToast,
		],
	)

	const onUpdateClick = useCallback(
		async ({ name, capacity_limit, users, active }: FormValues) => {
			if (!id) {
				return
			}
			const response = await updateDepartment({
				id,
				body: {
					name,
					capacity_limit: capacity_limit,
					users: users.map((u) => u.email),
					active,
				},
			})

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast)
					return
				}
				errorToast(response.error.message)
				return
			}

			infoToast(
				t(
					"desktop.settings.departments.department_form.department_updated_toast",
				),
			)

			analyticsEvent(SupportedEvents.DEPARTMENT_UPDATE, {
				id: response.data.id,
				name: response.data.name,
			})

			history.push(DEPARTMENT_PATHS.root)
		},
		[id, history, updateDepartment, infoToast, t, setError, errorToast],
	)

	const onDeleteClick = useCallback(async () => {
		if (!id) {
			return
		}
		const response = await deleteDepartment(id)

		if (isRejected(response)) {
			const { error } = response
			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast)
				return
			}
			errorToast(response.error.message)
			return
		}

		infoToast(
			t(
				"desktop.settings.departments.department_form.department_deleted_toast",
			),
		)
		analyticsEvent(SupportedEvents.DEPARTMENT_DELETE, {
			id: id,
			total: departments.length - 1,
		})

		history.push(DEPARTMENT_PATHS.root)
	}, [
		id,
		history,
		departments,
		deleteDepartment,
		infoToast,
		t,
		setError,
		errorToast,
	])

	const totalDeskNo = getNumberOfDesksInDepartment(department)
	const activeDeskAmount = calculatePercentAmount(
		totalDeskNo,
		getValues().capacity_limit || 100,
	)
	const updateMode = !!id

	return (
		<FormProvider {...methods}>
			<PageForm
				backUrl={DEPARTMENT_PATHS.root}
				className="DepartmentForm"
				updateMode={updateMode}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={onDeleteClick}
			>
				<Field
					control={control}
					name="name"
					label={t(
						"desktop.settings.departments.department_form.department_name",
					)}
				>
					{(props) => (
						<Input
							autoFocus
							maxLength={60}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="users"
					label={t(
						"desktop.settings.departments.department_form.assigned_employees",
					)}
					subText={t("general.optional")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							isMulti
							urlGenerator={(fetchOptions) => usersURL(fetchOptions)}
							nothingFoundMessage={t(
								"desktop.settings.departments.department_form.no_users_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field control={control} name="capacity_limit">
					{(props) => (
						<LimitOccupancyDecider
							{...props}
							label={t(
								"desktop.settings.departments.department_form.department_occupancy_limit",
							)}
							infoText={t(
								"desktop.settings.departments.department_form.department_bookable_desks",

								{ activeDeskAmount, totalDeskNo },
							)}
							disabled={isSubmitting}
						/>
					)}
				</Field>
				<Field control={control} name="active" className="asset-enabled-field">
					{(props) => (
						<Switch
							label={t(
								"desktop.settings.departments.department_form.enable_department",
							)}
							disabled={isSubmitting}
							{...props}
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default DepartmentForm
