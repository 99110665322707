import { useCallback } from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { buildingsWithVMURL, usersURL, visitorsURL } from "../../../api"
import { timeZone as defaultTimezone } from "../../../dayjs"
import {
	InternalTime,
	OptionType,
	RecurringType,
} from "../../../types/sharedTypes"
import { EMAIL_REGEX, setTimeToDayjs, toInternalTime } from "../../../utils"
import { convertInternalTimeToUserTime, isBefore } from "../../../utils"
import Field from "../../Field"
import AsyncAutocomplete from "../../advanced/AsyncAutocomplete"
import AsyncSelect from "../../advanced/AsyncSelect"
import { DatePicker } from "../../basic/DatePicker"
import { Input } from "../../basic/Input"
import InputNumber from "../../basic/InputNumber"
import Switch from "../../basic/Switch"
import { TimeRange, TimeRangePicker } from "../../basic/TimeRangePicker"
import ModalForm from "../ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { selectAppDates } from "../../../redux/app/selectors"
import { importInvitesEdit } from "../../../redux/invites/invitesSlice"
import { Building, Host, InviteCSVResponse } from "../../../redux/invites/types"
import { useAppSelector } from "../../../redux/reducers"
import { formatUser } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"
import { VisitorSearchResponse } from "../../../redux/visitors/types"

import "./InviteCSVEditForm.sass"

type FormValues = {
	full_name: string
	company_name?: string
	email: string
	phone?: string
	date: string
	visit_time: TimeRange
	building: Building
	host: Host
	schedule?: OptionType<RecurringType | undefined>
	until: string
	guests?: number
	additional_info?: string
	send_invite_to_visitor: boolean
	send_notification_to_host: boolean
}

type Props = {
	invite: InviteCSVResponse
}

const DEFAULT_START_TIME: InternalTime = "09:00"
const DEFAULT_END_TIME: InternalTime = "17:00"

const InviteCSVEditForm = ({ invite }: Props) => {
	const { t } = useTranslation()
	const { closeModal } = useModals()

	const { currentDate } = useAppSelector(selectAppDates)

	const actions = useActions({
		importInvitesEdit: (invite: InviteCSVResponse) => importInvitesEdit(invite),
	})

	const {
		id,
		full_name,
		company_name,
		email,
		phone,
		start,
		end,
		building,
		host,
		guests,
		additional_info,
		send_invite_to_visitor,
		send_notification_to_host,
		errors,
	} = invite

	const methods = useForm<FormValues>({
		defaultValues: {
			full_name: errors?.full_name ? "" : full_name,
			company_name: errors?.company_name ? "" : company_name ?? "",
			email: errors?.email ? "" : email,
			phone: errors?.phone ? "" : phone ?? "",
			date: errors?.start ? currentDate.toISOString() : start,
			visit_time: {
				start: errors?.start ? DEFAULT_START_TIME : toInternalTime(start),
				end: errors?.end ? DEFAULT_END_TIME : toInternalTime(end),
			},
			building: errors?.building ? undefined : building,
			host: errors?.host ? undefined : host,
			schedule: undefined,
			until: undefined,
			guests: guests ?? 0,
			additional_info: errors?.additional_info ? "" : additional_info,
			send_invite_to_visitor: send_invite_to_visitor ?? false,
			send_notification_to_host: send_notification_to_host ?? false,
		},
	})

	const { control } = methods

	const onUpdateClick = useCallback(
		async (values: FormValues) => {
			actions.importInvitesEdit({
				id: id,
				full_name: values.full_name,
				company_name: values.company_name,
				email: values.email,
				phone: values.phone,
				start: setTimeToDayjs(
					dayjs(values.date),
					values.visit_time.start!,
				).toISOString(),
				end: setTimeToDayjs(
					dayjs(values.date),
					values.visit_time.end!,
				).toISOString(),
				tz: defaultTimezone,
				building: values.building,
				host: values.host,
				schedule:
					values.schedule && values.schedule.value
						? {
								freq: values.schedule.value,
								until: values.until,
						  }
						: undefined,
				guests: values.guests ?? 0,
				additional_info: values.additional_info,
				send_invite_to_visitor: values.send_invite_to_visitor,
				send_notification_to_host: values.send_notification_to_host,
			})

			closeModal()
		},
		[actions, closeModal, id],
	)

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="InviteCSVEditForm"
				title={t("desktop.manage.visitors.invite.csv_invite.edit_csv_invite")}
				updateMode={true}
				onUpdate={onUpdateClick}
			>
				<Field
					control={control}
					name="full_name"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.full_name")}
					rules={{
						required: t("desktop.manage.visitors.form.full_name_required"),
					}}
				>
					{(props) => (
						<AsyncAutocomplete
							{...props}
							urlGenerator={(fetchOptions) => visitorsURL(fetchOptions)}
							getOptionLabel={(visitor: VisitorSearchResponse) =>
								visitor.full_name
							}
							getOptionValue={(visitor: VisitorSearchResponse) =>
								visitor.full_name
							}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="company_name"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.company_name")}
					subText={`(${t("general.optional")})`}
				>
					{(props) => <Input {...props} />}
				</Field>

				<Field
					control={control}
					name="email"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.email")}
					rules={{
						required: t("desktop.manage.visitors.form.email_required"),
						pattern: {
							value: EMAIL_REGEX,
							message: t("desktop.manage.visitors.form.invalid_email_format"),
						},
					}}
				>
					{(props) => <Input {...props} />}
				</Field>
				<Field
					control={control}
					name="phone"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.phone_number")}
					subText={`(${t("general.optional")})`}
				>
					{(props) => <Input {...props} placeholder="+386 31 386 386" />}
				</Field>

				<Field
					control={control}
					name="date"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.visit_date")}
					rules={{
						required: t("desktop.manage.visitors.form.visit_date_required"),
					}}
				>
					{(props) => (
						<DatePicker
							{...props}
							showMonthDropdown
							showYearDropdown
							minDate={dayjs().startOf("day").toDate()}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="visit_time"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.visit_time")}
					rules={{
						validate: (value: TimeRange) =>
							value?.start && value?.end && isBefore(value.end, value.start)
								? t("general.validators.end_time_after_start_time", {
										startTime: convertInternalTimeToUserTime(value.start),
								  })
								: undefined,
					}}
				>
					{(props) => <TimeRangePicker {...props} />}
				</Field>

				<Field
					control={control}
					name="building"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.select_building")}
					rules={{
						required: t(
							"desktop.manage.visitors.form.select_building_required",
						),
					}}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => buildingsWithVMURL(fetchOptions)}
							getOptionLabel={(building) => building.name}
							getOptionValue={(building) => building.id}
						/>
					)}
				</Field>

				<div className="field-width-50"></div>

				<Field
					control={control}
					name="host"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.select_host")}
					rules={{
						required: t("desktop.manage.visitors.form.select_host_required"),
					}}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => usersURL(fetchOptions)}
							nothingFoundMessage={t(
								"desktop.settings.desks.desk_form.no_user_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="guests"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.additional_guests")}
				>
					{(props) => <InputNumber {...props} />}
				</Field>

				<Field
					control={control}
					name="additional_info"
					label={t("desktop.manage.visitors.form.additional_information")}
					subText={`(${t("general.optional")})`}
				>
					{(props) => (
						<Input className="additional-info-input" {...props} multiline />
					)}
				</Field>

				<Field control={control} name="send_invite_to_visitor">
					{(props) => (
						<Switch
							{...props}
							label={t(
								"desktop.manage.visitors.form.send_invite_email_to_visitor",
							)}
							disabled
						/>
					)}
				</Field>

				<Field control={control} name="send_notification_to_host">
					{(props) => (
						<Switch
							{...props}
							label={t(
								"desktop.manage.visitors.form.send_notifications_to_host",
							)}
							disabled
						/>
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default InviteCSVEditForm
