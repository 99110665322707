import React from "react"

import {
	Redirect,
	Switch as RouteSwitch,
	useRouteMatch,
} from "react-router-dom"

import Invoices from "./Invoices"
import Overview from "./Overview"
import PaymentMethod from "./PaymentMethod"
import CompanyDetailsFormPage from "./Payments/CompanyDetailsFormPage"
import SubscriptionFlow from "./SubscriptionFlow/SubscriptionFlow"
import { BILLING_PATHNAMES } from "./constants"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Billing = () => {
	let { path } = useRouteMatch()

	return (
		<RouteSwitch>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.overview.root}
				// accessPermission="portal_subscriptions.change_subscriptions"
				component={Overview}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.overview.companyDetails}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={CompanyDetailsFormPage}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.overview.paymentMethod}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={PaymentMethod}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.invoices}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={Invoices}
			/>
			{/* TODO: Change components to correct ones once they are done */}
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.room}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={SubscriptionFlow}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.desk}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={SubscriptionFlow}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.visitor}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={SubscriptionFlow}
			/>
			<ProtectedRoute
				exact
				path={BILLING_PATHNAMES.sign}
				accessPermission="portal_subscriptions.change_subscriptions"
				component={SubscriptionFlow}
			/>
			<Redirect to={`${path}/overview`} />
		</RouteSwitch>
	)
}

export default Billing
