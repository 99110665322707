import React from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { BILLING_URL } from "../../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../../constants"
import { useToast } from "../../../../hooks/useToast"
import RoomActivationModal from "./RoomActivationModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useLazyFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"
import {
	useActivateServiceMutation,
	useDeactivateServiceMutation,
	useFetchServicesQuery,
} from "../../../../redux/api/services"
import { Services } from "../../../../redux/api/services/types"
import { isRejected } from "../../../../redux/api/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectUserGroups } from "../../../../redux/user/selectors"
import { isNormalUser } from "../../../../redux/user/utils"

import PageForm from "../../../../components/Form/PageFormHook"
import Switch from "../../../../components/basic/Switch"

import "./RoomsSettingsForm.sass"

type FormValues = {
	enabled: boolean
}

const RoomsSettingsForm = () => {
	const { t } = useTranslation()
	const { openModal, closeModal } = useModals()
	const { infoToast, errorToast } = useToast()

	const { data: services } = useFetchServicesQuery()
	const [activateService] = useActivateServiceMutation()
	const [deactivateService] = useDeactivateServiceMutation()
	const [fetchSubscriptions] = useLazyFetchSubscriptionsQuery()

	const groups = useAppSelector(selectUserGroups)

	const methods = useForm<FormValues>({
		defaultValues: {
			enabled: false,
		},
	})
	const {
		formState: { isSubmitting },
	} = methods

	const handleServiceActivation = async () => {
		const response = await activateService("room")

		if (isRejected(response)) {
			errorToast(response.error.message)

			return
		}

		await fetchSubscriptions({ limit: FETCH_WITH_NO_LIMIT })

		infoToast(t("desktop.settings.rooms.overview.form.toasts.activate_success"))
		closeModal()
	}

	const handleServiceDeactivation = async () => {
		const response = await deactivateService("room")

		if (isRejected(response)) {
			errorToast(response.error.message)

			return
		}

		await fetchSubscriptions({ limit: FETCH_WITH_NO_LIMIT })

		infoToast(
			t("desktop.settings.rooms.overview.form.toasts.deactivate_success"),
		)
		closeModal()
	}

	const redirectToPayments = () => {
		closeModal()
		// TODO: Redirect to billing page when it's ready
		window.open(BILLING_URL, "_blank")
	}

	const handleOnRoomEnableChange = (isActivation: boolean) => {
		const hasTrial = hasTrialAvailable(services)

		openModal(RoomActivationModal, {
			isActivation,
			hasTrialAvailable: hasTrial,
			onConfirm: async () => {
				if (isActivation) {
					if (hasTrial) {
						await handleServiceActivation()

						return
					}
					redirectToPayments()
				}

				await handleServiceDeactivation()
			},
			onCancel: closeModal,
		})
	}

	const canEdit = !isNormalUser({ groups })
	const isDisabled = !canEdit || isSubmitting

	return (
		<FormProvider {...methods}>
			<PageForm<FormValues>
				updateMode={false}
				className="RoomSettingsForm"
				header={
					<Switch
						value={!!services?.room?.active}
						onChange={(value) => handleOnRoomEnableChange(value)}
						label={t("desktop.settings.rooms.overview.form.enable_joan_room")}
						disabled={isSubmitting || isDisabled}
					/>
				}
				hide
			/>
		</FormProvider>
	)
}

export default RoomsSettingsForm

/**
 * Checks if a trial is available for the room.
 * @param services - The services object containing room information.
 * @returns A boolean indicating whether a trial is available.
 */
const hasTrialAvailable = (services?: Services): boolean => {
	if (!services) {
		return true
	}

	if (services.room.active === false && !services.room.trial_end) {
		return true
	}

	if (
		services.room.active === false &&
		services.room.trial_end &&
		dayjs().isBefore(services.room.trial_end)
	) {
		return true
	}

	return false
}
