import React from "react"

import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { FilterSpecialValues } from "../../Filter/types"
import { HorizontalListMultiSelect } from "./HorizontalListMultiSelect"

import { useFetchAmenitiesQuery } from "../../../redux/api/amenities"

export type AmenitiesFilterProps = {
	onSelect: (text: string[]) => void
	preSelected?: string[]
}

const AmenitiesFilter = ({ onSelect, preSelected }: AmenitiesFilterProps) => {
	const { t } = useTranslation()

	const { data: { results: amenities = [] } = {} } = useFetchAmenitiesQuery({
		limit: FETCH_WITH_NO_LIMIT,
	})

	const hasAmenities: boolean = amenities.length > 0
	let amenitiesWithAll = amenities

	if (hasAmenities) {
		if (!amenities.find((d) => d.id === FilterSpecialValues.ALL)) {
			amenitiesWithAll = [
				{
					name: t("mobile.general.all_amenities"),
					id: FilterSpecialValues.ALL,
				},
				...amenities,
			]
		}
	}

	const handleOnSelectAmenity = (selected: string[]) => {
		onSelect(selected)
	}

	return !hasAmenities ? null : (
		<HorizontalListMultiSelect
			listElements={amenitiesWithAll}
			onSelect={handleOnSelectAmenity}
			preSelected={preSelected}
		>
			{t("mobile.general.amenities")}
		</HorizontalListMultiSelect>
	)
}

export default AmenitiesFilter
