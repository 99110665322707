import { PaginatedResponse } from "../reduxUtils"

export enum DeviceType {
	TABLET = "TABLET",
	PRINTER = "PRINTER",
}

export enum PrinterModelType {
	"QL-820NWB" = "Brother QL-820NWB",
	"QL-720NW" = "Brother QL-720NW",
	"QL-710W" = "Brother QL-710W",
}

export type ConnectedDevice = {
	id: string
	name: string
}

export type Building = {
	id: string
	name: string
}

export type TabletResponse = {
	type: DeviceType.TABLET
	id: string
	name?: string
	model: string
	version: string
	building?: Building
	battery_status: number
	battery_charging?: boolean
	status_sent_at?: string
	connected_device?: ConnectedDevice
	connected_devices?: ConnectedDevice[]
}

export type TabletPairRequest = {
	pin: string
	name: string
	building_id?: string
	connected_device_id?: string | null
}

export type TabletUpdateRequest = {
	name: string
	building_id?: string
	connected_device_id?: string | null
}

export type PrinterResponse = {
	type: DeviceType.PRINTER
	id: string
	name?: string
	model: PrinterModelType
	ip_address: string
	building?: Building
	connected_device?: ConnectedDevice
	connected_devices?: ConnectedDevice[]
}

export type PrinterRequest = {
	name: string
	model: string
	ip_address: string
}

export type PrinterUpdateRequest = {
	name: string
	model: string
	building_id?: string
	ip_address?: string
}

export type DeviceResponse = TabletResponse | PrinterResponse

export type DevicesResponse = PaginatedResponse<DeviceResponse>

export type DevicesAction = {
	type: string
	payload: DevicesResponse | string
}
