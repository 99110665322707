import React, { useEffect, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { API_ROOT } from "../../api/urls"
import { useBackendPagination } from "../../hooks/useBackendPagination"
import CSVImportModal from "../../modals/CSVImportModal"
import PersonModal from "../../modals/PersonModal"
import { getDepartmentString } from "../../utils"
import LicensesInfoList from "./LicensesInfoList"
import { useModals } from "@mattjennings/react-modal-stack"

import { useAppSelector } from "../../redux/reducers"
import { selectUser, selectUserPermissions } from "../../redux/user/selectors"
import { formatUser } from "../../redux/user/utils"
import {
	FetchOptions,
	UserResponse,
	isLicenseFilter,
} from "../../redux/users/types"
import { ENTRIES_PER_PAGE, fetchUsers } from "../../redux/users/usersSlice"
import { ignoreAllFilter } from "../../redux/utils"
import { useActions } from "../../redux/utils"

import Card from "../../components/Card"
import DepartmentFilter from "../../components/Filter/DepartmentFilter"
import FilterSpace from "../../components/Filter/FilterSpace"
import Filters from "../../components/Filter/Filters"
import LicenseFilter from "../../components/Filter/LicenseFilter"
import RoleFilter from "../../components/Filter/RoleFilter"
import SearchFilter from "../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../components/Filter/types"
import Heading from "../../components/Heading"
import Intro from "../../components/Intro"
import LicenseIndicator from "../../components/LicenseIndicator"
import Pagination from "../../components/Pagination"
import Space from "../../components/Space"
import Button from "../../components/basic/Button"
import Loader from "../../components/basic/Loader"

import "./People.sass"

const scimURL = `${API_ROOT}/settings/integrations/scim/?kiosk=1`

const getUserRole = (person: UserResponse) => {
	if (!person.groups) return ""

	if (person.groups.includes("portal_officer_manager"))
		return "/ Office Manager"

	if (person.groups.includes("portal_admin")) return "/ IT Admin"

	return ""
}

const IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT =
	process.env.REACT_APP_IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT

function People() {
	const { openModal } = useModals()
	const { t } = useTranslation()
	const actions = useActions({
		fetchUsers: (options: FetchOptions) => fetchUsers(options),
	})

	// Filters
	const [search, setSearch] = useState<string>(FilterSpecialValues.EMPTY)
	const [roleFilter, setRoleFilter] = useState<string>(FilterSpecialValues.ALL)
	const [licenseFilter, setLicenseFilter] = useState<string>(
		FilterSpecialValues.ALL,
	)
	const [departmentFilter, setDepartmentFilter] = useState<string>(
		FilterSpecialValues.ALL,
	)

	const isAllFilter = (value: string) => value === FilterSpecialValues.ALL

	useEffect(() => {
		actions.fetchUsers({
			limit: ENTRIES_PER_PAGE,
			offset: 0,
			search: search,
			...(!isAllFilter(roleFilter) && { role_id: roleFilter }),
			...(!isAllFilter(departmentFilter) && {
				department_id: departmentFilter,
			}),
			...(isLicenseFilter(licenseFilter) && { license: licenseFilter }),
		})
	}, [actions, search, roleFilter, departmentFilter, licenseFilter])

	const {
		entries: people,
		isLoading: arePeopleLoading,
		initialLoad,
		count,
		offset,
	} = useAppSelector((state) => state.users)
	const { entry: user } = useAppSelector(selectUser)

	const permissions = useAppSelector(selectUserPermissions)
	const canAddUsersCSVs = permissions.includes("users.change_user")

	const { from, to, hasNext, hasPrevious, paginationLinks } =
		useBackendPagination({
			offset,
			totalNumberOfItems: count,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 7,
			maxTrailingLinks: 2,
		})

	const stateToFetchOptions = () => {
		return {
			limit: ENTRIES_PER_PAGE,
			search,
			role_id: ignoreAllFilter(roleFilter),
			department_id: ignoreAllFilter(departmentFilter),
			...(isLicenseFilter(licenseFilter) && { license: licenseFilter }),
		}
	}

	const handlePrevClick = () => {
		actions.fetchUsers({
			offset: offset - ENTRIES_PER_PAGE,
			...stateToFetchOptions(),
		})
	}

	const handleNextClick = () => {
		actions.fetchUsers({
			offset: offset + ENTRIES_PER_PAGE,
			...stateToFetchOptions(),
		})
	}

	const handlePageClick = (page: number) => {
		actions.fetchUsers({
			offset: ENTRIES_PER_PAGE * (page - 1),
			...stateToFetchOptions(),
		})
	}

	const handleEditClick = (person: UserResponse) => {
		openModal(PersonModal, {
			person: { ...person, type: (person.groups || [])[0] },
			fetchOptions: {
				offset,
				...stateToFetchOptions(),
			},
		})
	}

	const handleNewClick = () => {
		openModal(PersonModal, {
			person: {
				first_name: "",
				last_name: "",
				email: "",
				type: "portal_user",
				default_building: "",
				phone_number: "",
				departments: [],
			},
		})
	}

	const userCount = people.filter((u: UserResponse) => !!u.last_login).length
	const employeeNoun = userCount !== 1 ? "people" : "person"
	const employeeVerb = userCount !== 1 ? "are" : "is"

	const { entry: scim } = useAppSelector((state) => state.scim)

	const { enabled: isSCIMEnabled } = scim ?? {}
	const isEditingEnabled = !isSCIMEnabled

	const thisUser = useAppSelector((state) => state.user.entry)
	const amPortalAdmin = thisUser?.groups?.includes("portal_admin") ?? false

	const cardClassNames = classNames({
		people: true,
		isLoading: arePeopleLoading && initialLoad,
	})

	return (
		<div className="People">
			<Heading>
				<span>People</span>
			</Heading>

			<Intro>
				<div className="description">
					{isSCIMEnabled ? (
						<p>
							Review active employees and their status (office manager, admin).
						</p>
					) : (
						<p>
							Invite other employees, such as an office manager, or remove
							inactive accounts.
							<br />
							{amPortalAdmin ? (
								<>
									You can also configure provisioning and management using{" "}
									<a href={scimURL}>SCIM</a>.
								</>
							) : (
								<>
									Your administrator can also configure provisioning and
									management using SCIM.
								</>
							)}
						</p>
					)}

					{IS_LAST_LOGGED_IN_ATTRIBUTE_CORRECT && !arePeopleLoading && (
						<p>
							<strong>
								{userCount} {employeeNoun}
							</strong>{" "}
							{employeeVerb} actively signed into this organization account.
						</p>
					)}

					{isSCIMEnabled && (
						<p className="notice">
							You chose to provision and manage users using SCIM. Due to this,
							we only allow you to update users' departments. Other options will
							be synced via SCIM.
							<br />
							<br />
							{amPortalAdmin ? (
								<>
									You can change this setting on the{" "}
									<a href={scimURL}>SCIM Integration</a> screen.
								</>
							) : (
								<>
									Refer to your administrator if you wish to change this
									setting.
								</>
							)}
						</p>
					)}
				</div>
				{(canAddUsersCSVs || isEditingEnabled) && (
					<div className="buttons">
						{canAddUsersCSVs && (
							<Button
								onClick={() => openModal(CSVImportModal)}
								variant="secondary"
								isDisabled={arePeopleLoading}
								isSmall
							>
								Import CSV
							</Button>
						)}
						{isEditingEnabled && (
							<Button
								onClick={() => handleNewClick()}
								isDisabled={arePeopleLoading}
								isSmall
							>
								Add user
							</Button>
						)}
					</div>
				)}
			</Intro>

			<LicensesInfoList
				licenseType="user"
				tooltipContent={
					<div className="people-license-tooltip">
						{t("desktop.settings.people.license_tooltip")}
					</div>
				}
			/>
			<Space size={0.75} />
			<Filters>
				<RoleFilter value={roleFilter} onChange={setRoleFilter} />
				<DepartmentFilter
					value={departmentFilter}
					onChange={setDepartmentFilter}
					showAll={true}
					showNotAssigned={true}
				/>

				<LicenseFilter value={licenseFilter} onChange={setLicenseFilter} />
				<FilterSpace />
				<SearchFilter
					value={search}
					onChange={setSearch}
					placeholder="Search users"
				/>
				{arePeopleLoading && <Loader size="small" />}
			</Filters>

			<Space size={0.75} />

			<Card className={cardClassNames}>
				{arePeopleLoading && !initialLoad && <span>Loading...</span>}

				{people.length === 0 && !arePeopleLoading && (
					<span>No people found.</span>
				)}

				{people.map((person: UserResponse, i: number) => (
					<PeopleItem
						key={`person-${i}`}
						person={person}
						user={user}
						onEditClick={handleEditClick}
						arePeopleLoading={arePeopleLoading}
						amPortalAdmin={amPortalAdmin}
					/>
				))}
				{paginationLinks.length > 1 && (
					<Pagination
						links={paginationLinks}
						setPage={handlePageClick}
						onPrevious={handlePrevClick}
						onNext={handleNextClick}
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						from={from}
						to={to}
						total={count}
						items={"people"}
					/>
				)}
			</Card>

			<div className="invite-users">
				You will need to notify employees about the service manually. We've
				prepared a template together with a knowledge base article with{" "}
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://support.getjoan.com/hc/en-us/articles/360017188140"
				>
					instructions for new users
				</a>
				.
			</div>
		</div>
	)
}

export default People

type PeopleItemProps = {
	person: UserResponse
	user: UserResponse
	arePeopleLoading: boolean
	onEditClick: (person: UserResponse) => void
	amPortalAdmin: boolean
}

const PeopleItem = ({
	person,
	onEditClick,
	user,
	arePeopleLoading,
	amPortalAdmin,
}: PeopleItemProps) => {
	const isPersonAdmin = Boolean(person.groups?.includes("portal_admin"))

	const isEditShown = !(isPersonAdmin && !amPortalAdmin)

	return (
		<div className="person">
			<div className="data">
				<div className="primary">
					<span className="name">{formatUser(person)}</span>
					<div className="role">
						{person.email === user.email ? "/ You" : getUserRole(person)} /{" "}
						{person.email}
					</div>
				</div>
				<div className="secondary">
					<div className="email">{getDepartmentString(person.departments)}</div>
					{person.last_login && (
						<div className="last-login">
							Last signin: {dayjs(person.last_login).format("ddd, D MMM, YYYY")}
							.
						</div>
					)}
					<LicenseIndicator license={person.license} />
				</div>
			</div>
			{isEditShown && (
				<Button
					onClick={(e) => onEditClick(person)}
					isDisabled={arePeopleLoading}
					variant="secondary"
				>
					Edit
				</Button>
			)}
		</div>
	)
}
