import React, { MouseEvent, useEffect, useRef, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"
import FilterModal from "../../modals/mobile/FilterModal"
import { FilterSpecialValues } from "../Filter/types"
import { BuildingPicker } from "./BuildingPicker"
import { DepartmentPicker } from "./DepartmentPicker"
import FilterButton from "./Filter/FilterButton"
import { Filter, OccupancyFilterValues } from "./Filter/FilterSelector"
import { FloorPicker } from "./FloorPicker"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useFetchDepartmentsQuery } from "../../redux/api/departments"
import { DepartmentResponse } from "../../redux/api/departments/types"
import { useLazyFetchFloorsQuery } from "../../redux/api/floors"
import { useAppSelector } from "../../redux/reducers"
import { selectDefaultUserBuilding } from "../../redux/user/selectors"

import { ReactComponent as TriDownSVG } from "../../assets/images/icons/Tri down.svg"

import "./TopSelectors.sass"

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

type TopSelectorsProps = {
	day: string
	dayChange: (v: string) => void
	buildingFilter: string
	buildingFilterChange: (v: string) => void
	floorFilter: string
	floorFilterChange: (v: string) => void
	departmentFilter?: string
	departmentFilterChange?: (v: string) => void
	showAll?: boolean
	showDepartments?: boolean
	showFilter?: boolean
	filter?: Filter
	onFilterChange?: (filter: Filter) => void
}
const FILTER_MODAL_ID = "FilterModal"

export const TopSelectors: React.FC<TopSelectorsProps> = ({
	day,
	dayChange,
	buildingFilter,
	buildingFilterChange,
	floorFilter,
	floorFilterChange,
	departmentFilter,
	departmentFilterChange,
	showAll = false,
	showDepartments = false,
	showFilter = false,
	filter,
	onFilterChange,
}) => {
	const { t } = useTranslation()

	const buildingFilterRef = useRef<string>(buildingFilter)
	const floorFilterRef = useRef<string>(floorFilter)
	const departmentFilterRef = useRef<string>(departmentFilter || "")
	const isDefaultFilterSet = useRef<boolean>(false)

	const [buildingPicker, setBuildingPicker] = useState(false)
	const [floorPicker, setFloorPicker] = useState(false)
	const [departmentPicker, setDepartmentPicker] = useState(false)

	const [building, setBuilding] = useState<any>(null)
	const [location, setLocation] = useState<string | null>(null)

	const [department, setDepartment] = useState<string | null>(null)

	const { data: { results: buildings = [] } = {}, isSuccess } =
		useFetchBuildingsQuery()

	const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

	const [fetchFloors] = useLazyFetchFloorsQuery()

	const { data: { results: departments = [] } = {} } =
		useFetchDepartmentsQuery()

	const { openModal } = useModals()

	const dayStr = dayjs(day).isToday()
		? t("mobile.general.today")
		: dayjs(day).isTomorrow()
		? t("mobile.general.tomorrow")
		: dayjs(day).format("ddd DD MMM")

	const desk_reservation_window_length = useCheckReservationWindowLength()

	useEffect(() => {
		const init = async () => {
			let initBuildingFilter = buildingFilterRef.current
			let initFloorFilter = floorFilterRef.current
			let initDepartmentFilter = departmentFilterRef.current
			let locationName: string | undefined = undefined

			if (buildings.length > 0) {
				const initBuilding = buildings.find((b) => b.id === initBuildingFilter)

				if (!initBuilding) {
					if (showAll) {
						initBuildingFilter = FilterSpecialValues.ALL
						initFloorFilter = FilterSpecialValues.ALL
					} else {
						const location = defaultUserBuilding
							? buildings.find(
									(building) => building.id === defaultUserBuilding?.id,
							  )
							: buildings[0]

						const floorResponse = await fetchFloors({
							building: location?.id ?? null,
						})
						if (!isRejected(floorResponse)) {
							//select first floor with desks and image
							const floor = floorResponse.data?.results?.find(
								(f) =>
									f.building_id === location?.id &&
									(f?.desks_count ?? 0) > 0 &&
									!!f.image,
							)
							initBuildingFilter = location?.id ?? ""
							initFloorFilter = floor?.id ?? ""
							locationName = floor?.name
						}
					}
				} else {
					locationName = initBuilding.name

					const floorResponse = await fetchFloors({
						building: initBuilding.id,
					})
					if (!isRejected(floorResponse)) {
						const initFloor = floorResponse.data?.results?.find(
							(f) => f.id === initFloorFilter,
						)

						if (!initFloor) {
							if (showAll) {
								initFloorFilter = FilterSpecialValues.ALL
							} else {
								//select first floor with desks and image
								const usedFloor = floorResponse.data?.results?.find(
									(f) => (f?.desks_count ?? 0) > 0 && f.image,
								)
								initFloorFilter = usedFloor?.id ?? ""
								locationName = usedFloor?.name
							}
						} else {
							locationName = initFloor.name
						}
					}
				}

				setLocation(locationName ?? "")
				floorFilterChange(initFloorFilter)
				buildingFilterChange(initBuildingFilter)

				if (departmentFilterChange) {
					const initDepartment: DepartmentResponse | undefined =
						departments.find(
							(b: DepartmentResponse) => b.id === initDepartmentFilter,
						)

					if (!initDepartment) {
						departmentFilterChange(FilterSpecialValues.ALL)
					} else {
						setDepartment(initDepartment.name)
					}
				}
			}
		}
		if (isSuccess && isDefaultFilterSet.current === false) {
			init()
			isDefaultFilterSet.current = true
		}
	}, [
		buildingFilterChange,
		floorFilterChange,
		departmentFilterChange,
		departments,
		showAll,
		isSuccess,
		buildings,
		fetchFloors,
		defaultUserBuilding,
	])

	const pickBuilding = () => {
		setBuildingPicker(true)
	}

	const changeBuilding = async (building: any | null) => {
		setBuilding(building)

		if (building !== null) {
			await fetchFloors(building.id)
			setFloorPicker(true)
			setBuildingPicker(false)

			buildingFilterRef.current = building.id
			buildingFilterChange(building.id)
			if (showAll) {
				setLocation(building.name)
				floorFilterChange(FilterSpecialValues.ALL)
			}
		} else {
			if (showAll) {
				setLocation(null)
				buildingFilterChange(FilterSpecialValues.ALL)
				floorFilterChange(FilterSpecialValues.ALL)
			}
		}
	}

	const changeFloor = (floor: any | null) => {
		setFloorPicker(false)

		if (floor !== null) {
			setLocation(floor.name)

			floorFilterRef.current = floor.id
			floorFilterChange(floor.id)
		} else {
			if (showAll) {
				floorFilterChange(FilterSpecialValues.ALL)
			}
		}
	}

	const changeDepartment = (department: DepartmentResponse | null) => {
		setDepartmentPicker(false)

		if (department !== null) {
			setDepartment(department.name)

			departmentFilterRef.current = department.id
			departmentFilterChange && departmentFilterChange(department.id)
		} else {
			if (showAll) {
				setDepartment(null)

				departmentFilterChange &&
					departmentFilterChange(FilterSpecialValues.ALL)
			}
		}
	}

	const changeDate = (event: React.ChangeEvent<{ value: string }>) => {
		const newDay = dayjs(event.target.value)
		if (newDay.isValid()) {
			dayChange(newDay.toISOString())
		} else {
			dayChange(dayjs().startOf("day").toISOString())
		}
	}

	const handleFilterModal = (e: MouseEvent) => {
		e.preventDefault()

		openModal(FilterModal, {
			onChange: onFilterChange ? onFilterChange : () => undefined,
			modalId: FILTER_MODAL_ID,
			filter: filter ?? {
				occupancy: OccupancyFilterValues.ALL,
				department: FilterSpecialValues.ALL,
				amenities: [FilterSpecialValues.ALL],
			},
		})
	}

	return (
		<div className={`TopSelectors showDepartments-${showDepartments}`}>
			<div className="location" onClick={pickBuilding}>
				<div>{location || t("mobile.general.all_buildings")}</div>
				<TriDownSVG />
			</div>

			{showDepartments && (
				<div className="department" onClick={() => setDepartmentPicker(true)}>
					<div>{department || t("mobile.general.all_departments")}</div>
					<TriDownSVG />
				</div>
			)}

			{showFilter && (
				<FilterButton modalId={FILTER_MODAL_ID} onClick={handleFilterModal} />
			)}

			<div className="date">
				<input
					type="date"
					className="date-input"
					min={dayjs().format("YYYY-MM-DD")}
					max={dayjs()
						.add(desk_reservation_window_length ?? 0, "day")
						.format("YYYY-MM-DD")}
					onChange={changeDate}
				/>
				<div>{dayStr}</div>
				<TriDownSVG />
			</div>
			{(buildingPicker || (floorPicker && building)) && (
				<div className="selector">
					{buildingPicker && (
						<BuildingPicker
							onPick={changeBuilding}
							type="desk"
							showAll={showAll}
						/>
					)}
					{floorPicker && building && (
						<FloorPicker
							building={building}
							onPick={changeFloor}
							showAll={showAll}
						/>
					)}
				</div>
			)}
			{departmentPicker && (
				<div className="selector">
					<DepartmentPicker onPick={changeDepartment} showAll={showAll} />
				</div>
			)}
		</div>
	)
}
