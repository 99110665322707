import classNames from "classnames"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"

import { prettifyUrlValue } from "../utils"
import Heading, { HeadingSpacer } from "./Heading"

import "./Breadcrumbs.sass"

type Props = {
	className?: any
	depth?: number
	values?: string[]
	includeParamsAsPath?: boolean
}

/**
 * Breadcrumb component that uses useLocation and useParams to properly structure
 * breadcrumbs with proper urls and it's values.
 * The depth value should also be the values length value
 *
 * !IMPORTANT! duplicate url locations doesn't work for now. (settings/visitors/settings won't work, but settings/visitors/settings1 will)
 *
 * @param {number} depth -  Level of depth starting from the end (number of breadcrumbs to display)
 * @param {string[]} values - Text that should be displayed for each corresponding breadcrumb link
 * otherwise check translation at desktop.settings.breadcrumbs.{value}
 * @param {boolean} includeParamsAsPath - Boolean flag in case we render something else by url parameters
 * and we want to include parameters as part of path.
 *
 * Example: /settings/visitors/devices/ and /settings/visitors/devices/<device_id>
 */

const Breadcrumbs = ({
	className,
	depth = 0,
	values,
	includeParamsAsPath = false,
}: Props) => {
	const path = useLocation()
	const { t } = useTranslation()
	const params = useParams()

	let breadcrumbs = path.pathname.split("/").filter((segment) => segment !== "")

	if (!depth && values?.length) {
		depth = values.length ?? 0
	}

	if (params && !includeParamsAsPath) {
		Object.values(params)
			.filter((value) => value)
			.forEach((param) =>
				breadcrumbs.splice(breadcrumbs.indexOf(param as string), 1),
			)
	}

	if (breadcrumbs.length && depth) {
		breadcrumbs = breadcrumbs.reverse().splice(0, depth).reverse()
	}

	return (
		<div className={classNames("Breadcrumbs", className)}>
			<Heading>
				{breadcrumbs.map((segment, index, array) => {
					// Either remove or keep params as part of url path
					const location = !includeParamsAsPath
						? (Object.values(params).reduce(
								(acc: string, value: any) => acc.split(value).join(""),
								path.pathname,
						  ) as string)
						: path.pathname

					const url = location
						.split(array[index + 1])[0]
						.replace(/\/$/, "") /* Strip trailing slash */

					return (
						<span key={index}>
							{index !== array.length - 1 ? (
								<>
									<Link to={url}>
										{values?.length
											? values?.[index]
											: t([
													`desktop.settings.breadcrumbs.${segment}`,
													prettifyUrlValue(segment),
											  ] as ParseKeys[])}
									</Link>
									<HeadingSpacer />
								</>
							) : (
								<>
									{values?.length
										? values?.[index]
										: t([
												`desktop.settings.breadcrumbs.${segment}`,
												prettifyUrlValue(segment),
										  ] as ParseKeys[])}
								</>
							)}
						</span>
					)
				})}
			</Heading>
		</div>
	)
}

export default Breadcrumbs
