import { PropsWithChildren, createContext, useEffect, useState } from "react"

import { Error } from "../../screens/Visitors/Error"
import { isTablet } from "../../utils"

import { appError } from "../../redux/app/appSlice"
import { selectError } from "../../redux/app/selectors"
import { useAppSelector } from "../../redux/reducers"
import { clearTablet } from "../../redux/tablet/tabletSlice"
import { useActions } from "../../redux/utils"

export type ErrorContextType = {
	setError: (error?: string) => void
	stateError?: string
}

export const ErrorContext = createContext<ErrorContextType>({
	setError: (error?: string) => {},
	stateError: undefined,
})

export const ErrorHandlerProvider = ({
	children,
}: PropsWithChildren<unknown>) => {
	const [stateError, setError] = useState<string | undefined>(undefined)
	const error = useAppSelector(selectError)

	const actions = useActions({
		appError: (message: string | null) => appError(message),
		clearTablet: () => clearTablet(),
	})

	const { access_token } = useAppSelector((state) => state.auth)

	/* JOAN-6480
	To avoid showing an error on a tablet in regards to authentication,
	we added a checker that clears errors and starts the tablet off with a clean slate so the user does not have to do it themselves.
	Once a token expires, the tablet will clear and require pairing again. 
	*/
	useEffect(() => {
		if (
			isTablet() &&
			!access_token &&
			error &&
			error.toLowerCase().includes("authentication")
		) {
			actions.appError(null)
			actions.clearTablet()
		}
	}, [access_token, actions, error])

	return (
		<ErrorContext.Provider value={{ setError, stateError }}>
			{stateError ? (
				<Error error={stateError} />
			) : error ? (
				<Error error={error} />
			) : (
				<>{children}</>
			)}
		</ErrorContext.Provider>
	)
}
