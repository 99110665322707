import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import {
	getSubscriptionIcon,
	toFriendlyName,
	toFriendlyTitle,
} from "../../../screens/Settings/Billing/utils"
import Button from "../../basic/Button"
import Card from "../../basic/Card"

import { ACTIVE_LICENSE_STATUSES } from "../../../redux/api/billing/constants"
import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanType } from "../../../redux/api/billing/types"

import "./styles.sass"

export type SubscriptionCardProps = {
	id?: string
	subscriptions: Subscription[]
	onButtonClick?: (params: { id?: number; planType: PlanType }) => void
	onLicenseClick?: (subscription: Subscription) => void
}

/**
 * SubscriptionCard
 */
const SubscriptionCard = ({
	id,
	subscriptions,
	onButtonClick,
	onLicenseClick,
}: SubscriptionCardProps) => {
	const { plan_type } = subscriptions[0] ?? {}

	return (
		<Card
			className="SubscriptionCard"
			header={<CardHeader planType={plan_type} id={id} />}
		>
			<div className="SubscriptionCard__grid">
				{subscriptions.map((subscription) => {
					return (
						<SubscriptionDetails
							key={subscription.id}
							subscription={subscription}
							onLicenseClick={onLicenseClick}
							onButtonClick={onButtonClick}
						/>
					)
				})}
			</div>
			<div className="plan-icon">
				{getSubscriptionIcon(plan_type)({ className: "plan-icon" })}
			</div>
		</Card>
	)
}

/**
 * CardHeader
 */
const CardHeader = ({ planType, id }: { planType: PlanType; id?: string }) => (
	<div className="subscription-header" id={id}>
		{toFriendlyTitle(planType)}
	</div>
)

/**
 * SubscriptionDetails
 */
const SubscriptionDetails = ({
	subscription,
	onLicenseClick,
	onButtonClick,
}: {
	subscription: Subscription
	onLicenseClick?: (subscription: Subscription) => void
	onButtonClick?: (params: { id?: number; planType: PlanType }) => void
}) => {
	const {
		unit,
		amount,
		plan_variation,
		next_billing_date,
		trial_end,
		status,
		prepaid_code,
		plan_type,
	} = subscription
	const { t } = useTranslation()
	const currency = useCurrencyFormatter(plan_variation.currency)

	const isActive = ACTIVE_LICENSE_STATUSES.includes(status)
	const isTrial = status === "in_trial"

	const handleOnClick = () => {
		onButtonClick?.({
			planType: plan_type,
			id: subscription.subscription_id,
		})
	}

	const getDates = () => {
		if (status === "in_trial") {
			return (
				<div className="small-details">
					{t("desktop.components.subscription_card.trial_ends")}:{" "}
					<strong>{dayjs(trial_end).format("L")}</strong>
				</div>
			)
		}
		if (status === "active") {
			return (
				<div className="small-details">
					{t("desktop.components.subscription_card.next_billing_date")}:{" "}
					<strong>{dayjs(next_billing_date).format("L")}</strong>
				</div>
			)
		}
		if (status === "non_renewing") {
			const startDate = prepaid_code?.activated_at
				? dayjs(prepaid_code?.activated_at)
				: dayjs(next_billing_date).subtract(
						1,
						plan_variation.period_unit === "month" ? "month" : "year",
				  )

			return (
				<div className="small-details">
					{t("desktop.components.subscription_card.validity")}:{" "}
					<strong>
						{startDate.format("L")} - {dayjs(next_billing_date).format("L")}
					</strong>
				</div>
			)
		}
	}

	const getType = () => {
		if (status === "in_trial")
			return t("desktop.components.subscription_card.types.trial")
		if (status === "active")
			return t("desktop.components.subscription_card.types.auto_renewing")
		if (status === "non_renewing")
			return t("desktop.components.subscription_card.types.non_renewing")
	}

	return (
		<div className="subscription-details">
			<div className="plan-info">
				<div>
					<div className="plan-info-title">
						{isActive
							? toFriendlyName(plan_type)
							: t("desktop.components.subscription_card.inactive")}
					</div>
					<div className="plan-info-desc">
						{isActive
							? isTrial
								? t("desktop.components.subscription_card.free_trial")
								: t("desktop.components.subscription_card.subscription")
							: t("desktop.components.subscription_card.no_plan_selected")}
					</div>
				</div>
			</div>
			<div className="licenses">
				<Button variant="link" onClick={() => onLicenseClick?.(subscription)}>
					{t("desktop.components.subscription_card.licenses", {
						count: unit[0].quantity,
						unit: unit[0].type,
					})}
				</Button>
			</div>
			<div className="active">
				{t("desktop.components.subscription_card.active_units", {
					count: unit[0].quantity_used,
					unit: unit[0].type,
				})}
			</div>
			{getDates()}
			<div className="small-details">
				{t("desktop.components.subscription_card.type")}:{" "}
				<strong>{getType()}</strong>
			</div>
			<div className="small-details">
				{t("desktop.components.subscription_card.cost")}:{" "}
				<strong>
					{currency.format(amount ?? 0)} / {plan_variation.period_unit}
				</strong>
			</div>
			{prepaid_code && (
				<div className="small-details">
					{t("desktop.components.subscription_card.prepaid_code")}:{" "}
					<strong>{prepaid_code.code}</strong>
				</div>
			)}
			{status === "active" && (
				<Button
					variant="secondary"
					onClick={handleOnClick}
					className="subscription-details-modify"
				>
					{t("desktop.components.subscription_card.actions.modify_plan")}
				</Button>
			)}
		</div>
	)
}

export default SubscriptionCard
