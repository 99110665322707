import {
	MouseEvent,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"

import classNames from "classnames"

import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { Checkbox } from "../Checkbox"
import { ConfirmationModal } from "../ConfirmationModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { ReactComponent as MoreHorizontalSVG } from "../../../assets/images/icons/More horizontal.svg"

import "./styles.sass"

export type HasIdOrKey = { id: string; key: string }

export type RowDataWithId<T> = T & { id: string }
export type RowDataWithKey<T> = T & { key: string }

type ModifyRow<T> = RowDataWithId<T> | RowDataWithKey<T>

export const DEFAULT_EMPTY_TABLE_CELL = <span className="no-data-cell">—</span>

export type Action<RowData> = {
	label:
		| string
		| JSX.Element
		| ((d: ModifyRow<RowData>) => string | JSX.Element)
	onClick: (d: ModifyRow<RowData>) => void
	requiresConfirmation?: boolean
}

type RowActionProps<RowData> = {
	row: ModifyRow<RowData>
	actions: Action<ModifyRow<RowData>>[]
}

function RowAction<RowData>({ row, actions }: RowActionProps<RowData>) {
	const { openModal, closeModal } = useModals()
	const ref = useRef<HTMLDivElement>(null)

	const [showDropdown, setShowDropdown] = useState(false)

	const closeDropdown = useCallback(() => {
		setShowDropdown(false)
	}, [])

	useOnClickOutside([ref], closeDropdown)

	const toggleDropdown = useCallback((e: MouseEvent) => {
		e.stopPropagation()
		setShowDropdown((p) => !p)
	}, [])

	const handleConfirmation = (
		row: ModifyRow<RowData>,
		onClick: (d: ModifyRow<RowData>) => void,
	) => {
		openModal(ConfirmationModal, {
			onConfirm: () => {
				onClick(row)
				closeModal()
			},
			onCancel: closeModal,
		})
	}

	return (
		<div ref={ref} onClick={toggleDropdown} className="RowAction">
			<MoreHorizontalSVG />
			{showDropdown && (
				<div className="dropdown">
					{actions.map(({ label, onClick, requiresConfirmation }, index) => (
						<div
							className="action"
							key={index}
							onClick={() =>
								requiresConfirmation
									? handleConfirmation(row, onClick)
									: onClick(row)
							}
						>
							{typeof label === "function" ? label(row) : label}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export type Column<RowData> = {
	field: Extract<keyof ModifyRow<RowData>, string>
	label: string | JSX.Element | (() => string | JSX.Element)
	headClassName?: string
	cellClassName?: string
	renderCell?: (d: ModifyRow<RowData>) => string | JSX.Element
}

export type TableProps<RowData> = {
	className?: string
	rows: ModifyRow<RowData>[]
	columns: Column<ModifyRow<RowData>>[]
	emptyTableCell?: string | JSX.Element
	footerCells?: (string | JSX.Element)[]
	rowActions?: Action<ModifyRow<RowData>>[]
	loading?: boolean
	showRowNumber?: boolean
	calcRowNumber?: (index: number) => number
	isSelectable?: boolean
	onSelectedRows?: (r: ModifyRow<RowData>[]) => void
	onRowClick?: (r: ModifyRow<RowData>) => void
	pagination?: ReactNode
	notFoundText?: string
}

function Table<RowData>({
	className,
	rows,
	columns,
	footerCells,
	emptyTableCell,
	rowActions,
	loading = false,
	showRowNumber = false,
	calcRowNumber,
	isSelectable = false,
	onSelectedRows,
	onRowClick,
	pagination,
	notFoundText = "Not found",
}: TableProps<RowData>) {
	const tableClassNames = classNames("Table", className, {
		loading: !!loading,
		isRowClickable: !!onRowClick,
	})

	const [selectedRows, setSelectedRows] = useState<ModifyRow<RowData>[]>([])

	useEffect(() => {
		if (onSelectedRows) {
			onSelectedRows(selectedRows)
		}
	}, [selectedRows, onSelectedRows])

	useEffect(() => {
		setSelectedRows([])
	}, [rows])

	// Returns a function that handles the selection of a row
	const handleRowCheck = (row: ModifyRow<RowData>) => {
		if (!isCheckboxChecked(row)) {
			setSelectedRows((r) => [...r, row])
			return
		}
		setSelectedRows((r) =>
			r.filter(
				(r) =>
					(r as RowDataWithId<RowData>).id !==
						(row as RowDataWithId<RowData>).id ||
					(r as RowDataWithKey<RowData>).key !==
						(row as RowDataWithKey<RowData>).key,
			),
		)
	}

	const isAllCheckboxChecked =
		selectedRows.length > 0 && rows.length === selectedRows.length

	const handleCheckAll = () => {
		if (isAllCheckboxChecked) {
			setSelectedRows([])
			return
		}
		setSelectedRows(rows)
	}

	const isCheckboxChecked = (row: RowData) =>
		selectedRows.find(
			(r) =>
				(r as RowDataWithId<RowData>).id === (row as RowDataWithId<RowData>).id,
		) !== undefined

	const collSpanNo = useMemo(() => {
		let number = columns.length
		if (isSelectable) {
			number++
		}
		if (showRowNumber) {
			number++
		}
		if (rowActions) {
			number++
		}
		return number
	}, [columns, isSelectable, showRowNumber, rowActions])

	const rowClassNames = classNames({ "clickable-row": !!onRowClick })

	return (
		<table className={tableClassNames}>
			<thead>
				<tr>
					{isSelectable && (
						<th className="checkbox-cell">
							<Checkbox
								value={isAllCheckboxChecked}
								onChange={handleCheckAll}
							/>
						</th>
					)}
					{showRowNumber && <th className="number-cell" />}
					{columns.map(({ field, label, headClassName }) => (
						<th key={field} className={headClassName}>
							{typeof label === "function" ? label() : label}
						</th>
					))}
					{rowActions && <th className="action-cell" />}
				</tr>
			</thead>
			<tbody>
				{!loading && rows.length === 0 ? (
					<tr>
						<td colSpan={collSpanNo} className="empty-cell">
							{emptyTableCell ? (
								emptyTableCell
							) : (
								<div className="empty-div">{notFoundText}</div>
							)}
						</td>
					</tr>
				) : (
					rows.map((row, i) => (
						<tr
							key={
								(row as RowDataWithId<RowData>).id ||
								(row as RowDataWithKey<RowData>).key
							}
							className={rowClassNames}
							onClick={() => onRowClick?.(row)}
						>
							{isSelectable && (
								<td
									className="checkbox-cell"
									onClick={(e: MouseEvent) => {
										e.stopPropagation()
										handleRowCheck(row)
									}}
								>
									<Checkbox value={isCheckboxChecked(row)} />
								</td>
							)}
							{showRowNumber && (
								<td className="number-cell">{calcRowNumber?.(i) ?? i + 1}</td>
							)}
							{columns.map(({ field, cellClassName, renderCell }, j) => {
								return (
									<td key={`${i}-${field}`} className={cellClassName}>
										{renderCell ? (
											renderCell(row)
										) : typeof row[field] === "string" ? (
											<>{row[field]}</>
										) : (
											JSON.stringify(row[field])
										)}
									</td>
								)
							})}
							{rowActions && (
								<td
									className="action-cell"
									onClick={(e: MouseEvent) => e.stopPropagation()}
								>
									<RowAction row={row} actions={rowActions} />
								</td>
							)}
						</tr>
					))
				)}
			</tbody>
			{rows.length > 0 && (footerCells || pagination) && (
				<tfoot>
					{footerCells && (
						<tr>
							{isSelectable && <td className="checkbox-cell" />}
							{showRowNumber && <td className="number-cell" />}
							{footerCells.map((cell, i) => (
								<td key={`foot-${i}`}>{cell}</td>
							))}
							{rowActions && <td className="action-cell" />}
						</tr>
					)}
					{pagination && (
						<tr className="pagination-row">
							<td colSpan={100}>{pagination}</td>
						</tr>
					)}
				</tfoot>
			)}
		</table>
	)
}

export default Table
