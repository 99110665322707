import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Screen.sass"

type Props = {
	sidebar?: JSX.Element
	className?: any
}

export default function Screen({
	children,
	sidebar,
	className,
}: PropsWithChildren<Props>) {
	const screenClassName = classNames({
		Screen: true,
		[className]: !!className,
	})
	return (
		<div className={screenClassName}>
			{sidebar && <div className="sidebar">{sidebar}</div>}
			<div className="content">{children}</div>
		</div>
	)
}
