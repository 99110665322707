import React, { PropsWithChildren, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { Redirect, useLocation } from "react-router-dom"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectIsAuth } from "../../redux/selectors"
import { selectTablet } from "../../redux/tablet/selectors"
import { fetchPrinter, fetchTablet } from "../../redux/tablet/tabletSlice"
import { useActions } from "../../redux/utils"

import Loader from "../../components/basic/Loader"

const TabletDataProvider = ({ children }: PropsWithChildren<unknown>) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()

	const isLocationPath =
		pathname.indexOf("/visitors/onboarding/assign_location") > -1

	const isAuth = useAppSelector(selectIsAuth)
	const { id, buildingId, isLoading, isLoaded } = useAppSelector(selectTablet)

	const actions = useActions({
		appError: (message: string) => appError(message),
		fetchTablet: (id: string) => fetchTablet(id),
		fetchPrinter: (id: string) => fetchPrinter(id),
	})

	useEffect(() => {
		if (isAuth && id) {
			actions.fetchTablet(id).then((response) => {
				if (fetchTablet.fulfilled.match(response)) {
					if (response.payload.connected_device) {
						actions
							.fetchPrinter(response.payload.connected_device.id)
							.then((printerResponse) => {
								if (fetchPrinter.rejected.match(printerResponse)) {
									actions.appError(
										printerResponse.error.message ?? t("tablet.general_error"),
									)
								}
							})
					}
				} else {
					if (response.error.message) {
						actions.appError(response.error.message)
					}
				}
			})
		}
	}, [t, actions, isAuth, id])

	if (isAuth && id) {
		if ((!isLoaded || isLoading) && !isLocationPath) {
			return <Loader size="large" />
		}

		if (!buildingId && !isLocationPath) {
			return <Redirect to={"/visitors/onboarding/assign_location"} />
		}
	}

	return <>{children}</>
}

export default TabletDataProvider
