import { PropsWithChildren } from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../modals/modalStyles"
import Button, { ButtonProps } from "./basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import { ReactComponent as CrossSVG } from "../assets/images/icons/Cross.svg"

import "./ConfirmButton.sass"

type ModalProps = {
	open: boolean
} & ConfirmButtonProps

const Modal = ({ open, cardTitle, cardHint, onConfirm }: ModalProps) => {
	const { closeModal } = useModals()

	const onClick = async () => {
		await onConfirm()
		closeModal()
	}

	if (!open) {
		return null
	}

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<div className="ModalForm isConstrained ConfirmForm">
				<div>
					<h1>{cardTitle ? cardTitle : "Please confirm your action"}</h1>
					<div className="close" onClick={() => closeModal()}>
						<CrossSVG />
					</div>
				</div>

				{cardHint && <div className="hint">{cardHint}</div>}

				<div className="actions actions-edit">
					<Button variant="secondary" onClick={closeModal}>
						Cancel
					</Button>
					<Button
						className="delete"
						variant="danger-pop"
						noConfirm
						onClick={onClick}
					>
						Confirm
					</Button>
				</div>
			</div>
		</ReactModal>
	)
}

type ConfirmButtonProps = {
	cardTitle?: React.ReactNode | string
	cardHint?: React.ReactNode | string
	onConfirm: () => void
	className?: string
} & ButtonProps

export const ConfirmButton = ({
	cardTitle,
	cardHint,
	onConfirm,
	children,
	...props
}: PropsWithChildren<ConfirmButtonProps>) => {
	const { openModal } = useModals()

	const onClick = () => {
		openModal(Modal, {
			cardTitle,
			cardHint,
			onConfirm,
		})
	}

	return (
		<Button {...props} onClick={onClick}>
			{children}
		</Button>
	)
}
