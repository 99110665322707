import { useTranslation } from "react-i18next"

import BasicTable, { TableProps } from "./../../basic/Table"

function Table<RowData>(props: TableProps<RowData>) {
	const { t } = useTranslation()

	return (
		<BasicTable {...props} notFoundText={t("general.not_found.standard")} />
	)
}

export default Table
