import React, { useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { LabelPaths } from "../../../../constants"
import { useToast } from "../../../../hooks/useToast"
import { KeyAndName, OptionType } from "../../../../types/sharedTypes"
import { getLabel } from "../../../../utils"
import LicensesInfoList from "../../LicensesInfoList"
import { ROOMS_PATHS } from "../constants"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"

import {
	useFetchRoomsDevicesQuery,
	useRepairRoomDeviceMutation,
	useUnpairRoomDeviceMutation,
} from "../../../../redux/api/devices"
import { RoomDeviceResponse } from "../../../../redux/api/devices/types"
import { isApiResponseError } from "../../../../redux/api/types"
import { getAppParams } from "../../../../redux/appParams/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import DeviceStatusFilter, {
	BATTERY_UNDER_25,
	BATTERY_UNDER_50,
	BATTERY_UNDER_75,
	DISCONNECTED,
	DeviceStatusFilterValues,
	STRONG_WIFI,
	WEAK_WIFI,
	filterDevicesByBatteryLvl,
	filterDevicesByWifiLvlConnection,
} from "../../../../components/Filter/DeviceStatusesFilter"
import DeviceTypeFilter from "../../../../components/Filter/DeviceTypesFilter"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import Filters from "../../../../components/Filter/Filters"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import RoomDeviceStatus from "../../../../components/Visitors/RoomDeviceStatus"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Table from "../../../../components/advanced/Table"
import Button from "../../../../components/basic/Button"
import MultiActionButton from "../../../../components/basic/MultiActionButton"
import { Column } from "../../../../components/basic/Table"
import { Tooltip } from "../../../../components/basic/Tooltip"

import { ReactComponent as DeviceSVG } from "../../../../assets/images/icons/Device.svg"

import "./styles.sass"

const DevicesEmptyTableRows = () => {
	const { t } = useTranslation()

	return (
		<div className="DevicesTableNoView">
			<div className="DevicesTableNoViewTop">
				<DeviceSVG />
				<p>{t("desktop.settings.rooms.devices.no_devices.title")}</p>
			</div>
			<div className="DevicesTableNoViewBottom">
				{t("desktop.settings.rooms.devices.no_devices.description")}
				<Button
					className="DevicesTableNoViewBottomButton"
					variant="link"
					to="/settings/rooms/devices/pair"
				>
					{t("desktop.settings.rooms.devices.no_devices.description_link")}
				</Button>
			</div>
		</div>
	)
}
enum RoomDeviceBulkAction {
	RE_PAIR = "RE_PAIR",
	UN_PAIR = "UN_PAIR",
}

const Devices = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const { openModal, closeModal } = useModals()
	const { errorToast, infoToast } = useToast()

	const { appConfiguratorURL } = useAppSelector(getAppParams)

	const { search } = useLocation()

	const [repairRoomDevice] = useRepairRoomDeviceMutation()
	const [unpairRoomDevice] = useUnpairRoomDeviceMutation()

	const query = useMemo(() => new URLSearchParams(search), [search])

	// Filters
	const [buildingFilter, setBuildingFilter] = useState<string>(
		query.get("building") || FilterSpecialValues.ALL,
	)

	const [floorFilter, setFloorFilter] = useState<string>(
		query.get("floor") || FilterSpecialValues.ALL,
	)

	const [deviceStatusFilter, setDeviceStatusFilter] = useState<string[]>([])

	const [deviceTypeFilter, setDeviceTypeFilter] = useState<string>(
		FilterSpecialValues.ALL,
	)

	const [searchFilter, setSearchFilter] = useState<string>("")

	const [selectedRows, setSelectedRows] = useState<RoomDeviceResponse[]>([])

	const selectedIdsDevices = useMemo(
		() => selectedRows.map((sd) => sd.id),
		[selectedRows],
	)

	const { data: { results: roomsDevices = [] } = {}, isFetching: isLoading } =
		useFetchRoomsDevicesQuery({
			building: buildingFilter,
			floor: floorFilter,
		})

	const handleSelectRow = (d: RoomDeviceResponse[]) => setSelectedRows(d)

	const bulkActionOptions = useMemo<OptionType<RoomDeviceBulkAction>[]>(
		() =>
			Object.values(RoomDeviceBulkAction).map((action) => ({
				label: t(
					`desktop.settings.rooms.devices.actions.${action.toLocaleLowerCase()}` as ParseKeys,
				),
				value: action,
			})),
		[t],
	)

	const filteredRoomsDevices = useMemo(() => {
		if (!buildingFilter && !floorFilter && !searchFilter && !deviceStatusFilter)
			return roomsDevices

		let filteredDevices = roomsDevices

		if (searchFilter) {
			filteredDevices = filteredDevices.filter(
				(device) =>
					device.name &&
					device.name.toLowerCase().includes(searchFilter.toLowerCase()),
			)
		}

		if (deviceTypeFilter !== FilterSpecialValues.ALL) {
			filteredDevices = filteredDevices.filter(
				(device) => device.type === deviceTypeFilter,
			)
		}

		if (deviceStatusFilter.includes(DeviceStatusFilterValues.NO_ROOMS)) {
			filteredDevices = filteredDevices.filter(
				(device) => device.rooms.length === 0,
			)
		}

		if (deviceStatusFilter.includes(BATTERY_UNDER_75)) {
			filteredDevices = filterDevicesByBatteryLvl(
				BATTERY_UNDER_75,
				filteredDevices,
			)
		}

		if (deviceStatusFilter.includes(BATTERY_UNDER_50)) {
			filteredDevices = filterDevicesByBatteryLvl(
				BATTERY_UNDER_50,
				filteredDevices,
			)
		}

		if (deviceStatusFilter.includes(BATTERY_UNDER_25)) {
			filteredDevices = filterDevicesByBatteryLvl(
				BATTERY_UNDER_25,
				filteredDevices,
			)
		}

		if (deviceStatusFilter.includes(STRONG_WIFI)) {
			filteredDevices = filterDevicesByWifiLvlConnection(
				STRONG_WIFI,
				filteredDevices,
			)
		}

		if (deviceStatusFilter.includes(WEAK_WIFI)) {
			filteredDevices = filterDevicesByWifiLvlConnection(
				WEAK_WIFI,
				filteredDevices,
			)
		}

		if (deviceStatusFilter.includes(DISCONNECTED)) {
			filteredDevices = filterDevicesByWifiLvlConnection(
				DISCONNECTED,
				filteredDevices,
			)
		}

		return filteredDevices
	}, [
		buildingFilter,
		floorFilter,
		searchFilter,
		roomsDevices,
		deviceStatusFilter,
		deviceTypeFilter,
	])

	const tableColumns = useMemo<Column<RoomDeviceResponse>[]>(
		() => [
			{
				field: "name",
				label: t("desktop.settings.rooms.devices.table.device_name"),
				renderCell: ({ name, type }) => {
					const deviceType = getLabel(
						`deviceTypes.${type.toLowerCase()}` as LabelPaths,
					)

					return deviceType + (name && ` - ${name}`)
				},
			},
			{
				field: "rooms",
				label: t("desktop.settings.rooms.devices.table.calendar"),
				renderCell: ({ rooms }) => roomsDevicesCell(rooms),
			},
			{
				field: "type",
				label: "Status",
				renderCell: (device) => <RoomDeviceStatus device={device} />,
			},
		],
		[t],
	)

	const roomsDevicesCell = (rooms: KeyAndName[]) => {
		if (rooms.length) {
			return (
				<>
					{rooms.map((room, index, array) => {
						const isLastElement = index === array.length - 1
						if (room.name && room.name.length > 10) {
							return (
								<Tooltip key={index} uniqueId={`${index}`} content={room.name}>
									{room.name.substring(0, 4) +
										`...${!isLastElement ? "," : ""}`}
								</Tooltip>
							)
						}

						return (
							<span>
								{room?.name ?? ""}
								{!isLastElement ? ", " : ""}
							</span>
						)
					})}
				</>
			)
		} else return "-"
	}

	const handleRepair = async () => {
		if (selectedIdsDevices.length) {
			const response = await repairRoomDevice(selectedIdsDevices)

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					errorToast(error.message)
				}
				return
			}

			setSelectedRows([])

			infoToast(
				t("desktop.settings.rooms.devices.form.toasts.device_repaired_other", {
					count: selectedIdsDevices.length,
				}),
			)
		}
	}

	const handleUnpair = async () => {
		const response = await unpairRoomDevice(selectedIdsDevices)

		if (isRejected(response)) {
			const { error } = response

			if (isApiResponseError(error)) {
				errorToast(error.message)
			}
			return
		}

		setSelectedRows([])

		infoToast(
			t("desktop.settings.rooms.devices.form.toasts.device_unpaired_other", {
				count: selectedIdsDevices.length,
			}),
		)
	}

	const handleBulkAction = async (action: RoomDeviceBulkAction) => {
		switch (action) {
			case RoomDeviceBulkAction.RE_PAIR:
				handleRepair()
				break
			case RoomDeviceBulkAction.UN_PAIR:
				handleUnpair()
				break
		}
	}

	const handleBulkActionConfirmation = (action: RoomDeviceBulkAction) => {
		if (!selectedIdsDevices.length) {
			infoToast(
				t("desktop.settings.rooms.devices.form.toasts.no_devices_selected"),
			)
			return
		}

		openModal(ConfirmationModal, {
			onConfirm: () => {
				handleBulkAction(action)
				closeModal()
			},
		})
	}

	const handleRowClick = (d: RoomDeviceResponse) => {
		history.push(`${ROOMS_PATHS.devices}/${d.id}`)
	}

	return (
		<View className="SettingsRoomsDevices">
			<Breadcrumbs depth={2} includeParamsAsPath />

			<Intro>
				<div className="description">
					{t("desktop.settings.rooms.devices.intro")}
					<br />
					<a href={appConfiguratorURL} target="_blank" rel="noreferrer">
						{t("desktop.settings.rooms.devices.download_here")}
					</a>
				</div>
				<div className="buttons">
					<Button to="/settings/rooms/devices/pair" isSmall>
						{t("desktop.settings.rooms.devices.pair_new_device")}
					</Button>
				</div>
			</Intro>

			<LicensesInfoList licenseType="user" />

			<Space size={0.75} />

			<Filters>
				<BuildingFilter
					onChange={setBuildingFilter}
					value={buildingFilter}
					showAll
				/>
				<FloorFilter
					onChange={setFloorFilter}
					value={floorFilter}
					buildingId={buildingFilter}
					showAll
				/>
				<DeviceTypeFilter
					onChange={setDeviceTypeFilter}
					value={deviceTypeFilter}
				/>
				<DeviceStatusFilter
					onChange={setDeviceStatusFilter}
					value={deviceStatusFilter}
				/>
				<SearchFilter
					placeholder={t("desktop.settings.visitors.devices.search_devices")}
					onChange={setSearchFilter}
					value={searchFilter}
				/>

				<FilterSpace />

				<MultiActionButton
					isSmall
					options={bulkActionOptions}
					onAction={handleBulkActionConfirmation}
					label={`${t("desktop.settings.rooms.devices.selected_devices")} (${
						selectedIdsDevices.length
					})`}
				/>
			</Filters>

			<Space size={0.75} />

			<Table
				isSelectable
				loading={isLoading}
				rows={filteredRoomsDevices}
				columns={tableColumns}
				onSelectedRows={handleSelectRow}
				emptyTableCell={<DevicesEmptyTableRows />}
				onRowClick={handleRowClick}
			/>
		</View>
	)
}

export default Devices
