import { useMemo } from "react"

import { useDispatch } from "react-redux"
import { ActionCreatorsMapObject, bindActionCreators } from "redux"

import { AppDispatch } from "./reducers"

import { FilterSpecialValues } from "../components/Filter/types"

type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (
	...a: Parameters<T>
) => TNewReturn

type ActionCreators<A extends ActionCreatorsMapObject> = {
	[K in keyof A]: ReplaceReturnType<A[K], ReturnType<ReturnType<A[K]>>>
}

export function useActions<A extends ActionCreatorsMapObject>(
	actions: A,
	deps?: any[],
) {
	const dispatch = useDispatch<AppDispatch>()
	return useMemo(
		() => bindActionCreators(actions, dispatch),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps ? [dispatch, ...deps] : [dispatch],
	) as ActionCreators<A>
}

export const ignoreAllFilter = (filter: string) => {
	return filter === FilterSpecialValues.ALL ? FilterSpecialValues.EMPTY : filter
}

export const readLocal = (key: string): string | null => {
	if (!window.localStorage) return null

	return window.localStorage.getItem(key)
}

export const writeLocal = (key: string, value: string): void => {
	if (!window.localStorage) return

	window.localStorage.setItem(key, value)
}
