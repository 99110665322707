import React, { PropsWithChildren } from "react"

import classNames from "classnames"
import { NavLink, useLocation } from "react-router-dom"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

import "./AuxNav.sass"

type Props = {
	className?: any
}

export default function AuxNav({
	children,
	className,
	...rest
}: PropsWithChildren<Props>) {
	const navClassName = classNames({
		AuxNav: true,
		[className]: !!className,
	})
	return (
		<div className={navClassName} {...rest}>
			{children}
		</div>
	)
}

type AuxButtonProps = {
	to?: string
	href?: string
	isDisabled?: boolean
	hasSubitems?: boolean
	activePath?: string
}

export const AuxButton = ({
	children,
	to,
	href,
	isDisabled,
	hasSubitems = false,
	activePath,
}: PropsWithChildren<AuxButtonProps>) => {
	const { pathname } = useLocation()
	const buttonClassName = classNames({
		AuxButton: true,
		isDisabled: !!isDisabled,
		hasSubitems: hasSubitems,
		active: activePath ? pathname.startsWith(activePath) : false,
	})

	if (href) {
		return (
			<a
				href={!isDisabled ? href : "/"}
				target="_blank"
				rel="noopener noreferrer"
				className={buttonClassName}
			>
				{children}
			</a>
		)
	} else {
		return (
			<NavLink to={!isDisabled ? to! : "#"} className={buttonClassName}>
				{children}
			</NavLink>
		)
	}
}

export function AuxSeparator() {
	return <div className="AuxSeparator"></div>
}

export const IOSLink = () => {
	const { appName, appStore } = useAppSelector(getAppParams)

	return (
		<a
			title={`${appName} - App Store`}
			href={appStore.appleStoreURL}
			target="_blank"
			rel="noopener noreferrer"
		>
			iOS
		</a>
	)
}

export const AndroidLink = () => {
	const { appName, appStore } = useAppSelector(getAppParams)

	return (
		<a
			title={`${appName} - Play Store`}
			href={appStore.googlePlayURL}
			target="_blank"
			rel="noopener noreferrer"
		>
			Android
		</a>
	)
}

export const MobileAppLinks = () => (
	<div className="MobileAppLinks">
		Mobile app
		<br />
		<IOSLink />
		<span> &middot; </span>
		<AndroidLink />
	</div>
)
