import cn from "classnames"

import { Children } from "../types/sharedTypes"

import { ReactComponent as AssetSVG } from "../assets/images/icons/Asset.svg"
import { ReactComponent as VisitorSVG } from "../assets/images/icons/BadgeOutline.svg"
import { ReactComponent as DeskSVG } from "../assets/images/icons/Chair.svg"
import { ReactComponent as RoomSVG } from "../assets/images/icons/Room.svg"

import "./CorrectIconMapper.sass"

type IconType = "desk" | "asset" | "visitor" | "room" | string
type IconColors = "white" | "default"

type Props = {
	iconType: IconType
	className?: string
	needsWrap?: boolean
	color?: IconColors
}

type MappedIcons = {
	[key: IconType]: Children
}

export const CorrectIconMapper = ({
	iconType,
	className,
	needsWrap = true,
	color,
}: Props) => {
	const icons: MappedIcons = {
		desk: <DeskSVG className={cn(className, color)} />,
		asset: <AssetSVG className={cn(className, color)} />,
		visitor: <VisitorSVG className={cn(className, color)} />,
		room: <RoomSVG className={cn(className, color)} />,
	}

	if (!needsWrap) {
		return icons[iconType] ?? icons["desk"]
	}

	return (
		<div className="CorrectIconMapper">{icons[iconType] ?? icons["desk"]}</div>
	)
}
