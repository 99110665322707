import { createSlice } from "@reduxjs/toolkit"

/**
 *  Slice
 */
export type AppParamsType = {
	isAppWhitelabel: boolean | null
	appName: string
	appLogo: {
		portrait: string
		landscape: string
		icon: string
		mobile: string
	}
	appStore: {
		appleStoreURL: string
		googlePlayURL: string
	}
	appKnowledgeCenter: {
		helpCenter: string
		howToConfigureDevices: string
		howToAddDevices: string
		howToInviteVisitorsFromCalendar: string
		appPerms: string
		howToConfigureDesksAndAssets: string
	}
	appConfiguratorURL: string
	appManifest: string
}

const initialState: AppParamsType = {
	isAppWhitelabel: null,
	appName: "",
	appLogo: {
		icon: "",
		portrait: "",
		landscape: "",
		mobile: "",
	},
	appStore: {
		appleStoreURL: "",
		googlePlayURL: "",
	},
	appConfiguratorURL: "",
	appKnowledgeCenter: {
		helpCenter: "",
		howToConfigureDevices: "",
		howToAddDevices: "",
		howToInviteVisitorsFromCalendar: "",
		appPerms: "",
		howToConfigureDesksAndAssets: "",
	},
	appManifest: "",
}

const appParamsSlice = createSlice({
	name: "appParams",
	initialState,
	reducers: {
		changeAppParams: (state, action: { payload: AppParamsType }) => {
			state.isAppWhitelabel = action.payload.isAppWhitelabel
			state.appName = action.payload.appName
			state.appLogo = action.payload.appLogo
			state.appStore = action.payload.appStore
			state.appConfiguratorURL = action.payload.appConfiguratorURL
			state.appKnowledgeCenter = action.payload.appKnowledgeCenter
			state.appManifest = action.payload.appManifest
		},
	},
})

export const appParamsReducer = appParamsSlice.reducer
export const { changeAppParams } = appParamsSlice.actions
