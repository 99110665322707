import { FloorResponse } from "./types"

export const floorComparator = (a: FloorResponse, b: FloorResponse) => {
	if (!a || !b || !a.name || !b.name) {
		return 0
	} else if (a.name > b.name) {
		return 1
	} else if (b.name > a.name) {
		return -1
	} else {
		return 0
	}
}

export const formatFloorLabel = (prefix: string, name: string) =>
	String(name) === String(parseInt(name)) ? `${prefix} ${name}` : name
