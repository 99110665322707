import { DepartmentResponse } from "./types"

export const getNumberOfDesksInDepartment = (
	department: DepartmentResponse | null,
) => {
	if (!department) return 0

	return (
		department.stats?.reduce((prev, current) => prev + current.desk_count, 0) ||
		0
	)
}
