import React, { useCallback, useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { resourcesURL } from "../../../../api"
import { useToast } from "../../../../hooks/useToast"
import { KeyAndName } from "../../../../types/sharedTypes"
import {
	APP_TYPES,
	MULTI_CALENDAR_DEVICE_APPS,
	ROOMS_PATHS,
} from "../constants"
import DevicePreview from "./DevicePreview"
import {
	findAppType,
	findDevice,
	isAppAvailable,
	isAppCompatible,
	isJoan6Pro,
	isJoan6RE,
	isScheduleBoardApp,
} from "./utils"

import { useUpdateRoomDeviceMutation } from "../../../../redux/api/devices"
import { RoomDeviceResponse } from "../../../../redux/api/devices/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { selectFutureFlags } from "../../../../redux/feature_flags/selectors"
import { useAppSelector } from "../../../../redux/reducers"
import { Settings } from "../../../../redux/settings/types"

import Field from "../../../../components/Field"
import PageForm from "../../../../components/Form/PageFormHook"
import { setErrors } from "../../../../components/Form/formUtils"
import AsyncSelect from "../../../../components/advanced/AsyncSelect"
import { Input } from "../../../../components/basic/Input"
import MultiToggle from "../../../../components/basic/MultiToggle"

import "./DeviceDetailForm.sass"

type FormValues = {
	name: string
	rooms: KeyAndName[]
	interface: number
	settings: Settings
}

type Props = {
	roomDevice: RoomDeviceResponse
}

const UnavailableRoomsView = () => {
	const { t } = useTranslation()

	return (
		<div className="UnavailableRoomsView">
			<div className="UnavailableRoomsViewDescription">
				{t(
					"desktop.settings.rooms.devices.no_rooms_available_edit.description",
				)}
			</div>
			<div className="UnavailableRoomsViewButton">
				<Link className="settings-action" to={ROOMS_PATHS.addRoom}>
					{t(
						"desktop.settings.rooms.devices.no_rooms_available_edit.description_link",
					)}
				</Link>
			</div>
		</div>
	)
}

const DeviceDetailForm = ({ roomDevice }: Props) => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { entries: rooms } = useAppSelector((state) => state.rooms)
	const [updateRoomDevice] = useUpdateRoomDeviceMutation()

	const {
		id,
		name = "",
		gtin,
		type,
		provider,
		interface: appInterface,
		settings,
	} = roomDevice

	const [selectedApp, setSelectedApp] = useState<string>(
		appInterface.toString(),
	)

	const { entry: featureFlags } = useAppSelector(selectFutureFlags)

	const methods = useForm<FormValues>({
		defaultValues: {
			name,
			rooms: roomDevice.rooms,
			interface: appInterface,
			settings,
		},
	})
	const {
		control,
		setError,
		formState: { isSubmitting },
	} = methods

	const roomExists = rooms.length > 0

	const isMultiCalendar = useMemo(
		() =>
			MULTI_CALENDAR_DEVICE_APPS.some(
				(app) => app.value === parseInt(selectedApp),
			),
		[selectedApp],
	)

	const currentDevice = useMemo(() => findDevice(type), [type])

	const currentApp = useMemo(
		() => findAppType(appInterface, currentDevice.size),
		[appInterface, currentDevice.size],
	)

	// Get only apps that are visible
	const visibleApps = Object.values(APP_TYPES)
		.filter((app) =>
			isAppCompatible(appInterface, app, currentDevice.size, provider),
		)
		.map((app) => {
			return {
				...app,
				isLocked: !featureFlags.some((f) => f.name === app.accessFlags),
				isUnavailable: !isAppAvailable(currentApp?.isInJoanLabs, app),
			}
		})

	// Schedule Board and Schedule Board for Classrooms
	// are selected through additional Layout field.
	// We also override their labels because when used
	// in a layout picker field, they are named differently.
	//
	// - 'Schedule Board' is named 'Default'
	// - 'Schedule Board for Classrooms' is named 'Classroom'
	//
	// See JOAN-921
	const scheduleBoardLayoutOptions = !isScheduleBoardApp(parseInt(selectedApp))
		? []
		: visibleApps
				.filter((app) => isScheduleBoardApp(app.value))
				.map((app) => {
					return {
						label:
							app.value === APP_TYPES.PREMIUM.value
								? t(
										`desktop.settings.rooms.devices.device_interfaces.${app.value}.layout.label` as ParseKeys,
								  )
								: t(
										`desktop.settings.rooms.devices.device_interfaces.${app.value}.layout.label` as ParseKeys,
								  ),
						value: app.value.toString(),
					}
				})

	const hasLayout = scheduleBoardLayoutOptions.length > 0

	// Because we artificially remove some apps in case of Schedule Board,
	// we have to modify the original list of visible apps.
	const augmentedVisibleApps = visibleApps.filter((app) => {
		// Ignore all non Schedule Board apps
		if (!isScheduleBoardApp(app.value)) return true

		// If one of Schedule board apps is selected, then remove all Schedule
		// board apps except the selected one
		if (isScheduleBoardApp(appInterface)) {
			return app.value === appInterface
		}

		// Otherwise leave just the default Schedule Board app
		return app.value === APP_TYPES.PREMIUM.value
	})

	// If we only have 1 visible interface and that interface is already selected,
	// then no need to show the selection
	const isSelectionVisible = useMemo(() => {
		return !(
			augmentedVisibleApps.length === 0 ||
			(augmentedVisibleApps.length === 1 &&
				augmentedVisibleApps[0].value === parseInt(selectedApp)) ||
			isJoan6Pro(gtin) ||
			isJoan6RE(gtin)
		)
	}, [augmentedVisibleApps, selectedApp, gtin])

	const appInterfaceOptions = useMemo(() => {
		return augmentedVisibleApps.map(({ value, isLocked, isUnavailable }) => {
			const messages = {
				isLocked: t(
					"desktop.settings.rooms.devices.form.tooltips.unavailable_feature",
				),
				isUnavailable: t(
					"desktop.settings.rooms.devices.form.tooltips.beta_mode",
				),
			}

			// Rename the remaining Schedule Board app to default name
			const label = !isScheduleBoardApp(value)
				? t(
						`desktop.settings.rooms.devices.device_interfaces.${value}.label` as ParseKeys,
				  )
				: t(
						`desktop.settings.rooms.devices.device_interfaces.${value}.short_label` as ParseKeys,
				  )

			return {
				label,
				value: value.toString(),
				disabled: isLocked || isUnavailable,
				tooltip:
					(isLocked && messages.isLocked) ||
					(isUnavailable && messages.isUnavailable),
			}
		})
	}, [augmentedVisibleApps, t])

	const handleChangeAppInterface = (value: string) => setSelectedApp(value)

	const handleUpdateRoomDevice = useCallback(
		async (values: FormValues) => {
			if (id) {
				const response = await updateRoomDevice({
					id,
					name: values.name,
					// TODO: It is not known where to get the room ID.
					rooms: [],
					interface: parseInt(selectedApp),
					settings: values.settings,
				})

				if (isRejected(response)) {
					const { error } = response

					if (isApiResponseError(error)) {
						setErrors(error.formError, setError, errorToast)
					}
					return
				}
				infoToast(
					t("desktop.settings.rooms.devices.form.toasts.device_updated"),
				)
			}
		},
		[errorToast, id, infoToast, selectedApp, setError, t, updateRoomDevice],
	)

	return (
		<FormProvider {...methods}>
			<PageForm
				className="RoomDeviceForm"
				updateMode={true}
				onUpdate={handleUpdateRoomDevice}
				backUrl={ROOMS_PATHS.devices}
				disabled={isSubmitting}
				footer={!roomExists && <UnavailableRoomsView />}
			>
				<Field
					control={control}
					name="name"
					label={t("desktop.settings.rooms.devices.form.name")}
					helpText={t("desktop.settings.rooms.devices.form.name_info")}
				>
					{(props) => <Input {...props} />}
				</Field>

				{isSelectionVisible && (
					<div className="FieldWrapper">
						<label htmlFor="device_app_interface" className="Label">
							{t("desktop.settings.rooms.devices.form.interface")}
						</label>
						<div className="switch">
							<MultiToggle
								options={appInterfaceOptions}
								onChange={handleChangeAppInterface}
								value={selectedApp}
							/>
						</div>
					</div>
				)}

				{hasLayout && (
					<div className="FieldWrapper">
						<label htmlFor="device_app_interface_layout" className="Label">
							{t("desktop.settings.rooms.devices.form.layout")}
						</label>
						<div className="switch">
							<MultiToggle
								options={scheduleBoardLayoutOptions}
								onChange={handleChangeAppInterface}
								value={selectedApp}
							/>
						</div>
					</div>
				)}

				<div className="device-info">
					<div className="device-info-text">
						<div className="title">
							{t(
								`desktop.settings.rooms.devices.device_interfaces.${selectedApp}.label` as ParseKeys,
							)}
						</div>
						<div className="description">
							{t(
								`desktop.settings.rooms.devices.device_interfaces.${selectedApp}.description` as ParseKeys,
							)}
						</div>
					</div>

					<DevicePreview
						selectedApp={selectedApp}
						gtin={gtin}
						currentDevice={currentDevice}
					/>
				</div>

				<Field
					control={control}
					name="rooms"
					label={t("desktop.settings.rooms.devices.form.choose_room")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							isMulti={isMultiCalendar}
							urlGenerator={(fetchOptions) => resourcesURL(fetchOptions)}
							nothingFoundMessage={t(
								"desktop.settings.rooms.devices.form.no_rooms_found",
							)}
							getOptionLabel={(device) => device.name}
							getOptionValue={(device) => device.key}
							clearable
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default DeviceDetailForm
