import React from "react"

import Heading from "../components/Heading"
import NoNav from "../components/NoNav"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import View from "../components/View"

import "./NotFound.sass"

function Notice() {
	return (
		<View className="NotFoundNotice">
			<Heading>
				<strong>Error 404</strong> &nbsp;/&nbsp; <span>Page not found</span>
			</Heading>
			<p>
				We couldn't decide which page to render for the URL:
				<br />
				<code>{window.location.href}</code>
			</p>
			<p>
				Please <a href="mailto:support@getjoan.com">contact support</a> if this
				is an application error.
			</p>
		</View>
	)
}

export default function NotFound({ onlyView = false }) {
	if (onlyView) {
		return <Notice />
	}

	return (
		<RouteView className="NotFound">
			<Screen sidebar={<NoNav />}>
				<Notice />
			</Screen>
		</RouteView>
	)
}
