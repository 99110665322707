import React, { useEffect, useState } from "react"

import CopyToClipBoardFunction from "react-copy-to-clipboard"

import "./CopyToClipBoard.sass"

type CopyToClipboardTextProps = {
	text: string
	copyButtonText: string
	successText?: string
}
const SUCCESS_ACTION_TIMER = 2000

const CopyToClipBoard = ({
	text,
	copyButtonText,
	successText = "COPIED!",
}: CopyToClipboardTextProps) => {
	const [wasCopied, setWasCopied] = useState<boolean>(false)

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (wasCopied) {
			timer = setTimeout(() => {
				setWasCopied(false)
			}, SUCCESS_ACTION_TIMER)
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [wasCopied])

	const handleOnClick = () => {
		setWasCopied(true)
	}

	return (
		<div className="copy-to-clipboard">
			<input
				type="text"
				name="copy-to-clipboard"
				className="copy-to-clipboard-text"
				value={text}
				disabled
			/>
			<CopyToClipBoardFunction text={text}>
				<button
					type="button"
					className="copy-to-clipboard-button"
					onClick={handleOnClick}
					disabled={wasCopied}
				>
					{!wasCopied ? copyButtonText : successText}
				</button>
			</CopyToClipBoardFunction>
		</div>
	)
}

export default CopyToClipBoard
