import { forwardRef, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import { CSSTransition } from "react-transition-group"

import { useOnClickOutside } from "../hooks/useOnClickOutside"
import { useOnEscape } from "../hooks/useOnEscape"
import AssetIcon, { AssetIconNames, assetIconNames } from "./AssetIcon"
import { Field } from "./Field/Field"
import { Label } from "./Field/Label"
import Filters from "./Filter/Filters"
import SearchFilter from "./Filter/SearchFilter"

import "./AssetIconPicker.sass"

type AssetIconPickerProps = {
	value?: AssetIconNames
	onChange: (name: AssetIconNames) => void
	onChangeShouldCloseModal: (useEscape: boolean) => void
	id: string
}

const AssetIconPicker = forwardRef<HTMLButtonElement, AssetIconPickerProps>(
	({ onChange, value: iconName, onChangeShouldCloseModal, id }, ref) => {
		const [search, setSearch] = useState<string>("")
		const [icon, setIcon] = useState<AssetIconNames | undefined>(iconName)
		const [isOpen, setIsOpen] = useState<boolean>(false)

		const pickerRef = useRef(null)

		const { t } = useTranslation()

		const openPicker = () => {
			setIsOpen(true)
			onChangeShouldCloseModal(false)
		}
		const closePicker = () => {
			setIsOpen(false)
			onChangeShouldCloseModal(true)
		}

		useOnEscape(closePicker)
		useOnClickOutside([pickerRef], closePicker)

		let filteredIconsNames = assetIconNames
		if (search) {
			filteredIconsNames = assetIconNames.filter((iconName) =>
				iconName.includes(search),
			)
		}
		const handleOnClick = (name: AssetIconNames) => {
			setIcon(name)
			onChange(name)
			setIsOpen(false)
		}

		return (
			<Field className="asset-icon-picker">
				<Label forInput={id}>
					{t("desktop.components.asset_icon_picker.asset_icon_label")}
				</Label>
				{icon && <AssetIcon name={icon} size={64} />}
				<button
					className="icon-change-button"
					onClick={openPicker}
					type="button"
					id={id}
				>
					{icon
						? t("desktop.components.asset_icon_picker.change")
						: t("desktop.components.asset_icon_picker.select_icon")}
				</button>
				<CSSTransition
					in={isOpen}
					className="icon-picker"
					timeout={300}
					unmountOnExit
					nodeRef={pickerRef}
				>
					<div ref={pickerRef}>
						<Filters>
							<SearchFilter
								value={search}
								onChange={setSearch}
								placeholder={t("desktop.components.asset_icon_picker.search")}
							/>
						</Filters>
						<div className="icon-list">
							{filteredIconsNames.map((name) => (
								<button
									onClick={() => handleOnClick(name)}
									title={name}
									type="button"
									key={name}
									ref={ref}
								>
									<AssetIcon name={name} size={32} />
								</button>
							))}
						</div>
					</div>
				</CSSTransition>
			</Field>
		)
	},
)

export default AssetIconPicker
