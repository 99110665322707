import { useCallback } from "react"

import dayjs, { Dayjs } from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import {
	buildingsWithVMURL,
	desksURL,
	usersURL,
	visitorsURL,
} from "../../../api"
import { timeZone as defaultTimezone } from "../../../dayjs"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import { useToast } from "../../../hooks/useToast"
import { Filter } from "../../../screens/Manage/Visitors/InvitesFilters"
import { INVITES_FILTERS_STORE_NAME } from "../../../screens/Manage/constants"
import {
	InternalTime,
	OptionType,
	RecurringType,
} from "../../../types/sharedTypes"
import { setTimeToDayjs, toInternalTime } from "../../../utils"
import Field from "../../Field"
import { FilterSpecialValues } from "../../Filter/types"
import AsyncAutocomplete from "../../advanced/AsyncAutocomplete"
import AsyncSelect from "../../advanced/AsyncSelect"
import Alert from "../../basic/Alert"
import { DatePicker } from "../../basic/DatePicker"
import { Input } from "../../basic/Input"
import InputNumber from "../../basic/InputNumber"
import { InputPhone } from "../../basic/InputPhone"
import Switch from "../../basic/Switch"
import { TimeRange, TimeRangePicker } from "../../basic/TimeRangePicker"
import PageForm from "../PageFormHook"
import { setErrors } from "../formUtils"

import { changeDay } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { createInvite, updateInvite } from "../../../redux/invite/inviteSlice"
import { InviteRequest, InviteResponse } from "../../../redux/invite/types"
import { Building, Desk, Host } from "../../../redux/invites/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { formatUser, isNormalUser } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { VisitorSearchResponse } from "../../../redux/visitors/types"

import "./InviteEditForm.sass"

type FormValues = {
	full_name: string
	company_name?: string
	email: string
	phone?: string
	date: string
	visit_time: TimeRange
	building: Building
	desk?: Desk
	host?: Host
	schedule?: OptionType<RecurringType | undefined>
	until: string
	guests?: number
	additional_info?: string
	send_invite_to_visitor: boolean
	send_notification_to_host: boolean
}

type Props = {
	invite?: InviteResponse
}

const DEFAULT_START_TIME: InternalTime = "09:00"
const DEFAULT_END_TIME: InternalTime = "17:00"

const FORM_MAPPING = {
	desk_id: "desk",
	end: "visit_time",
	start: "visit_time",
	host_id: "host",
	building_id: "building",
} as const

const InviteEditForm = ({ invite }: Props) => {
	const { t } = useTranslation()
	const history = useHistory()
	const { infoToast, errorToast } = useToast()

	const { currentDate } = useAppSelector(selectAppDates)

	const { entry: currentUser } = useAppSelector(selectUser)

	const isUser = isNormalUser(currentUser)

	const actions = useActions({
		createInvite: (invite: InviteRequest) => createInvite(invite),
		updateInvite: (id: string, invite: InviteRequest) =>
			updateInvite({ id, ...invite }),
		changeDay: (day: Dayjs) => changeDay(day.toISOString()),
	})

	const { entries: visitorSettings } = useAppSelector(selectVisitorSettings)

	const defaultFilter: Filter = {
		building_id: currentUser.building
			? currentUser.building.id
			: FilterSpecialValues.ALL,
		show: "",
		search: "",
		page: 1,
	}

	const [storedFilterValues] = useStoredFilter({
		filterName: INVITES_FILTERS_STORE_NAME,
		defaultFilterValues: defaultFilter,
	})

	const {
		full_name,
		company_name,
		email,
		phone,
		start,
		end,
		building,
		desk,
		host,
		schedule,
		guests,
		additional_info,
		send_invite_to_visitor,
		send_notification_to_host,
	} = invite || {}

	// const scheduleDropdownOptions = useMemo(() => {
	// 	let options: OptionType<RecurringType | undefined>[] = [
	// 		{ label: t("general.repeat.once"), value: undefined },
	// 	]
	// 	options = options.concat(
	// 		Object.values(RecurringType).map((value) => {
	// 			let label = t(`general.repeat.${value}`)
	// 			if (value === RecurringType.EVERY_DAY_OF_WEEK) {
	// 				label = t("general.repeat.every_day_of_week", {
	// 					day: dayjs(start).format("dddd"),
	// 				})
	// 			}
	// 			return {
	// 				value,
	// 				label,
	// 			}
	// 		}),
	// 	)

	// 	return options
	// }, [t, start])

	const defaultFormValues = {
		full_name: full_name ?? "",
		company_name: company_name ?? "",
		email: email ?? "",
		phone: phone ?? "",
		date: start ?? currentDate.toISOString(),
		visit_time: {
			start: start ? toInternalTime(start) : DEFAULT_START_TIME,
			end: end ? toInternalTime(end) : DEFAULT_END_TIME,
		},
		building: building,
		desk: desk,
		host: isUser ? currentUser : host,
		schedule: {
			label: schedule?.freq
				? schedule?.freq === RecurringType.EVERY_DAY_OF_WEEK
					? t("general.repeat.every_day_of_week", {
							day: dayjs(start).format("dddd"),
					  })
					: t(`general.repeat.${schedule?.freq}`)
				: t("general.repeat.once"),
			value: schedule?.freq ?? undefined,
		},
		until: schedule?.until,
		guests: guests ?? 0,
		additional_info: additional_info ?? "",
		send_invite_to_visitor:
			send_invite_to_visitor ??
			(visitorSettings
				? Boolean(
						visitorSettings?.find(
							(setting) =>
								setting.building_id === storedFilterValues.building_id,
						)?.enable_visitors_notification,
				  )
				: false),
		send_notification_to_host:
			send_notification_to_host ??
			(visitorSettings
				? Boolean(
						visitorSettings?.find(
							(setting) =>
								setting.building_id === storedFilterValues.building_id,
						)?.enable_general_notification,
				  )
				: false),
	}

	const methods = useForm<FormValues>({
		defaultValues: defaultFormValues,
	})

	const { control, watch, setError, setValue } = methods

	const onCreateClick = useCallback(
		async (values: FormValues) => {
			const response = await actions.createInvite({
				full_name: values.full_name,
				company_name: values.company_name,
				email: values.email,
				phone: values.phone,
				start: setTimeToDayjs(
					dayjs(values.date),
					values.visit_time.start!,
				).toISOString(),
				end: setTimeToDayjs(
					dayjs(values.date),
					values.visit_time.end!,
				).toISOString(),
				tz: defaultTimezone,
				building_id: values.building?.id,
				desk_id: values.desk?.id ?? null,
				host_id: values.host?.email,
				schedule:
					values.schedule && values.schedule.value
						? {
								freq: values.schedule.value,
								until: values.until,
						  }
						: null,
				guests: values.guests ?? 0,
				additional_info: values.additional_info,
				send_invite_to_visitor: values.send_invite_to_visitor,
				send_notification_to_host: values.send_notification_to_host,
			})

			if (createInvite.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast, FORM_MAPPING)
				}
			} else {
				actions.changeDay(dayjs(values.date))

				analyticsEvent(
					isUser
						? SupportedEvents.VISITOR_USER_INVITE_ADD
						: SupportedEvents.VISITOR_ADMIN_INVITE_ADD,
					{
						id: response?.payload?.id,
						name: response?.payload?.full_name,
						building_id: response?.payload?.building.id,
						visitor_email: response?.payload?.email,
						host_email: response?.payload?.host?.email,
					},
				)

				infoToast(t("desktop.manage.visitors.form.invite_created_toast"))
				history.push("/manage/visitors/invites")
			}
		},
		[actions, setError, errorToast, isUser, infoToast, t, history],
	)

	const onUpdateClick = useCallback(
		async (values: FormValues) => {
			if (invite) {
				const response = await actions.updateInvite(invite.id, {
					full_name: values.full_name,
					company_name: values.company_name,
					email: values.email,
					phone: values.phone,
					start: setTimeToDayjs(
						dayjs(values.date),
						values.visit_time.start!,
					).toISOString(),
					end: setTimeToDayjs(
						dayjs(values.date),
						values.visit_time.end!,
					).toISOString(),
					tz: defaultTimezone,
					building_id: values.building?.id,
					desk_id: values.desk?.id ?? null,
					host_id: values.host?.email,
					schedule:
						values.schedule && values.schedule.value
							? {
									freq: values.schedule.value,
									until: values.until,
							  }
							: null,
					guests: values.guests ?? 0,
					additional_info: values.additional_info,
					send_invite_to_visitor: values.send_invite_to_visitor,
					send_notification_to_host: values.send_notification_to_host,
				})

				if (updateInvite.rejected.match(response)) {
					if (response.payload) {
						setErrors(response.payload, setError, errorToast, FORM_MAPPING)
					}
				} else {
					actions.changeDay(dayjs(values.date))

					analyticsEvent(
						isUser
							? SupportedEvents.VISITOR_USER_INVITE_UPDATE
							: SupportedEvents.VISITOR_ADMIN_INVITE_UPDATE,
						{
							id: response?.payload?.id,
							name: response?.payload?.full_name,
							building_id: response?.payload?.building.id,
							visitor_email: response?.payload?.email,
							host_email: response?.payload?.host?.email,
						},
					)

					infoToast(t("desktop.manage.visitors.form.invite_updated_toast"))
					history.push("/manage/visitors/invites")
				}
			}
		},
		[invite, actions, setError, errorToast, isUser, infoToast, t, history],
	)

	const scheduleValue = watch("schedule")
	const buildingValue = watch("building")

	const isFormKey = (v: string): v is keyof FormValues => {
		if (v in defaultFormValues) {
			return true
		}
		return false
	}

	const autoCompleteFieldsHandler = (
		visitorResponse: VisitorSearchResponse,
	) => {
		return Object.entries(visitorResponse).forEach(([key, value]) => {
			if (isFormKey(key)) {
				setValue(key, value)
			}
		})
	}

	return (
		<FormProvider {...methods}>
			{methods.formState.errors.desk && (
				<Alert className="VisitFormAlert">
					{methods.formState.errors.desk.message}
				</Alert>
			)}
			<PageForm
				className="InviteEditForm"
				updateMode={invite !== undefined}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				backUrl="/manage/visitors/invites"
			>
				<Field
					control={control}
					name="full_name"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.full_name")}
				>
					{(props) => (
						<AsyncAutocomplete
							{...props}
							urlGenerator={(fetchOptions) => visitorsURL(fetchOptions)}
							getOptionLabel={(visitor: VisitorSearchResponse) =>
								visitor.full_name
							}
							getOptionValue={(visitor: VisitorSearchResponse) =>
								visitor.full_name
							}
							autoCompleteCallback={autoCompleteFieldsHandler}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="company_name"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.company_name")}
					subText={`(${t("general.optional")})`}
				>
					{(props) => <Input {...props} />}
				</Field>

				<Field
					control={control}
					name="email"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.email")}
				>
					{(props) => <Input {...props} />}
				</Field>
				<Field
					control={control}
					name="phone"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.phone_number")}
					subText={`(${t("general.optional")})`}
				>
					{(props) => <InputPhone {...props} placeholder="+386 31 386 386" />}
				</Field>

				<Field
					control={control}
					name="date"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.visit_date")}
				>
					{(props) => (
						<DatePicker
							{...props}
							showMonthDropdown
							showYearDropdown
							minDate={dayjs().startOf("day").toDate()}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="visit_time"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.visit_time")}
				>
					{(props) => <TimeRangePicker {...props} />}
				</Field>

				<Field
					control={control}
					name="building"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.select_building")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => buildingsWithVMURL(fetchOptions)}
							getOptionLabel={(building) => building.name}
							getOptionValue={(building) => building.id}
						/>
					)}
				</Field>
				{buildingValue ? (
					<Field
						control={control}
						name="desk"
						className="field-width-50"
						label={t("desktop.manage.visitors.form.assign_desk")}
						subText={`(${t("general.optional")})`}
						hideErrorsOnField
					>
						{(props) => {
							if (buildingValue) {
								return (
									<AsyncSelect<Desk>
										{...props}
										key={buildingValue?.id}
										clearable
										urlGenerator={(fetchOptions) =>
											desksURL({
												query: {
													conditions: {
														building_id: encodeURIComponent(buildingValue.id),
														name: fetchOptions?.search
															? encodeURIComponent(
																	fetchOptions?.search?.toString(),
															  )
															: undefined,
													},
												},
												...fetchOptions,
											})
										}
										getOptionLabel={(desk) => desk.name}
										getOptionValue={(desk) => desk.id}
										fetchLimit={10000}
									/>
								)
							}
						}}
					</Field>
				) : (
					<div className="field-width-50"></div>
				)}

				<Field
					control={control}
					name="host"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.select_host")}
				>
					{(props) => (
						<AsyncSelect
							{...props}
							urlGenerator={(fetchOptions) => usersURL(fetchOptions)}
							nothingFoundMessage={t(
								"desktop.settings.desks.desk_form.no_user_found",
							)}
							getOptionLabel={(user) => formatUser(user ?? {})}
							getOptionValue={(user) => user?.email ?? ""}
							disabled={isUser}
						/>
					)}
				</Field>
				{/* <Field
					control={control}
					name="schedule"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.schedule")}
				>
					{(props) => (
						<Select<OptionType<RecurringType | undefined>>
							{...props}
							options={scheduleDropdownOptions}
						/>
					)}
				</Field> */}
				<Field
					control={control}
					name="guests"
					className="field-width-50"
					label={t("desktop.manage.visitors.form.additional_guests")}
				>
					{(props) => <InputNumber {...props} />}
				</Field>
				{scheduleValue?.value !== undefined && (
					<Field
						control={control}
						name="until"
						className="field-width-50"
						label={t("desktop.manage.visitors.form.until")}
					>
						{(props) => (
							<DatePicker {...props} showMonthDropdown showYearDropdown />
						)}
					</Field>
				)}

				<Field
					control={control}
					name="additional_info"
					label={t("desktop.manage.visitors.form.additional_information")}
					subText={`(${t(
						"desktop.manage.visitors.form.additional_information_descr",
					)})`}
				>
					{(props) => (
						<Input className="additional-info-input" {...props} multiline />
					)}
				</Field>

				<Field control={control} name="send_invite_to_visitor">
					{(props) => (
						<Switch
							{...props}
							label={t(
								"desktop.manage.visitors.form.send_invite_email_to_visitor",
							)}
						/>
					)}
				</Field>

				<Field control={control} name="send_notification_to_host">
					{(props) => (
						<Switch
							{...props}
							label={t(
								"desktop.manage.visitors.form.send_notifications_to_host",
							)}
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default InviteEditForm
