import { PropsWithChildren } from "react"

import classNames from "classnames"
import {
	ITooltip,
	PlacesType,
	Tooltip as ReactTooltip,
	TooltipWrapper,
} from "react-tooltip"

import { Children } from "../../../types/sharedTypes"

import "./style.sass"

export interface TooltipProps extends Omit<ITooltip, "content"> {
	uniqueId: string
	place?: PlacesType
	content: Children
}

export const Tooltip = ({
	children,
	uniqueId,
	place = "top",
	content,
	className,
	...props
}: PropsWithChildren<TooltipProps>) => {
	const cn = classNames("TpWrapper", className)

	return (
		<TooltipWrapper className={cn} tooltipId={uniqueId}>
			<div className="TpInnerWrapper" id={uniqueId}>
				{children}
			</div>
			<ReactTooltip {...props} anchorId={uniqueId} place={place}>
				{content}
			</ReactTooltip>
		</TooltipWrapper>
	)
}
