import { useRefresh } from "./useRefresh"

import { fetchUser } from "../../redux/user/userSlice"
import { useActions } from "../../redux/utils"

export const useRefreshUser = (): void => {
	const actions = useActions({
		fetchUser: () => fetchUser(),
	})

	useRefresh(() => {
		actions.fetchUser()
	})
	return
}
