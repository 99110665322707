import React, { useCallback, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { parseQueryWithDefault } from "../../../utils"
import AssetItem from "./AssetItem"

import { useFetchAssetsQuery } from "../../../redux/api/assets"
import { Asset } from "../../../redux/assets/types"

import Pagination from "../../../components/Pagination"
import Loader from "../../../components/basic/Loader"

import "./AssetList.sass"

type Props = {
	assetTypeId: string
	onAddAssetClick: (assetTypeId: string) => void
	onEditAssetClick: (asset: Asset) => void
}

const ENTRIES_PER_PAGE = 10

const AssetList = ({
	assetTypeId,
	onAddAssetClick,
	onEditAssetClick,
}: Props) => {
	const { t } = useTranslation()

	const history = useHistory()

	const location = history.location

	const { page: queryPage } = parseQueryWithDefault(location.search, {
		page: 1,
	})

	const [page, setCurrentPage] = useState(queryPage)

	const {
		data: { count = 0, offset = 0, results: assets = [] } = {},
		refetch: refetchAssets,
		isFetching: areAssetsTypesLoading,
	} = useFetchAssetsQuery({
		asset_type: assetTypeId,
		offset: (page - 1) * ENTRIES_PER_PAGE,
		limit: ENTRIES_PER_PAGE,
	})

	const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
		useBackendPagination({
			offset,
			totalNumberOfItems: count,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 3,
			maxTrailingLinks: 2,
		})

	const setPage = useCallback(
		(nextPage: number) => {
			setCurrentPage(nextPage)
			refetchAssets()
		},
		[refetchAssets],
	)

	if (areAssetsTypesLoading) {
		return <Loader />
	}

	return (
		<>
			{!!assets.length ? (
				<div className="card-body">
					<div className="asset-list">
						{assets.map((asset, index) => (
							<AssetItem
								key={asset.id}
								rowNumber={calcRowNumber(index)}
								asset={asset}
								onEditAssetClick={onEditAssetClick}
							/>
						))}
					</div>
				</div>
			) : (
				<div className="card-foot card-foot-no-assets">
					<div className="">
						<div>{t("desktop.settings.assets.asset_type_has_no_assets")}</div>
						<div>
							<Trans i18nKey="desktop.settings.assets.create_new_asset">
								<span
									className="text-link"
									onClick={() => onAddAssetClick(assetTypeId)}
								>
									Add an asset
								</span>
							</Trans>
						</div>
					</div>
				</div>
			)}

			{!!paginationLinks.length && (
				<div className="card-foot card-foot-pagination">
					<Pagination
						links={paginationLinks}
						setPage={setPage}
						onPrevious={() => setPage(page - 1)}
						onNext={() => setPage(page + 1)}
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						from={from}
						to={to}
						total={count}
						items={t("desktop.settings.assets.assets", {
							count,
						})}
					/>
				</div>
			)}
		</>
	)
}

export default AssetList
