import { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { Facet, OptionalKeysRecord } from "../../../types/sharedTypes"

import { PeopleScheduleFacets } from "../../../redux/people_schedule/types"

import FacetsFilter from "../../../components/Filter/FacetsFilter"
import FilterSpace from "../../../components/Filter/FilterSpace"
import Filters from "../../../components/Filter/Filters"
import SearchFilter from "../../../components/Filter/SearchFilter"

export type Filter = {
	show?: string
	department_id?: string
	search: string
	page?: number
}

export type FilterKeys = keyof Filter

type MapFacetToFilters = OptionalKeysRecord<PeopleScheduleFacets, FilterKeys>

export type PeopleFiltersProps = {
	onChange: (filter: Filter) => Promise<void>
	defaultValues: Filter
	mapFacetToFilters: MapFacetToFilters
	facets?: OptionalKeysRecord<PeopleScheduleFacets, Facet[]>
	useFacets: readonly PeopleScheduleFacets[]
}

const isFilterKey = (value: string): value is FilterKeys => {
	const validValues: FilterKeys[] = ["show", "department_id", "search"]
	return validValues.includes(value as FilterKeys)
}

const isPeopleScheduleFacets = (
	value: string,
): value is PeopleScheduleFacets => {
	const validValues: PeopleScheduleFacets[] = ["people", "departments"]
	return validValues.includes(value as PeopleScheduleFacets)
}

const getOnChangeKey = (
	facet: PeopleScheduleFacets,
	mapFacetToFilters: MapFacetToFilters,
) => {
	const onChangeKey = mapFacetToFilters[facet] ?? facet
	if (isFilterKey(onChangeKey)) {
		return onChangeKey
	}
}

const PeopleFilters = ({
	onChange,
	defaultValues,
	mapFacetToFilters,
	facets,
	useFacets,
}: PeopleFiltersProps) => {
	const filtersRef = useRef<Filter>(defaultValues)
	const [filters, setFilters] = useState<Filter>(defaultValues)

	const { t } = useTranslation()

	const handleOnChange = useCallback(
		(filterName: FilterKeys) => (value: string) => {
			filtersRef.current = {
				...filtersRef.current,
				[filterName]: value,
			}

			onChange(filtersRef.current as Filter)

			setFilters(filtersRef.current)
		},
		[onChange],
	)

	return (
		<Filters>
			{facets &&
				useFacets.map((facetName) => {
					if (isPeopleScheduleFacets(facetName)) {
						const onChangeKey = getOnChangeKey(facetName, mapFacetToFilters)
						return (
							<FacetsFilter
								key={facetName}
								options={facets[facetName]}
								onChange={onChangeKey && handleOnChange(onChangeKey)}
								value={((onChangeKey && filters[onChangeKey]) ?? "") as string}
							/>
						)
					}
					return null
				})}

			<FilterSpace />
			<SearchFilter
				value={filters.search}
				onChange={handleOnChange("search")}
				placeholder={t("desktop.manage.desk_booking.filter_desks")}
			/>
		</Filters>
	)
}

export default PeopleFilters
