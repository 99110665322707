import React, { useEffect, useMemo } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../../hooks/useToast"
import { PAYMENT_METHOD_OPTIONS, STEPS } from "../Payments/constants"
import PrepaidCodeForm from "../PrepaidCode/PrepaidCodeForm"
import PrepaidCodeInfo from "../PrepaidCode/PrepaidCodeInfo"
import { BILLING_PATHNAMES } from "../constants"
import CreditCard from "./CreditCard"

import { PAYMENT_METHODS } from "../../../../redux/api/billing/constants"
import {
	useFetchPaymentsQuery,
	useSavePaymentCreditCardMutation,
	useSavePaymentPrepaidCodeMutation,
} from "../../../../redux/api/billing/payments"
import { useRedeemPrepaidCodeMutation } from "../../../../redux/api/billing/prepaidCode"
import { ACTIVATION_DATE_OPTIONS } from "../../../../redux/api/billing/prepaidCode/constants"
import { useFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"
import { PaymentMethod as PaymentMethods } from "../../../../redux/api/billing/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Field from "../../../../components/Field"
import PageForm from "../../../../components/Form/PageFormHook"
import { setErrors } from "../../../../components/Form/formUtils"
import View from "../../../../components/View"
import Button from "../../../../components/basic/Button"
import Card from "../../../../components/basic/Card"
import Loader from "../../../../components/basic/Loader"
import { RadioGroup } from "../../../../components/basic/Radio"
import Stepper from "../../../../components/basic/Stepper"

import "./styles.sass"

const prepaidCodeFormMapping = {
	activated_at: `selectedDate`,
} as const

type PaymentMethodFormValues = {
	type: PaymentMethods
	// for prepaid code
	code: string
	selectedDate: string // 'today' | 'month' | 'custom'
	customDate: Date | null
}
const PaymentMethod = () => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const history = useHistory()

	const { data: payments, isLoading } = useFetchPaymentsQuery()
	const { data: { results: subscriptions = [] } = {} } =
		useFetchSubscriptionsQuery()
	const [redeemPrepaidCode] = useRedeemPrepaidCodeMutation()
	const [saveCreditCard] = useSavePaymentCreditCardMutation()
	const [savePrepaidCode] = useSavePaymentPrepaidCodeMutation()

	const defaultValues = useMemo(
		() => ({
			type: payments?.type ?? PAYMENT_METHODS.NONE,
			selectedDate: "today",
		}),
		[payments?.type],
	)
	const subscriptionsWithPrepaidCode = subscriptions
		?.filter((s) => s.prepaid_code)
		?.sort((a, b) => b.subscription_id - a.subscription_id)

	const methods = useForm<PaymentMethodFormValues>({
		defaultValues,
	})

	const {
		control,
		handleSubmit,
		watch,
		setError,
		resetField,
		reset,
		formState: { isSubmitting },
	} = methods

	const selectedType = watch("type")

	const isCreditCardTypeWithoutCreditCard =
		selectedType === PAYMENT_METHODS.CREDIT_CARD && !payments?.credit_card

	const translatedPaymentMethodOptions = PAYMENT_METHOD_OPTIONS.map(
		(option) => ({
			...option,
			label: t(option.label as ParseKeys),
		}),
	)

	/**
	 *  Handlers
	 */
	const handleOnUpdate = async ({ type }: PaymentMethodFormValues) => {
		let result

		switch (type) {
			case PAYMENT_METHODS.CREDIT_CARD:
				result = await saveCreditCard()
				break
			case PAYMENT_METHODS.PREPAID_CODE:
				// TODO: not implemented on BE jet
				result = await savePrepaidCode()
				break
			default:
				return
		}

		if (isRejected(result)) {
			const { error } = result

			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast)
			}

			return
		}

		infoToast(
			t("desktop.settings.billing.payment_method.toasts.update_success"),
		)
		history.push(BILLING_PATHNAMES.overview.root)
	}

	const handleRedeemCode = async () => {
		handleSubmit(async ({ code, selectedDate, customDate }) => {
			const activated_at = (() => {
				switch (selectedDate) {
					case ACTIVATION_DATE_OPTIONS.today:
						return null
					case ACTIVATION_DATE_OPTIONS.beginning_month:
						return dayjs().add(1, "month").startOf("month").toISOString()
					case ACTIVATION_DATE_OPTIONS.custom:
						return dayjs(customDate ?? undefined).toISOString()
					default:
						return null
				}
			})()

			const response = await redeemPrepaidCode({ code, activated_at })

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					setErrors(
						error.formError,
						setError,
						errorToast,
						prepaidCodeFormMapping,
					)
				}
				return
			}

			infoToast(t("desktop.settings.billing.pre_paid_code_card.success_toast"))
			resetField("code")
			resetField("selectedDate", { defaultValue: "today" })
			resetField("customDate")
		})()
	}

	const handleManageCreditCardSuccess = async () => {
		const result = await saveCreditCard()
		if (isRejected(result)) {
			const { error } = result

			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast)
			}

			return
		}
		infoToast(
			t("desktop.settings.billing.payment_method.toasts.update_success"),
		)
		infoToast(
			t(
				`desktop.settings.billing.payment_method.toasts.${
					payments?.credit_card ? "update" : "add"
				}_credit_card_success`,
			),
		)
		history.push(BILLING_PATHNAMES.overview.root)
	}

	/**
	 *  UseEffect
	 */
	useEffect(() => {
		reset(defaultValues)
	}, [defaultValues, reset])

	/**
	 *  Render
	 */
	return (
		<View className="PaymentMethod">
			<Breadcrumbs
				depth={2}
				includeParamsAsPath
				values={[
					t("desktop.settings.billing.title"),
					t("desktop.settings.billing.billing_details_form.title"),
				]}
			/>

			<Stepper steps={STEPS} currentStep="payment" onClick={() => {}} />
			{isLoading ? (
				<Loader variant="fullScreen" />
			) : (
				<FormProvider {...methods}>
					<PageForm
						className="PaymentMethod__form"
						updateMode={true}
						backUrl={BILLING_PATHNAMES.overview.companyDetails}
						onUpdate={handleOnUpdate}
						submitButtonText={t("general.next")}
						disabled={isCreditCardTypeWithoutCreditCard}
					>
						<div className="PaymentMethod__form__card">
							{!payments?.type && selectedType === PAYMENT_METHODS.NONE ? (
								<div className="PaymentMethod__form__no-payment">
									{t(
										"desktop.settings.billing.payment_method.no_payment_method",
									)}
								</div>
							) : null}
							<Field control={control} name={"type"}>
								{(props) => (
									<RadioGroup
										{...props}
										options={translatedPaymentMethodOptions}
										display="vertical"
									/>
								)}
							</Field>

							{selectedType === PAYMENT_METHODS.PREPAID_CODE ? (
								<>
									<PrepaidCodeForm
										control={control}
										watch={watch}
										isSubmitting={isSubmitting}
									/>
									<Button onClick={handleRedeemCode}>
										{t(
											"desktop.settings.billing.payment_method.buttons.redeem_code",
										)}
									</Button>
								</>
							) : null}

							{selectedType === PAYMENT_METHODS.CREDIT_CARD ? (
								<CreditCard onSuccess={handleManageCreditCardSuccess} />
							) : null}
						</div>

						{selectedType === PAYMENT_METHODS.PREPAID_CODE ? (
							<Card className="PaymentMethod__prepaid-codes">
								<div className="PaymentMethod__prepaid-codes__title">
									{t(
										"desktop.settings.billing.payment_method.applied_coupon_codes",
									)}
								</div>
								{subscriptionsWithPrepaidCode?.map((s, index) => (
									<PrepaidCodeInfo
										key={s.subscription_id}
										subscription={s}
										index={index + 1}
									/>
								))}
							</Card>
						) : null}
					</PageForm>
				</FormProvider>
			)}
		</View>
	)
}

export default PaymentMethod
