import React, { useEffect, useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useAppSelector } from "../../../redux/reducers"
import { selectTabletSettings } from "../../../redux/tablet_settings/selectors"
import {
	createTabletSettings,
	fetchTabletSettings,
	updateTabletSettings,
} from "../../../redux/tablet_settings/tabletSettingsSlice"
import {
	SignInField,
	SignInFieldType,
	TabletSettingRequest,
} from "../../../redux/tablet_settings/types"
import { useActions } from "../../../redux/utils"

import BuildingFilter from "../../../components/Filter/BuildingFilter"
import FilterSpace from "../../../components/Filter/FilterSpace"
import Filters from "../../../components/Filter/Filters"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { PageActions } from "../../../components/Form/PageFormHook"
import SortableCardsContainer from "../../../components/SortableCardsContainer"
import Space from "../../../components/Space"
import View from "../../../components/View"
import VisitorSignInField from "../../../components/Visitors/VisitorSignInField"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"
import MultiActionButton from "../../../components/basic/MultiActionButton"
import Switch from "../../../components/basic/Switch"

import "./TabletSettings.sass"

function TabletSettings() {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const allFieldDropdownOptions: OptionType<SignInFieldType>[] = useMemo(() => {
		return Object.values(SignInFieldType).map((value) => {
			return {
				value,
				label: t(
					`desktop.settings.visitors.device_settings.tablet.sign_in_fields.${value.toLowerCase()}.label` as ParseKeys,
				),
			}
		})
	}, [t])

	const actions = useActions({
		fetchTabletSettings: () => fetchTabletSettings(),
		createTabletSettings: (setting: TabletSettingRequest) =>
			createTabletSettings(setting),
		updateTabletSettings: (setting: TabletSettingRequest) =>
			updateTabletSettings(setting),
	})

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

	const {
		entries: tabletSettings,
		isLoaded,
		isLoading,
		isSubmitting,
	} = useAppSelector(selectTabletSettings)

	const [tabletSetting, setTabletSetting] = useState<TabletSettingRequest>()
	const [updateMode, setUpdateMode] = useState(false)
	const [invalidCustomFields, setInvalidCustomFields] = useState<number[]>([])

	const { value: buildingFilter, onChange: setBuildingFilter } =
		useLocalStorage(
			"tablet-settings-building-filter",
			FilterSpecialValues.EMPTY,
		)

	const selectedBuilding = buildings.find(
		(building) => building.id === buildingFilter,
	)

	const [fieldsDropdownOptions, setFieldsDropdownOptions] = useState(
		allFieldDropdownOptions,
	)

	// Add new setting to settings state
	const handleOnFieldAdded = (value: SignInFieldType) => {
		setTabletSetting((prevSettings) => {
			if (prevSettings) {
				return {
					...prevSettings,
					signin_fields: [
						...prevSettings.signin_fields,
						{
							order: prevSettings.signin_fields.length,
							type: value,
							required: false,
						},
					],
				}
			}
		})
	}

	const handleOnFieldDeleted = (index: number) => {
		setTabletSetting((prevSettings) => {
			if (prevSettings) {
				const filteredFields = prevSettings.signin_fields.filter(
					(f, i) => i !== index,
				)

				return {
					...prevSettings,
					signin_fields: filteredFields.map((field, i) => ({
						...field,
						order: i,
					})),
				}
			}
		})
	}

	const handleOnFieldSorted = (
		sourceIndex: number,
		destinationIndex: number,
	) => {
		// Ignore NAME setting since it's always on top
		if (destinationIndex === 0 || !tabletSetting) {
			return
		}

		const currentFieldList = Array.from(tabletSetting.signin_fields)
		const [reorderedField] = currentFieldList.splice(sourceIndex, 1)
		currentFieldList.splice(destinationIndex, 0, reorderedField)

		// Reorder fields and set their order property accordingly
		const updatedSettings = currentFieldList.map((field, index) => ({
			...field,
			order: index,
		}))
		setTabletSetting((prevSettings) => {
			if (prevSettings) {
				return {
					...prevSettings,
					signin_fields: updatedSettings,
				}
			}
		})
	}

	const handleFieldChange = (updatedField: SignInField) => {
		setTabletSetting((prevSettings) => {
			if (prevSettings) {
				return {
					...prevSettings,
					signin_fields: prevSettings.signin_fields.map((field) => {
						if (field.type === SignInFieldType.CUSTOM) {
							if (field.order === updatedField.order) {
								setInvalidCustomFields((prevState) =>
									prevState.filter((o) => o !== field.order),
								)
								return {
									...field,
									required: updatedField.required,
									custom_field_name: updatedField.custom_field_name,
								}
							} else {
								return field
							}
						} else {
							if (field.type === updatedField.type) {
								return {
									...field,
									required: updatedField.required,
								}
							} else {
								return field
							}
						}
					}),
				}
			}
		})
	}

	const handleSave = async (enable_sign_in?: boolean) => {
		if (isLoading) return

		let infoToastString: ParseKeys =
			"desktop.settings.visitors.device_settings.tablet.settings_updated_toast"

		if (tabletSetting) {
			let payload = tabletSetting

			if (enable_sign_in !== undefined) {
				payload = {
					...payload,
					enable_sign_in,
				}

				if (enable_sign_in) {
					infoToastString =
						"desktop.settings.visitors.device_settings.tablet.settings_enabled_toast"
				} else {
					infoToastString =
						"desktop.settings.visitors.device_settings.tablet.settings_disabled_toast"
				}
			}

			tabletSetting.signin_fields.forEach((field) => {
				if (field.type === SignInFieldType.CUSTOM && !field.custom_field_name) {
					setInvalidCustomFields((prevState) => [...prevState, field.order])
				}
			})

			if (invalidCustomFields.length === 0) {
				if (updateMode) {
					const response = await actions.updateTabletSettings(payload)

					if (updateTabletSettings.rejected.match(response)) {
						errorToast(
							t(
								"desktop.settings.visitors.device_settings.tablet.settings_error_toast",
							),
						)
					} else {
						analyticsEvent(
							response?.payload?.enable_sign_in
								? SupportedEvents.VISITOR_ADMIN_SIGN_IN_ENABLED
								: SupportedEvents.VISITOR_ADMIN_SIGN_IN_DISABLED,
							{
								id: response?.payload?.id,
								building_id: response?.payload?.building_id,
							},
						)
						infoToast(t(infoToastString))
					}
				} else {
					const response = await actions.createTabletSettings(payload)
					if (createTabletSettings.rejected.match(response)) {
						errorToast(t(infoToastString))
					} else {
						analyticsEvent(
							response?.payload?.enable_sign_in
								? SupportedEvents.VISITOR_ADMIN_SIGN_IN_ENABLED
								: SupportedEvents.VISITOR_ADMIN_SIGN_IN_DISABLED,
							{
								id: response?.payload?.id,
								building_id: response?.payload?.building_id,
							},
						)

						infoToast(t(infoToastString))
					}
				}
			}
		}
	}

	useEffect(() => {
		actions.fetchTabletSettings()
	}, [actions])

	// Set tablet settings based on selected building or make new entry
	useEffect(() => {
		const exTabletSetting = tabletSettings.find(
			(ts) => ts.building_id === buildingFilter,
		)

		if (exTabletSetting !== undefined) {
			setUpdateMode(true)
			setTabletSetting({
				...exTabletSetting,
				signin_fields: exTabletSetting.signin_fields
					.slice()
					.sort((a, b) => a.order - b.order),
			})
		} else {
			setUpdateMode(false)
			setTabletSetting({
				building_id: buildingFilter,
				enable_sign_in: false,
				signin_fields: [
					{
						order: 0,
						type: SignInFieldType.NAME,
						required: true,
					},
				],
			})
		}
	}, [buildingFilter, tabletSettings])

	// Filter the dropdown options based on current fields
	useEffect(() => {
		if (tabletSetting) {
			setFieldsDropdownOptions(
				allFieldDropdownOptions.filter((option) => {
					return !tabletSetting.signin_fields.some(
						(field) =>
							field.type === option.value &&
							field.type !== SignInFieldType.CUSTOM,
					)
				}),
			)
		}
	}, [allFieldDropdownOptions, tabletSetting])

	return (
		<View className="TabletSettings">
			<Filters>
				<BuildingFilter
					value={buildingFilter}
					onChange={setBuildingFilter}
					hasVisitorManagement={true}
				/>

				{selectedBuilding && (
					<Switch
						onChange={handleSave}
						value={tabletSetting?.enable_sign_in ?? false}
						label={t(
							"desktop.settings.visitors.device_settings.tablet.enable_sign_in",
						)}
						disabled={isLoading || !isLoaded}
					/>
				)}
				<FilterSpace />

				<MultiActionButton
					isSmall
					options={fieldsDropdownOptions}
					onAction={handleOnFieldAdded}
					label={t("desktop.components.multi_action_button.add_field")}
				/>
			</Filters>

			<Space size={1} />

			{!isLoaded && (
				<div className="loading">
					<Loader />
				</div>
			)}

			{isLoaded && tabletSetting && (
				<SortableCardsContainer
					onCardDeleted={handleOnFieldDeleted}
					onCardSorted={handleOnFieldSorted}
				>
					{tabletSetting.signin_fields.map((field, index) => {
						return (
							<VisitorSignInField
								key={index}
								signInField={field}
								onChange={handleFieldChange}
								disabled={field.type === SignInFieldType.NAME ? true : false}
								valid={
									field.type === SignInFieldType.CUSTOM
										? !invalidCustomFields.includes(field.order)
										: true
								}
							/>
						)
					})}
				</SortableCardsContainer>
			)}

			<PageActions>
				<Button
					variant="submit"
					className="save"
					onClick={() => handleSave()}
					isLoading={isSubmitting}
				>
					Save
				</Button>
			</PageActions>
		</View>
	)
}

export default TabletSettings
