import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"

import dayjs, { Dayjs } from "dayjs"
import { useHistory, useLocation, useParams } from "react-router-dom"

import { useFromToCalculator } from "../../hooks/useFromToCalculator"
import { IdAndName } from "../../types/sharedTypes"
import { isRejected } from "@reduxjs/toolkit"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useLazyFetchFloorsQuery } from "../../redux/api/floors"
import { FloorResponse } from "../../redux/floors/types"
import { useAppSelector } from "../../redux/reducers"
import { BuildingRoom, RoomResponse } from "../../redux/rooms/types"
import { TimeslotResponse } from "../../redux/timeslots/types"
import { selectDefaultUserBuilding } from "../../redux/user/selectors"

type BookTypeProps = {
	type: "room" | "desk"
}

type BookScreenProps =
	| "desk"
	| "time"
	| "suggest"
	| "floor"
	| "room"
	| "building"
	| "title"
	| "summary"
	| "done"

type LocationStateProps = {
	id?: string
	date: string
	timeslot: TimeslotResponse
	building: BuildingRoom
	floor?: FloorResponse
	desk?: IdAndName
	room?: RoomResponse
	title?: string
	editing?: boolean
}

type BookContextProps = {
	id: string | undefined
	date: Dayjs
	timeslot: Partial<TimeslotResponse> | null
	building: BuildingRoom | null
	floor: FloorResponse | null
	desk: IdAndName | null
	room: RoomResponse | null
	title: string | undefined
	type: BookTypeProps["type"]
	isShowDateTimePicker: boolean
	editing: boolean
	onChangeTitle: (value: string) => void
	onDeskPick: (desk: IdAndName | null) => void
	onRoomPick: (room: RoomResponse | null) => void
	onDateTimeConfirm: () => void
	onToggleDateTimePicker: () => void
	onAnotherDeskPick: () => void
	onChangeDate: (date: Dayjs) => void
	onDateTimePick: (
		newDate: Dayjs | null,
		newTimeslot: Partial<TimeslotResponse> | null,
	) => void
	onFloorPick: (floor: FloorResponse | null) => void
	onBuildingPick: (building: BuildingRoom | null) => void
	goToScreen: (bookScreen: BookScreenProps, isEditMode?: boolean) => void
	goToHome: () => void
	isBookDesk: boolean
	isBookRoom: boolean
	bookScreenURLs: { [Screen in BookScreenProps]: string }
}

const BookContext = createContext<BookContextProps | null>(null)

export const useBookContext = () => useContext(BookContext) as BookContextProps

export const BookContextProvider = ({ children }: { children: ReactNode }) => {
	const { type } = useParams<BookTypeProps>()
	const { state } = useLocation<LocationStateProps>()

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()
	const [fetchFloors] = useLazyFetchFloorsQuery()

	const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

	const history = useHistory()

	const isBookDesk = useMemo(() => type === "desk", [type])

	const isBookRoom = useMemo(() => type === "room", [type])

	const [id, setId] = useState<string>()

	const initDate = isBookRoom ? dayjs() : dayjs().add(1, "day")
	const [date, setDate] = useState<Dayjs>(initDate)

	const [isShowDateTimePicker, setShowDateTimePicker] = useState<boolean>(true)

	const [timeslot, setTimeslot] = useState<Partial<TimeslotResponse> | null>(
		null,
	)

	const [building, setBuilding] = useState<BuildingRoom | null>(null)
	const [floor, setFloor] = useState<FloorResponse | null>(null)
	const [room, setRoom] = useState<RoomResponse | null>(null)
	const [desk, setDesk] = useState<IdAndName | null>(null)

	const [title, setTitle] = useState<string | undefined>("")

	const [editing, setEditing] = useState<boolean>(false)

	type BookScreenURL = Record<
		BookScreenProps,
		`/book/${typeof type}/${BookScreenProps}`
	>

	const bookScreenURLs: BookScreenURL = useMemo(
		() => ({
			desk: `/book/${type}/desk`,
			time: `/book/${type}/time`,
			suggest: `/book/${type}/suggest`,
			floor: `/book/${type}/floor`,
			room: `/book/${type}/room`,
			building: `/book/${type}/building`,
			title: `/book/${type}/title`,
			summary: `/book/${type}/summary`,
			done: `/book/${type}/done`,
		}),
		[type],
	)

	const goToScreen = (
		bookScreen: BookScreenProps,
		isEditMode: boolean = false,
	) => {
		history.push(bookScreenURLs[bookScreen])
		isEditMode && setEditing(true)
	}

	const goToHome = useCallback(
		() => history.replace("/home/reservations"),
		[history],
	)

	const getLocationFloors = async (building: string) => {
		const floorResponse = await fetchFloors({
			building,
		})
		if (!isRejected(floorResponse)) {
			return floorResponse.data?.results ?? []
		}
		return []
	}

	const handleChangeDate = (date: Dayjs) => setDate(date)

	const { defaultFrom, defaultTo } = useFromToCalculator()

	const handleDateTimePick = (
		newDate: Dayjs | null,
		newTimeslot: Partial<TimeslotResponse> | null,
	) => {
		setShowDateTimePicker(false)

		if (newDate !== null) {
			setDate(newDate!)
		}

		if (newTimeslot !== null) {
			setTimeslot(newTimeslot)
		}

		if (newDate === null && newTimeslot === null) {
			if (!editing) {
				setShowDateTimePicker(false)

				/**
				 * Timeslot will never be set if the user doesn't click Confirm.
				 * We make sure to select at least something in this case.
				 * */
				if (!timeslot) {
					setTimeslot({ from: defaultFrom, to: defaultTo })
				}
			}
		}
	}

	const handleDeskPick = (desk: IdAndName | null) => {
		if (desk !== null) {
			setDesk(desk)

			goToScreen("summary")
		} else {
			goToHome()
		}
	}

	const handleAnotherDeskPick = () => {
		setShowDateTimePicker(true)

		goToScreen("time")
	}

	const handleFloorPick = (floor: FloorResponse | null) => {
		if (floor !== null) {
			setFloor(floor)

			goToScreen("desk")
		} else {
			goToHome()
		}
	}

	const handleRoomPick = (room: RoomResponse | null) => {
		if (room !== null) {
			setRoom(room)

			goToScreen(editing ? "summary" : "title")
		} else {
			goToHome()
		}
	}

	const handleBuildingPick = async (building: BuildingRoom | null) => {
		if (building !== null) {
			setBuilding(building)

			if (type === "room") {
				goToScreen("room")
			} else {
				const floors = await getLocationFloors(building.id)

				if (floors.length === 1) {
					setFloor(floors[0])

					goToScreen("desk")
				} else {
					goToScreen("floor")
				}
			}
		} else {
			goToHome()
		}
	}

	const handleDateTimeConfirm = async () => {
		if (editing) {
			goToScreen("summary")
		} else {
			if (isBookDesk && (defaultUserBuilding?.id || buildings.length === 1)) {
				let floors: FloorResponse[]

				if (defaultUserBuilding?.id) {
					setBuilding(defaultUserBuilding)
					floors = await getLocationFloors(defaultUserBuilding.id)
				} else {
					setBuilding(buildings[0])
					floors = await getLocationFloors(buildings[0].id)
				}

				if (floors.length === 1) {
					setFloor(floors[0])

					goToScreen("desk")
				} else {
					goToScreen("floor")
				}
			} else {
				goToScreen("building")
			}
		}
	}

	const handleToggleDateTimePicker = () =>
		setShowDateTimePicker((prev) => !prev)

	const handleChangeTitle = (value: string) => setTitle(value)

	useEffect(() => {
		if (state) {
			if (state.id) {
				setId(state.id)
			}

			if (state.date) {
				const date = dayjs(state.date)
				if (date.isValid()) {
					setDate(date)
				}
			}

			setTimeslot(state.timeslot)
			setBuilding(state.building)

			if (isBookRoom) {
				state?.room && setRoom(state.room)
				setTitle(state.title)
			} else {
				state?.floor && setFloor(state.floor)
				state?.desk && setDesk(state.desk)
			}
		}
	}, [isBookRoom, state, type])

	return (
		<BookContext.Provider
			value={{
				id,
				date,
				timeslot,
				building,
				floor,
				desk,
				room,
				title,
				type,
				isShowDateTimePicker,
				editing,
				onChangeTitle: handleChangeTitle,
				onDeskPick: handleDeskPick,
				onAnotherDeskPick: handleAnotherDeskPick,
				onRoomPick: handleRoomPick,
				onDateTimeConfirm: handleDateTimeConfirm,
				onToggleDateTimePicker: handleToggleDateTimePicker,
				onChangeDate: handleChangeDate,
				onDateTimePick: handleDateTimePick,
				onFloorPick: handleFloorPick,
				onBuildingPick: handleBuildingPick,
				goToScreen,
				goToHome,
				isBookDesk,
				isBookRoom,
				bookScreenURLs,
			}}
		>
			{children}
		</BookContext.Provider>
	)
}
