import React from "react"

import classNames from "classnames"

import { Children } from "../../../types/sharedTypes"

import "./style.sass"

export type CardProps = {
	children: Children
	header?: Children
	actions?: Children
	className?: string
}

const Card = ({ header, children, actions, className }: CardProps) => {
	const cn = classNames("Card", className)
	return (
		<div className={cn}>
			{header && <div className="card-header">{header}</div>}

			<div className="card-content">
				<div className="card-content-body">{children}</div>
				{actions && <div className="card-actions">{actions}</div>}
			</div>
		</div>
	)
}

export default Card
