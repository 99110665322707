import React, { useState } from "react"

import BuildingModal from "../../modals/BuildingModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { BuildingResponse } from "../../redux/buildings/types"
import { useAppSelector } from "../../redux/reducers"
import { selectUserPermissions } from "../../redux/user/selectors"

import Card from "../../components/Card"
import FilterSpace from "../../components/Filter/FilterSpace"
import Filters from "../../components/Filter/Filters"
import SearchFilter from "../../components/Filter/SearchFilter"
import Heading from "../../components/Heading"
import OccupancyInfo from "../../components/OccupancyInfo"
import Space from "../../components/Space"
import View from "../../components/View"
import GoogleMap from "../../components/advanced/GoogleMap"
import Button from "../../components/basic/Button"
import Loader from "../../components/basic/Loader"

import "./Buildings.sass"

type BuildingProps = {
	building: BuildingResponse
}

function Building({ building }: BuildingProps) {
	const { openModal } = useModals()

	const permissions = useAppSelector(selectUserPermissions)

	const canEditBuilding = permissions.includes(
		"portal.locations.change_building",
	)

	function handleEditClick() {
		openModal(BuildingModal, { building })
	}

	/*
		Sometimes lat and long are saved as null, sometimes as 0. If it's
		0, then ternary return 0 and we get 0 in JSX, which is wrong.
	*/
	let doesLatAndLongExistAndNotNull = false

	if (building.longitude && building.latitude) {
		doesLatAndLongExistAndNotNull = true
	}

	return (
		<Card>
			<div className="building">
				<div className="main">
					<div className="data">
						<div className="primary">{building.name}</div>
						<div className="secondary">{building.address}</div>
					</div>

					{canEditBuilding && (
						<div className="actions">
							<Button onClick={() => handleEditClick()} variant="secondary">
								Edit
							</Button>
						</div>
					)}
				</div>
				{doesLatAndLongExistAndNotNull && (
					<GoogleMap
						className="map"
						position={{ lat: building.latitude!, lng: building.longitude! }}
					/>
				)}
				<div className="statistic">
					<div className="info">
						<div className="number">{building.floors_count || 0}</div>
						<div className="name">Floors</div>
					</div>
					<div className="info">
						<div className="number">{building.departments_count || 0}</div>
						<div className="name">Departments</div>
					</div>
					<div className="info">
						<div className="number">{building.desks_count || 0}</div>
						<div className="name">Desks</div>
					</div>
					<div className="info">
						<div className="number">{building.rooms_count || 0}</div>
						<div className="name">Rooms</div>
					</div>
					<div className="info">
						<div className="number">{building.employees_count || 0}</div>
						<div className="name">Employees</div>
					</div>
				</div>
				{building &&
					building.settings &&
					(building.settings.desk_capacity_limit ||
						building.settings.desk_capacity_limit === 0) && (
						<OccupancyInfo type="Building" building={building} />
					)}
			</div>
		</Card>
	)
}

function SettingsBuildings() {
	const [search, setSearch] = useState<string>("")
	const { openModal } = useModals()

	const {
		data: { results: buildings = [] } = {},
		isSuccess: isLoaded,
		isFetching: isLoading,
	} = useFetchBuildingsQuery({
		stats: true,
		// remove search from query if empty this ensures that the query is not called with search=""
		...(search && { search }),
	})

	const permissions = useAppSelector(selectUserPermissions)

	const canAddBuilding = permissions.includes("portal.locations.add_building")

	function handleNewClick() {
		openModal(BuildingModal, { building: null })
	}

	return (
		<View className="SettingsBuildings SettingsPage">
			<Heading>Buildings</Heading>

			<Space size={0.75} />

			<Filters>
				<SearchFilter
					value={search}
					onChange={setSearch}
					placeholder="Filter buildings"
				/>
				{isLoading && <Loader size="small" />}

				<FilterSpace />
				{canAddBuilding && (
					<Button onClick={handleNewClick} isSmall>
						Add building
					</Button>
				)}
			</Filters>

			<Space size={0.75} />

			{isLoaded && buildings.length > 0 ? (
				buildings.map((building: BuildingResponse, i: number) => (
					<Building key={building.id} building={building} />
				))
			) : (
				<Card className="small-font">No buildings found.</Card>
			)}
		</View>
	)
}

export default SettingsBuildings
