import React, { useCallback, useMemo, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { useBackendPagination } from "../../../../hooks/useBackendPagination"
import { useToast } from "../../../../hooks/useToast"
import { ENTRIES_PER_PAGE } from "../../../Manage/constants"
import { ROOMS_PATHS } from "../constants"

import {
	useFetchRoomsQuery,
	useStartScanAndCheckRoomsMutation,
} from "../../../../redux/api/rooms"
import { RoomResponse } from "../../../../redux/api/rooms/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { selectAutoScanRooms } from "../../../../redux/autoScanRooms/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import Filters from "../../../../components/Filter/Filters"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import Pagination from "../../../../components/Pagination"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import Table from "../../../../components/advanced/Table"
import Button from "../../../../components/basic/Button"
import { Column } from "../../../../components/basic/Table"

import { ReactComponent as RoomSVG } from "../../../../assets/images/icons/Room.svg"

import "./styles.sass"

type DataEmptyTableProps = {
	onScanRooms: () => void
}

const DataEmptyTableRows = ({ onScanRooms }: DataEmptyTableProps) => {
	const { t } = useTranslation()

	const { syncInProgress } = useAppSelector(selectAutoScanRooms)

	return (
		<div className="TableNoView">
			<div className="TableNoViewTop">
				<RoomSVG />
				<p>{t("desktop.settings.rooms.rooms.no_rooms.title")}</p>
			</div>
			<div className="TableNoViewBottom">
				<Trans i18nKey="desktop.settings.rooms.rooms.no_rooms.description">
					<Button
						className="TableNoViewBottomButton"
						variant="link"
						to={ROOMS_PATHS.addRoom}
					>
						add rooms manually
					</Button>

					<Button
						className="TableNoViewBottomButton"
						variant="link"
						onClick={onScanRooms}
						isDisabled={syncInProgress}
					>
						import automatically
					</Button>
				</Trans>
			</div>
		</div>
	)
}
const RoomList = () => {
	const { errorToast } = useToast()

	const history = useHistory()
	const { t } = useTranslation()

	const { search } = useLocation()

	const query = useMemo(() => new URLSearchParams(search), [search])

	const { syncInProgress } = useAppSelector(selectAutoScanRooms)

	const [page, setCurrentPage] = useState(1)

	// Filters
	const [buildingFilter, setBuildingFilter] = useState<string>(
		query.get("building") || FilterSpecialValues.ALL,
	)

	const [floorFilter, setFloorFilter] = useState<string>(
		query.get("floor") || FilterSpecialValues.ALL,
	)

	const [searchFilter, setSearchFilter] = useState<string>("")

	const [selectedRows, setSelectedRows] = useState<RoomResponse[]>([])

	const [startScanAndCheckRoomsQuery] = useStartScanAndCheckRoomsMutation()

	const {
		data: { results: rooms = [], count = 0, offset = 0 } = {},
		isFetching: isLoading,
		refetch: refetchRooms,
	} = useFetchRoomsQuery({
		building: buildingFilter,
		floor: floorFilter,
		search: searchFilter,
		offset: (page - 1) * ENTRIES_PER_PAGE,
		limit: ENTRIES_PER_PAGE,
	})

	const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
		useBackendPagination({
			offset,
			totalNumberOfItems: count,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 7,
			maxTrailingLinks: 2,
		})

	const setPage = useCallback(
		(nextPage: number) => {
			setCurrentPage(nextPage)
			refetchRooms()
		},
		[refetchRooms],
	)

	const handleSelectRow = (d: RoomResponse[]) => setSelectedRows(d)

	const tableColumns = useMemo<Column<RoomResponse>[]>(
		() => [
			{
				field: "name",
				label: t("desktop.settings.rooms.rooms.table.name"),
				renderCell: ({ name }) => name ?? "-",
			},
			{
				field: "building",
				label: t("desktop.settings.rooms.rooms.table.building"),
				renderCell: ({ building }) => building?.name ?? "-",
			},
			{
				field: "floor",
				label: t("desktop.settings.rooms.rooms.table.floor"),
				renderCell: ({ floor }) => floor?.name ?? "-",
			},
		],
		[t],
	)

	const handleRowClick = (r: RoomResponse) => {
		history.push(`${ROOMS_PATHS.room(r.key)}`)
	}

	const handleScanRooms = async () => {
		const response = await startScanAndCheckRoomsQuery()

		if (isRejected(response)) {
			const { error } = response

			if (isApiResponseError(error)) {
				errorToast(error.message)
			}
			return
		}
	}

	return (
		<View className="SettingsRooms">
			<Breadcrumbs depth={2} includeParamsAsPath />

			<Intro>
				<div className="description">
					{t("desktop.settings.rooms.rooms.intro")}
				</div>
				<div className="buttons">
					<Button
						isSmall
						variant="secondary-white"
						onClick={handleScanRooms}
						isLoading={syncInProgress}
						disabled={syncInProgress}
					>
						{t("desktop.settings.rooms.rooms.autoscan_rooms")}
					</Button>
					<Button to={ROOMS_PATHS.addRoom} isSmall>
						{t("desktop.settings.rooms.rooms.add_room")}
					</Button>
				</div>
			</Intro>

			<Space size={0.75} />

			<Filters>
				<BuildingFilter
					onChange={setBuildingFilter}
					value={buildingFilter}
					showAll
				/>
				<FloorFilter
					onChange={setFloorFilter}
					value={floorFilter}
					buildingId={buildingFilter}
					showAll
				/>
				<SearchFilter
					placeholder={t("desktop.settings.rooms.rooms.search_rooms")}
					onChange={setSearchFilter}
					value={searchFilter}
				/>
			</Filters>

			<Space size={0.75} />

			<Table
				isSelectable
				loading={isLoading}
				rows={rooms}
				calcRowNumber={calcRowNumber}
				columns={tableColumns}
				onSelectedRows={handleSelectRow}
				emptyTableCell={<DataEmptyTableRows onScanRooms={handleScanRooms} />}
				onRowClick={handleRowClick}
				pagination={
					paginationLinks.length > 1 ? (
						<Pagination
							links={paginationLinks}
							setPage={setPage}
							onPrevious={() => setPage(page - 1)}
							onNext={() => setPage(page + 1)}
							hasNext={hasNext}
							hasPrevious={hasPrevious}
							from={from}
							to={to}
							total={count}
							items={t("desktop.settings.rooms.title", {
								count,
							})}
						/>
					) : undefined
				}
			/>
		</View>
	)
}

export default RoomList
