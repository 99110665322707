import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { fetchVisitorSettings } from "../../../redux/visitor_settings/visitorSettingsSlice"

import Breadcrumbs from "../../../components/Breadcrumbs"
import InviteEditForm from "../../../components/Form/Visitors/InviteEditForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"
import CsvInvite from "../../../components/Visitors/CsvInvite"
import Loader from "../../../components/basic/Loader"
import MultiToggle from "../../../components/basic/MultiToggle"

import "./InviteAdd.sass"

enum InviteTabType {
	SINGLE = "SINGLE",
	CSV = "CSV",
}

const InviteAdd = () => {
	const { t } = useTranslation()

	const [tab, setTab] = useState(InviteTabType.SINGLE)

	const { isLoading: settingsLoading } = useAppSelector(selectVisitorSettings)

	const actions = useActions({
		fetchVisitorSettings: () => fetchVisitorSettings(),
	})

	useEffect(() => {
		actions.fetchVisitorSettings()
	}, [actions])

	return (
		<View className="InviteAdd">
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.manage.visitors.invite.invites"),
					t("desktop.manage.visitors.invite.new_invite"),
				]}
			/>

			<Intro>{t("desktop.manage.visitors.invite.intro")}</Intro>

			<Space size={1} />

			{
				<MultiToggle
					options={[
						{
							label: t("desktop.manage.visitors.invite.tabs.single"),
							value: InviteTabType.SINGLE,
						},
						{
							label: t("desktop.manage.visitors.invite.tabs.csv"),
							value: InviteTabType.CSV,
						},
					]}
					onChange={setTab}
					value={tab}
				/>
			}

			<Space size={0.5} />

			{settingsLoading ? (
				<div className="loading">
					<Loader />
				</div>
			) : tab === InviteTabType.SINGLE ? (
				<InviteEditForm />
			) : (
				<CsvInvite />
			)}
		</View>
	)
}
export default InviteAdd
