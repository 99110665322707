import { useCallback } from "react"

import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../hooks/useToast"
import Field from "../../Field"
import { FilterSpecialValues } from "../../Filter/types"
import { DatePicker } from "../../basic/DatePicker"
import ModalForm from "../ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { exportInvites } from "../../../redux/invites/invitesSlice"
import { FetchOptions } from "../../../redux/invites/types"
import { useActions } from "../../../redux/utils"
import { RangeType } from "../../../redux/visitors/types"

type Props = {
	range: RangeType
	buildingId?: string
	search?: string
	show?: string
}

type FormValues = {
	start: Date | string
	end: Date | string
}

const InviteExportForm = ({ range, buildingId, search, show }: Props) => {
	const { t } = useTranslation()
	const { closeModal } = useModals()
	const { errorToast } = useToast()

	const { from, to } = range

	const actions = useActions({
		exportInvites: (params: FetchOptions) => exportInvites(params),
	})

	const methods = useForm<FormValues>({
		defaultValues: {
			start: from.toDate() ?? dayjs().toDate(),
			end: to.toDate() ?? dayjs().add(7, "day").toDate(),
		},
	})

	const { control } = methods

	const onSubmit = useCallback(
		async ({ start, end }: FormValues) => {
			const response = await actions.exportInvites({
				building_id:
					buildingId === FilterSpecialValues.ALL ? undefined : buildingId,
				show: show,
				start: dayjs(start).startOf("day").toISOString(),
				end: dayjs(end).endOf("day").toISOString(),
				search,
			})

			if (exportInvites.fulfilled.match(response)) {
				const blob = new Blob([response.payload], {
					type: "text/plain;charset=utf-8",
				})

				const startStr = dayjs(start).format("YYYY-MM-DD")
				const endStr = dayjs(end).format("YYYY-MM-DD")

				await saveAs(
					blob,
					`${t(
						"desktop.manage.visitors.export.invites",
					)} ${startStr} - ${endStr}.csv`,
				)

				closeModal()
			} else {
				errorToast(response.error.message)
			}
		},
		[actions, t, closeModal, errorToast, buildingId, search, show],
	)

	return (
		<FormProvider {...methods}>
			<ModalForm
				className="InviteExportForm"
				updateMode={true}
				title={t("desktop.manage.visitors.export.download_csv_report")}
				onUpdate={onSubmit}
			>
				<Field
					control={control}
					name="start"
					label={t("desktop.manage.visitors.export.from")}
					className="field-width-50"
				>
					{(props) => (
						<DatePicker {...props} showMonthDropdown showYearDropdown />
					)}
				</Field>
				<Field
					control={control}
					name="end"
					label={t("desktop.manage.visitors.export.to")}
					className="field-width-50"
				>
					{(props) => (
						<DatePicker {...props} showMonthDropdown showYearDropdown />
					)}
				</Field>
			</ModalForm>
		</FormProvider>
	)
}

export default InviteExportForm
