import React, { FC } from "react"

import { useTranslation } from "react-i18next"

const ShareableFloorPlanError: FC = () => {
	const { t } = useTranslation()
	return (
		<div className="error-text">
			{t("desktop.shareable.shareable_error_text_row1")}
			<br />
			{t("desktop.shareable.shareable_error_text_row2")}
			<span className="strong">
				{" "}
				{t("desktop.shareable.shareable_error_text_strong")}
			</span>
		</div>
	)
}
export default ShareableFloorPlanError
