import { ThunkApiConfig } from "RootType"

import { checkInsURL, get } from "../../api"
import { timeZone } from "../../dayjs"
import {
	PaginationState,
	SliceState,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { CheckInResponse, CheckInsResponse, FetchOptions } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const ENTRIES_PER_PAGE = 10

const FETCH_DEFAULTS = {
	mode: "open",
	tz: timeZone,
	limit: ENTRIES_PER_PAGE,
	offset: 0,
}

/**
 *  Thunks
 */
export const fetchCheckIns = createAsyncThunk<
	CheckInsResponse,
	FetchOptions | undefined,
	ThunkApiConfig
>("checkIns/fetch", async (options, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(
		checkInsURL({ ...FETCH_DEFAULTS, ...(options ?? {}) }),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface CheckInsState extends SliceState, PaginationState {
	entries: CheckInResponse[]
}

const initialState: CheckInsState = {
	entries: [],
	...sliceInitialState,
	...paginationInitialState,
}

const checkInsSlice = createSlice({
	name: "checkIns",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCheckIns.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchCheckIns.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchCheckIns.fulfilled, (state, { payload }) => {
			const { results, ...paginationData } = payload
			state = {
				...state,
				...paginationData,
				entries: results,
			}
			setFetchSuccessState(state)

			return state
		})
	},
})

export const checkInsReducer = checkInsSlice.reducer
