import { Middleware, MiddlewareAPI } from "redux"

import { isSentryActive, logBreadcrumb } from "../analytics"
import { parseApiError } from "../api/apiUtils"
import { isRejectedWithValue } from "@reduxjs/toolkit"
import { captureMessage } from "@sentry/react"

export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
		if (isRejectedWithValue(action)) {
			if (action.type.includes("executeMutation")) {
				console.log("executeMutation action", action.payload, action.meta)
			}
		}

		return next(action)
	}

type ParsedReduxError = {
	message?: string
}

const mappedError = (error: string): string => {
	const normalizedError = error.toLowerCase()
	if (normalizedError.includes("not found")) {
		return "Requested resource was not found."
	}

	return error
}

export const sentryMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		try {
			if (!isSentryActive || !isRejectedWithValue(action)) {
				return next(action)
			}

			const apiError = parseApiError(action.payload) as ParsedReduxError

			if (!apiError) {
				return next(action)
			}

			logBreadcrumb("Redux action failed (middleware)")

			if (apiError?.message) {
				logBreadcrumb(`Error: ${apiError.message}`)
				captureMessage(mappedError(apiError.message))
				return next(action)
			}

			if (typeof action.payload === "string") {
				logBreadcrumb(`Error: ${action.payload}`)
				captureMessage(mappedError(action.payload))
				return next(action)
			}

			for (const key in action.payload) {
				const value = action.payload[key]
				logBreadcrumb(`Error: ${key} - ${value}`)
			}

			if (Object.keys(action.payload).length === 0) {
				apiError.message = "An unknown error occurred"
			} else {
				apiError.message = Object.values(action.payload)[0] as string
			}

			captureMessage(apiError.message)

			return next(action)
		} catch (error) {
			console.error("Sentry middleware error", error)
			return next(action)
		}
	}
