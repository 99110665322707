import { forwardRef, useCallback } from "react"

import classNames from "classnames"
import ReactQuill, { Quill, ReactQuillProps } from "react-quill"

import {
	boldIcon,
	centerIcon,
	clearIcon,
	imageIcon,
	italicIcon,
	justifyIcon,
	leftIcon,
	rightIcon,
	strikeIcon,
	underlineIcon,
	videoIcon,
} from "./icons"
import "react-quill/dist/quill.snow.css"

import "./style.sass"

type CommonProps = {
	className?: string
	disabled?: boolean
	onChange?: (value: string) => void
	hasError?: boolean
}

type ConditionalProps = ReactQuillProps

export type RichTextProps = CommonProps &
	Omit<ConditionalProps, "onChange"> & {
		allowVideoEmbedding?: boolean
	}

const fontSizes = [
	"12px",
	"14px",
	"16px",
	"18px",
	"20px",
	"24px",
	"28px",
	"34px",
	"48px",
]

export const RichText = forwardRef<ReactQuill, RichTextProps>(
	(
		{
			className,
			disabled,
			onChange,
			hasError,
			allowVideoEmbedding = false,
			...props
		},
		ref,
	) => {
		const innerOnChange = useCallback(
			(value: string) => {
				onChange && onChange(value)
			},
			[onChange],
		)

		const FontSize = Quill.import("attributors/style/size")
		FontSize.whitelist = fontSizes
		Quill.register(FontSize, true)

		const icons = Quill.import("ui/icons")

		icons["align"]["right"] = rightIcon
		icons["align"][""] = leftIcon
		icons["align"]["center"] = centerIcon
		icons["align"]["justify"] = justifyIcon
		icons["bold"] = boldIcon
		icons["italic"] = italicIcon
		icons["underline"] = underlineIcon
		icons["strike"] = strikeIcon
		icons["clean"] = clearIcon
		icons["image"] = imageIcon
		icons["video"] = videoIcon

		return (
			<div
				className={classNames([
					{
						disabled: !!disabled,
						error: hasError,
					},
					"RichText",
					className,
				])}
			>
				<ReactQuill
					ref={ref}
					{...(props as ReactQuillProps)}
					theme="snow"
					key={allowVideoEmbedding ? "video" : "no-video"}
					modules={{
						toolbar: {
							container: [
								[
									{ header: [1, 2, 3, 4, 5, 6, false] },
									{ size: FontSize.whitelist },
								],
								[
									{ align: "" },
									{ align: "center" },
									{ align: "right" },
									{ align: "justify" },
									"bold",
									"italic",
									"underline",
									"strike",
									"clean",
									"image",
									...(allowVideoEmbedding ? ["video"] : []),
								],
							],
						},
					}}
					readOnly={disabled}
					onChange={innerOnChange}
				/>
			</div>
		)
	},
)
