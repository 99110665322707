import React from "react"

import { useTranslation } from "react-i18next"

import { useRedirectTimeout } from "../../../hooks/useRedirectTimeout"
import { VISITOR_BASE_URL } from "../Visitors"

import VisitorContent from "../../../components/Visitors/VisitorContent"
import VisitorView from "../../../components/Visitors/VisitorView"

import { ReactComponent as TaskAltSVG } from "../../../assets/images/icons/TaskAlt.svg"

const Confirmation = () => {
	const { t } = useTranslation()

	useRedirectTimeout(VISITOR_BASE_URL, 5000)

	return (
		<VisitorView>
			<VisitorContent>
				<TaskAltSVG />
				<div className="title">
					{t("tablet.visitors.onboarding.screens.confirmation.all_set")}
				</div>
				<div className="subheading">
					<div className="info">
						{t(
							"tablet.visitors.onboarding.screens.confirmation.redirected_to_joan_sign_in",
						)}
					</div>
				</div>
			</VisitorContent>
		</VisitorView>
	)
}

export default Confirmation
