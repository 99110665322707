import { MutableRefObject, useEffect, useState } from "react"

type UseStartEndIndexInViewProps = {
	elements: (HTMLDivElement | null)[]
	container: MutableRefObject<HTMLDivElement | null>
	deps?: any[]
	topMargin?: number
	bottomMargin?: number
}
type StartEndIndexes = { start: number; end: number }
/*
  The useStartEndIndexInView returns start and end index of elements that are in the visible in container
  if there is no element visible the returned values will be -1 and when all the elements are in visible in container the end will be -1
  the deps is used to trigger the calculation if additional trigger is needed
*/
export const useStartEndIndexInView = ({
	elements,
	container,
	deps,
	topMargin = 0,
	bottomMargin = 0,
}: UseStartEndIndexInViewProps): StartEndIndexes => {
	const [startEnd, setStartEnd] = useState<StartEndIndexes>({
		start: -1,
		end: -1,
	})

	useEffect(
		() => {
			const { bottom = 0, top = 0 } =
				container.current?.getBoundingClientRect() ?? {}
			const containerTop = top + topMargin
			const containerBottom = bottom - bottomMargin
			let start = -1
			let end = -1

			elements.forEach((e, i) => {
				if (start < 0) {
					const { bottom: elementBottom = 0, top: elementTop = 0 } =
						e?.getBoundingClientRect() ?? {}
					if (elementTop >= containerTop && elementBottom <= containerBottom) {
						start = i
					}
				} else if (end < 0) {
					const { bottom: eBottom = 0 } = e?.getBoundingClientRect() ?? {}
					if (eBottom > containerBottom) {
						end = i
					}
				}
			})
			setStartEnd({ start, end })
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps
			? [container, elements, topMargin, bottomMargin, ...deps]
			: [container, elements, topMargin, bottomMargin, deps],
	)

	return startEnd
}
