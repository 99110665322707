import {
	APP_TYPES,
	COMPATIBLE_APPS,
	DEVICE_GTINS,
	DEVICE_SIZES,
	DISPLAY,
	JOAN_6_PRO_GTINS,
	JOAN_6_RE_GTINS,
	NINE_INCH,
	SIX_INCH,
	TABLET,
	THIRTEEN_INCH,
	THIRTY_TWO_INCH,
} from "../constants"

export type DeviceAppInterface = {
	value: number
	image: string
	imageWl: string
	isInJoanLabs: boolean
}

export const findDevice = (type: string) => {
	switch (type) {
		case DEVICE_SIZES.joan_6:
			return SIX_INCH
		case DEVICE_SIZES.joan_9:
			return NINE_INCH
		case DEVICE_SIZES.joan_13:
			return THIRTEEN_INCH
		case DEVICE_SIZES.joan_32:
			return THIRTY_TWO_INCH
		case DEVICE_SIZES.joan_on_display:
			return DISPLAY
		case DEVICE_SIZES.joan_on_tablets:
			return TABLET
		default:
			return SIX_INCH
	}
}

/**
 * Function fill find a supported application (functionality) specified
 * by app parameter and return the result. If application does not exist,
 * function will return null.
 *
 * @param {Number} app ID of the application (alias for interface)
 * @param {Number} deviceSize device size (negative for JoT and JoD)
 */
export const findAppType = (app: number, deviceSize: number) => {
	if (app === 0 && deviceSize < 0) {
		const compatibleApps = COMPATIBLE_APPS[deviceSize] || []
		return compatibleApps[0]
	}

	return Object.values(APP_TYPES).find((o) => o.value === app)
}

/**
 * Function will check if transition can be made from current app to destination app.
 */
export const isAppCompatible = (
	currentApp: number,
	desiredApp: any,
	deviceSize: number,
	provider: string | null,
): boolean => {
	let compatibleApps = COMPATIBLE_APPS[deviceSize] || []

	// Joan Home and Joan Sign interfaces are a special case. Any device that is detected as such
	// can only show Joan Home/Joan Sign interface (cannot transition to other). And also, no other
	// interface can transition into Joan Home/Joan Sign.
	//
	// See JOAN-1815
	if (currentApp === APP_TYPES.JOAN_HOME.value) {
		// If current app is Joan Home, then force compatible apps to only include Joan Home
		compatibleApps = [APP_TYPES.JOAN_HOME]
	} else if (currentApp === APP_TYPES.JOAN_SIGN.value) {
		// If current app is Joan Sign, then force compatible apps to only include Joan Sign
		compatibleApps = [APP_TYPES.JOAN_SIGN]
	} else {
		// For any other apps we remove Joan Home and Joan Sign from compatibility list
		compatibleApps = compatibleApps.filter((o) => {
			return (
				o.value !== APP_TYPES.JOAN_HOME.value &&
				o.value !== APP_TYPES.JOAN_SIGN.value
			)
		})
	}

	/*
    https://futurevisions.atlassian.net/browse/JOAN-3316
    This is Optoma specific - we don't support Status board there
    so simply filter it out.
  */

	if (isOptomaDevice(provider)) {
		compatibleApps = compatibleApps.filter((deviceAppInterface) => {
			return deviceAppInterface.value !== APP_TYPES.STATUS_BOARD.value
		})
	}

	return deviceSize && desiredApp && compatibleApps.indexOf(desiredApp) > -1
}

/**
 * Function will return true if application belongs to ScheduleBoard group
 * and false otherwise.
 *
 * @param {Number} app ID of the application (alias for interface)
 */
export const isScheduleBoardApp = (app: number) => {
	return APP_TYPES.PREMIUM.value === app || APP_TYPES.CLASSROOM.value === app
}

export const isOptomaDevice = (provider: string | null) => {
	if (provider) {
		if (typeof provider !== "string") return false
		return provider.toUpperCase() === "OPTOMA"
	}

	return false
}

export const isAppAvailable = (
	isLabsEnabled = false,
	app: DeviceAppInterface,
) => {
	if (isLabsEnabled) return true

	return !app.isInJoanLabs
}

export const isTablet = (size: number) => TABLET.size === size

export const isJoanSign = (value: number) => APP_TYPES.JOAN_SIGN.value === value

export const isJoan6Pro = (gtin: string) => JOAN_6_PRO_GTINS.includes(gtin)

export const isJoan6RE = (gtin: string) => JOAN_6_RE_GTINS.includes(gtin)

export const convertGtinToColor = (gtin: string) => {
	if (
		[
			DEVICE_GTINS.PLACE_AND_PLAY_32_DEV_KIT_GRAPHITE_BLACK,
			DEVICE_GTINS.ASSISTANT_GRAPHITE_BLACK,
			DEVICE_GTINS.BOARD_13_BLACK,
			DEVICE_GTINS.CLASSROOM_13_BLACK,
			DEVICE_GTINS.EXECUTIVE_GRAPHITE_BLACK,
			DEVICE_GTINS.HOME_GRAPHITE_BLACK,
			DEVICE_GTINS.HOME_GRAPHITE_BLACK_REDPINE,
			DEVICE_GTINS.JOAN_13_GRAPHITE_BLACK,
			DEVICE_GTINS.JOAN_9_GRAPHITE_BLACK,
			DEVICE_GTINS.MANAGER_GRAPHITE_BLACK,
			DEVICE_GTINS.PARKING_13_BLACK,
			DEVICE_GTINS.PLACE_AND_PLAY_13_BLACK_EAP,
			DEVICE_GTINS.PLACE_AND_PLAY_13_GRAPHITE_BLACK,
			DEVICE_GTINS.PLACE_AND_PLAY_32_GRAPHITE_BLACK,
			DEVICE_GTINS.PREMIUM_13_BLACK,
			DEVICE_GTINS.UNIVERSAL_GRAPHITE_BLACK,
			DEVICE_GTINS.JOAN_6_PRO_GRAPHITE_BLACK,
			DEVICE_GTINS.JOAN_6_RE_GRAPHITE_BLACK,
		].includes(gtin)
	) {
		return "black"
	}

	return "white"
}
