import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"
import ReactSelect, {
	ActionMeta,
	GetOptionLabel,
	GetOptionValue,
	GroupBase,
	OnChangeValue,
	OptionsOrGroups,
	Theme,
} from "react-select"

import SelectType from "react-select/dist/declarations/src/Select"

import "./style.sass"

export type SelectProps<
	TOption extends unknown = unknown,
	TIsMulti extends boolean = false,
	TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = {
	placeholder?: string
	nothingFoundMessage?: string
	getOptionLabel?: GetOptionLabel<TOption>
	getOptionValue?: GetOptionValue<TOption>
	className?: string
	id?: string
	isMulti?: TIsMulti
	value?: OnChangeValue<TOption, TIsMulti>
	onChange?: (
		newValue: OnChangeValue<TOption, TIsMulti>,
		actionMeta: ActionMeta<TOption>,
	) => void
	options: OptionsOrGroups<TOption, TGroup>
	disabled?: boolean
	clearable?: boolean
	hasError?: boolean
}

const SelectInternal = <TOption, TIsMulti extends boolean>(
	{
		placeholder,
		nothingFoundMessage,
		getOptionLabel,
		getOptionValue,
		className,
		onChange,
		value,
		isMulti,
		id,
		options,
		disabled = false,
		clearable = false,
		hasError,
	}: SelectProps<TOption, TIsMulti>,
	ref: React.ForwardedRef<SelectType<TOption, TIsMulti>>,
) => {
	const cn = classNames("Select", className, {
		disabled,
		error: hasError,
	})

	return (
		<ReactSelect
			ref={ref}
			className={cn}
			placeholder={placeholder}
			options={options}
			classNamePrefix="select-input"
			isMulti={isMulti}
			getOptionLabel={getOptionLabel}
			getOptionValue={getOptionValue}
			noOptionsMessage={({ inputValue }) => {
				if (!inputValue.trim().length) return null
				return nothingFoundMessage
			}}
			value={value}
			onChange={onChange}
			theme={selectTheme}
			id={id}
			closeMenuOnSelect
			blurInputOnSelect
			menuPortalTarget={document.body}
			menuPosition="absolute"
			isDisabled={disabled}
			isClearable={clearable}
		/>
	)
}

const selectTheme = (theme: Theme) => ({
	...theme,
	borderRadius: 0,
	colors: {
		...theme.colors,
		primary25: "#F6F6F6",
		primary50: "#E8E8E8",
		primary: "#4205DD",
	},
})

/**
 * For Select to be typed correctly provide type to the component
 * @example
 *   <Select {...props} /> //normal select
 *   <Select isMulti {...props} /> // multi select
 *
 * @param {OptionsOrGroups} options - Array of options
 * @param [placeholder] - Placeholder text for the input component
 * @param [getOptionLabel] - Function that returns the label for options
 * @param [getOptionValue] - Function that returns the value for options
 * @param {string} [nothingFoundMessage]  - The text for nothing found message
 * @param	{string} [className] - Additional class names
 * @param {string} [id] - HTML id passed down to the input element
 * @param {boolean} [isMulti] - Changes behavior of the select from normal (false) to multi select (true)
 * @param {TOption} [value] - Value of the selected option
 * @param {function} [onChange] - Function is triggered on change
 * @param {boolean} [disabled] - Disables input
 * @param {boolean} [clearable] - Can clear selected value
 */

export const Select = forwardRef(SelectInternal) as <
	TOption,
	TIsMulti extends boolean = false,
>(
	props: SelectProps<TOption, TIsMulti> & {
		ref?: ForwardedRef<SelectType<TOption, TIsMulti>>
	},
) => ReturnType<typeof SelectInternal>
