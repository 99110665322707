import React, { useRef, useState } from "react"

import classNames from "classnames"

import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { OptionType } from "../../../types/sharedTypes"

import { ReactComponent as ChevronSVG } from "../../../assets/images/icons/Chevron.svg"

import "./style.sass"

export type MultiActionButtonProps<T extends string> = {
	disabled?: boolean
	options: OptionType<T>[]
	label: string
	onAction: (value: T) => void
	className?: string
	isSmall?: boolean
}

function MultiActionButton<T extends string>({
	disabled,
	options,
	label = "ACTIONS",
	onAction,
	className,
	isSmall = false,
}: MultiActionButtonProps<T>) {
	const ref = useRef<HTMLDivElement>(null)

	const [dropdownOpen, setDropdownOpen] = useState<boolean>()

	const handleOnDropDownToggle = () => {
		!disabled && setDropdownOpen((isOpen) => !isOpen)
	}

	const closeMultiActionButton = () => {
		setDropdownOpen(false)
	}

	useOnClickOutside([ref], closeMultiActionButton)

	const handleOnOptionSelected = (value: T) => {
		closeMultiActionButton()
		return onAction(value)
	}

	return (
		<div
			className={classNames([
				"MultiActionButton",
				className,
				{
					disabled,
					isSmall,
				},
			])}
			ref={ref}
		>
			<div className="Control" onClick={handleOnDropDownToggle}>
				<span>{label} </span>
				<ChevronSVG />
			</div>
			{dropdownOpen && (
				<div className="Content">
					{options?.map((option) => {
						return (
							<div
								className="Option"
								key={option.value}
								onClick={() => handleOnOptionSelected(option.value)}
							>
								<span className="Label">{option.label}</span>
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default MultiActionButton
