import React, { ChangeEvent, useRef, useState } from "react"

import Search from "../Search"

type Props = {
	value: string
	onChange: (v: string) => void
	placeholder: string
}

export default function SearchFilter({ value, onChange, placeholder }: Props) {
	const timerRef = useRef<NodeJS.Timeout | undefined>()

	const [searchTerm, setSearchTerm] = useState(value)

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		const sT = e.target.value

		setSearchTerm(sT)

		if (timerRef.current) {
			clearTimeout(timerRef.current)
		}

		timerRef.current = setTimeout(() => {
			onChange(sT)
		}, 500)
	}

	function handleClear() {
		setSearchTerm("")
		onChange("")
	}

	return (
		<Search
			value={searchTerm}
			onChange={handleChange}
			onClear={handleClear}
			placeholder={placeholder}
		/>
	)
}
