import { useCallback, useEffect, useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { printerSettingURL, printerSettingsURL } from "../../../api"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"
import Field from "../../Field"
import BuildingFilter from "../../Filter/BuildingFilter"
import Filters from "../../Filter/Filters"
import { FilterSpecialValues } from "../../Filter/types"
import Space from "../../Space"
import VisitorBadge from "../../Visitors/VisitorBadge"
import { AsyncSwitch } from "../../advanced/AsyncToggle"
import { Input } from "../../basic/Input"
import { Select } from "../../basic/Select"
import PageForm from "../PageFormHook"
import { setErrors } from "../formUtils"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useFetchCompanyQuery } from "../../../redux/api/company"
import {
	createPrinterSettings,
	fetchPrinterSetting,
	updatePrinterSettings,
} from "../../../redux/printer_settings/printerSettingsSlice"
import { selectPrinterSettings } from "../../../redux/printer_settings/selectors"
import {
	BrandingOption,
	CheckInOption,
	PrinterSettingRequest,
	PrinterSettingResponse,
	SignInField,
	SignInFieldType,
} from "../../../redux/printer_settings/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectTabletSettings } from "../../../redux/tablet_settings/selectors"
import { fetchTabletSetting } from "../../../redux/tablet_settings/tabletSettingsSlice"
import { useActions } from "../../../redux/utils"

import "./PrinterSettingsForm.sass"

type FormValues = {
	show_check_in: OptionType<CheckInOption>
	branding: OptionType<BrandingOption>
	message?: string
	signin_field_1?: SignInField | null
	signin_field_2?: SignInField | null
}

const PrinterSettingsForm = () => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const [signinFields, setSigninFields] = useState<SignInField[]>([])
	const [signinFields1, setSigninFields1] = useState<SignInField[]>([])
	const [signinFields2, setSigninFields2] = useState<SignInField[]>([])

	const [printerSetting, setPrinterSetting] = useState<PrinterSettingRequest>()

	const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

	const { entries: printerSettings, isLoaded } = useAppSelector(
		selectPrinterSettings,
	)
	const { entries: tabletSettings, isLoading } =
		useAppSelector(selectTabletSettings)

	const { value: buildingFilter, onChange: setBuildingFilter } =
		useLocalStorage(
			"printer-settings-building-filter",
			FilterSpecialValues.EMPTY,
		)

	const selectedBuilding = buildings.find(
		(building) => building.id === buildingFilter,
	)

	const { data: company } = useFetchCompanyQuery()

	const actions = useActions({
		fetchPrinterSetting: (id: string) => fetchPrinterSetting(id),
		createPrinterSettings: (printerSetting: PrinterSettingRequest) =>
			createPrinterSettings(printerSetting),
		updatePrinterSettings: (printerSetting: PrinterSettingRequest) =>
			updatePrinterSettings(printerSetting),
		fetchTabletSettings: (id: string) => fetchTabletSetting(id),
	})

	useEffect(() => {
		if (isLoaded && buildingFilter) {
			const exPrinterSettings = printerSettings.find(
				(ts) => ts.building_id === buildingFilter,
			)

			setPrinterSetting({
				id: exPrinterSettings?.id,
				building_id: buildingFilter,
				enable_badge_printing:
					exPrinterSettings?.enable_badge_printing ?? false,
				branding: exPrinterSettings?.branding ?? BrandingOption.NONE,
				show_check_in: exPrinterSettings?.show_check_in ?? CheckInOption.NONE,
				signin_fields: exPrinterSettings?.signin_fields ?? [],
				message: exPrinterSettings?.message ?? "",
			})
		}
	}, [buildingFilter, printerSettings, isLoaded])

	const {
		id,
		building_id,
		enable_badge_printing,
		show_check_in,
		branding,
		message,
		signin_fields,
	} = printerSetting || {}

	const brandingDropdownOptions = useMemo(() => {
		return Object.values(BrandingOption).map((value) => {
			return {
				value,
				label: t(
					`desktop.settings.visitors.device_settings.printer.branding_options.${value.toLowerCase()}` as ParseKeys,
				),
			}
		})
	}, [t])

	const checkInTypeDropdownOptions = useMemo(() => {
		return Object.values(CheckInOption).map((value) => {
			return {
				value,
				label: t(
					`desktop.settings.visitors.device_settings.printer.check_in_options.${value.toLowerCase()}` as ParseKeys,
				),
			}
		})
	}, [t])

	const methods = useForm<FormValues>({
		defaultValues: {
			show_check_in: checkInTypeDropdownOptions.find(
				(checkInOption) => checkInOption.value === show_check_in,
			),
			branding: brandingDropdownOptions.find(
				(brandingOption) => brandingOption.value === branding,
			),
			message: message,
			signin_field_1: signin_fields ? signin_fields[0] : null,
			signin_field_2: signin_fields ? signin_fields[1] : null,
		},
	})

	const { control, watch, reset, setError } = methods

	// Update default values if settings were updated from parent (e.g. different building was selected)
	useEffect(() => {
		if (!printerSetting) return

		reset({
			show_check_in: checkInTypeDropdownOptions.find(
				(checkInOption) => checkInOption.value === show_check_in,
			),
			branding: brandingDropdownOptions.find(
				(brandingOption) => brandingOption.value === branding,
			),
			message: message,
			signin_field_1: signin_fields ? signin_fields[0] : null,
			signin_field_2: signin_fields ? signin_fields[1] : null,
		})
	}, [
		reset,
		printerSetting,
		branding,
		message,
		signin_fields,
		brandingDropdownOptions,
		checkInTypeDropdownOptions,
		show_check_in,
	])

	const generateBody = useCallback(
		({
			show_check_in,
			branding,
			message,
			signin_field_1,
			signin_field_2,
		}: FormValues) => {
			const signinFields: SignInField[] = []

			if (signin_field_1) {
				signinFields.push(signin_field_1)
			}

			if (signin_field_2) {
				signinFields.push(signin_field_2)
			}

			return {
				building_id: building_id ?? "",
				enable_badge_printing: !!enable_badge_printing,
				branding: branding.value,
				show_check_in: show_check_in.value,
				message,
				signin_fields: signinFields,
			}
		},
		[building_id, enable_badge_printing],
	)

	const onCreateClick = useCallback(
		async (values: FormValues) => {
			const response = await actions.createPrinterSettings(generateBody(values))

			if (createPrinterSettings.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast)
				}
			} else {
				infoToast(
					t(
						"desktop.settings.visitors.device_settings.printer.settings_updated_toast",
					),
				)
			}
		},
		[generateBody, actions, setError, errorToast, infoToast, t],
	)

	const onUpdateClick = useCallback(
		async (values: FormValues) => {
			const response = await actions.updatePrinterSettings(generateBody(values))

			if (updatePrinterSettings.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast)
				}
			} else {
				infoToast(
					t(
						"desktop.settings.visitors.device_settings.printer.settings_updated_toast",
					),
				)
			}
		},
		[generateBody, actions, setError, errorToast, infoToast, t],
	)

	const getFieldLabel = (field: SignInField) => {
		if (field.type === SignInFieldType.CUSTOM) {
			return field.custom_field_name!
		}
		return t(
			`desktop.settings.visitors.device_settings.tablet.sign_in_fields.${field.type.toLocaleLowerCase()}.label` as ParseKeys,
		)
	}

	const getExampleFieldData = (field?: SignInField | null) => {
		if (field) {
			switch (field.type) {
				case SignInFieldType.EMAIL:
					return "email@example.com"
				case SignInFieldType.PHONE:
					return "+386 23 456 789"
				case SignInFieldType.HOST:
					return "Host Name"
				case SignInFieldType.CUSTOM:
					return field.custom_field_name
			}
		}
	}

	const signInField1 = watch("signin_field_1")
	const signInField2 = watch("signin_field_2")
	const customMessage = watch("message")
	const brandingType = watch("branding")
	const checkInType = watch("show_check_in")

	const refresh = useCallback(
		(value: boolean) => {
			if (value) {
				infoToast(
					t(
						"desktop.settings.visitors.device_settings.printer.settings_enabled_toast",
					),
				)
			} else {
				infoToast(
					t(
						"desktop.settings.visitors.device_settings.printer.settings_disabled_toast",
					),
				)
			}

			actions.fetchPrinterSetting(building_id ?? buildingFilter)
		},
		[actions, buildingFilter, building_id, infoToast, t],
	)

	useEffect(() => {
		actions.fetchPrinterSetting(building_id ?? buildingFilter)
		actions.fetchTabletSettings(building_id ?? buildingFilter)
	}, [actions, buildingFilter, building_id])

	useEffect(() => {
		if (!isLoading) {
			const tabletSetting = tabletSettings.find(
				(ts) => ts.building_id === building_id,
			)
			if (tabletSetting) {
				const fields = tabletSetting.signin_fields.filter(
					(f) => f.type !== SignInFieldType.NAME,
				)

				setSigninFields(fields)
				setSigninFields1(fields)
				setSigninFields2(fields)
			} else {
				setSigninFields1([])
				setSigninFields2([])
			}
		}
	}, [
		building_id,
		isLoading,
		setSigninFields1,
		setSigninFields2,
		printerSettings,
		printerSetting,
		tabletSettings,
	])

	useEffect(() => {
		if (signInField1) {
			setSigninFields2(signinFields.filter((f) => f.id !== signInField1.id))
		} else {
			setSigninFields2(signinFields)
		}
	}, [signinFields, signInField1, setSigninFields2])

	useEffect(() => {
		if (signInField2) {
			setSigninFields1(signinFields.filter((f) => f.id !== signInField2.id))
		} else {
			setSigninFields1(signinFields)
		}
	}, [signinFields, signInField2, setSigninFields1])

	const updateMode = id !== undefined

	return (
		<div className="PrinterSettingsForm">
			<Filters>
				<BuildingFilter
					value={buildingFilter}
					onChange={setBuildingFilter}
					hasVisitorManagement={true}
				/>

				{selectedBuilding && (
					<AsyncSwitch
						type="put"
						updateMode={updateMode}
						urlGenerator={() => {
							if (updateMode) {
								return printerSettingURL(selectedBuilding.id)
							}

							return printerSettingsURL()
						}}
						analyticsGenerator={async (response) => {
							const data: PrinterSettingResponse = await response.json()

							if (data) {
								analyticsEvent(
									data.enable_badge_printing
										? SupportedEvents.VISITOR_ADMIN_PRINTER_ENABLED
										: SupportedEvents.VISITOR_ADMIN_PRINTER_DISABLED,
									{
										id: data.id,
										building_id: data.building_id,
									},
								)
							}
						}}
						bodyGenerator={(value) => ({
							...generateBody(methods.getValues()),
							enable_badge_printing: value,
						})}
						refresh={refresh}
						value={printerSetting?.enable_badge_printing ?? false}
						label={t(
							"desktop.settings.visitors.device_settings.printer.enable_badge_printing",
						)}
					/>
				)}
			</Filters>
			<Space size={1} />

			<FormProvider {...methods}>
				<PageForm
					updateMode={updateMode}
					onCreate={onCreateClick}
					onUpdate={onUpdateClick}
					className="PrinterSettingsForm"
				>
					<div className="VisitorBadgeFieldsWrapper">
						<Field
							control={control}
							name="branding"
							className="InputField"
							label={t(
								"desktop.settings.visitors.device_settings.printer.branding",
							)}
						>
							{(props) => (
								<Select<OptionType>
									{...props}
									options={brandingDropdownOptions}
								/>
							)}
						</Field>
						<Field
							control={control}
							name="signin_field_1"
							className="InputField"
							label={t(
								"desktop.settings.visitors.device_settings.printer.custom_field_1",
							)}
						>
							{(props) => (
								<Select
									{...props}
									clearable
									options={signinFields1}
									getOptionLabel={getFieldLabel}
									disabled={signinFields1.length === 0}
								/>
							)}
						</Field>
						<Field
							control={control}
							name="signin_field_2"
							className="InputField"
							label={t(
								"desktop.settings.visitors.device_settings.printer.custom_field_2",
							)}
						>
							{(props) => (
								<Select
									{...props}
									clearable
									options={signinFields2}
									getOptionLabel={getFieldLabel}
									disabled={signinFields2.length === 0}
								/>
							)}
						</Field>
						<Field
							control={control}
							name="show_check_in"
							className="InputField"
							label={t(
								"desktop.settings.visitors.device_settings.printer.check_in",
							)}
						>
							{(props) => (
								<Select {...props} options={checkInTypeDropdownOptions} />
							)}
						</Field>
						<Field
							control={control}
							name="message"
							className="InputField"
							label={t(
								"desktop.settings.visitors.device_settings.printer.custom_message",
							)}
						>
							{(props) => (
								<Input
									multiline
									{...props}
									placeholder={t(
										"desktop.settings.visitors.device_settings.printer.message_placeholder",
									)}
								/>
							)}
						</Field>
					</div>

					<div className="VisitorBadgeWrapper">
						<label htmlFor="preview" className="Label">
							Preview
						</label>
						<div className="Border">
							<VisitorBadge
								name={t(
									"desktop.settings.visitors.device_settings.printer.first_last_name",
								)}
								brandingType={brandingType?.value}
								checkInType={checkInType?.value}
								message={customMessage}
								companyLogo={company?.settings?.company_logo?.url}
								companyName={
									company?.name ??
									t(
										"desktop.settings.visitors.device_settings.printer.company_name",
									)
								}
								signInField1={getExampleFieldData(signInField1)}
								signInField2={getExampleFieldData(signInField2)}
							/>
						</div>
					</div>
				</PageForm>
			</FormProvider>
		</div>
	)
}
export default PrinterSettingsForm
