import { ThunkApiConfig } from "RootType"

import { get, visitsExportURL, visitsURL } from "../../api"
import {
	PaginationState,
	SliceState,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	FetchOptions,
	VisitorListResponse,
	VisitorsListResponse,
} from "./types"
import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
	isAnyOf,
} from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

export const fetchVisitors = createAsyncThunk<
	VisitorsListResponse,
	FetchOptions,
	ThunkApiConfig
>("visitors/fetch", async (options, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const { building_id, ...rest } = options
	const isAllBuilding = building_id === FilterSpecialValues.ALL

	const response = await get(
		visitsURL({ ...rest, ...(!isAllBuilding && { building_id }) }),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}
	return rejectWithValue(await getErrorMessage(response))
})

export const getVisitorsTotal = createAsyncThunk<
	number,
	FetchOptions,
	ThunkApiConfig
>("visitors/getTotal", async (options, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const { building_id, search, show, ...rest } = options
	const isAllBuilding = building_id === FilterSpecialValues.ALL
	const response = await get(
		visitsURL({ ...rest, ...(!isAllBuilding && { building_id }), limit: 0 }),
		{},
		access_token,
	)

	if (response.ok) {
		const result = await response.json()

		return result.count
	}

	return 0
})

export const exportVisitors = createAsyncThunk<
	string,
	FetchOptions,
	ThunkApiConfig
>("visitors/export", async (options, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(visitsExportURL(options), {}, access_token)

	if (response.ok) {
		return await response.text()
	}
	return rejectWithValue(await getErrorMessage(response))
})

export interface VisitorsState extends SliceState, PaginationState {
	entries: VisitorListResponse[]
	total: number
}

const initialState: VisitorsState = {
	...sliceInitialState,
	...paginationInitialState,
	entries: [],
	total: -1,
}

const visitorsSlice = createSlice({
	name: "visitors",
	initialState,
	reducers: {
		visitorRemove: (state, { payload }: PayloadAction<string>) => {
			state.entries = state.entries.filter((visitor) => visitor.id !== payload)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchVisitors.fulfilled, (state, { payload, meta }) => {
			state.count = payload.count
			state.offset = meta.arg?.offset ?? 0
			state.entries = payload.results
			if (
				(meta.arg.search === "" && meta.arg.show === "all") ||
				state.total === -1
			) {
				state.total = payload.count
			}
			setFetchSuccessState(state)
		})
		builder.addCase(getVisitorsTotal.fulfilled, (state, { payload }) => {
			state.total = payload
		})

		builder.addMatcher(isAnyOf(fetchVisitors.pending), (state) => {
			state.isLoading = true
		})

		builder.addMatcher(isAnyOf(fetchVisitors.rejected), (state, action) => {
			setFetchErrorState(state, action)
		})
	},
})

export const visitorsReducer = visitorsSlice.reducer

export const { visitorRemove } = visitorsSlice.actions
