import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	VisitorListResponse,
	FetchOptions as VisitorsFetchOptions,
} from "../../redux/visitors/types"

import VisitorDeleteForm from "../../components/Form/Visitors/VisitorDeleteForm"

type Props = {
	open: boolean
	visitor: VisitorListResponse
	fetchParams?: VisitorsFetchOptions
}

export default function VisitDeleteModal({
	open,
	visitor,
	fetchParams,
}: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<VisitorDeleteForm visitor={visitor} fetchParams={fetchParams} />
		</ReactModal>
	)
}
