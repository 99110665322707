import React, { useCallback, useContext } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ErrorContext } from "../../providers/Tablet/ErrorHandlerProvider"
import { FlowContext } from "../../providers/Tablet/RegistrationFlowProvider"
import { isNative } from "../../utils"

import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import { RegistrationUpdateRequest } from "../../redux/visitor_registration/types"
import { updateRegistration } from "../../redux/visitor_registration/visitorRegistrationSlice"

import Field from "../../components/Field"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorForm from "../../components/Visitors/VisitorForm"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"
import Button from "../../components/basic/Button"
import ButtonSelect from "../../components/basic/ButtonSelect"

import "./Health.sass"

type FormValues = {
	[question: string]: string
}

const Health = () => {
	const { t } = useTranslation()

	const { nextStep, previousStep } = useContext(FlowContext)
	const { setError } = useContext(ErrorContext)

	const { buildingId, id: tabletId } = useAppSelector(selectTablet)
	const { data, registration, isSubmitting } = useAppSelector(
		selectVisitorRegistration,
	)

	const actions = useActions({
		updateRegistration: (
			buildingId: string,
			tabletId: string,
			id: string,
			body: RegistrationUpdateRequest,
		) => updateRegistration({ buildingId, tabletId, id, ...body }),
	})

	const defaultValues = data?.health_screening?.questions.reduce(
		(obj, question) => {
			return {
				...obj,
				[question.question]: "",
			}
		},
		{},
	)

	const methods = useForm<FormValues>({
		defaultValues,
	})
	const { control } = methods

	const onSubmit = useCallback(
		async (values: FormValues) => {
			if (buildingId && tabletId && registration) {
				const health_screening = Object.keys(values).map((question) => ({
					[question]: values[question],
				}))

				const response = await actions.updateRegistration(
					buildingId,
					tabletId,
					registration?.id,
					{
						health_screening,
					},
				)
				if (updateRegistration.fulfilled.match(response)) {
					nextStep(response.payload.id)
				} else {
					setError(response.error.message)
				}
			}
		},
		[buildingId, tabletId, registration, actions, nextStep, setError],
	)

	const handleGoBack = () => previousStep()

	return (
		<VisitorView className="Health">
			<FormProvider {...methods}>
				<VisitorForm onSubmit={onSubmit}>
					<VisitorHeader
						showNavigation={!isNative()}
						title={t("tablet.visitors.screens.health.title")}
					/>
					<VisitorContent>
						<div className="fields">
							{data?.health_screening?.questions.map((q) => (
								<Field
									key={q.question}
									control={control}
									name={q.question}
									label={q.question}
									rules={{
										required: t(
											"tablet.visitors.screens.health.field_required",
										),
									}}
								>
									{(props) => (
										<ButtonSelect
											{...props}
											options={[
												{ label: t("general.yes"), value: "yes" },
												{ label: t("general.no"), value: "no" },
											]}
										/>
									)}
								</Field>
							))}
						</div>
					</VisitorContent>
					<VisitorFooter>
						<div>
							<Button variant="submit" isLoading={isSubmitting}>
								{t("tablet.visitors.continue")}
							</Button>
						</div>
						{isNative() && (
							<div>
								<Button
									variant="link"
									onClick={handleGoBack}
									isDisabled={isSubmitting}
								>
									{t("general.redirection.go_back")}
								</Button>
							</div>
						)}
					</VisitorFooter>
				</VisitorForm>
			</FormProvider>
		</VisitorView>
	)
}

export default Health
