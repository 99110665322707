import React, { FC, PropsWithChildren, useEffect } from "react"

import { useTranslation } from "react-i18next"

import AccessActivityTable from "./AccessActivityTable"
import Loader from "./basic/Loader"
import { useModals } from "@mattjennings/react-modal-stack"

import { useAppSelector } from "../redux/reducers"
import { selectShareable } from "../redux/shareable/selectors"
import { fetchShareableAccessLog } from "../redux/shareable/shareableSlice"
import { ShareableAccessLog } from "../redux/shareable/types"
import { useActions } from "../redux/utils"

import { ReactComponent as CrossSVG } from "../assets/images/icons/Cross.svg"

import "./ShareFloorAccessActivity.sass"

type ShareFloorAccessActivityProps = {
	shareableId: string
}

export const ShareFloorAccessActivity: FC<
	PropsWithChildren<ShareFloorAccessActivityProps>
> = ({ shareableId }) => {
	const { closeModal } = useModals()
	const actions = useActions({
		fetchShareableAccessLog: (id: string) => fetchShareableAccessLog(id),
	})
	const { isLoading, isLoaded, accessLogs } = useAppSelector(selectShareable)
	const { t } = useTranslation()

	useEffect(() => {
		actions.fetchShareableAccessLog(shareableId)
	}, [shareableId, actions])

	const accessLog: ShareableAccessLog[] = accessLogs[shareableId] ?? []

	return (
		<div className="ModalForm access-activity">
			<div className="title">
				<h1>{t("desktop.settings.floor_plans.share.access_log.title")}</h1>
			</div>
			<div className="close" onClick={() => closeModal()}>
				<CrossSVG />
			</div>
			<div className="container">
				<div className="description">
					{t("desktop.settings.floor_plans.share.access_log.description")}
				</div>

				{!isLoaded || isLoading ? (
					<div className="loader-wrapper">
						<Loader />
					</div>
				) : accessLog.length === 0 ? (
					<div className="no-activity">
						{t("desktop.settings.floor_plans.share.access_log.no_activity")}
					</div>
				) : (
					<AccessActivityTable accessLogs={accessLog} />
				)}
			</div>
		</div>
	)
}

export default ShareFloorAccessActivity
