import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { DeskResponse } from "../redux/api/desks/types"
import { FloorResponse } from "../redux/floors/types"

import DeskForm from "../components/Form/DeskForm"

type Props = {
	open: boolean
	floor: FloorResponse | null
	desks: DeskResponse[]
}

export default function DeskModal({ open, floor, desks }: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<DeskForm floor={floor} desks={desks} />
		</ReactModal>
	)
}
