import { ChangeEvent, HTMLProps, forwardRef } from "react"

import classNames from "classnames"

import { InternalTime } from "../../../types/sharedTypes"
import { isInternalTime } from "../../../utils"

import { ReactComponent as ClockSVG } from "../../../assets/images/icons/Clock.svg"

import "./style.sass"

export type TimePickerProps = {
	onChange?: (value: InternalTime) => void
	value?: InternalTime
	maxTime?: InternalTime
	minTime?: InternalTime
	name?: string
	hasError?: boolean
} & Omit<HTMLProps<HTMLInputElement>, "onChange" | "value" | "ref">

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
	(
		{
			onChange,
			maxTime = "24:00",
			minTime = "00:00",
			name,
			className,
			disabled,
			value,
			hasError = false,

			...props
		}: TimePickerProps,
		ref,
	) => {
		const cn = classNames("TimePicker", className, { disabled })
		const wrapperCn = classNames("TimePickerWrapper", className, {
			disabled,
			error: hasError,
		})

		const internalOnChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (isInternalTime(e.target.value)) {
				onChange && onChange(e.target.value)
			}
		}

		return (
			<div className={wrapperCn}>
				<input
					ref={ref}
					{...props}
					value={value ?? ""}
					className={cn}
					type="time"
					pattern="[0-9]{2}:[0-9]{2}"
					onChange={internalOnChange}
					min={`${minTime}:00`}
					max={`${maxTime}:00`}
					name={name}
					id={name}
					disabled={disabled}
				/>
				<ClockSVG className="clock-icon" />
			</div>
		)
	},
)
