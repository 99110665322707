import React from "react"

import classNames from "classnames"
import { PaginatorLink } from "headless-pagination"
import { useTranslation } from "react-i18next"

import { ReactComponent as ArrowNextSVG } from "../assets/images/icons/Arrow_next.svg"
import { ReactComponent as ArrowPreviousSVG } from "../assets/images/icons/Arrow_previous.svg"

import "./Pagination.sass"

type Props = {
	from: number
	to: number
	hasPrevious: boolean
	onPrevious: () => void
	links: PaginatorLink[]
	setPage: (p: number) => void
	hasNext: boolean
	onNext: () => void
	total: number
	items?: string
	className?: string
}

export function Pagination({
	from,
	to,
	hasPrevious,
	onPrevious,
	links,
	setPage,
	hasNext,
	onNext,
	total,
	items,
	className,
}: Props) {
	const { t } = useTranslation()

	const cn = classNames("Pagination", className)

	return (
		<>
			<div className={cn}>
				<div className="summary">
					{t("desktop.manage.pagination_showing_items", {
						from: from < total ? from : total,
						to: to < total ? to : total,
						total,
						items: items ?? t("desktop.manage.pagination_items"),
					})}
				</div>

				{(hasPrevious || hasNext) && (
					<div className="links">
						<button disabled={!hasPrevious} onClick={onPrevious}>
							<ArrowPreviousSVG />
						</button>

						{links.map((link: PaginatorLink, i: number) => (
							<button
								key={i}
								disabled={link.disabled}
								className={link.active ? "current" : "link"}
								onClick={() =>
									typeof link.label !== "string"
										? setPage(link.label)
										: undefined
								}
							>
								<span>{link.label}</span>
							</button>
						))}

						<button disabled={!hasNext} onClick={onNext}>
							<ArrowNextSVG />
						</button>
					</div>
				)}
			</div>
		</>
	)
}

export default Pagination
