import { useCallback, useState } from "react"

export const useLocalStorage = (
	key: string,
	defaultValue: string,
	override?: string,
) => {
	const savedValue = window.localStorage.getItem(key)

	const [filterValue, setFilterValue] = useState(
		override ?? savedValue ?? defaultValue,
	)

	const onChange = useCallback(
		(v: string) => {
			setFilterValue(v)
			window.localStorage.setItem(key, v)
		},
		[key],
	)

	return {
		value: filterValue,
		onChange,
	}
}
