import { ObjectToUnion } from "../../types/sharedTypes"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const FEATURE = {
	desk: "desk",
	visitor: "visitor",
	home: "home",
	room: "room",
	sign: "sign",
} as const

export type Feature = ObjectToUnion<typeof FEATURE>
export type PaymentState = {
	hasPaid: boolean
	locked: {
		[FEATURE.desk]: boolean
		[FEATURE.visitor]: boolean
		[FEATURE.home]: boolean
		[FEATURE.room]: boolean
		[FEATURE.sign]: boolean
	}
}

const initialState: PaymentState = {
	hasPaid: true,
	locked: {
		[FEATURE.desk]: false,
		[FEATURE.visitor]: false,
		[FEATURE.home]: false,
		[FEATURE.room]: false,
		[FEATURE.sign]: false,
	},
}

const paymentSlice = createSlice({
	name: "payment",
	initialState,
	reducers: {
		setHasPaid: (state, { payload }: PayloadAction<Feature | null>) => {
			state.hasPaid = !payload
			if (!payload) {
				state.locked = initialState.locked
			}
			if (payload) {
				state.locked[FEATURE[payload]] = true
			}
		},
	},
})

export const paymentReducer = paymentSlice.reducer

export const { setHasPaid } = paymentSlice.actions
