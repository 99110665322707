import React, { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import BuildingFilter from "../../../components/Filter/BuildingFilter"
import FilterSpace from "../../../components/Filter/FilterSpace"
import Filters from "../../../components/Filter/Filters"
import InvitesFilter from "../../../components/Filter/InvitesFilter"
import SearchFilter from "../../../components/Filter/SearchFilter"

export type Filter = {
	building_id: string
	show: string
	search: string
	page?: number
}
type FilterKeys = keyof Filter

type Props = {
	onChange: (filter: Filter) => Promise<void>
	defaultValues: Filter
}

const InvitesFilters = ({ onChange, defaultValues }: Props) => {
	const filtersRef = useRef<Filter>(defaultValues)
	const [filters, setFilters] = useState<Filter>(defaultValues)
	const { t } = useTranslation()

	const handleOnChange = useCallback(
		(filterName: FilterKeys) => (value: string | string[]) => {
			filtersRef.current = {
				...filtersRef.current,
				[filterName]: value,
			}
			onChange(filtersRef.current as Filter)
			setFilters(filtersRef.current)
		},
		[onChange],
	)

	return (
		<Filters>
			<BuildingFilter
				value={filters.building_id}
				onChange={handleOnChange("building_id")}
				hasVisitorManagement={true}
				showAll={true}
			/>
			<InvitesFilter value={filters.show} onChange={handleOnChange("show")} />

			<FilterSpace />

			<SearchFilter
				value={filters.search}
				onChange={handleOnChange("search")}
				placeholder={t("desktop.manage.visitors.filters.search_placeholder")}
			/>
		</Filters>
	)
}

export default InvitesFilters
