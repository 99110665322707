import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { buildingsURL } from "../../../api"
import { useToast } from "../../../hooks/useToast"

import { BuildingResponse } from "../../../redux/buildings/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { fetchUser } from "../../../redux/user/userSlice"
import { UserRequest } from "../../../redux/users/types"
import { patchUser } from "../../../redux/users/usersSlice"
import { useActions } from "../../../redux/utils"

import Field from "../../../components/Field"
import PageForm from "../../../components/Form/PageFormHook"
import { setErrors } from "../../../components/Form/formUtils"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import Space from "../../../components/Space"
import AsyncSelect from "../../../components/advanced/AsyncSelect"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as DomainSVG } from "../../../assets/images/icons/Domain.svg"

import "./BuildingSelect.sass"

type FormValues = {
	building: BuildingResponse
}
const BuildingSelect = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const actions = useActions({
		fetchUser: () => fetchUser(),
		patchManagementUser: (email: string, payload: Partial<UserRequest>) =>
			patchUser({ email, payload }),
	})

	const { entry: user, isLoading } = useAppSelector(selectUser)

	const methods = useForm<FormValues>({
		defaultValues: { building: user.building ?? undefined },
	})

	const { control, setError } = methods

	const handleSetDefaultBuilding = async ({ building }: FormValues) => {
		if (user) {
			const response = await actions.patchManagementUser(user.email, {
				building_id: building.id,
			})

			if (patchUser.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast)
				}
			} else if (patchUser.fulfilled.match(response)) {
				await actions.fetchUser()
				infoToast(t("desktop.building_select.success"))
				history.push("/")
			}
		}
	}

	return (
		<SafeViewArea className="BuildingSelect">
			<div className="head">
				<TopNav />
				<div className="icon">
					<div>
						<DomainSVG />
					</div>
				</div>
			</div>
			<div className="body">
				<div className="main-data">
					<div className="title">
						{t("mobile.building_selection.default_building_required")}
					</div>
					<div className="intro">
						<div>{t("mobile.building_selection.before_start_using_app")}</div>
						<Space size={0.8} />
						<div>
							{t(
								"mobile.building_selection.select_from_dropdown_or_contact_admin",
							)}
						</div>
					</div>
					{isLoading ? (
						<Loader />
					) : (
						<div className="BuildingSelectForm">
							<FormProvider {...methods}>
								<PageForm updateMode={true} onUpdate={handleSetDefaultBuilding}>
									<Field
										control={control}
										name="building"
										label={t(
											"mobile.building_selection.select_default_building",
										)}
									>
										{(props) => (
											<AsyncSelect
												{...props}
												urlGenerator={(fetchOptions) => {
													return buildingsURL(fetchOptions)
												}}
												nothingFoundMessage={t(
													"desktop.settings.person.person_form.errors.no_building_found",
												)}
												getOptionLabel={(building) => building.name}
												getOptionValue={(building) => building.id}
											/>
										)}
									</Field>
								</PageForm>
							</FormProvider>
						</div>
					)}
				</div>
			</div>
		</SafeViewArea>
	)
}

export default BuildingSelect
