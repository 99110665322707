import React, { PropsWithChildren, memo, useCallback, useEffect } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { isNetworkError } from "../api/utils"
import { useNativeConnectivityChange } from "../hooks/useNativeConnectivityChange"
import Heading from "./Heading"
import Button from "./basic/Button"
import { ErrorBoundary, captureException } from "@sentry/react"

import { appError } from "../redux/app/appSlice"
import { useActions } from "../redux/utils"

import "./RouteView.sass"

type ErrorProps = {
	error: Error
	resetError: () => void
}

export const ErrorFallback = (props: ErrorProps) => {
	const { error, resetError } = props

	useEffect(() => {
		if (error) {
			captureException(error)
		}
	}, [error])

	if (!window.navigator.onLine || isNetworkError(props.error)) {
		return <ReconnectRouteView {...props} />
	}

	return (
		<div className="RouteView Error">
			<Heading>An unhandled error occurred</Heading>
			<p>
				<code>{error.message}</code>
			</p>
			<p>
				Try reloading the screen, to see if this was an intermittent issue.
				<br />
				Please <a href="mailto:support@getjoan.com">contact support</a> if the
				issue persists.
			</p>
			<br />
			<p>
				<Button onClick={resetError}>Reload screen</Button>
			</p>
		</div>
	)
}

export const MemoizedErrorFallback = memo(ErrorFallback)

type RouteProps = {
	className: string
}

const RouteView = ({ className, children }: PropsWithChildren<RouteProps>) => {
	const routeViewClassName = classNames({
		RouteView: true,
		[className]: !!className,
	})

	const actions = useActions({
		appError: (message: string) => appError(message),
	})

	const logError = useCallback(
		(error: Error, componentStack: string) => {
			captureException(error)
			error.message && actions.appError(`${error.message}\n${componentStack}`)
		},
		[actions],
	)

	const reloadPage = useCallback(() => window.location.reload(), [])

	return (
		<ErrorBoundary
			fallback={ErrorFallback}
			onError={logError}
			onReset={reloadPage}
		>
			<div className={routeViewClassName}>{children}</div>
		</ErrorBoundary>
	)
}

export default RouteView

/**
 * We had some problems with Apple not wanting to click a button when network was lost
 * so we needed to implement some automatization in case there's problems with network issues.
 */
const ReconnectRouteView = ({ resetError }: ErrorProps) => {
	const { t } = useTranslation()

	useNativeConnectivityChange((connected) => {
		if (connected) {
			resetError()
		}
	})

	return (
		<div className="RouteView Error">
			<Heading>{t("mobile.general.no_connection")}</Heading>
			<p>{t("mobile.general.no_connection_detail")}</p>
			<br />
			<p>
				<Button onClick={resetError}>Reload screen</Button>
			</p>
		</div>
	)
}
