import { Department, Desk } from "./types"

export const WITHOUT_DEPARTMENT = "withoutDepartment"
export const getDepartments = (desks: Desk[]) => {
	return desks.reduce((departments, desk) => {
		desk.departments.forEach((d) => {
			if (!departments.find((eDepartment) => d.id === eDepartment.id)) {
				departments.push(d)
			}
		})
		return departments
	}, [] as Department[])
}

export const sortDesks = (desks: Desk[]) =>
	[...desks].sort((a, b) => {
		if (a.reserved !== b.reserved) {
			return a.reserved ? 1 : -1
		} else {
			return a.name.localeCompare(b.name, undefined, { numeric: true })
		}
	})

export const getDesksByDepartment = (desks: Desk[]) => {
	const departments = getDepartments(desks)
	const desksByDep = departments.reduce((byDepartment, dep) => {
		byDepartment[dep.name] = desks.filter(
			(d) => d.departments.findIndex((dd: Department) => dd.id === dep.id) > -1,
		)
		return byDepartment
	}, {} as Record<string, Desk[]>)
	if (desks.some((d) => d.departments.length === 0)) {
		desksByDep[WITHOUT_DEPARTMENT] = desks.filter(
			(d) => d.departments.length === 0,
		)
	}
	return desksByDep
}
