import { PropsWithChildren } from "react"

import { getAppParams } from "../../../redux/appParams/selectors"
import { useAppSelector } from "../../../redux/reducers"

import "./style.sass"

type RedirectLinkProps = {
	to: string
}

/**
 * Automatically creates a normal / whitelabelled link to the getjoan.com site which then redirects to a specific URL (knowledge base, store, ...).
 * For whitelabeled apps, the URL will be `https://getjoan.com/re/${to}` and for non-whitelabeled apps, the URL will be `https://getjoan.com/r/${to}`.
 * The redirects have to be set up on the Wordpress site.
 * @param to - the slug of the page to redirect to
 */
export const RedirectLink = ({
	children,
	to,
}: PropsWithChildren<RedirectLinkProps>) => {
	const { isAppWhitelabel } = useAppSelector(getAppParams)
	const redirectType = isAppWhitelabel ? "re" : "r"
	const url = `https://getjoan.com/${redirectType}/${to}`
	return (
		<a href={url} target="_blank" rel="noreferrer">
			{children}
		</a>
	)
}
