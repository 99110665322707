import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/dist/query"

import { AmenityResponse } from "../../../../redux/amenities/types"
import { useFetchAmenityQuery } from "../../../../redux/api/amenities"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import AmenityForm from "../../../../components/Form/AmenityForm"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import Loader from "../../../../components/basic/Loader"

type ParamsType = {
	amenity_id: string
}

type AmenitiesState = {
	query: string
}

const DEFAULT_AMENITY = {
	name: "",
} as Partial<AmenityResponse>

const EditAmenities = () => {
	const { t } = useTranslation()
	const history = useHistory<AmenitiesState>()
	const { amenity_id } = useParams<ParamsType>()

	const { query = "" } = history.location.state || {}

	const {
		data: amenity = DEFAULT_AMENITY,
		isLoading,
		error,
	} = useFetchAmenityQuery(amenity_id ?? skipToken)

	const amenityNotFound =
		(!!amenity_id && !amenity.id) ||
		(error && "status" in error && error.status === 404)

	return (
		<View className="EditAmenities">
			<Breadcrumbs
				depth={3}
				values={[
					t("desktop.settings.desks.title"),
					t("desktop.settings.amenities.title"),
					amenity?.id
						? t("desktop.settings.amenities.edit_breadcrumb")
						: t("desktop.settings.amenities.add_breadcrumb"),
				]}
			/>

			<Intro isConstrained>
				{amenity?.id
					? t("desktop.settings.amenities.edit_title")
					: t("desktop.settings.amenities.add_title")}
			</Intro>

			<Space size={0.75} />

			{isLoading ? (
				<Loader />
			) : amenityNotFound ? (
				<p>{t("desktop.settings.amenities.not_found")}</p>
			) : (
				<AmenityForm query={query} amenity={amenity} />
			)}
		</View>
	)
}

export default EditAmenities
