import React from "react"

import { Control, FieldValues, Path, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useTranslateOptions } from "../../../../hooks/useTranslateOptions"
import { PrepaidCodeFormValues } from "./usePrepaidCodeForm"

import { ACTIVATION_DATE_RADIO_OPTIONS } from "../../../../redux/api/billing/prepaidCode/constants"

import Field from "../../../../components/Field"
import Accordion from "../../../../components/basic/Accordion"
import { DatePicker } from "../../../../components/basic/DatePicker"
import { Input } from "../../../../components/basic/Input"
import { RadioGroup, RadioProps } from "../../../../components/basic/Radio"

export type PrepaidCodeProps<
	Values extends FieldValues & PrepaidCodeFormValues,
> = {
	control: Control<Values, any>
	watch: UseFormWatch<Values>
	isSubmitting: boolean
}

const PrepaidCodeForm = <Values extends FieldValues & PrepaidCodeFormValues>({
	control,
	watch,
	isSubmitting,
}: PrepaidCodeProps<Values>) => {
	const { t } = useTranslation()
	const selectedDate = watch("selectedDate" as Path<Values>)
	const radioOptions = useTranslateOptions(
		ACTIVATION_DATE_RADIO_OPTIONS,
	) as RadioProps[]

	return (
		<>
			<Field
				control={control}
				name={"code" as Path<Values>}
				label={t("desktop.settings.billing.pre_paid_code_card.pre_paid_code")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Accordion
				header={t(
					"desktop.settings.billing.pre_paid_code_card.code_activation_date",
				)}
			>
				<>
					<Field control={control} name={"selectedDate" as Path<Values>}>
						{(props) => (
							<RadioGroup
								{...props}
								display="vertical"
								options={radioOptions}
								disabled={isSubmitting}
							/>
						)}
					</Field>
					{selectedDate === "custom" ? (
						<Field control={control} name={"customDate" as Path<Values>}>
							{(props) => <DatePicker {...props} disabled={isSubmitting} />}
						</Field>
					) : null}
				</>
			</Accordion>
		</>
	)
}

export default PrepaidCodeForm
