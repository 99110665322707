import { ThunkApiConfig } from "RootType"

import { get, meURL, putJSON } from "../../api"
import { setCompany } from "../app/appSlice"
import {
	SliceState,
	getErrorMessage,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { MeResponse } from "./types"
import {
	PayloadAction,
	Slice,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit"

/**
 * Thunks
 */
export const fetchUser = createAsyncThunk<MeResponse, void, ThunkApiConfig>(
	"user/fetchUser",
	async (_, { getState, dispatch }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await get(meURL(), {}, access_token)

		if (response.ok) {
			const user: MeResponse = await response.json()

			if (user.companies[0].id) {
				dispatch(setCompany(user.companies[0].id))
			}

			return user
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const updatePrivacyMode = createAsyncThunk<
	boolean,
	boolean,
	ThunkApiConfig
>("user/updatePrivacyMode", async (privacy_mode_enabled, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		meURL(),
		{ body: { privacy_mode_enabled } },
		access_token,
	)

	if (response.ok) {
		return privacy_mode_enabled
	}

	throw new Error(await getErrorMessage(response))
})

export const updatePushEnabled = createAsyncThunk<
	boolean,
	boolean,
	ThunkApiConfig
>("user/updatePushEnabled", async (push_enabled, { getState, dispatch }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		meURL(),
		{ body: { push_enabled } },
		access_token,
	)

	if (response.ok) {
		return push_enabled
	}

	throw new Error(await getErrorMessage(response))
})

/**
 * Slice
 */
export type UserState = SliceState & {
	entry: MeResponse
}

const initialState: UserState = {
	entry: {
		id: "",
		email: "",
		first_name: "",
		last_name: "",
		permissions: [],
		groups: [],
		companies: [],
		departments: [],
		open_check_ins: [],
		location_tracking_enabled: false,
		privacy_mode_enabled: false,
		push_enabled: false,
		profile: {},
		building: null,
	},
	...sliceInitialState,
}

const userSlice: Slice<UserState> = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<MeResponse>) => {
			state.entry = action.payload
			state.isLoaded = true
			state.isLoading = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUser.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchUser.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
			state.entry = payload
			setFetchSuccessState(state)
		})
		builder.addCase(updatePrivacyMode.pending, (state) => {
			state.isSubmitting = true
		})
		builder.addCase(updatePrivacyMode.rejected, (state, action) => {
			setSubmitErrorState(state, action)
		})
		builder.addCase(updatePrivacyMode.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.entry.privacy_mode_enabled = payload
		})
		builder.addCase(updatePushEnabled.pending, (state) => {
			state.isSubmitting = true
		})
		builder.addCase(updatePushEnabled.rejected, (state, action) => {
			setSubmitErrorState(state, action)
		})
		builder.addCase(updatePushEnabled.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.entry.push_enabled = payload
		})
	},
})

export const userReducer = userSlice.reducer

export const { setUser } = userSlice.actions
