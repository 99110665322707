import { forwardRef } from "react"

import classNames from "classnames"
import ReactDatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as DaySVG } from "../../../assets/images/icons/Day.svg"

import "./style.sass"

export const DEFAULT_DATE_PICKER_PORTAL_ID = "DatePickerPortal"

export type DatePickerProps = {
	maxDate?: Date | string
	minDate?: Date | string
	value: Date | string | null
	onChange: (date: Date | null) => void
	className?: string
	placeholderText?: string
	todayButtonText?: string
	disabled?: boolean
	showYearDropdown?: boolean
	showMonthDropdown?: boolean
	showToday?: boolean
	clearable?: boolean
	hasError?: boolean
	autoComplete?: "on" | "off"
	open?: boolean
	dayClassName?: (date: Date) => string
	portalId?: string
	filterDate?: (date: Date) => boolean
	onClickOutside?: () => void
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
	(
		{
			className,
			value,
			minDate,
			maxDate,
			disabled,
			hasError,
			placeholderText = "mm/dd/yyyy",
			todayButtonText = "Select today",
			showToday = true,
			showMonthDropdown = false,
			clearable = false,
			portalId = DEFAULT_DATE_PICKER_PORTAL_ID,
			...props
		}: DatePickerProps,
		ref,
	) => {
		const cn = classNames("DatePickerInput", className, {
			disabled,
			error: hasError,
		})
		const date = value ? new Date(value) : undefined

		return (
			<div className={cn}>
				<ReactDatePicker
					{...props}
					minDate={minDate ? new Date(minDate) : undefined}
					maxDate={maxDate ? new Date(maxDate) : undefined}
					className="date-picker-input"
					isClearable={clearable}
					selected={date}
					showMonthDropdown={showMonthDropdown}
					placeholderText={placeholderText}
					showPopperArrow={false}
					todayButton={showToday && <TodayButton text={todayButtonText} />}
					fixedHeight
					disabled={disabled}
					customInput={<input ref={ref} />}
					portalId={portalId}
					autoComplete="off"
					calendarStartDay={1}
				/>
				<CalendarSVG className="calendar-icon" />
			</div>
		)
	},
)

const TodayButton = ({ text }: { text: string }) => (
	<div className="TodayButton">
		<DaySVG />
		<span>{text}</span>
	</div>
)
