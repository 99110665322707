import React, { SyntheticEvent, createRef, useEffect, useState } from "react"

import { Trans, useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useToast } from "../../hooks/useToast"
import { FileField } from "../Field/FileField"
import Space from "../Space"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import { useAppSelector } from "../../redux/reducers"
import { selectUsers } from "../../redux/users/selectors"
import { CSVEntry, FetchOptions, UserResponse } from "../../redux/users/types"
import {
	ENTRIES_PER_PAGE,
	batchCreateUsers,
	fetchUsers,
	importUsers,
} from "../../redux/users/usersSlice"
import { useActions } from "../../redux/utils"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as UploadSVG } from "../../assets/images/icons/Upload.svg"

import "./UsersCSVForm.sass"

function readFile(file: File): Promise<string | null | ArrayBuffer> {
	return new Promise((resolve, reject) => {
		var fr = new FileReader()
		fr.onload = () => resolve(fr.result)
		fr.onerror = reject
		fr.readAsText(file)
	})
}

type Props = {
	isDisabled?: boolean
}

interface HTMLInputEvent extends Event {
	target: HTMLInputElement & EventTarget
}

function UsersCSVForm({ isDisabled }: Props) {
	const { closeModal } = useModals()
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const actions = useActions({
		batchCreateUsers: (users: CSVEntry[]) => batchCreateUsers(users),
		fetchUsers: (options: FetchOptions) => fetchUsers(options),
		importUsers: (file: File) => importUsers(file),
	})

	const { entries: existingUsers } = useAppSelector(selectUsers)

	const [loading, setLoading] = useState<boolean>(false)
	const [data, setData] = useState<UserResponse[]>([])
	const [existing, setExisting] = useState<CSVEntry[]>([])
	const [usersWithErrors, setUsersWithErrors] = useState<string[]>([])

	const fileRef: React.RefObject<HTMLInputElement> = createRef()

	const handleChange = async (e: HTMLInputEvent) => {
		const { target } = e
		const file = target.files && target.files.length > 0 && target.files[0]

		// Reset the event so that multiple files can be uploaded without refreshing the page
		e.target.value = ""
		setData([])
		setExisting([])
		setUsersWithErrors([])
		setLoading(true)

		if (!file) {
			return
		}
		const response = await actions.importUsers(file)
		if (importUsers.rejected.match(response)) {
			errorToast(response.error?.message)
		}
		if (importUsers.fulfilled.match(response)) {
			const text = (await readFile(file)) as string
			const lines = text.split("\n")
			if (
				"status" in response.payload &&
				response.payload.status === 400 &&
				"errors" in response.payload
			) {
				const { errors } = response.payload

				setUsersWithErrors(mapErrorsToUsers(lines, errors))
				setLoading(false)
				errorToast(t("desktop.settings.people.csv_import.upload_failed"))
				return
			}

			if (!("status" in response.payload)) {
				const users = response.payload
				setData(users)
				setExisting(
					existingUsers.filter((existingUser) =>
						users.find((user) => user.email === existingUser.email),
					),
				)
				infoToast(t("desktop.settings.people.csv_import.upload_successful"))
				setLoading(false)

				const usersRes = await actions.fetchUsers({
					limit: ENTRIES_PER_PAGE,
					offset: 0,
					search: "",
				})
				if (fetchUsers.fulfilled.match(usersRes)) {
					analyticsEvent(SupportedEvents.PEOPLE_ADD, {
						num_users_added: response.payload.length,
						total: usersRes.payload.count,
					})
				}
			}
		}
	}

	const openFileUpload = (e: SyntheticEvent) => {
		e.preventDefault()
		fileRef.current && fileRef.current.click()
	}

	useEffect(() => {
		actions.fetchUsers({
			limit: FETCH_WITH_NO_LIMIT,
			offset: 0,
			search: "",
		})
	}, [actions])

	return (
		<form className="UsersCSVForm ModalForm">
			<div className="title">
				<h1>{t("desktop.settings.people.csv_import.title")}</h1>
			</div>
			<div className="close" onClick={() => closeModal()}>
				<CrossSVG />
			</div>
			<div>
				<FileField
					onChange={handleChange}
					style={{ display: "none" }}
					accept={"text/csv"}
					ref={fileRef}
				/>
				<div>
					<Trans components={{ code: <code /> }}>
						{"desktop.settings.people.csv_import.instructions"}
					</Trans>
				</div>
			</div>
			<Space size={0.75} />
			<div>
				{t("desktop.settings.people.csv_import.example_file_content")}:
				<Space size={0.25} />
				<div>
					<code>
						jane@example.com, Jane, Doe, portal_officer_manager, , +14052955384
					</code>
				</div>
				<div>
					<code>
						john@example.com, John, Doe, portal_user, sales, +447893987638
					</code>
				</div>
				<div>
					<code>
						richard@example.com, Richard, Roe, portal_user, marketing,
						+4593706119
					</code>
				</div>
			</div>
			<Space size={0.75} />
			<div className="actions">
				{!loading ? (
					<span className="button-with-hint">
						<Button
							onClick={openFileUpload}
							isDisabled={isDisabled}
							variant="secondary"
						>
							<UploadSVG />
							{t("desktop.settings.people.csv_import.choose_a_file")}
						</Button>
					</span>
				) : (
					<span>{t("desktop.settings.people.csv_import.uploading")}</span>
				)}
			</div>
			{data.length > 0 && (
				<div>
					<Space size={0.75} />
					<div>{t("desktop.settings.people.csv_import.upload_successful")}</div>
					<Space size={0.25} />
					<div>
						{data.length}{" "}
						{t("desktop.settings.people.csv_import.users_uploaded")}
					</div>
				</div>
			)}
			{existing.length > 0 && (
				<div>
					<Space size={0.75} />
					<div>
						{existing.length}{" "}
						{t("desktop.settings.people.csv_import.users_updated")}:
					</div>
					<Space size={0.25} />
					{existing.map((user) => {
						return <div className="light-text">{user.email}</div>
					})}
				</div>
			)}
			{usersWithErrors.length > 0 && (
				<div>
					<Space size={0.75} />
					<div>
						{t("desktop.settings.people.csv_import.users_with_errors")}:
					</div>
					<Space size={0.25} />
					{usersWithErrors.map((user) => {
						return <div className="light-text">{user}</div>
					})}
				</div>
			)}
		</form>
	)
}

export default UsersCSVForm

const mapErrorsToUsers = (
	lines: string[],
	errors: Record<string, string[]>[],
) => {
	const emails = lines.map((line: string) => line.split(",")[0])
	return emails.flatMap((email: string, index: number) => {
		if (email === "") {
			return []
		}
		const userErrors = errors[index]
		if (userErrors && isEmptyObject(userErrors)) {
			return []
		}
		return `${email}: ${Object.entries(userErrors)
			.map(([key, error]) => `${key}: ${error.join(", ")}`)
			.join(", ")}`
	})
}

const isEmptyObject = (obj: Record<string, unknown>) => {
	return Object.keys(obj).length === 0
}
