import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"

import { useLongPress } from "../hooks/useLongPress"
import { IdAndName } from "../types/sharedTypes"
import PlaceTooltip from "./PlaceTooltip"

import { DepartmentResponse } from "../redux/api/departments/types"
import { useAppSelector } from "../redux/reducers"
import { ScheduleReservation } from "../redux/types"

import "./Place.sass"

type Props = {
	id?: string
	x: number
	y: number
	title: string
	amenities?: IdAndName[]
	departments?: DepartmentResponse[]
	reservations?: ScheduleReservation[]
	mapWidth: number
	mapHeight: number
	scale?: number
	onClick?: () => void
	onMouseOver?: () => void
	onMouseOut?: () => void
	onToggleReposition?: () => void
	onDownDragPlace?: (id: string | undefined) => void
	onStopDragPlace?: (e: DraggableEvent, data: DraggableData) => void
	isInactive?: boolean
	isUnmarked?: boolean
	isBlinking?: boolean
	isAvailable?: boolean
	isOccupied?: boolean
	isDisabled?: boolean
	isSelected?: boolean
	isFocused?: boolean
	isCurrentlyBooked?: boolean
	isHovered?: boolean
	isHighlighted?: boolean
}

export default function Place({
	id,
	x,
	y,
	title,
	amenities,
	departments,
	reservations,
	mapWidth,
	mapHeight,
	scale = 1,
	onClick,
	onMouseOver,
	onMouseOut,
	onToggleReposition,
	onDownDragPlace,
	onStopDragPlace,
	isInactive = false,
	isUnmarked = false,
	isBlinking = false,
	isAvailable = false,
	isOccupied = false,
	isDisabled = false,
	isSelected = false,
	isFocused = false,
	isCurrentlyBooked = false,
	isHovered = false,
	isHighlighted = false,
}: Props) {
	const placeRef = useRef<HTMLDivElement>(null)

	const { reposition_desk } = useAppSelector((state) => state.app)

	const isRepositionDesk = reposition_desk && reposition_desk.id

	const isDisabledDrag =
		!isSelected || !isRepositionDesk || id !== reposition_desk.id

	const [isOpen, setIsOpen] = useState(false)

	const [currentPosition, setCurrentPosition] = useState({
		x: x / mapWidth,
		y: y / mapHeight,
	})

	const placeStyle = useMemo(
		() => ({
			top: `${(y / mapHeight) * 100}%`,
			left: `${(x / mapWidth) * 100}%`,
		}),
		[x, y, mapHeight, mapWidth],
	)
	const uniqueId = `${x}${y}`

	const handleMouseClick = useCallback(() => {
		if (isDisabled) {
			return undefined
		}

		onClick?.()
	}, [onClick, isDisabled])

	const handleLongMouseClick = useCallback(() => {
		if (isDisabled) {
			return undefined
		}

		if (!isSelected) {
			onClick?.()
		}

		onToggleReposition?.()
	}, [isDisabled, isSelected, onToggleReposition, onClick])

	const handleDragPlace = (e: DraggableEvent, data: DraggableData) =>
		setCurrentPosition({ x: data.x, y: data.y })

	const handleResetCoords = () => setCurrentPosition({ x: 0, y: 0 })

	const longPressEvent = useLongPress(
		{
			onLongPress: handleLongMouseClick,
			onClick: handleMouseClick,
		},
		{
			delay: 175,
			shouldPreventDefault: true,
		},
	)

	useEffect(() => {
		if (isHovered || isSelected || isHighlighted) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [isHighlighted, isHovered, isSelected])

	const placeClassName = classNames({
		Place: true,
		isInactive,
		isUnmarked,
		isBlinking,
		isFocused,
		isSelected,
		isAvailable,
		isOccupied,
		isDisabled,
		isCurrentlyBooked,
		isHovered,
		isHighlighted,
	})

	const wrapperClassName = classNames("PlaceWrapper", {
		"show-tooltip": isOpen,
	})

	return (
		<Draggable
			nodeRef={placeRef}
			axis={isDisabledDrag ? "none" : "both"}
			position={currentPosition}
			scale={scale}
			onMouseDown={() => (onDownDragPlace ? onDownDragPlace(id) : undefined)}
			onDrag={(e, data) =>
				!isRepositionDesk ? handleResetCoords() : handleDragPlace(e, data)
			}
			onStop={onStopDragPlace}
			bounds="parent"
		>
			<div ref={placeRef} className={wrapperClassName} style={placeStyle}>
				<PlaceTooltip
					title={title}
					amenities={amenities}
					departments={departments}
					reservations={reservations}
					uniqueId={uniqueId}
				>
					<span
						className={placeClassName}
						id={uniqueId}
						onMouseOver={onMouseOver}
						onMouseOut={onMouseOut}
						{...longPressEvent}
					>
						&nbsp;
					</span>
				</PlaceTooltip>
			</div>
		</Draggable>
	)
}
