import React from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	cancelEvent,
	checkinEvent,
	extendEvent,
	finishEvent,
} from "../../redux/events/eventsSlice"
import { selectEvents } from "../../redux/events/selectors"
import { EventResponse } from "../../redux/events/types"
import { normalizeEventEmail } from "../../redux/events/utils"
import { useAppSelector } from "../../redux/reducers"
import { dateRangeOverlaps } from "../../redux/timeslots/utils"
import { selectUser } from "../../redux/user/selectors"
import { useActions } from "../../redux/utils"

import "./EventActions.sass"

type EventActionsProps = {
	event: EventResponse
	small?: boolean
}

export const EventActions: React.FC<EventActionsProps> = ({
	event,
	small = false,
}) => {
	const { errorToast } = useToast()
	const { openModal, closeModal } = useModals()
	const { t } = useTranslation()
	const history = useHistory()

	const viewClass = classNames({
		"actions-row": true,
		small,
	})

	const { entry: currentUser } = useAppSelector(selectUser)
	const { entries: events } = useAppSelector(selectEvents)

	const actions = useActions({
		checkinEvent: (event: EventResponse, lifetime: number) =>
			checkinEvent({ event, lifetime }),
		extendEvent: (event: EventResponse, extendUntil: string) =>
			extendEvent({ event, extend_until: extendUntil }),
		finishEvent: (event: EventResponse) => finishEvent(event),
		cancelEvent: (event: EventResponse) => cancelEvent(event),
	})

	const today = dayjs()

	let canCheckin = false
	const checkInTime = event.room.settings.check_into_meeting
	if (checkInTime > 0) {
		canCheckin =
			today.isAfter(dayjs(event.start).add(-1 * checkInTime, "minute")) &&
			today.isBefore(dayjs(event.start).add(checkInTime, "minute"))

		canCheckin = canCheckin && !event.confirmed
	}

	const handleCheckIn = async () => {
		const start = dayjs(event.start)
		const end = dayjs(event.end)

		const lifetime = end.diff(start, "second") + 3600

		const response = await actions.checkinEvent(event, lifetime)

		if (checkinEvent.rejected.match(response)) {
			errorToast(response.error?.message)
		}
	}

	let canExtend =
		event &&
		today.isAfter(dayjs(event.start)) &&
		today.isBefore(dayjs(event.end))

	let extendFor = 15

	let origEnd = dayjs(event.end)
	let extEnd = dayjs(event.end).add(extendFor, "minute")

	const oEvent = events.find(
		(e: EventResponse) =>
			e.room.email === event.room.email &&
			e.id !== event.id &&
			dateRangeOverlaps(
				origEnd.toDate(),
				extEnd.toDate(),
				new Date(e.start),
				new Date(e.end),
			),
	)

	if (oEvent) {
		extendFor = dayjs(oEvent.start).diff(origEnd, "minute")
	}

	const handleExtend = async () => {
		extEnd = origEnd.add(extendFor, "minute")

		if (extendFor > 0) {
			const response = await actions.extendEvent(event, extEnd.toISOString())

			if (extendEvent.rejected.match(response)) {
				errorToast(response.error?.message)
			}
		}
	}

	let canEnd =
		event &&
		today.isAfter(dayjs(event.start)) &&
		today.isBefore(dayjs(event.end))

	const handleEnd = async () => {
		const response = await actions.finishEvent(event)

		if (finishEvent.rejected.match(response)) {
			errorToast(response.error?.message)
		}
	}

	const eventStart = event && dayjs(event.start)
	const hasntStarted = dayjs().isBefore(eventStart)
	const isOrganizer =
		event &&
		currentUser &&
		normalizeEventEmail(event.organizer?.email) ===
			normalizeEventEmail(currentUser.email)
	let canCancel = isOrganizer && hasntStarted

	const handleCancel = async () => {
		const response = await actions.cancelEvent(event)

		if (cancelEvent.rejected.match(response)) {
			errorToast(response.error?.message)
			return
		}

		analyticsEvent(SupportedEvents.ROOM_BOOKING_CANCEL, {
			id: event?.id,
		})

		history.push("/home/reservations")
	}

	const actionClicked = (type: "checkin" | "extend" | "end" | "cancel") => {
		let title = ""
		let func = () => {}

		switch (type) {
			case "checkin":
				func = handleCheckIn
				title = t("mobile.home.confirm_checkin")
				break
			case "extend":
				func = handleExtend
				title = t("mobile.home.confirm_extend")
				break
			case "end":
				func = handleEnd
				title = t("mobile.home.confirm_finish")
				break
			default:
				func = handleCancel
				title = t("mobile.home.confirm_cancel")
				break
		}

		openModal(ConfirmationModal, {
			title,
			onConfirm: () => {
				func()
				closeModal()
			},
		})
	}

	return (
		<div className="EventActions">
			{(canCheckin || (canExtend && extendFor > 0) || canEnd || canCancel) && (
				<div className={viewClass}>
					{canCheckin && (
						<Button
							variant="link"
							className="action"
							onClick={() => actionClicked("checkin")}
						>
							{t("mobile.general.check_in")}
						</Button>
					)}
					{canExtend && extendFor > 0 && (
						<Button
							variant="link"
							className="action"
							onClick={() => actionClicked("extend")}
						>
							{t("mobile.general.extend")}
						</Button>
					)}
					{canEnd && (
						<Button
							variant="link"
							className="action"
							onClick={() => actionClicked("end")}
						>
							{t("mobile.general.end")}
						</Button>
					)}
					{canCancel && (
						<Button
							variant="link"
							className="action"
							onClick={() => actionClicked("cancel")}
						>
							{t("mobile.general.cancel")}
						</Button>
					)}
				</div>
			)}
		</div>
	)
}
