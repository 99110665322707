import React, { ReactNode } from "react"

import classNames from "classnames"

import "./Label.sass"

type Props = {
	subText?: ReactNode
	children: ReactNode
	forInput?: string
	className?: string
}

export const Label = ({ children, subText, forInput, className }: Props) => {
	const cn = classNames(["Label", className])

	return (
		<label htmlFor={forInput} className={cn}>
			{children}
			{subText && <span className="subText">{subText}</span>}
		</label>
	)
}
