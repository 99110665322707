import React, { useEffect, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { fetchDocument } from "../../../redux/documents/documentsSlice"
import { selectDocuments } from "../../../redux/documents/selectors"
import { DocumentViewType } from "../../../redux/documents/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Breadcrumbs from "../../../components/Breadcrumbs"
import Card from "../../../components/Card"
import { PageActions } from "../../../components/Form/PageFormHook"
import DocumentForm from "../../../components/Form/Visitors/DocumentForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"
import DocumentView from "../../../components/Visitors/DocumentView"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"

import "./DocumentEdit.sass"

type ParamsType = {
	id?: string
}

const DocumentEdit = () => {
	const { t } = useTranslation()
	const { id } = useParams<ParamsType>()

	const [documentPreview, setDocumentPreview] = useState<
		DocumentViewType | undefined
	>()

	const { entries: documents, isLoading } = useAppSelector(selectDocuments)

	const document = documents.find((d) => d.id === id)

	const actions = useActions({
		fetchDocument: (id: string) => fetchDocument(id),
	})

	useEffect(() => {
		if (id && !document) {
			actions.fetchDocument(id)
		}
	}, [actions, id, document])

	return (
		<View className="DocumentEdit">
			<Breadcrumbs
				depth={3}
				values={[
					t("desktop.settings.visitors.title"),
					t("desktop.settings.visitors.documents.title"),
					t(
						`desktop.settings.visitors.documents.${
							id ? "edit_document" : "new_document"
						}`,
					),
				]}
			/>

			<Intro isConstrained>
				{t("desktop.settings.visitors.documents.form.subtitle")}
			</Intro>

			<Space size={0.75} />

			{id && isLoading ? (
				<div className="loading">
					<Loader />
				</div>
			) : (id && document) || (!id && !document) ? (
				<div className="DocumentWrapper">
					{documentPreview && (
						<div className="DocumentPreview">
							<Card className="DocumentViewCard">
								<DocumentView document={documentPreview} />
							</Card>

							<PageActions>
								<Button
									variant="secondary"
									onClick={() => setDocumentPreview(undefined)}
								>
									{t("desktop.settings.visitors.documents.close_preview")}
								</Button>
							</PageActions>
						</div>
					)}

					<div
						className={classNames("DocumentForm", {
							hidden: documentPreview,
						})}
					>
						<DocumentForm
							document={document}
							onShowPreview={setDocumentPreview}
						/>
					</div>
				</div>
			) : (
				<div>{t("desktop.settings.visitors.documents.no_document")}</div>
			)}
		</View>
	)
}

export default DocumentEdit
