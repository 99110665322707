import React, { StrictMode } from "react"

import { Provider } from "react-redux"

import App from "./App"
import "./analytics"
import "./dayjs"
import "./i18n"
import "./index"
import "./polyfills"
import { createRoot } from "react-dom/client"

import { store } from "./redux/reducers"

import { BranchName } from "./components/BranchName"

import "./index.sass"

/**
 * DO NOT REMOVE THE LINES BELOW
 *
 * It is used by the Google Tag Manager
 */
const w: any = window
w.JoanOfficeStore = store
/** */

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
	<StrictMode>
		<Provider store={store}>
			<BranchName />
			<App />
		</Provider>
	</StrictMode>,
)
