import React, { FC, useState } from "react"

import classNames from "classnames"

import "./HorizontalListSelect.sass"

export type HorizontalListProps = {
	listElements: ({ name: string; id: string } & Record<string, any>)[]
	onSelect: (text: string) => void
	preSelected?: string
}

export const HorizontalListSelect: FC<React.PropsWithChildren<HorizontalListProps>> = ({
	listElements,
	onSelect,
	preSelected = "",
	children,
}) => {
	const [selected, setSelected] = useState<string>(preSelected)

	const handleOnClick = (id: string) => {
		setSelected(id)
		onSelect(id)
	}

	return (
		<div className="horizontal-list-selector">
			<div className="label">{children}</div>
			<div className="horizontal-scroll">
				{listElements.map((el) => {
					const classes = classNames("list-item", {
						selected: el.id === selected,
					})

					return (
						<div
							className={classes}
							key={el.id}
							onClick={() => handleOnClick(el.id)}
						>
							{el.name}
						</div>
					)
				})}
			</div>
		</div>
	)
}
