import { useTranslation } from "react-i18next"

import { COUNTRIES } from "../../../../constants"

import { CustomerInfo } from "../../../../redux/api/billing/customerInfo/types"

import Button from "../../../../components/basic/Button"

import { ReactComponent as EditSVG } from "../../../../assets/images/icons/Pencil.svg"

import "./CompanyDetails.sass"

type CompanyDetailsProps = {
	companyDetails?: CustomerInfo | null
	onButtonClick: () => void
}

type CompanyInfoProps = {
	companyDetails: CustomerInfo
	onButtonClick: () => void
}

/**
 * CompanyInfo
 */
const CompanyInfo = ({ companyDetails, onButtonClick }: CompanyInfoProps) => {
	const { t } = useTranslation()

	return (
		<>
			<div className="company-info">
				<div>
					<p>{companyDetails.company}</p>
					<p>
						{companyDetails.address} {companyDetails.secondary_address}
					</p>
					<p>
						{companyDetails.zip}, {companyDetails.city}
					</p>
					<p>{COUNTRIES[companyDetails.country] ?? ""}</p>
				</div>
				<div>
					<p>
						{t("desktop.settings.billing.billing_details.name")}:{" "}
						{companyDetails.first_name} {companyDetails.last_name}
					</p>
					<p>
						{t("desktop.settings.billing.billing_details.email")}:{" "}
						{companyDetails.email}
					</p>
					<p>
						{t("desktop.settings.billing.billing_details.id_for_vat")}:{" "}
						{companyDetails.vat_number}
					</p>
				</div>
			</div>
			<Button variant="link" onClick={onButtonClick} icon={<EditSVG />}>
				{t(
					"desktop.settings.billing.billing_details.button.edit_billing_details",
				)}
			</Button>
		</>
	)
}

/**
 * MissingBillingDetails
 */
const MissingBillingDetails = ({ onClick }: { onClick: () => void }) => {
	const { t } = useTranslation()

	return (
		<div className="MissingBillingDetails">
			<p>{t("desktop.settings.billing.payment_details.no_billing_details")}</p>
			<p>
				{t(
					"desktop.settings.billing.payment_details.no_billing_details_action",
				)}
			</p>
			<Button variant="link" onClick={onClick} icon={<EditSVG />}>
				{t("desktop.settings.billing.payment_details.buttons.add_billing_info")}
			</Button>
		</div>
	)
}

/**
 * CompanyDetails
 */
const CompanyDetails = ({
	companyDetails,
	onButtonClick,
}: CompanyDetailsProps) => {
	return (
		<div className="CompanyDetails">
			{companyDetails ? (
				<CompanyInfo
					companyDetails={companyDetails}
					onButtonClick={onButtonClick}
				/>
			) : (
				<MissingBillingDetails onClick={onButtonClick} />
			)}
		</div>
	)
}

export default CompanyDetails
