import { RootState } from "./reducers"
import { createSelector } from "@reduxjs/toolkit"

export const getAuth = (state: RootState) => state.auth

export const selectIsAuth = createSelector(getAuth, ({ access_token }) => {
	return !!access_token
})

export const selectIsLogin = createSelector(getAuth, (authState) => {
	return authState.isLogin
})
