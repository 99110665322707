import React, { useState } from "react"

import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../analytics"
import { useToast } from "../hooks/useToast"
import { ButtonProps } from "./basic/Button"
import { UploadFile } from "./basic/UploadFile"

import { useUpdateFloorMutation } from "../redux/api/floors"
import { isApiResponseError, isRejected } from "../redux/api/types"
import { uploadDeskFile } from "../redux/files/filesSlice"
import { FloorResponse } from "../redux/floors/types"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

const URLHelper = window.URL || window.webkitURL

type Props = {
	buildingId: string
	floor: FloorResponse
	hasFloorPlan: boolean
	buttonProps?: ButtonProps
}

const FileUploadAction = ({
	buildingId,
	floor,
	hasFloorPlan,
	buttonProps,
}: Props) => {
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const actions = useActions({
		uploadDeskFile: (file: File) => uploadDeskFile(file),
	})

	const [updateFloor, { isLoading: areFloorsLoading }] =
		useUpdateFloorMutation()

	const { isLoading: areFilesLoading } = useAppSelector((state) => state.files)

	const loading = areFilesLoading || areFloorsLoading

	const [failedAttempts, setFailedAttempts] = useState(0)

	const handleChange = async (files: FileList | null) => {
		const file = files && files.length > 0 && files[0]

		if (file) {
			const response = await actions.uploadDeskFile(file)

			if (uploadDeskFile.rejected.match(response)) {
				errorToast(
					t("desktop.settings.floor_plans.upload_image.something_wrong"),
				)

				if (failedAttempts >= 2) {
					infoToast(
						t("desktop.settings.floor_plans.upload_image.contact_support"),
					)
				} else {
					infoToast(t("desktop.settings.floor_plans.upload_image.smaller_file"))
				}
				setFailedAttempts(failedAttempts + 1)
				return false
			}

			let imageURL = response.payload.url
			if (imageURL[imageURL.length - 1] === "/") {
				imageURL = imageURL.substring(0, imageURL.length - 1)
			}

			const img = new Image()
			img.onload = async function () {
				const response = await updateFloor({
					id: floor.id,
					building_id: buildingId,
					name: floor.name,
					capacity_limit: floor.capacity_limit,
					image: imageURL,
					height: img.height,
					width: img.width,
					active: floor.active,
				})

				if (isRejected(response)) {
					const { error } = response

					if (isApiResponseError(error)) {
						errorToast(error.message)
					}
				} else {
					infoToast(t("desktop.settings.floor_plans.upload_image.success"))

					if (hasFloorPlan) {
						analyticsEvent(SupportedEvents.FLOORPLAN_EDIT, {
							id: floor.id,
							name: floor.name,
						})
					} else {
						analyticsEvent(SupportedEvents.FLOORPLAN_ADD, {
							id: floor.id,
							name: floor.name,
						})
					}
				}
			}

			img.src = URLHelper.createObjectURL(file)
		}
	}

	return (
		<div className="FileUploadAction">
			<UploadFile
				onChange={handleChange}
				loading={loading}
				failedAttempts={failedAttempts}
				buttonProps={buttonProps}
			>
				{hasFloorPlan
					? t("desktop.settings.floor_plans.upload_image.replace")
					: t("desktop.settings.floor_plans.upload_image.upload")}
			</UploadFile>
		</div>
	)
}

export default FileUploadAction
