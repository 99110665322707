import { useCallback, useMemo, useState } from "react"

import classNames from "classnames"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { buildingsURL, departmentsURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import Field from "../Field"
import AsyncSelect from "../advanced/AsyncSelect"
import { Input } from "../basic/Input"
import { InputPhone } from "../basic/InputPhone"
import { RadioGroup } from "../basic/Radio"
import ModalForm from "./ModalFormHook"
import { setErrors } from "./formUtils"
import { useModals } from "@mattjennings/react-modal-stack"

import { DepartmentResponse } from "../../redux/api/departments/types"
import { BuildingResponse } from "../../redux/buildings/types"
import { useAppSelector } from "../../redux/reducers"
import { selectUserPermissions } from "../../redux/user/selectors"
import { fetchUser } from "../../redux/user/userSlice"
import { FetchOptions, UserRequest } from "../../redux/users/types"
import {
	createUser,
	destroyUser,
	fetchUsers,
	updateUser,
} from "../../redux/users/usersSlice"
import { useActions } from "../../redux/utils"

import "./PersonForm.sass"

const FORM_MAPPING = {
	building_id: "building",
} as const

type Props = {
	person: any
	fetchOptions?: FetchOptions
	refetch?: () => void
}

type FormValues = {
	first_name: string
	last_name: string
	email: string
	phone: string
	building?: BuildingResponse
	groups: string[]
	departments?: DepartmentResponse[]
}

const PersonForm = ({ person, fetchOptions, refetch }: Props) => {
	const { closeModal } = useModals()
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const { entry: user } = useAppSelector((state) => state.user)

	const actions = useActions({
		fetchUser: () => fetchUser(),
		fetchUsers: (options?: FetchOptions) => fetchUsers(options),
		createManagementUser: (payload: UserRequest) => createUser(payload),
		updateManagementUser: (email: string, payload: UserRequest) =>
			updateUser({ email, payload }),
		destroyManagementUser: (email: string) => destroyUser(email),
	})

	const { entry: scim } = useAppSelector((state) => state.scim)

	const { enabled: isSCIMEnabled } = scim ?? {}
	const isEditingEnabled = !isSCIMEnabled

	const {
		id,
		first_name,
		last_name,
		email,
		type,
		departments,
		building,
		phone,
	} = person || {}

	const personNotProvided = !id && email === ""

	const methods = useForm<FormValues>({
		defaultValues: {
			first_name: first_name ?? "",
			last_name: last_name ?? "",
			email: email ?? "",
			phone: phone ?? "",
			building: building,
			groups: [type],
			departments,
		},
	})

	const userGroupOptions = useMemo(
		() => [
			{
				value: "portal_user",
				label: t("desktop.settings.person.person_form.employee"),
			},
			{
				value: "portal_officer_manager",
				label: t("desktop.settings.person.person_form.office_manager"),
			},
		],
		[t],
	)

	const { setError, control } = methods

	const [personType, setPersonType] = useState(type || "")

	const onCreateClick = useCallback(
		async ({
			first_name,
			last_name,
			phone,
			building,
			email,
			departments,
		}: FormValues) => {
			const response = await actions.createManagementUser({
				first_name,
				last_name,
				phone,
				email,
				building_id: building?.id,
				groups: [personType],
				departments: departments?.map((d: DepartmentResponse) => d.id),
			})

			if (createUser.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast, FORM_MAPPING)
				}
			} else {
				const usersResponse = await actions.fetchUsers()
				if (fetchUsers.fulfilled.match(usersResponse)) {
					analyticsEvent(SupportedEvents.PEOPLE_ADD, {
						num_users_added: 1,
						total: usersResponse.payload.count,
					})
				}
				infoToast(t("desktop.settings.person.person_form.person_created"))
				refetch?.()
				closeModal()
			}
		},
		[
			actions,
			personType,
			setError,
			errorToast,
			infoToast,
			t,
			refetch,
			closeModal,
		],
	)

	const onUpdateClick = useCallback(
		async ({
			first_name,
			last_name,
			phone,
			building,
			departments,
		}: FormValues) => {
			const response = await actions.updateManagementUser(email, {
				first_name,
				last_name,
				phone,
				email,
				building_id: building?.id,
				groups: [personType],
				departments: departments?.map((d: DepartmentResponse) => d.id),
			})

			if (updateUser.rejected.match(response)) {
				if (response.payload) {
					setErrors(response.payload, setError, errorToast, FORM_MAPPING)
				}
			} else {
				await actions.fetchUsers(fetchOptions)

				if (email === user.email) {
					await actions.fetchUser()
				}
				infoToast(t("desktop.settings.person.person_form.person_updated"))
				refetch?.()
				closeModal()
			}
		},
		[
			actions,
			email,
			personType,
			setError,
			errorToast,
			fetchOptions,
			user.email,
			infoToast,
			t,
			refetch,
			closeModal,
		],
	)

	const onDeleteClick = useCallback(async () => {
		const response = await actions.destroyManagementUser(email)

		if (destroyUser.rejected.match(response)) {
			if (response.payload) {
				setErrors(response.payload, setError, errorToast, FORM_MAPPING)
			}
		} else {
			const usersResponse = await actions.fetchUsers()
			infoToast(t("desktop.settings.person.person_form.person_deleted"))

			if (fetchUsers.fulfilled.match(usersResponse)) {
				analyticsEvent(SupportedEvents.PEOPLE_REMOVE, {
					num_users_removed: 1,
					total: usersResponse.payload.count,
				})
			}
			closeModal()
		}
	}, [actions, email, closeModal, infoToast, errorToast, setError, t])

	const permissions = useAppSelector(selectUserPermissions)
	const isCurrentUser = email === user.email
	const isPortalAdmin = personType === "portal_admin"
	const hasPermissions =
		permissions.includes("users.change_user") || isCurrentUser

	const modalClasses = classNames("PersonForm ModalForm", {
		AlignRight: isCurrentUser, // If Delete is disabled, align action button to right
	})

	const formTitle = personNotProvided
		? t("desktop.settings.person.person_form.new_person")
		: t("desktop.settings.person.person_form.edit_person")

	return (
		<FormProvider {...methods}>
			<ModalForm
				className={modalClasses}
				title={formTitle}
				updateMode={!personNotProvided}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={hasPermissions && !isCurrentUser ? onDeleteClick : undefined}
			>
				<Field
					control={control}
					name="first_name"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.first_name")}
				>
					{(props) => (
						<Input
							{...props}
							maxLength={30}
							disabled={!(hasPermissions && isEditingEnabled)}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="last_name"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.last_name")}
				>
					{(props) => (
						<Input
							{...props}
							maxLength={30}
							disabled={!(hasPermissions && isEditingEnabled)}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="email"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.email")}
				>
					{(props) => (
						<Input
							{...props}
							type="email"
							disabled={
								!(hasPermissions && isEditingEnabled && personNotProvided)
							}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="phone"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.phone_number")}
				>
					{(props) => (
						<InputPhone
							{...props}
							placeholder="+386 68 123 456"
							disabled={!(hasPermissions && isEditingEnabled)}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="building"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.default_building")}
				>
					{(props) => (
						<AsyncSelect
							urlGenerator={(fetchOptions) => {
								return buildingsURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.person.person_form.errors.no_building_found",
							)}
							getOptionLabel={(building) => building.name}
							getOptionValue={(building) => building.id}
							{...props}
						/>
					)}
				</Field>
				<Field
					control={control}
					name="departments"
					className="field-width-50"
					label={t("desktop.settings.person.person_form.departments")}
				>
					{({ ref, ...props }) => (
						<AsyncSelect<DepartmentResponse, true>
							urlGenerator={(fetchOptions) => {
								return departmentsURL(fetchOptions)
							}}
							nothingFoundMessage={t(
								"desktop.settings.person.person_form.errors.no_departments_found",
							)}
							getOptionLabel={(department) => department.name}
							getOptionValue={(department) => department.id}
							isMulti
							{...props}
						/>
					)}
				</Field>
				{!isCurrentUser && !isPortalAdmin && (
					<Field control={control} name="groups" className="field-width-50">
						{({ ref, ...props }) => (
							<RadioGroup
								{...props}
								options={userGroupOptions}
								value={personType}
								onChange={setPersonType}
								disabled={!hasPermissions}
							/>
						)}
					</Field>
				)}
			</ModalForm>
		</FormProvider>
	)
}

export default PersonForm
