import { useTranslation } from "react-i18next"

import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"
import Button from "../../components/basic/Button"

type ErrorProps = {
	error: string
}

export const Error = ({ error }: ErrorProps) => {
	const { t } = useTranslation()

	const reloadPage = () => window.location.reload()

	return (
		<VisitorView>
			<VisitorHeader />
			<VisitorContent>
				<div className="title">{t("tablet.visitors.screens.error.title")}</div>
				<div className="info">{error}</div>
			</VisitorContent>
			<VisitorFooter>
				<div>
					<Button onClick={reloadPage}>
						{t("tablet.visitors.screens.error.retry")}
					</Button>
				</div>
				<div>
					<Button variant="link" href="https://support.getjoan.com/knowledge">
						{t("tablet.visitors.screens.error.contact_support")}
					</Button>
				</div>
			</VisitorFooter>
		</VisitorView>
	)
}
