import React, { HTMLProps, forwardRef, useCallback, useMemo } from "react"

import classNames from "classnames"

import { Children } from "../../../types/sharedTypes"
import { generateString } from "../../../utils"
import { Label } from "../../Field/Label"
import { Tooltip } from "../Tooltip"

import { ReactComponent as LockSVG } from "../../../assets/images/icons/Lock.svg"

import "./style.sass"

export type RadioProps = {
	className?: string
	value?: string
	checked?: boolean
	onChange?: (value: string) => void
	disabled?: boolean
	locked?: boolean
	label?: string
	hasError?: boolean
	tooltip?: Children
	key?: string
} & Omit<HTMLProps<HTMLInputElement>, "onChange" | "value">

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
	({ className, checked, onChange, locked, label, ...props }, ref) => {
		const recognizer = useMemo(() => {
			return props.name || generateString(5)
		}, [props.name])

		const innerOnChange = useCallback(() => {
			if (props.disabled || locked) return
			onChange && onChange(props.value ?? "")
		}, [onChange, props.disabled, props.value, locked])

		return (
			<div
				key={props.key}
				className={classNames([
					{
						checked,
						disabled: !!props.disabled,
					},
					"Radio",
					className,
				])}
				onClick={innerOnChange}
			>
				<input {...props} id={recognizer} type="radio" ref={ref} />
				{label && (
					<Label className="RadioLabel" forInput={recognizer}>
						{label}
					</Label>
				)}
				{locked && <LockSVG className="lock" />}
			</div>
		)
	},
)

type RadioGroupProps = {
	display?: "horizontal" | "vertical"
	className?: string
	options: RadioProps[]
	value?: string
	name?: string
	onChange: (v: string) => void
	disabled?: boolean
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
	(
		{
			className,
			options,
			value,
			name,
			onChange,
			disabled: radioGroupDisabled,
			display = "horizontal",
		},
		ref,
	) => {
		return (
			<div ref={ref} className={classNames(["RadioGroup", className, display])}>
				{options.map(({ disabled: radioDisabled, tooltip, ...opt }, i) => {
					const checked = value === opt.value
					const disabled = radioDisabled || radioGroupDisabled
					const locked = checked && radioGroupDisabled
					const uniqueName = `${name}-${i}`

					const RadioButton = ({ key }: { key?: string }) => (
						<Radio
							{...opt}
							key={key}
							name={uniqueName}
							checked={checked}
							onChange={onChange}
							disabled={disabled}
							locked={locked}
							ref={undefined}
						/>
					)

					if (tooltip) {
						return (
							<Tooltip
								key={uniqueName}
								uniqueId={uniqueName}
								content={tooltip}
								className="radio-tooltip"
							>
								<RadioButton />
							</Tooltip>
						)
					}

					return <RadioButton key={uniqueName} />
				})}
			</div>
		)
	},
)
