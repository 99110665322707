import React from "react"

import { useTranslation } from "react-i18next"

import "./WeekendToggle.sass"

type Props = {
	showWeekends: boolean
	onToggle: () => void
}

export default function WeekendToggle({ showWeekends, onToggle }: Props) {
	const { t } = useTranslation()
	return (
		<span className="WeekendToggle" onClick={onToggle}>
			{showWeekends ? t("general.hide_weekend") : t("general.show_weekend")}
		</span>
	)
}
