import { PropsWithChildren, useEffect, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"
import { Week, WeekPicker, isWeekday } from "../advanced/WeekPicker"
import { DatePicker } from "../basic/DatePicker"

import { changeDay, changeWeek } from "../../redux/app/appSlice"
import { selectApp } from "../../redux/app/selectors"
import { useAppSelector } from "../../redux/reducers"
import { useActions } from "../../redux/utils"

import { ReactComponent as ArrowBackSVG } from "../../assets/images/icons/Arrow back.svg"
import { ReactComponent as ArrowForwardSVG } from "../../assets/images/icons/Arrow forward.svg"
import { ReactComponent as DaySVG } from "../../assets/images/icons/Day.svg"
import { ReactComponent as WeekSVG } from "../../assets/images/icons/Week.svg"

import "./CalNav.sass"

function rejectClick(reason?: string) {
	toast.info(reason, {
		hideProgressBar: true,
	})
}

type WeekNavBtnProps = {
	className: any
	onClick: () => void
	disabled?: boolean
	disabledMessage?: string
}

const WeekNavBtn = ({
	className,
	onClick,
	disabled,
	disabledMessage,
	children,
}: PropsWithChildren<WeekNavBtnProps>) => {
	const btnClassName = classNames({
		[className]: !!className,
		disabled: disabled,
	})

	return (
		<div
			className={btnClassName}
			onClickCapture={!disabled ? onClick : () => rejectClick(disabledMessage)}
		>
			{children}
		</div>
	)
}

type WeekNavProps = {
	prevDisabled?: boolean
}

/**
 * WeekNav
 */
export const WeekNav = ({ prevDisabled = false }: WeekNavProps) => {
	const actions = useActions({
		changeWeek: (start: Dayjs, end: Dayjs) =>
			changeWeek({ start: start.toISOString(), end: end.toISOString() }),
	})

	const desk_reservation_window_length = useCheckReservationWindowLength({
		adminSettings: true,
	})

	const { fromDate, showWeekends } = useAppSelector(selectApp)
	const { t } = useTranslation()

	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const today = dayjs().format("YYYY-MM-DD")

	const lastDay = dayjs(today)
		.add(desk_reservation_window_length ?? 7, "day")
		.endOf("day")

	const nextWeekDay = fromDate.add(7, "day")
	const nextDisabled = nextWeekDay.isAfter(lastDay)

	const [selectedWeek, setSelectedWeek] = useState<Week | null>({
		start: fromDate.weekday(1).startOf("day"),
		end: fromDate.weekday(showWeekends ? 7 : 5).endOf("day"),
	})

	const handlePrevWeekClick = () => {
		const newStart = dayjs(fromDate).subtract(7, "day").startOf("day")
		const newEnd = dayjs(newStart).add(6, "day").endOf("day")

		actions.changeWeek(newStart, newEnd)
	}

	const handleNextWeekClick = () => {
		const newStart = dayjs(fromDate).add(7, "day").startOf("day")
		const newEnd = dayjs(newStart).add(6, "day").endOf("day")

		actions.changeWeek(newStart, newEnd)
	}

	const handleThisWeekClick = () => {
		setIsCalendarOpen(true)
	}

	const handleWeekChange = (week: Week | null) => {
		if (!week) {
			setSelectedWeek(null)
			return
		}
		setSelectedWeek(week)
		actions.changeWeek(week.start, week.end.weekday(7))
		setIsCalendarOpen(false)
	}

	useEffect(() => {
		setSelectedWeek({
			start: fromDate.weekday(1).startOf("day"),
			end: fromDate.weekday(showWeekends ? 7 : 5).endOf("day"),
		})
	}, [showWeekends, fromDate])

	return (
		<div className="WeekNav">
			<WeekNavBtn
				className="prev"
				onClick={handlePrevWeekClick}
				disabled={prevDisabled}
			>
				<ArrowBackSVG />
			</WeekNavBtn>

			<WeekPicker
				value={selectedWeek}
				onChange={handleWeekChange}
				open={isCalendarOpen}
				maxDate={lastDay.toDate()}
				todayButtonText={t("desktop.components.cal_nav.select_current_week")}
				minDate={prevDisabled ? dayjs().weekday(1).toDate() : undefined}
				includeWeekends={showWeekends}
				onClickOutside={() => setIsCalendarOpen(false)}
			/>
			<WeekNavBtn className="week" onClick={handleThisWeekClick}>
				<WeekSVG />
			</WeekNavBtn>

			<WeekNavBtn
				disabled={nextDisabled}
				className="next"
				onClick={handleNextWeekClick}
			>
				<ArrowForwardSVG />
			</WeekNavBtn>
		</div>
	)
}

type DayNavProps = {
	noMaxDateSet?: boolean
}
/**
 * DayNav
 */
export const DayNav = ({ noMaxDateSet = false }: DayNavProps) => {
	const actions = useActions({
		changeDay: (day: Dayjs) => changeDay(day.toISOString()),
	})

	const { currentDate, showWeekends } = useAppSelector(selectApp)

	const desk_reservation_window_length = useCheckReservationWindowLength({
		adminSettings: true,
	})

	const dayStart = dayjs(currentDate).startOf("day")
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const today = dayjs().format("YYYY-MM-DD")
	const lastDay = dayjs(today).add(desk_reservation_window_length ?? 7, "day")
	const nextDayDate = dayjs(dayStart).add(1, "day")
	const nextDisabled = noMaxDateSet ? false : nextDayDate.isAfter(lastDay)

	const handleNextDayClick = () => {
		const friday = dayjs(currentDate).weekday(5)

		let newDay

		if (showWeekends) {
			newDay = dayjs(currentDate).add(1, "day")
		} else {
			if (dayjs(currentDate).isSame(friday)) {
				newDay = dayjs(currentDate).add(3, "day")
			} else {
				newDay = dayjs(currentDate).add(1, "day")
			}
		}

		actions.changeDay(newDay)
	}

	const handlePrevDayClick = () => {
		const monday = dayjs(currentDate).weekday(1)

		let newDay
		if (showWeekends) {
			newDay = dayjs(currentDate).subtract(1, "day")
		} else {
			if (dayjs(currentDate).isSame(monday)) {
				newDay = dayjs(currentDate).subtract(3, "day")
			} else {
				newDay = dayjs(currentDate).subtract(1, "day")
			}
		}

		actions.changeDay(newDay)
	}

	const handleThisDayClick = () => {
		setIsCalendarOpen(true)
	}

	const handleDayChange = (d: Date | null) => {
		if (d === null) {
			return
		}
		const saturday = dayjs(d).weekday(6)
		const sunday = dayjs(d).weekday(0)
		let newDay = dayjs(d)

		if (!showWeekends) {
			if (newDay.isSame(saturday)) {
				newDay = newDay.subtract(1, "day")
			} else if (newDay.isSame(sunday)) {
				newDay = newDay.add(1, "day")
			}
		}

		actions.changeDay(newDay)
	}

	return (
		<div className="DayNav">
			<div className="prev" onClick={handlePrevDayClick}>
				<ArrowBackSVG />
			</div>
			<DatePicker
				value={currentDate.toDate()}
				onChange={handleDayChange}
				open={isCalendarOpen}
				maxDate={noMaxDateSet ? undefined : lastDay.toDate()}
				filterDate={showWeekends ? undefined : isWeekday}
				onClickOutside={() => setIsCalendarOpen(false)}
			/>
			<div className="today" onClick={handleThisDayClick}>
				<DaySVG />
			</div>
			<div
				className="next"
				onClick={!nextDisabled ? handleNextDayClick : undefined}
			>
				<ArrowForwardSVG />
			</div>
		</div>
	)
}
