import { IMAGE_API_ROOT, deskFileImageURL } from "../../api"
import { ImageResizeOptions } from "../../types/sharedTypes"

function appendSlashIfNeeded(src: string) {
	if (!src) return ""

	if (src[src.length - 1] !== "/") {
		return src + "/"
	} else {
		return src
	}
}

export function getImageUrl(src: string, resizeOptions?: ImageResizeOptions) {
	const slashedSrc = appendSlashIfNeeded(src)

	if (slashedSrc.startsWith(IMAGE_API_ROOT)) {
		return slashedSrc
	}

	return deskFileImageURL(slashedSrc, resizeOptions)
}

export const removeDotsAndSpaces = (value: string) => {
	const withNextDot = /\.(?=.*\.)/g
	const space = /\s/g

	return value.replace(withNextDot, "-").replace(space, "-")
}
