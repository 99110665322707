import React, { ChangeEvent, forwardRef } from "react"

import { Field } from "./Field"
import { Label } from "./Label"

import { ReactComponent as LockSVG } from "../../assets/images/icons/Lock.svg"

import "./NameField.sass"

type Props = {
	value: string
	onChange: (v: string) => void
	label?: string
	helpText?: string | JSX.Element
	maxLength?: number
	canEdit?: boolean
	required?: boolean
}

/**
 * @deprecated
 */
export const NameField = forwardRef<HTMLInputElement, Props>(
	({ value, onChange, label, helpText, maxLength, canEdit, required }, ref) => {
		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (maxLength) {
				onChange(e.target.value.substring(0, maxLength))
			} else {
				onChange(e.target.value)
			}
		}

		return (
			<Field className="NameField">
				<Label>{label || "Name"}</Label>
				<div className="wrapper">
					<input
						type="text"
						value={value}
						onChange={handleChange}
						ref={ref}
						maxLength={maxLength}
						disabled={!canEdit}
						required={required}
					/>
					{!canEdit && <LockSVG className="lock" />}
				</div>
				{helpText && <p>{helpText}</p>}
			</Field>
		)
	},
)
