import React from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { BILLING_PATHNAMES } from "../constants"
import CompanyDetails from "./CompanyDetails"
import PaymentMethodInfo from "./PaymentMethodInfo"

import { useFetchPaymentsQuery } from "../../../../redux/api/billing/payments"

import Card from "../../../../components/basic/Card"
import Loader from "../../../../components/basic/Loader"

import "./PaymentDetails.sass"

const PaymentDetails = () => {
	const history = useHistory()
	const { t } = useTranslation()

	const { data: payments, isFetching } = useFetchPaymentsQuery()
	const { billing_details } = payments ?? {}

	const handleOnEditCompanyDetailsClick = () => {
		history.push(BILLING_PATHNAMES.overview.companyDetails)
	}

	return (
		<>
			{!isFetching && payments ? (
				<Card className="PaymentDetails">
					<div className="PaymentDetails__section">
						<div className="PaymentDetails__header">
							{t(
								"desktop.settings.billing.payment_details.headers.company_details",
							)}
						</div>
						<CompanyDetails
							companyDetails={billing_details}
							onButtonClick={handleOnEditCompanyDetailsClick}
						/>
					</div>
					<PaymentMethodInfo payments={payments} />
				</Card>
			) : (
				<Loader />
			)}
		</>
	)
}

export default PaymentDetails
