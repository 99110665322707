import { api } from ".."
import { serviceActivationURL, servicesURL } from "../../../api"
import { Service, ServiceTypes, Services } from "./types"

export const services = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchServices: builder.query<Services, void>({
			query: () => servicesURL(),
			providesTags: ["Services"],
		}),

		activateService: builder.mutation<Service, string>({
			query: (type: ServiceTypes) => ({
				url: serviceActivationURL({ type }),
				method: "POST",
			}),
			invalidatesTags: ["Services"],
		}),

		deactivateService: builder.mutation<void, string>({
			query: (type: ServiceTypes) => ({
				url: serviceActivationURL({ type }),
				method: "DELETE",
			}),
			invalidatesTags: ["Services"],
		}),
	}),
})

export const {
	useFetchServicesQuery,
	useActivateServiceMutation,
	useDeactivateServiceMutation,
} = services
