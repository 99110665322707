import { useMemo } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import {
	TABLET_LOW_BATTERY_THRESHOLD,
	TABLET_STATUS_REFRESH_TIME,
} from "../../constants"
import { useId } from "../../hooks/useId"
import { Tooltip } from "../basic/Tooltip"

import { TabletResponse } from "../../redux/devices/types"

import { ReactComponent as Battery40SVG } from "../../assets/images/icons/Battery40.svg"
import { ReactComponent as Battery60SVG } from "../../assets/images/icons/Battery60.svg"
import { ReactComponent as Battery80SVG } from "../../assets/images/icons/Battery80.svg"
import { ReactComponent as Battery100SVG } from "../../assets/images/icons/Battery100.svg"
import { ReactComponent as BatteryChargingSVG } from "../../assets/images/icons/BatteryCharging.svg"
import { ReactComponent as BatteryCriticalSVG } from "../../assets/images/icons/BatteryCritical.svg"
import { ReactComponent as NoWifiSVG } from "../../assets/images/icons/NoWifi.svg"
import { ReactComponent as WifiThreeBarSVG } from "../../assets/images/icons/Wifi3Bar.svg"

import "./TabletStatus.sass"

const formatStatusDate = (date: string) =>
	dayjs(date).format("DD-MM-YYYY HH:mm:ss")

type TabletBatteryStatusProps = {
	batteryStatus: number
	batteryCharging?: boolean
}

export const TabletBatteryStatus = ({
	batteryStatus,
	batteryCharging,
}: TabletBatteryStatusProps) => {
	let icon = useMemo(() => {
		if (batteryCharging) {
			return <BatteryChargingSVG />
		} else {
			if (batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD) {
				return <BatteryCriticalSVG />
			} else if (batteryStatus <= 40) {
				return <Battery40SVG />
			} else if (batteryStatus <= 60) {
				return <Battery60SVG />
			} else if (batteryStatus <= 80) {
				return <Battery80SVG />
			}
			return <Battery100SVG />
		}
	}, [batteryCharging, batteryStatus])

	const iconClasses = classNames({
		icon: true,
		warning: batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD,
	})

	return (
		<div className="status">
			<span className={iconClasses}>{icon}</span>
			{!batteryCharging && <span className="label">{batteryStatus} %</span>}
		</div>
	)
}

type TabletConnectionStatusProps = {
	statusSentAt?: string
}

export const TabletConnectionStatus = ({
	statusSentAt,
}: TabletConnectionStatusProps) => {
	const connected = useMemo(() => {
		if (statusSentAt !== undefined) {
			return dayjs(statusSentAt)
				.add(TABLET_STATUS_REFRESH_TIME, "second")
				.isAfter(dayjs())
		}
		return false
	}, [statusSentAt])

	const icon = useMemo(
		() => (connected ? <WifiThreeBarSVG /> : <NoWifiSVG />),
		[connected],
	)

	const iconClasses = classNames({
		icon: true,
		warning: !connected,
	})

	return (
		<div className="status">
			<span className={iconClasses}>{icon}</span>
		</div>
	)
}

type TabletStatusIconsProps = {
	tablet: TabletResponse
}

const TabletStatus = ({ tablet }: TabletStatusIconsProps) => {
	const uniqueId = useId()
	const { t } = useTranslation()

	const content = useMemo(() => {
		const statusSendAtLabel = tablet.status_sent_at
			? formatStatusDate(tablet.status_sent_at)
			: "-"

		return t("desktop.settings.visitors.devices.last_updated", {
			updatedAt: statusSendAtLabel,
		})
	}, [tablet.status_sent_at, t])

	return (
		<Tooltip uniqueId={uniqueId} content={content}>
			<div id={uniqueId} className="TabletStatus">
				<TabletConnectionStatus statusSentAt={tablet.status_sent_at} />
				{tablet.status_sent_at && (
					<TabletBatteryStatus
						batteryStatus={tablet.battery_status}
						batteryCharging={tablet.battery_charging}
					/>
				)}
			</div>
		</Tooltip>
	)
}

export default TabletStatus
