import { api } from ".."
import { calendarsURL } from "../../../api"
import { PaginatedOptions } from "../../reduxUtils"
import { CalendarsResponse } from "./types"

const FETCH_DEFAULTS = {
	limit: 20,
	offset: 0,
}

export const calendars = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchCalendars: builder.query<
			CalendarsResponse & { offset: number },
			PaginatedOptions | void
		>({
			query: (options) => calendarsURL({ ...FETCH_DEFAULTS, ...options }),
			providesTags: ["Calendars"],
			transformResponse: (response: CalendarsResponse, _meta, arg) => {
				return { ...response, offset: arg?.offset ?? 0 }
			},
		}),
	}),
})

export const { useFetchCalendarsQuery } = calendars
