import { useCallback, useState } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { parseQueryWithDefault } from "../utils"

type UseStoredFilterProps<Filters> = {
	filterName?: string
	defaultFilterValues: Filters
}

type UseStoredFilterResult<Filters> = [
	filter: Filters,
	storeFilter: (value: Filters) => void,
]

const { parse, stringify } = queryString

/**
 * useStoredFilter manages storing and retrieving filter data in local storage,
 * and also updates the filter state according to user input.
 */
export const useStoredFilter = <Filters extends Record<string, any>>({
	filterName,
	defaultFilterValues,
}: UseStoredFilterProps<Filters>): UseStoredFilterResult<Filters> => {
	const { location } = useHistory()
	const { search, pathname } = location

	// only run when component is mounted and thus only gets filters form local storage once
	const [filter] = useState<Filters>(() => {
		const savedFilters = window.localStorage.getItem(
			filterName ? filterName : pathname,
		)
		const parsedValue = savedFilters ? parse(savedFilters) : defaultFilterValues
		return parseQueryWithDefault(search ?? "?", parsedValue) as Filters
	})

	const saveFilters = useCallback(
		(filter: Filters) => {
			window.localStorage.setItem(
				filterName ? filterName : pathname,
				stringify({ ...filter, search: "" }),
			)
		},
		[filterName, pathname],
	)

	return [filter as Filters, saveFilters]
}
