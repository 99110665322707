import { useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import { useAnalyticsScreenView } from "../../../providers/Mobile/FirebaseAnalyticsProvider"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchDeskReservationQuery } from "../../../redux/api/deskReservations"
import { useFetchDesksQuery } from "../../../redux/api/desks"
import { DeskResponse } from "../../../redux/api/desks/types"
import { useLazyFetchFloorQuery } from "../../../redux/api/floors"

import Map from "../../../components/Map"
import { DeskInfo } from "../../../components/Mobile/DeskInfo"
import { Popup } from "../../../components/Mobile/Popup"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import Place from "../../../components/Place"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./ReservationMap.sass"

type ParamsType = {
	id: string
}

const ReservationMap = () => {
	useAnalyticsScreenView("Home/ReservationMap")

	const { t } = useTranslation()
	const { id } = useParams<ParamsType>()
	const history = useHistory()

	const [fetchFloor, { data: floor }] = useLazyFetchFloorQuery()

	const { data: reservation, isSuccess: isReservationLoaded } =
		useFetchDeskReservationQuery(id ?? skipToken)

	const { data: { results: desks = [] } = {}, isSuccess: areDeskLoaded } =
		useFetchDesksQuery(
			{ floor: reservation?.floor.id },
			{ skip: !reservation?.floor.id },
		)

	const isLoaded =
		(isReservationLoaded && floor && areDeskLoaded) ||
		(isReservationLoaded && !reservation)

	useEffect(() => {
		if (reservation?.floor.id && !floor) {
			fetchFloor({ id: reservation.floor.id, settings: false })
		}
	}, [reservation?.floor.id, floor, fetchFloor])

	return (
		<SafeViewArea className="ReservationMap">
			<TopNav
				backArrow={false}
				title={t("mobile.home.your_reservation")}
				titleCenter={true}
				rightIcon={
					<CrossSVG
						onClick={() => {
							history.push("/home/reservations")
						}}
					/>
				}
			/>
			<div className="body">
				{isLoaded && reservation && (
					<div className="content">
						<div className="reservation-info">
							<div>
								{reservation?.building.name}
								{", "}
								{reservation?.floor.name}
							</div>
							<div>
								{dayjs(reservation.start).format("LLL")} {" - "}
								{dayjs(reservation.end).format("LT")}
							</div>
						</div>
						<div className="map">
							{floor && (
								<Map
									map={floor}
									onClick={() => {}}
									isDisabled={false}
									showCrosshair={false}
									showZoomControls={false}
									showPanControls={false}
								>
									{desks.map((desk: DeskResponse, i: number) => {
										const isReservation = reservation.desk.id === desk.id

										return (
											<Place
												key={`seat-${i}`}
												x={desk.coord_x}
												y={desk.coord_y}
												mapWidth={floor!.width!}
												mapHeight={floor!.height!}
												onClick={() => {}}
												title=""
												isSelected={isReservation}
												isCurrentlyBooked={isReservation}
												isOccupied={false}
												isBlinking={false}
												isDisabled={!isReservation}
											/>
										)
									})}
								</Map>
							)}
							<Popup open={true} position="low">
								{reservation.desk && (
									<DeskInfo
										seat={reservation.desk}
										isAvailable={true}
										reservations={[reservation]}
									/>
								)}
							</Popup>
						</div>
						<div className="spacer"></div>
					</div>
				)}
				{!isLoaded && (
					<div className="loading">
						<Loader />
					</div>
				)}
			</div>
		</SafeViewArea>
	)
}

export default ReservationMap
