import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

import { isNetworkError } from "../../../api/utils"
import { useBookContext } from "../../../contexts/Mobile/BookContext"
import {
	internalTimeFormat,
	isInternalTime,
	setTimeToDayjs,
	shortUserTimeFormat,
} from "../../../utils"

import {
	FetchAssetScheduleProps,
	fetchAssetsSchedule,
} from "../../../redux/asset_schedule/assetScheduleSlice"
import { setBookAsset } from "../../../redux/book_asset/bookAssetSlice"
import { BookAsset } from "../../../redux/book_asset/types"
import { bookEvent, fetchEventsRooms } from "../../../redux/events/eventsSlice"
import {
	createRecurringReservation,
	createReservation,
	updateReservation,
} from "../../../redux/reservations/reservationsSlice"
import {
	FailedRecurringReservation,
	ReservationRequest,
} from "../../../redux/reservations/types"
import { RoomResponse } from "../../../redux/rooms/types"
import { selectUser } from "../../../redux/user/selectors"
import { useActions } from "../../../redux/utils"

import { FailedReservations } from "../../../components/FailedReservations"
import { Drawer } from "../../../components/Mobile/Drawer"
import {
	RepeatPicker,
	RepeatPickerTypes,
	repeatPickerTypes,
} from "../../../components/Mobile/RepeatPicker"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import Button from "../../../components/basic/Button"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"
import { ReactComponent as PencilSVG } from "../../../assets/images/icons/Pencil.svg"

import "./Summary.sass"

const Summary = () => {
	const {
		id,
		date,
		timeslot,
		building,
		floor,
		desk,
		room,
		title,
		onChangeTitle,
		goToHome,
		goToScreen,
		isBookRoom,
	} = useBookContext()

	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)
	const [repeatPicker, setRepeatPicker] = useState(false)

	const [repeat, setRepeat] = useState<RepeatPickerTypes>(
		repeatPickerTypes.ONCE,
	)
	const [repeatUntil, setRepeatUntil] = useState<Dayjs | null>(null)

	const [recurringMeetingsError, setRecurringMeetingsError] =
		useState<boolean>(false)
	const [failedRecurringMeetings, setFailedRecurringMeetings] = useState<
		FailedRecurringReservation[]
	>([])

	const actions = useActions({
		create: (
			date: Dayjs,
			seat_id: string,
			start: string,
			end: string,
			user_id: string,
		) =>
			createReservation({
				date: date.toISOString(),
				seat_id,
				start,
				end,
				user_id,
			}),
		createRecurring: (
			date: Dayjs,
			seat_id: string,
			start: string,
			end: string,
			user_id: string,
			freq: string,
			until: Dayjs,
		) =>
			createRecurringReservation({
				date: date.toISOString(),
				seat_id,
				start,
				end,
				user_id,
				freq,
				until: until.toISOString(),
			}),
		update: (payload: ReservationRequest) => updateReservation(payload),
		bookEvent: (
			room: RoomResponse,
			organizer: string,
			title: string,
			start: string,
			end: string,
		) => bookEvent({ room, organizer, title, start, end }),
		fetchEventsRooms: () => fetchEventsRooms(),
		setBookAsset: (params: BookAsset) => setBookAsset(params),
		fetchAssetsSchedule: (params: FetchAssetScheduleProps) =>
			fetchAssetsSchedule({ ...params, show: "reserved" }),
	})

	const { entry: currentUser } = useSelector(selectUser)

	const handleClose = () => goToHome()

	const handleContinue = () => goToScreen("done")

	const handleRecurringMeetingsError = (
		failedArray: FailedRecurringReservation[],
	) => {
		setRecurringMeetingsError(true)
		setFailedRecurringMeetings(failedArray)
	}

	const handleRepeatPick = (
		repeatOption: RepeatPickerTypes,
		untilDate: Dayjs | null,
	) => {
		setRepeat(repeatOption)
		setRepeatUntil(untilDate)
		setRepeatPicker(false)
	}

	const handleConfirm = async () => {
		setLoading(true)

		let response

		if (timeslot?.from && timeslot?.to) {
			if (isBookRoom) {
				if (room) {
					const start = dayjs(date.format("YYYY-MM-DDT") + timeslot.from)
					const end = dayjs(date.format("YYYY-MM-DDT") + timeslot.to)

					response = await actions.bookEvent(
						room,
						currentUser.email,
						title ?? "",
						start.toISOString(),
						end.toISOString(),
					)

					actions.fetchEventsRooms()
				}
			} else if (id) {
				if (desk) {
					response = await actions.update({
						id,
						date: date.format("YYYY-MM-DD"),
						from: timeslot.from,
						to: timeslot.to,
						seat_id: desk.id,
						user_id: currentUser.id,
					})
				}
			} else if (repeat === repeatPickerTypes.ONCE) {
				if (desk) {
					response = await actions.create(
						date,
						desk.id,
						timeslot.from,
						timeslot.to,
						currentUser.id,
					)
				}
			} else {
				/*
          This response of this request is unique so special handling is required
          http://api.joan.vnct.xyz/#/Desk/post_api_2_0_desk_company__company_id__recurring_reservation
        */
				if (repeatUntil) {
					if (desk) {
						response = await actions.createRecurring(
							date,
							desk.id,
							timeslot?.from,
							timeslot.to,
							currentUser.id,
							repeat,
							repeatUntil,
						)
						if (createRecurringReservation.fulfilled.match(response)) {
							if (response.payload.failed?.length > 0) {
								handleRecurringMeetingsError(response.payload.failed)
							}
						}
					}
				}
			}
			if (response) {
				if (bookEvent.fulfilled.match(response)) return goToScreen("done")

				if (
					createRecurringReservation.fulfilled.match(response) ||
					createReservation.fulfilled.match(response) ||
					updateReservation.fulfilled.match(response)
				) {
					if (
						isInternalTime(timeslot.from) &&
						isInternalTime(timeslot.to) &&
						building
					) {
						const start = setTimeToDayjs(date, timeslot.from).toISOString()
						const end = setTimeToDayjs(date, timeslot.to).toISOString()
						actions.setBookAsset({
							building: building,
							start,
							end,
						})
						await actions.fetchAssetsSchedule({
							building_id: building?.id,
							start,
							end,
						})
					}

					goToScreen("done")
				} else {
					if (isNetworkError((response as any)?.error)) {
						toast.error(t("mobile.general.something_wrong"), {
							hideProgressBar: true,
						})
						return
					}
					toast.error((response as any)?.error?.message, {
						hideProgressBar: true,
					})
				}
			}
		}
	}

	useEffect(() => {
		if (title === "") {
			onChangeTitle(t("mobile.book.default_title"))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<SafeViewArea className="Summary">
			<div className="body">
				<TopNav rightIcon={<CrossSVG onClick={handleClose} />} />
				{!id && (
					<div className="summary-title">
						{t("mobile.book.almost_finished")}
						<br />
						{t("mobile.book.check_information")}
					</div>
				)}
				{id && (
					<div className="summary-title">
						{t("mobile.book.edit_reservation")}
					</div>
				)}

				<div className="details">
					<div className="scroller">
						<div
							className="detail-row"
							onClick={() => goToScreen("building", true)}
						>
							<div className="data">
								<div className="type">{t("mobile.book.location")}</div>
								<div className="value">
									{building?.name ?? t("mobile.general.unknown")}
								</div>
							</div>
							<div className="edit">
								<PencilSVG />
							</div>
						</div>

						{!isBookRoom && (
							<div
								className="detail-row"
								onClick={() => goToScreen("floor", true)}
							>
								<div className="data">
									<div className="type">{t("mobile.book.floor")}</div>
									<div className="value">{floor?.name}</div>
								</div>
								<div className="edit">
									<PencilSVG />
								</div>
							</div>
						)}

						{isBookRoom && (
							<div
								className="detail-row"
								onClick={() => goToScreen("title", true)}
							>
								<div className="data">
									<div className="type">{t("mobile.book.title")}</div>
									<div className="value">{title}</div>
								</div>
								<div className="edit">
									<PencilSVG />
								</div>
							</div>
						)}

						<div
							className="detail-row"
							onClick={() => goToScreen("time", true)}
						>
							<div className="data">
								<div className="type">{t("mobile.book.date_time")}</div>
								<div className="value">
									{dayjs(date).format("MMM D")}
									{", "}
									{dayjs(timeslot?.from, internalTimeFormat()).format(
										shortUserTimeFormat(),
									)}
									{" - "}
									{dayjs(timeslot?.to, internalTimeFormat()).format(
										shortUserTimeFormat(),
									)}
								</div>
							</div>
							<div className="edit">
								<PencilSVG />
							</div>
						</div>

						{!isBookRoom && (
							<div
								className="detail-row"
								onClick={() => goToScreen("desk", true)}
							>
								<div className="data">
									<div className="type">{t("mobile.book.desk")}</div>
									<div className="value">{desk?.name}</div>
								</div>
								<div className="edit">
									<PencilSVG />
								</div>
							</div>
						)}

						{isBookRoom && (
							<div
								className="detail-row"
								onClick={() => goToScreen("room", true)}
							>
								<div className="data">
									<div className="type">{t("mobile.book.room")}</div>
									<div className="value">{room?.name}</div>
								</div>
								<div className="edit">
									<PencilSVG />
								</div>
							</div>
						)}

						{!id && !isBookRoom && (
							<div className="detail-row" onClick={() => setRepeatPicker(true)}>
								<div className="data">
									<div className="type">{t("mobile.book.repeat")}</div>
									<div className="value">
										{t(`mobile.general.repeat.${repeat}` as ParseKeys)}
										{repeat === repeatPickerTypes.EVERY_DAY_OF_WEEK &&
											dayjs(date).format(" dddd")}
										{repeatUntil &&
											" (" +
												t("mobile.general.until") +
												": " +
												repeatUntil.format("MMM D") +
												")"}
									</div>
								</div>
								<div className="edit">
									<PencilSVG />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Drawer open={repeatPicker}>
				<RepeatPicker
					date={date}
					value={repeat}
					until={repeatUntil}
					onPick={handleRepeatPick}
				/>
			</Drawer>
			<Drawer open={recurringMeetingsError}>
				<FailedReservations
					title={t("mobile.book.error_recurring_reservation")}
					buttonText={t("mobile.book.confirm_reservation")}
					failedReservations={failedRecurringMeetings}
					handleClose={handleContinue}
					type="mobile"
					reservationType="desk"
				/>
			</Drawer>
			<div className="action">
				<Button
					variant="mobile-action"
					isLoading={loading}
					onClick={handleConfirm}
				>
					{t("mobile.book.confirm_reservation")}
				</Button>
			</div>
		</SafeViewArea>
	)
}

export default Summary
