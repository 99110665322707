import React, { useEffect } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import { useRedirectTimeout } from "../hooks/useRedirectTimeout"

import { requestAppointment } from "../redux/appointments/appointmentsSlice"
import {
	AppointmentRequest,
	RequestAppointmentOptions,
} from "../redux/appointments/types"
import { useAppSelector } from "../redux/reducers"
import { fetchVisitorScreeningsConfigData } from "../redux/screenings/screeningsSlice"
import { useActions } from "../redux/utils"

import VisitorAppointmentForm from "../components/Form/VisitorAppointmentForm"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Space from "../components/Space"
import View from "../components/View"
import Button from "../components/basic/Button"

import "./VisitorAdHoc.sass"

type Params = {
	company_id?: string
}

export default function VisitorAdHoc({ match }: RouteComponentProps<Params>) {
	const { params } = match || {}
	const { company_id } = params || {}

	const actions = useActions({
		requestAppointment: (
			cid: string,
			payload: AppointmentRequest,
			options?: RequestAppointmentOptions,
		) =>
			requestAppointment({
				cid,
				payload,
				options: { withCheckin: options?.withCheckin ?? false },
			}),
		fetchVisitorScreeningsConfigData: (company_id: string) =>
			fetchVisitorScreeningsConfigData(company_id),
	})

	useEffect(() => {
		if (company_id) {
			actions.fetchVisitorScreeningsConfigData(company_id)
		}
	}, [actions, company_id])

	const { entry: config } = useAppSelector((state) => state.screenings)

	const history = useHistory()

	const handleSubmitWithoutHealthScreen = async (
		appointment: AppointmentRequest,
	) => {
		if (company_id) {
			const resp = await actions.requestAppointment(company_id, appointment, {
				withCheckin: true,
			})

			if (requestAppointment.fulfilled.match(resp)) {
				toast.info("Request submitted.", { hideProgressBar: true })

				history.push(`/visitor-login/${company_id}/requested`)
			} else {
				toast.error(resp.error.message, {
					hideProgressBar: true,
				})
			}
		}
	}

	const handleSubmitWithHealthScreen = async (
		appointment: AppointmentRequest,
	) => {
		if (company_id) {
			const resp = await actions.requestAppointment(company_id, appointment)

			if (requestAppointment.fulfilled.match(resp)) {
				toast.info("Request submitted.", { hideProgressBar: true })

				const { id: appointment_id } = resp.payload
				const { id: visitor_id } = resp.payload.visitors[0]

				history.push(
					`/health-screening/${company_id}/visitor/${visitor_id}/appointment/${appointment_id}`,
				)
			} else {
				toast.error(
					"Could not request appointment. Check whether you typed the host's email address correctly.",
					{
						hideProgressBar: true,
					},
				)
			}
		}
	}

	const handleSubmit = config?.is_active
		? handleSubmitWithHealthScreen
		: handleSubmitWithoutHealthScreen

	function handleCancel() {
		history.push(`/visitor-login/${company_id}`)
	}

	const visitorClassName = classNames({
		VisitorAdHoc: true,
		VisitorLogin: true,
		isConstrained: true,
	})

	const appointment = {
		start: dayjs().toISOString(),
		end: dayjs().add(1, "hour").toISOString(),
	}

	return (
		<RouteView className={visitorClassName}>
			<Screen>
				<View className={visitorClassName}>
					<Heading>
						<span>Request an appointment</span>
					</Heading>
					<Intro>
						Please enter the details needed to request your appointment.
						<br />
						Your host will be notified that you have arrived.
					</Intro>

					<Space size={0.75} />

					<VisitorAppointmentForm
						appointment={appointment}
						onSubmit={handleSubmit}
						onCancel={handleCancel}
						submitLabel="Request appointment"
						canEdit={true}
					/>
				</View>
			</Screen>
		</RouteView>
	)
}

export function MeetingRequested({ match }: RouteComponentProps<Params>) {
	const { params } = match || {}
	const { company_id } = params || {}

	const history = useHistory()

	function handleDismiss() {
		history.push(`/visitor-login/${company_id}`)
	}

	const healthClassName = classNames({
		MeetingRequested: true,
		isConstrained: true,
	})

	useRedirectTimeout(`/visitor-login/${company_id}`, 10000)

	return (
		<RouteView className={healthClassName}>
			<Screen>
				<View className="MeetingRequestedResult">
					<Heading>
						<span>Appointment requested</span>
					</Heading>
					<Intro>
						<p>
							Thank you for submitting the appointment request. Your host has
							been notified.
						</p>
					</Intro>

					<Space size={0.75} />

					<Button onClick={handleDismiss}>Dismiss</Button>
				</View>
			</Screen>
		</RouteView>
	)
}
