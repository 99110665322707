import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"

import { toast } from "react-toastify"

import { NameField } from "../Field/NameField"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	useFetchCompanyQuery,
	useUpdateCompanyMutation,
} from "../../redux/api/company"
import { isRejected } from "../../redux/api/types"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./CompanyForm.sass"

const CompanyForm = () => {
	const { closeModal } = useModals()

	const { data: company } = useFetchCompanyQuery()
	const [updateCompany] = useUpdateCompanyMutation()

	const { name } = company ?? {}

	const [formName, setFormName] = useState(name || "")

	const onUpdateClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault?.()

			const response = updateCompany({
				name: formName,
			})

			if (isRejected(response)) {
				toast.error(response.error.message, { hideProgressBar: true })
			} else {
				toast.info("Company updated", { hideProgressBar: true })
				closeModal()
			}
		},
		[updateCompany, formName, closeModal],
	)

	const nameFieldRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (nameFieldRef !== null && nameFieldRef.current !== null) {
			nameFieldRef.current.focus()
		}
	}, [nameFieldRef])

	const canEdit = true // is office manager

	return (
		<form className="CompanyForm ModalForm">
			<div className="title">
				<h1>Edit company</h1>
			</div>
			<div className="close" onClick={() => closeModal()}>
				<CrossSVG />
			</div>

			<div className="fields">
				<NameField
					label={"Name"}
					value={formName}
					onChange={setFormName}
					canEdit={canEdit}
					ref={nameFieldRef}
					maxLength={30}
				/>
			</div>

			{canEdit && (
				<div className={"actions actions-new"}>
					<Button
						variant="submit"
						className="save-or-create"
						onClick={onUpdateClick}
					>
						Save
					</Button>
				</div>
			)}
		</form>
	)
}

export default CompanyForm
