import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { PopupButton } from "./Popup"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { DeskReservation } from "../../redux/api/deskReservations/types"
import { useFetchDeskQuery } from "../../redux/api/desks"
import { SeatReservation } from "../../redux/reservations/types"
import { formatUser } from "../../redux/user/utils"

import { ReactComponent as RadioFullSVG } from "../../assets/images/icons/Radio full.svg"
import { ReactComponent as RadioUncheckedSVG } from "../../assets/images/icons/Radio unchecked.svg"

import "./DeskInfo.sass"

type DeskInfoProps = {
	seat?: { id: string }
	isAvailable: boolean
	reservations: Partial<DeskReservation>[]
	onPick?: (desk: SeatReservation) => void
}

export const DeskInfo = ({
	seat,
	isAvailable,
	reservations,
	onPick,
}: DeskInfoProps) => {
	const { t } = useTranslation()
	const { data: desk } = useFetchDeskQuery(seat?.id ?? skipToken)

	if (desk && reservations.length > 0) {
		return (
			<div className="DeskInfo">
				<div className="info">
					<div className="text">
						<div className="main">{desk.name}</div>
						<div className="sub">{t("mobile.floor.desk")}</div>
						<div className="status">
							{desk.departments &&
								desk.departments.map((department) => (
									<span key={department.id}>{department.name}</span>
								))}
						</div>
						<div className="amenities">
							{desk?.amenities &&
								desk.amenities.map((amenity, index) => (
									<span key={amenity.id}>
										{amenity.name}
										{index < (desk?.amenities?.length ?? 0) - 1 ? "," : ""}
									</span>
								))}
						</div>
					</div>
					{isAvailable && !reservations[0].id && (
						<PopupButton
							small={false}
							label={<span>{t("mobile.floor.book")}</span>}
							onClick={() => {
								onPick?.(deskReservationToSeatReservation(reservations[0]))
							}}
						/>
					)}
				</div>
				{reservations[0].id && (
					<div className="reservations">
						{reservations.map((reservation) => {
							return (
								<div className="item" key={reservation.id}>
									<div className="check">
										{reservation.user && reservation.checked_in ? (
											<RadioFullSVG className="in" />
										) : (
											<RadioUncheckedSVG className="out" />
										)}
									</div>
									<div className="name">
										{reservation.user && formatUser(reservation.user)}
									</div>
									<div className="time">
										<span>
											{dayjs(reservation.start).format("LT")}
											{" - "}
											{dayjs(reservation.end).format("LT")}
										</span>
									</div>
								</div>
							)
						})}
					</div>
				)}
			</div>
		)
	}
	return null
}

const deskReservationToSeatReservation = (
	reservation: Partial<DeskReservation>,
): SeatReservation => {
	return {
		id: reservation?.desk?.id ?? "",
		name: reservation?.desk?.name ?? "",
		type: "desk",
	}
}
