import {
	ChangeEvent,
	MouseEventHandler,
	SyntheticEvent,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"

import classNames from "classnames"

import { API_ROOT } from "../../../api/urls"

import { ImageResponse } from "../../../redux/settings/types"

import { ReactComponent as AddPhotoSVG } from "../../../assets/images/icons/AddPhoto.svg"
import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

export type ImageSelectorProps = {
	label: string
	className?: string
	image?: ImageResponse | null
	onChange?: (value: File | null) => void
	hasError?: boolean
	accept?: string
}

const getImageSrc = (image?: ImageResponse | null) => {
	if (image?.url) {
		return image.url.startsWith("http") ? image.url : `${API_ROOT}${image.url}`
	}
	return ""
}

export const ImageSelector = forwardRef<HTMLDivElement, ImageSelectorProps>(
	({ label, className, onChange, image, accept = "image/*" }, ref) => {
		const hiddenFileInput = useRef<HTMLInputElement>(null)
		const [selectedImage, setSelectedImage] = useState<File | null>()
		const [imageSrc, setImageSrc] = useState(getImageSrc(image))

		const innerOnChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				if (e.target.files) {
					const file = e.target.files[0]

					onChange && onChange(file)
					setSelectedImage(file)

					if (file) {
						setImageSrc(URL.createObjectURL(file))
					}
				}
			},
			[onChange],
		)

		const removeImage: MouseEventHandler<HTMLDivElement> = (e) => {
			e.stopPropagation()

			onChange && onChange(null)
			setSelectedImage(null)
			setImageSrc("")
		}

		const openImageUpload = (e: SyntheticEvent) => {
			e.preventDefault()
			hiddenFileInput.current?.click()
		}

		useEffect(() => {
			if (image && !selectedImage) {
				setImageSrc(getImageSrc(image))
			}
		}, [image, imageSrc, selectedImage])

		return (
			<div className={classNames(["ImageSelector", className])}>
				<div ref={ref} className="image-display" onClick={openImageUpload}>
					{(selectedImage || image) && selectedImage !== null ? (
						<div>
							<img
								className="image"
								alt={image?.name ?? selectedImage?.name}
								src={imageSrc}
							/>
							<div onClick={removeImage} className="remove">
								<CrossSVG />
							</div>
						</div>
					) : (
						<>
							<div>
								<AddPhotoSVG />
							</div>
							<div>{label}</div>
						</>
					)}
				</div>
				<input
					onChange={innerOnChange}
					ref={hiddenFileInput}
					type="file"
					accept={accept}
				/>
			</div>
		)
	},
)
