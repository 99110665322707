import React, { Children, ReactElement, ReactNode } from "react"

import SortableCard from "./SortableCard"
import { DragDropContext, Droppable } from "@hello-pangea/dnd"

import "./SortableCardsContainer.sass"

export interface SortableItemProp {
	disabled: boolean
}

type Props = {
	onCardSorted: (sourceIndex: number, destinationIndex: number) => void
	onCardDeleted: (index: number) => void
	children: ReactElement<SortableItemProp>[]
}

const SortableCardsContainer = ({
	onCardDeleted,
	onCardSorted,
	children,
}: Props) => {
	return (
		<div className="DraggableContainer">
			<DragDropContext
				onDragEnd={(result) =>
					typeof result?.destination?.index === "number" &&
					onCardSorted(result.source.index, result.destination.index)
				}
			>
				<Droppable droppableId="items">
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{Children.map(
								children,
								(child: ReactNode, index) =>
									React.isValidElement<SortableItemProp>(child) && (
										<SortableCard
											onCardDeleted={onCardDeleted}
											index={index}
											disabled={child.props.disabled}
										>
											{child}
										</SortableCard>
									),
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

export default SortableCardsContainer
