import { Dayjs } from "dayjs"

import { nameComparator } from "../../utils"
import { TimeslotResponse } from "../timeslots/types"
import { getAvailableSlots, timeslotComparator } from "../timeslots/utils"
import { ReservationResponse, SeatReservation } from "./types"

export const reservationDateStringFormat = (day: Dayjs) =>
	day.format("YYYY-MM-DD")

export const byDateString = (dateString: string) => (
	res: ReservationResponse,
) => res.date === dateString

export const bySeatId = (seat_id: string) => (res: ReservationResponse) =>
	res.seat && res.seat.id === seat_id

export const byUserId = (user_id: string) => (res: ReservationResponse) =>
	res.user && res.user.id === user_id

export const byNotUserId = (user_id: string) => (res: ReservationResponse) =>
	res.user && res.user.id !== user_id

export function onlyUnique(
	value: ReservationResponse,
	index: number,
	self: ReservationResponse[],
) {
	return self.indexOf(value) === index
}

type AvailableReservation = {
	date: Dayjs
	seat: SeatReservation
	timeslot: TimeslotResponse
}

export function findAvailableReservations(
	date: Dayjs,
	reservations: ReservationResponse[],
	seats: SeatReservation[],
	slots: TimeslotResponse[],
) {
	const dateString = reservationDateStringFormat(date)
	const reservationsToday = reservations.filter(byDateString(dateString))

	const availableReservations: AvailableReservation[] = []
	seats
		.slice(0)
		.sort(nameComparator)
		.forEach((seat) => {
			const otherReservations = reservationsToday.filter(bySeatId(seat.id))

			const availableSlots = getAvailableSlots(
				slots,
				otherReservations.map((r) => r.timeslot!),
			)

			if (availableSlots.length > 0) {
				const timeslot = availableSlots.sort(timeslotComparator)[0]
				availableReservations.push({ date, seat, timeslot })
			}
		})

	return availableReservations
}
