import React, { PropsWithChildren } from "react"

import { useLocation } from "react-router-dom"

import { useLoadReducers } from "../hooks/useLoadReducers"

import { selectCompanyDetails } from "../redux/api/company"
import { useAppSelector } from "../redux/reducers"

import Loader from "../components/basic/Loader"

function LoadingProvider({ children }: PropsWithChildren<unknown>) {
	const { pathname } = useLocation()

	const { isSuccess: areCompanyDetailsLoaded } =
		useAppSelector(selectCompanyDetails)

	const isAuthPath = pathname.indexOf("/auth/") > -1

	const { areSlicesLoaded } = useLoadReducers({
		reducerKeys: [
			"settings",
			"featureFlags",
			"scim",
			"experiments",
			"user_experiments",
			"user",
		],
	})

	if ((!areSlicesLoaded || !areCompanyDetailsLoaded) && !isAuthPath) {
		return <Loader size="large" />
	}

	return <>{children}</>
}

export default LoadingProvider
