import { Trans, useTranslation } from "react-i18next"

import { getLabel } from "../../../../utils"
import DeskSettingsForm from "./DeskSettingsForm"

import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const General = () => {
	const { t } = useTranslation()

	const { isLoaded } = useAppSelector(selectSettingsEffective)

	return (
		<View>
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.settings.desks.title"),
					t("desktop.settings.desks.general.title"),
				]}
			/>

			<Intro isConstrained>
				<Trans i18nKey="desktop.settings.desks.general.intro">
					<a
						href={getLabel("links.deskBookingInstructionsURL")}
						target="_blank"
						rel="noopener noreferrer"
					>
						link
					</a>
				</Trans>
			</Intro>
			<Space size={1} />
			{isLoaded && <DeskSettingsForm />}
		</View>
	)
}

export default General
