export const ENTRIES_PER_PAGE = 20

export const ASSETS_SCHEDULE_PATHNAME = "/manage/assets"
export const DESKS_SCHEDULE_PATHNAME = "/manage/desks"
export const PEOPLE_SCHEDULE_PATHNAME = "/manage/people"
export const VISITORS_INVITES_PATHNAME = "/manage/visitors/invites"
export const VISITORS_VISITS_PATHNAME = "/manage/visitors/visits"

export const ASSETS_FILTER_STORE_NAME = "manage-assets-filters"
export const DESK_FILTERS_STORE_NAME = "manage-desk-filters"
export const INVITES_FILTERS_STORE_NAME = "manage-invites-filters"
export const PEOPLE_FILTERS_STORE_NAME = "manage-people-filters"
export const VISITORS_FILTERS_STORE_NAME = "manage-visitors-filters"
