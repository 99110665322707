import React, { useCallback, useContext, useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import {
	FinishType,
	FlowContext,
	Step,
} from "../../providers/Tablet/RegistrationFlowProvider"
import {
	BarcodeScanner,
	SupportedFormat,
} from "@joan/capacitor-barcode-scanner"

import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"

import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorView from "../../components/Visitors/VisitorView"
import Button from "../../components/basic/Button"

import "./Scan.sass"

const Scan = () => {
	const isBarcodeScannerStarted = useRef<boolean>(false)
	const { t } = useTranslation()

	const { goToStep, finishStep } = useContext(FlowContext)

	const { buildingId, id: tabletId } = useAppSelector(selectTablet)

	const startScanning = useCallback(() => {
		if (document !== null) {
			document.querySelector("body")!.classList.add("scanner-active")
			document.querySelector(".VisitorView")!.classList.add("scanner-active")
		}
		BarcodeScanner.hideBackground()
	}, [])

	const finishScanning = useCallback(() => {
		if (document !== null) {
			document.querySelector("body")!.classList.remove("scanner-active")
			document.querySelector(".VisitorView")!.classList.remove("scanner-active")
		}
		BarcodeScanner.showBackground()
	}, [])

	const handleGoBack = useCallback(() => {
		BarcodeScanner.stopScan()

		finishScanning()

		goToStep(Step.NAME)
	}, [finishScanning, goToStep])

	useEffect(() => {
		if (!isBarcodeScannerStarted.current) {
			isBarcodeScannerStarted.current = true

			startScanning()

			BarcodeScanner.startScan({
				targetedFormats: [SupportedFormat.QR_CODE],
				cameraDirection: "front",
			}).then((result) => {
				if (result.hasContent && buildingId && tabletId) {
					BarcodeScanner.stopScan()
					finishScanning()

					finishStep(FinishType.CHECK_IN, result.content)
				}
			})
		}

		const timer = setTimeout(() => {
			handleGoBack()
		}, 20 * 1000)

		return () => {
			clearTimeout(timer)
		}
	}, [
		buildingId,
		tabletId,
		handleGoBack,
		finishStep,
		startScanning,
		finishScanning,
	])

	return (
		<VisitorView className="Scan">
			<VisitorContent>
				<div className="scanWindow"></div>
			</VisitorContent>
			<VisitorFooter>
				<div className="scanInfo">
					{t("tablet.visitors.screens.scan.qr_code_focused")}
				</div>

				<Button variant="link" onClick={handleGoBack}>
					{t("general.redirection.go_back")}
				</Button>
			</VisitorFooter>
		</VisitorView>
	)
}

export default Scan
