import React, { useMemo } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { internalTimeFormat, nameComparator } from "../../../../utils"

import { useFetchAssetTypesQuery } from "../../../../redux/api/assetTypes"
import { useFetchAssetsQuery } from "../../../../redux/api/assets"
import { AssetResponse } from "../../../../redux/api/assets/types"
import { getAssetScheduleSelector } from "../../../../redux/asset_schedule/selectors"
import { selectBookAsset } from "../../../../redux/book_asset/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import AssetIcon from "../../../../components/AssetIcon"
import Loader from "../../../../components/basic/Loader"

import { ReactComponent as ChairSVG } from "../../../../assets/images/icons/Chair.svg"
import { ReactComponent as MoreSVG } from "../../../../assets/images/icons/More horizontal.svg"

import "./SuggestAssetType.sass"

type SuggestAssetTypeProps = {
	wasAsset: boolean
}

const MAX_SUGGESTED_ITEMS: number = 6
const MAX_SUGGESTED_INDEX: number = 5

const SuggestAssetType = ({ wasAsset }: SuggestAssetTypeProps) => {
	const { t } = useTranslation()
	const history = useHistory()

	const { locked } = useAppSelector((state) => state.payment)
	const { schedule } = useAppSelector(getAssetScheduleSelector)

	const {
		data: { results: assetTypes = [] } = {},
		isSuccess: areAssetTypesLoaded,
	} = useFetchAssetTypesQuery()

	const { data: { results: assets = [] } = {}, isSuccess: areAssetsLoaded } =
		useFetchAssetsQuery({ active: true })

	const { bookAsset } = useAppSelector(selectBookAsset)

	const isLoaded = areAssetTypesLoaded && areAssetsLoaded

	const getFilteredAssetTypes = useMemo(() => {
		const filteredAssets = schedule
			? assets.filter(
					(a: AssetResponse) => !schedule.find((s) => s.id === a.id),
			  )
			: assets

		return assetTypes
			.filter((a) => a.buildings[0]?.id === bookAsset?.building?.id)
			.filter((at) => filteredAssets.find((a) => a.asset_type.id === at.id))
			.sort(nameComparator)
	}, [assetTypes, assets, bookAsset, schedule])

	if (!isLoaded) {
		return <Loader />
	}

	const isSliced =
		getFilteredAssetTypes.length >
		(wasAsset ? MAX_SUGGESTED_ITEMS - 1 : MAX_SUGGESTED_ITEMS)
	const displayAssetTypes = isSliced
		? getFilteredAssetTypes.slice(
				0,
				wasAsset ? MAX_SUGGESTED_INDEX - 1 : MAX_SUGGESTED_INDEX,
		  )
		: getFilteredAssetTypes

	const suggestTypeListClasses = classNames("suggest-asset-types-list", {
		one: displayAssetTypes.length === (wasAsset ? 0 : 1),
	})

	const areSuggestionAvailable =
		getFilteredAssetTypes.length > 0 &&
		(!locked.desk || (wasAsset && !locked.desk))

	return areSuggestionAvailable ? (
		<div className={"suggest-asset-types"}>
			<div className="book-asset">{t("mobile.book.done_book_asset")}</div>
			<div className={suggestTypeListClasses}>
				{wasAsset && !locked.desk && (
					<div
						className="item"
						key={"desk-id"}
						onClick={() =>
							history.push("/book/desk/building", {
								date: bookAsset.start,
								timeslot: {
									from: dayjs(bookAsset.start).format(internalTimeFormat()),
									to: dayjs(bookAsset.end).format(internalTimeFormat()),
								},
							})
						}
					>
						<ChairSVG className="more-icon" />
						<div className="name">{t("mobile.book.desk")}</div>
					</div>
				)}
				{!locked.desk &&
					displayAssetTypes.map((at) => (
						<div
							className="item"
							key={at.id}
							onClick={() => history.push(`/book/asset/${at.id}`)}
						>
							<AssetIcon name={at.icon} />
							<div className="name">{at.name}</div>
						</div>
					))}
				{isSliced && (
					<div
						className="item"
						key={"more-id"}
						onClick={() => history.push("/book/asset/asset-type")}
					>
						<MoreSVG className="more-icon" />
						<div className="name">
							{t("mobile.book.suggest_asset_type_other")}
						</div>
					</div>
				)}
			</div>
		</div>
	) : null
}

export default SuggestAssetType
