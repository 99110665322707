import React from "react"

import { Route } from "react-router-dom"

import { useAnalyticsScreenView } from "../../providers/Mobile/FirebaseAnalyticsProvider"
import About from "./Profile/About"
import Edit from "./Profile/Edit"
import Info from "./Profile/Info"
import Settings from "./Profile/Settings"

import { AnimatedSwitch } from "../../components/Mobile/AnimatedSwitch"

const Profile: React.FC = () => {
	useAnalyticsScreenView("Profile")

	return (
		<div className="Profile">
			<AnimatedSwitch>
				<Route exact path="/profile/info" component={Info} />
				<Route exact path="/profile/edit" component={Edit} />
				<Route exact path="/profile/settings" component={Settings} />
				<Route exact path="/profile/about" component={About} />
			</AnimatedSwitch>
		</div>
	)
}

export default Profile
