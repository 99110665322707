import classNames from "classnames"
import { useHistory, useRouteMatch } from "react-router-dom"

import Headway from "./Headway"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

import "./Footer.sass"

type Props = {
	isCentered?: boolean
}

export default function Footer({ isCentered }: Props) {
	const { location } = useHistory()

	const { showWeekends } = useAppSelector((state) => state.app)

	const { isAppWhitelabel } = useAppSelector(getAppParams)

	const isManage = useRouteMatch("/manage")
	const isFloorPlan = useRouteMatch("/manage/floor-plan")
	const isQRPrint = useRouteMatch("/qr-printer")

	const isVisitor = location.pathname.startsWith("/manage/visitor")

	const footerClassName = classNames({
		Footer: true,
		isConstrained: (!(showWeekends && isManage) && !isFloorPlan) || isVisitor,
		isCentered,
	})

	if (isQRPrint) {
		return null
	}

	if (isAppWhitelabel) {
		return null
	}

	return (
		<div className={footerClassName}>
			<div className="row">
				<div className="copy">&copy; {new Date().getFullYear()} Visionect</div>
				<div className="menu">
					<a href="https://getjoan.com/terms-of-use-2/">Terms</a>
					<a href="https://getjoan.com/privacy-policy/">Privacy</a>
					<a href="https://support.getjoan.com/">Support</a>
					<Headway />
				</div>
			</div>
		</div>
	)
}
