import React, { useContext } from "react"

import { FlowContext } from "../../providers/Tablet/RegistrationFlowProvider"
import LogoVariant from "../../screens/LogoVariant"
import LanguageSelector from "../advanced/LanguageSelector"

import { useAppSelector } from "../../redux/reducers"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"

import { ReactComponent as ArrowBackSVG } from "../../assets/images/icons/Arrow back.svg"
import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./VisitorHeader.sass"

type Props = {
	showNavigation?: boolean
	title?: string
	showLanguageSelector?: boolean
}

const VisitorHeader = ({
	showNavigation = false,
	showLanguageSelector = false,
	title,
}: Props) => {
	const { previousStep, startStep } = useContext(FlowContext)

	const { branding } = useAppSelector(selectVisitorRegistration)

	const handleBack = () => previousStep()

	const handleClose = () => startStep()

	return (
		<div className="VisitorHeader">
			{showNavigation ? (
				<div className="navigation">
					<div className="arrow" onClick={handleBack}>
						<ArrowBackSVG />
					</div>
					<div className="title">{title}</div>
					<div className="close" onClick={handleClose}>
						<CrossSVG />
					</div>
				</div>
			) : (
				<div className="logo">
					{branding && branding.company_logo ? (
						<img alt="Logo" src={branding.company_logo.url} />
					) : (
						<LogoVariant variant="landscape" />
					)}
				</div>
			)}
			{showLanguageSelector && <LanguageSelector />}
		</div>
	)
}

export default VisitorHeader
