import { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { IdAndName } from "../../../../types/sharedTypes"
import PickerItem from "./PickerItem"
import {
	BuildingWithDefaultAndDisabled,
	getSortedDisabledAndDefaultBuilding,
} from "./utils"

import { useFetchAssetTypesQuery } from "../../../../redux/api/assetTypes"
import { useFetchAssetsQuery } from "../../../../redux/api/assets"
import { useFetchBuildingsQuery } from "../../../../redux/api/buildings"
import { getAssetScheduleSelector } from "../../../../redux/asset_schedule/selectors"
import { BuildingResponse } from "../../../../redux/buildings/types"
import { useAppSelector } from "../../../../redux/reducers"
import { selectDefaultUserBuilding } from "../../../../redux/user/selectors"

import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"
import Loader from "../../../../components/basic/Loader"

import { ReactComponent as CrossSVG } from "../../../../assets/images/icons/Cross.svg"

import "./BuildingPicker.sass"

type BuildingPickerProps = {
	onBuildingSelect: (payload: IdAndName) => void
	onCancelClick: () => void
}

const BuildingPicker = ({
	onBuildingSelect,
	onCancelClick,
}: BuildingPickerProps) => {
	const { t } = useTranslation()

	const {
		data: { results: buildings = [] } = {},
		isSuccess: areBuildingsLoaded,
	} = useFetchBuildingsQuery({
		stats: true,
	})

	const {
		data: { results: assetTypes = [] } = {},
		isSuccess: areAssetTypesLoaded,
	} = useFetchAssetTypesQuery()

	const { data: { results: assets = [] } = {}, isSuccess: areAssetsLoaded } =
		useFetchAssetsQuery({ active: true })

	const { schedule } = useAppSelector(getAssetScheduleSelector)
	const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

	const onPick = (building: BuildingResponse | null) => {
		if (building) {
			onBuildingSelect({ id: building.id, name: building.name })
		}
	}

	const disableBuildings = useMemo(
		(): BuildingWithDefaultAndDisabled[] =>
			getSortedDisabledAndDefaultBuilding(
				assets,
				buildings,
				assetTypes,
				schedule,
				defaultUserBuilding,
			),
		[assets, buildings, assetTypes, schedule, defaultUserBuilding],
	)

	const isLoaded = areBuildingsLoaded && areAssetTypesLoaded && areAssetsLoaded

	return (
		<SafeViewArea className="building-picker">
			<TopNav backArrow rightIcon={<CrossSVG onClick={onCancelClick} />} />
			<h2>{t("mobile.general.choose_location")}</h2>
			<div className="picker-content">
				{!isLoaded ? (
					<Loader />
				) : (
					<div>
						{disableBuildings.map((building) => {
							return (
								<PickerItem
									key={building.id}
									name={building.name}
									disabled={building.disabled}
									description={
										building.address && building.address !== ","
											? building.address
											: undefined
									}
									onClick={() => {
										if (!building.disabled) {
											onPick(building)
											return
										}
									}}
								/>
							)
						})}
						{buildings.length === 0 && (
							<div>{t("mobile.general.no_locations")}</div>
						)}
					</div>
				)}
			</div>
		</SafeViewArea>
	)
}

export default BuildingPicker
