import React from "react"

import classNames from "classnames"

import { Asset } from "../../../redux/assets/types"

import "./AssetItem.sass"

type Props = {
	rowNumber: number
	asset: Asset
	onEditAssetClick: (asset: Asset) => void
}
const AssetItem = ({ rowNumber, asset, onEditAssetClick }: Props) => {
	const assetClasses = classNames("asset-item", { inactive: !asset.active })

	return (
		<div className={assetClasses}>
			<button
				className="asset-item-button"
				type="button"
				onClick={() => onEditAssetClick(asset)}
			>
				<div className="number">{rowNumber}</div>
				<div className="name">{asset.name}</div>
				{asset.description && (
					<div className="description">{asset.description}</div>
				)}
			</button>
		</div>
	)
}

export default AssetItem
