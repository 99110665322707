import { ThunkApiConfig } from "RootType"

import { deskFilesURL, postData, uploadFileCompatURL } from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	SliceState,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { FileResponse, FileResponseCompat } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const uploadDeskFile = createAsyncThunk<
	FileResponse,
	File,
	ThunkApiConfig<ResponseError>
>("files/upload", async (file, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postData(
		deskFilesURL(),
		{ body: { file } },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

export const uploadFileCompat = createAsyncThunk<
	FileResponseCompat,
	File,
	ThunkApiConfig<ResponseError>
>("files/upload", async (file, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postData(
		uploadFileCompatURL(),
		{ body: { file } },
		access_token,
	)

	if (response.ok) {
		const json = await response.json()
		return json
	}

	return rejectWithValue(await getErrorObject(response))
})

/**
 *  Slice
 */
export type FilesState = SliceState

const initialState: FilesState = {
	...sliceInitialState,
}

const filesSlice = createSlice({
	name: "files",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(uploadDeskFile.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(uploadDeskFile.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(uploadDeskFile.fulfilled, (state) => {
			setFetchSuccessState(state)
		})
	},
})

export const filesReducer = filesSlice.reducer
