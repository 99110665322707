import React, { useEffect, useMemo, useRef } from "react"

import dayjs from "dayjs"
import { toPng } from "html-to-image"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import VisitorBadge from "./VisitorBadge"
import { Printer } from "@awesome-cordova-plugins/printer"
import { Capacitor } from "@capacitor/core"

import { SignInFieldType } from "../../redux/printer_settings/types"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { formatUser } from "../../redux/user/utils"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import { setPrintError } from "../../redux/visitor_registration/visitorRegistrationSlice"

import "./PrintBadge.sass"

const PrintBadge = () => {
	const badgeRef = useRef<HTMLDivElement>(null)

	const { printer } = useAppSelector(selectTablet)
	const { data, registration, branding } = useAppSelector(
		selectVisitorRegistration,
	)

	const actions = useActions({
		setPrintError: (printError: boolean) => setPrintError(printError),
	})

	const printerSettings = data?.printer_settings

	const signInField1 = useMemo(() => {
		const signinField = data?.printer_settings?.signin_fields[0]
		if (signinField) {
			const regFieldVal = registration?.fields.find(
				(f) => f.id === signinField.id,
			)?.value
			if (regFieldVal) {
				return typeof regFieldVal === "string"
					? regFieldVal
					: formatUser(regFieldVal)
			} else {
				if (signinField.type === SignInFieldType.HOST && registration?.host) {
					return formatUser(registration.host)
				}
			}
		}
		return undefined
	}, [
		data?.printer_settings?.signin_fields,
		registration?.fields,
		registration?.host,
	])

	const signInField2 = useMemo(() => {
		const signinField = data?.printer_settings?.signin_fields[1]
		if (signinField) {
			const regFieldVal = registration?.fields.find(
				(f) => f.id === signinField.id,
			)?.value
			if (regFieldVal) {
				return typeof regFieldVal === "string"
					? regFieldVal
					: formatUser(regFieldVal)
			} else {
				if (signinField.type === SignInFieldType.HOST && registration?.host) {
					return formatUser(registration.host)
				}
			}
		}
		return undefined
	}, [
		data?.printer_settings?.signin_fields,
		registration?.fields,
		registration?.host,
	])

	useEffect(() => {
		if (
			badgeRef &&
			badgeRef.current &&
			printerSettings &&
			printerSettings.enable_badge_printing &&
			printer
		) {
			toPng(badgeRef.current, { cacheBust: true })
				.then((dataUrl) => {
					const content = `base64://${dataUrl.split(",")[1]}`

					let ip = printer.ip_address
					if (Capacitor.getPlatform() === "ios") {
						ip = `ipp://${printer.ip_address}:631/ipp/print`
					}

					Printer.print(content, {
						printer: ip,
						orientation: "portrait",
						monochrome: false,
						autoFit: true,
						margin: false,
						photo: true,
					}).then((success) => {
						if (!success) {
							actions.setPrintError(true)
						} else {
							analyticsEvent(SupportedEvents.VISITOR_USER_BADGE_PRINTED, {
								id: registration?.id,
								name: registration?.full_name,
								visitor_email: registration?.visitor?.email,
								host_email: registration?.host?.email,
								printer_id: printer.id,
								printer_connected_device_id: printer.connected_device?.id,
								printer_building_id: printer.building?.id,
							})
						}
					})
				})
				.catch((err) => {
					console.error("toPng", err)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actions, badgeRef, printerSettings, printer])

	if (!printerSettings || !registration) {
		return null
	}

	return (
		<div className="PrintBadge">
			<VisitorBadge
				ref={badgeRef}
				name={registration.full_name}
				companyName={branding?.company_name}
				companyLogo={branding?.company_logo?.url}
				checkInType={printerSettings.show_check_in}
				brandingType={printerSettings.branding}
				checkInTime={
					registration.checkin_at ? dayjs(registration.checkin_at) : dayjs()
				}
				message={printerSettings.message}
				signInField1={signInField1}
				signInField2={signInField2}
			/>
		</div>
	)
}

export default PrintBadge
