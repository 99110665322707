import React, { FC, useRef } from "react"

import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { FilterSpecialValues } from "../../Filter/types"
import AmenitiesFilter from "./AmenitiesFilter"
import { HorizontalListSelect } from "./HorizontalListSelect"

import { useFetchDepartmentsQuery } from "../../../redux/api/departments"

export type Filter = {
	occupancy: string
	department: string
	amenities: string[]
}

type FilterSelectorProps = {
	onChange: (filter: Filter) => void
	filter: Filter
}

export const OccupancyFilterValues = {
	ALL: "ALL",
	BOOKED: "BOOKED",
	FREE: "FREE",
}

const FilterSelector: FC<FilterSelectorProps> = ({
	onChange,
	filter: preFilter,
}) => {
	const { t } = useTranslation()
	const filter = useRef<Filter>(preFilter)

	const { data: { results: departments = [] } = {} } =
		useFetchDepartmentsQuery()

	const departmentList = [...departments]

	const occupancyItems: { name: string; id: string }[] = [
		{
			name: t("mobile.general.all_desks"),
			id: OccupancyFilterValues.ALL,
		},

		{
			name: t("mobile.general.only_booked"),
			id: OccupancyFilterValues.BOOKED,
		},
		{
			name: t("mobile.general.only_free"),
			id: OccupancyFilterValues.FREE,
		},
	]

	const hasDepartments: boolean = departments.length > 0

	if (hasDepartments) {
		if (!departmentList.find((d) => d.id === FilterSpecialValues.ALL)) {
			departmentList.unshift({
				name: t("mobile.general.all_departments"),
				id: FilterSpecialValues.ALL,
			})
		}
	}

	const handleOnSelect = (
		id: string | string[],
		key: "occupancy" | "department" | "amenities",
	) => {
		filter.current = { ...filter.current, [key]: id }
		onChange(filter.current)
	}

	const handleOnSelectOccupancy = (id: string) => {
		handleOnSelect(id, "occupancy")
	}
	const handleOnSelectDepartment = (id: string) => {
		handleOnSelect(id, "department")
	}
	const handleOnSelectAmenity = (ids: string[]) => {
		analyticsEvent(SupportedEvents.FILTER_BY_AMENITY, {
			ids,
		})

		handleOnSelect(ids, "amenities")
	}

	return (
		<div className={"filter-selector"}>
			<HorizontalListSelect
				listElements={occupancyItems}
				onSelect={handleOnSelectOccupancy}
				preSelected={filter.current.occupancy}
			>
				{t("mobile.general.occupancy")}
			</HorizontalListSelect>
			{hasDepartments && (
				<HorizontalListSelect
					listElements={departmentList}
					onSelect={handleOnSelectDepartment}
					preSelected={filter.current.department}
				>
					{t("mobile.general.departments")}
				</HorizontalListSelect>
			)}
			<AmenitiesFilter
				onSelect={handleOnSelectAmenity}
				preSelected={filter.current.amenities}
			/>
		</div>
	)
}

export default FilterSelector
