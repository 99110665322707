import { CSSProperties } from "react"

export const contentStyle: CSSProperties = {
	maxWidth: "64rem",
	height: "auto",
	position: "absolute",
	top: "50vh",
	left: "50vw",
	right: "none",
	bottom: "none",
	padding: "2rem",
	transform: "translate(-50%, -50%)",
	boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
	borderRadius: "3px",
	border: "none",
	overflow: "auto",
}

export const overlayStyle: CSSProperties = {
	backgroundColor: "rgba(196, 196, 196, 0.7)",
	zIndex: 100,
}

export const mobileContentStyle: CSSProperties = {
	maxWidth: "100%",
	width: "100%",
	height: "auto",
	position: "absolute",
	padding: "2rem",
	top: "none",
	left: "none",
	right: "none",
	bottom: "0",
	boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
	borderRadius: "3px",
	border: "none",
	overflow: "auto",
}

export const mobileOverlayStyle: CSSProperties = {
	backgroundColor: "rgba(196, 196, 196, 0.7)",
	zIndex: 100,
}
