import cn from "classnames"
import ReactModal from "react-modal"

import {
	contentStyle,
	mobileContentStyle,
	mobileOverlayStyle,
	overlayStyle,
} from "../../../modals/modalStyles"
import { Children } from "../../../types/sharedTypes"
import Button from "../Button"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

export type ConfirmationModalProps = {
	open: boolean
	title?: Children
	hint?: Children
	cancelText?: Children
	confirmText?: Children
	onConfirm: () => void
	onCancel: () => void
	className?: string
	isMobile?: boolean
}

export const ConfirmationModal = ({
	open,
	title = "Are you sure?",
	hint = "If you wish to continue, Press Confirm.",
	cancelText = "Cancel",
	confirmText = "Confirm",
	onConfirm,
	onCancel,
	className,
	isMobile = false,
}: ConfirmationModalProps) => {
	if (!open) {
		return null
	}

	return (
		<ReactModal
			isOpen={open}
			style={{
				content: isMobile ? mobileContentStyle : contentStyle,
				overlay: isMobile ? mobileOverlayStyle : overlayStyle,
			}}
			onRequestClose={() => onCancel()}
			ariaHideApp={false}
		>
			<div
				className={cn(
					"ModalForm",
					isMobile ? "ConfirmationMenu" : "ConfirmationModal",
					className,
				)}
			>
				<div className="top-bar">
					<h1>{title}</h1>
					<div className="close" onClick={() => onCancel()}>
						<CrossSVG />
					</div>
				</div>

				<div className="hint">{hint}</div>

				<div className="actions actions-edit">
					<Button variant="secondary" onClick={onCancel}>
						{cancelText}
					</Button>
					<Button variant="primary" onClick={onConfirm}>
						{confirmText}
					</Button>
				</div>
			</div>
		</ReactModal>
	)
}
