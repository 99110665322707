import { api } from ".."
import { companyConsentURL } from "../../../api"
import { Consents } from "./types"

export const consentApi = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchConsents: builder.query<Consents, void>({
			query: () => companyConsentURL("desk_booking"),
			// transformResponse: (response: Consent[]) => {
			providesTags: ["Consents"],
		}),
		fetchDeskBookingConsent: builder.query<boolean | null, void>({
			query: () => companyConsentURL("desk_booking"),
			transformResponse: (response: Consents) => {
				return (
					response.results.find(
						(consent) => consent.consent_type === "desk_booking",
					)?.status ?? null
				)
			},
			providesTags: ["Consents"],
		}),
		createDeskBookingConsent: builder.mutation<boolean, boolean | void>({
			query: (status = true) => ({
				url: companyConsentURL("desk_booking"),
				method: "POST",
				body: { status },
			}),
			invalidatesTags: ["Consents"],
		}),
		updateDeskBookingConsent: builder.mutation<boolean, boolean | void>({
			query: (status = true) => ({
				url: companyConsentURL("desk_booking"),
				method: "PATCH",
				body: { status },
			}),
			invalidatesTags: ["Consents"],
		}),
	}),
})

export const {
	useFetchConsentsQuery,
	useFetchDeskBookingConsentQuery,
	useCreateDeskBookingConsentMutation,
	useUpdateDeskBookingConsentMutation,
} = consentApi
