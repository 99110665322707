import { api, invalidateOn } from ".."
import { mePortalURL, meURL } from "../../../api"
import { setCompany } from "../../app/appSlice"
import { setUser } from "../../user/userSlice"
import { UserRequest } from "../users/types"
import { MeResponse } from "./types"

export const me = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchMe: builder.query<MeResponse, void>({
			query: () => ({
				url: meURL(),
			}),
			providesTags: ["Me"],
			// update user state so we don't make two calls to the API
			onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
				const result = await queryFulfilled
				dispatch(setUser(result.data))
				if (result.data.companies[0].id) {
					dispatch(setCompany(result.data.companies[0].id))
				}
			},
		}),
		updateMe: builder.mutation<MeResponse, Partial<UserRequest>>({
			query: (payload) => ({
				url: mePortalURL(),
				method: "PUT",
				body: payload,
			}),
			invalidatesTags: invalidateOn({ success: ["Me"] }),
		}),
	}),
})

export const { useFetchMeQuery, useLazyFetchMeQuery, useUpdateMeMutation } = me
