import { api } from "../.."
import { subscriptionsPlansURL } from "../../../../api"
import { getPlanGroup } from "../utils"
import { PlanList, PlanListBe } from "./types"

export const plans = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchPlans: builder.query<PlanList, void>({
			query: () => subscriptionsPlansURL(),
			providesTags: ["SubscriptionPlans"],
			transformResponse: (response: PlanListBe) =>
				response.map((plans) => ({
					...plans,
					planGroup: getPlanGroup(plans.plan_type),
				})),
		}),
	}),
})

export const { useFetchPlansQuery } = plans
