import React, { ChangeEvent, MouseEvent } from "react"

import { ReactComponent as CrossSVG } from "../assets/images/icons/Cross.svg"
import { ReactComponent as LoupeSVG } from "../assets/images/icons/Loupe.svg"

import "./Search.sass"

type Props = {
	value?: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	onClear?: (e: MouseEvent<HTMLDivElement>) => void
	placeholder?: string
}

export default function Search({
	value,
	onChange,
	onClear,
	placeholder = "Search",
}: Props) {
	return (
		<div className="Search">
			{!value ? (
				<div className="loupe">
					<LoupeSVG />
				</div>
			) : (
				<div className="clear" onClick={onClear}>
					<CrossSVG />
				</div>
			)}
			<input value={value} onChange={onChange} placeholder={placeholder} />
		</div>
	)
}
