import { useAppSelector } from "../redux/reducers"
import { selectUserPermissions } from "../redux/user/selectors"

export const useCheckForPermission = (permission?: string) => {
	const permissions: string[] = useAppSelector(selectUserPermissions)

	// If permission is not specified, return as if permission is granted.
	// This is purely for convenience, so that we do not have to
	// do falsy checks when calling the function.
	if (!permission) {
		return true
	}

	return permissions.includes(permission)
}
