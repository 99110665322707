import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { BILLING_URL } from "../../../../api"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { BILLING_PATHNAMES } from "../../Billing/constants"
import { toFriendlyName } from "../../Billing/utils"

import { ACTIVE_LICENSE_STATUSES } from "../../../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"
import {
	Subscription,
	SubscriptionUnit,
} from "../../../../redux/api/billing/subscriptions/types"
import {
	PlanType,
	SubscriptionStatus,
} from "../../../../redux/api/billing/types"

import Card from "../../../../components/basic/Card"

import "./RoomsSubscription.sass"

const JOAN_ROOM_PLAN_TYPES = [
	"room_essentials",
	"room_professional",
	"room_enterprise",
]

const ACTIVE_AND_FUTURE_LICENSE_STATUSES = [
	...ACTIVE_LICENSE_STATUSES,
	"future",
] as const

const RoomsSubscriptions = () => {
	const { data: { results: subscriptions = [] } = {} } =
		useFetchSubscriptionsQuery({ limit: 10000 })

	const roomSubscriptions = subscriptions
		.filter((s: Subscription) => JOAN_ROOM_PLAN_TYPES.includes(s.plan_type))
		.filter((s: Subscription) =>
			ACTIVE_AND_FUTURE_LICENSE_STATUSES.includes(s.status),
		)

	if (!roomSubscriptions.length) {
		return
	}

	return roomSubscriptions.map((s: Subscription) => (
		<RoomSubscription
			key={s.subscription_id}
			type={s.plan_type}
			unit={s.unit}
			status={s.status}
		/>
	))
}

type RoomSubscriptionProps = {
	type: PlanType
	status: SubscriptionStatus
	unit: SubscriptionUnit[]
}

const RoomSubscription = ({ type, unit, status }: RoomSubscriptionProps) => {
	const { t } = useTranslation()
	const isPortalMergerEnabled = useCheckForFeatureFlag("portalMerger.isEnabled")

	const isTrial = status === "in_trial"

	return (
		<Card className="RoomSubscription">
			<div className="info">
				<div className="title">
					{isTrial
						? t("desktop.components.subscription_card.free_trial")
						: t("desktop.components.subscription_card.subscription")}
				</div>
				<div className="type">{toFriendlyName(type)}</div>
			</div>
			<div className="licenses">
				<div>
					{t("desktop.components.subscription_card.licenses", {
						count: unit[0].quantity,
						unit: unit[0].type,
					})}
				</div>
				<div>
					{t("desktop.components.subscription_card.active_units", {
						count: unit[0].quantity_used,
						unit: unit[0].type,
					})}
				</div>
			</div>
			<div className="actions">
				<NavLink
					to={
						isPortalMergerEnabled
							? BILLING_PATHNAMES.overview.root
							: BILLING_URL
					}
				>
					{t(
						"desktop.settings.rooms.overview.subscriptions_details.go_to_billing",
					)}
				</NavLink>
			</div>
		</Card>
	)
}

export default RoomsSubscriptions
