import { ThunkApiConfig } from "RootType"

import {
	deleteJSON,
	devicesURL,
	get,
	patchJSON,
	postJSON,
	printerURL,
	printersURL,
	putJSON,
	tabletPairURL,
	tabletRepairURL,
	tabletURL,
	tabletUnpairURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	DeviceResponse,
	DevicesResponse,
	PrinterRequest,
	PrinterResponse,
	PrinterUpdateRequest,
	TabletPairRequest,
	TabletResponse,
	TabletUpdateRequest,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchDevices = createAsyncThunk<
	DevicesResponse,
	void,
	ThunkApiConfig
>("devices/fetch", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()
	const response = await get(devicesURL(), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const pairTablet = createAsyncThunk<
	TabletResponse,
	TabletPairRequest,
	ThunkApiConfig<ResponseError>
>("devices/pairTablet", async (body, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await patchJSON(
		tabletPairURL(),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	if (response.status === 404) {
		return rejectWithValue({ pin: "Invalid PIN" })
	}

	return rejectWithValue(await getErrorObject(response))
})

export const repairTablet = createAsyncThunk<void, string, ThunkApiConfig>(
	"devices/repairTablet",
	async (id, { getState }) => {
		const { access_token } = getState().auth

		const response = await patchJSON(
			tabletRepairURL(id),
			undefined,
			access_token,
		)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

export const unpairTablet = createAsyncThunk<void, string, ThunkApiConfig>(
	"devices/unpairTablet",
	async (id, { getState }) => {
		const { access_token } = getState().auth

		const response = await deleteJSON(
			tabletUnpairURL(id),
			undefined,
			access_token,
		)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

export interface UpdateTabletProps extends TabletUpdateRequest {
	id: string
}

export const updateTablet = createAsyncThunk<
	TabletResponse,
	UpdateTabletProps,
	ThunkApiConfig<ResponseError>
>(
	"devices/updateTablet",
	async ({ id, ...body }, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await patchJSON(
			tabletURL(id),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export const createPrinter = createAsyncThunk<
	PrinterResponse,
	PrinterRequest,
	ThunkApiConfig<ResponseError>
>("devices/createPrinter", async (body, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		printersURL(),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

export interface UpdatePrinterProps extends PrinterUpdateRequest {
	id: string
}

export const updatePrinter = createAsyncThunk<
	PrinterResponse,
	UpdatePrinterProps,
	ThunkApiConfig<ResponseError>
>(
	"devices/updatePrinter",
	async ({ id, ...body }, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await putJSON(
			printerURL(id),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export const deletePrinter = createAsyncThunk<void, string, ThunkApiConfig>(
	"devices/deletePrinter",
	async (id, { getState }) => {
		const { access_token } = getState().auth

		const response = await deleteJSON(printerURL(id), undefined, access_token)

		if (response.ok) {
			return
		}

		throw new Error(await getErrorMessage(response))
	},
)

export interface DeviceState extends SliceState {
	entries: DeviceResponse[]
}

const initialState: DeviceState = {
	entries: [],
	...sliceInitialState,
}

const devicesSlice = createSlice({
	name: "devices",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDevices.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.entries = payload.results
		})
		builder.addCase(unpairTablet.fulfilled, (state, { meta }) => {
			setSubmitSuccessState(state)
			state.entries = state.entries.filter((e) => e.id !== meta.arg)
			return state
		})
		builder.addCase(deletePrinter.fulfilled, (state, { meta }) => {
			setSubmitSuccessState(state)
			state.entries = state.entries.filter((e) => e.id !== meta.arg)
			return state
		})
		builder.addMatcher(
			isAnyOf(
				pairTablet.fulfilled,
				repairTablet.fulfilled,
				updateTablet.fulfilled,
				createPrinter.fulfilled,
				updatePrinter.fulfilled,
			),
			(state) => {
				setSubmitSuccessState(state)
			},
		)
		builder.addMatcher(
			isAnyOf(
				pairTablet.pending,
				repairTablet.pending,
				unpairTablet.pending,
				updateTablet.pending,
				createPrinter.pending,
				updatePrinter.pending,
				deletePrinter.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(isAnyOf(fetchDevices.pending), (state) => {
			state.isLoading = true
		})
		builder.addMatcher(isAnyOf(fetchDevices.rejected), (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addMatcher(
			isAnyOf(
				pairTablet.rejected,
				repairTablet.rejected,
				unpairTablet.rejected,
				updateTablet.rejected,
				createPrinter.rejected,
				updatePrinter.rejected,
				deletePrinter.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
	},
})

export const devicesReducer = devicesSlice.reducer
