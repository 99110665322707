import React from "react"

import { useTranslation } from "react-i18next"

import LogoVariant from "../../LogoVariant"

import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Help.sass"

const Help = () => {
	const { t } = useTranslation()

	return (
		<SafeViewArea className="Help">
			<TopNav />
			<div className="body">
				<LogoVariant variant="mobile" />
				<h2>{t("mobile.auth.cant_login")}</h2>
				<p>{t("mobile.auth.help_email")}</p>
				<p>{t("mobile.auth.help_password")}</p>
				<p>{t("mobile.auth.help_note")}</p>
			</div>
		</SafeViewArea>
	)
}

export default Help
