import { api } from ".."
import { employeeScreeningURL } from "../../../api"
import { isApiResponseError, isRejected } from "../types"
import { EmployeeScreening, ScreeningQuestionResponse } from "./types"

export const screenings = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchEmployeeScreening: builder.query<EmployeeScreening, string>({
			query: (buildingId) => employeeScreeningURL(buildingId),
			providesTags: ["Screenings"],
		}),

		saveEmployeeScreening: builder.mutation<
			{ passed: boolean },
			{ buildingId: string; questions: ScreeningQuestionResponse[] }
		>({
			query: ({ buildingId, questions }) => ({
				url: employeeScreeningURL(buildingId),
				method: "POST",
				body: { questions },
			}),
			invalidatesTags: ["Screenings"],
		}),
	}),
})

export const {
	useFetchEmployeeScreeningQuery,
	useLazyFetchEmployeeScreeningQuery,
	useSaveEmployeeScreeningMutation,
} = screenings

export const isEmployeeScreeningNotApplicable = (screening: unknown): boolean =>
	isRejected(screening) &&
	isApiResponseError(screening.error) &&
	screening.error.status === 404
