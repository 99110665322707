import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { timeZone } from "../dayjs"
import Datestring from "./Datestring"

import { ShareableAccessLog } from "../redux/shareable/types"

import "./AccessActivityTable.sass"

type AccessActivityTableProps = {
	accessLogs: ShareableAccessLog[]
}

const AccessActivityTable = ({ accessLogs }: AccessActivityTableProps) => {
	const { t } = useTranslation()
	const getAccessLogs = () =>
		accessLogs.map((log, index) => {
			const date = dayjs(log.created_at).tz(timeZone)

			const fromNow = date.fromNow()

			return (
				<tr key={index}>
					<td>{log.user_agent}</td>
					<td>{log.ip_address}</td>
					<td>
						<Datestring date={date} showYear /> {date.format("HH:mm")}
						<br />
						{`(${fromNow})`}
					</td>
				</tr>
			)
		})

	return (
		<div className="access-activity-table">
			<table>
				<thead>
					<tr>
						<th>
							<div className="table-title">
								{t(
									"desktop.settings.floor_plans.share.access_log.table.access_type_title",
								)}
							</div>
							<div className="table-description">
								(
								{t(
									"desktop.settings.floor_plans.share.access_log.table.access_type_description",
								)}
								)
							</div>
						</th>
						<th>
							<div className="table-title">
								{t(
									"desktop.settings.floor_plans.share.access_log.table.ip_address_title",
								)}
							</div>
						</th>
						<th>
							<div className="table-title">
								{t(
									"desktop.settings.floor_plans.share.access_log.table.date_time_title",
								)}
							</div>
							<div className="table-description">
								(
								{t(
									"desktop.settings.floor_plans.share.access_log.table.date_time_description",
								)}
								)
							</div>
						</th>
					</tr>
				</thead>
				<tbody>{getAccessLogs()}</tbody>
			</table>
		</div>
	)
}

export default AccessActivityTable
