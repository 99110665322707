import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { Applies_To, ScreeningConfigList } from "../redux/screenings/types"

import HealthScreeningExportForm from "../components/Form/HealthScreeningExportForm"

type Props = {
	open: boolean
	screeningConfigsList: ScreeningConfigList[]
}

export const HealthScreeningExportModal = ({
	open,
	screeningConfigsList,
}: Props) => {
	const { closeModal } = useModals()

	let export_for

	if (
		screeningConfigsList.every(
			(sc) =>
				sc.applies_to.includes(Applies_To.EMPLOYEES) &&
				!sc.applies_to.includes(Applies_To.VISITORS),
		)
	) {
		export_for = "employees"
	} else if (
		screeningConfigsList.every(
			(sc) =>
				!sc.applies_to.includes(Applies_To.EMPLOYEES) &&
				sc.applies_to.includes(Applies_To.VISITORS),
		)
	) {
		export_for = "visitors"
	} else {
		export_for = "visitors,employees"
	}

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<HealthScreeningExportForm export_for={export_for} />
		</ReactModal>
	)
}
