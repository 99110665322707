import dayjs, { Dayjs } from "dayjs"

import { is12Hour } from "../../utils"
import { ReservationResponse } from "../reservations/types"
import { byDateString, byUserId } from "../reservations/utils"
import { TimeslotResponse } from "../timeslots/types"
import { UserResponse } from "../users/types"

export function dateRangeOverlaps(
	aStart: Date,
	aEnd: Date,
	bStart: Date,
	bEnd: Date,
) {
	if (bStart >= aStart && bEnd <= bStart) return true // b in a
	if (aStart >= bStart && aEnd <= bEnd) return true // a in b
	if (bEnd > aStart && bEnd <= aEnd) return true // b ends in a
	if (aEnd > bStart && aEnd <= bEnd) return true // a ends in b

	return false
}

export function areSlotsOverlapping(
	a: Partial<TimeslotResponse>,
	b: Partial<TimeslotResponse>,
) {
	if (!a || !a.from || !a.to || !b || !b.from || !b.to) {
		return false
	}

	const aStart = dayjs(`1984-06-07T${a.from}`).toDate()
	const aEnd = dayjs(`1984-06-07T${a.to}`).toDate()
	const bStart = dayjs(`1984-06-07T${b.from}`).toDate()
	const bEnd = dayjs(`1984-06-07T${b.to}`).toDate()

	return dateRangeOverlaps(aStart, aEnd, bStart, bEnd)
}

export function getAvailableSlots(
	slots: TimeslotResponse[],
	bookedSlots: Partial<TimeslotResponse>[],
) {
	return slots.filter((slot) => {
		let isOccupied = false

		bookedSlots.forEach((booked) => {
			if (areSlotsOverlapping(booked, slot)) {
				isOccupied = true
			}
		})

		return !isOccupied
	})
}

export function getOccupiedSlots(
	slots: TimeslotResponse[],
	bookedSlots: TimeslotResponse[],
) {
	return slots.filter((slot) => {
		let isOccupied = false
		bookedSlots.forEach((booked) => {
			if (areSlotsOverlapping(booked, slot)) {
				isOccupied = true
			}
		})

		return isOccupied
	})
}

export function findAvailableSlots(
	date: Dayjs,
	reservations: ReservationResponse[],
	user: UserResponse,
	slots: TimeslotResponse[],
) {
	const activeSlots = slots.filter((slot) => slot.active)
	const dateString = date.format("YYYY-MM-DD")
	const reservationsToday = reservations
		.filter(byDateString(dateString))
		.filter(byUserId(user.id))

	const availableReservations = []

	const availableSlots = getAvailableSlots(
		activeSlots,
		reservationsToday.map((r) => ({ from: r.from, to: r.to })),
	)

	if (availableSlots.length > 0) {
		const timeslot = availableSlots.sort(timeslotComparator)[0]
		availableReservations.push({ user, timeslot })
	}

	return availableReservations
}

export function isPartialTimeslot(
	timeslot: TimeslotResponse,
	slots: TimeslotResponse[],
) {
	const activeSlots = slots.filter((slot) => slot.active)
	const remainingSlots = activeSlots.filter((s) => {
		return !areSlotsOverlapping(s, timeslot)
	})

	return remainingSlots.length > 0
}

export function timeslotComparator(a: TimeslotResponse, b: TimeslotResponse) {
	const { from: fromA, to: toA } = a
	const { from: fromB, to: toB } = b
	const timeFromA = dayjs("1984-06-07T" + fromA)
	const timeToA = dayjs("1984-06-07T" + toA)
	const timeFromB = dayjs("1984-06-07T" + fromB)
	const timeToB = dayjs("1984-06-07T" + toB)

	if (timeFromA.isAfter(timeFromB)) {
		return 1
	} else if (timeFromB.isAfter(timeFromA)) {
		return -1
	} else {
		if (timeToA.isAfter(timeToB)) {
			return 1
		}
		if (timeToB.isAfter(timeToA)) {
			return -1
		}
		return 0
	}
}

export const formatSlotTime = (str: string) => {
	const timeFormat = is12Hour() ? "hh:mm A" : "HH:mm"
	return dayjs()
		.hour(parseInt(str.substring(0, 2)))
		.minute(parseInt(str.substring(3, 5)))
		.format(timeFormat)
}
