import { ThunkApiConfig } from "RootType"
import dayjs from "dayjs"

import { get, suggestionsURL } from "../../api"
import {
	PaginationState,
	SliceState,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { Suggestion, SuggestionsResponse } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
type FetchSuggestionsProp = {
	now?: string
	limit?: number
}

export const fetchSuggestions = createAsyncThunk<
	SuggestionsResponse,
	FetchSuggestionsProp,
	ThunkApiConfig
>(
	"suggestions/fetch",
	async ({ now = dayjs().toISOString(), limit = 3 }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await get(suggestionsURL({ limit, now }), {}, access_token)

		if (response.ok) {
			return await response.json()
		}

		throw new Error(await getErrorMessage(response))
	},
)

/**
 *  Slice
 */

export interface SuggestionsState extends SliceState, PaginationState {
	entries: Suggestion[]
}

const initialState: SuggestionsState = {
	entries: [],
	...sliceInitialState,
	...paginationInitialState,
}

const suggestionsSlice = createSlice({
	name: "suggestions",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSuggestions.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchSuggestions.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchSuggestions.fulfilled, (state, { payload }) => {
			const { results, ...paginationData } = payload
			state = {
				...state,
				...paginationData,
				entries: results,
			}
			setFetchSuccessState(state)

			return state
		})
	},
})

export const suggestionsReducer = suggestionsSlice.reducer
