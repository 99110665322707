import { api } from "../.."
import {
	paymentsCreditCardURL,
	paymentsInvoiceURL,
	paymentsResellerURL,
	paymentsURL,
} from "../../../../api"
import { Payments, Reseller } from "./types"

export const payments = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchPayments: builder.query<Payments, void>({
			query: () => paymentsURL(),
			providesTags: ["Payments"],
		}),

		savePaymentReseller: builder.mutation<void, Reseller>({
			query: (body) => ({
				url: paymentsResellerURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Payments"],
		}),

		savePaymentCreditCard: builder.mutation<void, void>({
			query: () => ({
				url: paymentsCreditCardURL(),
				method: "POST",
				body: {},
			}),
			invalidatesTags: ["Payments"],
		}),

		savePaymentInvoice: builder.mutation<void, void>({
			query: () => ({
				url: paymentsInvoiceURL(),
				method: "POST",
				body: {},
			}),
			invalidatesTags: ["Payments"],
		}),

		savePaymentPrepaidCode: builder.mutation<void, void>({
			query: () => ({
				// TODO: Implement when BE available
				url: `${paymentsURL()}missing-url/`,
				method: "POST",
				body: {},
			}),
			invalidatesTags: ["Payments"],
		}),
	}),
})

export const {
	useFetchPaymentsQuery,
	useSavePaymentResellerMutation,
	useSavePaymentCreditCardMutation,
	useSavePaymentInvoiceMutation,
	useSavePaymentPrepaidCodeMutation,
} = payments
