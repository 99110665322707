import { ThunkApiConfig } from "RootType"

import { buildingsWithVMURL, get } from "../../api"
import {
	SliceState,
	getErrorMessage,
	setFetchErrorState,
	setFetchSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { BuildingResponse, BuildingsResponse, FetchOptions } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */

export const fetchBuildingsWithVM = createAsyncThunk<
	BuildingsResponse,
	FetchOptions,
	ThunkApiConfig
>("buildingsWithVM/fetch", async (options, { getState, rejectWithValue }) => {
	const { access_token } = getState().auth

	const response = await get(
		buildingsWithVMURL({
			...options,
			stats: false,
			query: {
				fields: ["*", "settings"],
			},
		}),
		{},
		access_token,
	)

	if (response.ok) {
		const json = await response.json()

		return json
	}
	return rejectWithValue(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface BuildingsWithVMState extends SliceState {
	entries: BuildingResponse[]
}

const initialState: BuildingsWithVMState = {
	...sliceInitialState,
	entries: [],
}

const buildingsWithVMSlice = createSlice({
	name: "buildingsWithVM",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBuildingsWithVM.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchBuildingsWithVM.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchBuildingsWithVM.fulfilled, (state, { payload }) => {
			const { results } = payload
			state = {
				...state,
				entries: results,
			}
			setFetchSuccessState(state)
			return state
		})
	},
})

export const buildingsWithVMReducer = buildingsWithVMSlice.reducer
