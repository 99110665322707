import { api } from ".."
import { departmentURL, departmentsURL } from "../../../api"
import { DepartmentResponse, DepartmentsResponse, FetchOptions } from "./types"
import { DepartmentRequest } from "./types"

const FETCH_DEFAULTS = {
	stats: false,
	search: "",
}

export const departments = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchDepartments: builder.query<DepartmentsResponse, FetchOptions | void>({
			query: (options) => departmentsURL({ ...FETCH_DEFAULTS, ...options }),
			providesTags: (result, _error) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "Departments" as const,
								id,
							})),
							{ type: "Departments", id: "LIST" },
					  ]
					: [{ type: "Departments", id: "LIST" }],
		}),

		fetchDepartment: builder.query<DepartmentResponse, string>({
			query: (id) => departmentURL(id),
			providesTags: (_result, _error, id) => [{ type: "Departments", id }],
		}),

		createDepartment: builder.mutation<DepartmentResponse, DepartmentRequest>({
			query: (body) => ({
				url: departmentsURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Departments", id: "LIST" }],
		}),

		updateDepartment: builder.mutation<
			DepartmentResponse,
			{
				id: string
				body: Partial<DepartmentRequest>
			}
		>({
			query: ({ id, body }) => ({
				url: departmentURL(id),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "Departments", id: "LIST" },
				{ type: "Departments", id },
			],
		}),

		deleteDepartment: builder.mutation<void, string>({
			query: (id) => ({
				url: departmentURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "Departments", id: "LIST" },
				{ type: "Departments", id },
				{ type: "Desks", id: "LIST" },
			],
		}),
	}),
})

export const {
	useFetchDepartmentsQuery,
	useFetchDepartmentQuery,
	useCreateDepartmentMutation,
	useUpdateDepartmentMutation,
	useDeleteDepartmentMutation,
} = departments
