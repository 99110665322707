import React, { MouseEvent, useCallback } from "react"

import { Dayjs } from "dayjs"

import ExportAssetsModal from "../../modals/ExportAssetsModal"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

type Props = {
	start: Dayjs
	end: Dayjs
	departmentId?: string
	assetTypeId?: string
}

function AssetCSVReportDownload({
	start,
	end,
	departmentId,
	assetTypeId,
}: Props) {
	const { openModal } = useModals()

	const handleDownloadClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			openModal(ExportAssetsModal, {
				range: { start, end },
				departmentId,
				assetTypeId,
			})
		},
		[start, end, openModal, departmentId, assetTypeId],
	)

	return (
		<Button onClick={handleDownloadClick} variant="secondary" isSmall>
			CSV
		</Button>
	)
}

export default AssetCSVReportDownload
