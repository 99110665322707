import React from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import { ReactComponent as ArrowBackSVG } from "../../assets/images/icons/Arrow back.svg"

import "./TopNav.sass"

type TopNavProps = {
	backArrow?: boolean
	onGoBack?: () => void
	title?: string | React.ReactNode
	titleCenter?: boolean
	rightIcon?: JSX.Element
	onTopScreen?: boolean
}

export const TopNav: React.FC<TopNavProps> = ({
	backArrow = true,
	onGoBack,
	title,
	titleCenter = false,
	rightIcon,
	onTopScreen = true,
}) => {
	const history = useHistory()

	const TopNavName = classNames({
		TopNav: true,
		top: onTopScreen,
	})

	const titleClassName = classNames({
		title: true,
		center: titleCenter,
	})

	return (
		<div className={TopNavName}>
			<div className="back-arrow">
				{backArrow && (
					<div
						className="arrow"
						onClick={onGoBack ? onGoBack : () => history.goBack()}
					>
						<ArrowBackSVG />
					</div>
				)}
			</div>
			<div className={titleClassName}>{title}</div>
			<div className="right-icon">{rightIcon}</div>
		</div>
	)
}
