import React, { useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { NavLink, useParams } from "react-router-dom"

import { SupportedEvents, analyticsEvent } from "../../../analytics"
import { shortUserTimeFormat } from "../../../utils"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitor } from "../../../redux/visitor/selectors"
import {
	checkoutVisitor,
	fetchVisitor,
} from "../../../redux/visitor/visitorSlice"

import { CorrectIconMapper } from "../../../components/CorrectIconMapper"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"
import Button from "../../../components/basic/Button"
import Loader from "../../../components/basic/Loader"

import { ReactComponent as BuildingSVG } from "../../../assets/images/icons/Building.svg"
import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as CheckSVG } from "../../../assets/images/icons/Check.svg"
import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"
import { ReactComponent as LoginSVG } from "../../../assets/images/icons/Login.svg"
import { ReactComponent as MapsSVG } from "../../../assets/images/icons/Maps.svg"

import "./VisitorDetail.sass"

type ParamsType = {
	id: string
}

const VisitorDetail = () => {
	const { t } = useTranslation()
	const { id } = useParams<ParamsType>()

	const { entry: visitor, isLoading } = useAppSelector(selectVisitor)

	const actions = useActions({
		fetchVisitor: (id: string) => fetchVisitor(id),
		checkoutVisitor: (id: string) => checkoutVisitor(id),
	})

	useEffect(() => {
		if (id && (!visitor || visitor.id !== id)) {
			actions.fetchVisitor(id).then((response) => {
				if (fetchVisitor.fulfilled.match(response)) {
					analyticsEvent(SupportedEvents.VISITOR_USER_OPEN_VISIT_CARD, {
						id: response?.payload.id,
						name: response?.payload.full_name,
						building_id: response?.payload.building?.id,
						visitor_email: response?.payload.email,
						host_email: response?.payload.host?.email,
					})
				}
			})
		}
	}, [actions, id, visitor])

	const handleCheckOut = async () => {
		const response = await actions.checkoutVisitor(id)

		if (checkoutVisitor.rejected.match(response)) {
			window.alert(response.error.message)
		}
	}

	return (
		<SafeViewArea className="VisitorDetail">
			<div className="head">
				<TopNav />
				<div className="icon">
					<div>
						<CorrectIconMapper iconType="visitor" needsWrap={false} />
					</div>
				</div>
			</div>
			<div className="body">
				{visitor && visitor.id === id && !isLoading && (
					<>
						<div className="main-data">
							<div className="info">
								<div className="type">{t("mobile.general.visitor")}</div>
								<div className="status">
									{visitor.checkout_at
										? t("mobile.general.checked_out")
										: t("mobile.general.checked_in")}
								</div>
							</div>
							<div className="name">{visitor.full_name}</div>
						</div>
						<div className="details">
							<div className="detail-row">
								<BuildingSVG />
								<div>{visitor.building.name}</div>
							</div>
							{!!visitor.desk && (
								<div className="detail-row">
									<CorrectIconMapper iconType="desk" needsWrap={false} />
									<div>{visitor.desk?.name}</div>
								</div>
							)}
							{!!visitor.reservation_id && (
								<div className="detail-row">
									<NavLink
										to={"/home/reservation/" + visitor.reservation_id + "/map"}
									>
										<MapsSVG />
										<div>{t("mobile.home.show_on_map")}</div>
									</NavLink>
								</div>
							)}
							<div className="detail-row">
								<CalendarSVG />
								<div>
									{dayjs(visitor.checkin_at).format("dddd MMM D, YYYY")}
								</div>
							</div>
							<div className="detail-row">
								<LoginSVG />
								<div>
									{dayjs(visitor.checkin_at).format(shortUserTimeFormat())}
								</div>
							</div>
							{(visitor.document_signed !== undefined &&
								visitor.document_signed) !== null && (
								<div className="detail-row">
									{visitor.document_signed ? <CheckSVG /> : <CrossSVG />}
									<div>
										{visitor.document_signed
											? t("mobile.visitor.document_signed")
											: t("mobile.visitor.document_not_signed")}
									</div>
								</div>
							)}
							{!visitor.checkout_at && (
								<div className="actions-row">
									<span className="action">
										<Button variant="mobile-action" onClick={handleCheckOut}>
											{t("mobile.general.check_out")}
										</Button>
									</span>
								</div>
							)}
						</div>
					</>
				)}
				{isLoading && (
					<div className="loading">
						<Loader />
					</div>
				)}
			</div>
		</SafeViewArea>
	)
}

export default VisitorDetail
