import * as React from "react"

import { Redirect } from "react-router-dom"

import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../hooks/useCheckForPermission"
import NotFound from "../screens/NotFound"
import { ONBOARDING_PATHS } from "../screens/Onboarding/constants"
import Route, { RoutePropsWithFeature } from "./Route"

import { selectNeedsOnboarding, selectOnboarding } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"

type Props = RoutePropsWithFeature & {
	/**
	 * Name of the permission user must have in order to have access to the route.
	 */
	accessPermission?: string

	/**
	 * Name of the feature flag that must be enabled in order to have access to the route.
	 */
	accessFeatureFlag?: string
}

/**
 * Route that will display the view if user has correct access rights based on
 * user permissions and enabled feature flags. If access is not permitted, control
 * will display generic 404 view.
 */
const ProtectedRoute: React.FC<React.PropsWithChildren<Props>> = ({
	accessPermission,
	accessFeatureFlag,
	...props
}) => {
	const hasPermissionAccess: boolean = useCheckForPermission(accessPermission)
	const hasFeatureFlagAccess: boolean =
		useCheckForFeatureFlag(accessFeatureFlag)
	const needsOnboarding = useAppSelector(selectNeedsOnboarding)
	const onboarding = useAppSelector(selectOnboarding)

	if (needsOnboarding && onboarding) {
		const redirect = ONBOARDING_PATHS[onboarding.steps[0]]
		if (redirect) {
			return <Redirect to={redirect} />
		}
	}

	// show the view only if access is grated based on the permission and the feature flag.
	if (hasFeatureFlagAccess && hasPermissionAccess) {
		return <Route {...props} />
	}

	return <Route render={() => <NotFound onlyView />} />
}

export default ProtectedRoute
