import React, { MouseEvent, useState } from "react"

import { useBookContext } from "../../../contexts/Mobile/BookContext"
import FilterModal from "../../../modals/mobile/FilterModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { FilterSpecialValues } from "../../../components/Filter/types"
import { DeskPicker } from "../../../components/Mobile/DeskPicker"
import FilterButton from "../../../components/Mobile/Filter/FilterButton"
import {
	Filter,
	OccupancyFilterValues,
} from "../../../components/Mobile/Filter/FilterSelector"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./Desks.sass"

const FILTER_MODAL_ID = "DeskFilterModal"

const Desks = () => {
	const { id, date, timeslot, building, floor, desk, onDeskPick } =
		useBookContext()

	const { openModal } = useModals()

	const [filter, setFilter] = useState<Filter>({
		occupancy: OccupancyFilterValues.ALL,
		department: FilterSpecialValues.ALL,
		amenities: [FilterSpecialValues.ALL],
	})

	const handelOnFilterChange = (filter: Filter) => {
		setFilter(filter)
	}

	const handleFilterModal = (e: MouseEvent) => {
		e.preventDefault()

		openModal(FilterModal, {
			onChange: handelOnFilterChange,
			modalId: FILTER_MODAL_ID,
			filter: filter,
		})
	}

	return (
		<SafeViewArea className="Desks">
			<TopNav
				backArrow={true}
				title={
					<FilterButton modalId={FILTER_MODAL_ID} onClick={handleFilterModal} />
				}
				rightIcon={<CrossSVG onClick={() => onDeskPick(null)} />}
			/>
			<div className="body">
				{building && floor && timeslot && (
					<DeskPicker
						date={date}
						timeslot={timeslot}
						buildingId={building.id}
						floorId={floor.id}
						deskId={desk?.id}
						excludeResId={id}
						popupPos="low"
						onPick={onDeskPick}
						filter={filter}
					/>
				)}
			</div>
		</SafeViewArea>
	)
}

export default Desks
