export type ReducerState = {
	loading: boolean
	error?: string | null
}

export enum ActionTypes {
	START = "START",
	STOP = "STOP",
	ERROR = "ERROR",
}

type Action = {
	type: ActionTypes
	payload?: string | null
}

export const initialState: ReducerState = {
	loading: false,
	error: null,
}

const reducer = (state: ReducerState, action: Action) => {
	switch (action.type) {
		case ActionTypes.START:
			return {
				loading: true,
				error: null,
			}
		case ActionTypes.STOP:
			return {
				loading: false,
			}
		case ActionTypes.ERROR:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export default reducer
