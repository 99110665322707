import React, {
	CSSProperties,
	MouseEvent,
	PropsWithChildren,
	forwardRef,
	useState,
} from "react"

import classNames from "classnames"

import { ImageResizeOptions } from "../types/sharedTypes"
import Loader from "./basic/Loader"

import { getImageUrl } from "../redux/files/utils"

import { ReactComponent as InfoSVG } from "../assets/images/icons/Info.svg"

import "./PlanImage.sass"

export type Dimension = {
	width: number
	height: number
}

type PlanImageProps = {
	src: string
	imageResizeOptions?: ImageResizeOptions
	onClick?: (e: MouseEvent<HTMLImageElement>) => void
	containerSize: Dimension
	isWider: boolean
	isTaller: boolean
	isSame: boolean
	onIsLoaded?: () => void
}

const PlanImage = forwardRef<
	HTMLImageElement,
	PropsWithChildren<PlanImageProps>
>(
	(
		{
			children,
			src,
			onClick,
			containerSize,
			isWider,
			isTaller,
			isSame,
			onIsLoaded,
			imageResizeOptions,
		},
		ref,
	) => {
		const [imageState, setSetImageState] = useState({
			isLoading: false,
			isLoaded: false,
			hasError: false,
		})
		const { isLoading, isLoaded, hasError } = imageState
		const imageStyle: CSSProperties = {
			width: undefined,
			height: undefined,
		}

		if (isTaller || isSame) {
			imageStyle.height = `${containerSize.height}px`
			imageStyle.width = "auto"
		}

		if (isWider) {
			imageStyle.width = `${containerSize.width}px`
			imageStyle.height = "auto"
		}

		const planImageClassName = classNames({
			PlanImage: true,
			hidden: isLoading || hasError,
		})

		return (
			<>
				{isLoading && (
					<div className="PlanImage loading">
						<Loader />
					</div>
				)}
				{hasError && !isLoaded && (
					<div className="PlanImage">
						<div className="error">
							<InfoSVG />
							Error while loading floor plan
						</div>
					</div>
				)}
				<div className={planImageClassName}>
					<img
						src={getImageUrl(src, imageResizeOptions)}
						ref={ref}
						alt={"Floor plan"}
						onClick={(e) => onClick?.(e)}
						style={imageStyle}
						onLoadStart={() => {
							setSetImageState((state) => ({
								...state,
								isLoading: false,
								hasError: false,
							}))
						}}
						onLoad={() => {
							setSetImageState((state) => ({
								isLoading: false,
								isLoaded: true,
								hasError: false,
							}))
							onIsLoaded?.()
						}}
						onError={() => {
							setSetImageState((state) => ({
								...state,
								isLoading: false,
								hasError: true,
							}))
						}}
					/>
					{children}
				</div>
			</>
		)
	},
)

export default PlanImage
