import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Filters.sass"

type Props = {
	className?: any
}

export const Filters = ({
	children,
	className,
	...rest
}: PropsWithChildren<Props>) => {
	const filtersClassName = classNames({
		Filters: true,
		[className]: !!className,
	})

	return (
		<div className={filtersClassName} {...rest}>
			{children}
		</div>
	)
}

export default Filters
