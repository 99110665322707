import React from "react"

import classNames from "classnames"
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom"

import NotFound from "../NotFound"
import NotPaid from "../NotPaid"
import { ManageAssets } from "./Assets"
import Desks from "./Desks"
import FloorPlanWrapper from "./FloorPlan/FloorPlanWrapper"
import { ManageNav } from "./ManageNav"
import People from "./People"
import InviteAdd from "./Visitors/InviteAdd"
import InviteEdit from "./Visitors/InviteEdit"
import Invites from "./Visitors/Invites"
import VisitorEdit from "./Visitors/VisitorEdit"
import VisitorsNew from "./Visitors/Visitors"

import { useFetchServicesQuery } from "../../redux/api/services"
import { FEATURE } from "../../redux/payment/paymentSlice"
import { useAppSelector } from "../../redux/reducers"

import ProtectedRoute from "../../components/ProtectedRoute"
import Route from "../../components/Route"
import RouteView from "../../components/RouteView"
import Screen from "../../components/Screen"
import Loader from "../../components/basic/Loader"

import "./Manage.sass"

function Manage() {
	const { showWeekends } = useAppSelector((state) => state.app)
	const isFloorPlan = useRouteMatch("/manage/floor-plan")
	const { location } = useHistory()
	const { data: services, isSuccess } = useFetchServicesQuery()
	const isJoanDeskActive = services?.desk?.active ?? false
	const isVisitorActive = services?.visitor?.active ?? false
	const isVisitor = location.pathname.startsWith("/manage/visitor")

	const manageClassName = classNames({
		Manage: true,
		isConstrained: (!showWeekends && !isFloorPlan) || isVisitor,
	})

	return (
		<RouteView className={manageClassName}>
			{isSuccess ? (
				<Screen sidebar={<ManageNav />}>
					<Switch>
						<Route
							exact
							path="/manage/desks"
							component={Desks}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/desks/add"
							component={() => <Desks add={true} />}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/desks/:id"
							component={Desks}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/floor-plan"
							component={FloorPlanWrapper}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/floor-plan/add"
							component={() => <FloorPlanWrapper add />}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/floor-plan/:id"
							component={FloorPlanWrapper}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/people/add"
							component={() => <People add={true} />}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/people/:id"
							component={People}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/people"
							component={People}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path={"/manage/visitors/visits"}
							component={VisitorsNew}
							feature={FEATURE.visitor}
						/>
						<ProtectedRoute
							exact
							path="/manage/visitors/visits/edit/:id?"
							component={VisitorEdit}
							feature={FEATURE.visitor}
						/>
						<ProtectedRoute
							exact
							path="/manage/visitors/visits/add"
							component={VisitorEdit}
							feature={FEATURE.visitor}
						/>
						<ProtectedRoute
							exact
							path="/manage/visitors/invites"
							component={Invites}
							feature={FEATURE.visitor}
						/>
						<ProtectedRoute
							exact
							path="/manage/visitors/invites/add"
							component={InviteAdd}
							feature={FEATURE.visitor}
						/>
						<ProtectedRoute
							exact
							path="/manage/visitors/invites/edit/:id"
							component={InviteEdit}
							feature={FEATURE.visitor}
						/>
						<Route
							exact
							path="/manage/assets"
							component={ManageAssets}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/assets/add"
							component={() => <ManageAssets add={true} />}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage/assets/:assetType/:id"
							component={ManageAssets}
							feature={FEATURE.desk}
						/>
						<Route
							exact
							path="/manage"
							render={() => {
								if (!isJoanDeskActive && !isVisitorActive) {
									return <NotPaid />
								}
								return (
									<Redirect
										to={
											isJoanDeskActive
												? "/manage/people"
												: "/manage/visitors/visits"
										}
									/>
								)
							}}
						/>
						<Route render={() => <NotFound onlyView />} />
					</Switch>
				</Screen>
			) : (
				<Loader />
			)}
		</RouteView>
	)
}

export default Manage
