const BILLING_ROOT_PATH = "/settings/billing"
const BILLING_OVERVIEW_ROOT_PATH = `${BILLING_ROOT_PATH}/overview`

export const BILLING_PATHNAMES = {
	root: BILLING_ROOT_PATH,
	overview: {
		root: BILLING_OVERVIEW_ROOT_PATH,
		companyDetails: `${BILLING_OVERVIEW_ROOT_PATH}/company-details`,
		paymentMethod: `${BILLING_OVERVIEW_ROOT_PATH}/payment-method`,
	},
	invoices: `${BILLING_ROOT_PATH}/invoices`,
	room: `${BILLING_ROOT_PATH}/room`,
	desk: `${BILLING_ROOT_PATH}/desk`,
	visitor: `${BILLING_ROOT_PATH}/visitor`,
	sign: `${BILLING_ROOT_PATH}/sign`,
}
