import dayjs from "dayjs"
import { UseFormReturn, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../../hooks/useToast"

import { useRedeemPrepaidCodeMutation } from "../../../../redux/api/billing/prepaidCode"
import { ACTIVATION_DATE_OPTIONS } from "../../../../redux/api/billing/prepaidCode/constants"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { setErrors } from "../../../../components/Form/formUtils"

export type PrepaidCodeFormValues = {
	code: string
	selectedDate: string // 'today' | 'month' | 'custom'
	customDate: Date | null
}

export type UsePrepaidCodeFormReturn = {
	methods: UseFormReturn<PrepaidCodeFormValues>
	submitPrePaidCode: (values: PrepaidCodeFormValues) => Promise<void>
}

const formMapping = {
	activated_at: `selectedDate`,
} as const

export const usePrepaidCodeForm = (): UsePrepaidCodeFormReturn => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()

	const [redeemPrepaidCode] = useRedeemPrepaidCodeMutation()

	const defaultFormValues: PrepaidCodeFormValues = {
		code: "",
		selectedDate: ACTIVATION_DATE_OPTIONS.today,
		customDate: new Date(),
	}

	const methods = useForm<PrepaidCodeFormValues>({
		defaultValues: defaultFormValues,
	})
	const { setError, reset } = methods

	const submitPrePaidCode = async ({
		code,
		selectedDate,
		customDate,
	}: PrepaidCodeFormValues) => {
		const activated_at = (() => {
			switch (selectedDate) {
				case ACTIVATION_DATE_OPTIONS.today:
					return null
				case ACTIVATION_DATE_OPTIONS.beginning_month:
					return dayjs().add(1, "month").startOf("month").toISOString()
				case ACTIVATION_DATE_OPTIONS.custom:
					return dayjs(customDate ?? undefined).toISOString()
				default:
					return null
			}
		})()
		const response = await redeemPrepaidCode({ code, activated_at })

		if (isRejected(response)) {
			const { error } = response

			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast, formMapping)
			}
			return
		}
		infoToast(t("desktop.settings.billing.pre_paid_code_card.success_toast"))
		reset()
	}

	return {
		methods,
		submitPrePaidCode,
	}
}
