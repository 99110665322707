import React, { MouseEvent, useCallback } from "react"

import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import ExportModal from "../../modals/ExportModal"
import { useModals } from "@mattjennings/react-modal-stack"

import Button from "../../components/basic/Button"

type Props = {
	start: Dayjs
	end: Dayjs
}

function CSVReportDownload({ start, end }: Props) {
	const { openModal } = useModals()
	const { t } = useTranslation()

	const handleDownloadClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			openModal(ExportModal, { range: { from: start, to: end } })
		},
		[start, end, openModal],
	)

	return (
		<Button onClick={handleDownloadClick} variant="secondary-white" isSmall>
			{t("desktop.manage.csv_button")}
		</Button>
	)
}

export default CSVReportDownload
