import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { InviteCSVResponse } from "../../redux/invites/types"

import InviteCSVEditForm from "../../components/Form/Visitors/InviteCSVEditForm"

type Props = {
	open: boolean
	invite: InviteCSVResponse
}

const InviteCSVEditModal = ({ open, invite }: Props) => {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<InviteCSVEditForm invite={invite} />
		</ReactModal>
	)
}

export default InviteCSVEditModal
