import React from "react"

import classNames from "classnames"

import { ReactComponent as LoaderSVG } from "../../../assets/images/icons/Loader.svg"

import "./style.sass"

export type LoaderProps = {
	variant?: "inline" | "fullScreen"
	size?: "small" | "medium" | "large"
	className?: string
}

const Loader = ({
	variant = "inline",
	size = "medium",
	className,
}: LoaderProps) => {
	const cn = classNames("Loader", className, variant, size)

	return (
		<div className={cn}>
			<LoaderSVG />
		</div>
	)
}

export default Loader
