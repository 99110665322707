import React, { useEffect, useState } from "react"

import { Route, Switch as RouteSwitch, useHistory } from "react-router-dom"

import { Children } from "../../types/sharedTypes"
import { DESKS_PATHS } from "../Settings/Desks/constants"
import BuildingSelect from "./BuildingSelect"
import OrganizationDetails from "./OrganizationDetails"
import UserDetails from "./UserDetails"
import { ONBOARDING_PATHS } from "./constants"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useFetchCompanyQuery } from "../../redux/api/company"
import { OnboardingState, setOnboarding } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectUser } from "../../redux/user/selectors"
import { isPortalAdmin } from "../../redux/user/utils"
import { useActions } from "../../redux/utils"

const Onboarding = () => {
	return (
		<RouteSwitch>
			<Route exact path={ONBOARDING_PATHS.user} component={UserDetails} />
			<Route
				exact
				path={ONBOARDING_PATHS.organization}
				component={OrganizationDetails}
			/>
			<Route
				exact
				path={ONBOARDING_PATHS.building}
				component={BuildingSelect}
			/>
		</RouteSwitch>
	)
}

export const OnboardingProvider = React.memo(
	({ children }: { children: Children }) => {
		const [isDecided, setIsDecided] = useState(false)

		const history = useHistory()
		const {
			location: { pathname, search },
		} = history
		const { entry: user } = useAppSelector(selectUser)

		const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()
		const { data: company, isSuccess } = useFetchCompanyQuery()

		const isAdmin = !!user && isPortalAdmin(user)

		const actions = useActions({
			setOnboarding: (onboardingState: OnboardingState) =>
				setOnboarding(onboardingState),
		})

		useEffect(() => {
			const needUserOnboarding =
				!user?.last_name || !user?.last_name || !user.profile
			const needOrganizationOnboarding =
				isAdmin && isSuccess && (!company?.name || !company?.tools)
			const needDefaultBuilding = !user?.building && buildings.length > 1

			if (
				!needUserOnboarding &&
				!needOrganizationOnboarding &&
				!needDefaultBuilding
			) {
				setIsDecided(true)
				return
			}

			const onboardingSteps: OnboardingState["steps"] = [
				...(needUserOnboarding ? (["user"] as const) : []),
				...(needOrganizationOnboarding ? (["organization"] as const) : []),
				...(needDefaultBuilding ? (["building"] as const) : []),
			]

			actions.setOnboarding({
				steps: onboardingSteps,
				currentStep: 0,
				url:
					pathname.startsWith(ONBOARDING_PATHS.root) || pathname === "/"
						? isAdmin
							? DESKS_PATHS.general
							: "/manage"
						: `${pathname}${search}`,
			})
			setIsDecided(true)
		}, [
			user,
			isDecided,
			actions,
			isAdmin,
			isSuccess,
			company?.name,
			company?.tools,
			pathname,
			search,
			buildings.length,
		])

		if (!isDecided) {
			return null
		}

		return <>{children}</>
	},
)

export default Onboarding
