import React from "react"

import { Redirect, Switch } from "react-router-dom"

import Amenities from "./Amenities"
import EditAmenities from "./Amenities/EditAmenities"
import General from "./General"
import DeskLayout from "./Layout"
import TimeSlots from "./TimeSlots"
import EditTimeSlots from "./TimeSlots/EditTimeSlots"
import { DESKS_PATHS } from "./constants"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Desks = () => {
	return (
		<Switch>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.general}
				accessPermission="desk.change_deskcompanysettings"
				component={General}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.layout}
				accessPermission="desk.change_deskresource"
				component={DeskLayout}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.timeSlots.default}
				accessPermission="desk.change_timeslot"
				component={TimeSlots}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.timeSlots.add}
				accessPermission="desk.change_timeslot"
				component={EditTimeSlots}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.timeSlots.edit}
				accessPermission="desk.change_timeslot"
				component={EditTimeSlots}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.amenities.default}
				accessPermission="portal_resources.change_amenity"
				component={Amenities}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.amenities.add}
				accessPermission="portal_resources.change_amenity"
				component={EditAmenities}
			/>
			<ProtectedRoute
				exact
				path={DESKS_PATHS.amenities.edit}
				accessPermission="portal_resources.change_amenity"
				component={EditAmenities}
			/>
			<Redirect to={DESKS_PATHS.general} />
		</Switch>
	)
}

export default Desks
