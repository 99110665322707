import React, { CSSProperties, useMemo } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { ReactComponent as YouAreHereSVG } from "../assets/images/icons/YouAreHere.svg"

import "./YouAreHere.sass"

type YouAreHereProps = {
	x: number
	y: number
	mapWidth: number
	mapHeight: number
	isDarkModeEnabled?: boolean
}

export default function YouAreHere({
	x,
	y,
	mapWidth,
	mapHeight,
	isDarkModeEnabled = false,
}: YouAreHereProps) {
	const { t } = useTranslation()
	const placeStyle: CSSProperties = useMemo(
		() => ({
			top: `${(y / mapHeight) * 100}%`,
			left: `${(x / mapWidth) * 100}%`,
		}),
		[x, y, mapHeight, mapWidth],
	)

	const classes = classNames({
		"you-are-here": true,
		dark: isDarkModeEnabled,
	})

	return (
		<div style={placeStyle} className={classes}>
			<YouAreHereSVG />
			<div className="text-wrapper">
				<div className="you">
					{t("desktop.components.you_are_here.you_are")}
				</div>
				<div className="here">{t("desktop.components.you_are_here.here")}</div>
			</div>
		</div>
	)
}
