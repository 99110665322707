import React, {
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
	forwardRef,
} from "react"

import classNames from "classnames"

import { ReactComponent as LockSVG } from "../../assets/images/icons/Lock.svg"

import "./TextInput.sass"

type Props = {
	value?: string | number
	type?: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void
	onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
	className?: any
	isDisabled?: boolean
	maxLength?: number
	min?: number
	max?: number
	pattern?: string
	required?: boolean
	id?: string
}

/**
 * @deprecated
 */
export const TextInput = forwardRef<HTMLInputElement, Props>(
	(
		{
			value,
			type = "text",
			onChange,
			className,
			isDisabled = false,
			maxLength,
			pattern,
			onKeyUp,
			onBlur,
			required,
			min,
			max,
			id,
		},
		ref,
	) => (
		<div
			className={classNames({
				TextInput: true,
				[className]: !!className,
				isDisabled,
			})}
		>
			<input
				ref={ref}
				type={type}
				value={value}
				onChange={onChange}
				disabled={isDisabled}
				maxLength={maxLength}
				pattern={pattern}
				onKeyUp={onKeyUp}
				onBlur={onBlur}
				required={required}
				min={min}
				max={max}
				id={id}
			/>

			{isDisabled && <LockSVG className="lock" />}
		</div>
	),
)
