import { ThunkApiConfig } from "RootType"

import {
	get,
	postJSON,
	putJSON,
	tabletSettingURL,
	tabletSettingsURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	TabletSettingRequest,
	TabletSettingResponse,
	TabletSettingsResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchTabletSettings = createAsyncThunk<
	TabletSettingsResponse,
	void,
	ThunkApiConfig
>("tabletSettings/fetch", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(tabletSettingsURL(), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const fetchTabletSetting = createAsyncThunk<
	TabletSettingResponse,
	string,
	ThunkApiConfig
>("tabletSetting/fetch", async (id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(tabletSettingURL(id), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const createTabletSettings = createAsyncThunk<
	TabletSettingResponse,
	TabletSettingRequest,
	ThunkApiConfig<ResponseError>
>("tabletSettings/create", async (body, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		tabletSettingsURL(),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

export const updateTabletSettings = createAsyncThunk<
	TabletSettingResponse,
	TabletSettingRequest,
	ThunkApiConfig<ResponseError>
>("tabletSettings/update", async (body, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		tabletSettingURL(body.building_id),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	return rejectWithValue(await getErrorObject(response))
})

export interface TabletSettingsState extends SliceState {
	entries: TabletSettingResponse[]
}

const initialState: TabletSettingsState = {
	entries: [],
	...sliceInitialState,
}

const tabletSettingsSlice = createSlice({
	name: "tabletSettings",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTabletSettings.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.entries = payload.results
		})
		builder.addCase(fetchTabletSetting.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			const remain = state.entries.filter((e) => e.id !== payload.id)
			state.entries = [...remain, payload]
		})
		builder.addCase(createTabletSettings.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.entries = [...state.entries, payload]
		})
		builder.addCase(updateTabletSettings.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			const remain = state.entries.filter((e) => e.id !== payload.id)
			state.entries = [...remain, payload]
		})
		builder.addMatcher(
			isAnyOf(fetchTabletSettings.pending, fetchTabletSetting.pending),
			(state) => {
				state.isLoading = true
			},
		)
		builder.addMatcher(
			isAnyOf(createTabletSettings.pending, updateTabletSettings.pending),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				fetchTabletSettings.rejected,
				fetchTabletSetting.rejected,
				createTabletSettings.rejected,
				updateTabletSettings.rejected,
			),
			(state, action) => {
				setFetchErrorState(state, action)
			},
		)
	},
})

export const tabletSettingsReducer = tabletSettingsSlice.reducer
