import { api } from ".."
import { assetTypesGetURL, assetTypesURL } from "../../../api"
import { nameComparator } from "../../../utils"
import { PaginatedOptions } from "../../reduxUtils"
import {
	AssetTypeResponse,
	AssetTypeWithIdRequest,
	AssetTypesResponse,
	CreateAssetTypeRequest,
	FetchAssetTypesRequest,
} from "./types"

export const assetTypes = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchAssetTypes: builder.query<
			AssetTypesResponse,
			(PaginatedOptions & FetchAssetTypesRequest) | void
		>({
			query: (options) => ({
				url: assetTypesGetURL({ ...options }),
			}),
			providesTags: (result, _error, arg) =>
				result
					? [
							...result.results.map(({ id }) => ({
								type: "AssetTypes" as const,
								id,
							})),
							{ type: "AssetTypes", id: "LIST" },
					  ]
					: [{ type: "AssetTypes", id: "LIST" }],
			transformResponse: (response: AssetTypesResponse, _, arg) => {
				return {
					...response,
					results: response.results.sort(nameComparator),
					offset: arg?.offset ?? 0,
				}
			},
		}),
		fetchAssetType: builder.query<AssetTypeResponse, string>({
			query: (id) => ({
				url: assetTypesURL(id),
			}),
			providesTags: (_result, _error, id) => [{ type: "AssetTypes", id }],
		}),
		createAssetType: builder.mutation<
			AssetTypeResponse,
			CreateAssetTypeRequest
		>({
			query: (body) => ({
				url: assetTypesURL(),
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "AssetTypes", id: "LIST" }],
		}),
		updateAssetType: builder.mutation<
			AssetTypeResponse,
			AssetTypeWithIdRequest
		>({
			query: ({ id, ...body }) => ({
				url: assetTypesURL(id),
				method: "PUT",
				body,
			}),
			invalidatesTags: (_result, _error, { id }) => [
				{ type: "AssetTypes", id: "LIST" },
				{ type: "AssetTypes", id },
			],
		}),
		destroyAssetType: builder.mutation<void, string>({
			query: (id) => ({
				url: assetTypesURL(id),
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: "AssetTypes", id: "LIST" },
				{ type: "AssetTypes", id },
			],
		}),
	}),
})

export const {
	useFetchAssetTypesQuery,
	useLazyFetchAssetTypesQuery,
	useFetchAssetTypeQuery,
	useCreateAssetTypeMutation,
	useUpdateAssetTypeMutation,
	useDestroyAssetTypeMutation,
} = assetTypes
