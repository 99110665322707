import React, { MouseEvent, PropsWithChildren, ReactNode } from "react"

import classNames from "classnames"
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Card from "../Card"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import "./PageForm.sass"

type PageActionsProps = {
	backUrl?: string
}

export const PageActions = ({
	children,
	backUrl,
}: PropsWithChildren<PageActionsProps>) => {
	const { t } = useTranslation()

	return (
		<div className="PageActions">
			<Button variant="link" to={backUrl} isVisible={backUrl !== undefined}>
				{t("general.back")}
			</Button>

			<div className="spacer"></div>

			{children}
		</div>
	)
}

type Props<T extends FieldValues> = {
	className?: string
	updateMode: boolean
	onCreate?: (values: T) => Promise<void>
	onUpdate?: (values: T) => Promise<void>
	onDelete?: (e: MouseEvent) => Promise<void>
	additionalButton?: JSX.Element
	hasConfirmationPrompt?: boolean
	backUrl?: string
	disabled?: boolean
	header?: ReactNode
	footer?: ReactNode
	hide?: boolean
	submitButtonText?: string
}

const PageForm = <T extends FieldValues>({
	children,
	className,
	updateMode,
	onCreate,
	onUpdate,
	onDelete,
	additionalButton,
	hasConfirmationPrompt = true,
	backUrl,
	disabled = false,
	header,
	footer,
	hide = false,
	submitButtonText,
}: PropsWithChildren<Props<T>>) => {
	const { t } = useTranslation()
	const { openModal, closeModal } = useModals()

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = useFormContext()

	const pageFormClass = classNames("PageForm", className)

	const onSubmit: SubmitHandler<T> = async (values: T) => {
		if (updateMode) {
			await onUpdate?.(values)
		} else {
			await onCreate?.(values)
		}
	}

	const handleDeleteConfirmation = (e: MouseEvent) => {
		openModal(ConfirmationModal, {
			title: t("general.confirm_action"),
			onConfirm: async () => {
				await onDelete?.(e)
				closeModal()
			},
		})
	}

	if (hide && header) {
		return (
			<form
				className={pageFormClass}
				onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
			>
				<Card>
					<div className="PageHeader">{header}</div>
				</Card>
			</form>
		)
	}

	if (hide) {
		return null
	}

	return (
		<form
			className={pageFormClass}
			onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
		>
			<Card>
				{header && <div className="PageHeader">{header}</div>}
				<div className="fields">{children}</div>
				{footer && <div className="PageFooter">{footer}</div>}
			</Card>

			<PageActions backUrl={backUrl}>
				{additionalButton}

				<Button
					className="delete"
					variant="danger-pop"
					onClick={handleDeleteConfirmation}
					isDisabled={disabled}
					isLoading={isSubmitting}
					isVisible={updateMode && onDelete !== undefined}
					noConfirm
				>
					{t("general.delete_button")}
				</Button>

				<Button variant="submit" isDisabled={disabled} isLoading={isSubmitting}>
					{submitButtonText ? submitButtonText : t("general.save_button")}
				</Button>
			</PageActions>
		</form>
	)
}

export default PageForm
