import React from "react"

import queryString from "query-string"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useScrollToLocation } from "../../../../hooks/useScrollToLocation"
import { useToast } from "../../../../hooks/useToast"
import { getLabel } from "../../../../utils"
import BillingSection from "../BillingSection"
import { planTypeToTrialType } from "./utils"
import { isRejected } from "@reduxjs/toolkit"

import { LIST_PLAN_GROUPS } from "../../../../redux/api/billing/constants"
import {
	useCreateTrialSubscriptionMutation,
	useFetchSubscriptionsQuery,
} from "../../../../redux/api/billing/subscriptions"
import { Subscription } from "../../../../redux/api/billing/subscriptions/types"
import { PlanType } from "../../../../redux/api/billing/types"

import SubscriptionCard from "../../../../components/advanced/SubscriptionCard"

import "./styles.sass"

const { stringify } = queryString

const Plans = () => {
	const { t } = useTranslation()
	const { errorToast, infoToast } = useToast()
	const history = useHistory()

	const { data: { subsByPlanGroup } = {}, isSuccess } =
		useFetchSubscriptionsQuery({})
	const [createTrialSubscription] = useCreateTrialSubscriptionMutation()
	useScrollToLocation([isSuccess])
	const handleModifySubscription = async ({
		id,
		planType,
	}: {
		id?: number
		planType: PlanType
	}) => {
		if (!id) {
			const response = await createTrialSubscription(
				planTypeToTrialType(planType),
			)

			if (isRejected(response)) {
				errorToast(response.error.message)
				return
			}
			infoToast(t("desktop.settings.billing.plans.toasts.trial_created"))
			return
		}
		history.push(
			`/settings/billing/${planTypeToTrialType(planType)}?${stringify({
				step: 1,
				id,
				planType,
			})}`,
		) // TODO: add correct path and params needed
	}

	const handleLicenseOnClick = ({
		plan_type,
		subscription_id,
		plan_variation,
	}: Subscription) => {
		history.push(
			`/settings/billing/${planTypeToTrialType(plan_type)}?${stringify({
				step: 3,
				id: subscription_id,
				plan_type,
				period: plan_variation.period_unit,
				currency: plan_variation.currency,
			})}`,
		) // TODO: add correct path and params needed
	}

	return (
		<BillingSection
			className="Plans"
			title={t("desktop.settings.billing.plans.title")}
			intro={
				<Trans i18nKey="desktop.settings.billing.plans.intro">
					<a
						href={`mailto:${getLabel("links.salesEmail")}`}
						target="_blank"
						rel="noreferrer"
					>
						link
					</a>
				</Trans>
			}
		>
			<div className="plans-list">
				{subsByPlanGroup &&
					LIST_PLAN_GROUPS.map((group) => {
						const value = subsByPlanGroup[group]

						if (!value || value.length === 0) {
							return null
						}

						return (
							<SubscriptionCard
								id={group}
								key={group}
								subscriptions={value}
								onButtonClick={handleModifySubscription}
								onLicenseClick={handleLicenseOnClick}
							/>
						)
					})}
			</div>
		</BillingSection>
	)
}

export default Plans
