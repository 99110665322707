import React from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { timeZone as defaultTimezone } from "../../dayjs"
import { userTimeFormat } from "../../utils"
import { CorrectIconMapper } from "../CorrectIconMapper"
import { EventActions } from "./EventActions"

import { formatFloorLabel } from "../../redux/floors/utils"

import "./EventRow.sass"

type EventRowProps = {
	event: any
}

export const EventRow: React.FC<EventRowProps> = ({ event }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const today = dayjs()

	const start = dayjs(event.start)
	const end = dayjs(event.end)

	const isActive = today.isAfter(start) && today.isBefore(end)

	const eventRowClassName = classNames({
		EventRow: true,
		isActive,
	})

	const building = event.room.locations.find((l: any) => l.type === 0)
	const floor = event.room.locations.find((l: any) => l.type === 1)

	const attendees = []
	if (event.organizer && event.organizer.email !== event.room.email) {
		attendees.push(event.organizer.displayName)
	}
	attendees.push(
		...event.attendees
			.filter(
				(a: any) =>
					!event.organizer ||
					(a.mail !== event.organizer && event.organizer.email),
			)
			.map((a: any) => a.name),
	)

	const location = [event.room.name]
	if (building) {
		location.push(building.name)
	}
	if (floor) {
		location.push(formatFloorLabel(t("mobile.book.floor"), floor.name))
	}

	const timezoneDiffers = event.tz !== defaultTimezone

	return (
		<div
			className={eventRowClassName}
			onClick={() => {
				history.push("/home/event/" + event.id)
			}}
		>
			<div className="resource-info">
				<div className="event-title">{event.summary}</div>
				{attendees.length > 0 && (
					<div className="attendees">
						{attendees[0]}
						{attendees.length > 1 && (
							<>
								{" & "}
								{attendees.length - 1} {t("mobile.home.others")}
							</>
						)}
					</div>
				)}
				<div className="aux-info">
					<span className="from">{start.format(userTimeFormat())}</span>
					<span className="dash">{" - "}</span>
					<span className="to">{end.format(userTimeFormat())}</span>
					{timezoneDiffers && (
						<>
							<span> &middot; </span>
							<span className="diff-tz">{event.tz}</span>
						</>
					)}
				</div>
				<span className="building-floor">{location.join(", ")}</span>
				{isActive && <EventActions event={event} small={true} />}
			</div>
			<CorrectIconMapper
				color={isActive ? "white" : "default"}
				iconType="room"
			/>
		</div>
	)
}
