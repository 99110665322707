import dayjs from "dayjs"

import "dayjs/locale/en"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/nl"
import "dayjs/locale/pl"
import "dayjs/locale/sl"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isToday from "dayjs/plugin/isToday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(isToday)

export const timeZone = dayjs.tz.guess()
