import { MouseEvent, PropsWithChildren, ReactNode } from "react"

import classNames from "classnames"
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form"

import Button from "../basic/Button"
import { useModals } from "@mattjennings/react-modal-stack"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"

import "./ModalDeleteForm.sass"

type Props<T extends FieldValues> = {
	className?: string
	title: ReactNode
	onCancelClick?: (e: MouseEvent) => Promise<void>
	onDelete: (values: T) => Promise<void>
	hint: ReactNode
	helpText: ReactNode
}

const ModalDeleteForm = <T extends FieldValues>({
	children,
	className,
	title,
	onCancelClick,
	onDelete,
	hint,
	helpText,
}: PropsWithChildren<Props<T>>) => {
	const { closeModal } = useModals()
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = useFormContext()

	const modalClass = classNames("ModalForm", "ModalDeleteForm", className)

	const onSubmit: SubmitHandler<T> = (values: T) => {
		onDelete(values)
	}

	const handleCancel = (e: MouseEvent) => {
		onCancelClick ? onCancelClick(e) : closeModal()
	}

	return (
		<form
			className={modalClass}
			onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
		>
			<div className="title">
				<h1>{title}</h1>
			</div>
			<div className="close" onClick={() => closeModal()}>
				<CrossSVG />
			</div>
			{hint && <div className="hint">{hint}</div>}

			<div className="fields">{children}</div>
			{helpText && <div className="help-text">{helpText}</div>}
			<div className="actions">
				<Button
					className="delete-button"
					variant="danger-pop"
					isDisabled={isSubmitting}
					type={"submit"}
					noConfirm
					isSubmit
				>
					Delete
				</Button>

				<Button
					className="cancel-button"
					onClick={handleCancel}
					isDisabled={isSubmitting}
					noConfirm
				>
					Cancel
				</Button>
			</div>
		</form>
	)
}

export default ModalDeleteForm
