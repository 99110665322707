import { useReducer } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { patchJSON, postJSON, putJSON } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import Switch, { SwitchProps } from "../../basic/Switch"
import reducer, { ActionTypes, initialState } from "./reducer"

import { useAppSelector } from "../../../redux/reducers"
import { getErrorMessage } from "../../../redux/reduxUtils"

type Props = {
	className?: string
	urlGenerator: () => string
	bodyGenerator: (value: boolean) => {
		[x: string]: any
	}
	analyticsGenerator?: (response: any) => void
	value: boolean | undefined
	refresh?: (value: boolean) => void
	updateMode?: boolean
	type?: "patch" | "put"
	confirmAction?: (
		action: (value: boolean) => Promise<void>,
		value: boolean,
	) => void
} & SwitchProps

/**
 * A component that will trigger a put or a patch
 * to a certain endpoint with a provided body as
 * a parameter. Once done, it will trigger an onSuccess
 * function. It will display the given value as well.
 */
export const AsyncSwitch = ({
	className,
	urlGenerator,
	bodyGenerator,
	value,
	refresh,
	updateMode = true,
	disabled,
	analyticsGenerator,
	type = "patch",
	confirmAction,
	...props
}: Props) => {
	const { t } = useTranslation()
	const { errorToast } = useToast()
	const { access_token } = useAppSelector((state) => state.auth)

	const [{ loading, error }, dispatch] = useReducer(reducer, initialState)

	const toggle = async (value: boolean) => {
		if (!access_token) return

		dispatch({ type: ActionTypes.START })

		let response
		try {
			const url = urlGenerator()
			const body = {
				body: bodyGenerator(value),
			}

			if (updateMode) {
				if (type === "patch") {
					response = await patchJSON(url, body, access_token)
				} else {
					response = await putJSON(url, body, access_token)
				}
			} else {
				response = await postJSON(url, body, access_token)
			}

			if (response.ok) {
				dispatch({ type: ActionTypes.STOP })

				refresh?.(value)

				analyticsGenerator?.(response)

				return
			}

			const error = await getErrorMessage(response)

			dispatch({ type: ActionTypes.ERROR, payload: error })
			errorToast(error)
		} catch (e) {
			if (!response) {
				dispatch({
					type: ActionTypes.ERROR,
					payload: t("desktop.components.async_select.something_wrong"),
				})
				errorToast(t("desktop.components.async_select.something_wrong"))
				return
			}

			const error = await getErrorMessage(response)

			dispatch({ type: ActionTypes.ERROR, payload: error })
			errorToast(error)
		}
	}

	const actualValue = value ?? false

	return (
		<div className={classNames("AsyncSwitch", className)}>
			<Switch
				{...props}
				value={actualValue}
				onChange={() => {
					if (confirmAction) {
						return confirmAction(toggle, !actualValue)
					}
					toggle(!actualValue)
				}}
				hasError={!!error}
				disabled={disabled || loading}
			/>
		</div>
	)
}
