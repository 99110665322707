import dayjs from "dayjs"
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { DEFAULT_NS, WHITELABEL_NS } from "./constants"
import "dayjs/locale/de"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/nl"
import "dayjs/locale/pl"

import deWhitelabel from "./assets/i18n/de/de-whitelabel.json"
import de from "./assets/i18n/de/de.json"
import enWhitelabel from "./assets/i18n/en/en-whitelabel.json"
import en from "./assets/i18n/en/en.json"
import esWhitelabel from "./assets/i18n/es/es-whitelabel.json"
import es from "./assets/i18n/es/es.json"
import foWhitelabel from "./assets/i18n/fo/fo-whitelabel.json"
import fo from "./assets/i18n/fo/fo.json"
import frWhitelabel from "./assets/i18n/fr/fr-whitelabel.json"
import fr from "./assets/i18n/fr/fr.json"
import nlWhitelabel from "./assets/i18n/nl/nl-whitelabel.json"
import nl from "./assets/i18n/nl/nl.json"
import plWhitelabel from "./assets/i18n/pl/pl-whitelabel.json"
import pl from "./assets/i18n/pl/pl.json"

// Translations
export const resources = {
	en: {
		[DEFAULT_NS]: en,
		[WHITELABEL_NS]: enWhitelabel,
	},
	es: {
		[DEFAULT_NS]: es,
		[WHITELABEL_NS]: esWhitelabel,
	},

	de: {
		[DEFAULT_NS]: de,
		[WHITELABEL_NS]: deWhitelabel,
	},
	nl: {
		[DEFAULT_NS]: nl,
		[WHITELABEL_NS]: nlWhitelabel,
	},
	fo: {
		[DEFAULT_NS]: fo,
		[WHITELABEL_NS]: foWhitelabel,
	},
	fr: {
		[DEFAULT_NS]: fr,
		[WHITELABEL_NS]: frWhitelabel,
	},
	pl: {
		[DEFAULT_NS]: pl,
		[WHITELABEL_NS]: plWhitelabel,
	},
} as const

export const DEFAULT_LANGUAGE = "en"

export const AVAILABLE_LANGUAGES = Object.keys(resources)

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: DEFAULT_LANGUAGE,
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ["navigator"],
		},
	})

i18n.services.formatter?.add(
	"enHandleAn",
	(value: any, lng: string | undefined) => {
		if (!lng?.startsWith("en")) {
			return value
		}
		if (typeof value === "string") {
			const letters = ["a", "e", "i", "o", "u", "h"]
			if (letters.includes(value.toLocaleLowerCase()[0])) {
				return `an ${value}`
			}
			return `a ${value}`
		}
		return value
	},
)

let langShort = i18n.language
if (langShort.includes("-")) {
	langShort = langShort.split("-")[0]
}

dayjs.locale(langShort)

export default i18n
