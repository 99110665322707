import React, { MouseEvent } from "react"

import { useTranslation } from "react-i18next"

import { SupportedEvents, analyticsEvent } from "../analytics"
import { get } from "../api"
import { useToast } from "../hooks/useToast"

import { useUpdateFloorMutation } from "../redux/api/floors"
import { isApiResponseError, isRejected } from "../redux/api/types"
import { uploadDeskFile } from "../redux/files/filesSlice"
import { FloorResponse } from "../redux/floors/types"
import { useActions } from "../redux/utils"

import exampleFloorPlan from "../assets/images/example_floor_plan.jpg"

const URLHelper = window.URL || window.webkitURL

type Props = {
	buildingId: string
	floor: FloorResponse
	onClick?: () => void
}

export default function ExampleFloorPlanLink({
	buildingId,
	floor,
	onClick,
}: Props) {
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()

	const actions = useActions({
		uploadDeskFile: (file: File) => uploadDeskFile(file),
	})

	const [updateFloor] = useUpdateFloorMutation()

	async function handleDemoUpload(e: MouseEvent<HTMLAnchorElement>) {
		e.preventDefault()

		onClick && onClick()

		const imgResp = await get(exampleFloorPlan)
		if (imgResp.status !== 200) return

		const imgBlob = await imgResp.blob()
		const file = new File([imgBlob], "example-floor-plan.jpg", {
			type: "image/jpg",
		})

		const upload = await actions.uploadDeskFile(file)
		if (uploadDeskFile.rejected.match(upload)) return

		const img = new Image()
		img.onload = async function () {
			const response = await updateFloor({
				id: floor.id,
				building_id: buildingId,
				name: floor.name,
				capacity_limit: floor.capacity_limit,
				image: upload.payload.url,
				height: img.height,
				width: img.width,
				active: floor.active,
			})

			if (isRejected(response)) {
				const { error } = response

				if (isApiResponseError(error)) {
					errorToast(error.message)
				}
			} else {
				analyticsEvent(SupportedEvents.FLOORPLAN_ADD, {
					id: floor.id,
					name: floor.name,
				})

				infoToast(t("desktop.settings.floor_plans.upload_image.success"))
			}
		}
		img.src = URLHelper.createObjectURL(file)
	}

	return (
		<a onClick={handleDemoUpload} href="#example-floorplan">
			{t("desktop.settings.floor_plans.actions.example_floor_plan")}
		</a>
	)
}
