import React from "react"

import { Redirect, Switch } from "react-router-dom"

import DeviceDetail from "./DeviceDetail"
import DeviceSettings from "./DeviceSettings"
import Devices from "./Devices"
import DocumentEdit from "./DocumentEdit"
import Documents from "./Documents"
import EmailSettings from "./EmailSettings"
import VisitorSettings from "./VisitorSettings"
import VisitorSettingsUser from "./VisitorSettingsUser"
import { VISITORS_PATHS } from "./constants"

import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isNormalUser } from "../../../redux/user/utils"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Visitors = () => {
	const { entry: currentUser } = useAppSelector(selectUser)

	const isUser = isNormalUser(currentUser)
	return (
		<Switch>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.documents}
				accessPermission="core_visitors.change_visitorsdocuments"
				component={Documents}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.documentsEdit}
				accessPermission="core_visitors.change_visitorsdocuments"
				component={DocumentEdit}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.documentsAdd}
				accessPermission="core_visitors.change_visitorsdocuments"
				component={DocumentEdit}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.devices}
				// TODO: Add proper access permissions
				component={Devices}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.deviceDetail}
				// TODO: Add proper access permissions
				component={DeviceDetail}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.deviceSettings}
				component={DeviceSettings}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.general}
				// TODO: Remove /settings/visitors in the future (currently used for redirects)
				render={() => (isUser ? <VisitorSettingsUser /> : <VisitorSettings />)}
			/>
			<ProtectedRoute
				exact
				path={VISITORS_PATHS.emailSettings}
				component={EmailSettings}
			/>

			<Redirect to={VISITORS_PATHS.general} />
		</Switch>
	)
}

export default Visitors
