import React from "react"

import classNames from "classnames"

import { Children } from "../../../types/sharedTypes"

import Intro from "../../../components/Intro"

import "./BillingSection.sass"

export type BillingSectionProps = {
	title: Children
	intro?: Children
	children: Children
	className?: string
}

const BillingSection = ({
	title,
	intro,
	children,
	className,
}: BillingSectionProps) => {
	const cn = classNames("BillingSection", className)

	return (
		<div className={cn}>
			<h2>{title}</h2>
			{intro && <Intro>{intro}</Intro>}
			<div className="section-content">{children}</div>
		</div>
	)
}

export default BillingSection
