import { DeviceAppInterface } from "./Devices/utils"

import boardInterfaceImage from "../../../assets/images/device_interfaces/joan_board_13/board_13_interface.png"
import boardInterfaceImageWl from "../../../assets/images/device_interfaces/joan_board_13/board_13_interface_whitelabel.png"
import classRoomInterfaceImage from "../../../assets/images/device_interfaces/joan_classroom_13/classroom_13_interface.png"
import classRoomInterfaceImageWl from "../../../assets/images/device_interfaces/joan_classroom_13/classroom_13_interface_whitelabel.png"
import deskInterfaceImage from "../../../assets/images/device_interfaces/joan_desk_6/hot_desking_6_interface.png"
import deskInterfaceImageWl from "../../../assets/images/device_interfaces/joan_desk_6/hot_desking_6_interface_whitelabel.png"
import homeInterfaceImage from "../../../assets/images/device_interfaces/joan_home_6/home_6_interface.png"
import homeInterfaceImageWl from "../../../assets/images/device_interfaces/joan_home_6/home_6_interface_whitelabel.png"
import inRoomInterfaceImage from "../../../assets/images/device_interfaces/joan_in_room_-1/in_room_interface.png"
import inRoomInterfaceImageWl from "../../../assets/images/device_interfaces/joan_in_room_-1/in_room_interface_whitelabel.png"
import meetingInterfaceImage from "../../../assets/images/device_interfaces/joan_manager_6/manager_meeting_6_interface.png"
import meetingInterfaceImageWl from "../../../assets/images/device_interfaces/joan_manager_6/manager_meeting_6_interface_whitelabel.png"
import parkingInterfaceImage from "../../../assets/images/device_interfaces/joan_parking_13/parking_13_interface.png"
import parkingInterfaceImageWl from "../../../assets/images/device_interfaces/joan_parking_13/parking_13_interface_whitelabel.png"
import premiumInterfaceImage from "../../../assets/images/device_interfaces/joan_premium_13/premium_13_interface.png"
import premiumInterfaceImageWl from "../../../assets/images/device_interfaces/joan_premium_13/premium_13_interface_whitelabel.png"
import signInterfaceImage from "../../../assets/images/device_interfaces/joan_sign/sign_13_interface.gif"
import signInterfaceImageWl from "../../../assets/images/device_interfaces/joan_sign/sign_13_interface_whitelabel.gif"
import statusBoardInterfaceImage from "../../../assets/images/device_interfaces/joan_status_board_-1/status_board_interface.png"
import statusBoardInterfaceImageWl from "../../../assets/images/device_interfaces/joan_status_board_-1/status_board_interface_whitelabel.png"
import teamBoardInterfaceImage from "../../../assets/images/device_interfaces/joan_team_board_13/team_board_13_interface.png"
import teamBoardInterfaceImageWl from "../../../assets/images/device_interfaces/joan_team_board_13/team_board_13_interface_whitelabel.png"
import device6inch from "../../../assets/images/devices/6inch.png"
import device6inchWl from "../../../assets/images/devices/6inch_whitelabel.png"
import device13inch from "../../../assets/images/devices/13inch.png"
import device13inchWl from "../../../assets/images/devices/13inch_whitelabel.png"
import displayDevice from "../../../assets/images/devices/display.png"
import displayDeviceWl from "../../../assets/images/devices/display.png"
import tabletDevice from "../../../assets/images/devices/tablet_device.png"
import tabletDeviceWl from "../../../assets/images/devices/tablet_whitelabel.png"

const ROOMS_ROOT_PATH = "/settings/rooms"
const ROOMS_OVERVIEW_PATHNAME = `${ROOMS_ROOT_PATH}/overview`
const ROOMS_PATHNAME = `${ROOMS_ROOT_PATH}/room-list`
const ADD_ROOM_PATHNAME = `${ROOMS_PATHNAME}/add`
const ROOMS_DEVICES_PATHNAME = `${ROOMS_ROOT_PATH}/devices`
const ROOMS_DEVICES_PAIR_PATHNAME = `${ROOMS_ROOT_PATH}/devices/pair`

export const ROOMS_PATHS = {
	root: ROOMS_ROOT_PATH,
	overview: ROOMS_OVERVIEW_PATHNAME,
	rooms: ROOMS_PATHNAME,
	addRoom: ADD_ROOM_PATHNAME,
	room: (key: string) => `${ROOMS_PATHNAME}/${key}`,
	devices: ROOMS_DEVICES_PATHNAME,
	pair: ROOMS_DEVICES_PAIR_PATHNAME,
} as const

export const DEVICE_FEATURES = {
	REAL_TIME_BOOKING: "Real time booking",
	HOT_DESKING: "Hot desking",
	SCHEDULE_BOARD: "Schedule board",
	SCHEDULE_BOARD_2: "Schedule board 2",
	ROOM_GRID: "Room grid",
	TEAM_GRID: "Team grid",
	JOAN_IN_ROOM: "Joan in room",
	JOAN_HOME: "Joan home",
	JOAN_SIGN: "Joan sign",
	JOAN_ON_TABLETS: "Joan on tablets",
	MEETING_LIST: "Joan in room",
	PARKING: "Parking",
}

export const DEVICE_GTINS = {
	JOAN_6_PRO_SLATE_GRAY: "3830065464311",
	JOAN_6_PRO_GRAPHITE_BLACK: "3830065464328",
	JOAN_6_RE_SLATE_GRAY: "3830065465745",
	JOAN_6_RE_GRAPHITE_BLACK: "3830065465752",
	HOME_SLATE_GRAY: "3830065463499",
	HOME_GRAPHITE_BLACK: "3830065463505",
	HOME_SLATE_GRAY_REDPINE: "3830065463567",
	HOME_GRAPHITE_BLACK_REDPINE: "3830065463550",
	JOAN_9_GRAPHITE_BLACK: "3830065460108",
	JOAN_9_SLATE_GRAY: "3830065460115",
	ASSISTANT_SLATE_GRAY: "3830065460047",
	ASSISTANT_GRAPHITE_BLACK: "3830065460054",
	MANAGER_SLATE_GRAY: "3830065460061",
	MANAGER_GRAPHITE_BLACK: "3830065460078",
	EXECUTIVE_SLATE_GRAY: "3830065460085",
	EXECUTIVE_GRAPHITE_BLACK: "3830065460092",
	UNIVERSAL_SLATE_GRAY: "3830065460900",
	UNIVERSAL_GRAPHITE_BLACK: "3830065460917",
	JOAN_13_GRAPHITE_BLACK: "3830065463093",
	JOAN_13_SLATE_GRAY: "3830065463086",
	BOARD_13_BLACK: "3830065460214",
	BOARD_13_GRAY: "3830065460191",
	CLASSROOM_13_GRAY: "3830065460245",
	CLASSROOM_13_BLACK: "3830065460252",
	PREMIUM_13_GRAY: "3830065460221",
	PREMIUM_13_BLACK: "3830065460238",
	PARKING_13_GRAY: "3830065460269",
	PARKING_13_BLACK: "3830065460276",
	PARKING_13_REFLECTIVE_WHITE_EU: "3830065462744",
	PARKING_13_REFLECTIVE_BLUE_EU: "3830065462751",
	PARKING_13_REFLECTIVE_WHITE_JP: "3830065462768",
	PARKING_13_REFLECTIVE_BLUE_JP: "3830065462775",
	PARKING_13_REFLECTIVE_WHITE_US: "3830065462782",
	PARKING_13_REFLECTIVE_BLUE_US: "3830065462799",
	PLACE_AND_PLAY_13_SLATE_GRAY: "3830065461693",
	PLACE_AND_PLAY_13_GRAPHITE_BLACK: "3830065461709",
	PLACE_AND_PLAY_13_GRAY_EAP: "3830065461716",
	PLACE_AND_PLAY_13_BLACK_EAP: "3830065461723",
	PLACE_AND_PLAY_32_SLATE_GRAY: "3830065461761",
	PLACE_AND_PLAY_32_GRAPHITE_BLACK: "3830065461778",
	PLACE_AND_PLAY_32_DEV_KIT_SLATE_GRAY: "3830065461785",
	PLACE_AND_PLAY_32_DEV_KIT_GRAPHITE_BLACK: "3830065461792",
}

export const JOAN_6_PRO_GTINS = [
	DEVICE_GTINS.JOAN_6_PRO_GRAPHITE_BLACK,
	DEVICE_GTINS.JOAN_6_PRO_SLATE_GRAY,
]
export const JOAN_6_RE_GTINS = [
	DEVICE_GTINS.JOAN_6_RE_GRAPHITE_BLACK,
	DEVICE_GTINS.JOAN_6_RE_SLATE_GRAY,
]

export const SIX_INCH = {
	value: 0,
	label: "",
	size: 6,
	image: device6inch,
	imageWl: device6inchWl,
}

export const NINE_INCH = {
	value: 1,
	label: "",
	size: 9,
	image: device6inch,
	imageWl: device6inchWl,
}

export const THIRTEEN_INCH = {
	value: 2,
	label: "",
	size: 13,
	image: device13inch,
	imageWl: device13inchWl,
}

export const THIRTY_TWO_INCH = {
	value: 3,
	label: "",
	size: 32,
	image: device13inch,
	imageWl: device13inchWl,
}

export const DISPLAY = {
	value: 2,
	label: "",
	size: -1,
	image: displayDevice,
	imageWl: displayDeviceWl,
}

export const TABLET = {
	value: 2,
	size: -2,
	image: tabletDevice,
	imageWl: tabletDeviceWl,
}

export const DEVICE_TYPES = {
	SIX_INCH,
	NINE_INCH,
	THIRTEEN_INCH,
	THIRTY_TWO_INCH,
	DISPLAY,
	TABLET,
}

export const DEVICE_SIZES = {
	joan_6: "JOAN_6",
	joan_6_pro: "JOAN_6_PRO",
	joan_6_re: "JOAN_6_RE",
	joan_9: "JOAN_9",
	joan_13: "JOAN_13",
	joan_32: "JOAN_32",
	joan_on_display: "JOAN_ON_DISPLAY",
	joan_on_tablets: "JOAN_ON_TABLETS",
}

const MEETING = {
	value: 0,
	image: meetingInterfaceImage,
	imageWl: meetingInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.REAL_TIME_BOOKING,
}

const PREMIUM = {
	value: 1,
	image: premiumInterfaceImage,
	imageWl: premiumInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.SCHEDULE_BOARD,
}

const BOARD = {
	value: 2,
	image: boardInterfaceImage,
	imageWl: boardInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.ROOM_GRID,
}

const TEAM_BOARD = {
	value: 3,
	image: teamBoardInterfaceImage,
	imageWl: teamBoardInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.TEAM_GRID,
}

const CLASSROOM = {
	value: 4,
	image: classRoomInterfaceImage,
	imageWl: classRoomInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.SCHEDULE_BOARD_2,
}

const DESK = {
	value: 5,
	image: deskInterfaceImage,
	imageWl: deskInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.HOT_DESKING,
}

const PARKING = {
	value: 6,
	image: parkingInterfaceImage,
	imageWl: parkingInterfaceImageWl,
	isInJoanLabs: true,
	accessFlags: DEVICE_FEATURES.PARKING,
}

const STATUS_BOARD = {
	value: 7,
	image: statusBoardInterfaceImage,
	imageWl: statusBoardInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.MEETING_LIST,
}

const JOAN_ON_TABLETS = {
	value: 8,
	image: statusBoardInterfaceImage,
	imageWl: statusBoardInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.JOAN_ON_TABLETS,
}

const JOAN_IN_ROOM = {
	value: 9,
	image: inRoomInterfaceImage,
	imageWl: inRoomInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.JOAN_IN_ROOM,
}

const JOAN_HOME = {
	value: 10,
	image: homeInterfaceImage,
	imageWl: homeInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.JOAN_HOME,
}

const JOAN_SIGN = {
	value: 11,
	image: signInterfaceImage,
	imageWl: signInterfaceImageWl,
	isInJoanLabs: false,
	accessFlags: DEVICE_FEATURES.JOAN_SIGN,
}

export const APP_TYPES = {
	MEETING,
	DESK,
	PREMIUM,
	CLASSROOM,
	BOARD,
	TEAM_BOARD,
	PARKING,
	STATUS_BOARD,
	JOAN_ON_TABLETS,
	JOAN_IN_ROOM,
	JOAN_HOME,
	JOAN_SIGN,
}

// Note: do not export this directly. Use `isAppCompatible` function
// to evaluate compatibility.
export const COMPATIBLE_APPS: { [key: number]: DeviceAppInterface[] } = {
	[-2]: [JOAN_ON_TABLETS],
	[-1]: [STATUS_BOARD, JOAN_IN_ROOM],
	6: [MEETING, DESK, JOAN_HOME],
	9: [MEETING],
	13: [PREMIUM, BOARD, TEAM_BOARD, CLASSROOM, PARKING, JOAN_SIGN],
	32: [JOAN_SIGN, BOARD, TEAM_BOARD],
}

export const MULTI_CALENDAR_DEVICE_APPS = [
	BOARD,
	TEAM_BOARD,
	STATUS_BOARD,
	JOAN_HOME,
]
