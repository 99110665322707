import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type DevicesFilterProps = {
	value: string
	onChange: (v: string) => void
}

export const DeviceFilterValues = {
	TABLET: "TABLET",
	PRINTER: "PRINTER",
	LOW_BATTERY: "DEVICE_LOW_BATTERY",
	NO_CONNECTION: "NO_CONNECTION",
}

const DeviceFilter = ({ value, onChange }: DevicesFilterProps) => {
	const { t } = useTranslation()
	const options = [
		{
			label: t("desktop.settings.visitors.devices.filters.all_devices"),
			value: FilterSpecialValues.ALL,
		},
		{
			label: t("desktop.settings.visitors.devices.filters.tablets"),
			value: DeviceFilterValues.TABLET,
		},
		{
			label: t("desktop.settings.visitors.devices.filters.printers"),
			value: DeviceFilterValues.PRINTER,
		},
		{
			label: t("desktop.settings.visitors.devices.filters.low_battery"),
			value: DeviceFilterValues.LOW_BATTERY,
		},
		{
			label: t("desktop.settings.visitors.devices.filters.no_connection"),
			value: DeviceFilterValues.NO_CONNECTION,
		},
	]

	return (
		<Dropdown
			className="device-filter"
			options={options}
			value={value}
			onChange={onChange}
		/>
	)
}

export default DeviceFilter
