import React, { useCallback, useEffect, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { SupportedEvents, analyticsEvent } from "../../analytics"
import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useRefetch } from "../../hooks/mobile/useRefetch"
import { useCalculateBookable } from "../../hooks/useCalculateBookable"
import { toInternalTime } from "../../utils"
import Map from "../Map"
import Place from "../Place"
import Button from "../basic/Button"
import Loader from "../basic/Loader"
import { DeskInfo } from "./DeskInfo"
import { Filter, OccupancyFilterValues } from "./Filter/FilterSelector"
import { Popup } from "./Popup"

import { useFetchDeskReservationsQuery } from "../../redux/api/deskReservations"
import { DeskReservation } from "../../redux/api/deskReservations/types"
import { useFetchDesksQuery } from "../../redux/api/desks"
import { DeskResponse } from "../../redux/api/desks/types"
import { useLazyFetchFloorQuery } from "../../redux/api/floors"
import { useAppSelector } from "../../redux/reducers"
import { SeatReservation } from "../../redux/reservations/types"
import { TimeslotResponse } from "../../redux/timeslots/types"
import { areSlotsOverlapping } from "../../redux/timeslots/utils"

import "./DeskPicker.sass"

type DeskPickerProps = {
	date: Dayjs
	fromDate?: Dayjs
	toDate?: Dayjs
	timeslot: Partial<TimeslotResponse>
	buildingId: string
	floorId?: string
	deskId?: string
	excludeResId?: string
	popupPos?: "high" | "medium" | "low"
	onPick: (desk: SeatReservation | null) => void
	filter?: Filter
}

export const DeskPicker: React.FC<DeskPickerProps> = ({
	date,
	fromDate,
	toDate,
	timeslot,
	buildingId,
	floorId,
	deskId,
	excludeResId,
	popupPos,
	onPick,
	filter,
}) => {
	const { t } = useTranslation()
	const history = useHistory()

	const startDate = fromDate?.toISOString() || date.startOf("day").toISOString()
	const endDate = toDate?.toISOString() || date.endOf("day").toISOString()

	const [showSeat, setShowSeat] = useState(false)
	const [showPopup, setShowPopup] = useState(false)

	const isFloorPage = history.location.pathname === "/floor"

	/**
	 * isReady is used to prevent the map from being rendered before the map image is loaded
	 * to achieve that we are listening to the map image is loaded event.
	 */
	const [isReady, setIsReady] = useState(false)
	const [selectedSeat, setSelectedSeat] = useState<DeskResponse>()
	const [selectedSeatIsAvailable, setSelectedSeatIsAvailable] =
		useState<boolean>(false)
	const [selectedSeatReservations, setSelectedSeatReservations] = useState<
		Partial<DeskReservation>[]
	>([])
	const {
		data: { results: reservations = [] } = {},
		isFetching,
		isSuccess,
		refetch,
	} = useFetchDeskReservationsQuery(
		{
			start: startDate,
			end: endDate,
			floor_id: floorId,
			limit: FETCH_WITH_NO_LIMIT,
		},
		{ skip: !floorId },
	)

	const { data: { results: desks = [] } = {}, isFetching: areDeskLoading } =
		useFetchDesksQuery(
			{
				floor: floorId,
				department_id: filter?.department,
				amenity_id: filter?.amenities,
			},
			{ skip: !floorId },
		)

	const [fetchFloor, { data: map, isFetching: areFloorsLoading }] =
		useLazyFetchFloorQuery()

	useRefetch([refetch])

	const { entry: user } = useAppSelector((state) => state.user)

	const desksBookable = useCalculateBookable({
		date: date.toISOString(),
		timeslot,
		floorId,
		excludeResId,
	})

	const getDeskReservations = useCallback(
		(desk: DeskResponse) => {
			const seatReservations = reservations
				.filter((res) => {
					return (
						res.desk.id === desk.id &&
						date.isSame(dayjs(res.start), "day") &&
						areSlotsOverlapping(
							{ from: timeslot.from, to: timeslot.to },
							{
								from: toInternalTime(res.start ?? ""),
								to: toInternalTime(res.end ?? ""),
							},
						) &&
						(!excludeResId || res.id !== excludeResId)
					)
				})
				.sort((a, b) => a.desk.name.localeCompare(b.desk.name))

			if (seatReservations.length === 0) {
				return [
					{
						id: undefined,
						desk,
						user: {
							...user,
						},
						checked_in: null,
					},
				]
			}

			return seatReservations
		},
		[reservations, user, timeslot.from, timeslot.to, excludeResId, date],
	)

	const filterDesks = useCallback(() => {
		if (!filter) {
			return desks
		}
		const seats = desks.filter((seat) => {
			let shouldAdd: boolean = true
			if (filter.occupancy !== OccupancyFilterValues.ALL) {
				const seatReservations = getDeskReservations(seat)
				shouldAdd =
					(!!seatReservations[0].id &&
						filter.occupancy === OccupancyFilterValues.BOOKED) ||
					(!seatReservations[0].id &&
						filter.occupancy === OccupancyFilterValues.FREE)
			}

			return shouldAdd
		})
		return seats
	}, [filter, getDeskReservations, desks])

	const filteredDesks = filterDesks()

	useEffect(() => {
		if (floorId && (!map || map.floor_id !== floorId)) {
			fetchFloor({
				id: floorId,
				settings: false,
			})
		}
	}, [fetchFloor, floorId, map])

	useEffect(() => {
		setShowPopup(false)
		setSelectedSeat(undefined)
		setSelectedSeatReservations([])
	}, [buildingId, floorId, startDate, timeslot.from, timeslot.to])

	useEffect(() => {
		if (filteredDesks && deskId && !showSeat) {
			const chosenSeat = desks?.find((s: DeskResponse) => s.id === deskId)

			if (chosenSeat) {
				const deskReservations = getDeskReservations(chosenSeat)

				setSelectedSeat(chosenSeat)
				setSelectedSeatIsAvailable(desksBookable[deskId])
				setSelectedSeatReservations(deskReservations)
				setShowPopup(true)

				setShowSeat(true)
			}
		}
	}, [
		getDeskReservations,
		filteredDesks,
		desksBookable,
		showSeat,
		deskId,
		desks,
	])

	const handlePlaceClick = useCallback(
		(
			seat: DeskResponse,
			available: boolean,
			seatReservations: Partial<DeskReservation>[],
		) => {
			setSelectedSeat(seat)
			setSelectedSeatIsAvailable(available)
			setSelectedSeatReservations(seatReservations)
			setShowPopup(true)
		},
		[],
	)

	useEffect(() => {
		analyticsEvent(SupportedEvents.DESK_MAP)
	}, [])

	useEffect(() => {
		if (areDeskLoading || areFloorsLoading) {
			setIsReady(false)
		}
	}, [areDeskLoading, areFloorsLoading])

	const cn = classNames("map", { visible: isReady })

	return (
		<div className="DeskPicker">
			{map && !areDeskLoading && !areFloorsLoading && (
				<Map
					map={map}
					onClick={() => {
						setShowPopup(false)
						setSelectedSeat(undefined)
						setSelectedSeatReservations([])
					}}
					isDisabled={false}
					showCrosshair={false}
					showZoomControls={false}
					showPanControls={false}
					onIsReady={() => {
						setIsReady(true)
					}}
					className={cn}
				>
					{filteredDesks?.map((seat, i: number) => {
						const seatReservations = getDeskReservations(seat)
						const isSelected =
							selectedSeat !== undefined && seat.id === selectedSeat.id

						return (
							<Place
								key={`seat-${i}`}
								x={seat.coord_x}
								y={seat.coord_y}
								mapWidth={map!.width!}
								mapHeight={map!.height!}
								onClick={() => {
									handlePlaceClick(
										seat,
										desksBookable[seat.id],
										seatReservations,
									)
								}}
								title=""
								isAvailable={isFetching ? undefined : !seatReservations[0].id}
								isOccupied={isFetching ? undefined : !!seatReservations[0].id}
								isBlinking={false}
								isDisabled={isFetching || !isSuccess}
								isInactive={!desksBookable[seat.id]}
								isSelected={isSelected}
							/>
						)
					})}
				</Map>
			)}
			{isReady && !map && (
				<div className="no-floors">
					<div>{t("mobile.floor.no_floors")}</div>
				</div>
			)}
			{!isReady && <Loader variant="fullScreen" />}
			<Popup open={showPopup} position={popupPos}>
				{selectedSeat && (
					<DeskInfo
						seat={selectedSeat}
						isAvailable={selectedSeatIsAvailable}
						reservations={selectedSeatReservations}
						onPick={onPick}
					/>
				)}
			</Popup>
			{!showPopup && !isFloorPage && (
				<div className="choose-another-building">
					<p className="question">{t("mobile.book.not_okay")}</p>
					<div className="next-button">
						<Button
							variant="mobile-action"
							onClick={() => history.push("/book/desk/building")}
						>
							{t("mobile.book.pick_another_building")}
						</Button>
					</div>
				</div>
			)}
		</div>
	)
}
