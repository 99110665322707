import ReactModal from "react-modal"
import { useHistory } from "react-router-dom"

import { contentStyle, overlayStyle } from "./modalStyles"

import {
	AssetBookingForm,
	AssetFormType,
} from "../components/Form/AssetBookingForm"

type Props = {
	open: boolean
	reservationId?: string
	assetType: string
	assetFormData?: AssetFormType
	/**
	 * A callback that is called every time the modal does some
	 * manipulation about data
	 */
	dataUpdate?: () => void
}

export default function AssetBookingModal({
	open,
	reservationId,
	assetType,
	assetFormData,
	dataUpdate,
}: Props) {
	const history = useHistory()

	return (
		<ReactModal
			isOpen={open}
			style={{
				content: {
					...contentStyle,
					width: "100%",
				},
				overlay: overlayStyle,
			}}
			onRequestClose={() => history.push("/manage/assets")}
			ariaHideApp={false}
		>
			<AssetBookingForm
				reservationId={reservationId}
				assetType={assetType}
				dataUpdate={dataUpdate}
				assetFormData={assetFormData}
			/>
		</ReactModal>
	)
}
