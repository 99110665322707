import React from "react"

import classNames from "classnames"

import "./style.sass"

export type Step = {
	id: string
	title: string
	disabled?: boolean
}

export type StepperProps = {
	steps: Step[]
	currentStep: string
	onClick: (id: string, index: number) => void
}

const Stepper = ({ steps, currentStep, onClick }: StepperProps) => {
	return (
		<div className="Stepper">
			{steps.map((s, i) => {
				const className = classNames("stepper-step", {
					current: s.id === currentStep,
				})
				return (
					<div className={className} key={s.id}>
						<button
							className="stepper-button"
							type="button"
							onClick={() => onClick?.(s.id, i)}
							disabled={s.disabled}
						>
							<span className="step-number">{i + 1}</span>
							<span>{s.title}</span>
						</button>
					</div>
				)
			})}
		</div>
	)
}

export default Stepper
