import React, { useMemo } from "react"

import classNames from "classnames"

import {
	APP_TYPES,
	DISPLAY,
	NINE_INCH,
	SIX_INCH,
	TABLET,
	THIRTEEN_INCH,
	THIRTY_TWO_INCH,
} from "../constants"
import {
	convertGtinToColor,
	isJoan6Pro,
	isJoan6RE,
	isJoanSign,
	isTablet,
} from "./utils"

import { getAppParams } from "../../../../redux/appParams/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import sixInchProImg from "../../../../assets/images/devices/6inch_pro.png"
import sixInchProBlackImg from "../../../../assets/images/devices/6inch_pro_black.png"
import sixInchProBlackWlImg from "../../../../assets/images/devices/6inch_pro_black_whitelabel.png"
import sixInchProWlImg from "../../../../assets/images/devices/6inch_pro_whitelabel.png"
import sixInchReImg from "../../../../assets/images/devices/6inch_re.png"
import sixInchReBlackImg from "../../../../assets/images/devices/6inch_re_black.png"
import sixInchReBlackWlImg from "../../../../assets/images/devices/6inch_re_black_whitelabel.png"
import sixInchReWlImg from "../../../../assets/images/devices/6inch_re_whitelabel.png"

import "./DevicePreview.sass"

type Props = {
	selectedApp: string
	currentDevice: {
		value: number
		size: number
		image: string
		imageWl: string
	}
	gtin: string
}

const DevicePreview = ({ selectedApp, currentDevice, gtin }: Props) => {
	const { isAppWhitelabel } = useAppSelector(getAppParams)

	const deviceAppInterface = useMemo(
		() =>
			Object.values(APP_TYPES).find((o) => o.value === parseInt(selectedApp)),
		[selectedApp],
	)

	const deviceAppInterfaceImg = useMemo(
		() =>
			isAppWhitelabel ? deviceAppInterface!.imageWl : deviceAppInterface!.image,
		[deviceAppInterface, isAppWhitelabel],
	)

	const deviceImg = useMemo(
		() => (isAppWhitelabel ? currentDevice.imageWl : currentDevice.image),
		[isAppWhitelabel, currentDevice.image, currentDevice.imageWl],
	)

	const sixInchPro = {
		white: isAppWhitelabel ? sixInchProWlImg : sixInchProImg,
		black: isAppWhitelabel ? sixInchProBlackWlImg : sixInchProBlackImg,
	}

	const sixInchRE = {
		white: isAppWhitelabel ? sixInchReWlImg : sixInchReImg,
		black: isAppWhitelabel ? sixInchReBlackWlImg : sixInchReBlackImg,
	}

	const devicePreviewClassName = classNames({
		"device-preview": true,
		"six-inch": currentDevice.size === SIX_INCH.size,
		"nine-inch": currentDevice.size === NINE_INCH.size,
		"thirteen-inch": currentDevice.size === THIRTEEN_INCH.size,
		"thirty-two-inch": currentDevice.size === THIRTY_TWO_INCH.size,
		display: currentDevice.size === DISPLAY.size,
		tablet: currentDevice.size === TABLET.size,
		"six-inch-pro": isJoan6Pro(gtin),
		"six-inch-re": isJoan6RE(gtin),
	})

	return (
		<div className="device-preview-container">
			<div className={devicePreviewClassName}>
				{isJoan6Pro(gtin) || isJoan6RE(gtin) ? (
					<div className="device-preview-frame">
						{isJoan6Pro(gtin) && (
							<img alt="Device" src={sixInchPro[convertGtinToColor(gtin)]} />
						)}

						{isJoan6RE(gtin) && (
							<img alt="Device" src={sixInchRE[convertGtinToColor(gtin)]} />
						)}
					</div>
				) : (
					<>
						{!isJoanSign(parseInt(selectedApp)) && (
							<div className="device-preview-frame">
								<img alt="Device" src={deviceImg} />
							</div>
						)}

						{!isTablet(currentDevice.size) && deviceAppInterfaceImg && (
							<div className="device-preview-interface">
								<img alt="App content" src={deviceAppInterfaceImg} />
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default DevicePreview
