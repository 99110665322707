import { useTranslation } from "react-i18next"

import InvoiceList from "./Invoices/InvoiceList"
import Payments from "./Payments"
import Plans from "./Plans"

import { useFetchInvoicesQuery } from "../../../redux/api/billing/invoices"

import Breadcrumbs from "../../../components/Breadcrumbs"
import View from "../../../components/View"

import "./Overview.sass"

const Overview = () => {
	const { t } = useTranslation()
	const { data: invoices, isSuccess: areInvoicesLoaded } =
		useFetchInvoicesQuery({ limit: 5 })

	return (
		<View className="Overview">
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.settings.billing.title"),
					t("desktop.settings.billing.overview.title"),
				]}
			/>
			<Payments />
			<Plans />
			{areInvoicesLoaded && invoices?.results?.length > 0 && (
				<InvoiceList invoices={invoices.results} />
			)}
		</View>
	)
}

export default Overview
