import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { NavLink, matchPath, useLocation } from "react-router-dom"

import { selectConsent } from "../../redux/consent/selectors"
import { useAppSelector } from "../../redux/reducers"

import { ReactComponent as AccountSVG } from "../../assets/images/icons/Account.svg"
import { ReactComponent as CheckListSVG } from "../../assets/images/icons/CheckList.svg"
import { ReactComponent as HomeSVG } from "../../assets/images/icons/Home.svg"
import { ReactComponent as MapsSVG } from "../../assets/images/icons/Maps.svg"

import "./BottomNav.sass"

type NavButtonProps = {
	icon: JSX.Element
	isActive?: Boolean
}

const NavButton: React.FC<React.PropsWithChildren<NavButtonProps>> = ({
	icon,
	isActive,
	children,
}) => {
	const navButtonClassName = classNames({
		NavButton: true,
		isActive,
	})

	return (
		<div className={navButtonClassName}>
			<div>{icon}</div>
			<div>{children}</div>
		</div>
	)
}

export const BottomNav: React.FC<React.PropsWithChildren> = () => {
	const { t } = useTranslation()
	const location = useLocation()

	const { entry: deskBookingConsent } = useAppSelector(selectConsent)

	const isActive = (path: string) => {
		return (
			matchPath(location.pathname, {
				path: path,
				exact: false,
				strict: false,
			}) !== null
		)
	}

	return (
		<div className="BottomNav">
			<NavLink to="/home/reservations">
				<NavButton icon={<HomeSVG />} isActive={isActive("/home")}>
					{t("mobile.navigation.home")}
				</NavButton>
			</NavLink>
			{deskBookingConsent && (
				<NavLink to="/floor">
					<NavButton icon={<MapsSVG />} isActive={isActive("/floor")}>
						{t("mobile.navigation.map")}
					</NavButton>
				</NavLink>
			)}
			{deskBookingConsent && (
				<NavLink to="/presence">
					<NavButton icon={<CheckListSVG />} isActive={isActive("/presence")}>
						{t("mobile.navigation.presence")}
					</NavButton>
				</NavLink>
			)}
			<NavLink to="/profile/info">
				<NavButton icon={<AccountSVG />} isActive={isActive("/profile")}>
					{t("mobile.navigation.profile")}
				</NavButton>
			</NavLink>
		</div>
	)
}
