import { ThunkApiConfig } from "RootType"

import { experimentsUserURL, get, postJSON } from "../../api"
import {
	PaginationState,
	SliceState,
	getErrorMessage,
	paginationInitialState,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import { UserExperimentResponse, UserExperimentSettings } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */

export const fetchUserExperiments = createAsyncThunk<
	UserExperimentResponse,
	void,
	ThunkApiConfig
>("userExperiments/fetch", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(experimentsUserURL(), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const giveUserConsent = createAsyncThunk<
	UserExperimentSettings,
	void,
	ThunkApiConfig
>("userExperiments/giveUserConsent", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		experimentsUserURL(),
		{ body: { data: { type: "USER_CONSENT", value: true } } },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */

export interface UserExperimentsState extends SliceState, PaginationState {
	entries: UserExperimentSettings[]
}

const initialState: UserExperimentsState = {
	entries: [],
	...sliceInitialState,
	...paginationInitialState,
}

const userExperimentsSlice = createSlice({
	name: "userExperiments",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUserExperiments.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchUserExperiments.rejected, (state, action) => {
			setFetchErrorState(state, action)
		})
		builder.addCase(fetchUserExperiments.fulfilled, (state, { payload }) => {
			const { results, ...paginationData } = payload
			state = {
				...state,
				...paginationData,
				entries: results,
			}
			setFetchSuccessState(state)

			return state
		})

		builder.addCase(giveUserConsent.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(giveUserConsent.rejected, (state, action) => {
			setSubmitErrorState(state, action)
		})
		builder.addCase(giveUserConsent.fulfilled, (state, { payload }) => {
			state.entries = [...state.entries, payload]
			setSubmitSuccessState(state)

			return state
		})
	},
})

export const userExperimentsReducer = userExperimentsSlice.reducer
