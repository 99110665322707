import React from "react"

import { useTranslation } from "react-i18next"

import { BILLING_URL } from "../../api"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { BILLING_PATHNAMES } from "./Billing/constants"
import { DEPARTMENT_PATHS } from "./Departments/constants"
import { DESKS_PATHS } from "./Desks/constants"

import { LIST_PLAN_GROUPS } from "../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../redux/api/billing/subscriptions"
import { groupToTranslationKey } from "../../redux/api/billing/utils"
import { useAppSelector } from "../../redux/reducers"
import { selectUser, selectUserPermissions } from "../../redux/user/selectors"
import { isNormalUser, isPortalAdmin } from "../../redux/user/utils"

import AuxNav, { AuxButton, AuxSeparator } from "../../components/AuxNav"
import { NavMenu, NavSubItem } from "../../components/NavMenu"

import { ReactComponent as AccountSVG } from "../../assets/images/icons/Account.svg"
import { ReactComponent as AssetSVG } from "../../assets/images/icons/Asset.svg"
import { ReactComponent as BadgeSVG } from "../../assets/images/icons/BadgeOutline.svg"
import { ReactComponent as BuildingSVG } from "../../assets/images/icons/Building.svg"
import { ReactComponent as ChairSVG } from "../../assets/images/icons/Chair.svg"
import { ReactComponent as DepartmentsSVG } from "../../assets/images/icons/Departments.svg"
import { ReactComponent as HealthSVG } from "../../assets/images/icons/Health.svg"
import { ReactComponent as FloorPlanSVG } from "../../assets/images/icons/Maps.svg"
import { ReactComponent as OrganizationSVG } from "../../assets/images/icons/Organization.svg"
import { ReactComponent as PaymentSVG } from "../../assets/images/icons/Payment.svg"
import { ReactComponent as PeopleSVG } from "../../assets/images/icons/People.svg"
import { ReactComponent as RoomSVG } from "../../assets/images/icons/Room.svg"

export const SettingsNav = () => {
	const permissions = useAppSelector(selectUserPermissions)
	const { entry: currentUser } = useAppSelector(selectUser)
	const { data: { subsByPlanGroup } = {} } = useFetchSubscriptionsQuery()

	const { t } = useTranslation()

	const canEditCompanySettings = permissions.includes(
		"desk.change_deskcompanysettings",
	)
	const canEditTimeSlots = permissions.includes("desk.change_timeslot")
	const canEditBuilding = permissions.includes(
		"portal.locations.change_building",
	)
	const canEditFloor = permissions.includes("portal.locations.change_floor")
	const canEditDepartments = permissions.includes(
		"portal.locations.change_department",
	)
	const canEditDesks = permissions.includes("desk.change_deskresource")
	const canEditRooms = permissions.includes("desk.change_deskresource")
	const canEditAmenities = permissions.includes(
		"portal_resources.change_amenity",
	)

	const canEditBilling = isPortalAdmin(currentUser)
	const isBillingDisabled = useCheckForFeatureFlag("subscriptions.disabled")

	const canEditPeople = permissions.includes("users.change_user")
	const canEditHealth = permissions.includes("desk.change_screeningconfig")
	const canEditAssetTypes = permissions.includes(
		"portal_resources.change_assettype",
	)

	const canEditVisitorDocuments = permissions.includes(
		"core_visitors.change_visitorsdocuments",
	)
	const canEditVisitorTabletSettings = permissions.includes(
		"core_visitors.change_tabletsettings",
	)
	const canEditVisitorPrinterSettings = permissions.includes(
		"core_visitors.change_printersettings",
	)

	const isUser = isNormalUser(currentUser)

	const isPortalMergerEnabled = useCheckForFeatureFlag("portalMerger.isEnabled")

	return (
		<AuxNav>
			{canEditBuilding && (
				<AuxButton to="/settings/buildings" isDisabled={!canEditBuilding}>
					<BuildingSVG />
					<span>Buildings</span>
				</AuxButton>
			)}

			{canEditFloor && (
				<AuxButton to="/settings/floor-plans" isDisabled={!canEditFloor}>
					<FloorPlanSVG />
					<span>Floor plans</span>
				</AuxButton>
			)}

			{canEditDepartments && (
				<AuxButton to={DEPARTMENT_PATHS.root} isDisabled={!canEditDepartments}>
					<DepartmentsSVG />
					<span>Departments</span>
				</AuxButton>
			)}

			{(canEditBuilding || canEditFloor || canEditDepartments) && (
				<AuxSeparator />
			)}

			{/*
				Desks
			*/}
			{canEditDesks && (
				<NavMenu
					to={DESKS_PATHS.general}
					isDisabled={!canEditDesks}
					activePath="/settings/desks"
					icon={<ChairSVG />}
					text="Desks"
				>
					<>
						{canEditCompanySettings && (
							<NavSubItem to={DESKS_PATHS.general}>General settings</NavSubItem>
						)}
						{canEditDesks && (
							<NavSubItem to={DESKS_PATHS.layout}>Layout</NavSubItem>
						)}
						{canEditTimeSlots && (
							<NavSubItem to={DESKS_PATHS.timeSlots.default}>
								Timeslots
							</NavSubItem>
						)}
						{canEditAmenities && (
							<NavSubItem to={DESKS_PATHS.amenities.default}>
								Amenities
							</NavSubItem>
						)}
					</>
				</NavMenu>
			)}

			{/*
				Rooms
			*/}
			{canEditRooms ? (
				isPortalMergerEnabled ? (
					<NavMenu
						to="/settings/rooms/overview"
						isDisabled={!canEditRooms}
						activePath="/settings/rooms"
						icon={<RoomSVG />}
						text="Rooms"
					>
						<NavSubItem to="/settings/rooms/overview">Overview</NavSubItem>
						<NavSubItem to="/settings/rooms/room-list">Room list</NavSubItem>
						<NavSubItem to="/settings/rooms/devices">Devices</NavSubItem>
					</NavMenu>
				) : (
					<AuxButton to="/settings/rooms" isDisabled={!canEditRooms}>
						<RoomSVG />
						<span>Rooms</span>
					</AuxButton>
				)
			) : null}

			{canEditAssetTypes && (
				<AuxButton to="/settings/assets" isDisabled={!canEditAssetTypes}>
					<AssetSVG />
					<span>{t("desktop.settings.settings_nav.assets")}</span>
				</AuxButton>
			)}

			{canEditHealth && (
				<AuxButton to="/settings/health" isDisabled={!canEditHealth}>
					<HealthSVG />
					<span>Health</span>
				</AuxButton>
			)}

			{/*
				Visitor management
			*/}
			{canEditPeople && (
				<NavMenu
					to="/settings/visitors/general"
					isDisabled={!canEditPeople}
					activePath="/settings/visitors"
					icon={<BadgeSVG />}
					text="Visitors"
				>
					<>
						<>
							<NavSubItem to="/settings/visitors/general">
								General settings
							</NavSubItem>
							<NavSubItem to="/settings/visitors/devices">Devices</NavSubItem>
							{(canEditVisitorTabletSettings ||
								canEditVisitorPrinterSettings) && (
								<NavSubItem
									to="/settings/visitors/device-settings/tablet"
									activePath="/settings/visitors/device-settings"
								>
									Device settings
								</NavSubItem>
							)}
							{canEditVisitorDocuments && (
								<NavSubItem to="/settings/visitors/documents">
									Documents
								</NavSubItem>
							)}
						</>
					</>
				</NavMenu>
			)}

			{isUser && (
				<>
					<AuxButton
						to="/settings/visitors/general"
						activePath="/settings/visitors"
						hasSubitems
					>
						<BadgeSVG />
						<span>Visitors</span>
					</AuxButton>
					<NavSubItem to="/settings/visitors/general">
						General settings
					</NavSubItem>
				</>
			)}

			{(canEditDesks || canEditRooms || canEditHealth || canEditPeople) && (
				<AuxSeparator />
			)}

			<AuxButton to="/settings/profile">
				<AccountSVG />
				<span>Account</span>
			</AuxButton>

			{canEditPeople && (
				<AuxButton to="/settings/people" isDisabled={!canEditPeople}>
					<PeopleSVG />
					<span>User directory</span>
				</AuxButton>
			)}

			{canEditPeople && (
				<>
					<NavMenu
						icon={<OrganizationSVG />}
						text="Organization"
						to="/settings/organization/branding"
						activePath="/settings/organization"
						isDisabled={!canEditPeople}
					>
						<NavSubItem to="/settings/organization/branding">
							Branding
						</NavSubItem>
					</NavMenu>
				</>
			)}
			{/*
				Plans and billings
			*/}
			{canEditBilling &&
				!isBillingDisabled &&
				(isPortalMergerEnabled ? (
					<NavMenu
						icon={<PaymentSVG />}
						text={t("desktop.settings.navigation.plans_billing.title")}
						to={BILLING_PATHNAMES.overview.root}
						activePath={BILLING_PATHNAMES.root}
						isDisabled={!canEditBilling}
					>
						<>
							<NavSubItem
								to={BILLING_PATHNAMES.overview.root}
								activePath={BILLING_PATHNAMES.overview.root}
							>
								{t(
									"desktop.settings.navigation.plans_billing.sub_items.overview",
								)}
							</NavSubItem>
							<NavSubItem
								to={BILLING_PATHNAMES.overview.companyDetails}
								activePath={BILLING_PATHNAMES.overview.companyDetails}
							>
								{t(
									"desktop.settings.navigation.plans_billing.sub_items.company_details",
								)}
							</NavSubItem>
							<NavSubItem
								to={BILLING_PATHNAMES.overview.paymentMethod}
								activePath={BILLING_PATHNAMES.overview.paymentMethod}
							>
								{t(
									"desktop.settings.navigation.plans_billing.sub_items.payment_method",
								)}
							</NavSubItem>
							{subsByPlanGroup &&
								LIST_PLAN_GROUPS.map((group) => {
									const value = subsByPlanGroup[group]

									if (!value || value.length === 0) {
										return null
									}
									const path = `${BILLING_PATHNAMES.overview.root}#${group}`
									return (
										<NavSubItem key={group} to={path} activePath={path}>
											{t(groupToTranslationKey(group))}
										</NavSubItem>
									)
								})}
						</>
					</NavMenu>
				) : (
					<AuxButton href={BILLING_URL} isDisabled={!canEditBilling}>
						<PaymentSVG />
						<span>Plans {"&"} billing</span>
					</AuxButton>
				))}
		</AuxNav>
	)
}
