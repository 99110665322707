import { ThunkApiConfig } from "RootType"
import dayjs, { Dayjs } from "dayjs"

import {
	configScreeningEmployeeURL,
	configScreeningExportCSV,
	configScreeningURL,
	configScreeningVisitorURL,
	configScreeningsBulk,
	configScreeningsDelete,
	configScreeningsListURL,
	configScreeningsURL,
	configScreeningsURLNew,
	deleteJSON,
	employeeScreeningURLOLD,
	exportScreeningURL,
	get,
	patchJSON,
	postJSON,
	putJSON,
	visitorScreeningURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import { timeZone } from "../../dayjs"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	EmployeeScreeningPayload,
	ScreeningConfig,
	ScreeningConfigExportFetchOptions,
	ScreeningConfigList,
	ScreeningConfigListResponse,
	ScreeningConfigResponse,
	ScreeningConfigsBulkRequest,
	ScreeningsConfig,
	ScreeningsConfigData,
	VisitorScreeningPayload,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

const formatDate = (date: Dayjs | string) => {
	return dayjs(date).format("YYYY-MM-DD")
}

/**
 *  Thunks
 */
export const fetchEmployeeScreeningsConfigData = createAsyncThunk<
	ScreeningsConfigData,
	void,
	ThunkApiConfig
>("screenings/fetchEmployeeConfigData", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(configScreeningEmployeeURL(), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const fetchScreeningsConfigOld = createAsyncThunk<
	ScreeningsConfig,
	void,
	ThunkApiConfig
>("screenings/fetchScreeningsConfigOld", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(configScreeningsURL(), {}, access_token)

	if (response.ok) {
		const json = await response.json()
		const config: ScreeningsConfig = json.results[0] || {
			enabled: false,
			auto_cancel_desk_reservation: false,
			send_survey_to: "visitors&employees",
			questions: [],
			notify_emails: [],
			instructions: "",
			success_msg: "",
			failure_msg: "",
		}

		return config
	}

	throw new Error(await getErrorMessage(response))
})

export const createScreeningsConfigOld = createAsyncThunk<
	ScreeningsConfig,
	ScreeningsConfig,
	ThunkApiConfig
>("screenings/createScreeningsConfigOld", async (payload, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		configScreeningsURL(),
		{ body: payload },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const fetchVisitorScreeningsConfigData = createAsyncThunk<
	ScreeningsConfigData,
	string,
	ThunkApiConfig
>("screenings/fetchVisitorConfigData", async (company_id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(
		configScreeningVisitorURL(company_id),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

type CreateEmployeeScreeningProps = {
	userId: string
	survey: JSON
}

export const createEmployeeScreening = createAsyncThunk<
	EmployeeScreeningPayload,
	CreateEmployeeScreeningProps,
	ThunkApiConfig
>("screenings/createEmployee", async ({ userId, survey }, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		employeeScreeningURLOLD(),
		{
			body: {
				employee: { id: userId },
				survey,
			},
		},
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

type CreateVisitorScreening = {
	companyId: string
	visitorId: string
	survey: JSON
}

export const createVisitorScreening = createAsyncThunk<
	VisitorScreeningPayload,
	CreateVisitorScreening,
	ThunkApiConfig
>(
	"screenings/createVisitor",
	async ({ companyId, visitorId, survey }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await postJSON(
			visitorScreeningURL(companyId),
			{
				body: {
					visitor: { id: visitorId },
					survey: survey,
				},
			},
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		throw new Error(await getErrorMessage(response))
	},
)

// Config screenings -- OLD

export const fetchScreeningsConfig = createAsyncThunk<
	ScreeningConfig,
	string,
	ThunkApiConfig
>("screenings/fetchScreeningsConfig", async (id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	if (!id) return

	const response = await get(configScreeningsURLNew(id), {}, access_token)

	if (response.ok) {
		const json = await response.json()
		return json
	}

	throw new Error(await getErrorMessage(response))
})

export const createScreeningsConfig = createAsyncThunk<
	ScreeningConfig,
	ScreeningConfig,
	ThunkApiConfig<ResponseError>
>(
	"screenings/createScreeningsConfig",
	async (payload, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await postJSON(
			configScreeningsListURL(),
			{ body: payload },
			access_token,
		)

		if (response.ok) {
			return await response.json()
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

type ScreeningsConfigWithId = {
	id: string
	config: ScreeningsConfig
}

export const updateScreeningsConfig = createAsyncThunk<
	ScreeningsConfig,
	ScreeningsConfigWithId,
	ThunkApiConfig
>("screenings/updateScreeningsConfig", async ({ id, config }, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		configScreeningURL(id),
		{ body: config },
		access_token,
	)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

type FetchScreeningsCSVProps = {
	start: string
	end: string
	exportFor: "visitors" | "employees" | "visitors&employees"
}

export const fetchScreeningsCSV = createAsyncThunk<
	string,
	FetchScreeningsCSVProps,
	ThunkApiConfig
>(
	"screenings/fetchScreeningsCSV",
	async ({ start, end, exportFor }, { getState }) => {
		const {
			auth: { access_token },
		} = getState()

		const response = await get(
			exportScreeningURL({
				from: formatDate(start),
				to: formatDate(end),
				export_for: exportFor,
				tz: timeZone,
			}),
			{},
			access_token,
		)

		if (response.ok) {
			return await response.text()
		}

		throw new Error(await getErrorMessage(response))
	},
)

/** New screenings */
export const fetchScreeningConfigList = createAsyncThunk<
	ScreeningConfigListResponse,
	string,
	ThunkApiConfig
>("screenings/fetchScreeningConfigList", async (building_id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const isAllBuilding = building_id === FilterSpecialValues.ALL

	const response = await get(
		configScreeningsListURL({ building_id: !isAllBuilding ? building_id : "" }),
		{},
		access_token,
	)

	if (response.ok) {
		const json = await response.json()
		return json
	}

	throw new Error(await getErrorMessage(response))
})

export const updateScreeningsConfigNew = createAsyncThunk<
	ScreeningConfig,
	ScreeningConfig,
	ThunkApiConfig<ResponseError>
>(
	"screenings/updateScreeningsConfigNew",
	async (body, { getState, rejectWithValue }) => {
		const {
			auth: { access_token },
		} = getState()

		if (!body.id) return

		const response = await patchJSON(
			configScreeningsURLNew(body.id),
			{
				body,
			},
			access_token,
		)

		if (response.ok) {
			const json = await response.json()
			return json
		}

		return rejectWithValue(await getErrorObject(response))
	},
)

export const deleteScreeningConfig = createAsyncThunk<
	void,
	string,
	ThunkApiConfig
>("screenings/deleteScreeningConfig", async (id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await deleteJSON(
		configScreeningsDelete(id),
		undefined,
		access_token,
	)

	if (response.ok) {
		return
	}

	throw new Error(await getErrorMessage(response))
})

export const screeningConfigsBulkAction = createAsyncThunk<
	void,
	ScreeningConfigsBulkRequest,
	ThunkApiConfig<ResponseError>
>("screenings/bulk", async (body, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await patchJSON(
		configScreeningsBulk(),
		{ body },
		access_token,
	)

	if (response.ok) {
		return
	}

	throw new Error(await getErrorMessage(response))
})

export const exportScreeningConfig = createAsyncThunk<
	string,
	ScreeningConfigExportFetchOptions,
	ThunkApiConfig
>("screenings/export", async (options, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(
		configScreeningExportCSV(options),
		{},
		access_token,
	)

	if (response.ok) {
		return await response.text()
	}
	return rejectWithValue(await getErrorMessage(response))
})

/**
 *  Slice
 */
export type ScreeningsState = SliceState & {
	entry?: ScreeningConfigResponse
	healthSettings: ScreeningsConfig | null
	screeningConfigsListEntries: ScreeningConfigList[] | []
	data: ScreeningConfigResponse | {}
}

const initialState: ScreeningsState = {
	...sliceInitialState,
	entry: undefined,
	healthSettings: null,
	screeningConfigsListEntries: [],
	data: {},
}

const screeningsSlice = createSlice({
	name: "screenings",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			fetchEmployeeScreeningsConfigData.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.data = payload
			},
		)

		builder.addCase(
			fetchVisitorScreeningsConfigData.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.data = payload
			},
		)
		builder.addCase(fetchScreeningsConfig.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.entry = payload
		})
		builder.addCase(
			fetchScreeningsConfigOld.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.healthSettings = payload
			},
		)
		builder.addCase(
			fetchScreeningConfigList.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.screeningConfigsListEntries = payload.results
			},
		)
		builder.addCase(updateScreeningsConfig.pending, (state) => {
			state.isSubmitting = true
		})
		builder.addCase(updateScreeningsConfig.rejected, (state, action) => {
			setSubmitErrorState(state, action)
		})
		builder.addCase(
			updateScreeningsConfigNew.fulfilled,
			(state, { payload }) => {
				setSubmitSuccessState(state)
				state.entry = payload
			},
		)
		builder.addCase(createScreeningsConfig.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.entry = payload
		})
		builder.addCase(
			createScreeningsConfigOld.fulfilled,
			(state, { payload }) => {
				setFetchSuccessState(state)
				state.healthSettings = payload
			},
		)
		builder.addCase(screeningConfigsBulkAction.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})
		builder.addCase(deleteScreeningConfig.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})
		builder.addMatcher(
			isAnyOf(
				fetchEmployeeScreeningsConfigData.pending,
				fetchVisitorScreeningsConfigData.pending,
				fetchScreeningsConfig.pending,
				fetchScreeningsConfigOld.pending,
			),
			(state) => {
				state.isLoading = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				fetchEmployeeScreeningsConfigData.rejected,
				fetchVisitorScreeningsConfigData.rejected,
				fetchScreeningsConfig.rejected,
				fetchScreeningsConfigOld.rejected,
			),
			(state, action) => {
				setFetchErrorState(state, action)
			},
		)

		builder.addMatcher(
			isAnyOf(
				createEmployeeScreening.pending,
				createVisitorScreening.pending,
				createScreeningsConfig.pending,
				updateScreeningsConfig.pending,
				screeningConfigsBulkAction.pending,
				deleteScreeningConfig.pending,
				createScreeningsConfigOld.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				createEmployeeScreening.rejected,
				createVisitorScreening.rejected,
				createScreeningsConfig.rejected,
				updateScreeningsConfig.rejected,
				screeningConfigsBulkAction.rejected,
				deleteScreeningConfig.rejected,
				createScreeningsConfigOld.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
	},
})

export const screeningsReducer = screeningsSlice.reducer
