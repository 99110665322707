import { useMemo, useState } from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import { useToast } from "../../hooks/useToast"
import Space from "../Space"
import { InputMulti } from "../advanced/InputMulti"
import { Checkbox } from "../basic/Checkbox"
import { Input } from "../basic/Input"
import { HealthEditQuestions } from "./HealthEditQuestions"
import PageForm from "./PageFormHook"
import { setErrors } from "./formUtils"

import {
	createScreeningsConfig,
	updateScreeningsConfigNew,
} from "../../redux/screenings/screeningsSlice"
import {
	Applies_To,
	DEFAULT_QUESTIONS,
	ScreeningConfig,
	ScreeningConfigResponse,
	ScreeningsQuestion,
} from "../../redux/screenings/types"
import { useActions } from "../../redux/utils"

import Field, { UncontrolledField } from "../../components/Field"

import "./HealthSettingsForm.sass"

type FormValues = {
	id: string
	is_active: boolean
	name: string
	enabled: boolean
	applies_to: Applies_To
	auto_cancel_desk_reservation: boolean
	notify_email: string[]
	failure_msg?: string
	failure_msg_headline?: string
	introduction?: string
	introduction_headline?: string
	questions: ScreeningsQuestion[]
}

type ParamsType = {
	id: string
	building_id: string
}

type Props = {
	healthSetting?: ScreeningConfigResponse
}

const HealthSettingsForm = ({ healthSetting }: Props) => {
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()
	const { id, building_id } = useParams<ParamsType>()
	const history = useHistory()

	const actions = useActions({
		createConfig: (config: ScreeningConfig) => createScreeningsConfig(config),
		updateConfig: (config: ScreeningConfig) =>
			updateScreeningsConfigNew(config),
	})

	const {
		is_active,
		notify_email,
		applies_to,
		name,
		questions,
		auto_cancel_desk_reservation,
		introduction_headline,
		introduction,
		failure_msg,
		failure_msg_headline,
	} = healthSetting || {}

	const [appliesTo, setAppliesTo] = useState<Applies_To[]>(applies_to ?? [])

	const defaultQuestions: ScreeningsQuestion[] = useMemo(() => {
		return Object.values(DEFAULT_QUESTIONS).map((question) => {
			return {
				question: t(
					`desktop.manage.visitors.health_screening.default_questions.${question.question}` as ParseKeys,
				),
				positive_answer: t(
					`desktop.manage.visitors.health_screening.answers.${question.positive_answer}` as ParseKeys,
				),
			}
		})
	}, [t])

	const methods = useForm<FormValues>({
		defaultValues: {
			is_active,
			name: name,
			notify_email: notify_email ?? [],
			questions: questions ?? defaultQuestions,
			auto_cancel_desk_reservation: auto_cancel_desk_reservation ?? false,
			introduction_headline: introduction_headline ?? "",
			introduction: introduction ?? "",
			failure_msg: failure_msg ?? "",
			failure_msg_headline: failure_msg_headline ?? "",
		},
	})

	const { control, setError, /*watch,*/ setValue } = methods

	const onUpdateClick = async (e: FormValues) => {
		const response = await actions.updateConfig({
			id: id,
			name: e.name,
			building_id: building_id,
			applies_to: appliesTo,
			auto_cancel_desk_reservation: e.auto_cancel_desk_reservation,
			questions: e.questions,
			notify_email: e.notify_email,
			introduction: e.introduction,
			failure_msg: e.failure_msg,
			failure_msg_headline: e.failure_msg_headline,
			introduction_headline: e.introduction_headline,
		})

		if (updateScreeningsConfigNew.rejected.match(response)) {
			if (response.payload) {
				if (response.payload.hasOwnProperty("applies_to")) {
					errorToast(
						t(
							"desktop.manage.visitors.health_screening.form.select_at_least_one_filled_by",
						),
					)
				} else {
					setErrors(response.payload, setError, errorToast)
				}
			}
		} else {
			infoToast(
				t(
					"desktop.manage.visitors.health_screening.form.health_screening_updated",
				),
			)
			history.push("/settings/health")
		}
	}

	const onCreateClick = async (e: FormValues) => {
		const response = await actions.createConfig({
			is_active: false,
			name: e.name,
			building_id: building_id,
			applies_to: appliesTo,
			auto_cancel_desk_reservation: e.auto_cancel_desk_reservation,
			questions: e.questions,
			notify_email: e.notify_email,
			introduction: e.introduction,
			failure_msg: e.failure_msg,
			failure_msg_headline: e.failure_msg_headline,
			introduction_headline: e.introduction_headline,
		})

		if (createScreeningsConfig.rejected.match(response)) {
			if (response.payload) {
				if (response.payload.hasOwnProperty("applies_to")) {
					errorToast(
						t(
							"desktop.manage.visitors.health_screening.form.select_at_least_one_filled_by",
						),
					)
				} else {
					setErrors(response.payload, setError, errorToast)
				}
			}
		} else {
			infoToast(
				t(
					"desktop.manage.visitors.health_screening.form.health_screening_updated",
				),
			)
			history.push("/settings/health")
		}
	}

	const handleQuestionEdit = (questions: ScreeningsQuestion[]) => {
		setValue("questions", questions)
	}

	const questionnairesCn = classNames("questionnaires", {
		//disabled: isSpecificBuilding && !watch("is_default"), After override functionality is implemented, this can be reused again
	})

	return (
		<div className="HealthSettingsForm">
			<FormProvider {...methods}>
				<PageForm
					updateMode={!!id}
					onUpdate={onUpdateClick}
					onCreate={onCreateClick}
					backUrl="/settings/health"
				>
					<Field
						control={control}
						name="name"
						label={t(
							"desktop.manage.visitors.health_screening.form.questionnaire_name",
						)}
					>
						{(props) => (
							<>
								<p className="Subtext">
									{t(
										"desktop.manage.visitors.health_screening.form.questionnaire_name_description",
									)}
								</p>
								<Input
									{...props}
									placeholder={t(
										"desktop.manage.visitors.health_screening.form.questionnaire_name",
									)}
								/>
							</>
						)}
					</Field>
					<hr />
					<UncontrolledField
						label={t(
							"desktop.manage.visitors.health_screening.form.questionnaire_must_be_filled_by",
						)}
					>
						<p className="Subtext">
							{t(
								"desktop.manage.visitors.health_screening.form.questionnaire_must_be_filled_by_description",
							)}
						</p>
						<Space size={1} />
						<div className="CheckboxWrapper">
							<Checkbox
								label={t(
									"desktop.manage.visitors.health_screening.form.employees",
								)}
								value={appliesTo.includes(Applies_To.EMPLOYEES)}
								onChange={(value) =>
									value
										? setAppliesTo((prevState) =>
												prevState.concat(Applies_To.EMPLOYEES),
										  )
										: setAppliesTo((prevState) =>
												prevState.filter((app) => app !== Applies_To.EMPLOYEES),
										  )
								}
								isSecondary
							/>
						</div>
						<div className="CheckboxWrapper">
							<Checkbox
								label={t(
									"desktop.manage.visitors.health_screening.form.visitors",
								)}
								value={appliesTo.includes(Applies_To.VISITORS)} // This logic will have to change once BE is completed. It will be employees | visitors | employees&visitors
								onChange={(value) =>
									value
										? setAppliesTo((prevState) =>
												prevState.concat(Applies_To.VISITORS),
										  )
										: setAppliesTo((prevState) =>
												prevState.filter((app) => app !== Applies_To.VISITORS),
										  )
								}
								isSecondary
							/>
						</div>
					</UncontrolledField>
					<hr />
					<Field control={control} name="auto_cancel_desk_reservation">
						{(props) => (
							<>
								<Checkbox
									{...props}
									label={t(
										"desktop.manage.visitors.health_screening.form.auto_cancel_reservation",
									)}
									isSecondary
								/>
								<p className="Subtext field-width-50 indent">
									{t(
										"desktop.manage.visitors.health_screening.form.in_case_visitor_employee_fails_questionnaire",
									)}
								</p>
							</>
						)}
					</Field>
					<hr />
					<Field
						control={control}
						name="notify_email"
						label={t(
							"desktop.manage.visitors.health_screening.form.feedback_questionaire",
						)}
					>
						{(props) => (
							<>
								<p className="Subtext">
									{t(
										"desktop.manage.visitors.health_screening.form.employee_failure_text",
									)}
								</p>
								<InputMulti {...props} validateEmail />
							</>
						)}
					</Field>
					<hr />
					{/* Overriding will not be implemented yet.
					{isSpecificBuilding && (
						<>
							<Field control={control} name="is_default">
								{(props) => (
									<>
										<Checkbox
											{...props}
											isSecondary
											label={t(
												"desktop.manage.visitors.health_screening.form.override_questionnaire",
											)}
										/>
										<p className="Subtext field-width-50 indent">
											{t(
												"desktop.manage.visitors.health_screening.form.enable_option_to_set_different_questionnaire",
											)}
										</p>
									</>
								)}
							</Field>
							<hr />
						</>
					)} */}
					<div className={questionnairesCn}>
						<div className="field-width-100">
							<Field
								control={control}
								name="introduction_headline"
								label={t(
									"desktop.manage.visitors.health_screening.form.introduction",
								)}
							>
								{(props) => (
									<>
										<p className="Subtext">
											{t(
												"desktop.manage.visitors.health_screening.form.enter_short_headline_additional_instructions",
											)}
										</p>
										<Input
											{...props}
											placeholder={t(
												"desktop.manage.visitors.health_screening.form.introductory_headline",
											)}
										/>
									</>
								)}
							</Field>
							<Field control={control} name="introduction">
								{(props) => (
									<Input
										{...props}
										placeholder={t(
											"desktop.manage.visitors.health_screening.form.introduction",
										)}
									/>
								)}
							</Field>
						</div>
						<div className="field-width-100">
							<Field
								control={control}
								name="failure_msg_headline"
								label={t(
									"desktop.manage.visitors.health_screening.form.failed_questionnaire_message",
								)}
							>
								{(props) => (
									<>
										<p className="Subtext">
											{t(
												"desktop.manage.visitors.health_screening.form.display_failure_message",
											)}
										</p>
										<Input
											{...props}
											placeholder={t(
												"desktop.manage.visitors.health_screening.form.message_headline",
											)}
										/>
									</>
								)}
							</Field>
							<Field control={control} name="failure_msg">
								{(props) => (
									<Input
										{...props}
										placeholder={t(
											"desktop.manage.visitors.health_screening.form.additional_information",
										)}
									/>
								)}
							</Field>
						</div>
						<hr />
						<Field control={control} name="questions">
							{({ ref, ...props }) => (
								<HealthEditQuestions
									{...props}
									questions={props.value}
									onChange={handleQuestionEdit}
								/>
							)}
						</Field>
					</div>
				</PageForm>
			</FormProvider>
		</div>
	)
}

export default HealthSettingsForm
