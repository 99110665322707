import { IdAndName } from "../../types/sharedTypes"
import { AssetScheduleReservation } from "../asset_schedule/types"
import { Recurring, ScheduleReservation } from "../types"

import { AssetIconNames } from "../../components/AssetIcon"

export interface AssetReservation
	extends Omit<ScheduleReservation, "visit_id"> {
	asset: IdAndName & {
		building: IdAndName
		asset_type: IdAndName & {
			icon: AssetIconNames
		}
	}
	recurring: Recurring | null
}

export type CreateAssetReservation = {
	tz: string
	asset_id?: string
	user_email: string
} & Pick<AssetScheduleReservation, "start" | "end" | "recurring">

export type FailedRecurringAssetReservation = {
	date: string
	_error: string[]
}

export type CreatedRecurringAssetReservation = {
	id: string
	date: string
}

export type RecurringAssetReservationResponse = {
	created: CreatedRecurringAssetReservation[]
	failed: FailedRecurringAssetReservation[]
}

export const isRecurringAssetReservation = (
	response: RecurringAssetReservationResponse | AssetReservation,
): response is RecurringAssetReservationResponse => "created" in response
