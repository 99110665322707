import React, { CSSProperties, useEffect } from "react"

import { useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"
import { selectVisitorEmailSettings } from "../redux/visitor_email_settings/selectors"
import {
	VisitorEmailSettingRequest,
	VisitorEmailType,
} from "../redux/visitor_email_settings/types"
import { visitorEmailPreview } from "../redux/visitor_email_settings/visitorEmailSettingsSlice"

import Loader from "../components/basic/Loader"

import "./VisitorEmailPreviewModal.sass"

type Props = {
	open: boolean
	building_id: string
	type: VisitorEmailType
	content: VisitorEmailSettingRequest
}

const iframeContentStyle: CSSProperties = {
	...contentStyle,
	width: "640px",
	height: "100%",
	padding: "0",
	border: 0,
	overflow: "hidden",
	justifyContent: "center",
	alignItems: "center",
	display: "flex",
}

export default function VisitorEmailPreviewModal({
	open,
	building_id,
	type,
	content,
}: Props) {
	const { closeModal } = useModals()
	const { t } = useTranslation()

	const { emailPreview: visitorEmailSetting, isSubmitting } = useAppSelector(
		selectVisitorEmailSettings,
	)

	const onRequestClose = () => {
		closeModal()
	}

	const actions = useActions({
		getEmailPreview: (building_id, type, content) =>
			visitorEmailPreview({ building_id, type, content }),
	})

	useEffect(() => {
		actions.getEmailPreview(building_id, type, content)
	}, [actions, building_id, type, content])

	return (
		<ReactModal
			isOpen={open}
			style={{
				content: iframeContentStyle,
				overlay: overlayStyle,
			}}
			onRequestClose={onRequestClose}
			ariaHideApp={false}
		>
			{isSubmitting ? (
				<Loader />
			) : visitorEmailSetting ? (
				<iframe
					id="VisitorEmailPreviewIframe"
					title="Preview"
					srcDoc={visitorEmailSetting}
				/>
			) : (
				<>{t("general.not_found.standard")}</>
			)}
		</ReactModal>
	)
}
