import React, { FC, MouseEvent } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useModals } from "@mattjennings/react-modal-stack"

import { ReactComponent as FilterSVG } from "../../../assets/images/icons/Filter.svg"
import { ReactComponent as TriDownSVG } from "../../../assets/images/icons/Tri down.svg"

import "./FilterButton.sass"

type FilterButtonProps = {
	modalId: string
	children?: React.ReactNode
	onClick: (e: MouseEvent) => void
}

const FilterButton: FC<FilterButtonProps> = ({
	modalId,
	children,
	onClick,
}) => {
	const { stack } = useModals()
	const { t } = useTranslation()
	const filterClasses = classNames({
		active: stack.length > 0 && stack.find((m) => m.props.modalId === modalId),
	})

	return (
		<div className="filter-button">
			<button className={filterClasses} type="button" onClick={onClick}>
				<FilterSVG className="icon" />
				<div>{children ? children : t("mobile.general.filters")}</div>
				<TriDownSVG />
			</button>
		</div>
	)
}

export default FilterButton
