import React from "react"

import { useTranslation } from "react-i18next"

import { COUNTRY_OPTIONS } from "../../../../constants"

import Field from "../../../../components/Field"
// import { Checkbox } from "../../../../components/basic/Checkbox"
import { Input } from "../../../../components/basic/Input"
import { Select } from "../../../../components/basic/Select"

import "./CompanyDetailsForm.sass"

export type CompanyDetailsFormProps = {
	control: any
	isSubmitting: boolean
}

const CompanyDetailsForm = ({
	control,
	isSubmitting,
}: CompanyDetailsFormProps) => {
	const { t } = useTranslation()

	return (
		<div className="CompanyDetailsForm">
			<Field
				control={control}
				name={"first_name"}
				label={t("desktop.settings.billing.billing_details_form.first_name")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"last_name"}
				label={t("desktop.settings.billing.billing_details_form.last_name")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"email"}
				label={t("desktop.settings.billing.billing_details_form.email")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"company"}
				label={t("desktop.settings.billing.billing_details_form.company")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"address"}
				label={t("desktop.settings.billing.billing_details_form.address")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"zip"}
				label={t("desktop.settings.billing.billing_details_form.zip")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"city"}
				label={t("desktop.settings.billing.billing_details_form.city")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"country"}
				label={t("desktop.settings.billing.billing_details_form.country")}
			>
				{(props) => (
					<Select
						{...props}
						disabled={isSubmitting}
						options={COUNTRY_OPTIONS}
					/>
				)}
			</Field>
			<Field
				control={control}
				name={"state"}
				label={t("desktop.settings.billing.billing_details_form.state")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"phone"}
				label={t("desktop.settings.billing.billing_details_form.phone")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			<Field
				control={control}
				name={"vat_number"}
				label={t("desktop.settings.billing.billing_details_form.vat_number")}
				subText={t("general.optional")}
			>
				{(props) => <Input {...props} disabled={isSubmitting} />}
			</Field>
			{/* <Field
				className="vat-not-applicable"
				control={control}
				name={"vat_not_applicable"}
			>
				{(props) => (
					<Checkbox
						{...props}
						disabled={isSubmitting}
						isSecondary
						label={t(
							"desktop.settings.billing.billing_details_form.vat_not_applicable",
						)}
					/>
				)}
			</Field> */}
		</div>
	)
}

export default CompanyDetailsForm
