import React, { useEffect } from "react"

import { Trans } from "react-i18next"

import RoomSettingsDetails from "./RoomSettingsDetails"
import RoomsSettingsForm from "./RoomsSettingsForm"
import RoomsSubscriptions from "./RoomsSubscription"

import { fetchExperiments } from "../../../../redux/experiments/experimentsSlice"
import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { useActions } from "../../../../redux/utils"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const RoomsOverview = () => {
	const actions = useActions({
		fetchExperiments: () => fetchExperiments(),
	})

	useEffect(() => {
		actions.fetchExperiments()
	}, [actions])

	const { isLoaded } = useAppSelector(selectSettingsEffective)

	return (
		<View className="SettingsPage RoomsOverviewPage">
			<Breadcrumbs depth={2} includeParamsAsPath />
			<Intro isConstrained>
				<Trans i18nKey="desktop.settings.rooms.overview._intro">
					<a href="/" target="_blank" rel="noopener noreferrer">
						link
					</a>
				</Trans>
			</Intro>
			<Space size={1} />
			{isLoaded && (
				<div className="RoomsOverviewLayout">
					<div className="RoomsOverviewSettings">
						<RoomsSettingsForm />
						<RoomSettingsDetails />
					</div>
					<div className="RoomsOverviewSubscriptions">
						<RoomsSubscriptions />
					</div>
				</div>
			)}
		</View>
	)
}

export default RoomsOverview
