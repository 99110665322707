import React, { useCallback, useState } from "react"

import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBackendPagination } from "../../../../hooks/useBackendPagination"
import { nameComparator, parseQueryWithDefault } from "../../../../utils"

import { useFetchAmenitiesQuery } from "../../../../redux/api/amenities"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Card from "../../../../components/Card"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import Filters from "../../../../components/Filter/Filters"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import Intro from "../../../../components/Intro"
import Pagination from "../../../../components/Pagination"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import Button from "../../../../components/basic/Button"
import Divider from "../../../../components/basic/Divider"

import "./style.sass"

const { stringify } = queryString

const AMENITIES_PATHNAME = "/settings/desks/amenities"

const ENTRIES_PER_PAGE = 8

function Amenities() {
	const { t } = useTranslation()
	const history = useHistory()

	const { search } = history.location

	const { page: queryPage, search: querySearch } = parseQueryWithDefault(
		search,
		{
			search: "",
			page: 1,
		},
	)

	const [page, setCurrentPage] = useState(queryPage)
	const [searchText, setSearchQuery] = useState(querySearch)

	const {
		data: { count = 0, offset = 0, results: amenities = [] } = {},
		refetch: refetchAmenities,
	} = useFetchAmenitiesQuery({
		offset: (page - 1) * ENTRIES_PER_PAGE,
		limit: ENTRIES_PER_PAGE,
		search: searchText,
	})

	const { from, to, hasNext, hasPrevious, paginationLinks } =
		useBackendPagination({
			offset,
			totalNumberOfItems: count,
			entriesPerPage: ENTRIES_PER_PAGE,
			maxLinks: 3,
			maxTrailingLinks: 2,
		})

	const setPage = useCallback(
		(nextPage: number) => {
			history.push(
				`${AMENITIES_PATHNAME}?${stringify({
					page: nextPage,
					search: searchText,
				})}`,
			)
			setCurrentPage(nextPage)
			refetchAmenities()
		},
		[history, searchText, refetchAmenities],
	)

	const handleSearchChange = useCallback(
		(search: string) => {
			history.push(
				`${AMENITIES_PATHNAME}?${stringify({
					page: 1,
					search,
				})}`,
			)
			setCurrentPage(1)
			setSearchQuery(search)
			refetchAmenities()
		},
		[history, refetchAmenities],
	)

	function handleEditClick(id: string) {
		const amenity = amenities.find((a) => a.id === id)
		history.push(`/settings/desks/amenities/edit/${id}`, {
			amenity,
			query: stringify({
				page: page,
				search: searchText,
			}),
		})
	}

	function handleNewClick() {
		history.push("/settings/desks/amenities/add", {
			query: stringify({
				page: page,
				search: searchText,
			}),
		})
	}

	return (
		<View className="Amenities">
			<Breadcrumbs
				depth={2}
				values={[
					t("desktop.settings.desks.title"),
					t("desktop.settings.amenities.title"),
				]}
			/>

			<Intro isConstrained>{t("desktop.settings.amenities.subtitle")}</Intro>

			<Space size={0.75} />

			<Filters>
				<SearchFilter
					value={searchText}
					onChange={handleSearchChange}
					placeholder={t("desktop.settings.amenities.search")}
				/>
				<FilterSpace />
				<Button onClick={(e) => handleNewClick()} isSmall>
					{t("desktop.settings.amenities.form.new_amenity")}
				</Button>
			</Filters>

			<Space size={0.75} />

			<Card className="amenities">
				<div className="amenities-padding">
					{amenities.length === 0 && (
						<p>{t("desktop.settings.amenities.no_amenities")}</p>
					)}

					{amenities
						.slice(0)
						.sort(nameComparator)
						.map(({ id, name }, i: number) => (
							<p key={`amenity-${i}`}>
								<strong className="name">{name}</strong>

								<Button
									onClick={(e) => handleEditClick(id)}
									variant="secondary"
								>
									{t("desktop.settings.amenities.form.edit_amenity")}
								</Button>
							</p>
						))}
				</div>

				<Divider hasMargin={false} />

				<div className="pagination">
					<Pagination
						links={paginationLinks}
						setPage={setPage}
						onPrevious={() => setPage(page - 1)}
						onNext={() => setPage(page + 1)}
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						from={from}
						to={to}
						total={count}
						items={t("desktop.settings.amenities.amenities", {
							count,
						})}
						className="amenity-pagination"
					/>
				</div>
			</Card>
		</View>
	)
}

export default Amenities
