import { ThunkApiConfig } from "RootType"

import {
	documentURL,
	documentsBulkURL,
	documentsURL,
	get,
	patchJSON,
	postJSON,
	putJSON,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
	SliceState,
	getErrorMessage,
	getErrorObject,
	setFetchErrorState,
	setFetchSuccessState,
	setSubmitErrorState,
	setSubmitSuccessState,
	sliceInitialState,
} from "../reduxUtils"
import {
	DocumentBulkActionRequest,
	DocumentRequest,
	DocumentResponse,
	DocumentsResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchDocuments = createAsyncThunk<
	DocumentsResponse,
	void,
	ThunkApiConfig
>("documents/fetch", async (_, { getState }) => {
	const {
		auth: { access_token },
	} = getState()
	const response = await get(documentsURL(), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const fetchDocument = createAsyncThunk<
	DocumentResponse,
	string,
	ThunkApiConfig
>("document/fetch", async (id, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await get(documentURL(id), {}, access_token)

	if (response.ok) {
		return await response.json()
	}

	throw new Error(await getErrorMessage(response))
})

export const createDocument = createAsyncThunk<
	DocumentResponse,
	DocumentRequest,
	ThunkApiConfig<ResponseError>
>("document/create", async (body, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await postJSON(
		documentsURL(),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		const json = await response.json()

		return json
	}

	return rejectWithValue(await getErrorObject(response))
})

export interface UpdateDocumentProps extends DocumentRequest {
	id: string
}

export const updateDocument = createAsyncThunk<
	DocumentResponse,
	UpdateDocumentProps,
	ThunkApiConfig<ResponseError>
>("document/update", async ({ id, ...body }, { getState, rejectWithValue }) => {
	const {
		auth: { access_token },
	} = getState()

	const response = await putJSON(
		documentURL(id),
		{
			body,
		},
		access_token,
	)

	if (response.ok) {
		const json = await response.json()

		return json
	}

	return rejectWithValue(await getErrorObject(response))
})

export const documentBulkAction = createAsyncThunk<
	void,
	DocumentBulkActionRequest,
	ThunkApiConfig<ResponseError>
>("documents/bulk", async (body, { getState }) => {
	const {
		auth: { access_token },
	} = getState()

	await patchJSON(
		documentsBulkURL(),
		{
			body,
		},
		access_token,
	)
})

export interface DocumentsState extends SliceState {
	entries: DocumentResponse[]
}

const initialState: DocumentsState = {
	entries: [],
	...sliceInitialState,
}

const documentsSlice = createSlice({
	name: "documents",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			state.entries = payload.results
		})
		builder.addCase(fetchDocument.fulfilled, (state, { payload }) => {
			setFetchSuccessState(state)
			const remain = state.entries.filter((e) => e.id !== payload.id)
			state.entries = [...remain, payload]
		})
		builder.addCase(createDocument.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			state.entries = [...state.entries, payload]
		})
		builder.addCase(updateDocument.fulfilled, (state, { payload }) => {
			setSubmitSuccessState(state)
			const remain = state.entries.filter((e) => e.id !== payload.id)
			state.entries = [...remain, payload]
		})
		builder.addCase(documentBulkAction.fulfilled, (state) => {
			setSubmitSuccessState(state)
		})
		builder.addMatcher(
			isAnyOf(fetchDocument.pending, fetchDocuments.pending),
			(state) => {
				state.isLoading = true
			},
		)
		builder.addMatcher(
			isAnyOf(
				createDocument.pending,
				updateDocument.pending,
				documentBulkAction.pending,
			),
			(state) => {
				state.isSubmitting = true
			},
		)
		builder.addMatcher(
			isAnyOf(fetchDocument.rejected, fetchDocuments.rejected),
			(state, action) => {
				setFetchErrorState(state, action)
			},
		)
		builder.addMatcher(
			isAnyOf(
				createDocument.rejected,
				updateDocument.rejected,
				documentBulkAction.rejected,
			),
			(state, action) => {
				setSubmitErrorState(state, action)
			},
		)
	},
})

export const documentsReducer = documentsSlice.reducer
