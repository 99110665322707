import React, { useEffect } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { RouteComponentProps } from "react-router-dom"

import { VISITOR_ONBOARDING_BASE_URL } from "../Visitors"
import { Device } from "@capacitor/device"

import { appError } from "../../../redux/app/appSlice"
import { registerTablet } from "../../../redux/tablet/tabletSlice"
import {
	RegisterTabletRequest,
	TabletConnectionType,
} from "../../../redux/tablet/types"
import { useActions } from "../../../redux/utils"

import VisitorContent from "../../../components/Visitors/VisitorContent"
import VisitorFooter from "../../../components/Visitors/VisitorFooter"
import VisitorView from "../../../components/Visitors/VisitorView"
import Button from "../../../components/basic/Button"

import "./Pin.sass"

type Params = {
	pin: string
}

const Pin = ({ match }: RouteComponentProps<Params>) => {
	const { params } = match || {}
	const { pin } = params || {}

	const { t } = useTranslation()
	const history = useHistory()

	const actions = useActions({
		appError: (message: string) => appError(message),
		registerTablet: (body: RegisterTabletRequest) => registerTablet(body),
	})

	useEffect(() => {
		const timer = setInterval(() => {
			Device.getId().then(async (deviceId) => {
				const response = await actions.registerTablet({
					device_id: deviceId.identifier,
				})

				if (registerTablet.fulfilled.match(response)) {
					const tablet = response.payload

					if (tablet.connection_status === TabletConnectionType.PAIRED) {
						clearInterval(timer)
						history.push(`${VISITOR_ONBOARDING_BASE_URL}/confirmation`)
					}
				} else {
					actions.appError(response.error.message ?? t("tablet.general_error"))
				}
			})
		}, 5000)
		return () => clearInterval(timer)
	}, [t, actions, history])

	const handleGoBack = () => history.push(VISITOR_ONBOARDING_BASE_URL)

	return (
		<VisitorView className="Pin">
			<VisitorContent>
				<div className="info">
					{t(
						"tablet.visitors.onboarding.screens.pin.to_connect_tablet_to_your_account",
					)}
				</div>
				<div className="pin">{pin}</div>
			</VisitorContent>
			<VisitorFooter>
				<div className="info">
					<Trans
						i18nKey={
							"tablet.visitors.onboarding.screens.pin.go_to_visitors_and_pair"
						}
					/>
				</div>
				<Button variant="link" onClick={handleGoBack}>
					{t("general.redirection.go_back")}
				</Button>
			</VisitorFooter>
		</VisitorView>
	)
}

export default Pin
