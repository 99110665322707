import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import ShareFloorForm from "../components/Form/ShareFloorForm"

import "./ShareFloorModal.sass"

type Props = {
	open: boolean
	floorId: string
	onAccessActivityClick: (shareableId: string) => void
}

export default function ShareFloorModal({
	open,
	floorId,
	onAccessActivityClick,
}: Props) {
	const { closeModal } = useModals()

	return (
		<ReactModal
			isOpen={open}
			style={{ content: contentStyle, overlay: overlayStyle }}
			onRequestClose={() => closeModal()}
			ariaHideApp={false}
		>
			<ShareFloorForm
				floorId={floorId}
				onAccessActivityClick={onAccessActivityClick}
			/>
		</ReactModal>
	)
}
