import {
	ChangeEvent,
	HTMLProps,
	SyntheticEvent,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"

import classNames from "classnames"

import { PRIMARY_COLOR } from "../../../constants"
import { COLOR_HEX_REGEX } from "../../../utils"

import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

type CommonProps = {
	value?: string
	className?: string
	onChange?: (value: string) => void
	hasError?: boolean
	resettable?: boolean
}

type ConditionalProps = HTMLProps<HTMLInputElement>

export type ColorPickerProps = CommonProps & Omit<ConditionalProps, "onChange">

export const ColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(
	(
		{ className, onChange, value, hasError, resettable = true, ...props },
		ref,
	) => {
		const hiddenColorPicker = useRef<HTMLInputElement>(null)
		const [color, setColor] = useState(value ?? "")

		const innerOnChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				onChange && onChange(e.target.value)
				if (COLOR_HEX_REGEX.test(e.target.value)) {
					setColor(e.target.value)
				}
			},
			[onChange],
		)

		const openColorPicker = (e: SyntheticEvent) => {
			hiddenColorPicker.current?.click()
		}

		useEffect(() => {
			if (value && COLOR_HEX_REGEX.test(value)) {
				setColor(value)
			}
		}, [value])

		return (
			<div
				className={classNames([
					"ColorPicker",
					className,
					{
						disabled: !!props.disabled,
						error: hasError,
					},
				])}
			>
				<div
					onClick={openColorPicker}
					className="color-display"
					style={{ backgroundColor: color }}
				>
					<input
						className="HiddenColorPickerInput"
						onChange={innerOnChange}
						ref={hiddenColorPicker}
						type="color"
					/>
				</div>
				<input
					{...(props as HTMLProps<HTMLInputElement>)}
					onChange={innerOnChange}
					ref={ref}
					maxLength={7}
					value={value}
				/>
				{resettable && color !== PRIMARY_COLOR && (
					<div
						className="reset-color"
						onClick={() => {
							onChange && onChange(PRIMARY_COLOR)
							setColor(PRIMARY_COLOR)
						}}
					>
						<CrossSVG />
					</div>
				)}
			</div>
		)
	},
)
