import { useEffect } from "react"

import { RouteProps, Route as RouterRoute, useLocation } from "react-router"

import { useScrollToTop } from "../hooks/useScrollToLocation"
import NotPaid from "../screens/NotPaid"

import { selectIsMobile } from "../redux/app/selectors"
import { Feature, setHasPaid } from "../redux/payment/paymentSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

export type RoutePropsWithFeature = RouteProps & { feature?: Feature }

const Route = ({ feature, ...props }: RoutePropsWithFeature) => {
	const isMobile = useAppSelector(selectIsMobile)
	const { locked } = useAppSelector((state) => state.payment)

	const { pathname } = useLocation()

	useScrollToTop([pathname])

	const actions = useActions({
		setHasPaid: () => setHasPaid(null),
	})

	/**
	 * The state is reset when the pathname changes. This means that
	 * when a user changes the URL, any 402 fetch will retrigger the
	 * not paid overview screen.
	 *
	 * This gives backend the ability to lock individual screens if
	 * they need to.
	 */
	useEffect(() => {
		actions.setHasPaid()
	}, [pathname, actions])

	const isFeatureLocked = feature && locked[feature]

	if (isFeatureLocked) {
		return (
			<RouterRoute
				{...props}
				component={() => <NotPaid onlyView={isMobile} />}
			/>
		)
	}

	return <RouterRoute {...props} />
}

export default Route
