import React from "react"

import Options from "../basic/Options"
import { Field } from "./Field"

import { DeleteType } from "../../redux/reservations/types"

import "./DeleteTypeField.sass"

const deleteTypeOptions = [
	{
		value: DeleteType.CURRENT,
		label: "This reservation",
	},
	{
		value: DeleteType.CURRENT_FUTURE,
		label: "This and future reservations",
	},
]

type Props = {
	value: DeleteType
	onChange: (v: DeleteType) => void
}

/**
 * @deprecated
 */
export const DeleteTypeField = ({ value, onChange }: Props) => {
	return (
		<Field className="DeleteTypeField">
			<Options
				options={deleteTypeOptions}
				value={value}
				onChange={(v: string) => onChange(v as DeleteType)}
			/>
		</Field>
	)
}
