import React, { FC, useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { useMediaQuery } from "../../hooks/useMediaQuery"
import LogoURL from "../LogoURL"

import DateString from "../../components/Datestring"

import "./ShareableHeader.sass"

type ShareableHeaderProps = {
	buildingName: string
	floorName: string
	noOfBookedDesks: number
	logoURL?: string
}

const TIME_SYNC_INTERVAL = 10000

const ShareableHeader: FC<ShareableHeaderProps> = ({
	buildingName,
	floorName,
	noOfBookedDesks,
	logoURL,
}) => {
	const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs())

	const { t, i18n } = useTranslation()
	const isBigScreen = useMediaQuery("(min-width: 1400px)")

	const time = currentTime.format("LT")

	useEffect(() => {
		const timeSyncInterval = setInterval(() => {
			setCurrentTime(dayjs())
		}, TIME_SYNC_INTERVAL)
		return () => {
			if (timeSyncInterval) {
				clearInterval(timeSyncInterval)
			}
		}
	}, [])

	return (
		<div className="ShareableHeader">
			<div className="logo-wrapper">
				<LogoURL url={logoURL} width={360} height={54} />
			</div>
			<div className="element">{buildingName}</div>
			<div className="element">{floorName}</div>
			<div className="element">
				{t("desktop.shareable.shareable_header_booked_desks")}・
				{noOfBookedDesks}
			</div>
			<div className="end-element">
				<DateString
					className="element"
					date={currentTime}
					isLong={isBigScreen}
					isInline
					isPluralShown={i18n.language.startsWith("en")}
				/>
				<div className="element time strong">{time}</div>
			</div>
		</div>
	)
}
export default ShareableHeader
