import React from "react"

import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { BILLING_PATHNAMES } from "../constants"
import CompanyDetailsFrom from "./CompanyDetailsForm"
import { STEPS } from "./constants"
import {
	CompanyDetailsFormValues,
	useCompanyDetailsForm,
} from "./useCompanyDetailsForm"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import PageForm from "../../../../components/Form/PageFormHook"
import View from "../../../../components/View"
import Loader from "../../../../components/basic/Loader"
import Stepper from "../../../../components/basic/Stepper"

import "./CompanyDetailsFormPage.sass"

const CompanyDetailsFormPage = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { isUpdateMode, onUpdateClick, onCreateClick, methods, isLoaded } =
		useCompanyDetailsForm()
	const {
		control,
		formState: { isSubmitting },
	} = methods
	const handleUpdateClick = async (value: CompanyDetailsFormValues) => {
		const success = await onUpdateClick(value)
		if (success) {
			history.push(BILLING_PATHNAMES.overview.paymentMethod)
		}
	}
	const handleCreateClick = async (value: CompanyDetailsFormValues) => {
		const success = await onCreateClick(value)
		if (success) {
			history.push(BILLING_PATHNAMES.overview.paymentMethod)
		}
	}

	return (
		<View className="CompanyDetailsPage">
			<Breadcrumbs
				depth={2}
				includeParamsAsPath
				values={[
					t("desktop.settings.billing.title"),
					t("desktop.settings.billing.billing_details_form.title"),
				]}
			/>

			<Stepper steps={STEPS} currentStep="details" onClick={() => {}} />
			{!isLoaded ? (
				<Loader variant="fullScreen" />
			) : (
				<FormProvider {...methods}>
					<PageForm
						className="CompanyDetailsPageForm"
						updateMode={isUpdateMode}
						onUpdate={handleUpdateClick}
						onCreate={handleCreateClick}
						disabled={isSubmitting}
						backUrl={BILLING_PATHNAMES.overview.root}
						submitButtonText={t("general.next")}
					>
						<CompanyDetailsFrom control={control} isSubmitting={isSubmitting} />
					</PageForm>
				</FormProvider>
			)}
		</View>
	)
}

export default CompanyDetailsFormPage
