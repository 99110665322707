import React from "react"

import { useTranslation } from "react-i18next"

import { TopNav } from "./TopNav"

import { ReactComponent as CrossSVG } from "../../assets/images/icons/Cross.svg"
import { ReactComponent as DeleteSVG } from "../../assets/images/icons/Delete.svg"
import { ReactComponent as PencilSVG } from "../../assets/images/icons/Pencil.svg"

import "./ReservationOptionMenu.sass"

type ReservationOptionMenuProps = {
	onEdit: () => void
	onDelete: () => void
	onClose: () => void
}

export const ReservationOptionMenu: React.FC<ReservationOptionMenuProps> = ({
	onEdit,
	onDelete,
	onClose,
}) => {
	const { t } = useTranslation()

	return (
		<div className="ReservationOptionMenu">
			<div className="picker-content">
				<div className="section-head">
					<TopNav
						backArrow={false}
						title={t("mobile.general.options")}
						titleCenter={true}
						rightIcon={
							<CrossSVG
								onClick={() => {
									onClose()
								}}
							/>
						}
						onTopScreen={false}
					/>
				</div>
				<div
					className="section"
					onClick={() => {
						onEdit()
					}}
				>
					<div className="option">
						<div className="option-icon">
							<PencilSVG />
						</div>
						<div className="option-name">{t("mobile.general.edit")}</div>
					</div>
				</div>
				<div
					className="section"
					onClick={() => {
						onDelete()
					}}
				>
					<div className="option">
						<div className="option-icon">
							<DeleteSVG />
						</div>
						<div className="option-name">{t("mobile.general.delete")}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
