const VISITORS_ROOT_PATH = "/settings/visitors"

export const VISITORS_PATHS = {
	root: VISITORS_ROOT_PATH,
	documents: `${VISITORS_ROOT_PATH}/documents`,
	documentsEdit: `${VISITORS_ROOT_PATH}/documents/edit/:id?`,
	documentsAdd: `${VISITORS_ROOT_PATH}/documents/add`,
	devices: `${VISITORS_ROOT_PATH}/devices`,
	deviceDetail: `${VISITORS_ROOT_PATH}/devices/:id`,
	deviceSettings: `${VISITORS_ROOT_PATH}/device-settings/:device_type`,
	general: `${VISITORS_ROOT_PATH}/general`,
	emailSettings: `${VISITORS_ROOT_PATH}/general/:building_id/emails/:type`,
} as const
