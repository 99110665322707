import React from "react"

import { Redirect, Route, Switch } from "react-router-dom"

import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import NotFound from "../NotFound"
import Assets from "./Assets/Assets"
import Billing from "./Billing"
import { BILLING_PATHNAMES } from "./Billing/constants"
import Branding from "./Branding"
import Buildings from "./Buildings"
import Departments from "./Departments/Departments"
import EditDepartments from "./Departments/EditDepartments"
import { DEPARTMENT_PATHS } from "./Departments/constants"
import Desks from "./Desks"
import { DESKS_PATHS } from "./Desks/constants"
import FloorPlans from "./FloorPlans"
import Health from "./Health"
import HealthSettings from "./HealthSettings"
import People from "./People"
import Profile from "./Profile"
import Rooms from "./Rooms"
import RoomsOverview from "./Rooms/Overview"
import { ROOMS_PATHS } from "./Rooms/constants"
import { SettingsNav } from "./SettingsNav"
import Visitors from "./Visitors"
import { VISITORS_PATHS } from "./Visitors/constants"

import { useAppSelector } from "../../redux/reducers"
import { selectUserPermissions } from "../../redux/user/selectors"

import ProtectedRoute from "../../components/ProtectedRoute"
import RouteView from "../../components/RouteView"
import Screen from "../../components/Screen"

function Settings() {
	const permissions = useAppSelector(selectUserPermissions)

	const isPortalMergerEnabled = useCheckForFeatureFlag("portalMerger.isEnabled")

	const canEditFloor = permissions.includes("portal.locations.change_floor")

	return (
		<RouteView className="Settings">
			<Screen sidebar={<SettingsNav />}>
				<Switch>
					<ProtectedRoute
						exact
						path="/settings/buildings"
						accessPermission="portal.locations.change_building"
						component={Buildings}
					/>
					<ProtectedRoute
						exact
						path="/settings/buildings/:id"
						accessPermission="portal.locations.change_building"
						component={Buildings}
					/>
					<ProtectedRoute
						exact
						path="/settings/floor-plans"
						accessPermission="portal.locations.change_floor"
						component={FloorPlans}
					/>
					<ProtectedRoute
						exact
						path={DEPARTMENT_PATHS.root}
						accessPermission="portal.locations.change_department"
						component={Departments}
					/>
					<ProtectedRoute
						exact
						path={`${DEPARTMENT_PATHS.edit}/:department_id`}
						accessPermission="portal.locations.change_department"
						component={EditDepartments}
					/>
					<ProtectedRoute
						exact
						path={DEPARTMENT_PATHS.add}
						accessPermission="portal.locations.change_department"
						component={EditDepartments}
					/>
					<ProtectedRoute path={DESKS_PATHS.root} component={Desks} />

					{isPortalMergerEnabled ? (
						<ProtectedRoute
							path={ROOMS_PATHS.root}
							component={Rooms}
							accessFeatureFlag="portalMerger.isEnabled"
						/>
					) : (
						<ProtectedRoute
							exact
							path={ROOMS_PATHS.root}
							accessPermission="desk.change_deskresource"
							component={RoomsOverview}
						/>
					)}

					<ProtectedRoute
						exact
						path="/settings/assets"
						accessPermission="portal_resources.change_amenity"
						component={Assets}
					/>

					<ProtectedRoute
						exact
						accessPermission="users.change_user"
						path="/settings/people"
						component={People}
					/>

					<ProtectedRoute
						exact
						path="/settings/health"
						accessPermission="desk.change_screeningconfig"
						component={Health}
					/>
					<ProtectedRoute
						exact
						path="/settings/health/edit/:id?"
						accessPermission="desk.change_screeningconfig"
						component={HealthSettings}
					/>
					<ProtectedRoute
						exact
						path="/settings/health/new/:building_id?"
						accessPermission="desk.change_screeningconfig"
						component={HealthSettings}
					/>
					<ProtectedRoute path={VISITORS_PATHS.root} component={Visitors} />
					<ProtectedRoute
						exact
						path="/settings/organization/branding"
						accessPermission="users.change_user"
						component={Branding}
					/>
					<ProtectedRoute
						exact
						path="/settings/organization"
						accessPermission="users.change_user"
						render={() => <Redirect to={"/settings/organization/branding"} />}
					/>
					<ProtectedRoute
						path={BILLING_PATHNAMES.root}
						component={Billing}
						accessFeatureFlag="portalMerger.isEnabled"
					/>
					<Route exact path="/settings/profile" component={Profile} />
					{/*
						------------------
						TODO: after completing JOAN-5317 remove redirects
					*/}
					<Route
						exact
						path="/settings/desks"
						render={() => <Redirect to={DESKS_PATHS.layout} />}
					/>
					<Route
						exact
						path="/settings/general"
						render={() => <Redirect to={DESKS_PATHS.general} />}
					/>
					<Route
						exact
						path="/settings/time-slots)"
						render={() => <Redirect to={DESKS_PATHS.timeSlots.default} />}
					/>
					<Route
						exact
						path="/settings/amenities"
						render={() => <Redirect to={DESKS_PATHS.amenities.default} />}
					/>
					{/*
						------------------
					*/}
					<Route
						exact
						path="/settings"
						render={() => (
							<Redirect
								to={
									canEditFloor ? "/settings/floor-plans" : "/settings/profile"
								}
							/>
						)}
					/>
					<Route render={() => <NotFound onlyView />} />
				</Switch>
			</Screen>
		</RouteView>
	)
}

export default Settings
