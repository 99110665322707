import { MouseEvent, useCallback } from "react"

import { t as translate } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../hooks/useToast"
import { isInternalTime } from "../../utils"
import Field from "../Field"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import { TimeRange, TimeRangePicker } from "../basic/TimeRangePicker"
import PageForm from "./PageFormHook"
import { setErrors } from "./formUtils"

import {
	useCreateTimeslotMutation,
	useDeleteTimeslotMutation,
	useFetchTimeslotsQuery,
	useUpdateTimeslotMutation,
} from "../../redux/api/timeslots"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { useAppSelector } from "../../redux/reducers"
import { TimeslotResponse } from "../../redux/timeslots/types"
import { selectUserPermissions } from "../../redux/user/selectors"

import "./SlotForm.sass"

export const DEFAULT_NAME = translate(
	"desktop.settings.timeslots.form.default_name",
)
export const DEFAULT_START_TIME = "09:00"
export const DEFAULT_END_TIME = "17:00"

const FORM_MAPPING = {
	to: "timeRange",
	from: "timeRange",
} as const

type Props = {
	slot: Partial<TimeslotResponse>
}

type FormValues = {
	name: string
	is_default: boolean
	timeRange: TimeRange
}

const SlotForm = ({ slot }: Props) => {
	const { t } = useTranslation()
	const { infoToast, errorToast } = useToast()
	const history = useHistory()

	const [createTimeslot] = useCreateTimeslotMutation()
	const [updateTimeslot] = useUpdateTimeslotMutation()
	const [destroyTimeslot] = useDeleteTimeslotMutation()

	const { id, name, from, to, is_default, active } = slot || {}

	const methods = useForm<FormValues>({
		defaultValues: {
			name: name ?? DEFAULT_NAME,
			is_default: is_default ?? false,
			timeRange: {
				start: from && isInternalTime(from) ? from : DEFAULT_START_TIME,
				end: to && isInternalTime(to) ? to : DEFAULT_END_TIME,
			},
		},
	})

	const {
		setError,
		control,
		formState: { isSubmitting },
	} = methods

	const { data: slots = [] } = useFetchTimeslotsQuery()

	const onCreateClick = async ({ name, is_default, timeRange }: FormValues) => {
		const response = await createTimeslot({
			name,
			from: timeRange.start as string,
			to: timeRange.end as string,
			is_default,
			active: true,
			type: "desk",
		})

		if (isRejected(response)) {
			const { error } = response
			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast, FORM_MAPPING)
				return
			}
		}
		infoToast(t("desktop.settings.timeslots.form.timeslot_created_toast"))
		history.push("/settings/desks/time-slots")
	}

	const onUpdateClick = async ({ name, is_default, timeRange }: FormValues) => {
		if (!id) return

		const response = await updateTimeslot({
			id,
			name,
			from: timeRange.start as string,
			to: timeRange.end as string,
			is_default,
			active: active ?? true,
			type: "desk",
		})

		if (isRejected(response)) {
			const { error } = response
			if (isApiResponseError(error)) {
				setErrors(error.formError, setError, errorToast, FORM_MAPPING)
				return
			}
		}
		infoToast(t("desktop.settings.timeslots.form.timeslot_updated_toast"))
		history.push("/settings/desks/time-slots")
	}

	const onDeleteClick = useCallback(
		async (e: MouseEvent) => {
			e.preventDefault()

			if (!id) return

			const response = await destroyTimeslot(id)

			if (isRejected(response)) {
				const { error } = response
				if (isApiResponseError(error)) {
					setErrors(error.formError, setError, errorToast, FORM_MAPPING)
					return
				}
			}
			infoToast(t("desktop.settings.timeslots.form.timeslot_deleted_toast"))
			history.push("/settings/desks/time-slots")
		},
		[id, history, destroyTimeslot, setError, infoToast, t, errorToast],
	)

	const permissions = useAppSelector(selectUserPermissions)
	const canEdit = permissions.includes("desk.change_timeslot")
	const canDelete = canEdit && slots.length > 1

	return (
		<FormProvider {...methods}>
			<PageForm
				className="SlotForm"
				updateMode={!!id}
				onCreate={onCreateClick}
				onUpdate={onUpdateClick}
				onDelete={canDelete ? onDeleteClick : undefined}
				disabled={!canEdit}
				backUrl="/settings/desks/time-slots"
			>
				<Field
					control={control}
					name="timeRange"
					label={t("desktop.settings.timeslots.form.time")}
				>
					{(props) => (
						<TimeRangePicker autoFocus {...props} disabled={isSubmitting} />
					)}
				</Field>
				<Field
					control={control}
					name="name"
					label={t("desktop.settings.timeslots.form.name")}
				>
					{(props) => (
						<Input maxLength={30} disabled={isSubmitting} {...props} />
					)}
				</Field>
				<Field control={control} name="is_default">
					{(props) => (
						<Switch
							{...props}
							disabled={isSubmitting}
							label={t("desktop.settings.timeslots.default_timeslot")}
						/>
					)}
				</Field>
			</PageForm>
		</FormProvider>
	)
}

export default SlotForm
