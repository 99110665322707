import { useEffect, useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"
import { useModals } from "@mattjennings/react-modal-stack"

import {
	documentBulkAction,
	fetchDocuments,
} from "../../../redux/documents/documentsSlice"
import { selectDocuments } from "../../../redux/documents/selectors"
import {
	DocumentBulkAction,
	DocumentBulkActionRequest,
	DocumentResponse,
	DocumentStatusType,
} from "../../../redux/documents/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Breadcrumbs from "../../../components/Breadcrumbs"
import FilterSpace from "../../../components/Filter/FilterSpace"
import Filters from "../../../components/Filter/Filters"
import SearchFilter from "../../../components/Filter/SearchFilter"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"
import { ConfirmationModal } from "../../../components/advanced/ConfirmationModal"
import Table from "../../../components/advanced/Table"
import Button from "../../../components/basic/Button"
import MultiActionButton from "../../../components/basic/MultiActionButton"
import { Action, Column } from "../../../components/basic/Table"

import { ReactComponent as CheckSVG } from "../../../assets/images/icons/Check.svg"
import { ReactComponent as CrossSVG } from "../../../assets/images/icons/Cross.svg"
import { ReactComponent as DocumentSVG } from "../../../assets/images/icons/Document.svg"

import "./Documents.sass"

const DocumentsEmptyTableRows = () => {
	const { t } = useTranslation()
	return (
		<div className="DocumentsTableNoView">
			<div className="DocumentsTableNoViewTop">
				<DocumentSVG />
				<p>{t("desktop.settings.visitors.documents.no_documents.title")}</p>
			</div>
			<div className="DocumentsTableNoViewBottom">
				{t("desktop.settings.visitors.documents.no_documents.description")}
				<Button variant="link" to="/settings/visitors/documents/edit">
					{t(
						"desktop.settings.visitors.documents.no_documents.description_link",
					)}
				</Button>
			</div>
		</div>
	)
}

const formatDocumentDate = (date: string) =>
	dayjs(date).format("ddd, DD MMM, YYYY")

type DocumentStatusProps = {
	document: DocumentResponse
}
const DocumentStatus = ({ document }: DocumentStatusProps) => {
	const { t } = useTranslation()

	const statusClass = classNames(
		"DocumentStatus",
		document.status.toLocaleLowerCase(),
	)

	return (
		<span className={statusClass}>
			{t(
				`desktop.settings.visitors.documents.table.${document.status.toLocaleLowerCase()}` as ParseKeys,
			)}
		</span>
	)
}

function Documents() {
	const { t } = useTranslation()
	const history = useHistory()

	const { errorToast, infoToast } = useToast()

	const { openModal, closeModal } = useModals()

	const actions = useActions({
		fetchDocuments: () => fetchDocuments(),
		documentBulkAction: (body: DocumentBulkActionRequest) =>
			documentBulkAction(body),
	})

	useEffect(() => {
		actions.fetchDocuments()
	}, [actions])

	const bulkActionOptions = useMemo<OptionType<DocumentBulkAction>[]>(
		() =>
			Object.values(DocumentBulkAction).map((action) => ({
				label: t(
					`desktop.settings.visitors.documents.actions.${action.toLocaleLowerCase()}` as ParseKeys,
				),
				value: action,
			})),
		[t],
	)

	// For bulk actions / actions on selected rows
	const [selectedRows, setSelectedRows] = useState<DocumentResponse[]>([])

	// For searching documents
	const [search, setSearch] = useState<string>("")

	const { entries: documents, isLoading: areDocumentsLoading } =
		useAppSelector(selectDocuments)

	// Filter documents by search
	const filteredDocuments = useMemo<DocumentResponse[]>(() => {
		if (!search) {
			return documents
		}
		return documents.filter((document) =>
			document.title.toLowerCase().includes(search.toLowerCase()),
		)
	}, [search, documents])

	const tableColumns = useMemo<Column<DocumentResponse>[]>(
		() => [
			{
				field: "title",
				label: t("desktop.settings.visitors.documents.table.name"),
			},
			{
				field: "created_at",
				label: t("desktop.settings.visitors.documents.table.date_added"),
				renderCell: (d) => {
					return formatDocumentDate(d.created_at)
				},
			},
			{
				field: "required_signing",
				label: t("desktop.settings.visitors.documents.table.requires_signing"),
				renderCell: (d) => {
					return d.required_signing ? (
						<CheckSVG className="SvgBlackColorFill" />
					) : (
						<CrossSVG className="SvgBlackColorFill" />
					)
				},
			},
			{
				field: "status",
				label: t("desktop.settings.visitors.documents.table.status"),
				renderCell: (d) => {
					return <DocumentStatus document={d} />
				},
			},
		],
		[t],
	)

	const rowActions = useMemo<Action<DocumentResponse>[]>(
		() => [
			{
				label: t("desktop.settings.visitors.documents.table.action_edit"),
				onClick: (d) => {
					history.push(`/settings/visitors/documents/edit/${d.id}`)
				},
			},
			{
				label: (d) => {
					const isPublished = d.status === DocumentStatusType.PUBLISHED
					return isPublished
						? t("desktop.settings.visitors.documents.table.action_unpublish")
						: t("desktop.settings.visitors.documents.table.action_publish")
				},
				onClick: async (d) => {
					const action =
						d.status === DocumentStatusType.PUBLISHED
							? DocumentBulkAction.UNPUBLISH
							: DocumentBulkAction.PUBLISH
					const response = await actions.documentBulkAction({
						document_ids: [d.id],
						action,
					})
					if (documentBulkAction.rejected.match(response)) {
						errorToast(response.error.message)
					} else {
						actions.fetchDocuments()
						infoToast(
							t(
								"desktop.settings.visitors.documents.action_statuses.patch_success",
							),
						)
					}
				},
			},
			{
				label: t("desktop.settings.visitors.documents.table.action_delete"),
				onClick: async (d) => {
					const response = await actions.documentBulkAction({
						document_ids: [d.id],
						action: DocumentBulkAction.DELETE,
					})
					if (documentBulkAction.rejected.match(response)) {
						errorToast(response.error.message)
					} else {
						actions.fetchDocuments()
						infoToast(
							t(
								"desktop.settings.visitors.documents.action_statuses.delete_success",
							),
						)
					}
				},
				requiresConfirmation: true,
			},
		],
		[t, history, actions, infoToast, errorToast],
	)

	const handleRowClick = (document: DocumentResponse) => {
		history.push(`/settings/visitors/documents/edit/${document.id}`)
	}

	const handleBulkAction = async (action: DocumentBulkAction) => {
		const activeSelectedRows = filteredDocuments
			.filter(
				(doc: DocumentResponse) =>
					selectedRows.find((d) => d.id === doc.id) !== undefined,
			)
			.map((doc: DocumentResponse) => doc.id)

		if (activeSelectedRows.length <= 0) {
			infoToast(
				t(
					"desktop.settings.visitors.documents.action_statuses.no_documents_selected",
				),
			)
			return
		}

		const actionResponse = await actions.documentBulkAction({
			document_ids: activeSelectedRows,
			action,
		})
		if (documentBulkAction.rejected.match(actionResponse)) {
			errorToast(actionResponse.error.message)
		} else {
			actions.fetchDocuments()

			switch (action) {
				case DocumentBulkAction.DELETE:
					infoToast(
						t(
							"desktop.settings.visitors.documents.action_statuses.delete_success",
						),
					)
					break
				case DocumentBulkAction.PUBLISH:
				case DocumentBulkAction.UNPUBLISH:
					infoToast(
						t(
							"desktop.settings.visitors.documents.action_statuses.patch_success",
						),
					)
					break
			}
		}
	}

	const handleBulkActionConfirmation = (action: DocumentBulkAction) => {
		openModal(ConfirmationModal, {
			onConfirm: () => {
				handleBulkAction(action)
				closeModal()
			},
		})
	}

	return (
		<View className="SettingsDocuments">
			<Breadcrumbs depth={2} />

			<Intro>{t("desktop.settings.visitors.documents.intro")}</Intro>

			<Space size={0.75} />

			<Filters>
				<SearchFilter
					value={search}
					onChange={(v: string) => setSearch(v)}
					placeholder={t("desktop.settings.visitors.documents.search")}
				/>
				<FilterSpace />

				<MultiActionButton
					isSmall
					options={bulkActionOptions}
					onAction={handleBulkActionConfirmation}
					label={t("desktop.settings.visitors.bulk_actions_filter_name")}
				/>
				<Button to="/settings/visitors/documents/add" isSmall>
					{t("desktop.settings.visitors.documents.new_document")}
				</Button>
			</Filters>

			<Space size={0.75} />

			<Table
				isSelectable
				loading={areDocumentsLoading}
				rows={filteredDocuments}
				columns={tableColumns}
				rowActions={rowActions}
				onSelectedRows={setSelectedRows}
				emptyTableCell={<DocumentsEmptyTableRows />}
				onRowClick={handleRowClick}
			/>
		</View>
	)
}

export default Documents
