import { useEffect } from "react"

import "./Headway.sass"

const Headway = () => {
	useEffect(() => {
		const ref = document.getElementsByTagName("meta")[0]
		const script = document.createElement("script")
		const w: any = window

		if (!ref.parentNode) return
		if (w.Headway) return
		if (!process.env.REACT_APP_HEADWAY_ACC) return

		w.HW_config = {
			selector: "#headway-changelog",
			trigger: "#headway-changelog",
			account: process.env.REACT_APP_HEADWAY_ACC,
		}

		script.async = true
		script.src = "https://cdn.headwayapp.co/widget.js"
		ref.parentNode.insertBefore(script, ref)
	}, [])

	return <span id="headway-changelog">Changelog</span>
}

export default Headway
