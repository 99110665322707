const ONBOARDING_ROOT_PATH = "/onboarding"
const ONBOARDING_USER_PATHNAME = `${ONBOARDING_ROOT_PATH}/user`
const ONBOARDING_ORGANIZATION_PATHNAME = `${ONBOARDING_ROOT_PATH}/organization`
const ONBOARDING_BUILDING_PATHNAME = `${ONBOARDING_ROOT_PATH}/building`

export const ONBOARDING_PATHS = {
	root: ONBOARDING_ROOT_PATH,
	user: ONBOARDING_USER_PATHNAME,
	organization: ONBOARDING_ORGANIZATION_PATHNAME,
	building: ONBOARDING_BUILDING_PATHNAME,
} as const
