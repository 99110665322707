import React from "react"

import LogoVariant from "./LogoVariant"

import "./LogoURL.sass"

type Props = {
	width: number
	height: number
	url?: string
}

const LogoURL = ({ url, width, height }: Props) => {
	if (!url) {
		return <LogoVariant variant="landscape" />
	}

	const src = `${url}?width=${width}&height=${height}&mode=1`
	return <img className="logo-image" src={src} alt="logo" />
}

export default LogoURL
