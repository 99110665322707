import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Alert.sass"

type Props = {
	type?: "warning" | "error" | "info"
	className?: string
}

export const Alert = ({
	children,
	className,
	type = "warning",
}: PropsWithChildren<Props>) => {
	const cn = classNames("alert", type, className)
	return <div className={cn}>{children}</div>
}
