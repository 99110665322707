import React, {
	CSSProperties,
	ForwardedRef,
	PropsWithChildren,
	forwardRef,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"

import classNames from "classnames"

import PlanImage, { Dimension } from "./PlanImage"
import throttle from "lodash.throttle"

import "./SimpleMap.sass"

type MapPlainProps = {
	image: string
	mapHeight: number
	mapWidth: number
	className?: string
}

export const MapPlain = (
	{
		children,
		image,
		mapHeight,
		mapWidth,
		className,
	}: PropsWithChildren<MapPlainProps>,
	ref: ForwardedRef<HTMLImageElement>,
) => {
	const simpleMap = useRef<HTMLDivElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)

	const [dimensions, setDimensions] = useState<Dimension>({
		width: 1,
		height: 1,
	})

	useEffect(() => {
		if (!containerRef.current) return

		const { clientWidth: width, clientHeight: height } = containerRef.current

		setDimensions({ width, height })
	}, [])

	useEffect(() => {
		const newDimensions = throttle(() => {
			if (!containerRef.current) return

			const { clientWidth: width, clientHeight: height } = containerRef.current
			setDimensions({ width, height })
		}, 500)

		window.addEventListener("resize", newDimensions)

		return () => {
			window.removeEventListener("resize", newDimensions)
		}
	}, [])

	const imageAspect = mapWidth / (mapHeight || 1)

	const containerAspect = useMemo(() => dimensions.width / dimensions.height, [
		dimensions,
	])

	const mapClassName = classNames({
		"simple-map": true,
		[className ?? ""]: Boolean(className),
	})

	return (
		<div className="simple-map-container" ref={containerRef}>
			<div
				className={mapClassName}
				ref={simpleMap}
				style={
					{
						width: `${dimensions.width}px`,
						height: `${dimensions.height}px`,
					} as CSSProperties
				}
			>
				<PlanImage
					isWider={imageAspect > containerAspect}
					isTaller={imageAspect < containerAspect}
					isSame={imageAspect === containerAspect}
					containerSize={dimensions}
					src={image}
					ref={ref}
				>
					{children}
				</PlanImage>
			</div>
		</div>
	)
}

const SimpleMap = forwardRef<
	HTMLImageElement,
	PropsWithChildren<MapPlainProps>
>(MapPlain)

export default SimpleMap
