import React, { PropsWithChildren } from "react"

import { ToastContainer } from "react-toastify"

import AppParamsProvider from "./AppParamsProvider"
import LocalizationProvider from "./LocalizationProvider"

export function OpenPathProviders({ children }: PropsWithChildren<unknown>) {
	return (
		<>
			<AppParamsProvider>
				<LocalizationProvider>{children}</LocalizationProvider>
			</AppParamsProvider>
			<ToastContainer />
		</>
	)
}
