import { useTranslation } from "react-i18next"

import { OptionType } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"

import {
	ASSET_SCHEDULE_STATUSES,
	AssetScheduleStatus,
} from "../../redux/asset_schedule/assetScheduleSlice"

type Props = {
	value: AssetScheduleStatus
	onChange: (v: AssetScheduleStatus) => void
}

export const AssetStatusFilter = ({ value, onChange }: Props) => {
	const { t } = useTranslation()
	const options: OptionType<AssetScheduleStatus>[] = [
		{
			label: t("desktop.components.filters.asset_status.options.all"),
			value: ASSET_SCHEDULE_STATUSES.all,
		},
		{
			label: t("desktop.components.filters.asset_status.options.available"),
			value: ASSET_SCHEDULE_STATUSES.available,
		},
		{
			label: t("desktop.components.filters.asset_status.options.reserved"),
			value: ASSET_SCHEDULE_STATUSES.reserved,
		},
	]
	return (
		<Dropdown
			className="asset-status-filter"
			options={options}
			value={value}
			onChange={onChange}
		/>
	)
}
