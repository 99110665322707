import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink, useHistory } from "react-router-dom"

import { DepartmentResponse } from "../../../redux/api/departments/types"
import { useAppSelector } from "../../../redux/reducers"
import { formatUser } from "../../../redux/user/utils"

import { BottomNav } from "../../../components/Mobile/BottomNav"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import { ReactComponent as ChatSVG } from "../../../assets/images/icons/Chat.svg"
import { ReactComponent as LogoutSVG } from "../../../assets/images/icons/Logout.svg"
import { ReactComponent as PencilSVG } from "../../../assets/images/icons/Pencil.svg"
import { ReactComponent as PhoneSVG } from "../../../assets/images/icons/Phone.svg"
import { ReactComponent as SettingsSVG } from "../../../assets/images/icons/Settings.svg"

import "./Info.sass"

const LinkBox: React.FC = () => {
	const { t } = useTranslation()

	const version = process.env.REACT_APP_VERSION

	const onFeedBackClick = () => {
		window.location.href =
			"mailto:support@getjoan.com?subject=App Feedback&body=App Version " +
			version
	}

	return (
		<div className="LinkBox">
			<NavLink to="/profile/settings">
				<SettingsSVG />
				<div>{t("mobile.profile.settings")}</div>
			</NavLink>
			<NavLink to="#" onClick={onFeedBackClick}>
				<ChatSVG />
				<div>{t("mobile.profile.feedback")}</div>
			</NavLink>
			<NavLink to="/profile/about">
				<PhoneSVG />
				<div>{t("mobile.profile.about")}</div>
			</NavLink>
		</div>
	)
}

const Info: React.FC = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const { entry } = useAppSelector((state) => state.user)
	const { entry: scim } = useAppSelector((state) => state.scim)

	const { enabled: isSCIMEnabled } = scim ?? {}
	const isEditingEnabled = !isSCIMEnabled

	const logOut = () => {
		history.push("/auth/logout")
	}

	return (
		<SafeViewArea className="Info">
			<TopNav backArrow={false} rightIcon={<LogoutSVG onClick={logOut} />} />
			<div className="body">
				<h2>{t("mobile.profile.my_profile")}</h2>
				<div className="fields">
					<div className="field">
						<div className="data">
							<div className="label">{t("mobile.profile.name")}</div>
							<div className="value">{formatUser(entry)}</div>
						</div>
						{isEditingEnabled && (
							<div className="edit">
								<NavLink to="/profile/edit">
									<PencilSVG />
								</NavLink>
							</div>
						)}
					</div>
					<div className="field">
						<div className="data">
							<div className="label">{t("mobile.profile.email")}</div>
							<div className="value">{entry.email}</div>
						</div>
					</div>
					{entry.departments && entry.departments.length > 0 && (
						<div className="field">
							<div className="data">
								<div className="label">
									{t("mobile.profile.my_departments")}
								</div>
								{entry.departments.map((dep: DepartmentResponse, i: number) => (
									<span key={dep.id} className="value">
										{dep.name}
										{entry.departments.length !== i + 1 && ", "}
									</span>
								))}
							</div>
						</div>
					)}
				</div>
				<LinkBox />
			</div>
			<BottomNav />
		</SafeViewArea>
	)
}

export default Info
