import { API_ROOT } from "../../api"
import {
	ApiResponseError,
	getErrorCode,
	parseApiError,
} from "../../api/apiUtils"
import { formatErrorResponse } from "../../api/utils"
import { NON_RETRY_CODES, detectLockedFeature } from "../../fetch"
import { clearAuth } from "../auth/authSlice"
import { setHasPaid } from "../payment/paymentSlice"
import { RootState, store } from "../reducers"
import type {
	FetchArgs,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery"
import {
	BaseQueryFn,
	fetchBaseQuery,
	retry,
} from "@reduxjs/toolkit/query/react"

/**
 * Handles API requests and responses, with consistent treatment of certain HTTP status codes.
 *
 * The module fetchBaseQueryExtended exports a wrapper function which leverages Redux Toolkit
 * and the fetchBaseQuery function from '@reduxjs/toolkit/query/react' to provide a custom API fetching solution.
 * The use of async/await syntax results in more readable asynchronous code.
 *
 * @module
 * @param {string|FetchArgs} args - The arguments for your request. Either a string (the URL), or an object with properties.
 * @param {object} api - An API service.
 * @param {object} extraOptions - Extra options for the fetch function.
 *
 * @returns {{}} response - The server response.
 *
 * @throws {Error} If the server responds with an error, it is thrown.
 * The handling of specific status codes should be noted:
 *  401 response causes the authorization to be cleared,
 *  nonRetry codes defined in ../../fetch cause the request to fail immediately,
 *  402 prompts the function to attempt to interpret what feature is locked
 *  and change state to reflect this.
 *
 **/

export const fetchBaseQueryExtended = retry(
	async (args: string | FetchArgs, api, extraOptions) => {
		const response = await (
			fetchBaseQuery({
				baseUrl: API_ROOT,
				prepareHeaders: (headers, { getState }) => {
					const {
						auth: { access_token },
					} = getState() as RootState
					if (access_token) {
						headers.set("authorization", `Bearer ${access_token}`)
					}

					return headers
				},
			}) as BaseQueryFn<any, unknown, ApiResponseError, {}, FetchBaseQueryMeta>
		)(args, api, extraOptions)

		if (response.meta?.response?.ok) {
			return response
		}
		if (response.error) {
			const errorResponse: ApiResponseError = {
				data: response.error.data,
				status: response.meta?.response?.status ?? 0,
				formError: parseApiError(response.error.data),
				message: formatErrorResponse(response.error.data),
				errorCode: getErrorCode(response),
			}
			// bail out of re-tries immediately if unauthorized,
			// because we know successive re-retries would be redundant
			if (response.error.status === 401) {
				store.dispatch(clearAuth())
				retry.fail(errorResponse)
			}

			if (
				typeof response.error.status === "number" &&
				NON_RETRY_CODES.includes(response.error?.status)
			) {
				retry.fail(errorResponse)
			}

			if (response.error.status === 402) {
				store.dispatch(
					setHasPaid(
						detectLockedFeature(typeof args === "string" ? args : args.url),
					),
				)
			}
		}
		return response
	},
	{
		maxRetries: 0,
	},
)
